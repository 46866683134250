import React from "react";
import "./ZoomFrame.scss";

function ZoomFrame() {
  return (
    <div className="iframe-container">
      <iframe
        allow="microphone; camera"
        src="https://us04web.zoom.us/j/73729019976?pwd=ovQoRfhMaPk2WCJGu2SlB8iJxNVmQy.1"
        style={{ width: "100%", height: "100vh" }}
      ></iframe>
    </div>
  );
}

export default ZoomFrame;
