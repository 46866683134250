import React, { useEffect, useState } from "react";
import "./UnifiedApplication.scss";
import Input from "../../../Shared/Components/Input/Input";
import pen from "../../../Shared/Assets/Images/edit.png";
import check from "../../../Shared/Assets/Images/checked.png";
import unCheck from "../../../Shared/Assets/Images/unchecked.png";
import doc from "../../../Shared/Assets/Images/document.png";
import download from "../../../Shared/Assets/Images/download.png";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import deleteIcon from "../../../Shared/Assets/Images/trash.png";
import add from "../../../Shared/Assets/Images/add.png";
import Button from "../../../Shared/Components/Button/Button";
import back from "../../../Shared/Assets/Images/arrow1.webp";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import save from "../../../Shared/Assets/Images/save.png";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { getAllCont } from "../../../Shared/Redux/Location/LocationsActions";
import Popup from "../../../Shared/Components/Popup/Popup";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Shared/Components/Loader/Loader";
import {
  chooseUnifiedMissingDocuments,
  getUnifiedApplication,
  updateUnifiedApp,
  uploadAppMissingDocuments,
  uploadAppMissingDocumentsFromWebsite,
  uploadUnifiedMissingDocuments,
} from "../../../Shared/Redux/Application/ApplicationAction";
import { handleDownload, handlePreview } from "../../../Utils";
import EditEducationSummary from "../../../Shared/Components/EditEducationSummary/EditEducationSummary";
import UploadDoc from "../../../Shared/Components/UploadDoc/UploadDoc";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
function UnifiedApplication() {
  const navigate = useNavigate();
  const [studiedinCountries, setStudiedinCountries] = useState(true);
  const [disabilities, setDisabilities] = useState(false);
  const [visaRejection, setVisaRejection] = useState(false);
  const [visaRejectionDetails, setVisaRejectionDetails] = useState("");
  const [gapYear, setgapYear] = useState(false);
  const [gapDetails, setGapDetails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [disabled, setDisabled] = useState(-1);
  const [deletePassPop, setDeletePassPop] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPop, setShowPop] = useState(false);
  const [showUploadDoc, setShowUploadDoc] = useState(false);
  const [uploadedDoc, setUploadedDoc] = useState({});
  const [chosenDoc, setChosenDoc] = useState({});
  const [docName, setDocName] = useState("");
  const [disabilitiesDetaisl, setDisabilitiesDetaisl] = useState("");
  const [studiedDetails, setStudiedDetails] = useState("");

  const { user } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const { unifiedApplication, unifiedApplicationLoading } = useSelector(
    (state) => state.ApplicationsReducer
  );

  useEffect(() => {
    dispatch(getAllCont());
  }, []);

  useEffect(() => {
    dispatch(getUnifiedApplication());
  }, []);

  useEffect(() => {
    if (unifiedApplicationLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [unifiedApplicationLoading]);

  useEffect(() => {
    if (unifiedApplication && Object.keys(unifiedApplication).length > 0) {
      setStudiedinCountries(unifiedApplication.studied_in_countries);
      setDisabilities(unifiedApplication.disabilities);
      setVisaRejection(unifiedApplication.refused_visa);
      setVisaRejectionDetails(
        unifiedApplication.countries_rejected != "none"
          ? unifiedApplication.countries_rejected
          : ""
      );
      setgapYear(unifiedApplication.has_gap_year);
      setGapDetails(
        unifiedApplication.gap_year == "null" ? "" : unifiedApplication.gap_year
      );
      setDisabilitiesDetaisl(
        unifiedApplication.disabilities_description == "null"
          ? ""
          : unifiedApplication.disabilities_description
      );
      setStudiedDetails(
        unifiedApplication.studied_in_countries_description == "null"
          ? ""
          : unifiedApplication.studied_in_countries_description
      );
      setErrorMessage("");
      setErrorMessage2("");

      let chosen = {};
      unifiedApplication.documents.map((doc) => {
        chosen[doc.name] = { value: doc.name, label: doc.originalName };
      });
      setChosenDoc(chosen);
    } else {
      setChosenDoc({});
    }
  }, [unifiedApplication]);

  const dispatchUploadApplication = () => {
    setDisabled(3);

    if (disabled === 3) {
      if (gapYear === false) {
        setGapDetails(null);
      } else if (
        gapYear === true &&
        (gapDetails === "" || gapDetails === null)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }

      if (visaRejection === false) {
        setVisaRejectionDetails(null);
      } else if (
        visaRejection === true &&
        (visaRejectionDetails === "" || !visaRejectionDetails)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }
      if (studiedinCountries === false) {
        setStudiedDetails(null);
      } else if (
        studiedinCountries === true &&
        (studiedDetails === "" || !studiedDetails)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }
      if (disabilities === false) {
        setDisabilitiesDetaisl(null);
      } else if (
        disabilities === true &&
        (disabilitiesDetaisl === "" || !disabilitiesDetaisl)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }

      if (
        gapYear === true &&
        visaRejection === true &&
        disabilities === true &&
        studiedinCountries === true &&
        (gapDetails === "" || !gapDetails) &&
        (disabilitiesDetaisl === "" || !disabilitiesDetaisl) &&
        (studiedDetails === "" || !studiedDetails) &&
        (visaRejectionDetails === "" || !visaRejectionDetails)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      }

      setErrorMessage("");
      setErrorMessage2("");
      const application = {
        studied_in_countries: studiedinCountries,
        disabilities: disabilities,
        refused_visa: visaRejection,
        countries_rejected: visaRejection ? visaRejectionDetails : "",
        has_gap_year: gapYear,
        gap_year: gapYear ? gapDetails : "",
        disabilities_description: disabilitiesDetaisl
          ? disabilitiesDetaisl
          : "",

        studied_in_countries_description: studiedinCountries
          ? studiedDetails
          : "",
      };
      dispatch(updateUnifiedApp(application));
      setDisabled(-1);
    }
  };
  const [popup, setpopup] = useState(false);
  const [pop2, setpop2] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (disabled == 3) {
        // Show the popup or take any other necessary action

        setpop2(true);

        // Set the custom message for the confirmation dialog
        event.returnValue = "Are you sure you want to leave this page?";
      }
    };

    // Add the event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabled]);

  useEffect(() => {
    if (disabled == 3 && !pop2) {
      setpop2(true);
    }
  }, [disabled]);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.add("pen_shake");
        }
      }, 1850);
    } else {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.remove("pen_shake");
        }
      }, 1850);
    }
  }, [popup]);

  return (
    <div className="unified_main_div">
      {loading && <Loader fullScreen={true} />}
      {popup && (
        <UpperPopUp
          setShow={setpopup}
          title="Please Click on The Pen to Edit Info"
        />
      )}
      {pop2 && (
        <UpperPopUp
          setShow={setpop2}
          title="Please Save Your Changes Before Leaving The Page"
        />
      )}

      {showPop && (
        <EditEducationSummary
          setShowPop={setShowPop}
          data={unifiedApplication?.education_summary}
        />
      )}
      {showUploadDoc && (
        <UploadDoc
          setShow={setShowUploadDoc}
          title="Choose Document from"
          showRadio={true}
          uploadedDoc={uploadedDoc}
          setUploadedDoc={setUploadedDoc}
          setChosenDoc={setChosenDoc}
          chosenDoc={chosenDoc}
          onSubmit={() => {
            {
              uploadedDoc[docName]
                ? dispatch(
                    uploadUnifiedMissingDocuments(
                      unifiedApplication.id,
                      docName,
                      uploadedDoc[docName],
                      user.Student.id,
                      true
                    )
                  )
                : dispatch(
                    chooseUnifiedMissingDocuments(
                      unifiedApplication.id,
                      chosenDoc[docName].value,
                      docName,
                      user.Student.id,
                      true
                    )
                  );
            }
          }}
          docName={docName}
          setDocName={setDocName}
        />
      )}
      <div className="unified_header">
        <img
          src={back}
          alt="back"
          className="unified_header_back"
          onClick={() => navigate("/student/my_applications")}
        />
        <h1 className="unified_heading">My Unified Application</h1>
      </div>

      <div
        className="unified_personal_info"
        style={{ backgroundColor: "var(--pink" }}
      >
        <h2 className="unified_sub_title">
          Education Summary{" "}
          <img
            src={disabled != 2 ? pen : save}
            alt="edit"
            className={disabled != 2 ? "unified_edit_img" : "unified_edit_img0"}
            onClick={() => {
              setShowPop(true);
            }}
          />
        </h2>
        <div className="unified_personal_info_div">
          {unifiedApplication?.education_summary?.map((edu) => {
            return (
              <div className="unified_education_table">
                <div className="education_table_title">{edu.school_name}</div>
                <div className="education_table_major">
                  <div className="eduation_table_major_title">
                    {edu.degree} in {edu.field_of_study}
                  </div>
                  <div className="education_table_major_date">
                    {new Date(edu.start_date).getFullYear()} -{" "}
                    {new Date(edu.end_date).getFullYear()}
                  </div>
                </div>
                <div className="education_table_footer">
                  <div className="education_table_footer_location">
                    {edu.country_of_education}
                  </div>
                  <div className="education_table_footer_graduated">
                    <img
                      src={
                        new Date(edu.end_date) - new Date() <= 0
                          ? check
                          : unCheck
                      }
                      alt="check"
                      className="education_check_img"
                    />
                    {new Date(edu.end_date) - new Date() <= 0
                      ? "Graduated"
                      : "Not Graduated"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="unified_personal_info"
        style={{ backgroundColor: "var(--yellow" }}
      >
        <h2 className="unified_sub_title">
          Background Information
          <img
            src={disabled != 3 ? pen : save}
            alt="edit"
            className={
              disabled != 3
                ? "unified_edit_img edit_pen"
                : "unified_edit_img0 edit_save"
            }
            onClick={() => {
              dispatchUploadApplication();
            }}
          />
        </h2>
        <div
          className="unified_background_div"
          onClick={() => {
            if (disabled != 3) {
              setpopup(true);
            }
          }}
        >
          <div className="background_info_div">
            <div className="background_info_title">
              Have you ever pursued any previous studies in the UK, USA, Canada,
              Australia, Switzerland, or any countries within the (EEA) or the
              (EU)?
            </div>
            <div className="background_info_checkboxes">
              <Checkbox
                label="Yes"
                selected={studiedinCountries ? true : false}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setStudiedinCountries(true);
                }}
              />
              <Checkbox
                label="No"
                setSelected={setStudiedinCountries}
                selected={studiedinCountries ? false : true}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setStudiedinCountries(false);
                  setErrorMessage2("");
                }}
              />
            </div>
            {studiedinCountries !== false && (
              <textarea
                className="hasGap_details counselorTextArea"
                placeholder="Please provide us with more details."
                onChange={(e) => {
                  setStudiedDetails(e.target.value);
                }}
                value={studiedDetails}
                disabled={disabled == 3 ? false : true}
              ></textarea>
            )}
            {errorMessage2 !== "" &&
              studiedDetails == "" &&
              studiedinCountries && (
                <div className="error">{errorMessage2}</div>
              )}
          </div>
          <div className="background_info_div">
            <div className="background_info_title">
              Do you have any disability or considerations that need to be
              addressed by the university?
            </div>
            <div className="background_info_checkboxes">
              <Checkbox
                label="Yes"
                selected={disabilities ? true : false}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setDisabilities(true);
                }}
              />
              <Checkbox
                label="No"
                selected={disabilities ? false : true}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setDisabilities(false);
                  setErrorMessage2("");
                }}
              />
            </div>
            {disabilities !== false && (
              <textarea
                className="hasGap_details counselorTextArea"
                placeholder="Please provide us with more details."
                onChange={(e) => {
                  setDisabilitiesDetaisl(e.target.value);
                }}
                value={disabilitiesDetaisl}
                disabled={disabled == 3 ? false : true}
              ></textarea>
            )}
            {errorMessage2 !== "" &&
              disabilitiesDetaisl == "" &&
              disabilities && <div className="error">{errorMessage2}</div>}
          </div>
          <div className="background_info_div">
            <div className="background_info_title">
              Have you ever received a visa rejection?
            </div>
            <div className="background_info_checkboxes">
              <Checkbox
                label="Yes"
                selected={visaRejection ? true : false}
                disabled={disabled == 3 ? false : true}
                click={true}
                onClick={() => {
                  setVisaRejection(true);
                }}
              />
              <Checkbox
                label="No"
                selected={visaRejection ? false : true}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setVisaRejection(false);
                  setErrorMessage2("");
                }}
              />
            </div>
            {visaRejection !== false && (
              <textarea
                className="hasGap_details counselorTextArea"
                placeholder="Please provide us with more details."
                onChange={(e) => {
                  setVisaRejectionDetails(e.target.value);
                }}
                value={visaRejectionDetails}
                disabled={disabled == 3 ? false : true}
              ></textarea>
            )}
            {errorMessage2 !== "" && visaRejection == "" && visaRejection && (
              <div className="error">{errorMessage2}</div>
            )}
          </div>
          <div className="background_info_div">
            <div className="background_info_title">
              Did you go through a gap year?
            </div>
            <div className="background_info_checkboxes">
              <Checkbox
                label="Yes"
                selected={gapYear ? true : false}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setgapYear(true);
                }}
              />
              <Checkbox
                label="No"
                setSelected={setgapYear}
                selected={gapYear ? false : true}
                click={true}
                disabled={disabled == 3 ? false : true}
                onClick={() => {
                  setgapYear(false);
                  setErrorMessage2("");
                }}
              />
            </div>
            {gapYear !== false && gapDetails != "null" && (
              <textarea
                className="hasGap_details counselorTextArea"
                placeholder="Please provide us with more details."
                onChange={(e) => {
                  setGapDetails(e.target.value);
                }}
                value={gapDetails}
                disabled={disabled == 3 ? false : true}
              ></textarea>
            )}
            {errorMessage2 !== "" && gapDetails == "" && gapYear && (
              <div className="error">{errorMessage2}</div>
            )}
          </div>
        </div>
      </div>

      <div
        className="unified_personal_info"
        style={{ backgroundColor: "var(--lightGreen" }}
      >
        <h2 className="unified_sub_title">Uploaded Documents</h2>
        <div className="unified_background_div">
          {unifiedApplication &&
            unifiedApplication.documents?.length > 0 &&
            unifiedApplication.documents?.map((document) => {
              if (Object.keys(document).length > 0)
                return (
                  <div
                    className="uploaded_documents_box"
                    onClick={() =>
                      handlePreview(
                        `/students/${user.Student.id}/unifiedAppDocs/${document.path}`,
                        document.originalName
                      )
                    }
                  >
                    <div className="uploaded_documents_div1">
                      <img
                        src={doc}
                        alt="document"
                        className="uploaded_documents_img"
                      />
                      <div className="uploaded_documents_info">
                        <div className="uploaded_documents_title">
                          {document.name}
                        </div>
                        <div className="+">
                          {document.type.split("/")[1]},{" "}
                          {(document.size / 1024 / 1024).toFixed(2)} MB
                        </div>
                      </div>
                    </div>

                    <div className="uploaded_documents_div2">
                      <img
                        src={download}
                        alt="download"
                        className="documents_uni_icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(
                            `students/${user.Student.id}/unifiedAppDocs/${document.path}`,
                            document.originalName
                          );
                        }}
                      />
                      <img
                        src={pen}
                        alt="edit"
                        className="unified_edit_img"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDocName(document.name);
                          setShowUploadDoc(true);
                        }}
                      />
                    </div>
                  </div>
                );
            })}
        </div>
      </div>

      {/* {deletePassPop && (
            <Popup
              setShowPopup={setDeletePassPop}
              width={"35%"}
              body={
                <div className="unified_doc_div">
                  <div className="unified_doc_div_header">
                    Are you sure you want to delete this document?
                  </div>
                  <div className="unified_doc_div_subText">
                    If you deleted this document you need to reassign it as a
                    new document for this application{" "}
                  </div>
                  <div className="unified_doc_div_body">
                    <Button
                      text={"Yes"}
                      color={"yellow"}
                      onClick={() => {
                        setDeletePassPop(false);
                        // handleRemovePassport(selectedId);
                      }}
                    />
                    <Button
                      text={"Cancel"}
                      color={"pink"}
                      onClick={() => {
                        setDeletePassPop(false);
                      }}
                    />
                  </div>
                </div>
              }
            />
          )} */}
    </div>
  );
}

export default UnifiedApplication;
