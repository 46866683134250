import { CounselorMeetingActions } from "./CounselorMeetingReducer";
import { axiosReq } from "../../../Utils";

export const getCounselorMeeting = (date) => async (dispatch) => {
  dispatch(CounselorMeetingActions.getEventsReq());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/meetings/" + date, {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorMeetingActions.getEventsSuccess(res.data));
    } else {
      dispatch(
        CounselorMeetingActions.getEventsFail(res.data.response.data.message)
      );
    }
  });
};
export const getCounselorMeetingToday = () => async (dispatch) => {
  dispatch(CounselorMeetingActions.getTodayEventsReq());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API +
        "/meetings/" +
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorMeetingActions.getTodayEventsSuccess(res.data));
    } else {
      dispatch(
        CounselorMeetingActions.getTodayEventsFail(
          res.data.response.data.message
        )
      );
    }
  });
};

export const changeMeetingStatus = (id, status, date) => async (dispatch) => {
  dispatch(CounselorMeetingActions.changeMeetingStatusReq());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/meetings/answer-meeting-request",
      {},
      {
        meetingId: id,
        counselorResponse: status,
        code: "tsUKLxEgPs1NsTTLl_oT6OdV2WDSj8q9g",
      }
    )
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorMeetingActions.changeMeetingStatusSuccess());
      date
        ? dispatch(
            getCounselorMeeting(date.year + "-" + date.month + "-" + date.day)
          )
        : dispatch(getCounselorMeetingToday());
    } else {
      dispatch(
        CounselorMeetingActions.changeMeetingStatusFail(
          res.data.response.data.message
        )
      );
    }
  });
};
