import React, { useEffect, useState } from "react";
import "./OBStep1.scss";
import Circle from "../../../../Shared/Components/Animations/Circle/Circle";
import DropDown from "../../../../Shared/Components/DropDown/DropDown";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";
import OBStep2 from "../OBStep2/OBStep2";
import { getMajotCAtg } from "../../../../Shared/Redux/Universities/UniversityActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
function OBStep1({ counselor }) {
  const leadId = useLocation()?.state?.lead;
  const dispatch = useDispatch();
  const [student, setStudent] = useState({});
  const [majors, setMajors] = useState("");
  const { MajorCatg, getMajorCatgLoading } = useSelector(
    (state) => state.UniversitiesReducer
  );
  const [majorsAll, setMajorsAll] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getMajotCAtg());
  }, []);

  useEffect(() => {
    if (MajorCatg) {
      const allM = [];
      MajorCatg.map((item) => {
        const maj = { value: item.name, label: item.name };
        allM.push(maj);
      });
      setMajorsAll(allM);
    }
  }, [MajorCatg]);

  useEffect(() => {
    setLoading(getMajorCatgLoading);
  }, [getMajorCatgLoading]);

  const [step, setStep] = useState(1);

  const onSubmit = () => {
    const stMajors = majors.map((major) => major.value);
    setStudent({ ...student, what_to_study: stMajors, lead: leadId && leadId });
    if (majors.length != 0) setStep(2);
  };
  return (
    <div className="obStep_main_div">
      {step == 1 ? (
        <FormLayout
          showSteps={true}
          step={step}
          totalSteps={4}
          header={counselor ? "Choose the Major" : "What do you want to study?"}
          subHeader="You can choose up to 4 majors"
          minHeight="fit-content"
          width="90%"
          loading={loading}
          body={
            <div className="step_body">
              {majorsAll && (
                <DropDown
                  placeholder="Major"
                  setSelected={setMajors}
                  data={majorsAll}
                  blueArrow={true}
                  width="100%"
                  multiSelection={true}
                  isClearable={false}
                  searchable={false}
                  keepPlaceHolder={true}
                  selectedData={majors}
                  isDisabled={majors.length == 4 && true}
                />
              )}
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={majors.length == 0 && true}
              />
            </div>
          }
          icon={<Circle />}
          bg="var(--lightGreen)"
          gap="20px"
        />
      ) : (
        <OBStep2
          counselor={counselor}
          step={step}
          setStep={setStep}
          student={student}
          setStudent={setStudent}
        />
      )}
    </div>
  );
}

export default OBStep1;
