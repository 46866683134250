import React, { useEffect, useState } from "react";
import "./Students.scss";
import UserTable from "../../Components/UserTable/UserTable";
import Button from "../../../Shared/Components/Button/Button";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import plus from "../../../Shared/Assets/Images/add.png";
import SortBy from "../../../Shared/Components/SortBy/SortBy";
import Pagination from "../../../Shared/Components/Pagination/Pagination";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import ScheduleMeeting from "../../../Shared/Components/ScheduleMeeting/ScheduleMeeting";
import checked from "../../../Shared/Assets/Images/checked.png";
import Popup from "../../../Shared/Components/Popup/Popup";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import { useNavigate } from "react-router-dom";
import filter from "../../../Shared/Assets/Images/filterMob.png";

import { useDispatch, useSelector } from "react-redux";
import {
  getstudents,
  getactiveCounselors,
  SalesAssignCounselor,
  ArchiveStudents,
  UnArchiveStudents,
  changestatus,
} from "../../Redux/Students/StudentsActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import { getstudentsall } from "../../Redux/SalesCounselor/SalesCounselorActions";
import SearchInput from "../../../Shared/Components/SearchInput/SearchInput";
import Filters from "../../../Shared/Components/Filters/Filters";

function Students() {
  const dispatch = useDispatch();

  const [commentIndex, setCommentIndex] = useState(-1);
  const [commentNew, setCommentNew] = useState("");
  const [checkedstudents, setCheckedstudents] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setselectedTime] = useState();
  const [addstudentsPop, setAddstudentsPop] = useState(false);
  const screenWidth = UseInnerWidth();
  const [bookPop, setBookPop] = useState(false);
  const [meetingSubject, setmeetingSubject] = useState("");
  const [showPop3, setShowPop3] = useState(false);
  const [counselorName, setCounselorName] = useState("");
  const [sortBy, setsortBy] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setlimit] = useState(10);
  const [offset, setoffset] = useState(0);
  const [tempstid, settempstid] = useState("");
  const [ShowPop, setShowPop] = useState(false);
  const scWidth = UseInnerWidth();
  const [openFilter, setOpenFilter] = useState(false);
  //assign counselor
  const [selectedStudents, setselectedStudents] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [selectedCounselors, setselectedCounselors] = useState([]);
  const [loaders, setloaders] = useState(true);
  const [data, setData] = useState({});

  const {
    Students,
    successloading,
    activesuccess,
    assignsuccess,
    Counselors,
    archivesuccess,
    unarchicesuccess,
    changestatussuccess,
  } = useSelector((state) => state.StudentsSaleReducer);

  const StudentsAll = useSelector(
    (state) => state.SalesCounselorReducer.Students
  );

  useEffect(() => {
    if (sortBy.value == "") {
      dispatch(getstudents("", limit, offset, "", data));
    } else {
      dispatch(getstudents(sortBy.value, limit, offset, "", data));
    }
  }, [sortBy, limit, offset]);

  useEffect(() => {
    if (search) {
      const timer = setTimeout(() => {
        if (sortBy.value == "") {
          dispatch(getstudents("", limit, offset, search));
        } else {
          dispatch(getstudents(sortBy.value, limit, offset, search));
        }
      }, 800);

      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        if (sortBy.value == "") {
          dispatch(getstudents("", limit, offset));
        } else {
          dispatch(getstudents(sortBy.value, limit, offset));
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    dispatch(getactiveCounselors());
    dispatch(getstudentsall());
  }, []);
  const keys = [
    "Student's  Name",
    "Status",
    "Email",
    "Phone",
    "Budget",
    "Counselor",
    "Country of Residence",
    "Level of Education",
    "Assigned By",
    "Meeting",
  ];
  const sort1 = [
    { value: "a_z", label: "A to Z" },
    { value: "z_a", label: "Z to A" },
    { value: "budget_h", label: "High to  low budget" },
    { value: "budget_l", label: "Low to high budget" },
    { value: "archived", label: "Archived" },
  ];
  // const addChecked = (id) => {
  //   if (checkedstudents.includes(id)) {
  //     setCheckedstudents(checkedstudents.filter((el) => el != id));
  //   } else {
  //     setCheckedstudents([...checkedstudents, id]);
  //   }
  // };
  const addChecked = (id) => {
    const student = StudentsAll.find((student) => student.id === id);

    if (student) {
      if (checkedstudents.some((el) => el.value === id)) {
        setCheckedstudents(checkedstudents.filter((el) => el.value !== id));
      } else {
        setCheckedstudents([
          ...checkedstudents,
          {
            label: student.user.first_name + " " + student.user.last_name,
            value: id,
            userId: student.user.id,
          },
        ]);
      }
    }
  };

  // const selectAll = () => {
  //   if (checkedstudents.length == Students.length) {
  //     setCheckedstudents([]);
  //   } else {
  //     setCheckedstudents(Students.map((el) => el.id));
  //   }
  // };
  const selectAll = () => {
    if (checkedstudents.length === Students.students.length) {
      setCheckedstudents([]);
    } else {
      const allStudents = Students.students.map((student) => ({
        label: student.user.first_name + " " + student.user.last_name,
        value: student.id,
        userId: student.user.id,
      }));
      setCheckedstudents(allStudents);
    }
  };

  const disabledDays = [
    {
      year: 2023,
      month: 5,
      day: 15,
    },
    {
      year: 2023,
      month: 5,
      day: 21,
    },
    {
      year: 2023,
      month: 5,
      day: 6,
    },
  ];
  useEffect(() => {
    if (!bookPop) {
      setSelectedDay();
      setselectedTime();
      setmeetingSubject("");
      setCounselorName("");
    }
  }, [bookPop]);
  const navigate = useNavigate();

  const handleAssignCounselor = () => {
    const data2 = {
      students: checkedstudents.map((el) => {
        return { student: el.value, user: el.userId };
      }),

      counselor: selectedCounselors.value,
    };

    dispatch(
      SalesAssignCounselor(sortBy.value, limit, offset, "", data, data2)
    );
    setCheckedstudents([]);
    setselectedCounselors([]);
    setShowPop(false);
    setsortBy("");
    setpageNumber(1);
    setlimit(10);
    setoffset(0);
  };
  const handleArchive = () => {
    const data2 = {
      students: checkedstudents.map((el) => el.value),
    };
    dispatch(ArchiveStudents(sortBy.value, limit, offset, "", data, data2));
    setsortBy("");
    setCheckedstudents([]);
    setpageNumber(1);
    setlimit(10);
    setoffset(0);
  };
  const unarchive = () => {
    const data2 = {
      students: checkedstudents.map((el) => el.value),
    };
    dispatch(UnArchiveStudents(sortBy.value, limit, offset, "", data, data2));
    setsortBy("");
    setCheckedstudents([]);
    setpageNumber(1);
    setlimit(10);
    setoffset(0);
  };
  const [selectedstatus, setselectedstatus] = useState("");
  const status = [
    { label: "Cold", value: "Cold" },
    { label: "Hot", value: "Hot" },
  ];
  useEffect(() => {
    if (selectedstatus.value) {
      const data2 = {
        id: checkedstudents.map((el) => el.value),
        status: selectedstatus.value,
      };
      dispatch(changestatus(sortBy.value, limit, offset, "", data, data2));
      setsortBy("");
      setselectedstatus("");
      setCheckedstudents([]);
      setpageNumber(1);
    }
  }, [selectedstatus]);
  return (
    <>
      {(successloading ||
        activesuccess ||
        assignsuccess ||
        archivesuccess ||
        changestatussuccess ||
        unarchicesuccess) && <Loader fullScreen={true} />}
      <div className="sales_students_main_div">
        <div className="students_top_div_main">
          <div className="students_top_div">
            <h1 className="heads_main_header">Students</h1>
            <img
              src={plus}
              alt="add"
              className="add_students_img"
              onClick={() => setAddstudentsPop(true)}
            />
          </div>
          <div className="leads_top_div_header">
            <div className="catgUni_search">
              <SearchInput
                placeholder="Search for Students"
                width={screenWidth > 768 ? "400px" : "100%"}
                inputV={search}
                setInputV={setSearch}
              />
            </div>
            <Button
              text={
                scWidth <= 680 ? (
                  <img src={filter} alt="filter" className="filter_img_uni" />
                ) : (
                  "Add filter"
                )
              }
              color="blue"
              width="150px"
              onClick={() => {
                setOpenFilter(true);
              }}
            />
          </div>
        </div>
        <div className="students_top_div_sub1">
          <div className="students_top_div_sub">
            <div className="students_top_sub_sort">
              {" "}
              {checkedstudents.length > 0 && (
                <Button
                  text="Assign Counselor"
                  width="100%"
                  color="pink"
                  onClick={() => setShowPop(true)}
                />
              )}
              {checkedstudents.length > 0 && (
                <SortBy
                  width={"100%"}
                  placeholder="Change Status"
                  data={status}
                  searchable={false}
                  selectedData={selectedstatus}
                  value={selectedstatus}
                  setSelectedData={setselectedstatus}
                />
              )}
              {checkedstudents.length > 0 && sortBy.value !== "archived" ? (
                <Button
                  text="Archive"
                  width="100%"
                  color="yellow"
                  onClick={() => handleArchive()}
                />
              ) : (
                <>
                  {checkedstudents.length != 0 &&
                    sortBy.value === "archived" && (
                      <Button
                        text="Unarchive"
                        width="10rem"
                        color="yellow"
                        onClick={() => unarchive()}
                      />
                    )}{" "}
                </>
              )}
              <SortBy
                width="13rem"
                placeholder="Sort By"
                data={sort1}
                selectedData={sortBy}
                value={sortBy}
                setSelectedData={setsortBy}
              />
            </div>
          </div>

          <div className="students_top_sub_table_cont">
            <UserTable
              keys={keys}
              data={Students?.students?.map((el) => {
                return {
                  id: el.id,
                  name: el?.user?.first_name + " " + el?.user?.last_name,
                  status: el?.status,
                  email: el.user?.email,
                  phone: el.user?.phone,

                  budget:
                    (el?.budget ? el.budget?.[0].toLocaleString() + " $" : "") +
                    (el.budget?.[1]
                      ? "-" + el.budget?.[1].toLocaleString() + " $"
                      : ""),
                  counselor:
                    el.counselor != null
                      ? el.counselor?.first_name + " " + el.counselor?.last_name
                      : null,
                  country: el?.user?.country_of_residence,
                  level: el?.education_level,
                  assignedBy:
                    el?.sales &&
                    el?.sales?.first_name + " " + el?.sales.last_name,

                  meeting: el?.meetings?.length > 0 ? true : false,
                };
              })}
              type={3}
              setCommentIndex={setCommentIndex}
              checkedLeads={checkedstudents}
              addChecked={addChecked}
              salesstudent={true}
              selectAll={selectAll}
              openPop={(id) => {
                setBookPop(true);
                settempstid(id);
              }}
            />
          </div>

          <div className="students_pagination">
            <Pagination
              pageNumber={pageNumber}
              hasPrev={Students.previous}
              hasNext={Students.next}
              setPageNumber={setpageNumber}
              limit={limit}
              offset={offset}
              setOffset={setoffset}
            />
          </div>
        </div>
        <div className="popup_zoom">
          {bookPop && (
            <ScheduleMeeting
              sales={true}
              showPopup={bookPop}
              selectedDay={selectedDay}
              disabledDays={disabledDays}
              setShowPopup={setBookPop}
              selectedTime={selectedTime}
              setselectedTime={setselectedTime}
              setSelectedDay={setSelectedDay}
              stid={tempstid}
              setstid={settempstid}
              meetingSubject={meetingSubject}
              setmeetingSubject={setmeetingSubject}
              setShow={setShowPop3}
              counselors={Counselors.map((el) => {
                return {
                  label: el.counselor.first_name + " " + el.counselor.last_name,
                  value: el.counselor.id,
                };
              })}
              setCounselorName={setCounselorName}
              counselorName={counselorName}
              type={2}
            />
          )}
          {showPop3 && (
            <UpperPopUp
              setShow={setShowPop3}
              title="Meeting Booked"
              img={checked}
            />
          )}
        </div>
        {addstudentsPop && (
          <Popup
            width="40%"
            setShowPopup={setAddstudentsPop}
            body={
              <div className="add_students_popUp">
                <h1 className="add_students_header">Add Student</h1>

                <div className="add_students_sub_title">
                  Create a new student using the onboarding process
                </div>
                <div className="add_students_sub_btns">
                  <Button
                    text="Add"
                    color="blue"
                    width="100%"
                    onClick={() => navigate("/sales/students/create")}
                  />
                  <Button
                    text="Cancel"
                    color="pink"
                    width="100%"
                    onClick={() => setAddstudentsPop(false)}
                  />
                </div>
              </div>
            }
          />
        )}
      </div>
      {ShowPop && (
        <Popup
          setShowPopup={setShowPop}
          // showClose={true}
          body={
            <div className="popup_body">
              <div className="popup_body_title">
                Assign Counselor to Students
              </div>
              <div className="popup_body_content">
                <div className="profile_input_top">
                  <div className="input_title">Choose Counselor</div>
                  <DropDown
                    searchable={true}
                    setSelected={setselectedCounselors}
                    data={Counselors.map((el) => {
                      return {
                        label:
                          el.counselor?.first_name +
                          " " +
                          el.counselor?.last_name,
                        value: el.counselor?.id,
                      };
                    })}
                    placeholder={"Choose Counselor"}
                    blueArrow={true}
                    multiSelection={false}
                    selectedData={selectedCounselors}
                    keepPlaceHolder={false}
                    value={selectedCounselors}
                    width={"100%"}
                  />
                </div>
                <div className="profile_input_top">
                  <div className="input_title">Choose Student</div>
                  <DropDown
                    placeholder="Choose Students "
                    setSelected={setCheckedstudents}
                    data={StudentsAll.map((el) => {
                      return {
                        label: el.user.first_name + " " + el.user.last_name,
                        value: el.id,
                        userId: el.user.id,
                      };
                    })}
                    blueArrow={true}
                    width="100%"
                    multiSelection={true}
                    isClearable={false}
                    searchable={false}
                    keepPlaceHolder={true}
                    selectedData={checkedstudents}
                  />
                </div>
                <div className="popup_buttons">
                  <Button
                    text="Save"
                    color="yellow"
                    width="100%"
                    disabled={
                      checkedstudents.length !== 0 &&
                      selectedCounselors.length !== 0
                        ? false
                        : true
                    }
                    onClick={() => {
                      handleAssignCounselor();
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
      )}
      {openFilter && (
        <Filters
          setOpened={setOpenFilter}
          student={true}
          data={data}
          setData={setData}
        />
      )}
    </>
  );
}

export default Students;
