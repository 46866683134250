/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  addAddressLoading: false,
  addAddressError: "",
  addAddressSuccess: false,

  addExperienceLoading: false,
  addExperienceError: "",
  addExperienceSuccess: false,

  addContactLoading: false,
  addContactError: "",
  addContactSuccess: false,

  updateAddressLoading: false,
  updateAddressError: "",
  updateAddressSuccess: false,

  updateExpLoading: false,
  updateExpError: "",
  updateExpSuccess: false,

  updateContactLoading: false,
  updateContactError: "",
  updateContactSuccess: false,

  deleteExpLoading: false,
  deleteExpError: "",
  deleteExpSuccess: false,

  deleteContactLoading: false,
  deleteContactError: "",
  deleteContactSuccess: false,
};

const CompleteProfileSlice = createSlice({
  name: "complete profile",
  initialState,
  reducers: {
    addAddressRequest(state) {
      state.addAddressLoading = true;
      state.addAddressError = "";
      state.addAddressSuccess = false;
    },
    addAddressSuccess(state, action) {
      state.addAddressLoading = false;
      state.addAddressError = "";
      state.addAddressSuccess = true;
    },
    addAddressFail(state, action) {
      state.addAddressLoading = false;
      state.addAddressError = action.payload;
      state.addAddressSuccess = false;
    },
    addExperienceRequest(state) {
      state.addExperienceLoading = true;
      state.addExperienceError = "";
      state.addExperienceSuccess = false;
    },
    addExperienceSuccess(state, action) {
      state.addExperienceLoading = false;
      state.addExperienceError = "";
      state.addExperienceSuccess = true;
    },
    addExperienceFail(state, action) {
      state.addExperienceLoading = false;
      state.addExperienceError = action.payload;
      state.addExperienceSuccess = false;
    },
    addContactRequest(state) {
      state.addContactLoading = true;
      state.addContactError = "";
      state.addContactSuccess = false;
    },
    addContactSuccess(state, action) {
      state.addContactLoading = false;
      state.addContactError = "";
      state.addContactSuccess = true;
    },
    addContactFail(state, action) {
      state.addContactLoading = false;
      state.addContactError = action.payload;
      state.addContactSuccess = false;
    },
    updateAddressRequest(state) {
      state.updateAddressLoading = true;
      state.updateAddressError = "";
      state.updateAddressSuccess = false;
    },
    updateAddressSuccess(state, action) {
      state.updateAddressLoading = false;
      state.updateAddressError = "";
      state.updateAddressSuccess = true;
    },
    updateAddressFail(state, action) {
      state.updateAddressLoading = false;
      state.updateAddressError = action.payload;
      state.updateAddressSuccess = false;
    },
    updateExpRequest(state) {
      state.updateExpLoading = true;
      state.updateExpError = "";
      state.updateExpSuccess = false;
    },
    updateExpSuccess(state, action) {
      state.updateExpLoading = false;
      state.updateExpError = "";
      state.updateExpSuccess = true;
    },
    updateExpFail(state, action) {
      state.updateExpLoading = false;
      state.updateExpError = action.payload;
      state.updateExpSuccess = false;
    },
    updateContactRequest(state) {
      state.updateContactLoading = true;
      state.updateContactError = "";
      state.updateContactSuccess = false;
    },
    updateContactSuccess(state, action) {
      state.updateContactLoading = false;
      state.updateContactError = "";
      state.updateContactSuccess = true;
    },
    updateContactFail(state, action) {
      state.updateContactLoading = false;
      state.updateContactError = action.payload;
      state.updateContactSuccess = false;
    },
    deleteExpRequest(state) {
      state.deleteExpLoading = true;
      state.deleteExpError = "";
      state.deleteExpSuccess = false;
    },
    deleteExpSuccess(state, action) {
      state.deleteExpLoading = false;
      state.deleteExpError = "";
      state.deleteExpSuccess = true;
    },
    deleteExpFail(state, action) {
      state.deleteExpLoading = false;
      state.deleteExpError = action.payload;
      state.deleteExpSuccess = false;
    },
    deleteContactRequest(state) {
      state.deleteContactLoading = true;
      state.deleteContactError = "";
      state.deleteContactSuccess = false;
    },
    deleteContactSuccess(state, action) {
      state.deleteContactLoading = false;
      state.deleteContactError = "";
      state.deleteContactSuccess = true;
    },
    deleteContactFail(state, action) {
      state.deleteContactLoading = false;
      state.deleteContactError = action.payload;
      state.deleteContactSuccess = false;
    },
  },
});

export const completeProfileActions = CompleteProfileSlice.actions;
export default CompleteProfileSlice;
