import { getAllNotifications } from "../../../Shared/Redux/Notification/NotificationActions";
import { axiosReq } from "../../../Utils";
import { updateUser } from "../Student/StudentActions";
import { StudentActions } from "../Student/StudentReducer";
import { UserActions } from "./UserReducer";

export const getUser = () => async (dispatch) => {
  dispatch(UserActions.getUserRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/user/me", {}, {})
  ).then((res) => {
    if (res.success) dispatch(UserActions.getUserSuccess(res.data));
    else dispatch(UserActions.getUserFail(res.data.response.data.message));
  });
};

export const getUserRole = () => async (dispatch) => {
  dispatch(UserActions.getUserRoleRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/user/me", {}, {})
  ).then((res) => {
    if (res.success) dispatch(UserActions.getUserRoleSuccess(res.data));
    else dispatch(UserActions.getUserRoleFail(res.data.response.data.message));
  });
};

export const createUser = (user, isGoogle, isFb, get) => async (dispatch) => {
  dispatch(UserActions.createUserRequest());
  let url;
  if (isGoogle) {
    url = process.env.REACT_APP_API + "/auth/google/signup";
  } else if (isFb) {
    url = process.env.REACT_APP_API + "/auth/facebook/signup";
  } else {
    url = process.env.REACT_APP_API + "/auth/signup";
  }
  dispatch(axiosReq("post", url, {}, user)).then((res) => {
    if (res.success) {
      dispatch(UserActions.createUserSuccess(res.data));
      if (isGoogle || isFb) {
        dispatch(getUser());
        dispatch(
          axiosReq(
            "patch",
            process.env.REACT_APP_API + "/user/me",
            {},
            { fcm_token: localStorage.getItem("fcm_token") }
          )
        );
        dispatch(getUser());
        dispatch(getAllNotifications());
      }
    } else dispatch(UserActions.createUserFail(res.data.response.data.message));
  });
};

export const loginUser = (user) => async (dispatch) => {
  dispatch(UserActions.loginUserRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/auth/login", {}, user)
  ).then((res) => {
    if (res.success) {
      dispatch(UserActions.loginUserSuccess(res.data));
      dispatch(getUser());
      dispatch(
        axiosReq(
          "patch",
          process.env.REACT_APP_API + "/user/me",
          {},
          { fcm_token: localStorage.getItem("fcm_token") }
        )
      );
      dispatch(getUser());
      dispatch(getAllNotifications());
    } else dispatch(UserActions.loginUserFail(res.data.response.data.message));
  });
};

export const phoneVerification = (user) => async (dispatch) => {
  dispatch(UserActions.phoneVerifyRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/user/requestPhoneVerification",
      {},
      user
    )
  ).then((res) => {
    if (res.success) dispatch(UserActions.phoneVerifySuccess(res.data));
    else dispatch(UserActions.phoneVerifyFail(res.data.response.data.message));
  });
};

export const verifyPhonetForget = (phone) => async (dispatch) => {
  dispatch(UserActions.verifyPhoneForgetRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/user/requestResetPassword",
      {},
      phone
    )
  ).then((res) => {
    if (res.success) dispatch(UserActions.verifyPhoneForgetSuccess(res.data));
    else
      dispatch(
        UserActions.verifyPhoneForgetFail(res.data.response.data.message)
      );
  });
};

export const otpVerify = (user) => async (dispatch) => {
  dispatch(UserActions.otpVerifyRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/user/verifyPhone", {}, user)
  ).then((res) => {
    if (res.success) dispatch(UserActions.otpVerifySuccess(res.data));
    else dispatch(UserActions.otpVerifyFail(res.data.response.data.message));
  });
};

export const otpVerifyForget = (phone) => async (dispatch) => {
  dispatch(UserActions.otpVerifyForgetRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/user/verifyResetPasswordOTP",
      {},
      phone
    )
  ).then((res) => {
    if (res.success) dispatch(UserActions.otpVerifyForgetSuccess(res.data));
    else
      dispatch(UserActions.otpVerifyForgetFail(res.data.response.data.message));
  });
};

export const resetPassword = (password) => async (dispatch) => {
  dispatch(UserActions.resetPasswordRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/user/resetPassword",
      {},
      password
    )
  ).then((res) => {
    if (res.success) dispatch(UserActions.resetPasswordSuccess(res.data));
    else
      dispatch(UserActions.resetPasswordFail(res.data.response.data.message));
  });
};

export const logoutUser = (navigate) => async (dispatch) => {
  dispatch(UserActions.logoutRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/user/me",
      {},
      { fcm_token: null }
    )
  ).then((res) => {
    dispatch(
      axiosReq("delete", process.env.REACT_APP_API + "/auth/logout", {})
    ).then((res) => {
      if (res.success) {
        dispatch(UserActions.logoutSuccess(res.data));
        dispatch(StudentActions.cleanStudent());
        navigate("/");
      } else dispatch(UserActions.logoutFail(res.data.response.data.message));
    });
  });
};

export const sendSupportEmail = (data) => async (dispatch) => {

  dispatch(UserActions.sendSupportEmailLoading())
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/user/contact-us", {}, data)
  ).then(res => {
    if(res.success) {
      dispatch(UserActions.sendSupportEmailSuccess())
    }
    else dispatch(UserActions.sendSupportEmailFailed())
  })
};
