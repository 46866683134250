const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  ////////Get All Applications////////
  Applications: [],
  getAllAppsLoading: false,
  getAllAppsSuccess: false,
  getAllAppsError: "",
};

const CounselorAppsSlice = createSlice({
  name: "Student",
  initialState,
  reducers: {
    ////////Get All Applications Reducer////////
    getAllAppsRequest(state) {
      state.getAllAppsLoading = true;
    },
    getAllAppsSuccess(state, action) {
      state.Applications = action.payload;
      state.getAllAppsLoading = false;
      state.getAllAppsSuccess = true;
      state.getAllAppsError = "";
    },
    getAllAppsFail(state, action) {
      state.Applications = [];
      state.getAllAppsLoading = false;
      state.getAllAppsSuccess = false;
      state.getAllAppsError = action.payload;
    },
  },
});
export const CounselorAplicationActions = CounselorAppsSlice.actions;
export default CounselorAppsSlice;
