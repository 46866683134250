import { axiosReq } from "../../../Utils";
import { StudentsActions } from "./StudentsReducers";

export const getstudents =
  (sortBy, limit, offset, search, filter) => async (dispatch) => {
    const url = process.env.REACT_APP_API + "/sales/students";

    dispatch(StudentsActions.GetStudents());
    dispatch(
      axiosReq(
        "get",
        url,
        {},
        {},
        {
          limit: limit,
          offset: offset,
          sortBy: sortBy,
          ...(sortBy === "archived" && { archived: true }),
          search: search,
          country: filter?.countries?.map((el) => el.label).join(","),
          education: filter?.education?.map((el) => el.label).join(","),
          budgetLow: filter?.budget?.value[0],
          budgetHigh: filter?.budget?.value[1] && filter?.budget?.value[1],
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(StudentsActions.GetStudentsSuccess(res.data));
      } else {
        dispatch(
          StudentsActions.GetStudentsFail(res.data.response.data.message)
        );
      }
    });
  };

export const getactiveCounselors = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/counselor/schedule/active";
  dispatch(StudentsActions.getactiveCounselors());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(StudentsActions.getactiveCounselorsSuccess(res.data));
    } else {
      dispatch(
        StudentsActions.getactiveCounselorsFail(res.data.response.data.message)
      );
    }
  });
};

export const SalesAssignCounselor = (sortBy, limit, offset, search, filter,data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/sales/students/assign";
  dispatch(StudentsActions.AssignCounselor());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(StudentsActions.AssignCounselorSuccess());
      dispatch(getstudents(sortBy, limit, offset, search, filter));
    } else {
      dispatch(
        StudentsActions.AssignCounselorFail(res.data.response.data.message)
      );
    }
  });
};
export const ArchiveStudents = (sortBy, limit, offset, search, filter,data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/sales/students/archive";
  dispatch(StudentsActions.ArchiveStudents());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(StudentsActions.ArchiveStudentsSuccess());
      dispatch(getstudents(sortBy, limit, offset, search, filter));
    } else {
      dispatch(
        StudentsActions.ArchiveStudentsFail(res.data.response.data.message)
      );
    }
  });
};

export const UnArchiveStudents = (sortBy, limit, offset, search, filter,data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/sales/students/unarchive";
  dispatch(StudentsActions.UnArchiveStudents());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(StudentsActions.UnArchiveStudentsSuccess());
      dispatch(getstudents(sortBy, limit, offset, search, filter));
    } else {
      dispatch(
        StudentsActions.UnArchiveStudentsFail(res.data.response.data.message)
      );
    }
  });
};

export const changestatus = (sortBy, limit, offset, search, filter,data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/student/status";
  dispatch(StudentsActions.ChangeStatus());
  dispatch(axiosReq("patch", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(StudentsActions.ChangeStatusSuccess());
      dispatch(getstudents(sortBy, limit, offset, search, filter));
    } else {
      dispatch(
        StudentsActions.ChangeStatusFail(res.data.response.data.message)
      );
    }
  });
};

export const GetSales = (limit, offset) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/user/sales";

  dispatch(StudentsActions.GetSalesRequest());
  dispatch(axiosReq("get", url, {}, {}, { limit: limit, offset: offset })).then(
    (res) => {
      if (res.success) dispatch(StudentsActions.GetSalesSuccess(res.data));
      else
        dispatch(StudentsActions.GetSalesFail(res.data.response.data.message));
    }
  );
};
