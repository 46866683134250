import React, { useState } from "react";
import "./ZoomMeeting.scss";
import greenCircle from "../../../Shared/Assets/Images/greenCircle.png";
import ZoomFrame from "../../../Shared/Components/ZoomFrame/ZoomFrame";
import Popup from "../../../Shared/Components/Popup/Popup";
import Button from "../../../Shared/Components/Button/Button";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";

function ZoomMeeting() {
  const [showPopup, setShowPopup] = useState(true);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [check, setCheck] = useState(true);

  return (
    <div className="zoomMeeting_container">
      <div className="zoomMeeting_title">Your Zoom Meeting</div>
      <div className="zoomMeeting_subtitle">
        <div className="box">
          <div className="box_inner">
            <div className="box_inner_left">
              <img
                src={greenCircle}
                alt="green circle"
                className="green_circle"
              />
              <div className="box_inner_left_text">
                <div className="box_inner_left_title">Topic</div>
                <div className="box_inner_left_body">Documentation</div>
              </div>
            </div>

            <div className="box_inner_right">
              <img
                src={greenCircle}
                alt="green circle"
                className="green_circle"
              />
              <div className="box_inner_left_text">
                <div className="box_inner_left_title">When</div>
                <div className="box_inner_left_body">February 24th, 2023</div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy_link">Copy Meeting Link</div>
      </div>
      <div className="zoom_frame">
        <ZoomFrame />
      </div>
      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          width={"22rem"}
          body={
            <div className="satisfied_popup">
              <div className="satisfied_title">
                Are you satisfied with your counselor?
              </div>
              <div className="satisfied_button">
                <Button
                  text="Yes"
                  color={"lightGreen"}
                  onClick={() => {
                    setShowPopup(false);
                  }}
                />
                <Button
                  text="No"
                  color={"pink"}
                  onClick={() => {
                    setShowPopup(false);
                    setShowSecondPopup(true);
                  }}
                />
              </div>
            </div>
          }
        />
      )}
      {showSecondPopup && (
        <Popup
          setShowPopup={setShowSecondPopup}
          width={"25rem"}
          body={
            <div className="feedback_container">
              <div className="feedback_title">
                Please provide us with more information
              </div>
              <textarea
                className="feedback_body"
                placeholder="Enter Your Feedback"
              ></textarea>
              <div className="feedback_bottom">
                <div className="feedback_bottom_left">
                  Do you want to be reassigned to a new counselor?
                </div>
                <div className="feedback_bottom_right">
                  <Checkbox
                    label="Yes"
                    selected={check ? true : false}
                    click={true}
                    onClick={() => {
                      setCheck(true);
                    }}
                  />
                  <Checkbox
                    label="No"
                    selected={check ? false : true}
                    click={true}
                    onClick={() => {
                      setCheck(false);
                    }}
                  />
                </div>
              </div>
              <Button text={"Send"} color={"lightGreen"} width={"100%"} />
            </div>
          }
        />
      )}
    </div>
  );
}

export default ZoomMeeting;
