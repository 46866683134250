import React from "react";
import "./Hexa.scss";
import HexaImg from "../../../Assets/Images/hexa.png";

function HexaAnimation() {
  return (
    <div className="Hexa_Container">
      <img src={HexaImg} alt="hexa" className="Hexa" />
    </div>
  );
}

export default HexaAnimation;
