import React, { useDebugValue, useEffect, useState } from "react";
import "./Application.scss";
import Info from "../../../Shared/Assets/Images/info.png";
import Arrow from "../../../Shared/Assets/Images/arrow2.webp";
import pinkCircle from "../../../Shared/Assets/Images/pinkCircle.png";
import blueCircle from "../../../Shared/Assets/Images/blueCircle.png";
import ArrowUp from "../../../Shared/Assets/Images/arrow_up_default.webp";
import ArrowDown from "../../../Shared/Assets/Images/arrow_down_default.webp";
import UniLogo from "../../../Shared/Assets/Images/uni_logo.webp";
import Popup from "../../../Shared/Components/Popup/Popup";
import { useNavigate } from "react-router-dom";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApps,
  getUnifiedApplication,
  getApplicationFeed,
} from "../../../Shared/Redux/Application/ApplicationAction";
import Loader from "../../../Shared/Components/Loader/Loader";
import { IdSplit } from "../../../Utils";
function App() {
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((store) => store.userReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.Student && user.Student?.id) {
      dispatch(getAllApps(user.Student.id));
    }
  }, [user]);
  useEffect(() => {
    dispatch(getApplicationFeed());
  }, []);
  const {
    AllApplications,
    getAllApplicationsLoading,
    unifiedApplication,
    unifiedApplicationLoading,
    ApplicationFeed,
  } = useSelector((store) => store.ApplicationsReducer);
  useEffect(() => {
    if (
      getAllApplicationsLoading ||
      !user ||
      !user.Student ||
      unifiedApplicationLoading ||
      Object.keys(user.Student).length == 0
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getAllApplicationsLoading, user, unifiedApplicationLoading]);

  useEffect(() => {
    dispatch(getUnifiedApplication());
  }, []);
  const [haveApp, setHaveApp] = useState(false);
  const [expandDetails, setExpandDetails] = useState(-1);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const innerWidth = UseInnerWidth();
  const dateConv = (string) => {
    const date = new Date(string);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return formattedDate;
  };

  return (
    <>
      {loading && <Loader fullScreen={true} />}
      <div className="AppContainer">
        <div className="App_Left">
          <div
            className={
              AllApplications.length > 0
                ? "AppLeft_UpperHaveApp"
                : "AppLeft_Upper"
            }
          >
            <div
              className={
                haveApp ? "AppLeft_UpperInnerHaveApp" : "AppLeft_UpperInner"
              }
            >
              <div className="AppUpperOuterDiv">
                {AllApplications.length > 0 ? (
                  <div className=" AppLeftUpper_HaveApp">
                    <div className="AppLeft_HaveAppTitle">
                      Your Applications
                    </div>
                    <div className="allApp_cont_div">
                      {AllApplications.map((Application, index) => {
                        return (
                          <div
                            className={
                              expandDetails == index
                                ? "AppHaveAppContainer extended"
                                : "AppHaveAppContainer"
                            }
                            onClick={() => {
                              expandDetails == index
                                ? setExpandDetails(-1)
                                : setExpandDetails(index);
                            }}
                          >
                            <div
                              className={
                                expandDetails == index
                                  ? " AppHaveAppInner AppHaveAppInnerExtened"
                                  : "AppHaveAppInner"
                              }
                            >
                              <div className="AppLeftHaveAppDetailsContainer">
                                <div
                                  className={
                                    expandDetails == index
                                      ? "AppLeftHaveAppDetailsSmallPhone"
                                      : "AppLeftHaveAppDetails"
                                  }
                                  key={index}
                                >
                                  {IdSplit(Application.id)}
                                </div>
                                <div
                                  className={
                                    expandDetails == index
                                      ? "AppLeftDetailsUniNameRemoved"
                                      : "AppLeftDetailsUniName"
                                  }
                                >
                                  {
                                    Application.major_degree.major.faculty
                                      .university.name
                                  }
                                </div>
                                <div className="AppHaveAppRightStatusArrow">
                                  <p
                                    className={
                                      Application.status === "Draft" ||
                                      Application.status === "Ready To Be Sent"
                                        ? "AppLeftAppDraft"
                                        : Application.status === "Submitted" ||
                                          Application.status === "Accepted" ||
                                          Application.status ===
                                            "Applying For Visa"
                                        ? "AppLeftAppSubmitted"
                                        : Application.status === "Rejected" ||
                                          Application.status ===
                                            "Missing Documents" ||
                                          Application.status === "Canceled"
                                        ? "AppLeftAppRejected"
                                        : "AppLeftAppOther"
                                    }
                                  >
                                    {Application.status == "Accepted"
                                      ? "Confirmed"
                                      : Application.status}
                                  </p>
                                  {expandDetails == index ? (
                                    <img
                                      className="AppLeftHaveAppArrow"
                                      src={ArrowUp}
                                    />
                                  ) : (
                                    <img
                                      className="AppLeftHaveAppArrow"
                                      src={ArrowDown}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            {expandDetails == index && (
                              <div
                                className="AppUniExtendedContainer"
                                onClick={() => {
                                  navigate(
                                    `/student/my_applications/` +
                                      AllApplications[index].id
                                  );
                                }}
                              >
                                <div className="AppUniExtendedContainerInner">
                                  <div className="AppUniExtendedImgName">
                                    <img
                                      src={
                                        process.env.REACT_APP_API +
                                        "/files/universities/" +
                                        Application.major_degree.major.faculty
                                          .university.logo
                                      }
                                      className="AppUniImg"
                                    />
                                    <div>
                                      <div
                                        className={
                                          expandDetails == index
                                            ? "AppLeftDetailsUniName AppLeftDetailsUniNameExpanded"
                                            : "AppLeftDetailsUniName"
                                        }
                                      >
                                        {
                                          Application.major_degree.major.faculty
                                            .university.name
                                        }
                                      </div>
                                      <div className="AppExtendedUniLocation">
                                        {
                                          Application.major_degree.major.faculty
                                            .university.city
                                        }
                                        ,
                                        {
                                          Application.major_degree.major.faculty
                                            .university.country
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="AppExtendedUniLowerContainer">
                                  <div className="AppExtendedUniLowerInner">
                                    <div>
                                      <div className="AppExtendedUniLowerInnerUpper">
                                        Applied Major
                                      </div>
                                      <div className="AppExtendedUniLowerInnerLower">
                                        {Application.major_degree.major.name}
                                      </div>
                                    </div>
                                    <div className="AppExtendedUniLowerContainerRight">
                                      <div className="AppExtendedUniLowerInnerUpper">
                                        Application Date
                                      </div>
                                      <div className="AppExtendedUniLowerInnerLower">
                                        {dateConv(Application.createdAt)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="AppLeft_NoApplyDiv">
                    <img className="AppInfoImage" src={Info} alt="Info" />
                    <p className="AppNoApply">
                      You Haven't Applied To Any Universities Yet!
                    </p>
                  </div>
                )}
              </div>
            </div>
            <hr className="LeftHr"></hr>
          </div>

          <div className="AppLeftLower">
            <div className="AppLeftLowerInner">
              <div
                className={
                  unifiedApplication &&
                  Object.keys(unifiedApplication).length > 0
                    ? "AppLeftLowerUnified"
                    : " AppLeftLowerUnified AppLeftLowerUnifiedNoApp"
                }
              >
                <div
                  className="AppLeftLowerUnifiedDiv"
                  onClick={() => {
                    navigate("/student/my_applications/unifiedApplication");
                  }}
                >
                  <p
                    className={
                      unifiedApplication &&
                      Object.keys(unifiedApplication).length > 0
                        ? "AppLeftLowerText"
                        : "AppLeftLowerText AppLeftLowerTextNoApp "
                    }
                  >
                    Finalize your unified application
                  </p>
                  <img className="AppLeftArrowImg" src={Arrow} />
                </div>
              </div>
              <div className="AppLeftLowerUnified">
                <div className="AppLeftLowerUnifiedDiv">
                  <p className="AppLeftLowerText">
                    Apply using career test recommendations
                  </p>
                  <img className="AppLeftArrowImg" src={Arrow} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="App_Right">
          <div className="App_RightInner">
            <div className="App_Feed_InnerText">
              <p
                className={
                  haveApp
                    ? "App_RightInnerText"
                    : "App_RightInnerText App_RightInnerTextNoApp"
                }
              >
                Application Feed
              </p>
              {innerWidth <= 900 && ApplicationFeed?.length > 0 && (
                <p
                  className="App_ShowMore"
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  Show more
                </p>
              )}
            </div>
            {showMore && (
              <Popup
                setShowPopup={setShowMore}
                showClose={true}
                body={
                  <>
                    <div className="App_Feed_InnerText">
                      <p
                        className={
                          haveApp
                            ? "App_RightInnerText"
                            : "App_RightInnerText App_RightInnerTextNoApp"
                        }
                      >
                        Application Feed
                      </p>
                    </div>
                    {ApplicationFeed.map((elem, key) => {
                      return (
                        <>
                          <div className="AppRightOneFeed" key={key}>
                            <div className="AppRightOneFeedLeftText">
                              <img className="App_CircleImg" src={blueCircle} />
                              <p className="AppRightText">
                                {elem.feed}&nbsp;
                                {/* <span className="AppRightNew">
                                  {elem.status}
                                </span> */}
                              </p>
                            </div>
                            <div className="AppRightDays">
                              <p className="AppRightDaysText">
                                {dateConv(elem.createdAt)}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                }
                width="80%"
              />
            )}
            {ApplicationFeed?.length > 0 ? (
              innerWidth <= 900 ? (
                <div className="AppRightOneFeed">
                  <div className="AppRightOneFeedLeftText">
                    <img className="App_CircleImg" src={blueCircle} />
                    <p className="AppRightText">
                      {ApplicationFeed[0]?.feed}&nbsp;
                      {/* <span className="AppRightNew">{ApplicationFeed[0].status}</span> */}
                    </p>
                  </div>
                  <div className="AppRightDays">
                    <p className="AppRightDaysText">
                      {dateConv(ApplicationFeed[0]?.createdAt)}
                    </p>
                  </div>
                </div>
              ) : (
                ApplicationFeed.map((elem, key) => {
                  return (
                    <div className="AppRightOneFeed" key={key}>
                      <div className="AppRightOneFeedLeftText">
                        <img className="App_CircleImg" src={blueCircle} />
                        <p className="AppRightText">
                          {elem.feed}&nbsp;
                          {/* <span className="AppRightNew">{elem.status}</span> */}
                        </p>
                      </div>
                      <div className="AppRightDays">
                        <p className="AppRightDaysText">
                          {dateConv(elem.createdAt)}
                        </p>
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              AllApplications.length === 0 &&
              innerWidth > 900 && (
                <div className="AppLeft_NoApplyDiv">
                  <img className="AppInfoImage" src={Info} alt="Info" />
                  <p className="AppNoApply">
                    You Haven't Applied To Any Universities Yet!
                  </p>
                </div>
              )
            )}
            {/* {innerWidth > 900 && ( */}
            <div className="AppRightNoMoreupdates">
              {ApplicationFeed?.length > 0 ? "No more updates" : "No Updates"}
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
