import React from "react";
import "./ApplyForm.scss";
import arrow from "../../Assets/Images/arrow1.webp";
import add from "../../Assets/Images/add.png";
import Loader from "../Loader/Loader";
function ApplyForm({
  title,
  subTitle,
  hasBack,
  hasPlus,
  body,
  back,
  plus,
  loading,
  noBg,
}) {
  return (
    <div
      className="applyForm_main_div"
      style={{ backgroundImage: noBg && "none" }}
    >
      <div className="applyForm_card_div">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="applyForm_card_div_main_header">
              <div className="applyForm_card_div_header">
                {hasBack && (
                  <img
                    src={arrow}
                    alt="back"
                    className="applyForm_headerIcon1"
                    onClick={() => back()}
                  />
                )}
                <h1 className="applyForm_card_title">{title}</h1>
                {hasPlus && (
                  <img
                    src={add}
                    alt="add"
                    className="applyForm_headerIcon2"
                    onClick={() => plus()}
                  />
                )}
              </div>
              <p className="applyForm_card_sub">{subTitle}</p>
            </div>
            <>{body}</>
          </>
        )}
      </div>
    </div>
  );
}

export default ApplyForm;
