import React, { useEffect, useState } from "react";
import "./UniCard.scss";
import location from "../../Assets/Images/location.webp";
import heart from "../../Assets/Images/Heart.png";
import redHeart from "../../Assets/Images/redheart.webp";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addFavorite,
  getAllUnisCatg,
} from "../../Redux/Universities/UniversityActions";
import { useDispatch } from "react-redux";

function UniCard(props) {
  //width of the card : width
  //image of the card : image
  //uni image : uniImage
  //uni name : uniName
  //uni location : uniLocation
  //uni description : uniDescription
  //favorite : favorite
  //student or counselor : isStudent
  //unniversity is fulll : full
  //uni id : id
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fav, setFav] = useState(false);
  useEffect(() => {
    setFav(props.favorite);
  }, [props.favorite]);

  const setFavourite = (e) => {
    e.stopPropagation();
    setFav(!fav);
    dispatch(addFavorite(props.id));
  };
  const { pathname } = useLocation();
  return (
    <div
      className={props.full ? "uniCard_main_div  full_uni" : "uniCard_main_div"}
      style={{ width: props.width }}
      onClick={() => {
        pathname.includes("counselor")
          ? navigate("/counselor/universities/singleUni/" + props.id)
          : pathname.includes("sales")
          ? navigate("/sales/universities/singleUni/" + props.id)
          : navigate("/student/universities/singleUni/" + props.id);
      }}
    >
      <img
        src={process.env.REACT_APP_API + "/files/universities/" + props.image}
        alt="uniImage"
        className="uniCard_image"
      />
      <div className="uniCard_content">
        <div className="uniCard_content_header">
          <div className="uniCard_content_header_title">
            <img
              src={
                process.env.REACT_APP_API +
                "/files/universities/" +
                props.uniImage
              }
              className="uniCard_content_header_logo"
              alt="uniLogo"
            />
            <h3 className="uniCard_uniName">{props.uniName}</h3>
          </div>
          <div className="uniCard_content_header_location">
            <img src={location} alt="location" className="uniCard_location" />
            <div className="uniCard_location_text">{props.uniLocation}</div>
          </div>
        </div>
        <div className="uniCard_content_body">
          <div className="uniCard_content_body_description">
            {props.uniDescription}
          </div>
          {props.isStudent && (
            <img
              onClick={(e) => setFavourite(e)}
              src={fav ? redHeart : heart}
              alt="favorite"
              className="uniCard_favorite"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default UniCard;
