/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Students: [],
  error: "",
  Counselors: [],
  loading: false,
  successloading: true,
  activesuccess: false,
  assignsuccess: false,
  archivesuccess: false,
  unarchicesuccess: false,
  changestatussuccess: false,
  Sales: [],
  getSalesLoading: false,
  getSalesError: "",
};

const StudentsSlice = createSlice({
  name: "Students",
  initialState,
  reducers: {
    GetStudents(state) {
      state.successloading = true;
      state.error = "";
    },
    GetStudentsSuccess(state, action) {
      state.successloading = false;
      state.Students = action.payload;
      state.error = "";
    },
    GetStudentsFail(state, action) {
      state.successloading = false;
      state.error = action.payload;
    },
    getactiveCounselors(state) {
      state.activesuccess = true;
      state.error = "";
    },
    getactiveCounselorsSuccess(state, action) {
      state.activesuccess = false;
      state.Counselors = action.payload;
      state.error = "";
    },
    getactiveCounselorsFail(state, action) {
      state.activesuccess = false;
      state.error = action.payload;
    },
    AssignCounselor(state) {
      state.assignsuccess = true;
      state.error = "";
    },
    AssignCounselorSuccess(state, action) {
      state.assignsuccess = false;
      state.error = "";
    },
    AssignCounselorFail(state, action) {
      state.assignsuccess = false;
      state.error = action.payload;
    },
    ArchiveStudents(state) {
      state.archivesuccess = true;
      state.error = "";
    },
    ArchiveStudentsSuccess(state, action) {
      state.archivesuccess = false;
      state.error = "";
    },
    ArchiveStudentsFail(state, action) {
      state.archivesuccess = false;
      state.error = action.payload;
    },
    UnArchiveStudents(state) {
      state.unarchicesuccess = true;
      state.error = "";
    },
    UnArchiveStudentsSuccess(state, action) {
      state.unarchicesuccess = false;
      state.error = "";
    },
    UnArchiveStudentsFail(state, action) {
      state.unarchicesuccess = false;
      state.error = action.payload;
    },
    ChangeStatus(state) {
      state.changestatussuccess = true;
      state.error = "";
    },
    ChangeStatusSuccess(state, action) {
      state.changestatussuccess = false;
      state.error = "";
    },
    ChangeStatusFail(state, action) {
      state.changestatussuccess = false;
      state.error = action.payload;
    },
    GetSalesRequest(state) {
      state.getSalesLoading = true;
      state.getSalesError = "";
      state.Sales = [];
    },
    GetSalesSuccess(state, action) {
      state.getSalesLoading = false;
      state.getSalesError = "";
      state.Sales = action.payload.data;
    },
    GetSalesFail(state, action) {
      state.getSalesLoading = false;
      state.getSalesError = action.payload;
      state.Sales = [];
    },
  },
});

export const StudentsActions = StudentsSlice.actions;
export default StudentsSlice;
