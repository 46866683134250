/** @format */
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Docs: [],
  fetchDocsLoading: false,
  fetchDocsSuccess: false,
  //delete doc
  deleteDocLoading: false,
  deleteDocSuccess: false,
  deletemsg: "",
  deleteDocFail: "",
  //missing doc
  missingDocLoading: false,
  missingDocSuccess: false,
  missingDocFail: "",
  MissingDocs: [],
};

const DocumentsSlice = createSlice({
  name: "Documents",
  initialState,
  reducers: {
    fetchDocsRequest(state) {
      state.fetchDocsLoading = true;
      state.fetchDocsSuccess = false;
    },
    fetchDocsSuccess(state, action) {
      state.fetchDocsLoading = false;
      state.Docs = action.payload;
      state.fetchDocsSuccess = true;
    },
    fetchDocsFail(state, action) {
      state.fetchDocsLoading = false;
      state.fetchDocsSuccess = false;
    },
    //delete doc
    deleteDocRequest(state) {
      state.fetchDocsLoading = true;
      state.deleteDocSuccess = false;
      state.deleteDocFail = "";
    },
    deleteDocSuccess(state, action) {
      state.fetchDocsLoading = false;
      state.deleteDocSuccess = true;
      state.deletemsg = action.payload;
    },
    deleteDocFail(state, action) {
      state.fetchDocsLoading = false;
      state.deleteDocSuccess = false;
      state.deleteDocFail = action.payload;
    },
    //missing doc
    missingDocRequest(state) {
      state.missingDocLoading = true;
      state.missingDocSuccess = false;
      state.missingDocFail = "";
      state.MissingDocs = [];
    },
    missingDocSuccess(state, action) {
      state.missingDocLoading = false;
      state.MissingDocs = action.payload;
      state.missingDocSuccess = true;
    },
    missingDocFail(state, action) {
      state.missingDocLoading = false;
      state.missingDocSuccess = false;
      state.missingDocFail = action.payload;
    },
    uploadmissingDocRequest(state) {
      state.missingDocLoading = true;
      state.missingDocSuccess = false;
      state.missingDocFail = "";
    },
    uploadmissingDocSuccess(state, action) {
      state.missingDocLoading = false;
      state.MissingDocs = action.payload;
      state.missingDocSuccess = true;
    },
    uploadmissingDocFail(state, action) {
      state.missingDocLoading = false;
      state.missingDocSuccess = false;
      state.missingDocFail = action.payload;
    },
  },
});

export const DocumentsActions = DocumentsSlice.actions;
export default DocumentsSlice;
