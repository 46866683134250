import React, { useEffect, useState } from "react";
import "./ApplicationStep3.scss";
import ApplyForm from "../../../Components/ApplyForm/ApplyForm";
import Button from "../../../Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import ApplicationStep4 from "../Application4/ApplicationStep4";
import UpperPopUp from "../../../Components/UpperPopUp/UpperPopUp";
import img from "../../../Assets/Images/checked.png";
import { useDispatch, useSelector } from "react-redux";
import { updateApplication } from "../../../Redux/CreateApplication/CreateApplicationAction";
import Loader from "../../../Components/Loader/Loader";
import { getSingleApp } from "../../../Redux/Application/ApplicationAction";
function ApplicationStep3({ counselor, step, setStep }) {
  const [hasGap, setHasGap] = useState(true);
  const dispatch = useDispatch();
  const [gapDetails, setGapDetails] = useState("");
  const [error, setError] = useState("");
  const [clicked, setClicked] = useState(false);
  const [showUpper, setShowUpper] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const {
    updateApplicationLoading,
    updateApplicationError,
    updateApplicationSuccess,
  } = useSelector((state) => state.CreateApplicationReducer);
  const {
    singleApplication,
    getSingleAppLoading,
    getSingleAppSuccess,
    getSingleAppError,
  } = useSelector((state) => state.ApplicationsReducer);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const application = {
      application_step: 4,
      gap_year: gapDetails ? gapDetails : "",
      has_gap_year: hasGap,
    };
    setClicked(true);
    dispatch(
      updateApplication(localStorage.getItem("applicationId"), application)
    );
  };

  useEffect(() => {
    if (updateApplicationSuccess && clicked && step == 3) {
      if(!counselor){
      navigate("/student/my_applications/apply/4");
      setStep(4);
      }
      else{
        navigate("/counselor/applications/create/4");

        setStep(4);
      }
    }
  }, [updateApplicationSuccess]);
  const goBackF = () => {
    if(!counselor){
    setStep(2);
    navigate("/student/my_applications/apply/2");
    }
    else{
      setStep(2);
      navigate("/counselor/applications/create/2");
    }
  };

  useEffect(() => {
    if (updateApplicationLoading) {
      setBtnLoad(true);
    } else setBtnLoad(false);
  }, [updateApplicationLoading]);

  useEffect(() => {
    if (localStorage.getItem("applicationId")) {
      dispatch(getSingleApp(localStorage.getItem("applicationId")));
    }
  }, []);

  useEffect(() => {
    if (
      singleApplication &&
      Object.keys(singleApplication).length > 0 &&
      localStorage.getItem("applicationId") &&
      singleApplication.has_gap_year != null
    ) {
      setGapDetails(singleApplication.gap_year);
      setHasGap(singleApplication.has_gap_year);
    } else if (
      !singleApplication ||
      !Object.keys(singleApplication).length == 0 ||
      !localStorage.getItem("applicationId")
    ) {
      setGapDetails("");
      setHasGap(true);
    }
  }, [singleApplication]);

  return (
    <div className="application_step2_mainDiv">
      {showUpper && (
        <UpperPopUp
          setShow={setShowUpper}
          title="Application progress saved"
          img={img}
          navigate={() => {
            counselor
            ? navigate("/counselor/applications/singleApp" + "/" + localStorage.getItem("applicationId"))

              : navigate("/student/my_applications");
          }}
        />
      )}
      {step == 3 ? (
        <ApplyForm
          title="Education Summary"
          subTitle="Please make sure your details are accurate and updated"
          hasBack={true}
          back={goBackF}
          hasPlus={false}
          body={
            <div className="application_step1_body">
              <div className="input_div">
                <div className="application_checkbox_title">
                  Did you go through a gap year?
                </div>
                <div className="application_checkbox">
                  <CheckBox
                    label="Yes"
                    selected={hasGap ? true : false}
                    click={true}
                    onClick={() => {
                      setHasGap(true);
                    }}
                  />
                  <CheckBox
                    label="No"
                    selected={hasGap ? false : true}
                    click={true}
                    onClick={() => {
                      setHasGap(false);
                      setGapDetails("");
                    }}
                  />
                </div>
              </div>
              {hasGap && (
                <textarea
                  className="hasGap_details"
                  placeholder="Please provide us with more details."
                  onChange={(e) => {
                    setGapDetails(e.target.value);
                  }}
                  value={gapDetails}
                ></textarea>
              )}
              {error && <div className="error">{error}</div>}
              <div className="application_step1_body_footer">
                <Button
                  text={btnLoad ? <Loader onlyDots={true} /> : "Continue"}
                  color="yellow"
                  width="100%"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={
                    (hasGap && gapDetails == "") || btnLoad ? true : false
                  }
                />
                <div
                  className="application_step1_body_footer_text"
                  onClick={() => setShowUpper(true)}
                >
                  Continue Later
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <ApplicationStep4 counselor={counselor} step={step} setStep={setStep} />
      )}
    </div>
  );
}

export default ApplicationStep3;
