import React from "react";
import Popup from "../../Components/Popup/Popup";
import "./TermsNConditions.scss";

function TermsNConditions({ setShow , hide}) {
  return (
    <div style={{ fontSize: "0.8rem" }}>
      <Popup
        setShowPopup={setShow}
        showClose={!hide}
        body={
          <div className="popup_main">
            <div className="popup_title_1">
              <h2>WHO ARE WE?</h2>
              <p>
                Education Basket Kuwait W.L.L and its corporate affiliates
                (“Education Basket” or “ We”, “Us”, “Our”) are committed to
                protecting your privacy and safeguarding your Personal Data
                through our compliance with these terms and conditions. We
                implement various data protection regulations and best practices
                prevalent in the industry for protecting Your information and
                ensuring that You can access and control them at any time. These
                terms and conditions provide detailed information on how
                EDUCATION BASKET collects information, together with the privacy
                and security practices it implements on the Product
              </p>
              <p>
                If the Use of Your Information changes, We will provide You with
                more information when we are in contact with You, for example,
                through the website, by email, or in your information materials
                on our Product. Where necessary, we would do that by updating
                our terms and conditions so that You can check it when You visit
                the Product at&nbsp;
                <a href="https://www.education-basket.com">
                  www.educationbasket.com
                </a>
              </p>
              <h2>ABOUT EDUCATION BASKET</h2>
              <p>
                Education Basket has built the world’s most comprehensive and
                intuitive Products which provide advanced technology solutions
                for financial institutions, government, and private sector
                organizations across a wide array of industry with demonstrated
                technology competencies and high-end skill capabilities that
                support critical business functions. If Education Basket is
                collecting information directly from you, then we will act as
                the Service Provider (defined hereunder). If you have any
                questions related to the handling of Your Personal Data by
                EDUCATION BASKET or to exercising Your rights to privacy You can
                contact us at{" "}
                <a href="mailto:info@educationbasket.com">
                  info@educationbasket.com
                </a>
              </p>
              <h2>DEFINITIONS</h2>
              <ul>
                <li>
                  <b>“Account”</b> means the self-service account created by the
                  Customer on Education Basket's Product for using the Services.
                </li>
                <li>
                  <b>“Customer Data”</b> means all data submitted, displayed,
                  and/ or uploaded by the End User and/or Authorized
                  Administrator(s) and/or the Customer while registering,
                  accessing, using Products, and/ or otherwise using the Site.
                </li>
                <li>
                  <b>“Applicable Data Protection Laws”</b> collectively means
                  all data protection laws and regulations, which includes
                  Kuwait's Data Privacy Protection Regulation issued by the
                  Communication and Information Technology Regulatory Authority,
                  and laws relating to data privacy in the countries of the
                  Middle East and North America Region.
                </li>
                <li>
                  <b>“Customer Personal Data”</b> means any Personal Data that
                  the Customer shares with or permits EDUCATION BASKET to
                  access, store, host, modify, share, delete, and further
                  Process for the performance of the Services, which is
                  processed by Education Basket and /or its Affiliates.
                </li>
                <li>
                  <b>“Personal Data”</b> means any information relating to a
                  Data Subject; an identifiable natural person is one who can be
                  identified, directly or indirectly, by reference to an
                  identifier such as a name, an identification number, location
                  data, an online identifier or to one or more factors specific
                  to the physical, physiological, genetic, mental, economic,
                  cultural or social identity of that natural person.
                </li>
                <li>
                  <b>“Data Protection Officer”</b> (DPO) means, where
                  applicable, an individual who are responsible for protection
                  of Personal Data at EDUCATION BASKET.
                </li>
                <li>
                  <b>“Data Subject”</b> or <b>“Data Owners”</b> means a natural
                  person whose Personal Data is processed by a Service Provider
                  or Data Processor.
                </li>
                <li>
                  <b>“Sub-processor”</b> or{" "}
                  <b>“Third-Party Service Provider”</b> means any person natural
                  or legal public authority, agency or body appointed by or on
                  behalf of the Service Provider.
                </li>
              </ul>
              <h2>INFORMATION THAT WE COLLECT ABOUT YOU</h2>
              <p>
                We collect information about You when You provide it to Us, when
                You Use our Services or Product, and when other sources provide
                it to Us. When connecting and/or navigating on our Product, we
                will collect information about You which will help Us in
                providing better Services as part of Education Basket business
                functioning
              </p>
              <p>
                Below, is a list of general categories of Personal Information
                we collect from You:
              </p>
              <h3>General categories of Personal Information</h3>
              <ol>
                <li>
                  <b>Contact information</b> - First name, last name, business
                  email address, phone number and company name.
                </li>
                <li>
                  <b>
                    Professional information for job vacancies/ career
                    applications
                  </b>{" "}
                  - Job title, CVs and resume, educational information,
                  professional qualifications, position, work experience,
                  background checks, professional networks, programs,
                  publications and activities, referrals and other relevant
                  professional information where needed.
                </li>
                <li>
                  <b>Customer person of contact Personal Data</b> - First Name,
                  last name, email address, official address, title,
                  designation, name of the organization, social media
                  credentials, if applicable, and payment details, if
                  applicable.
                </li>
                <li>
                  <b>Customer Account Creation </b> - First Name, last name,
                  email address, username or similar identifier, title or
                  designation, and name of the organization, if applicable.
                </li>
                <li>
                  <b>5. Information technology-related data</b> - The
                  information We may collect might originate from Your use of
                  EDUCATION BASKET's website, software and mobile applications,
                  and other connected devices and includes:
                  <ul>
                    <li>
                      Internet Protocol (IP) address, geolocation data, your
                      browser, operating systems, and device ID.
                    </li>
                    <li>
                      Data captured by cookies which may include analytics
                      information and information about the date and time of
                      Your request read our cookies section
                    </li>
                    <li>
                      Information that We may collect that describes and gives
                      information about other data ("metadata”). These terms and
                      conditions apply to the extent such metadata allows Us to
                      improve user experience. Where required under Applicable
                      Data Protection Laws, We will inform You and ask for Your
                      permission prior to collecting such Personal Information.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>De-identified and anonymized information</b> - We may Use
                  Personal information which is anonymized to improve our
                  services.
                </li>
                <li>
                  <b>
                    Integration of Sub-processors/ Third-Party Service
                    Provider(s)
                  </b>
                  - By proceeding with the integration with a Third-Party
                  Service Provider(s), You allow Us to collect Personal Data
                  that is already associated with the Third-Party Service
                  Provider(s). If You choose to provide such information and
                  Personal Data, You are giving Us the permission to Use, share,
                  and store it in a manner consistent with these terms and
                  conditions. We are not liable for any agreements that You have
                  with any Third-Party Service Provider(s).
                </li>
              </ol>
              <h2>
                Types of Personal Data that is collected by Education Basket
              </h2>
              <ol>
                <li>
                  <b>Profile and contact data:</b> We collect data such as First
                  and last name, Email address, Phone number, Mailing address,
                  social security number, passport number.
                </li>
                <li>
                  <b>Payment Data:</b> When you avail our Products and related
                  services, we collect payment information such as Bank account
                  information, Billing address, Billing phone number, Billing
                  email.
                </li>
                <li>
                  <b>Commercial Data:</b> We retain information about our
                  customers such as purchase history and customer profiles.
                </li>
                <li>
                  <b>Device/ IP data:</b> We collect data with respect to your
                  IP address, Device ID, Domain server, Type of device/operating
                  system/browser used to access the Website, Products and its
                  services.
                </li>
                <li>
                  <b>Web Analytics:</b> We collect data with respect to your
                  browsing or search history web page interactions (including
                  with ads), referring webpage/source through which users
                  accessed the Website, statistics associated with the
                  interaction between device or browser and the Website.
                </li>
                <li>
                  <b>Geological Data:</b> We collect data with respect to IP
                  address-based location information as well specific location
                  data.
                </li>
              </ol>
              <p>
                When connecting to our Product and using our Services, We will
                collect Personal Information about You, for the following
                purposes:
              </p>
              <h2>PURPOSE(S) FOR WHICH WE USE YOUR PERSONAL DATA</h2>
              <ol type="I">
                <li>
                  Providing, Customizing and Improving the Services:
                  <ul type="disc">
                    <li>
                      Providing You with the products, services or information
                      they request. Meeting or fulfilling the reason You
                      provided the information to the Company. Providing support
                      and assistance for the Services.
                    </li>
                    <li>
                      Improving the Services, including testing, research,
                      internal analytics and product development.
                    </li>
                    <li>
                      Personalizing the Services, website content and
                      communications based on Your preferences.
                    </li>
                    <li>Doing fraud protection, security and debugging.</li>
                    <li>
                      Creating and managing user accounts or other user
                      profiles, where applicable.
                    </li>
                    <li>
                      Processing orders or other transactions; billing, where
                      applicable.
                    </li>
                  </ul>
                </li>
                <li>
                  Marketing the Services:
                  <ul type="disc">
                    <li>Marketing and selling the Services.</li>
                    <li>
                      Showing You advertisements, including interest-based or
                      online behavioral advertising
                    </li>
                    <li>
                      To assist customers with product and service inquiries
                      This includes obtaining Personal Information before,
                      during and after We enter into a contract with You.
                    </li>
                  </ul>
                </li>
                <li>
                  Corresponding with You:
                  <ul type="disc">
                    <li>
                      Responding to correspondence from You, contacting You when
                      necessary or requested, and sending You information about
                      the Company or the Products and its services.
                    </li>
                    <li>
                      Sending emails and other communications according to user
                      preferences or that display content that you think will
                      interest You.
                    </li>
                  </ul>
                </li>
                <li>
                  Meeting Legal Requirements and Enforcing Legal Terms:
                  <ul type="disc">
                    <li>
                      Fulfilling the Company's legal obligations under
                      applicable law, regulation, court order or other legal
                      process, such as preventing, detecting and investigating
                      security incidents and potentially illegal or prohibited
                      activities.
                    </li>
                    <li>
                      Protecting the rights, property or safety of You, the
                      Company or another party. Enforcing any agreements with
                      You.
                    </li>
                    <li>
                      Responding to claims that any posting or other content
                      violates third-party rights. Resolving disputes.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                In addition to the above-mentioned purposes, We are also
                collecting Personal Information to provide You with a secure,
                smooth, efficient, and customized experience on the Product. We
                process Your Personal Information and non-Personal Information
                to create, develop, operate, deliver, and improve the Product
                and the Services. We will not collect any additional categories
                of Personal Information or Use the Personal Information
                collected by Us for materially different, unrelated, or
                incompatible purposes without seeking Your express consent.
              </p>
              <h2>LEGAL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION</h2>
              <p>
                These are the terms and conditions and they shall apply wherever
                You reside. In this section, We describe our legal
                justifications (commonly referred to as “legal basis”) for the
                Use of Your Personal Information related to each of our main
                processing activities.
              </p>
              <p>
                <b>Please Note:</b> depending on the country where You reside,
                the law of Your country may not require that We use a specific
                legal basis to justify Using Your Personal Information,
                including transfers of Your Personal Information outside Your
                country of residence. If Your jurisdiction requires consent to
                Process Your Personal Information when You interact with Us, We
                will obtain Your consent prior to the Use of such Personal
                Information.
              </p>
              <p>
                Below, We explain to You the EDUCATION BASKET's legal basis when
                processing Your Personal Information.
              </p>
              <ol>
                <li>
                  <b>Based on our contract with You</b> - As one of our
                  customers (when entering into a customer agreement to avail
                  the services of our Products), or business partner We will
                  keep and store Your personal or professional contact details
                  to interact, and provide You with related offers as a
                  customer, and manage the (pre-) contract or Your user account,
                  and for administrative or billing purposes
                </li>
                <li>
                  <b>
                    Based on Your prior consent (where permitted or required by
                    law upon)
                  </b>
                  -
                  <ul type="disc">
                    <li>
                      We collect information about You when navigating on our
                      sites and other features when You submit queries or
                      interact with Us
                    </li>
                    <li>
                      We Use Your information in the context of marketing and
                      sales to propose You similar offers or inform You about
                      upcoming products and send newsletters; or for statistics
                      purposes. Depending on Your country of residence, We may
                      have to request Your prior consent for example:
                      <ul type="circle">
                        <li>When subscribing to our newsletters</li>
                        <li>
                          Sharing or disclosing Your Personal Data to
                          Sup-processors outside of Your country of residence in
                          countries that do not provide the same level of
                          protection to Your privacy and data protection as in
                          Your country of residence
                        </li>
                        <li>
                          Using forms, when creating Your user account; or when
                          using cookies or similar technologies.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>To comply with Applicable Data Protection laws</b> and
                  other legal statutes- In some limited instances, We may have
                  to keep some limited Personal Information about You longer
                  than needed such as for tax or accounting purposes.
                </li>
              </ol>
              <h2>RETENTION OF PERSONAL DATA</h2>
              <p>
                Education Basket will retain Your Personal Data only for as long
                as is necessary for the purposes set out in these terms and
                conditions.
              </p>
              <p>
                For You who have provided your Personal Data to us on our
                Product, We will keep Personal Data We collect about You for as
                long as necessary for providing the Services via our Product and
                to comply with any legal obligations (e.g:: to comply with
                applicable legal, tax or accounting requirements and for
                archiving purposes).
              </p>
              <p>
                Education Basket shall retain the Customer Personal Data for 3
                years from the date of termination of the agreement(s) with the
                Customers solely for repurposing and/or reusing the Personal
                Data for any future requirements by its customers. Education
                Basket shall not use this data for any purpose apart from
                retaining it for the Customer. Post completion of the above
                mentioned retention period, we shall automatically delete all
                data provided by the Customer from the our database.
              </p>
              <p>
                Below you can find details about data retention, legal basis,
                and purpose for each category of Personal Data{" "}
              </p>
              <h3>Product User data including name:</h3>
              <ul type="disc">
                <li>
                  Purpose: To provide and improve Education Basket Products and
                  Services.
                </li>
                <li>
                  Legal basis: Consent (cookies, sending newsletters, for
                  providing demos) and Legitimate Interest (to respond to your
                  queries about Education Basket product and services).
                </li>
              </ul>
              <ol>
                <li>
                  <b>Customer data:</b>
                  <ul type="disc">
                    <li>
                      Purpose: To interact, and provide You with related offers
                      as a Customer, and manage the (pre-) contract or Your user
                      account, and for administrative or billing purposes.
                    </li>
                    <li>
                      Legal basis: Contract (to provide products and services
                      for fulfillment, administrative, or billing purposes) and
                      the legitimate interest of Education Basket and Consent.
                    </li>
                  </ul>
                </li>
              </ol>
              <h2>Data retention and deletion</h2>
              <p>
                Where We have no legitimate business interest to continue to
                Process Your Personal Data or if You ask Us for deletion, we
                will either delete, anonymize it or, if this is not possible
                (for example, if Personal Data has been stored in secured
                archives), We will securely store and isolate Your information
                from any further processing until the deletion becomes possible
                and delete it as soon as technically possible. We will use
                advanced technology that is certified as best (practices) in the
                industry or other means to protect Your data and mitigate any
                risks, such as obfuscation, blanking, or encryption.{" "}
              </p>
              <p>
                We will retain the data for 3 years post expiration of the
                contract unless the User requests Us to delete the data at an
                earlier date. Post expiration of the retention period, all data
                will be automatically deleted by Us from all EDUCATION BASKET
                databases, including Third-Party Service Providers database(s).
              </p>
              <h2>WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h2>
              <h3>Education Basket Employees</h3>
              <p>
                Only duly authorized Education Basket employees can access Your
                Personal Data on a need-to-know basis.
              </p>
              <h3>
                Disclosure of Personal Data to Third-Party Service Providers
              </h3>
              <p>
                In certain limited cases, authorized Third-Party Service
                Providers outside our organization may access Your Personal
                Data. These may include:
              </p>
              <ul>
                <li>
                  For our internal business purposes that include administering
                  access and Use of our Products and Services, data analysis,
                  securely identifying Customers upon logging onto a Product,
                  enhancing or modifying our Services, determining the
                  effectiveness of our marketing campaigns, billing for
                  Services, and operating our business.
                </li>
                <li>
                  Other Third-Party Service Providers, such as auditors, and
                  data centers or to prevent, detect, mitigate, and investigate
                  fraudulent or illegal activities related to our Services and
                  other authorized Third-Party Service Providers only when
                  required by law or by a court decision, to defend legal claims
                  or in case of an investigation by a supervisory authority.
                </li>
              </ul>
              <p>
                You expressly consent to the disclosure of Your Personal Data to
                the relevant Third Party Service Providers whose services
                Education Basket uses for the following in-exhaustive list of
                services, such as marketing, analysis, advertisement, contract
                management, billing, consent management, data subject rights
                management, cloud service management.{" "}
              </p>
              <p>
                When engaging Third Party Service Providers, we have entered
                into agreements with them for the processing of Your Personal
                Data about You so that such processing is carried out in
                accordance with our instructions, in a confidential, secure,
                transparent manner, to protect Your privacy rights and comply
                with the Applicable Data Protection Laws.
              </p>
              <h2>COOKIES AND TRACKING TECHNOLOGIES THAT WE USE</h2>
              <p>
                Cookies or similar tracking technologies may be used on the site
                to automatically collect certain information about Your device.
                We usually do not collect Your Personal Data, however, should it
                happen, this may include Personal Data as explained in this
                section. For more details on the cookies We Use, refer to our
                terms and conditions.
              </p>
              <p>
                <b>Disclaimer</b> - We are not including cookie policies
                (dashboard and community) in these terms and conditions as it
                pertains to a different website.
              </p>
              <p>
                Where appropriate, You can also read how tracking technologies
                work on other websites You use by accessing the respective
                cookie notice.
              </p>
              <h2>UNSUBSCRIBE AND WITHDRAW OF CONSENT</h2>
              <h3>Unsubscribe from our Newsletter/ Updates</h3>
              <p>
                You always have the opportunity to easily unsubscribe, at any
                time, from our marketing communications. You can achieve this by
                using the "unsubscribe" link in our communications or by
                contacting Us using email.
              </p>
              <h3>Withdrawal of consent</h3>
              <p>
                Where we processes Your Personal Data on the basis of consent,
                You have the right and may choose to withdraw Your consent at
                any point in time. Kindly notify us of such withdrawal by
                communicating to Us using email.
              </p>
              <p>
                In case You do not wish to provide Your consent or later
                withdraw Your consent, We request You not to access the Product
                and use our Services We also reserve the right to not provide
                You with any Services or information on the Product. On such
                instances wherein You have withdrawn Your consent, we may delete
                Your information (personal or otherwise) or de-identify it so
                that it is anonymous and not attributable to You (except to the
                extent, as detailed in " LEGAL BASIS FOR PROCESSING YOUR
                INFORMATION" of these terms and conditions).
              </p>
              <h2>WE PROTECT YOUR INFORMATION</h2>
              <p>
                We implement appropriate technical and organizational controls
                to protect Your Personal Data that We hold to prevent
                unauthorized Processing, loss of data, disclosure, use,
                alteration, or destruction. Where appropriate, We may Use
                protective techniques such as encryption, pseudonymization,
                de-identification, and other technologies that can assist Us in
                securing the information about You, including measures to
                restore access to Your Personal Information. We also ensure that
                our Third-Party Service Providers comply with reasonable and
                recognized data privacy, confidentiality, integrity,
                availability, and security requirements.{" "}
              </p>
              <p>
                We conduct tests and reviews of our technologies and processes,
                including a review of our business partners and vendors, so that
                our security controls remain effective. Also, when not needed
                anymore, We will further anonymize Your Personal Data or delete
                it when it is no longer needed for the purpose for which We
                originally collected such Personal Information from You.
              </p>
              <p>
                Where We Use Third-Party Service Providers to help us with
                information security measures, they have committed to comply
                with strict security, data protection requirements, and privacy
                requirements that are not just best practices in the industry
                but to comply with Applicable Data Protection Laws to ensure
                maximum confidentiality, integrity, and availability of Your
                Personal Data.
              </p>
              <h2>CROSS-BORDER TRANSFERS OF DATA</h2>
              <p>
                When we receive information about You, such as for applying to
                Education Basket or exchanging information with Us, We will
                collect, process, and store Your Personal Data according to
                Applicable Data Protection Laws and in accordance with these
                terms and conditions. Most of the time, you will share such
                Personal Data with Us, via our Product, forms, and e-mails,
                voluntarily, for the purpose of entering into a contract with us
                or based on Your explicit consent.{" "}
              </p>
              <p>
                When You exchange information with other Third-Party Service
                Providers, You acknowledge that Personal Data about You or other
                data subjects may leave Your country of residence, which may
                require implementing appropriate/ supplementary or additional
                safeguards in accordance with Applicable Data Protection Laws.
              </p>
              <p>
                Kindly note that Education Basket will follow the appropriate
                level of protection and deploy all necessary safeguards for the
                transfer of such data that constitutes confidential information
                and/or Personal Data, additionally such transfers, to any
                country(s) which do not ensure an adequate level of data
                protection, we will ensure that the transfer is through one best
                mechanism possible under the law. Links to other websites.
              </p>
              <h2>WHAT ELSE DO YOU NEED TO KNOW?</h2>
              <ol>
                <li>
                  <b>Links to Third Parties</b>
                  <br />
                  Our website may contain links to other sites that are not
                  operated by Us. If You click on a third-party link, You will
                  be directed to that third-party's site. We strongly advise You
                  to review the terms and conditions of every website You visit.
                  We have no control over and assume no responsibility for the
                  content, terms and conditions, or practices of any third-party
                  sites or services.
                </li>
                <li>
                  <b>Children's Privacy</b>
                  <br />
                  Our Service does not address anyone under the age of 18
                  (“Children“). We do not knowingly collect Personal Information
                  from anyone under the age of 18 on a voluntary basis. If You
                  are a parent or guardian and You are aware that Your child has
                  provided Us with Personal Information, please contact Us. If
                  We become aware that We have collected Personal Information
                  from children without verification of parental, judicial, or
                  guardian consent, We take steps to remove that information
                  from our data servers.
                </li>
                <li>
                  <b>Personal Data breach and incidents </b>
                  <br />
                  We have procedures and safeguards in place to identify,
                  assess, investigate, and report Personal Data breaches at the
                  earliest possible time. Our procedures are robust and have
                  been disseminated to our staff who are regularly trained and
                  informed about industry-certified best IT security practices.
                  We also ensure the confidentiality, integrity, and
                  accessibility of Your Personal Data at all times. For further
                  information on Education Basket Personal Data breach
                  notification and communication kindly{" "}
                  <a href="mailto:info@educationbasket.com">click here.</a>
                </li>
              </ol>
              <h2 style={{ textAlign: "center" }}>YOUR RIGHTS</h2>
              <p>
                As a User of our Services and Product, and depending on Your
                country of residence, You may have the right to exercise your
                rights and/ or file a complaint to competent data protection
                authority/ Supervisory authority.
              </p>
              <p>
                <b>Access, Restrict, Rectification, Erasure</b>
                <br />
                Under Applicable Data Protection Laws, You have the right to
                control Your data You may request a copy of the information
                about You held by Us. You also have the right to rectify,
                restrict, or erase/delete such information. Your right(s) to
                such information may be subject to limited legal and regulatory
                restrictions.{" "}
              </p>
              <p>
                <b>Objection to processing and additional rights</b>
                <br />
                Under Applicable Data Protection law, You can formally object to
                the processing of Your Personal Data. In certain circumstances,
                You have the additional right to restrict or suspend aspects of
                the processing of Your information or ask for a copy of Your
                Personal data to be provided to You, or a Third-Party Service
                Provider, in a digital and machine-readable format
                (portability).
              </p>
              <p>
                <b>Objection to direct marketing</b>
                <br />
                You have the right to object to certain processing of Your
                information. This includes the right to object to our processing
                of Your information for direct marketing. If We Process Our
                information based on our legitimate interest(s) You can object
                to this processing, and We will cease processing Your
                information unless the processing is based on compelling
                legitimate grounds or is needed for legal reasons. Where We Use
                Your information for direct marketing for our services, You can
                always object and opt out of future marketing messages using the
                unsubscribe link provided in such communications.
              </p>
              <p>
                <b>Country- Specific Rights</b>
                <br />
                Since we also operate out of Kuwait, please know that we abide
                by the privacy laws of the country of which you are a resident.
              </p>
              <i>
                We may update these terms and conditions from time to time. You
                are advised to review the updated terms and conditions
                periodically for any changes. Changes to these terms and
                conditions are effective when they are posted on this page and
                any modifications can be ascertained by referring to the date
                that is displayed at the top of the page marked as, “Last
                updated”.
              </i>
              <p>
                We provide easily accessible information via our Product or on
                request. If You have any questions or requests related to these
                terms and conditions or Your Personal Information, please
                contact us at the following contact details
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default TermsNConditions;
