import React, { useState, useEffect } from "react";
import "./SalesConselorPage.scss";
import Button from "../../../Shared/Components/Button/Button";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import arrow_down from "../../../Shared/Assets/Images/arrow_down.svg";
import Popup from "../../../Shared/Components/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  getstats,
  getallcouselors,
  assignstudents,
  getstudentsall,
} from "../../Redux/SalesCounselor/SalesCounselorActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
function SalesConselorPage() {
  const dispatch = useDispatch();

  const {
    Stats,
    statssuccess,
    allcounssuccess,
    assignsuccess,
    getstudentsuccess,
    Counselors,
    Students,
  } = useSelector((state) => state.SalesCounselorReducer);

  const [ShowPop, setShowPop] = useState(false);

  const [fromDate, setFromDate] = useState(
    new Date().getFullYear() +
      "-" +
      new Date().getMonth() +
      "-" +
      new Date().getDate()
  );

  const [toDate, setToDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate()
  );

  const findStat = () => {
    if (new Date(fromDate) && new Date(toDate)) {
      dispatch(
        getstats(
          new Date(fromDate).getFullYear() +
            "-" +
            (new Date(fromDate).getMonth() + 1) +
            "-" +
            new Date(fromDate).getDate(),
          new Date(toDate).getFullYear() +
            "-" +
            (new Date(toDate).getMonth() + 1) +
            "-" +
            new Date(toDate).getDate()
        )
      );
    }
  };

  ///dropdown data in popup
  const [selectedStudents, setselectedStudents] = useState([]);
  const [selectedCounselors, setselectedCounselors] = useState([]);

  const [categoryColors, setCategoryColors] = useState({});
  const generateCategoryColors = () => {
    const colors = {};
    const colorClasses = [
      "SalesTable_pink",
      "SalesTable_green",
      "SalesTable_yellow",
      "SalesTable_blue",
      "SalesTable_grey",
      "SalesTable_white",
    ];
    let colorIndex = 0;

    Stats.forEach((item) => {
      const categoryKey = item.name;
      if (!colors[categoryKey]) {
        colors[categoryKey] = colorClasses[colorIndex];
        colorIndex = (colorIndex + 1) % colorClasses.length;
      }
    });

    setCategoryColors(colors);
  };

  useEffect(() => {
    dispatch(getallcouselors());
    dispatch(getstudentsall());
    generateCategoryColors();
    dispatch(
      getstats(
        new Date().getFullYear() +
          "-" +
          new Date().getMonth() +
          "-" +
          new Date().getDate(),
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate()
      )
    );
  }, []);
  useEffect(() => {
    generateCategoryColors();
  }, [Counselors, Stats]);

  const handleassign = () => {
    const temp = {
      students: selectedStudents.map((el) => {
        return { student: el.value, user: el.userId };
      }),

      counselor: selectedCounselors.value,
    };

    dispatch(assignstudents(temp));
    setselectedCounselors([]);
    setselectedStudents([]);
    setShowPop(false);
  };

  return (
    <>
      {(statssuccess ||
        allcounssuccess ||
        assignsuccess ||
        getstudentsuccess) && <Loader fullScreen={true} />}
      <div className="Main_conselor_sales_page">
        <div className="Main_conselor_sales_page_container">
          <div className="titles_cont_sales2"> Counselor</div>

          <div className="sales_head_cont">
            <div className="sales_sub_head_cont">
              <div className="profile_input_top">
                <div className="input_title">From</div>
                <DateInput
                  selectedDate={fromDate}
                  setSelectedDate={setFromDate}
                  maxDate={new Date()}
                />
              </div>
              <div className="profile_input_top ">
                <div className="input_title">To</div>
                <DateInput
                  selectedDate={toDate}
                  setSelectedDate={setToDate}
                  minDate={fromDate && fromDate}
                  maxDate={new Date()}
                />
              </div>
              <Button
                text="Find"
                color="pink"
                width="fit-content"
                onClick={() => {
                  findStat();
                }}
              />
            </div>

            <div className="buttons_sales_cont">
              <Button
                text="Assign student"
                color="pink"
                width="fit-content"
                onClick={() => {
                  setShowPop(!ShowPop);
                }}
              />
            </div>
          </div>
          <div className="titles_cont_sales">Students Per Counselor</div>

          {Stats?.length !== 0 ? (
            <div className="table_sales_cont">
              <table className="SalesTable_main_div">
                <tr className="userTable_header">
                  <th>Student's Category</th>
                  <th>Student's Source</th>
                  {Counselors.length !== 0 &&
                    Counselors.map((el, index) => (
                      <th key={index}>
                        {el.first_name} {el.last_name}
                      </th>
                    ))}
                  <th>Total</th>
                  <th>Total Category</th>
                </tr>
                {Stats?.length > 0 &&
                  Stats.map((item, index1) => {
                    return item.data.map((el, index2) => {
                      const categoryColor = categoryColors[item.name];

                      return (
                        <>
                          <tr className={`userTable_details ${categoryColor} `}>
                            <td>{item.name}</td>

                            <td>{el.name}</td>
                            {el.counselors.length > 0
                              ? Counselors.map((couns, index) => {
                                  return (
                                    <td key={index}>
                                      {el.counselors[
                                        el.counselors.findIndex(
                                          (counselor) =>
                                            counselor.id === couns.id
                                        )
                                      ]
                                        ? el.counselors[
                                            el.counselors.findIndex(
                                              (counselor) =>
                                                counselor.id === couns.id
                                            )
                                          ].studentCount
                                        : 0}
                                    </td>
                                  );
                                })
                              : Counselors.map((couns, index) => {
                                  return <td key={index}>0</td>;
                                })}

                            <td>{el.total_source}</td>

                            {index2 === 0 && (
                              <td rowSpan={item.data.length}>
                                {item.total_category}
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    });
                  })}
              </table>
            </div>
          ) : (
            <div className="title_lead">No Data Found</div>
          )}
        </div>
        <div className="Main_conselor_sales_page_container">
          <div className="sales_head_cont">
            <div className="titles_cont_sales">Meetings Per Counselor</div>
          </div>
          {Stats?.length != 0 ? (
            <div className="table_sales_cont">
              <table className="SalesTable_main_div">
                <tr className="userTable_header">
                  <th>Student's Category</th>
                  <th>Student's Source</th>
                  {Counselors.length !== 0 &&
                    Counselors.map((el, index) => (
                      <th key={index}>
                        {el.first_name} {el.last_name}{" "}
                      </th>
                    ))}
                  <th>Total</th>
                  <th>Total Category</th>
                </tr>
                {Stats?.length > 0 &&
                  Stats.map((item, i) => {
                    return item.data.map((el, index3) => {
                      const categoryColor = categoryColors[item.name];
                      return (
                        <>
                          <tr className={`userTable_details ${categoryColor}`}>
                            <td>{item.name}</td>

                            <td>{el.name}</td>
                            {el.counselors.length > 0
                              ? Counselors.map((couns, index) => {
                                  return (
                                    <td>
                                      {el.counselors[
                                        el.counselors.findIndex(
                                          (counselor) =>
                                            counselor.id === couns.id
                                        )
                                      ]
                                        ? el.counselors[
                                            el.counselors.findIndex(
                                              (counselor) =>
                                                counselor.id === couns.id
                                            )
                                          ].meetingsCount
                                        : 0}
                                    </td>
                                  );
                                })
                              : Counselors.map((couns, index) => {
                                  return <td>0</td>;
                                })}

                            <td>{el.total_meetings}</td>
                            {index3 == 0 && (
                              <td rowSpan={item.data.length}>
                                {item.total_category_meetings}
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    });
                  })}
              </table>
            </div>
          ) : (
            <div className="title_lead">No Data Found</div>
          )}
        </div>
        {ShowPop && (
          <Popup
            setShowPopup={setShowPop}
            body={
              <div className="popup_body">
                <div className="popup_body_title">
                  Assign Counselor to Students
                </div>
                <div className="popup_body_content">
                  <div className="profile_input_top">
                    <div className="input_title">Choose Counselor</div>
                    <DropDown
                      searchable={true}
                      setSelected={setselectedCounselors}
                      data={Counselors.map((el) => {
                        return {
                          value: el.id,
                          label: el.first_name + " " + el.last_name,
                        };
                      })}
                      placeholder={"Choose Counselor"}
                      blueArrow={true}
                      multiSelection={false}
                      selectedData={selectedCounselors}
                      keepPlaceHolder={false}
                      value={selectedCounselors}
                      width={"100%"}
                    />
                  </div>
                  <div className="profile_input_top">
                    <div className="input_title">Choose Student</div>
                    <DropDown
                      placeholder="Choose Student "
                      setSelected={setselectedStudents}
                      data={Students.map((el) => {
                        return {
                          value: el.id,
                          label: el.user.first_name + " " + el.user.last_name,
                          userId: el.user.id,
                        };
                      })}
                      blueArrow={true}
                      width="100%"
                      multiSelection={true}
                      isClearable={false}
                      searchable={true}
                      keepPlaceHolder={true}
                      isDisabled={false}
                      selectedData={selectedStudents}
                    />
                  </div>
                  <div className="popup_buttons">
                    <Button
                      text="Assign"
                      color="yellow"
                      width="100%"
                      disabled={
                        selectedStudents.length !== 0 &&
                        selectedCounselors.length !== 0
                          ? false
                          : true
                      }
                      onClick={() => {
                        handleassign();
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  );
}

export default SalesConselorPage;
