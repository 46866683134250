const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  FiltersDegrees: [],
  getFiltersDegreesLoading: false,
  getFiltersDegreesSuccess: false,
  getFiltersDegreesFail: "",
};

const FiltersDegreesSlice = createSlice({
  name: "FiltersDegrees",
  initialState,
  reducers: {
    getFiltersDegreesRequest(state) {
      state.FiltersDegrees = [];
      state.getFiltersDegreesLoading = true;
      state.getFiltersDegreesSuccess = false;
      state.getFiltersDegreesFail = false;
    },
    getFiltersDegreesSuccess(state, action) {
      state.FiltersDegrees = action.payload;
      state.getFiltersDegreesLoading = false;
      state.getFiltersDegreesSuccess = true;
      state.getFiltersDegreesFail = "";
    },

    getCountriesError(state, action) {
      state.Countries = [];
      state.getCountriesSuccess = false;
      state.getCountriesError = action.payload;
      state.getCountriesLoading = false;
    },
    getFiltersDegreesFail(state, action) {
      state.FiltersDegrees = [];
      state.getFiltersDegreesLoading = false;
      state.getFiltersDegreesSuccess = false;
      state.getFiltersDegreesFail = action.payload;
    },
  },
});

export const FiltersDegreesActions = FiltersDegreesSlice.actions;
export default FiltersDegreesSlice;
