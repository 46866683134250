import React, { useEffect, useState } from "react";
import "./CRM.scss";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { getcrmstatistics, getSalesSources } from "../../Redux/Crm/CrmActions";
import Loader from "../../../Shared/Components/Loader/Loader";

function CRM() {
  const dispatch = useDispatch();
  const { CrmStats, loading, Sources } = useSelector(
    (state) => state.CrmReducer
  );

  // const [selectedYear, setSelectedYear] = useState();
  const [leadYear, setLeadYear] = useState();
  const [leadMonth, setLeadMonth] = useState();

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 10;
  const maxYear = currentYear;

  const yearRange = Array.from(
    { length: maxYear - minYear + 1 },
    (_, index) => minYear + index
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    setSelectedYear({
      label: new Date().getFullYear(),
      value: new Date().getFullYear(),
    });
    setLeadYear({
      label: new Date().getFullYear(),
      value: new Date().getFullYear(),
    });
    setLeadMonth({
      label: new Date().toLocaleString("default", { month: "long" }),
      value: new Date().toLocaleString("default", { month: "long" }),
    });
  }, []);
  useEffect(() => {
    if (selectedYear?.value) dispatch(getcrmstatistics(selectedYear?.value));
  }, [selectedYear]);
  const [datss, setdatss] = useState([]);
  useEffect(() => {
    dispatch(getSalesSources());
    if (CrmStats.data?.length > 0) {
      const data = CrmStats.data;
      const totalCounts = countDataProperties(data);
      setdatss(totalCounts);
    }
  }, []);
  const joinedArray = Sources.map((data) => {
    const matchingSource = CrmStats.sources?.find(
      (source) => source.id === data.id
    );
    const apps = matchingSource ? matchingSource.apps : 0;
    const percent = matchingSource ? matchingSource.percent : 0;
    const name = data.name;

    return {
      name,
      apps,
      percent,
    };
  });
  const countDataProperties = (data) => {
    let totalCounts = {
      target: 0,
      confirmed: 0,
      canceled: 0,
      cancelationRate: 0,
      onGoing: 0,
      percentage_of_applications: 0,
    };

    data.forEach((item) => {
      totalCounts.target += item.target;
      totalCounts.confirmed += item.confirmed;
      totalCounts.canceled += item.canceled;
      totalCounts.onGoing += item.onGoing;
      totalCounts.cancelationRate += item.cancelationRate;
      totalCounts.percentage_of_applications += item.percentage_of_applications;
    });

    // Ensure percentage is capped at 100% and rounded to 2 decimal places
    totalCounts.percentage_of_applications = Math.min(
      totalCounts.percentage_of_applications,
      100
    ).toFixed(2);
    totalCounts.cancelationRate = Math.min(
      totalCounts.cancelationRate,
      100
    ).toFixed(2);

    return totalCounts;
  };
  return (
    <>
      {loading && <Loader fullScreen={true} />}
      <div className="CRM_main">
        <div className="yearly_target">
          <div className="yearly_target_inner">
            <div className="yearly_target_top">
              <div className="yearly_target_title">Yearly Target</div>
              <DropDown
                placeholder="Choose year"
                setSelected={setSelectedYear}
                data={yearRange.map((item) => {
                  return { label: item, value: item };
                })}
                blueArrow={true}
                width={"13rem"}
                multiSelection={false}
                searchable={false}
                selectedData={setSelectedYear}
                value={selectedYear}
              />
            </div>
            <div className="yearly_target_middle">
              <div className="yearly_target_middle_title">
                Application Management
              </div>
              {CrmStats?.data?.length == 0 ? (
                <div className="title_lead">No Data Found</div>
              ) : (
                <div className="table_cont">
                  <table className="yearly_target_table">
                    <tbody>
                      <tr>
                        <td>Office</td>
                        <td>Target</td>
                        <td>Confirmed</td>
                        <td>Ongoing</td>
                        <td className="pinkDiv">Canceled</td>
                        <td className="pinkDiv">Cancelation Rate</td>

                        <td>Applications in %</td>
                      </tr>
                      {/* {applicationsData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="office_name first">{item.office}</td>
                          <td className="middle">{item.Target}</td>
                          <td className="middle">{item.Confirmed}</td>
                          <td className="middle">{item.Remaining}</td>
                          <td className="middle">{item.Ongoing}</td>
                          <td className="pinkDiv middle">{item.Canceled}</td>
                          <td className="pinkDiv middle">
                            {item.CancelationRate}
                          </td>
                          <td className="middle">{item.ExpectedConversions}</td>
                          <td className="last">
                            {item.PercentageOfApplications}
                          </td>
                        </tr>
                      );
                    })} */}

                      <>
                        {CrmStats?.data?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="office_name first">
                                {item.office.name}
                              </td>
                              <td className="middle">{item.target}</td>
                              <td className="middle">{item.confirmed}</td>

                              <td className="middle">{item.onGoing}</td>
                              <td className="pinkDiv middle">
                                {item.canceled}
                              </td>
                              <td className="pinkDiv middle">
                                {item.cancelationRate?.toFixed(2)} %
                              </td>

                              <td className="last">
                                {item.percentage_of_applications?.toFixed(2)} %
                              </td>
                            </tr>
                          );
                        })}{" "}
                        <tr>
                          <td className="office_name first">Total</td>
                          <td className="middle">{datss.target}</td>

                          <td className="middle">{datss.confirmed}</td>

                          <td className="middle">{datss.onGoing}</td>
                          <td className="pinkDiv middle">{datss.canceled}</td>
                          <td className="pinkDiv middle">
                            {datss.cancelationRate} %
                          </td>

                          <td className="last">
                            {datss.percentage_of_applications} %
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="yearly_target_bottom">
              <div className="estimation_div">
                <div className="estimation_div_title">Estimations</div>
                {CrmStats?.estimations?.TotalApps != 0 &&
                CrmStats?.estimations?.ConversionRate != 0 &&
                CrmStats?.estimations?.percentageOfTarget != 0 ? (
                  <div className="estimation_table">
                    <table>
                      <tbody>
                        <tr>
                          <td>Total Apps</td>
                          <td>Conversion Rate</td>
                          <td>% of Target</td>
                        </tr>
                        <tr>
                          <td>{CrmStats?.estimations?.TotalApps}</td>
                          <td>
                            {CrmStats?.estimations?.ConversionRate?.toFixed(2)}%
                          </td>
                          <td>
                            {CrmStats?.estimations?.percentageOfTarget?.toFixed(
                              2
                            )}
                            %
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="title_lead">No Data Found</div>
                )}
              </div>
              <div className="sources_div">
                <div className="sources_div_title">Sources</div>
                {CrmStats.sources?.length == 0 ? (
                  <div className="title_lead">No Data Found</div>
                ) : (
                  <div className="sources_table">
                    <table>
                      <tbody>
                        <tr>
                          {joinedArray?.map((item, index) => {
                            return <td key={index}>{item.name}</td>;
                          })}
                        </tr>
                        <tr>
                          {joinedArray?.map((item, index) => {
                            return <td key={index}>{item.apps}</td>;
                          })}
                        </tr>
                        <tr>
                          {joinedArray?.map((item, index) => {
                            return (
                              <td key={index}>{item.percent?.toFixed(2)}%</td>
                            );
                          })}
                        </tr>
                        {/* <tr>
                          {Sources?.map((item, index) => {
                            return <td key={index}>{item.name}</td>;
                          })}
                        </tr> */}

                        {/* <tr>
                          <td>{spurcesData.schools}</td>
                          <td>{spurcesData.socialMedia}</td>
                          <td>{spurcesData.Referral}</td>
                          <td>{spurcesData.QatarOffice}</td>
                          <td>{spurcesData.subAgents}</td>
                          <td>{spurcesData.other}</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="lead_sources">
          <div className="lead_sources_top">
            <div className="lead_sources_title">Lead Sources</div>
            <div className="lead_sources_buttons">
              <DropDown
                placeholder="Choose year"
                setSelected={setLeadYear}
                data={yearRange.map((item) => {
                  return { value: item, label: item };
                })}
                blueArrow={true}
                width={"13rem"}
                multiSelection={false}
                searchable={false}
                selectedData={setLeadYear}
                value={leadYear}
              />
              <DropDown
                placeholder="Choose Month"
                setSelected={setLeadMonth}
                data={Months}
                blueArrow={true}
                width={"13rem"}
                multiSelection={false}
                searchable={false}
                selectedData={setLeadMonth}
                value={leadMonth}
              />
            </div>
          </div>
          <div className="lead_sources_table">
            <table id="myTable">
              <tbody>
                <tr>
                  <td>Applicant Category</td>
                  <td>Application Source</td>
                  <td>
                    {leadMonth?.value} {leadYear?.value?.toString().slice(-2)}
                  </td>
                  <td>Total/Year</td>
                  <td>Total Category</td>
                  <td>% of Category</td>
                  <td>Canceled</td>
                  <td>Cancelation Rate</td>
                  <td>Confirmed {leadYear?.value}</td>
                  <td>Confirmed/Apps</td>
                  <td>Total Confirmation</td>
                </tr>
                {leadSourcesData.map((item, index) => {
                  return item.data.map((data, i) => {
                    return (
                      <tr
                        key={i}
                        className={
                          index % 6 == 0
                            ? "color1"
                            : index % 6 == 1
                            ? "color2"
                            : index % 6 == 2
                            ? "color3"
                            : index % 6 == 3
                            ? "color4"
                            : index % 6 == 4
                            ? "color5"
                            : "color6"
                        }
                      >
                        <td>{item.category}</td>
                        <td>{data.source}</td>
                        <td>{data.thisMonth}</td>
                        <td>{data.Total}</td>
                        {i == 0 && (
                          <td
                            className={
                              index ==
                                Object.keys(leadSourcesData).length - 1 &&
                              "last_div"
                            }
                            rowSpan={Object.keys(item.data).length}
                          >
                            {item.totalCategory}
                          </td>
                        )}
                        {i == 0 && (
                          <td
                            className={
                              index ==
                                Object.keys(leadSourcesData).length - 1 &&
                              "last_div"
                            }
                            rowSpan={Object.keys(item.data).length}
                          >
                            {item.percentageOfCategory}%
                          </td>
                        )}
                        <td>{data.canceled}</td>
                        {i == 0 && (
                          <td
                            className={
                              index ==
                                Object.keys(leadSourcesData).length - 1 &&
                              "last_div"
                            }
                            rowSpan={Object.keys(item.data).length}
                          >
                            {item.cancelationRate}%
                          </td>
                        )}
                        <td>{data.confirmed}</td>
                        {i == 0 && (
                          <td
                            className={
                              index ==
                                Object.keys(leadSourcesData).length - 1 &&
                              "last_div"
                            }
                            rowSpan={Object.keys(item.data).length}
                          >
                            {item.confirmed}%
                          </td>
                        )}
                        {i == 0 && (
                          <td
                            className={
                              index ==
                                Object.keys(leadSourcesData).length - 1 &&
                              "last_div"
                            }
                            rowSpan={Object.keys(item.data).length}
                          >
                            {item.totalConfirmation}%
                          </td>
                        )}
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default CRM;
