import { FiltersDegreesActions } from "../Filters/FiltersReducer";
import { axiosReq } from "../../../Utils";

export const getFiltersDegrees = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/degree`;
  dispatch(FiltersDegreesActions.getFiltersDegreesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      const reversedData = res.data.map((item) => item.name).reverse();
      dispatch(FiltersDegreesActions.getFiltersDegreesSuccess(reversedData));
    } else {
      dispatch(
        FiltersDegreesActions.getFiltersDegreesFail(
          res.data.response.data.message
        )
      );
    }
  });
};
