import React, { useEffect, useRef, useState } from "react";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import ApplyForm from "../../../Components/ApplyForm/ApplyForm";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import choose from "../../../Assets/Images/ChoosePath.png";
import Popup from "../../../Components/Popup/Popup";
import Radio from "../../../Components/Radio/Radio";
import uploadHere from "../../../Assets/Images/uploadHere.png";
import documentUp from "../../../Assets/Images/document.png";
import "./ApplicationStep4.scss";
import DropDown from "../../../Components/DropDown/DropDown";
import ApplicationStep5 from "../Application5/ApplicationStep5";
import UpperPopUp from "../../../Components/UpperPopUp/UpperPopUp";
import img from "../../../Assets/Images/checked.png";
import UploadDoc from "../../../Components/UploadDoc/UploadDoc";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleApp,
  uploadAppMissingDocuments,
  uploadAppMissingDocumentsFromWebsite,
} from "../../../Redux/Application/ApplicationAction";
import {
  getAppDocument,
  updateApplication,
} from "../../../Redux/CreateApplication/CreateApplicationAction";
import Loader from "../../../Components/Loader/Loader";
function ApplicationStep4({ step, setStep, counselor }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [uploadedDoc, setUploadedDoc] = useState({});
  const [showPop, setShowPop] = useState(false);
  const [fromPC, setFromPC] = useState(false);
  const [chosenDoc, setChosenDoc] = useState({});
  const [docName, setDocName] = useState("");
  const [showUpper, setShowUpper] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allDoc, setAllDoc] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const {
    updateApplicationLoading,
    updateApplicationError,
    updateApplicationSuccess,
    getAppDocumentLoading,
    appDocument,
  } = useSelector((state) => state.CreateApplicationReducer);
  const {
    singleApplication,
    getSingleAppLoading,
    getSingleAppSuccess,
    getSingleAppError,
  } = useSelector((state) => state.ApplicationsReducer);

  const { user } = useSelector((store) => store.userReducer);

  const handleSubmit = (e) => {
    e.preventDefault();
    const application = {
      application_step: 5,
    };
    setClicked(true);
    dispatch(
      updateApplication(localStorage.getItem("applicationId"), application)
    );
  };

  useEffect(() => {
    if (updateApplicationLoading) {
      setBtnLoad(true);
    } else setBtnLoad(false);
  }, [updateApplicationLoading]);

  useEffect(() => {
    if (updateApplicationError) {
      setError(updateApplicationError);
    } else {
      setError("");
    }
  }, [updateApplicationError]);

  useEffect(() => {
    if (updateApplicationSuccess && clicked && step == 4) {
      if (!counselor) {
        navigate("/student/my_applications/apply/5");
        setStep(5);
      } else {
        navigate("/counselor/applications/create/5");

        setStep(5);
      }
    }
  }, [updateApplicationSuccess]);

  useEffect(() => {
    dispatch(getAppDocument(localStorage.getItem("applicationId")));
  }, []);
  useEffect(() => {
    if (getAppDocumentLoading) {
      setLoading(true);
    } else setLoading(false);
  }, [getAppDocumentLoading]);
  useEffect(() => {
    if (appDocument) {
      setAllDoc(appDocument?.major_degree?.degree?.documents);
    }
  }, [appDocument]);

  useEffect(() => {
    if (localStorage.getItem("applicationId")) {
      dispatch(getSingleApp(localStorage.getItem("applicationId")));
    }
  }, []);

  useEffect(() => {
    if (
      singleApplication &&
      singleApplication.documents &&
      singleApplication.documents.length > 0 &&
      localStorage.getItem("applicationId")
    ) {
      let chosen = {};
      singleApplication.documents.map((doc) => {
        chosen[doc.name] = { value: doc.name, label: doc.originalName };
      });
      setChosenDoc(chosen);
    } else {
      setChosenDoc({});
    }
  }, [singleApplication]);

  const goBackF = () => {
    if (!counselor) {
      setStep(3);
      navigate("/student/my_applications/apply/3");
    } else {
      setStep(3);
      navigate("/counselor/applications/create/3");
    }
  };

  const skipNow = () => {
    if (!counselor) {
      setStep(5);
      navigate("/student/my_applications/apply/5");
    } else {
      setStep(5);
      navigate("/counselor/applications/create/5");
    }
  };

  return (
    <div className="application_step1_mainDiv">
      {showUpper && (
        <UpperPopUp
          setShow={setShowUpper}
          img={img}
          title="Application progress saved"
          navigate={() => {
            counselor
              ? navigate(
                  "/counselor/applications/singleApp" +
                    "/" +
                    localStorage.getItem("applicationId")
                )
              : navigate("/student/my_applications");
          }}
        />
      )}

      {showPop && (
        <UploadDoc
          setShow={setShowPop}
          title={counselor ? "Upload Document" : "Choose Document from"}
          showRadio={counselor ? false : true}
          uploadedDoc={uploadedDoc}
          setUploadedDoc={setUploadedDoc}
          setChosenDoc={setChosenDoc}
          chosenDoc={chosenDoc}
          onSubmit={() => {
            {
              uploadedDoc[docName]
                ? dispatch(
                    uploadAppMissingDocuments(
                      localStorage.getItem("applicationId"),
                      docName,
                      uploadedDoc[docName],
                      user.Student.id,
                      true
                    )
                  )
                : dispatch(
                    uploadAppMissingDocumentsFromWebsite(
                      localStorage.getItem("applicationId"),
                      chosenDoc[docName].value,
                      docName,
                      user.Student.id,
                      true
                    )
                  );
            }
          }}
          docName={docName}
          setDocName={setDocName}
        />
      )}
      {step == 4 && !counselor ? (
        <ApplyForm
          title="Education Summary"
          subTitle="Please make sure your details are accurate and updated"
          hasBack={true}
          back={goBackF}
          loading={loading}
          hasPlus={false}
          body={
            <div className="application_step1_body">
              <div className="application_step4_body">
                {allDoc &&
                  allDoc?.map((document, i) => {
                    return (
                      <div className="upload_doc_div">
                        <div className="upload_doc_div_header_title">
                          {document}{" "}
                          <span className="upload_doc_div_header_size">
                            Max Size: 5MB
                            <span className="upload_doc_div_header_text">*</span>
                          </span>

                      
                        </div>

                        <div
                          className="upload_doc_div_body"
                          onClick={() => {
                            setShowPop(true);
                            setDocName(document);
                            uploadedDoc[document]
                              ? setFromPC(true)
                              : setFromPC(false);
                          }}
                        >
                          <img
                            src={
                              chosenDoc[document] || uploadedDoc[document]
                                ? documentUp
                                : choose
                            }
                            className="upload_doc_div_body_img"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              {error && <div className="error">{error}</div>}
              <div className="application_step1_body_footer">
                <Button
                  text={btnLoad ? <Loader onlyDots={true} /> : "Continue"}
                  color="yellow"
                  width="100%"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={
                    Object.keys(chosenDoc).length +
                      Object.keys(uploadedDoc).length >=
                      allDoc?.length && !btnLoad
                      ? false
                      : true
                  }
                />

                <div className="application_step1_body_footer_options">
                  <div
                    className="application_step1_body_footer_text2"
                    onClick={() => skipNow()}
                  >
                    Skip for now
                  </div>
                  <div
                    className="application_step1_body_footer_text"
                    onClick={() => setShowUpper(true)}
                  >
                    Continue Later
                  </div>
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <ApplicationStep5 step={step} setStep={setStep} counselor={counselor} />
      )}
    </div>
  );
}

export default ApplicationStep4;
