import React, { useEffect, useState } from "react";
import DropDown from "../DropDown/DropDown";
import Input from "../Input/Input";
import Popup from "../Popup/Popup";
import zoom from "../../../Shared/Assets/Images/zoom.png";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import Button from "../Button/Button";
import "./ScheduleMeeting.scss";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import arrow from "../../../Shared/Assets/Images/arrow1.webp";
import {
  getSchedualTime,
  getStudentEvents,
  requestMeeting,
  getSchedualTimeSales,
} from "../../../Student/Redux/MyCounselor/MyCounselorActions";
import { DropDownList } from "../../../Utils";
import Loader from "../Loader/Loader";
import UpperPopUp from "../UpperPopUp/UpperPopUp";

function ScheduleMeeting({
  showPopup,
  setShowPopup,
  selectedDay,
  selectedTime,
  setselectedTime,
  setSelectedDay,
  meetingSubject,
  setmeetingSubject,
  setShow,
  sales,
  counselorName,
  counselors,
  setCounselorName,
  type,
  stid,
  back,
  setstid,
  goBack,
  test,
}) {
  const [meetingData, setMeetingData] = useState({});
  const dispatch = useDispatch();
  const [showUpp, setShowUpp] = useState(false);
  const [showUpp2, setShowUpp2] = useState(false);

  const { user } = useSelector((state) => state.userReducer);
  const {
    timeSlots,
    requestMeetingSuccess,
    requestMeetingFail,
    requestMeetingLoading,
  } = useSelector((state) => state.myCounselorReducer);

  useEffect(() => {
    if (requestMeetingSuccess && type != 3 && !test) {
      setShowPopup(false);
      setShow(true);
      setSelectedDay(null);
      setselectedTime("");
      setmeetingSubject("");
      setTimeout(() => {
        dispatch(getStudentEvents());
      }, 2000);
    }
  }, [requestMeetingSuccess]);

  const formattedTimes = timeSlots.map((time) => {
    const date = new Date(); // Current date
    const [hours, minutes, seconds = "00"] = time.split(":");

    const formattedDate = `${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${new Date(formattedDate + " " + formattedTime).toLocaleTimeString(
      "en-US",
      { hour: "numeric", minute: "2-digit" }
    )}`;
  });

  useEffect(() => {
    if (selectedDay && Object.keys(user).length !== 0) {
      const date = new Date(
        selectedDay?.year + "-" + selectedDay?.month + "-" + selectedDay?.day
      ); // Current date

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      if (type === 2) {
        dispatch(
          getSchedualTimeSales(
            year + "-" + month + "-" + day,
            counselorName?.value
          )
        );
      }

      if (type === 3) {
        dispatch(getSchedualTimeSales(year + "-" + month + "-" + day, user.id));
      } else {
        dispatch(
          getSchedualTime(
            year + "-" + month + "-" + day,
            user?.Student?.counselor?.id
          )
        );
      }

      setselectedTime("");
    }
  }, [selectedDay]);

  useEffect(() => {
    var date1, year2, month3, day4, formattedDate5;
    if (selectedDay) {
      date1 = new Date(
        selectedDay.year + "-" + selectedDay.month + "-" + selectedDay.day
      ); // Current date

      year2 = date1.getFullYear();
      month3 = String(date1.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are zero-based
      day4 = String(date1.getDate()).padStart(2, "0");

      formattedDate5 = `${year2}-${month3}-${day4}`;
    }
    if (type === 2) {
      setMeetingData({
        ...(selectedDay?.day && {
          date: formattedDate5,
        }),
        ...(selectedTime && { time: selectedTime.value }),
        ...(meetingSubject && { topic: meetingSubject }),
        ...(stid && { student: stid }),
        ...(counselorName && { counselor: counselorName.value }),
      });
    } else if (type === 3) {
      setMeetingData({
        ...(selectedDay?.day && {
          date: formattedDate5,
        }),
        ...(selectedTime && { time: selectedTime.value }),
        ...(meetingSubject && { topic: meetingSubject }),
        ...(user && { student: stid }),
        ...(user && { counselor: user.id }),
        ...{ status: "accepted" },
      });
    } else {
      setMeetingData({
        ...(selectedDay?.day && {
          date: formattedDate5,
        }),
        ...(selectedTime && { time: selectedTime.value }),
        ...(meetingSubject && { topic: meetingSubject }),
        ...(user && { student: user?.Student?.id }),
        ...(user && { counselor: user?.Student?.counselor?.id }),
      });
    }
  }, [selectedDay, selectedTime, meetingSubject]);

  const [customDays, setConvertedArray] = useState([]);
  const { limitedTimeSlotsDays } = useSelector(
    (state) => state.myCounselorReducer.days
  );

  useEffect(() => {
    if (limitedTimeSlotsDays != undefined && limitedTimeSlotsDays.length == 0)
      return;
    const convertedDates = limitedTimeSlotsDays?.map((dateString) => {
      const splitDate = dateString.split("-");
      const year = parseInt(splitDate[0]);
      const month = parseInt(splitDate[1]);
      const day = parseInt(splitDate[2]);

      return { year: year, month: month, day: day, className: "yellowDay" };
    });

    setConvertedArray(convertedDates);
  }, [limitedTimeSlotsDays]);

  const [disabledDays, setDisabledDay] = useState([]);
  const { busyDays } = useSelector((state) => state.myCounselorReducer.days);

  useEffect(() => {
    if (busyDays != undefined && busyDays.length == 0) return;
    const convertedDates = busyDays?.map((dateString) => {
      const splitDate = dateString.split("-");
      const year = parseInt(splitDate[0]);
      const month = parseInt(splitDate[1]);
      const day = parseInt(splitDate[2]);

      return { year: year, month: month, day: day };
    });

    setDisabledDay(convertedDates);
  }, [busyDays]);
  return (
    <div>
      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          body={
            <div className="zoom_popup_main">
              <div className="zoom_popup_left">
                {back && (
                  <img
                    alt="back"
                    src={arrow}
                    onClick={() => goBack()}
                    style={{
                      width: "1rem",
                      marginRight: "1rem",
                      cursor: "pointer",
                    }}
                  />
                )}
                <img src={zoom} alt="Zoom" className="Zoom_img" />
                <div
                  className={
                    type == 2 && !counselorName.value ? "cal disabled" : "cal"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (type == 2) {
                      if (!counselorName.value) setShowUpp(true);
                    }
                  }}
                >
                  <Calendar
                    value={selectedDay}
                    onChange={setSelectedDay}
                    colorPrimary="var(--pink)"
                    calendarTodayClassName="selected_day"
                    disabledDays={disabledDays}
                    customDaysClassName={customDays}
                    minimumDate={{
                      year: new Date().getFullYear(),
                      month: new Date().getMonth() + 1,
                      day: new Date().getDate(),
                    }}
                  />
                </div>
                <div className="calendar_legend">
                  <div className="calendar_left">
                    <div className="single_legend">
                      <div className="yellow_box"></div>
                      Busy day
                    </div>
                    <div className="single_legend">
                      <div className="pink_box"></div>
                      Today
                    </div>
                  </div>
                  <div className="calendar_right">
                    <div className="single_legend">
                      <div className="white_box"></div>
                      Available day
                    </div>
                    <div className="single_legend">
                      <div className="opacity_box"></div>
                      Unavailable day
                    </div>
                  </div>
                </div>
              </div>
              {!sales ? (
                <div className="zoom_popup_right">
                  <div
                    className="single_div"
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!selectedDay) setShowUpp2(true);
                    }}
                  >
                    <div className="input_title">Date</div>
                    <Input
                      type={"text"}
                      placeholder={"DD-MM-YYYY"}
                      width="100%"
                      disabled={true}
                      value={
                        selectedDay
                          ? selectedDay?.month +
                            "-" +
                            selectedDay?.day +
                            "-" +
                            selectedDay?.year
                          : "MM/DD/YYYY"
                      }
                    />
                  </div>
                  <div
                    className="single_div"
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!selectedDay) setShowUpp2(true);
                    }}
                  >
                    <div className="input_title">Time</div>
                    {timeSlots && timeSlots.length > 0 && (
                      <DropDown
                        placeholder="00:00 PM"
                        setSelected={setselectedTime}
                        data={DropDownList(formattedTimes)}
                        blueArrow={false}
                        width={"100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={selectedTime}
                        keepPlaceHolder={false}
                        isDisabled={!selectedDay}
                        value={selectedTime}
                      />
                    )}
                    {!timeSlots ||
                      (timeSlots.length == 0 && (
                        <DropDown
                          placeholder="00:00 PM"
                          setSelected={setselectedTime}
                          data={[]}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={selectedTime}
                          keepPlaceHolder={false}
                          isDisabled={!selectedDay}
                          value={selectedTime}
                        />
                      ))}
                  </div>
                  <div className="single_div">
                    <div className="input_title">Meeting Title</div>
                    <Input
                      type={"text"}
                      placeholder={"e.g: Meeting with counselor"}
                      width="100%"
                      disabled={false}
                      onChange={(e) => setmeetingSubject(e.target.value)}
                      value={meetingSubject}
                    />
                  </div>
                  {requestMeetingFail && (
                    <div
                      style={{
                        color: "var(--pink)",
                        fontFamily: "P_Semibold",
                        fontSize: "1.2rem",
                        marginBottom: "-1rem",
                      }}
                    >
                      {requestMeetingFail}
                    </div>
                  )}
                  <div style={{ marginTop: "1.5rem" }}>
                    <Button
                      text={
                        requestMeetingLoading ? (
                          <Loader onlyDots={true} />
                        ) : type == 3 ? (
                          "Book Meeting"
                        ) : (
                          "Request Meeting"
                        )
                      }
                      color={"yellow"}
                      width={"100%"}
                      disabled={
                        !selectedDay ||
                        !meetingSubject ||
                        !selectedTime ||
                        requestMeetingLoading
                      }
                      onClick={() => {
                        Object.keys(meetingData).length >= 5 &&
                          dispatch(requestMeeting(meetingData));
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="zoom_popup_right">
                  <div className="single_div">
                    <div className="input_title">Counselor Name</div>
                    <DropDown
                      placeholder="Counselor Name"
                      setSelected={setCounselorName}
                      data={counselors}
                      blueArrow={false}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={counselorName}
                      keepPlaceHolder={false}
                      value={counselorName}
                    />
                  </div>
                  <div
                    className="meeting_zoom_flex_div"
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!selectedDay) setShowUpp2(true);
                    }}
                  >
                    <div className="single_div">
                      <div className="input_title">Date</div>
                      <Input
                        type={"text"}
                        placeholder={"Meeting Subject"}
                        width="100%"
                        disabled={true}
                        value={
                          selectedDay
                            ? selectedDay?.day +
                              "-" +
                              selectedDay?.month +
                              "-" +
                              selectedDay?.year
                            : "MM/DD/YYYY"
                        }
                      />
                    </div>
                    <div className="single_div">
                      <div className="input_title">Time</div>
                      {timeSlots && timeSlots.length > 0 && (
                        <DropDown
                          placeholder="00:00 PM"
                          setSelected={setselectedTime}
                          data={DropDownList(formattedTimes)}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={selectedTime}
                          keepPlaceHolder={false}
                          isDisabled={!selectedDay}
                          value={selectedTime}
                        />
                      )}
                      {!timeSlots ||
                        (timeSlots.length == 0 && (
                          <DropDown
                            placeholder="00:00 PM"
                            setSelected={setselectedTime}
                            data={[]}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={selectedTime}
                            keepPlaceHolder={false}
                            isDisabled={!selectedDay}
                            value={selectedTime}
                          />
                        ))}
                    </div>
                  </div>

                  <div className="single_div">
                    <div className="input_title">Meeting Title</div>
                    <Input
                      type={"text"}
                      placeholder={"e.g: Meeting with counselor"}
                      width="100%"
                      disabled={false}
                      onChange={(e) => setmeetingSubject(e.target.value)}
                      value={meetingSubject}
                    />
                  </div>
                  {requestMeetingFail && (
                    <div
                      style={{
                        color: "var(--pink)",
                        fontFamily: "P_Semibold",
                        fontSize: "0.8rem",
                        marginBottom: "-1rem",
                      }}
                    >
                      {requestMeetingFail}
                    </div>
                  )}
                  <div style={{ marginTop: "1.5rem" }}>
                    <Button
                      text={
                        requestMeetingLoading ? (
                          <Loader onlyDots={true} />
                        ) : (
                          "Request Meeting"
                        )
                      }
                      color={"yellow"}
                      width={"100%"}
                      disabled={
                        !selectedDay ||
                        !meetingSubject ||
                        !selectedTime ||
                        requestMeetingLoading
                      }
                      onClick={() => {
                        if (Object.keys(meetingData).length === 5) {
                          if (type != 2) dispatch(requestMeeting(meetingData));
                          else dispatch(requestMeeting(meetingData, true));

                          setstid && setstid("");
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          }
        />
      )}
      {showUpp && (
        <UpperPopUp
          setShow={setShowUpp}
          title="You need to select a counselor first"
        />
      )}

      {showUpp2 && (
        <UpperPopUp
          setShow={setShowUpp2}
          title="You need to select a date from the calendar first"
        />
      )}
    </div>
  );
}

export default ScheduleMeeting;
