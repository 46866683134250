import React from 'react'
import './Circle.scss'
import circle_animation from '../../../Assets/Images/circle_animation.gif'
function Circle() {

  return (
    <div className='circle_main_div'>

        <img src={circle_animation} alt='circle' className='circle_shape' />



    </div>
  )
}

export default Circle