import React from "react";
import "./Penta.scss";
import PentaImg from "../../../Assets/Images/penta.png";

function Penta() {
  return (
    <div className="Penta_Container">
      <img src={PentaImg} alt="Pentagon" className="Penta_Img" />
    </div>
  );
}

export default Penta;
