import React from "react";
import "./CompleteProfileCard.scss";
import one from "../../../Shared/Assets/Images/one.png";
import two from "../../../Shared/Assets/Images/two.png";
import three from "../../../Shared/Assets/Images/three.png";
import backimg from "../../../Shared/Assets/Images/arrow1.webp";

function CompleteProfileCard({ title, number, body, onClick, back }) {
  return (
    <div className="CompleteProfileCard_container">
      <div className="CompleteProfileCard_inner">
        {number && (
          <img
            src={number == 1 ? one : number == 2 ? two : three}
            alt="number"
            className="number_img"
          />
        )}
        <div className="CompleteProfileCard_title">
          {back && (
            <img
              src={backimg}
              alt="back"
              className="back_img"
              onClick={onClick}
              style={{ cursor: "pointer", width: "20px", height: "auto" }}
            />
          )}

          {title}
        </div>
        <div className="CompleteProfileCard_body">{body}</div>
      </div>
    </div>
  );
}

export default CompleteProfileCard;
