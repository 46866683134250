import React from "react";
import "./ProgressBar.scss";
import arrowBack from "../../../Shared/Assets/Images/arrow1.webp";
import Progress from "../../../Shared/Components/Progress/Progress";
function ProgressBar(props) {
  //step, steps, goBack
  //which step are you in, how many number of steps, go back function
  return (
    <div className="progressBar_main_div">
      {props.goBack && (
        <div className="goingBack_div" onClick={props.goBack}>
          <img src={arrowBack} alt="arrowBack" className="arrowBack" />
          <span className="back_title">Back</span>
        </div>
      )}
      {props.showSteps && (
        <div className="progressBar_comp">
          {Array.from(Array(props.steps * 2), (el, i) => {
            if (i % 2 == 0)
              return (
                <div className="steps_counter">
                  <Progress
                    ongoing={props.step >= i + 2 ? 2 : props.step > i ? 1 : 0}
                  />
                  {i == 0 && (
                    <div className="steps_counter_text">
                      {props.step} of {props.steps * 2}
                    </div>
                  )}
                </div>
              );
          })}
        </div>
      )}
    </div>
  );
}

export default ProgressBar;
