import { LocationsActions } from "../Location/LocationsReducer";
import { axiosReq } from "../../../Utils";

export const getAllCont = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/location/countries`;
  dispatch(LocationsActions.getCountriesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) dispatch(LocationsActions.getCountriesSuccess(res.data));
    else
      dispatch(
        LocationsActions.getCountriesError(res.data.response.data.message)
      );
  });
};

export const getCityByContId = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/location/cities/${id}`;
  if (id) {
    dispatch(LocationsActions.getCityByCountryIdRequest());
    dispatch(axiosReq("get", url, {}, {})).then((res) => {
      if (res.success)
        dispatch(LocationsActions.getCityByCountryIdSuccess(res.data));
      else
        dispatch(
          LocationsActions.getCityByCountryIdError(
            res.data.response.data.message
          )
        );
    });
  } else {
    return;
  }
};

export const getCity2ByContId = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/location/cities/${id}`;
  dispatch(LocationsActions.getCity2ByCountryIdRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(LocationsActions.getCity2ByCountryIdSuccess(res.data));
    else
      dispatch(
        LocationsActions.getCity2ByCountryIdError(
          res.data.response.data.message
        )
      );
  });
};
