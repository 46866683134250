import { components } from "react-select";
import arrow_down from "../../Assets/Images/arrow_down.svg";
import arrow_up from "../../Assets/Images/arrow_up.svg";

const { DropdownIndicator } = components;

export const CustomDropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <DropdownIndicator {...props}>
      {!menuIsOpen ? (
        <img className="drop_down_arrow" src={arrow_down} />
      ) : (
        <img className="drop_down_arrow" src={arrow_up} />
      )}
    </DropdownIndicator>
  );
};
