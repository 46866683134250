import React, { useEffect, useState } from "react";
import "./CounselorOverview.scss";
import OveriewCard from "../../../Shared/Components/OveriewCard/OveriewCard";
import { useNavigate } from "react-router-dom";
import Button from "../../../Shared/Components/Button/Button";
import CounselorOverviewCard from "../../Components/CounselorOverviewCard/CounselorOverviewCard";
import arrow2 from "../../../Shared/Assets/Images/arrow2.webp";
import chat from "../../../Shared/Assets/Images/chat.png";
import {
  getStatistics,
  getTasks,
  changeTaskStatus,
} from "../../Redux/CounselorOverView/CounselorOverViewActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Shared/Components/Loader/Loader";
import { IdSplit, capitalizeFirstLetter, dateConv } from "../../../Utils";
import {
  changeMeetingStatus,
  getCounselorMeetingToday,
} from "../../Redux/CounselorMeeting/CounselorMeetingActions";

function CounselorOverview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    statistics,
    getStatisticsLoading,
    tasks,
    getTasksLoading,
    changeTaskStatusLoading,
  } = useSelector((store) => store.CounselorOverViewReducer);
  useEffect(() => {
    dispatch(getStatistics());
    dispatch(getTasks());
    dispatch(getCounselorMeetingToday());
  }, []);

  const data = [
    {
      time: "09:00 AM",
      hasMeeting: false,
    },
    {
      time: "10:00 AM",
      hasMeeting: true,
      name: "Mark Moussa",
      date: "January 4, 2022",
      major: "Graphic Design",
    },
    {
      time: "11:00 AM",
      hasMeeting: false,
    },
    {
      time: "12:00 AM",
      hasMeeting: false,
    },
    {
      time: "10:00 AM",
      hasMeeting: true,
      name: "Mark Moussa",
      date: "January 4, 2022",
      major: "Graphic Design",
    },
    {
      time: "02:00 PM",
      hasMeeting: false,
    },
    {
      time: "03:00 PM",
      hasMeeting: false,
    },
  ];

  const studentTasks = [
    {
      name: "Mark Moussa",
      id: "201701234",
      task: "Fill the application form",
      status: "Pending",
    },
    {
      name: "Mark Moussa",
      id: "201701234",
      task: "Fill the application form",
      status: "Submitted",
    },
    {
      name: "Mark Moussa",
      id: "201701234",
      task: "Fill the application form",
      status: "Submitted",
    },
    {
      name: "Mark Moussa",
      id: "201701234",
      task: "Fill the application form",
      status: "Pending",
    },
    {
      name: "Mark Moussa",
      id: "201701234",
      task: "Fill the application form",
      status: "Submitted",
    },
    {
      name: "Mark Moussa",
      id: "201701234",
      task: "Fill the application form",
      status: "Submitted",
    },
  ];
  const hours = [
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
  ];
  const convertToHourMinuteFormat = (timeString) => {
    try {
      // Extract the substring representing the hours and minutes (e.g., "08:00" from "08:00:00")
      const hourMinuteFormat = timeString.substring(0, 5);
      return hourMinuteFormat;
    } catch (error) {
      // Handle any errors
      return null;
    }
  };
  const events = useSelector((state) => state.CounselorMeetingReducer.events);
  const {
    getEventsLoading,
    TodayEvents,
    TodayEventsLoading,
    changeMeetingStatusLoading,
  } = useSelector((state) => state.CounselorMeetingReducer);
  return (
    <>
      {(getStatisticsLoading || getTasksLoading || changeTaskStatusLoading) && (
        <Loader fullScreen={true} />
      )}
      <div className="CounselorOverview_container">
        <div className="CounselorOverview_top">
          <div className="CounselorOverview_top_title">
            Overview Applications
          </div>
          <div className="CounselorOverview_top_body">
            <div className="CounselorOverview_top_body_div">
              <CounselorOverviewCard
                number={statistics.students}
                color="yellow"
                text={
                  <>
                    Number of
                    <br />
                    Students
                  </>
                }
                onClick={() => navigate("/counselor/students")}
              />
              <CounselorOverviewCard
                number={statistics.applications}
                color="green"
                text={
                  <>
                    Number of
                    <br />
                    Applications
                  </>
                }
                onClick={() => navigate("/counselor/applications")}
              />
            </div>
            <div className="CounselorOverview_top_body_div">
              <CounselorOverviewCard
                number={statistics.acceptedApplications}
                color="blue"
                text={
                  <>
                    Applications
                    <br />
                    Confirmed
                  </>
                }
                onClick={() => navigate("/counselor/applications")}
              />
              <CounselorOverviewCard
                number={statistics.newStudents}
                color="pink"
                text={
                  <>
                    Number of
                    <br />
                    New Students
                  </>
                }
                onClick={() => navigate("/counselor/students")}
              />
            </div>
          </div>
        </div>
        <div className="CounselorOverview_middle">
          <div className="CounselorOverview_middle_inner">
            <div className="CounselorOverview_middle_inner_title">
              My Meetings
            </div>
            <div className="CounselorOverview_middle_inner_table">
              <table>
                <tbody>
                  {hours.map((hour, index) => {
                    const event = TodayEvents.find(
                      (event) =>
                        new Date(
                          event.date + " " + event.time
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                        }) === hour
                    );
                    return (
                      <tr key={index}>
                        <td className={event ? "pink_div hours" : "hours"}>
                          {hour.split(" ")[0]}
                        </td>
                        {event ? (
                          <>
                            <td className="pink_div title">
                              Meeting with {event.student.user.first_name}{" "}
                              {event.student.user.last_name}
                            </td>
                            <td className="pink_div">{event.topic}</td>
                            {
                              <td>
                                {event.status == "pending" ? (
                                  <div className="buttons_div">
                                    <Button
                                      text="Confirm"
                                      color={"lightGreen"}
                                      onClick={() =>
                                        window.open(
                                          `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri==${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
                                          "_blank"
                                        )
                                      }
                                    />
                                    <Button
                                      text="Reject"
                                      color={"pink"}
                                      onClick={() =>
                                        dispatch(
                                          changeMeetingStatus(
                                            event.id,
                                            "rejected"
                                          )
                                        )
                                      }
                                    />
                                  </div>
                                ) : event.status == "accepted" ? (
                                  <div className="buttons_div join_button">
                                    <Button text="Start" color={"lightGreen"} />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontFamily: "P_Bold",
                                      fontSize: "1.2rem",
                                      textAlign: "center",
                                    }}
                                  >
                                    Meeting was Rejected
                                  </div>
                                )}
                              </td>
                            }
                          </>
                        ) : (
                          <td colSpan={3} className="no_meeting">
                            No Scheduled Meetings
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="CounselorOverview_middle_inner_mobile">
              {TodayEvents.map((item, index) => {
                return (
                  <div
                    className="CounselorOverview_middle_inner_mobile_row"
                    key={index}
                  >
                    <div className="inner">
                      <div className="title">Meeting with:</div>
                      <div className="CounselorOverview_middle_inner_mobile_row_meeting_name">
                        {item.student.user.first_name +
                          " " +
                          item.student.user.last_name}
                      </div>
                      <div className="CounselorOverview_middle_inner_mobile_row_meeting_major">
                        {item.topic}
                      </div>
                      <div className="CounselorOverview_middle_inner_mobile_row_meeting_date_time">
                        <div className="CounselorOverview_middle_inner_mobile_row_meeting_date">
                          {new Date().toLocaleDateString()}
                        </div>
                        <div className="CounselorOverview_middle_inner_mobile_row_time">
                          {convertToHourMinuteFormat(item.time)}
                        </div>
                      </div>
                      <div className="CounselorOverview_middle_inner_mobile_row_meeting_buttons">
                        <Button
                          text="Confirm"
                          color={"lightGreen"}
                          onClick={() =>
                            window.open(
                              `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri==${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
                              "_blank"
                            )
                          }
                        />
                        <Button
                          text="Reject"
                          color={"pink"}
                          onClick={() =>
                            dispatch(changeMeetingStatus(item.id, "rejected"))
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="CounselorOverview_bottom">
          <div className="CounselorOverview_bottom_inner">
            <div className="CounselorOverview_bottom_inner_title">
              Student's Tasks for Today
            </div>
            <div className="CounselorOverview_bottom_inner_mobile">
              {tasks.map((item, index) => {
                return (
                  <div
                    className="CounselorOverview_bottom_inner_mobile_row"
                    key={index}
                  >
                    <div className="inner">
                      <div className="CounselorOverview_bottom_inner_mobile_row_student_id_status">
                        <div className="id">
                          {"ID" + " " + IdSplit(item.student.id)}
                        </div>
                        <div className="CounselorOverview_bottom_inner_mobile_row_student_status">
                          <div
                            className={
                              item.status.toLowerCase() == "pending"
                                ? "yellow"
                                : item.status.toLowerCase() == "rejected"
                                ? "pink"
                                : "green"
                            }
                          >
                            {capitalizeFirstLetter(item.status.toLowerCase())}
                          </div>
                        </div>
                      </div>
                      <div className="CounselorOverview_bottom_inner_mobile_row_student_name">
                        {item.student.user.first_name +
                          " " +
                          item.student.user.last_name}
                      </div>
                      <div className="CounselorOverview_bottom_inner_mobile_row_student_task">
                        {item.title}
                      </div>
                    </div>
                    <hr
                      style={{
                        borderBottom: "3px solid black",
                        marginTop: "2rem",
                      }}
                    />
                    <div className="bottom">
                      <div
                        className="chat_div"
                        on
                        onClick={() => navigate("/counselor/chat")}
                      >
                        <img src={chat} alt="Chat" className="chat_icon" />
                        <div className="text">Chat</div>
                      </div>
                      <div className="CounselorOverview_bottom_inner_mobile_row_student_buttons">
                        <Button
                          text="Accept"
                          color={"lightGreen"}
                          disabled={item.status.toLowerCase() == "pending"}
                          onClick={() => {
                            dispatch(changeTaskStatus(item.id, "accepted"));
                          }}
                        />
                        <Button
                          text="Reject"
                          color={"pink"}
                          disabled={item.status.toLowerCase() == "pending"}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="CounselorOverview_bottom_inner_table">
              <table>
                <tbody>
                  <tr>
                    <td>Student's Name</td>
                    <td>Student's Id</td>
                    <td>Task</td>
                    <td>Status</td>
                    <td>Confirmation</td>
                    <td>Contact</td>
                    <td></td>
                  </tr>
                  {tasks.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="student_name">
                          {item.student.user.first_name +
                            item.student.user.last_name}
                        </td>
                        <td>{IdSplit(item.id)}</td>
                        <td>{item.title}</td>
                        <td
                          className={
                            item.status.toLowerCase() == "pending"
                              ? "yellow"
                              : item.status.toLowerCase() == "rejected"
                              ? "pink"
                              : "green"
                          }
                        >
                          {capitalizeFirstLetter(item.status.toLowerCase())}
                        </td>
                        <td className="Buttons">
                          <div className="student_buttons">
                            {item.status.toLowerCase() === "accepted" ? (
                              <span className="task_confirmationMessage">
                                You accepted this task
                              </span>
                            ) : item.status.toLowerCase() === "rejected" ? (
                              <span className="task_confirmationMessage">
                                You rejected this task
                              </span>
                            ) : (
                              <>
                                <Button
                                  text="Accept"
                                  color={"lightGreen"}
                                  disabled={
                                    item.status.toLowerCase() == "pending"
                                  }
                                  onClick={() => {
                                    dispatch(
                                      changeTaskStatus(item.id, "accepted")
                                    );
                                  }}
                                />
                                <Button
                                  text="Reject"
                                  color={"pink"}
                                  disabled={
                                    item.status.toLowerCase() == "pending"
                                  }
                                  onClick={() => {
                                    dispatch(
                                      changeTaskStatus(item.id, "rejected")
                                    );
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/counselor/chat")}
                        >
                          <div className="chat_div">
                            <img
                              src={chat}
                              alt="contact"
                              className="contact_img"
                            />
                            <div>Chat</div>
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/counselor/students/singleStudent/${item.student.id}`
                            )
                          }
                        >
                          <img src={arrow2} alt="arrow" className="arrow_img" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CounselorOverview;
