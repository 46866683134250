import React, { useEffect, useState } from "react";
import "./OBStep7.scss";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";

import Triangle from "../../../../Shared/Components/Animations/Triangle/Triangle";
import DropDown from "../../../../Shared/Components/DropDown/DropDown";
import OBStep8 from "../OBStep8/OBStep8";
import { useDispatch } from "react-redux";
import { axiosReq } from "../../../../Utils";
import Input from "../../Input/Input";
function OBStep7({ step, setStep, student, setStudent, counselor }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [semester, setSemester] = useState("");
  const [year, setYear] = useState("");
  const [source, setSource] = useState("");
  const [othersRefSource, setOthersRefSource] = useState("");

  const [allSources, setAllSources] = useState("");

  useEffect(() => {
    dispatch(
      axiosReq("get", process.env.REACT_APP_API + "/referal/sources", {}, {})
    ).then((res) => {
      if (res.success) {
        const data = res.data;
        setLoading(false);
        setAllSources(
          data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      } else setLoading(false);
    });
  }, []);
  const currentYear = new Date().getFullYear();
  const futureYear = currentYear + 15;

  const yearArray = [];
  for (let year = currentYear; year <= futureYear; year++) {
    yearArray.push({ value: year, label: String(year) });
  }
  const data1 = [
    { value: "Fall", label: "Fall" },
    { value: "Spring", label: "Spring" },
    { value: "Summer", label: "Summer" },
  ];
  const data3 = [
    { value: "Facebook", label: "Facebook" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Instagram", label: "Instagram" },
  ];

  const onSubmit = () => {
    setStudent({
      ...student,
      preferred_semester: semester.value,
      preferred_year: year.value.toString(),
      referal_source: source.value,
      customRefferalSource: othersRefSource,
    });
    if (semester.length != 0 && year.length != 0 && source.length != 0)
      setStep(8);
  };
  return (
    <div className="obStep_main_div">
      {step == 7 ? (
        <FormLayout
          back={() => setStep(6)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={counselor ? "Start Date" : "When do you want to start?"}
          minHeight="fit-content"
          width="90%"
          loading={loading}
          body={
            <div className="step_body">
              <div className="dropdown_flex">
                <DropDown
                  value={semester}
                  placeholder="Choose a semester"
                  setSelected={setSemester}
                  data={data1}
                  blueArrow={true}
                  width="47%"
                  multiSelection={false}
                  searchable={false}
                  selectedData={semester}
                />
                <DropDown
                  value={year}
                  placeholder="Choose a year"
                  setSelected={setYear}
                  data={yearArray}
                  blueArrow={true}
                  width="47%"
                  multiSelection={false}
                  searchable={false}
                  selectedData={year}
                />
              </div>
              <div>
                <div className="mainCard_heading">
                  <h1 className="mainCard_header">
                    {counselor
                      ? "Referral Source"
                      : "How did you come to know about us?"}
                  </h1>
                </div>
                <DropDown
                  value={source}
                  placeholder="e.g: Social Media"
                  setSelected={setSource}
                  data={allSources}
                  blueArrow={true}
                  width="100%"
                  multiSelection={false}
                  searchable={false}
                  selectedData={source}
                />
              </div>
              <div>
                {source.label == "Others" && (
                  <Input
                    placeholder="Please specify your refferal source."
                    width="100%"
                    type="text"
                    onChange={(e) => {
                      setOthersRefSource(e.target.value);
                    }}
                    required={true}
                  />
                )}
              </div>
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={
                  (semester.length == 0 ||
                    year.length == 0 ||
                    source.length == 0) &&
                  true
                }
              />
            </div>
          }
          triangle={true}
          icon={<Triangle />}
          bg="var(--pink)"
          gap="20px"
        />
      ) : (
        <OBStep8
          step={step}
          counselor={counselor}
          setStep={setStep}
          student={student}
          setStudent={setStudent}
        />
      )}
    </div>
  );
}

export default OBStep7;
