import React from "react";
import "./OveriewCard.scss";
import arrow2 from "../../Assets/Images/arrow2.webp";

function OveriewCard(props) {
  return (
    <div
      className="OverviewCard_container"
      onClick={props.onClick}
      style={{ ...(props.width && { width: props.width }) }}
    >
      <div className="OverviewCard_container_inner">
        <div className="OverviewCard_container_inner_left">
          <div
            className={
              props.color == "pink"
                ? "OverviewCard_number pink_drop_shadow"
                : props.color == "yellow"
                ? "OverviewCard_number yellow_drop_shadow"
                : props.color == "blue"
                ? "OverviewCard_number blue_drop_shadow"
                : props.color == "green"
                ? "OverviewCard_number green_drop_shadow"
                : "OverviewCard_number"
            }
          >
            {props.number}
          </div>
          <div className="overview_right">
            <div className="overview_right_title">{props.title}</div>
            <div className="overview_right_text">{props.text}</div>
          </div>
        </div>
        <div className="arrow">
          <img src={arrow2} alt="arrow" />
        </div>
      </div>
    </div>
  );
}

export default OveriewCard;
