import React from "react";
import "./MobilePage.scss";
function MobilePage() {
  return (
    <div className="mobilePage_main_div">
      <div className="mobilePage_header">Hello!</div>
      <div className="mobilePage_subHeader">
        Please make sure to open 
        this page on your desktop for
  a better experience!
      </div>
    </div>
  );
}

export default MobilePage;
