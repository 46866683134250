const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  AllApplications: [],
  getAllApplicationsError: "",
  getAllApplicationsSuccess: false,
  getAllApplicationsLoading: false,
  /////////////////////////
  singleApplication: {},
  getSingleAppLoading: false,
  getSingleAppSuccess: false,
  getSingleAppError: "",
  /////////////////////////
  singleApplicationMissingDoc: [],
  singleApplicationMissingDocsLoading: false,
  singleApplicationMissingDocsSuccess: false,
  singleApplicaitionMissingDocsError: "",

  /////////////////////////
  unifiedApplication: {},
  unifiedApplicationLoading: false,
  unifiedApplicationSuccess: false,
  unifiedApplicationError: "",
  /////////////////////////
  cancelApplicationLoading: false,
  cancelApplicationSuccess: false,
  cancelApplicationError: "",
  /////////////////////////
  uploadDocumentLoading: false,
  uploadDocumentSuccess: false,
  uploadDocumentFail: "",

  /////////////////////////
  updateUnifiedApplicationLoading: false,
  updateUnifiedApplicationSuccess: false,
  updateUnifiedApplicationError: "",

  /////////////////////////
  updateUnifiedDocumentLoading: false,
  updateUnifiedDocumentSuccess: false,
  updateUnifiedDocumentError: "",

  /////////////////////////
  chooseUnifiedDocumentLoading: false,
  chooseUnifiedDocumentSuccess: false,
  chooseUnifiedDocumentError: "",

  /////////////////////////
  makeThisApplicationUnifiedLoading: false,
  makeThisApplicationUnifiedSuccess: false,
  makeThisApplicationUnifiedError: "",

  ///////////////////////
  ApplicationFeed: [],
  getApplicationFeedLoading: false,
  getApplicationFeedSuccess: false,
  getApplicationFeedError: "",
};

const AppSlice = createSlice({
  name: "Applications",
  initialState,
  reducers: {
    getAllApplicationsRequest(state) {
      state.getAllApplicationsLoading = true;
    },
    getAllApplicationsSuccess(state, action) {
      state.AllApplications = action.payload;
      state.getAllApplicationsSuccess = true;
      state.getAllApplicationsError = "";
      state.getAllApplicationsLoading = false;
    },
    getAllApplicationsError(state, action) {
      state.AllApplications = [];
      state.getAllAppsSuccess = false;
      state.getAllAppsError = action.payload;
      state.getAllAppsLoading = false;
    },
    ////////get Single Application////////
    getSingleApplicationRequest(state) {
      state.getSingleAppLoading = true;
    },
    getSignleApplicationSuccess(state, action) {
      state.singleApplication = action.payload;
      state.getSingleAppSuccess = true;
      state.getSingleAppLoading = false;
      state.getSingleAppError = "";
    },
    getSingleApplicationError(state, action) {
      state.singleApplication = {};
      state.getSingleAppLoading = false;
      state.getSingleAppSuccess = false;
      state.getSingleAppError = action.payload;
    },
    ////////get Single Application Missing Documents////////
    getSingleApplicationMissingDocsRequest(state) {
      state.singleApplicationMissingDocsLoading = true;
    },
    getSingleApplicationMissingDocsSuccess(state, action) {
      state.singleApplicationMissingDoc = action.payload;
      state.singleApplicationMissingDocsLoading = false;
      state.singleApplicationMissingDocsSuccess = true;
      state.singleApplicaitionMissingDocsError = "";
    },
    getSingleApplicationMissingDocsError(state, action) {
      state.singleApplicationMissingDoc = [];
      state.singleApplicationMissingDocsLoading = false;
      state.singleApplicationMissingDocsSuccess = false;
      state.singleApplicaitionMissingDocsError = action.payload;
    },
    ////////get Unified Application////////
    getUnifiedApplicationRequest(state) {
      state.unifiedApplicationLoading = true;
      state.unifiedApplication = {};
      state.unifiedApplicationSuccess = false;
      state.unifiedApplicationError = "";
    },
    getUnifiedApplicationSuccess(state, action) {
      state.unifiedApplication = action.payload;
      state.unifiedApplicationLoading = false;
      state.unifiedApplicationSuccess = true;
      state.unifiedApplicationError = "";
    },
    getUnifiedApplicationError(state, action) {
      state.unifiedApplication = {};
      state.unifiedApplicationLoading = false;
      state.unifiedApplicationSuccess = false;
      state.unifiedApplicationError = action.payload;
    },
    //////// cancel application ////////
    cancelApplicationRequest(state) {
      state.cancelApplicationLoading = true;
    },
    cancelApplicationSuccess(state) {
      state.cancelApplicationLoading = false;
      state.cancelApplicationSuccess = true;
      state.cancelApplicationError = "";
    },
    cancelApplicationError(state) {
      state.cancelApplicationLoading = false;
      state.cancelApplicationSuccess = false;
      state.cancelApplicationError = "";
    },
    ////////  upload document ////////
    uploadDocumentRequest(state) {
      state.uploadDocumentLoading = true;
      state.uploadDocumentFail = "";
      state.uploadDocumentSuccess = false;
    },
    uploadDocumentSuccess(state, action) {
      state.uploadDocumentLoading = false;
      state.uploadDocumentSuccess = action.payload;
      state.uploadDocumentFail = "";
    },
    uploadDocumentError(state, action) {
      state.uploadDocumentLoading = false;
      state.uploadDocumentSuccess = false;
      state.uploadDocumentFail = action.payload;
    },

    ////////  update unified application ////////
    updateUnifiedApplicationRequest(state) {
      state.updateUnifiedApplicationLoading = true;
      state.updateUnifiedApplicationSuccess = false;
      state.updateUnifiedApplicationError = "";
    },
    updateUnifiedApplicationSuccess(state) {
      state.updateUnifiedApplicationLoading = false;
      state.updateUnifiedApplicationSuccess = true;
      state.updateUnifiedApplicationError = "";
    },
    updateUnifiedApplicationError(state, action) {
      state.updateUnifiedApplicationLoading = false;
      state.updateUnifiedApplicationSuccess = false;
      state.updateUnifiedApplicationError = action.payload;
    },

    ////////  update unified document ////////
    updateUnifiedDocumentRequest(state) {
      state.updateUnifiedDocumentLoading = true;
      state.updateUnifiedDocumentSuccess = false;
      state.updateUnifiedDocumentError = "";
    },
    updateUnifiedDocumentSuccess(state) {
      state.updateUnifiedDocumentLoading = false;
      state.updateUnifiedDocumentSuccess = true;
      state.updateUnifiedDocumentError = "";
    },
    updateUnifiedDocumentError(state, action) {
      state.updateUnifiedDocumentLoading = false;
      state.updateUnifiedDocumentSuccess = false;
      state.updateUnifiedDocumentError = action.payload;
    },

    ////////  choose unified document ////////
    chooseUnifiedDocumentRequest(state) {
      state.chooseUnifiedDocumentLoading = true;
      state.chooseUnifiedDocumentSuccess = false;
      state.chooseUnifiedDocumentError = "";
    },
    chooseUnifiedDocumentSuccess(state) {
      state.chooseUnifiedDocumentLoading = false;
      state.chooseUnifiedDocumentSuccess = true;
      state.chooseUnifiedDocumentError = "";
    },
    chooseUnifiedDocumentError(state, action) {
      state.chooseUnifiedDocumentLoading = false;
      state.chooseUnifiedDocumentSuccess = false;
      state.chooseUnifiedDocumentError = action.payload;
    },

    ////////  make this application unified ////////
    makeThisApplicationUnifiedRequest(state) {
      state.makeThisApplicationUnifiedLoading = true;
      state.makeThisApplicationUnifiedSuccess = false;
      state.makeThisApplicationUnifiedError = "";
    },
    makeThisApplicationUnifiedSuccess(state) {
      state.makeThisApplicationUnifiedLoading = false;
      state.makeThisApplicationUnifiedSuccess = true;
      state.makeThisApplicationUnifiedError = "";
    },
    makeThisApplicationUnifiedError(state, action) {
      state.makeThisApplicationUnifiedLoading = false;
      state.makeThisApplicationUnifiedSuccess = false;
      state.makeThisApplicationUnifiedError = action.payload;
    },

    //////// get Application Feed ////////
    getApplicationFeedRequest(state) {
      state.getApplicationFeedLoading = true;
    },
    getApplicationFeedSuccess(state, action) {
      state.ApplicationFeed = action.payload;
      state.getApplicationFeedLoading = false;
      state.getApplicationFeedSuccess = true;
      state.getApplicationFeedError = "";
    },
    getApplicationFeedError(state, action) {
      state.ApplicationFeed = [];
      state.getApplicationFeedLoading = false;
      state.getApplicationFeedSuccess = false;
      state.getApplicationFeedError = action.payload;
    },
  },
});
export const ApplicationActions = AppSlice.actions;
export default AppSlice;
