import { axiosReq } from "../../../Utils";
import { SalesCounselorActions } from "./SalesCounselorReducers";

export const getstats = (from, to) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/sales/students/statistics";
  dispatch(SalesCounselorActions.GetStatsRequest());
  dispatch(axiosReq("get", url, {}, {}, { from: from, to: to })).then((res) => {
    if (res.success) {
      dispatch(SalesCounselorActions.GetStatsSuccess(res.data));
    } else {
      dispatch(
        SalesCounselorActions.GetStatsFail(res.data.response.data.message)
      );
    }
  });
};

export const getallcouselors = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/user/counselors";
  dispatch(SalesCounselorActions.getallcouselorsRequest());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(SalesCounselorActions.getallcouselorsSuccess(res.data));
    } else {
      dispatch(
        SalesCounselorActions.getallcouselorsFail(
          res.data.response.data.message
        )
      );
    }
  });
};

export const assignstudents = (data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/sales/students/assign";
  dispatch(SalesCounselorActions.AssignStudentRequest());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(SalesCounselorActions.AssignStudentSuccess(res.data));
    } else {
      dispatch(
        SalesCounselorActions.AssignStudentFail(res.data.response.data.message)
      );
    }
  });
};

export const getstudentsall = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/student";

  dispatch(SalesCounselorActions.getstudentsRequest());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(SalesCounselorActions.getstudentsSuccess(res.data));
    } else {
      dispatch(
        SalesCounselorActions.getstudentsFail(res.data.response.data.message)
      );
    }
  });
};
