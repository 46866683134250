import { MyCounselorActions } from "./MyCounselorReducer";
import { axiosReq } from "../../../Utils";
import { getCounselorMessages } from "../../../Counsler/Redux/CounselorChat/CounselorChatActions";
import { getstudents } from "../../../Sales/Redux/Students/StudentsActions";

export const getStudentEvents = () => async (dispatch) => {
  dispatch(MyCounselorActions.fetchEventsRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/meetings/latest-events",
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.fetchEventsSuccess(res.data));
    } else {
      dispatch(
        MyCounselorActions.fetchEventsFail(res.data.response.data.message)
      );
    }
  });
};

export const getStudentTasks = () => async (dispatch) => {
  dispatch(MyCounselorActions.fetchTasksRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/tasks/student", {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.fetchTasksSuccess(res.data));
    } else {
      dispatch(
        MyCounselorActions.fetchTasksFail(res.data.response.data.message)
      );
    }
  });
};

export const changeTaskStatus = (taskId) => async (dispatch) => {
  dispatch(MyCounselorActions.changeTaskStatusRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/tasks/submit",
      {},
      { taskId: taskId }
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.changeTaskStatusSuccess());
      dispatch(getStudentTasks());
    } else {
      dispatch(
        MyCounselorActions.changeTaskStatusFail(res.data.response.data.message)
      );
    }
  });
};

export const getSchedualTime = (date, counselorId) => async (dispatch) => {
  dispatch(MyCounselorActions.getTimeSlotsRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + `/counselor/schedules/${counselorId}/${date}`,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.getTimeSlotsSuccess(res.data.schedule));
    } else {
      dispatch(
        MyCounselorActions.getTimeSlotsFail(res.data.response.data.message)
      );
    }
  });
};

export const getSchedualTimeSales = (date, counselorId) => async (dispatch) => {
  dispatch(MyCounselorActions.getTimeSlotsRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API +
        "/counselor/schedules/" +
        counselorId +
        "/" +
        date,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.getTimeSlotsSuccess(res.data.schedule));
    } else {
      dispatch(
        MyCounselorActions.getTimeSlotsFail(res.data.response.data.message)
      );
    }
  });
};

export const requestMeeting = (data, getdata) => async (dispatch) => {
  dispatch(MyCounselorActions.requestMeetingRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/meetings/request-a-meeting",
      {},
      data
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.requestMeetingSuccess());
      setTimeout(() => {
        dispatch(MyCounselorActions.requestMeetingFail(""));
      }, 2000);
      if (getdata) {
        dispatch(getstudents("", 10, 0));
      }
    } else {
      dispatch(
        MyCounselorActions.requestMeetingFail(res.data.response.data.message)
      );
    }
  });
};

export const getDays = () => async (dispatch) => {
  dispatch(MyCounselorActions.getDaysRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/counselor/days/types", {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.getDaysSuccess(res.data));
    } else {
      dispatch(MyCounselorActions.getDaysFail(res.data.response.data.message));
    }
  });
};

export const getChats = (user_id, counselor_id) => async (dispatch) => {
  dispatch(MyCounselorActions.fetchChatRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API +
        "/user/messages/" +
        user_id +
        "/" +
        counselor_id,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MyCounselorActions.fetchChatSuccess(res.data));
    } else {
      dispatch(
        MyCounselorActions.fetchChatFail(res.data.response.data.message)
      );
    }
  });
};

export const sendMessage =
  (conversation, message, user_id, couselor_id, counselor_fcm, type, file) =>
  async (dispatch) => {
    if (type === "file") {
      const files = new FormData();

      files.append("message", message);
      files.append("conversation", conversation);
      files.append("sender", user_id);
      files.append(
        "receiver",
        JSON.stringify({ id: couselor_id, fcm_token: counselor_fcm })
      );
      files.append("file", file);

      dispatch(MyCounselorActions.sendMessageRequest());
      dispatch(
        axiosReq("post", process.env.REACT_APP_API + "/user/message", {}, files)
      ).then((res) => {
        if (res.success) {
          dispatch(MyCounselorActions.sendMessageSuccess());
          dispatch(getChats(user_id, couselor_id));
          dispatch(getCounselorMessages(user_id, couselor_id));
        } else {
          dispatch(
            MyCounselorActions.sendMessageFail(res.data.response.data.message)
          );
        }
      });
    } else {
      dispatch(MyCounselorActions.sendMessageRequest());
      dispatch(
        axiosReq(
          "post",
          process.env.REACT_APP_API + "/user/message",
          {},
          {
            conversation: conversation,
            message: message,
            sender: user_id,
            receiver: { id: couselor_id, fcm_token: counselor_fcm },
          }
        )
      ).then((res) => {
        if (res.success) {
          dispatch(MyCounselorActions.sendMessageSuccess());
        } else {
          dispatch(
            MyCounselorActions.sendMessageFail(res.data.response.data.message)
          );
        }
      });
    }
  };
