import React from "react";
import "./Star.scss";
import star from "../../../Assets/Images/star.png";
import starGif from "../../../Assets/Images/star.gif";

function Star() {
  return (
    <div className="Star_Container">
      <img src={star} alt="Star" className="Star" />
      <img src={starGif} alt="Star" className="Star_Gif" />
    </div>
  );
}

export default Star;
