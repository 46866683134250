import React, { useEffect, useState } from "react";
import edit from "../../../Shared/Assets/Images/edit.png";
import trash from "../../../Shared/Assets/Images/trash.png";
import Popup from "../../../Shared/Components/Popup/Popup";
import Button from "../../../Shared/Components/Button/Button";
import "./PassportTable.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserPassport } from "../../Redux/Student/StudentActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Shared/Components/Loader/Loader";

function PassportTable({
  setToEditPassport,
  userPassport,
  setUserPassport,
  setPassportEdit,
  setPassportCountry,
  setPassportNationality,
  setPassportDate,
  setPassportNumber,
  setPassport,
  setOpenPassportPopup,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    student,
    addPassportSuccess,
    deletePassportSuccess,
    addPassportLoading,
    deletePassportFail,
    deletePassportLoading,
  } = useSelector((state) => state.StudentReducer);

  const handleRemovePassport = (index) => {
    dispatch(deleteUserPassport(navigate, index, student?.id));
  };
  const handlePassportEdit = (index, item) => {
    setPassportEdit(true);
    setPassportCountry({ label: item.country, value: item.country });
    setPassportNationality({
      label: item.nationality,
      value: item.nationality,
    });
    setPassportDate(item.expiry);
    setPassportNumber(item.number);
    setPassport(item.document);
    setOpenPassportPopup(true);
  };

  const [DeleteConfirmPassport, setDeleteConfirmPassport] = useState(false);
  const [selectedId, setSelectedId] = useState();

  return (
    <div className="added_passports">
      <table className="added_passports_table">
        <tbody>
          {userPassport?.map((item, index) => {
            return (
              <tr className="added_passports_table_row" key={index}>
                <td
                  className={index == userPassport.length - 1 ? "last" : ""}
                  style={{
                    textAlign: "left",
                    fontFamily: "P_Bold",
                  }}
                >
                  P#{index + 1}
                </td>
                <td className={index == userPassport.length - 1 ? "last" : ""}>
                  {item.nationality}&nbsp;Passport
                </td>
                <td className={index == userPassport.length - 1 ? "last" : ""}>
                  {item.number}
                </td>
                <td className={index == userPassport.length - 1 ? "last" : ""}>
                  {item.country}
                </td>
                <td className={index == userPassport.length - 1 ? "last" : ""}>
                  {item.nationality}
                </td>
                <td className={index == userPassport.length - 1 ? "last" : ""}>
                  exp: {item.expiry}
                </td>
                <td
                  className={index == userPassport.length - 1 ? "last" : ""}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={edit}
                    alt="edit"
                    width="20px"
                    onClick={() => {
                      setToEditPassport(item);
                      handlePassportEdit(index, item);
                    }}
                  />
                </td>
                <td
                  className={index == userPassport.length - 1 ? "last" : ""}
                  style={{ textAlign: "right", cursor: "pointer" }}
                >
                  <img
                    src={trash}
                    alt="trash"
                    width={"20px"}
                    onClick={() => {
                      setDeleteConfirmPassport(true);
                      setSelectedId(item.id);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {DeleteConfirmPassport && (
        <Popup
          setShowPopup={setDeleteConfirmPassport}
          width={"30rem"}
          body={
            <div className="delete_Confirm_Popup">
              <div className="delete_Confrim_Title">
                Are you sure you want to delete this passport?
              </div>
              {deletePassportFail && (
                <div
                  style={{
                    color: "var(--pink)",
                    fontFamily: "P_Semibold",
                    fontSize: "1.2rem",
                    textAlign: "center",
                    width: "100%",
                    margin: "-0.5rem 0 1rem 0",
                  }}
                >
                  {deletePassportFail}
                </div>
              )}
              {!deletePassportLoading ? (
                <div className="delete_Confrim_Body">
                  <Button
                    text={"Yes"}
                    color={"yellow"}
                    onClick={() => {
                      handleRemovePassport(selectedId);
                    }}
                  />
                  <Button
                    text={"Cancel"}
                    color={"pink"}
                    onClick={() => {
                      setDeleteConfirmPassport(false);
                    }}
                  />
                </div>
              ) : (
                <div className="delete_confrim_body">
                  <Button
                    text={<Loader onlyDots={true} />}
                    disabled={true}
                    color={"yellow"}
                    width={"100%"}
                  />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
}

export default PassportTable;
