import React from "react";
import "./CounselorOverviewCard.scss";
import arrow2 from "../../../Shared/Assets/Images/arrow2.webp";

function CounselorOverviewCard(props) {
  return (
    <div className="CounselorOverviewCard_container" onClick={props.onClick}>
      <div
        className={
          props.color == "pink"
            ? "CounselorOverviewCard_number pink_drop_shadow"
            : props.color == "blue"
            ? "CounselorOverviewCard_number blue_drop_shadow"
            : props.color == "green"
            ? "CounselorOverviewCard_number green_drop_shadow"
            : props.color == "yellow"
            ? "CounselorOverviewCard_number yellow_drop_shadow"
            : "CounselorOverviewCard_number"
        }
      >
        {props.number}
      </div>
      <div className="CounselorOverviewCard_text">{props.text}</div>
      <img
        className="CounselorOverviewCard_arrow"
        src={arrow2}
        alt="right_arrow"
      />
    </div>
  );
}

export default CounselorOverviewCard;
