import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Shared/Pages/Login/Login";
import NavBar from "./Shared/Components/NavBar/NavBar";
import SignUp from "./Student/Pages/SignUp/SignUp";
import Profile from "./Shared/Pages/Profile/Profile";
import CompleteProfilee from "./Student/Pages/CompleteProfilee/CompleteProfilee";
import OTPVerify from "./Student/Pages/OTPVerify/OTPVerify";
import ChangePass from "./Student/Pages/ChangePass/ChangePass";
import NotFound from "./Shared/Pages/NotFound/NotFound";
import Overview from "./Student/Pages/Overview/Overview";
import SingleUni from "./Student/Pages/SingleUni/SingleUni";
import Universities from "./Shared/Pages/Universities/Universities";
import ApplicationDetails from "./Counsler/Pages/ApplicationDetails/ApplicationDetails";
import MyCounsler from "./Student/Pages/MyCounsler/MyCounsler";
import Application from "./Student/Pages/Application/Application";
import SingleApplication from "./Student/Pages/SingleApplication/SingleApplication";
import MyDocuments from "./Student/Pages/MyDocuments/MyDocuments";
import ZoomMeeting from "./Student/Pages/ZoomMeeting/ZoomMeeting";
import ApplicationStep1 from "./Shared/Pages/Application/Application1/ApplicationStep1";
import { getStudentMe } from "./Student/Redux/Student/StudentActions";
import CounselorStudents from "./Counsler/Pages/CounselorStudents/CounselorStudents";
import CounselorOverview from "./Counsler/Pages/CounselorOverview/CounselorOverview";
import CreateStudent from "./Counsler/Pages/CreateStudent/CreateStudent";
import CreateApplication from "./Counsler/Pages/CreateApplication/CreateApplication";
import CounselorMeeting from "./Counsler/Pages/CounselorMeeting/CounselorMeeting";
import Leads from "./Sales/Pages/Leads/Leads";
import CounselorApplications from "./Counsler/Pages/CounelorApplications/CounselorApplications";
import CounselorSingleStudent from "./Counsler/Pages/CounselorSingleStudent/CounselorSingleStudent";
import CounselorChat from "./Counsler/Pages/CounselorChat/CounselorChat";
import SalesConselorPage from "./Sales/Pages/SalesConselorPage/SalesConselorPage";
import UniversitiesSales from "./Sales/Pages/Universities/SalesUniversities";
import CRM from "./Sales/Pages/CRM/CRM";
import SalesSources from "./Sales/Pages/SalesSources/SalesSources";
import Students from "./Sales/Pages/Students/Students";
import Popup from "./Shared/Components/Popup/Popup";
import Button from "./Shared/Components/Button/Button";
import {
  getUser,
  getUserRole,
  logoutUser,
  phoneVerification,
} from "./Student/Redux/User/UserActions";
import Loader from "./Shared/Components/Loader/Loader";
import UnifiedApplication from "./Student/Pages/UnifiedApplication/UnifiedApplication";
import { getAllNotifications } from "./Shared/Redux/Notification/NotificationActions";
import { IoNavigateCircle } from "react-icons/io5";
import { openDB } from "idb";
import { NotificationActions } from "./Shared/Redux/Notification/NotificationReducer";
import MobilePage from "./Sales/Pages/MobilePage/MobilePage";
import UseInnerWidth from "./Hooks/UseInnerWidth";
import PrivacyPolicy from "./Shared/Pages/PrivacyPolicy/PrivacyPolicy";
import Documentation from "./Shared/Pages/Documentation/Documentation";
import Homepage3 from "./Shared/Pages/Homepage3/Homepage3";
import TermsNConditions from "./Shared/Pages/TermsNConditions/TermsNConditions";
import ContacUs from "./Shared/Pages/ContactUs/ContacUs";
import Footer from "./Shared/Components/Footer/Footer";

function SharedRoutes() {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { student } = useSelector((store) => store.StudentReducer);
  const {
    user,
    getUserRoleLoading,
    role,
    getUserRoleSuccess,
    getUserRoleError,
  } = useSelector((store) => store.userReducer);
  const [hasPhone, setHasPhone] = useState(true);
  const { phoneVerifyLoading, phoneVerifySuccess } = useSelector(
    (store) => store.userReducer
  );
  const [showPop, setShowPop] = useState(false);
  const [showeAccept, setShoweAccept] = useState(false);

  useEffect(() => {
    if (Object.keys(student).length === 0) {
      if (
        !pathname.includes("login") &&
        !pathname.includes("signUp") &&
        pathname != "/" &&
        !pathname.includes("privacyPolicy") &&
        !pathname.includes("documentation") &&
        !pathname.includes("termsAndConditions") &&
        !pathname.includes("contactUs") &&
        role == "student"
      ) {
        dispatch(getStudentMe());
      }
    }
    if (user && Object.keys(user).length === 0) {
      if (
        pathname != "/" &&
        !pathname.includes("signUp") &&
        !pathname.includes("privacyPolicy") &&
        !pathname.includes("documentation") &&
        !pathname.includes("termsAndConditions") &&
        !pathname.includes("contactUs")
      ) {
        dispatch(getUser());
      }
    }
    if (
      !role &&
      pathname != "/" &&
      !pathname.includes("signUp") &&
      !pathname.includes("privacyPolicy") &&
      !pathname.includes("documentation") &&
      !pathname.includes("termsAndConditions") &&
      !pathname.includes("contactUs")
    ) {
      dispatch(getUserRole());
    }
  }, [pathname, role]);

  useEffect(() => {
    if (
      user &&
      !pathname.includes("login") &&
      !pathname.includes("signUp") &&
      pathname != "/" &&
      !pathname.includes("privacyPolicy") &&
      !pathname.includes("documentation") &&
      !pathname.includes("termsAndConditions") &&
      !pathname.includes("contactUs") &&
      role == "student"
    ) {
      if (
        user &&
        user.phone_verified === false &&
        !pathname.includes("otpVerification")
      ) {
        if (user.phone === null) {
          setHasPhone(false);
        } else {
          setHasPhone(true);
        }
        setShowPop(true);
      } else {
        setShowPop(false);
      }
    } else {
      setShowPop(false);
    }
  }, [user, pathname, role]);

  useEffect(() => {
    if (role) {
      if (
        pathname.includes("login") ||
        pathname == "/sales" ||
        pathname == "/counselor" ||
        pathname == "/student"
      ) {
        if (role == "sales") {
          navigate(`/${role}/leads`);
        } else if (role != "admin") navigate(`/${role}/overview`);
        else {
          dispatch(logoutUser());
        }
      }
    }
  }, [role]);

  //scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const verfiyPhone = () => {
    if (hasPhone) {
      if (user) {
        dispatch(
          phoneVerification({
            userId: user.id,
            phone: user.phone,
          })
        );
      }
    } else {
      navigate("/student/otpVerification", {
        state: { user: user.id },
      });
    }
  };

  const { isAccepted, acceptedLink } = useSelector(
    (store) => store.notificationReducer
  );

  useEffect(() => {
    if (
      !showPop &&
      !pathname.includes("login") &&
      !pathname.includes("signUp") &&
      pathname != "/" &&
      !pathname.includes("privacyPolicy") &&
      !pathname.includes("documentation") &&
      !pathname.includes("termsAndConditions") &&
      !pathname.includes("contactUs")
    ) {
      if (isAccepted.includes("Accepted")) {
        setShoweAccept(true);
      } else {
        setShoweAccept(false);
      }
    }
  }, [pathname, isAccepted]);

  useEffect(() => {
    if (
      phoneVerifySuccess &&
      !pathname.includes("login") &&
      !pathname.includes("signUp") &&
      pathname != "/" &&
      !pathname.includes("privacyPolicy") &&
      !pathname.includes("documentation") &&
      !pathname.includes("termsAndConditions") &&
      !pathname.includes("contactUs") &&
      Object.keys(student).length > 0
    ) {
      setShowPop(false);
      navigate("/student/otpVerification", {
        state: { user: student?.user.id },
      });
    }
  }, [phoneVerifySuccess]);

  const handleDoneClick = () => {
    navigate("/student/my_applications/" + acceptedLink.id);
    dispatch(NotificationActions.resetIsAccepted());
    openDB("notificationsDBb", 1).then((db) => {
      const transaction = db.transaction("notificationss", "readwrite");
      const store = transaction.objectStore("notificationss");

      store.getAllKeys().then((keys) => {
        const deletePromises = keys.map((key) => store.delete(key));

        Promise.all(deletePromises).then(() => {});
      });
    });
  };

  const screenWidth = UseInnerWidth();
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Homepage3 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sales" element={<Login staff={true} />} />
        <Route path="/counselor" element={<Login staff={true} />} />
        <Route path="/student" element={<Login />} />
        <Route path="/student/login/" element={<Login />} />
        <Route path="/sales/login/" element={<Login staff={true} />} />
        <Route path="/counselor/login/" element={<Login staff={true} />} />
        <Route path="/staff/login/" element={<Login staff={true} />} />
        <Route
          path="/login/otpVerification/"
          element={<OTPVerify email={false} forget={true} />}
        />{" "}
        <Route path="/student/signUp/" element={<SignUp />} />
        <Route path="/login/changePassword/" element={<ChangePass />} />
        <Route path="/documentation/" element={<Documentation />} />
        <Route path="/privacyPolicy/" element={<PrivacyPolicy hide={true} />} />
        <Route
          path="/termsAndConditions/"
          element={<TermsNConditions hide={true} />}
        />
        <Route path="/contactUs" element={<ContacUs />} />
        <Route
          path="/student/signUp/otpVerification/"
          element={<OTPVerify email={false} />}
        />
        {!role &&
          !getUserRoleLoading &&
          (getUserRoleSuccess || getUserRoleError) && (
            <Route path="*" element={<NotFound />} />
          )}
      </Routes>

      {getUserRoleLoading ? (
        <Loader fullScreen={true} />
      ) : role == "student" ? (
        <Routes>
          <Route path="/student">
            <Route path="overview" element={<Overview />} />

            <Route path="zoom" element={<ZoomMeeting />} />

            <Route path="my_counselor" element={<MyCounsler />} />

            <Route path="my_documents" element={<MyDocuments />} />

            <Route path="universities" element={<Universities />} />

            <Route
              path="otpVerification"
              element={
                <OTPVerify email={false} showOtp={hasPhone} logged={true} />
              }
            />

            <Route
              path="universities/all"
              element={<Universities all={true} />}
            />

            <Route path="profile" element={<Profile />} />

            <Route
              path="profile/completeProfile"
              element={<CompleteProfilee />}
            />

            <Route path="universities/singleUni/:id" element={<SingleUni />} />

            <Route
              path="my_applications/unifiedApplication"
              element={<UnifiedApplication />}
            />

            <Route path="my_applications" element={<Application />} />

            <Route path="my_applications/:id" element={<SingleApplication />} />

            <Route
              path="my_applications/apply/:stepApp"
              element={<ApplicationStep1 />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : role == "counselor" ? (
        <Routes>
          <Route path="/counselor">
            <Route path="students" element={<CounselorStudents />} />
            <Route path="overview" element={<CounselorOverview />} />
            <Route path="applications" element={<CounselorApplications />} />
            <Route
              path="applications/create/:step"
              element={<CreateApplication />}
            />
            <Route
              path="applications/singleApp/:id"
              element={<ApplicationDetails counselor={true} />}
            />
            <Route
              path="universities"
              element={<Universities all={true} counselor={true} />}
            />
            <Route path="chat" element={<CounselorChat />} />
            <Route path="meeting" element={<CounselorMeeting />} />
            <Route path="profile" element={<Profile />} />
            <Route path="students/create" element={<CreateStudent />} />
            <Route path="universities/singleUni/:id" element={<SingleUni />} />
            <Route
              path="students/completeProfile/:id"
              element={<CompleteProfilee counselor={true} sales={false} />}
            />
            <Route
              path="students/singleStudent/:id"
              element={<CounselorSingleStudent sales={false} />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : role == "sales" && screenWidth > 1024 ? (
        <Routes>
          <Route path="/sales">
            <Route path="profile" element={<Profile />} />
            <Route path="students/create" element={<CreateStudent />} />
            <Route path="leads" element={<Leads />} />
            <Route path="counselor" element={<SalesConselorPage />} />
            <Route
              path="universities"
              element={<Universities all={true} counselor={true} />}
            />
            <Route path="universities/singleUni/:id" element={<SingleUni />} />
            <Route
              path="students/singleStudent/:id"
              element={<CounselorSingleStudent sales={true} />}
            />
            <Route
              path="students/completeProfile/:id"
              element={<CompleteProfilee sales={true} counselor={false} />}
            />
            {/* <Route path="universities" element={<UniversitiesSales />} /> */}
            <Route path="sources" element={<SalesSources />} />
            <Route path="students" element={<Students />} />
            <Route path="students/create" element={<CreateStudent />} />
            <Route path="crm" element={<CRM />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        role == "sales" &&
        screenWidth < 1024 && (
          <Routes>
            <Route path="/sales/*" element={<MobilePage />} />
          </Routes>
        )
      )}

      {showPop && (
        <Popup
          width="40%"
          body={
            phoneVerifyLoading ? (
              <Loader />
            ) : (
              <div
                style={{
                  padding: "1rem 1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontFamily: "P_Bold", margin: "0 0 0rem 0" }}>
                  Phone Number
                </h1>
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "P_Medium",
                    fontSize: "1.1rem",
                    marginBottom: "2rem",
                    color: "var(--darkgrey)",
                    width: "80%",
                  }}
                >
                  For security reasons, We kindly ask you to provide and verify
                  your phone number.
                </p>
                <Button
                  onClick={() => {
                    verfiyPhone();
                  }}
                  text="Verify"
                  color="yellow"
                  width="20rem"
                />
              </div>
            )
          }
        />
      )}
      {showeAccept && (
        <Popup
          width="40%"
          body={
            <div style={{ padding: "1rem" }}>
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "P_Bold",
                  fontSize: "2rem",
                }}
              >
                Congratulations!
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "P_Semibold",
                  margin: "0.5rem 0 1rem 0",
                }}
              >
                {isAccepted}
              </div>
              <Button
                text={"Check it out"}
                color={"yellow"}
                width={"100%"}
                onClick={() => handleDoneClick()}
              />
            </div>
          }
          confetti={true}
        />
      )}
    </>
  );
}

export default SharedRoutes;
