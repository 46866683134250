import React, { useEffect, useState } from "react";
import "./Overview.scss";
import OveriewCard from "../../../Shared/Components/OveriewCard/OveriewCard";
import Button from "../../../Shared/Components/Button/Button";
import OverviewCardProgress from "../../../Shared/Components/OverviewCardProgress/OverviewCardProgress";
import Popup from "../../../Shared/Components/Popup/Popup";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import check from "../../../Shared/Assets/Images/checkbox.svg";
import { getStudentMe } from "../../Redux/Student/StudentActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Shared/Components/Loader/Loader";
import {
  getOverview,
  getHelpReq,
  checkProgress,
} from "../../Redux/Overview/OverviewActions";
import CounselorOverviewCard from "../../../Counsler/Components/CounselorOverviewCard/CounselorOverviewCard";
function Overview() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [disabledClicked, SetDisabledClicked] = useState(false);

  const navigate = useNavigate();

  const [access, setAccess] = useState(false);

  const { user } = useSelector((state) => state.userReducer);
  const { student, fetchLoading } = useSelector(
    (state) => state.StudentReducer
  );
  const { overview, fetchOverviewLoading, checkProgressLoading } = useSelector(
    (state) => state.OverviewReducer
  );
  useEffect(() => {
    dispatch(getOverview());
  }, []);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (student.hasOwnProperty("progress") && !checked) {
      if (
        !student?.progress?.Complete_your_profile ||
        !student?.progress?.Apply_to_universities ||
        !student?.progress?.Upload_needed_documents ||
        !student?.progress?.Schedule_a_meeting
      ) {
        setChecked(true);
        dispatch(checkProgress());
      }
    }
  }, [student, checked]);

  useEffect(() => {
    setAccess(fetchOverviewLoading || checkProgressLoading || fetchLoading);
  }, [fetchOverviewLoading, checkProgressLoading, fetchLoading]);
  return (
    <div className="overview_container">
      {access && <Loader fullScreen={true} />}
      <div className="overview_top">
        <div className="overview_top_name">
          Hello {student?.user?.first_name}
        </div>
        <br />

        <div className="overview_top_cards">
          <div className="Overview_top_body_Twodiv">
            <CounselorOverviewCard
              number={overview?.applications_submitted}
              color="green"
              title="Applications Submitted"
              text={
                <>
                  Applications
                  <br />
                  Submitted
                </>
              }
              onClick={() => navigate("/student/my_applications")}
            />
            <CounselorOverviewCard
              number={overview?.number_of_offers}
              color="blue"
              title="Offers Received"
              text={
                <>
                  Offers
                  <br />
                  Received
                </>
              }
              onClick={() => navigate("/student/my_applications")}
            />
          </div>
          <div className="Overview_top_body_Twodiv">
            <CounselorOverviewCard
              number={overview?.favourites}
              color="pink"
              title="Your Favorite Universities"
              text={
                <>
                  Favorite
                  <br />
                  Universities
                </>
              }
              onClick={() => navigate("/student/universities")}
            />
            <CounselorOverviewCard
              number={overview?.missing}
              color="yellow"
              title="Missing Documents"
              text={
                <>
                  Missing
                  <br />
                  Documents
                </>
              }
              onClick={() => navigate("/student/my_documents")}
            />
          </div>
        </div>
      </div>
      <div className="overview_bottom">
        <div className="overview_bottom_left">
          <div className="overview_bottom_left_title">
            Explore, Apply And Grow Your Education
          </div>
          <div className="overview_bottom_left_buttons">
            <Button
              text={<span className="button_text">Take the Career Test</span>}
              color="blue"
              width={"25rem"}
            />
            <Button
              text={<span className="button_text">Check Previous Results</span>}
              color="yellow"
              width={"25rem"}
            />
            <Button
              text={<span className="button_text">Need Help?</span>}
              color="lightGreen"
              width={"25rem"}
              onClick={() => setShowPopup(true)}
            />
          </div>
        </div>
        <div className="overview_bottom_right">
          <div className="overview_bottom_right_inner">
            <div className="overview_bottom_right_inner_title">
              Your Progress
            </div>
            <div className="overview_bottom_right_inner_subtitle">
              After completing these tasks, a counselor will be assigned to help
              you in your journey.
            </div>
            <div className="overview_bottom_right_inner_cards">
              <OverviewCardProgress
                number={1}
                title="Complete Profile"
                // subTitle="Complete Profile"
                completed={
                  student?.progress?.Complete_your_profile ? true : false
                }
                onClick={() => navigate("/student/profile")}
              />
              <OverviewCardProgress
                number={2}
                title="Apply to at least one university"
                // subTitle="Apply Now"
                completed={
                  student?.progress?.Apply_to_universities ? true : false
                }
                onClick={() => navigate("/student/universities")}
              />
              <OverviewCardProgress
                number={3}
                title="Upload at least one document"
                // subTitle="Upload Now"
                completed={
                  student?.progress?.Upload_needed_documents ? true : false
                }
                onClick={() => navigate("/student/profile")}
              />
              <span
                onClick={() => {
                  !user?.Student?.counselor && SetDisabledClicked(true);
                }}
              >
                <OverviewCardProgress
                  number={4}
                  title="Schedule a meeting with counselor"
                  // subTitle="Schedule Meeting"
                  completed={
                    student?.progress?.Schedule_a_meeting ? true : false
                  }
                  disabled={user?.Student?.counselor ? false : true}
                  onClick={() => navigate("/student/my_counselor")}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      {disabledClicked && (
        <UpperPopUp
          setShow={SetDisabledClicked}
          title={"Finish your tasks to be assigned to a counselor!"}
        />
      )}
      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          body={
            <div className="needhelp_popup">
              <div className="needhelp_popup_title">Need Help?</div>
              <div className="needhelp_popup_subtitle">
                If you are having trouble with your application EB will contact
                you!
              </div>
              <div className="needhelp_popup_buttons">
                <Button
                  text="Yes"
                  color="pink "
                  width="100%"
                  onClick={() => {
                    dispatch(getHelpReq());
                    setShowPopup(false);
                    setShow(true);
                  }}
                />
                <Button
                  text="Cancel"
                  color="yellow"
                  width="100%"
                  onClick={() => setShowPopup(false)}
                />
              </div>
            </div>
          }
        />
      )}
      {show && (
        <UpperPopUp
          setShow={setShow}
          title="We will contact you within 24 hours."
          img={check}
        />
      )}
    </div>
  );
}

export default Overview;
