import { axiosReq } from "../../../Utils";
import { CounselorOverViewActions } from "./CounselorOverViewReducer";

export const getStatistics = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/counselor/overview";
  dispatch(CounselorOverViewActions.getStatisticsRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorOverViewActions.getStatisticsSuccess(res.data));
    } else {
      dispatch(
        CounselorOverViewActions.getStatisticsError(
          res.data.response.data.message
        )
      );
    }
  });
};
export const getTasks = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/tasks/student/assigned";
  dispatch(CounselorOverViewActions.getTasksRequet());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorOverViewActions.getTasksSuccess(res.data));
    } else {
      dispatch(
        CounselorOverViewActions.getTasksError(res.data.response.data.message)
      );
    }
  });
};
export const changeTaskStatus = (id, status) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/tasks/${id}/status`;
  dispatch(CounselorOverViewActions.changeTaskStatusRequest());
  dispatch(axiosReq("patch", url, {}, { status: status })).then((res) => {
    if (res.success) {
      dispatch(CounselorOverViewActions.changeTaskStatusSuccess(res.data));
      dispatch(getTasks());
    } else {
      dispatch(
        CounselorOverViewActions.changeTaskStatusError(
          res.data.response.data.message
        )
      );
    }
  });
};
