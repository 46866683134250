const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  ////////Get Statistics////////
  statistics: {},
  getStatisticsLoading: false,
  getStatisticsSuccess: false,
  getStatisticsError: "",
  ////////Get Students tasks////////
  tasks: [],
  getTasksloading: false,
  getTasksSuccess: false,
  getTasksError: "",
  ////////Patch task Status////////
  changeTaskStatusLoading: false,
  changeTaskStatusSuccess: false,
  ChangetaskStatusError: "",
};

const CounselorOverViewSlice = createSlice({
  name: "OverView",
  initialState,
  reducers: {
    ////////get Statistics////////
    getStatisticsRequest(state) {
      state.getStatisticsLoading = true;
    },
    getStatisticsSuccess(state, action) {
      state.statistics = action.payload;
      state.getStatisticsLoading = false;
      state.getStatisticsSuccess = true;
      state.getStatisticsError = "";
    },
    getStatisticsError(state, action) {
      state.statistics = {};
      state.getStatisticsLoading = false;
      state.getStatisticsSuccess = false;
      state.getStatisticsError = action.payload;
    },
    ////////Get Students Tasks////////
    getTasksRequet(state) {
      state.getTasksloading = true;
    },
    getTasksSuccess(state, action) {
      state.tasks = action.payload;
      state.getTasksloading = false;
      state.getTasksSuccess = true;
      state.getTasksError = "";
    },
    getTasksError(state, action) {
      state.tasks = [];
      state.getTasksloading = false;
      state.getTasksSuccess = false;
      state.getTasksError = action.payload;
    },
    ////////Change Task Status////////
    changeTaskStatusRequest(state) {
      state.changeTaskStatusLoading = true;
    },
    changeTaskStatusSuccess(state, action) {
      state.changeTaskStatusLoading = false;
      state.changeTaskStatusSuccess = true;
      state.ChangetaskStatusError = "";
    },
    changeStatusRequestError(state, action) {
      state.changeTaskStatusLoading = false;
      state.changeTaskStatusSuccess = false;
      state.changeStatusRequestError = action.payload;
    },
  },
});
export const CounselorOverViewActions = CounselorOverViewSlice.actions;
export default CounselorOverViewSlice;
