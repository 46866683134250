import React, { useEffect, useState } from "react";
import "./Radio.scss";

function Radio({
  left_text,
  right_text,
  isVisible,
  setRadioValue,
  radioValue,
  disabled,
}) {
  const [checked, setChecked] = useState(radioValue);

  const handleChange = (e) => {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  useEffect(() => {
    if (!disabled) setRadioValue(checked);
  }, [checked]);

  return (
    <div className="Radio_Main">
      <label className="switch">
        <input
          type="checkbox"
          checked={radioValue}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
        />
        <div>
          <span></span>
        </div>
      </label>
      {isVisible && (
        <div className="label_text">{!checked ? left_text : right_text}</div>
      )}
    </div>
  );
}

export default Radio;
