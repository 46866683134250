import React, { useEffect, useRef, useState } from "react";
import "./ApplicationDetails.scss";
import Input from "../../../Shared/Components/Input/Input";
import pen from "../../../Shared/Assets/Images/edit.png";
import check from "../../../Shared/Assets/Images/checked.png";
import unCheck from "../../../Shared/Assets/Images/unchecked.png";
import doc from "../../../Shared/Assets/Images/document.png";
import download from "../../../Shared/Assets/Images/download.png";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import deleteIcon from "../../../Shared/Assets/Images/trash.png";
import add from "../../../Shared/Assets/Images/add.png";
import Button from "../../../Shared/Components/Button/Button";
import back from "../../../Shared/Assets/Images/arrow1.webp";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import save from "../../../Shared/Assets/Images/save.png";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { getAllCont } from "../../../Shared/Redux/Location/LocationsActions";
import Popup from "../../../Shared/Components/Popup/Popup";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Shared/Components/Loader/Loader";
import { getUnifiedApplication } from "../../../Shared/Redux/Application/ApplicationAction";
import {
  getSingleApp,
  getSingleAppMissingDocs,
  cancelApplication,
  uploadAppMissingDocuments,
  uploadAppMissingDocumentsFromWebsite,
} from "../../../Shared/Redux/Application/ApplicationAction";
import {
  getTasks,
  createTask,
  changeTaskStatus,
  deleteTask,
} from "../../Redux/CounselorAppTasks/CounselorAppTasksActions";
import {
  IdSplit,
  dateConv,
  handleDownload,
  handlePreview,
} from "../../../Utils";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import attachment from "../../../Shared/Assets/Images/attachment.png";
import UploadDoc from "../../../Shared/Components/UploadDoc/UploadDoc";
import {
  updateApplication,
  uploadOffer,
} from "../../../Shared/Redux/CreateApplication/CreateApplicationAction";
import EditEducationSummary from "../../../Shared/Components/EditEducationSummary/EditEducationSummary";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";

function ApplicationDetails({ counselor }) {
  const navigate = useNavigate();
  const [studiedinCountries, setStudiedinCountries] = useState(true);
  const [disabilities, setDisabilities] = useState(false);
  const [disabilitiesDetaisl, setDisabilitiesDetaisl] = useState("");
  const [studiedDetails, setStudiedDetails] = useState("");
  const [visaRejection, setVisaRejection] = useState(false);
  const [visaRejectionDetails, setVisaRejectionDetails] = useState("");
  const [gapYear, setgapYear] = useState(false);
  const [gapDetails, setGapDetails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [disabled, setDisabled] = useState(-1);
  const [selectedId, setSelectedId] = useState("");
  const [showDocPopUp, setShowDocPopUp] = useState({ open: false, doc: false });
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [deletePassPop, setDeletePassPop] = useState(false);
  const [loading, setLoading] = useState(true);
  const [appDonePercentage, setAppDonePercentage] = useState();
  const [openUploadDocuments, setopenUploadDocuments] = useState(false);
  const [chosenDoc, setChosenDoc] = useState({});
  const [uploadedDocument, setUploadedDocument] = useState({});
  const [docName, setDocName] = useState("");
  const [app, setApp] = useState({});
  const { id } = useParams();
  const scWidth = UseInnerWidth();
  const [openOfferUpload, setOpenOfferUpload] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [offer, setOffer] = useState({});
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch(getSingleApp(id));
    dispatch(getSingleAppMissingDocs(id));
    dispatch(getTasks(id));
  }, []);

  const docRef = useRef(null);
  const handleButtonClick = () => {
    docRef.current.click();
  };
  const statuses = [
    {
      value: "Draft",
      label: "Draft",
    },
    {
      value: "In Review",
      label: "In Review",
    },
    {
      value: "Missing Documents",
      label: "Missing Documents",
    },

    {
      value: "Ready To Be Sent",
      label: "Ready To Be Sent",
    },
    {
      value: "Submitted",
      label: "Submitted",
    },
    {
      value: "Confirmed",
      label: "Confirmed",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Canceled",
      label: "Canceled",
    },
    {
      value: "Applying For VISA",
      label: "Applying For VISA",
    },
    {
      value: "Enrolled",
      label: "Enrolled",
    },
  ];

  const dispatch = useDispatch();

  const { Countries, getCountriesLoading } = useSelector(
    (state) => state.LocationReducer
  );
  const { Tasks, getTasksLoading, deleteTaskLoding, createTaskLoading } =
    useSelector((state) => state.CounselorAppTasksReducer);

  const {
    singleApplication,
    singleApplicationMissingDoc,
    getSingleAppLoading,
    getSingleAppSuccess,
    singleApplicationMissingDocsSuccess,
    singleApplicationMissingDocsLoading,
    uploadDocumentFail,
  } = useSelector((store) => store.ApplicationsReducer);

  const {
    updateApplicationError,
    updateApplicationLoading,
    updateApplicationSuccess,
  } = useSelector((store) => store.CreateApplicationReducer);
  const { uploadOfferLoading, uploadOfferError } = useSelector(
    (store) => store.CreateApplicationReducer
  );
  useEffect(() => {
    if (!getSingleAppLoading && getSingleAppSuccess)
      setApp({
        ...singleApplication,
        status:
          singleApplication.status == "Accepted"
            ? "Confirmed"
            : singleApplication.status,
      });
  }, [getSingleAppLoading, getSingleAppSuccess]);

  const { user } = useSelector((store) => store.userReducer);
  const [allCountries, setAllCountries] = useState("");
  const [allNat, setAllNat] = useState("");

  const [contLoading, setContLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllCont());
  }, []);
  useEffect(() => {
    if (
      singleApplication &&
      singleApplication.documents &&
      singleApplicationMissingDoc
    ) {
      const arraysTotalLength =
        singleApplication.documents.length + singleApplicationMissingDoc.length;
      setAppDonePercentage(
        Math.round(
          (singleApplication?.documents?.length * 100) / arraysTotalLength
        )
      );
    } else setAppDonePercentage(0);
  }, [singleApplication, singleApplicationMissingDoc]);

  useEffect(() => {
    if (Countries.length > 0) {
      let temp = [];
      let temp2 = [];
      Countries.map((item) => {
        temp.push({ value: item.name, label: item.name });
        temp2.push({ value: item.name, label: item.nationality });
      });
      setAllCountries(temp);
      setAllNat(temp2);
    }
  }, [Countries]);

  useEffect(() => {
    setContLoading(getCountriesLoading);
  }, [getCountriesLoading]);

  const assignDoc = () => {
    let taskData = {};
    taskData = {
      title: topic,
      due_date: new Date(dueDate),
      student: singleApplication.student.id,
      application: id,
      isDocument: showDocPopUp.doc,
      description: description,
      status: "pending",
      counselor: user.id,
    };
    let documentData = {};
    documentData = {
      title: topic,
      due_date: new Date(dueDate),
      student: singleApplication.student.id,
      isDocument: showDocPopUp.doc,
      application: id,
      documentName: topic,
      description: description,
      status: "pending",
      counselor: user.id,
    };
    if (showDocPopUp.doc) {
      dispatch(createTask(documentData, id));
    } else {
      dispatch(createTask(taskData, id));
    }
    setShowDocPopUp(false);
    setTopic("");
    setDescription("");
    setDueDate("");
  };

  useEffect(() => {
    if (
      getSingleAppLoading ||
      singleApplicationMissingDocsLoading ||
      getTasksLoading ||
      uploadOfferLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    getSingleAppLoading,
    singleApplicationMissingDocsLoading,
    getTasksLoading,
    uploadOfferLoading,
  ]);
  useEffect(() => {
    setStudiedinCountries(app.studied_in_countries);
    setDisabilities(app.disabilities);
    setVisaRejection(app.refused_visa);
    setVisaRejectionDetails(app.countries_rejected);
    setgapYear(app.has_gap_year);
    setGapDetails(app.gap_year);
    setDisabilitiesDetaisl(app.disabilities_description);
    setStudiedDetails(app.studied_in_countries_description);
    setErrorMessage("");
    setErrorMessage2("");
  }, [app]);
  const dispatchUploadApplication = () => {
    setDisabled(3);

    if (disabled === 3) {
      if (gapYear === false) {
        setGapDetails(null);
      } else if (
        gapYear === true &&
        (gapDetails === "" || gapDetails === null)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }

      if (visaRejection === false) {
        setVisaRejectionDetails(null);
      } else if (
        visaRejection === true &&
        (visaRejectionDetails === "" || !visaRejectionDetails)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }
      if (studiedinCountries === false) {
        setStudiedDetails(null);
      } else if (
        studiedinCountries === true &&
        (studiedDetails === "" || !studiedDetails)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }
      if (disabilities === false) {
        setDisabilitiesDetaisl(null);
      } else if (
        disabilities === true &&
        (disabilitiesDetaisl === "" || !disabilitiesDetaisl)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      } else {
        setErrorMessage2("");
      }

      if (
        gapYear === true &&
        visaRejection === true &&
        disabilities === true &&
        studiedinCountries === true &&
        (gapDetails === "" || !gapDetails) &&
        (disabilitiesDetaisl === "" || !disabilitiesDetaisl) &&
        (studiedDetails === "" || !studiedDetails) &&
        (visaRejectionDetails === "" || !visaRejectionDetails)
      ) {
        setErrorMessage2("Please provide more details");
        setDisabled(3);
        return;
      }

      setErrorMessage("");
      setErrorMessage2("");
      const application = {
        studied_in_countries: studiedinCountries,
        disabilities: disabilities,
        refused_visa: visaRejection,
        countries_rejected: visaRejection ? visaRejectionDetails : "",
        has_gap_year: gapYear,
        gap_year: gapYear ? gapDetails : "",
        disabilities_description: disabilitiesDetaisl
          ? disabilitiesDetaisl
          : "",

        studied_in_countries_description: studiedinCountries
          ? studiedDetails
          : "",
      };
      dispatch(updateApplication(id, application));
      setDisabled(-1);
    }
  };
  const handleTaskDelete = (taskId) => {
    dispatch(deleteTask(taskId, id));
    setSelectedId("");
    setDeletePassPop(false);
  };
  const handleChangeTaskStatus = (taskId, status, AppId) => {
    dispatch(changeTaskStatus(taskId, status, AppId));
  };
  useEffect(() => {
    if (app && Object.keys(app).length > 0) {
      let chosen = {};
      app?.documents?.map((doc) => {
        chosen[doc.name] = { value: doc.originalName, label: doc.originalName };
      });
      setChosenDoc(chosen);
    } else {
      setChosenDoc({});
    }
  }, [app]);
  useEffect(() => {
    if (uploadDocumentFail) {
      setError("Document is too large max 5MB Document/2MB Photo");
    } else {
      setError("");
    }
  }, [uploadDocumentFail]);
  const [popup, setpopup] = useState(false);
  const [pop2, setpop2] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (disabled == 3) {
        // Show the popup or take any other necessary action

        setpop2(true);

        // Set the custom message for the confirmation dialog
        event.returnValue = "Are you sure you want to leave this page?";
      }
    };

    // Add the event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabled]);

  useEffect(() => {
    if (disabled == 3 && !pop2) {
      setpop2(true);
    }
  }, [disabled]);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.add("pen_shake");
        }
      }, 1850);
    } else {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.remove("pen_shake");
        }
      }, 1850);
    }
  }, [popup]);

  return (
    <div className="counslerSingleApp_main_div">
      {popup && (
        <UpperPopUp
          setShow={setpopup}
          title="Please Click on The Pen to Edit Info"
        />
      )}
      {pop2 && (
        <UpperPopUp
          setShow={setpop2}
          title="Please Save Your Changes Before Leaving The Page"
        />
      )}
      {showPop && (
        <EditEducationSummary
          isApp={true}
          setShowPop={setShowPop}
          data={singleApplication?.education_summary}
        />
      )}
      {openUploadDocuments && (
        <UploadDoc
          setShow={setopenUploadDocuments}
          title={"Upload Document"}
          showRadio={false}
          uploadedDoc={uploadedDocument}
          setUploadedDoc={setUploadedDocument}
          setChosenDoc={setChosenDoc}
          chosenDoc={chosenDoc}
          onSubmit={() => {
            chosenDoc[docName] &&
            chosenDoc[docName]?.value == chosenDoc[docName]?.label
              ? setopenUploadDocuments(false)
              : uploadedDocument[docName]
              ? dispatch(
                  uploadAppMissingDocuments(
                    id,
                    docName,
                    uploadedDocument[docName],
                    singleApplication.student.id
                  )
                )
              : dispatch(
                  uploadAppMissingDocumentsFromWebsite(
                    id,
                    chosenDoc[docName].value,
                    docName,
                    singleApplication.student.id
                  )
                );
          }}
          docName={docName}
          setDocName={setDocName}
        />
      )}

      {openOfferUpload && (
        <UploadDoc
          setShow={setOpenOfferUpload}
          title={"Upload Document"}
          showRadio={false}
          uploadedDoc={offer}
          setUploadedDoc={setOffer}
          setChosenDoc={setChosenDoc}
          chosenDoc={chosenDoc}
          onSubmit={() => {
            offer && dispatch(uploadOffer(id, offer["offer"]));
          }}
          docName={"offer"}
          setDocName={setDocName}
        />
      )}

      {loading && <Loader fullScreen={true} />}
      <>
        {counselor ? (
          <div className="unified_header">
            <img
              src={back}
              alt="back"
              className="unified_header_back"
              onClick={() => navigate("/counselor/applications")}
            />
            <h1 className="unified_heading">
              <p>{IdSplit(singleApplication.id)}</p>
              {singleApplication.first_name} {singleApplication.last_name}
            </h1>
          </div>
        ) : (
          <div className="unified_header">
            <h1 className="unified_heading">My Unified Application</h1>
          </div>
        )}
        {counselor && (
          <div
            className="unified_personal_info upper_div_info"
            style={{ backgroundColor: "var(--lightGreen" }}
          >
            <div className="upper2_div_info">
              <h2 className="unified_sub_title2">
                Application Status
                <img
                  src={disabled != 1 ? pen : save}
                  alt="edit"
                  className={
                    disabled != 1
                      ? "unified_edit_img edit_pen"
                      : "unified_edit_img0 edit_save"
                  }
                  onClick={() => {
                    disabled == 1
                      ? dispatch(
                          updateApplication(
                            id,
                            {
                              status:
                                app.status == "Confirmed"
                                  ? "Accepted"
                                  : app.status,
                            },
                            true
                          )
                        ) && setDisabled(-1)
                      : setDisabled(1);
                  }}
                />
              </h2>
              <div
                className="unified_personal_info_div2"
                onClick={() => {
                  disabled != 1 && setpopup(true);
                }}
              >
                <DropDown
                  placeholder="Application Status"
                  setSelected={(e) => setApp({ ...app, status: e.value })}
                  data={statuses}
                  selectedData={{ label: app.status }}
                  value={{ label: app.status }}
                  width="100%"
                  isDisabled={disabled == 1 ? false : true}
                />
              </div>{" "}
              {updateApplicationError && (
                <div
                  className="error"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {updateApplicationError}
                </div>
              )}
            </div>
            <div className="upper3_div_info">
              <h2 className="unified_sub_title2">
                Received Offer
                <img
                  src={singleApplication?.offer?.originalName ? pen : add}
                  alt="add"
                  className={"unified_edit_img"}
                  onClick={() => setOpenOfferUpload(true)}
                />
              </h2>
              {singleApplication?.offer?.originalName ? (
                <div className="uploaded_documents_box2">
                  <div
                    className="uploaded_documents_div1"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePreview(
                        `/students/${singleApplication?.student?.id}/${id}/${singleApplication?.offer?.path}`,
                        singleApplication?.offer?.originalName
                      );
                    }}
                  >
                    <div className="uploaded_documents_info">
                      <div className="uploaded_documents_title">
                        {singleApplication?.offer?.originalName}
                      </div>
                    </div>
                  </div>

                  <div className="uploaded_documents_div2">
                    <img
                      src={download}
                      alt="download"
                      className="documents_uni_icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(
                          `students/${singleApplication?.student?.id}/${id}/${singleApplication?.offer?.path}`,
                          singleApplication?.offer?.originalName
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="background_info_title"
                    style={{
                      opacity: "0.6",
                      fontFamily: "P_SemiBoldItalic",
                    }}
                  >
                    No offers added
                  </div>
                  {uploadOfferError && (
                    <div className="error">{uploadOfferError}</div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div
          className="unified_personal_info"
          style={{ backgroundColor: "var(--blue" }}
        >
          <h2 className="unified_sub_title">Application Details</h2>
          <div className="OneApplicationUpperTableCont">
            <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftDiv ">
              <div className="OneApplicationYableSecTitle">The University</div>
              <div className="OneApplicationUniLogoName">
                <div>
                  <img
                    alt="uniLogo"
                    className="OneApplicationUniLogo"
                    src={
                      process.env.REACT_APP_API +
                      `/files/universities/${singleApplication?.major_degree?.major.faculty?.university.logo}`
                    }
                  />
                </div>
                <div className="OneAppUpperTableName">
                  <p className="OneAppUniName">
                    {
                      singleApplication?.major_degree?.major.faculty?.university
                        .name
                    }
                  </p>
                  <p className="OneAppUniLocation">
                    {
                      singleApplication?.major_degree?.major?.faculty
                        ?.university?.city
                    }
                    ,
                    {
                      singleApplication?.major_degree?.major?.faculty
                        ?.university?.country
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftMiddleRight">
              <div className="OneApplicationYableSecTitle">Faculty</div>
              <div className="OneAppUniName">
                {singleApplication?.major_degree?.major?.faculty?.name}
              </div>
            </div>
            <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftMiddleRight">
              <div className="OneApplicationYableSecTitle">Major</div>
              <div className="OneAppUniName">
                {singleApplication?.major_degree?.major?.name}
              </div>
            </div>
            <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftMiddleRight">
              <div className="OneApplicationYableSecTitle">Semester </div>
              <div className="OneAppUniName">
                {singleApplication?.semester?.name}
              </div>
            </div>
          </div>
        </div>
        <div
          className="unified_personal_info"
          style={{ backgroundColor: "var(--pink" }}
        >
          <h2 className="unified_sub_title">
            Education Summary
            <img
              src={disabled != 2 ? pen : save}
              alt="edit"
              className={
                disabled != 2 ? "unified_edit_img" : "unified_edit_img0"
              }
              onClick={() => {
                setShowPop(true);
                localStorage.setItem("applicationId", singleApplication.id);
              }}
            />
          </h2>
          <div className="unified_personal_info_div">
            {singleApplication?.education_summary?.map((edu) => {
              return (
                <div className="unified_education_table">
                  <div className="education_table_title">{edu.school_name}</div>
                  <div className="education_table_major">
                    <div className="eduation_table_major_title">
                      {edu.degree} in {edu.field_of_study}
                    </div>
                    <div className="education_table_major_date">
                      {new Date(edu.start_date).getFullYear()} -{" "}
                      {new Date(edu.end_date).getFullYear()}
                    </div>
                  </div>
                  <div className="education_table_footer">
                    <div className="education_table_footer_location">
                      {edu.country_of_education}
                    </div>
                    <div className="education_table_footer_graduated">
                      <img
                        src={
                          new Date(edu.end_date) - new Date() <= 0
                            ? check
                            : unCheck
                        }
                        alt="check"
                        className="education_check_img"
                      />
                      {new Date(edu.end_date) - new Date() <= 0
                        ? "Graduated"
                        : "Not Graduated"}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className="unified_personal_info"
          style={{ backgroundColor: "var(--yellow" }}
        >
          <h2 className="unified_sub_title">
            Background Information
            <img
              src={disabled != 3 ? pen : save}
              alt="edit"
              className={
                disabled != 3
                  ? "unified_edit_img edit_pen"
                  : "unified_edit_img0 edit_save"
              }
              onClick={() => {
                dispatchUploadApplication();
              }}
            />
          </h2>
          <div
            className="unified_background_div"
            onClick={() => {
              disabled != 3 && setpopup(true);
            }}
          >
            <div className="background_info_div">
              <div className="background_info_title">
                Have you ever pursued any previous studies in the UK, USA,
                Canada, Australia, Switzerland, or any countries within the
                (EEA) or the (EU)?
              </div>
              <div className="background_info_checkboxes">
                <Checkbox
                  label="Yes"
                  selected={studiedinCountries ? true : false}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setStudiedinCountries(true);
                  }}
                />
                <Checkbox
                  label="No"
                  setSelected={setStudiedinCountries}
                  selected={studiedinCountries ? false : true}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setStudiedinCountries(false);
                    setErrorMessage2("");
                  }}
                />
              </div>
              {studiedinCountries !== null && studiedinCountries && (
                <textarea
                  className="hasGap_details counselorTextArea"
                  placeholder="Please provide us with more details."
                  onChange={(e) => {
                    setStudiedDetails(e.target.value);
                  }}
                  value={studiedDetails}
                  disabled={disabled == 3 ? false : true}
                ></textarea>
              )}
              {errorMessage2 !== "" &&
                studiedDetails == "" &&
                studiedinCountries && (
                  <div className="ErrorMessageDiv">{errorMessage2}</div>
                )}
            </div>
            <div className="background_info_div">
              <div className="background_info_title">
                Do you have any disability or considerations that need to be
                addressed by the university?
              </div>
              <div className="background_info_checkboxes">
                <Checkbox
                  label="Yes"
                  selected={disabilities ? true : false}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setDisabilities(true);
                  }}
                />
                <Checkbox
                  label="No"
                  selected={disabilities ? false : true}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setDisabilities(false);
                    setErrorMessage2("");
                  }}
                />
              </div>
              {disabilities !== null && disabilities && (
                <textarea
                  className="hasGap_details counselorTextArea"
                  placeholder="Please provide us with more details."
                  onChange={(e) => {
                    setDisabilitiesDetaisl(e.target.value);
                  }}
                  value={disabilitiesDetaisl}
                  disabled={disabled == 3 ? false : true}
                ></textarea>
              )}
              {errorMessage2 !== "" &&
                disabilitiesDetaisl == "" &&
                disabilities && (
                  <div className="ErrorMessageDiv">{errorMessage2}</div>
                )}
            </div>
            <div className="background_info_div">
              <div className="background_info_title">
                Have you ever received a visa rejection?
              </div>
              <div className="background_info_checkboxes">
                <Checkbox
                  label="Yes"
                  selected={visaRejection ? true : false}
                  disabled={disabled == 3 ? false : true}
                  click={true}
                  onClick={() => {
                    setVisaRejection(true);
                  }}
                />
                <Checkbox
                  label="No"
                  selected={visaRejection ? false : true}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setVisaRejection(null);
                    setErrorMessage2("");
                  }}
                />
              </div>
              {visaRejection !== null && visaRejection && (
                <textarea
                  className="hasGap_details"
                  placeholder="Please provide us with more details."
                  onChange={(e) => {
                    setVisaRejectionDetails(e.target.value);
                  }}
                  value={visaRejectionDetails}
                  disabled={disabled == 3 ? false : true}
                ></textarea>
              )}
              {errorMessage2 !== "" &&
                visaRejectionDetails == "" &&
                visaRejection && (
                  <div className="ErrorMessageDiv">{errorMessage2}</div>
                )}

              <div className="background_info_title">
                Did you go through a gap year?
              </div>
              <div className="background_info_checkboxes">
                <Checkbox
                  label="Yes"
                  selected={gapYear ? true : false}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setgapYear(true);
                  }}
                />
                <Checkbox
                  label="No"
                  setSelected={setgapYear}
                  selected={gapYear ? false : true}
                  click={true}
                  disabled={disabled == 3 ? false : true}
                  onClick={() => {
                    setgapYear(null);
                    setErrorMessage("");
                  }}
                />
              </div>
              {gapYear !== null && gapYear && (
                <textarea
                  className="hasGap_details counselorTextArea"
                  placeholder="Please provide us with more details."
                  onChange={(e) => {
                    setGapDetails(e.target.value);
                  }}
                  value={gapDetails}
                  disabled={disabled == 3 ? false : true}
                ></textarea>
              )}
              {errorMessage2 !== "" && gapDetails == "" && gapYear && (
                <div className="ErrorMessageDiv">{errorMessage2}</div>
              )}
            </div>
          </div>
        </div>
        <div
          className="unified_personal_info"
          style={{ backgroundColor: "var(--blue" }}
        >
          <h2 className="unified_sub_title">
            Documents
            <img
              src={add}
              alt="add"
              className="unified_edit_img"
              onClick={() => setShowDocPopUp({ open: true, doc: true })}
            />
          </h2>

          <div className="OneApplicationLowerTableCont">
            <div className="OneAppLowerTableHeader">
              <p className="OneAppLowerTableHeaderP">Documents</p>
              <p className="OneAppLowerTableHeaderP">
                {appDonePercentage}% {scWidth > 450 && "Uploaded"}
              </p>
            </div>
            <div className="OneAppLowerTableDescAll">
              <div className="OneAppLowerTableDescriptions">
                <p className="OneAppLowerTableDescP">Document</p>
                <p className="OneAppLowerTableDescP">Due Date</p>
                <p className="OneAppLowerTableDescP">Submitted</p>
                <p className="OneAppLowerTableDescP doc_images">Actions</p>
              </div>
              {singleApplication?.documents?.map((document) => {
                return (
                  <div
                    style={{ cursor: "pointer" }}
                    className="OneAppLowerTableDescriptions"
                    onClick={() =>
                      handlePreview(
                        `students/${singleApplication.student.id}/${singleApplication.id}/${document.path}`,
                        document.originalName
                      )
                    }
                  >
                    <p className="OneAppLowerTableDescP OneAppDocs">
                      {document.name}
                    </p>
                    <p className="OneAppLowerTableDescP OneAppDocs">None</p>
                    {scWidth > 450 && (
                      <p className="OneAppLowerTableDescP OneAppDocs">
                        {dateConv(document.submitted)}
                      </p>
                    )}

                    <div className="OneAppLowerTableDescP OneAppDocs doc_images">
                      <img
                        src={download}
                        alt="download"
                        className="documents_uni_icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(
                            `students/${singleApplication.student.id}/${singleApplication.id}/${document.path}`,
                            document.originalName
                          );
                        }}
                      />

                      {(singleApplication.status === "In Review" ||
                        singleApplication.status === "Draft" ||
                        singleApplication.status === "Missing Documents") && (
                        <img
                          className="documents_uni_icon"
                          src={pen}
                          onClick={(e) => {
                            e.stopPropagation();
                            setopenUploadDocuments(true);
                            setDocName(document.name);
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              {singleApplicationMissingDoc?.map((missingDoc, index) => {
                return (
                  <div className="OneAppLowerTableDescriptions" key={index}>
                    <p className="OneAppLowerTableDescP OneAppDocs">
                      {missingDoc.name ? missingDoc.name : missingDoc}
                    </p>
                    <p className="OneAppLowerTableDescP OneAppDocs">
                      {singleApplication.status === "In Review" ||
                      singleApplication.status === "Draft" ||
                      singleApplication.status === "Missing Documents"
                        ? missingDoc?.dueDate
                          ? dateConv(missingDoc.dueDate)
                          : "None"
                        : "None"}
                    </p>
                    {scWidth > 450 && (
                      <p className="OneAppLowerTableDescP OneAppDocs">
                        {singleApplication.status === "In Review" ||
                        singleApplication.status === "Draft" ||
                        singleApplication.status === "Missing Documents"
                          ? "Pending"
                          : "Not Submitted"}
                      </p>
                    )}
                    <div className="OneAppLowerTableDescP doc_images">
                      {singleApplication.status === "In Review" ||
                      singleApplication.status === "Draft" ||
                      singleApplication.status === "Missing Documents" ? (
                        <>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            ref={docRef}
                            onChange={() => {
                              handleButtonClick();
                            }}
                          />
                          {scWidth <= 1000 ? (
                            <img
                              className="attachmentPic"
                              src={attachment}
                              alt="Upload Now"
                              onClick={(e) => {
                                setDocName(
                                  missingDoc.name ? missingDoc.name : missingDoc
                                );
                                setopenUploadDocuments(true);
                              }}
                            />
                          ) : (
                            <Button
                              text="Upload Now"
                              color="yellow"
                              onClick={(e) => {
                                setDocName(
                                  missingDoc.name ? missingDoc.name : missingDoc
                                );

                                setopenUploadDocuments(true);
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <div className="None_div">None</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <>
          <div
            className="unified_personal_info"
            style={{ backgroundColor: "var(--white" }}
          >
            <h2 className="unified_sub_title">
              Assigned Tasks
              <img
                src={add}
                alt="edit"
                className="unified_edit_img"
                onClick={() => setShowDocPopUp({ open: true, doc: false })}
              />
            </h2>
            <div className="tasks_info_div">
              {Tasks.map((task, key) => {
                return (
                  <div className="assigned_tasks_box" key={task.id}>
                    <div className="assigned_tasks_title">{task.title}</div>
                    <div className="assigned_tasks_desc">
                      {task.description}
                    </div>

                    <div className="assigned_tasks_date">
                      {dateConv(task.due_date)}
                    </div>
                    <div className="assigned_tasks_status">
                      <img
                        src={task.submitted !== null ? check : unCheck}
                        className="education_check_img"
                        alt="check"
                      />
                      {task.submitted !== null ? "Task Done" : "Task Sent"}
                    </div>
                    {task.status.toLowerCase() === "pending" ||
                    task.status.toLowerCase() === "in review" ? (
                      <div className="assigned_tasks_btns">
                        <Button
                          text="Accept"
                          color="lightGreen"
                          onClick={() => {
                            handleChangeTaskStatus(task.id, "accepted", id);
                          }}
                          disabled={task.submitted !== null ? false : true}
                        />
                        <Button
                          text="Reject"
                          color="pink"
                          onClick={() => {
                            handleChangeTaskStatus(task.id, "rejected", id);
                          }}
                          disabled={task.submitted !== null ? false : true}
                        />
                        <div className="DeleteIconDiv">
                          <img
                            className="CounselorSingleAppDeleteImg"
                            src={deleteIcon}
                            onClick={() => {
                              setDeletePassPop(true);
                              setSelectedId(task.id);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="assigned_tasks_btns">
                        {task.status.toLowerCase() === "accepted" ? (
                          <span className="StatusSpanAccepted">Accepted</span>
                        ) : (
                          <span className="StatusSpanRejected">Rejected</span>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="tasks_info_div_resp">
              {Tasks.map((task) => {
                return (
                  <div className="tasks_table">
                    <div className="tasks_table_title">
                      {task.title}
                      <div className="tasks_table_major_date">
                        {dateConv(task.due_date)}
                      </div>
                    </div>
                    <div className="tasks_table_major">{task.description}</div>
                    <div className="tasks_table_footer">
                      <div className="assigned_tasks_status">
                        <img
                          src={task.submitted !== null ? check : unCheck}
                          className="tasks_check_img"
                          alt="check"
                        />
                        {task.submitted !== null ? "Task Done" : "Task Sent"}
                      </div>
                      {task.status.toLowerCase() == "in review" ? (
                        <div className="assigned_tasks_btns">
                          <Button
                            text="Accept"
                            color="lightGreen"
                            onClick={() => {
                              handleChangeTaskStatus(task.id, "accepted", id);
                            }}
                            disabled={task.submitted !== null ? false : true}
                          />
                          <Button
                            text="Reject"
                            color="pink"
                            onClick={() => {
                              handleChangeTaskStatus(task.id, "rejected", id);
                            }}
                            disabled={task.submitted !== null ? false : true}
                          />
                        </div>
                      ) : (
                        <div className="assigned_tasks_btns">
                          {task.status.toLowerCase() === "accepted" ? (
                            <span className="StatusSpanAccepted">Accepted</span>
                          ) : task.status.toLowerCase() === "pending" ? (
                            <span
                              className="StatusSpanRejected"
                              style={{
                                color: "var(--yellow)",
                              }}
                            >
                              Pending{" "}
                            </span>
                          ) : (
                            <span className="StatusSpanRejected">Rejected</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="unified_personal_info"
            style={{ backgroundColor: "var(--yellow" }}
          >
            <h2 className="unified_sub_title">
              Application Fees:
              <img
                src={disabled != 5 ? pen : save}
                alt="edit"
                className={
                  disabled != 5
                    ? "unified_edit_img edit_pen"
                    : "unified_edit_img0 edit_save"
                }
                onClick={() => {
                  disabled == 5
                    ? dispatch(updateApplication(id, { paid: app.paid })) &&
                      setDisabled(-1)
                    : setDisabled(5);
                }}
              />
            </h2>
            <div
              className="application_fees_div"
              onClick={() => {
                disabled != 5 && setpopup(true);
              }}
            >
              <div className="application_fees_title">
                <Checkbox
                  label="Paid"
                  selected={app.paid ? true : false}
                  click={true}
                  disabled={disabled == 5 ? false : true}
                  onClick={() => {
                    setApp({ ...app, paid: !app.paid });
                  }}
                />
              </div>
            </div>
          </div>
        </>

        {deletePassPop && (
          <Popup
            setShowPopup={setDeletePassPop}
            width={"35%"}
            body={
              <div className="unified_doc_div">
                <div className="unified_doc_div_header">
                  Are you sure you want to delete this Task?
                </div>

                <div className="unified_doc_div_body">
                  <Button
                    text={"Yes"}
                    color={"yellow"}
                    onClick={() => {
                      handleTaskDelete(selectedId);
                    }}
                  />
                  <Button
                    text={"Cancel"}
                    color={"pink"}
                    onClick={() => {
                      setDeletePassPop(false);
                      setSelectedId("");
                    }}
                  />
                </div>
              </div>
            }
          />
        )}
        {showDocPopUp.open && (
          <Popup
            setShowPopup={setShowDocPopUp}
            width="40%"
            body={
              <div className="unified_doc_div">
                <div className="unified_doc_div_header">
                  {showDocPopUp.doc
                    ? "Assign A New Document"
                    : "Assign A New Task"}
                </div>
                <div className="applyForm_card_sub">
                  {showDocPopUp.doc &&
                    "This document will also be added to the assiegned tasks"}
                </div>

                <div className="input_div">
                  <div className="input_tile">
                    {showDocPopUp.doc ? "Document Name" : "Topic"}
                  </div>
                  <Input
                    placeholder={showDocPopUp.doc ? "Document Name" : "Topic"}
                    type="text"
                    width={"100%"}
                    onChange={(e) => {
                      setTopic(e.target.value);
                    }}
                    required={true}
                    value={topic}
                  />
                </div>
                <div className="input_div">
                  <div className="input_tile">Description</div>
                  <textarea
                    className="hasGap_details"
                    placeholder="Description"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    value={description}
                  ></textarea>
                </div>
                <div
                  className="input_div"
                  style={{ marginTop: "-10px", marginBottom: "15px" }}
                >
                  <div className="input_tile">Due Date</div>
                  <DateInput
                    placeholder="Topic"
                    width={"100%"}
                    setSelectedDate={setDueDate}
                    required={true}
                    selectedDate={dueDate}
                    minDate={new Date()}
                  />
                </div>
                <Button
                  text="Assign"
                  width="100%"
                  color="blue"
                  onClick={() => assignDoc()}
                  disabled={
                    topic != "" && description != "" && dueDate != ""
                      ? false
                      : true
                  }
                />
              </div>
            }
          />
        )}
      </>
    </div>
  );
}

export default ApplicationDetails;
