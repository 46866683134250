import React, { useEffect, useState } from "react";
import "./OBStep8.scss";
import Button from "../../Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";
import Star from "../../Animations/Star/Star";
import DropDown from "../../DropDown/DropDown";
import Input from "../../Input/Input";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { createUser } from "../../../../Student/Redux/User/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useGoogleLogin } from "@react-oauth/google";
import CreateStep9 from "../../../../Counsler/Pages/CreateStudent/CreateStep9/CreateStep9";
import MobileInput from "../../MobileInput/MobileInput";
import { createStudent } from "../../../../Counsler/Redux/CounselorStudent/CounselorStudentActions";
import Checkbox from "../../Checkbox/Checkbox";
import PrivacyPolicy from "../../../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsNConditions from "../../../Pages/TermsNConditions/TermsNConditions";
import img from "../../../../Shared/Assets/Images/checked.png";
import UpperPopUp from "../../UpperPopUp/UpperPopUp";

function OBStep8({ step, setStep, student, setStudent, counselor }) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setconfPassword] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [CheckingErrorMessage, setCheckingErrorMessage] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [ready, setReady] = useState("");
  const [phoneNb, setPhoneNb] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [clicked, setClicked] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [showUpper, setShowUpper] = useState(false);

  const {
    createdStudent,
    createStudentLoading,
    createStudentError,
    createStudentSuccess,
  } = useSelector((state) => state.CounselorStudentReducer);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !counselor &&
      (email == "" ||
        name == "" ||
        password == "" ||
        confPassword == "" ||
        !ready ||
        error ||
        error2)
    ) {
      return false;
    } else {
      if (!counselor) {
        const data = {
          first_name: name.slice(0, name.indexOf(" ")),
          last_name: name.slice(name.indexOf(" ") + 1),
          email: email,
          password: password,
          role: "student",
          country_of_residence: student.country,
          nationality: student.nationality,
          subscribed_to_newsletter: true,
          subscribed_to_emails: true,
        };

        const user = { userData: data, onboardingData: student };

        dispatch(createUser(user));
      } else {
        if (
          counselor &&
          (fName == "" ||
            lName == "" ||
            phoneNb == "" ||
            email == "" ||
            !ready ||
            error2 ||
            error ||
            confPassword == "" ||
            password == "")
        ) {
          return false;
        } else {
          const data = {
            first_name: fName,
            last_name: lName,
            phone: phoneNb,
            email: email,
            password: password,
            role: "student",
            country_of_residence: student.country,
            nationality: student.nationality,
            subscribed_to_newsletter: true,
            subscribed_to_emails: true,
          };

          const onboardingData = {
            ...student,

            counselor: user.user.role == "counselor" ? user.user.id : null,
          };

          const stud = { userData: data, onboardingData: onboardingData };
          setClicked(true);
          dispatch(createStudent(stud));
        }
      }
    }
  };

  useEffect(() => {
    if (createStudentSuccess && counselor && clicked) {
      setStep(9);
    }
  }, [createStudentSuccess]);
  const checkPass = (e) => {
    var pass = e;
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (
      pass.length >= 8 &&
      /\d/.test(pass) &&
      /[A-Z]/.test(pass) &&
      /[a-z]/.test(pass) &&
      format.test(pass)
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
    if (pass.length >= 8) {
      document.getElementById("8char").classList.add("list_checked");
    } else {
      document.getElementById("8char").classList.remove("list_checked");
    }

    if (/\d/.test(pass)) {
      document.getElementById("cNub").classList.add("list_checked");
    } else {
      document.getElementById("cNub").classList.remove("list_checked");
    }

    if (/[A-Z]/.test(pass)) {
      document.getElementById("cUpc").classList.add("list_checked");
    } else {
      document.getElementById("cUpc").classList.remove("list_checked");
    }

    if (/[a-z]/.test(pass)) {
      document.getElementById("cLpc").classList.add("list_checked");
    } else {
      document.getElementById("cLpc").classList.remove("list_checked");
    }

    if (format.test(pass)) {
      document.getElementById("cSc").classList.add("list_checked");
    } else {
      document.getElementById("cSc").classList.remove("list_checked");
    }

    setPassword(pass);
  };

  useEffect(() => {
    if (confPassword && password) {
      if (confPassword != password) {
        setError("Passwords do not match!");
      } else {
        setError("");
      }
    }
  }, [password, confPassword]);

  useEffect(() => {
    if (name.slice(name.indexOf(" ") + 1) == "" || name.indexOf(" ") == -1) {
      setError2("Please enter your full name");
    } else setError2("");
  }, [name]);
  useEffect(() => {
    if (user?.success && !counselor) {
      setShowUpper(true);
    }
  }, [user?.success]);

  useEffect(() => {
    setError("");
    setError2("");
    setError3("");
  }, []);

  useEffect(() => {
    if (user.createError && !counselor) setError3(user.createError);
    if (createStudentError && counselor) setError3(createStudentError);
  }, [user.createError, createStudentError]);

  const login = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    responseType: "id_token",
    onSuccess: (credentialResponse) => {
      const user = {
        credentialResponse: credentialResponse,
        onboardingData: student,
      };

      dispatch(createUser(user, true));
    },
    onError: (error) => {
      setError3(error);
    },
  });

  return (
    <div className="obStep_main_div">
      {showUpper && (
        <UpperPopUp
          title="Account Created Successfully"
          img={img}
          setShow={setShowUpper}
          navigate={() => navigate("/student/login")}
        />
      )}
      {step == 8 ? (
        <FormLayout
          back={() => setStep(7)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={counselor ? "Create Account" : "Sign up to Education Basket"}
          minHeight="fit-content"
          width="90%"
          loading={!counselor ? user?.createLoading : createStudentLoading}
          body={
            <form className="signUp_body" onSubmit={(e) => handleSubmit(e)}>
              {counselor ? (
                <div className="dropdown_flex">
                  <div className="input_div">
                    <div className="input_tile">First Name</div>
                    <Input
                      placeholder="First Name"
                      type="text"
                      width={"100%"}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const letterOnlyValue = inputValue.replace(
                          /[^A-Za-z]/g,
                          ""
                        );
                        setFName(letterOnlyValue);
                      }}
                      required={true}
                      value={fName}
                    />
                  </div>
                  <div className="input_div">
                    <div className="input_tile">Last Name</div>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      width={"100%"}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const letterOnlyValue = inputValue.replace(
                          /[^A-Za-z]/g,
                          ""
                        );
                        setLName(letterOnlyValue);
                      }}
                      required={true}
                      value={lName}
                    />
                  </div>
                </div>
              ) : (
                <div className="dropdown_flex">
                  <div className="input_div">
                    <div className="input_tile">Full Name</div>
                    <Input
                      placeholder="e.g: Jhon Smith"
                      type="text"
                      width={"100%"}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const letterOnlyValue = inputValue.replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                        setName(letterOnlyValue);
                      }}
                      required={true}
                      value={name}
                    />
                  </div>
                  <div className="input_div">
                    {" "}
                    <div className="input_tile">Email</div>
                    <Input
                      placeholder="e.g: jhon.smith13@gmail.com"
                      type="email"
                      width={"100%"}
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      value={email}
                    />
                  </div>
                </div>
              )}
              {counselor && (
                <div className="dropdown_flex">
                  <div className="input_div">
                    {" "}
                    <div className="input_tile">Email</div>
                    <Input
                      placeholder="e.g: jhon.smith13@gmail.com"
                      type="email"
                      width={"100%"}
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      value={email}
                    />
                  </div>
                  <div className="input_div">
                    <div className="input_tile">Phone Number</div>
                    <MobileInput
                      width="100%"
                      phone={phoneNb}
                      setPhone={setPhoneNb}
                      required={true}
                    />
                  </div>
                </div>
              )}
              <div className="dropdown_flex">
                <div className="input_div">
                  <div className="input_tile">Password</div>
                  <Input
                    placeholder="Enter password"
                    type="password"
                    value={password}
                    width={"100%"}
                    onChange={(e) => checkPass(e.target.value)}
                    onClick={() => {
                      setShowPopUp(true);
                    }}
                    onBlur={() => {
                      setShowPopUp(false);
                      if (!ready) {
                        setError2("Password must be strong!");
                      } else setError2("");
                    }}
                    required={true}
                  />

                  <div
                    className="password_popUp"
                    style={{ display: showPopUp ? "block" : "none" }}
                  >
                    <ul className="password_check">
                      <li id="8char">8 Characters </li>
                      <li id="cNub">Contains Number </li>
                      <li id="cLpc">Contains LowerCase </li>
                      <li id="cUpc">Contains Uppercase </li>
                      <li id="cSc">Contains Special Character</li>
                    </ul>
                  </div>
                </div>

                <div className="input_div">
                  <div className="input_tile">Confirm Password</div>
                  <Input
                    placeholder="Confirm password"
                    type="password"
                    width={"100%"}
                    onChange={(e) => setconfPassword(e.target.value)}
                    required={true}
                  />
                </div>
              </div>
              {error && <div className="error">{error}</div>}
              {error2 && <div className="error">{error2}</div>}
              {error3 && <div className="error">{error3}</div>}

              {!counselor && (
                <div className="privacy_policy_checkBox">
                  <Checkbox
                    setSelected={setAcceptPrivacy}
                    selected={acceptPrivacy}
                  />
                  <div className="privacy_policy_text">
                    I accept the{" "}
                    <span
                      className="privacy_policy_link"
                      onClick={() => {
                        setOpenPrivacy(true);
                        setOpenTerms(false);
                      }}
                    >
                      Privacy Policy
                    </span>{" "}
                    and{" "}
                    <span
                      className="privacy_policy_link"
                      onClick={() => {
                        setOpenPrivacy(false);
                        setOpenTerms(true);
                      }}
                    >
                      Terms and Conditions
                    </span>
                  </div>
                </div>
              )}
              <Button
                text={counselor ? "Create Account" : "Sign Up"}
                color="lightGreen"
                width="100%"
                disabled={
                  counselor
                    ? fName == "" ||
                      lName == "" ||
                      phoneNb == "" ||
                      email == "" ||
                      !ready ||
                      error2 ||
                      error ||
                      confPassword == "" ||
                      password == ""
                      ? true
                      : false
                    : (email == "" ||
                        name.slice(name.indexOf(" ") + 1) == "" ||
                        name.indexOf(" ") == -1 ||
                        password == "" ||
                        confPassword == "" ||
                        !ready ||
                        error ||
                        !acceptPrivacy ||
                        error2) &&
                      true
                }
              />

              {!counselor && (
                <>
                  <div className="dropdown_flex">
                    <hr className="sign_up_line" />
                    <div className="signUp_footer_title"> Or Sign Up With</div>
                    <hr className="sign_up_line" />
                  </div>
                  <div className="signUp_footer">
                    <div className="google">
                      <FcGoogle size="1.5rem" onClick={() => login()} />
                    </div>
                    <div className="google">
                      <FacebookLogin
                        render={(renderProps) => (
                          <FaFacebookF
                            color="#3b5998"
                            size="1.5rem"
                            onClick={renderProps.onClick}
                          />
                        )}
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        onSuccess={(response) => {
                          const user = {
                            facebookUserId: response.userID,
                            facebookUserToken: response.accessToken,
                            onboardingData: student,
                          };

                          dispatch(createUser(user, false, true));
                        }}
                        onFail={(error) => {
                          setError(error);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </form>
          }
          icon={<Star />}
          bg="var(--pink)"
          gap="20px"
        />
      ) : (
        <CreateStep9
          step={step}
          setStep={setStep}
          student={{ password: password, email: email }}
        />
      )}

      {openPrivacy && <PrivacyPolicy setShow={setOpenPrivacy} />}
      {openTerms && <TermsNConditions setShow={setOpenTerms} />}
    </div>
  );
}

export default OBStep8;
