import React from "react";
import "./Homepage3.scss";
import Snap2 from "./Snap/Snap2";

function Homepage3() {
	return (
		<>
			<Snap2 />
		</>
	);
}

export default Homepage3;
