import React, { useState, useEffect } from "react";
import "./CounselorTable.scss";
import Loader from "../../../Shared/Components/Loader/Loader";
import { IdSplit } from "../../../Utils";
import Pagination from "../../../Shared/Components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";

function CounselorTable({
  students,
  application,
  limit,
  offset,
  hasPrev,
  hasNext,
  pageNumber,
  setPageNumber,
  setOffset,
  setLimit,
}) {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    if (students) {
      setloading(false);
    }
  }, [students]);
  function NumberWithCommas({ value }) {
    // Use toLocaleString() to add commas to the number
    const formattedValue = value.toLocaleString();

    return <span>{formattedValue}</span>;
  }
  const navigate = useNavigate();
  return (
    <>
      {loading && <Loader fullScreen={true} />}
      <div className="TableViewContainer">
        <div
          className="TableViewInnerContainer"
          style={{ minHeight: !application && "calc(100vh - 340px)" }}
        >
          <table className="StudentTable">
            <thead className="StudentTableHead">
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Email</th>
                <th>Nationality</th>
                <th>Degree</th>
                <th>{application ? "Major" : "Destination"}</th>
                <th>{application ? "University" : "Budget"}</th>
                <th>{application ? "GPA" : "Semester"}</th>
                <th>{application ? "Status" : "Status"}</th>
              </tr>
            </thead>
            {application ? (
              <tbody className="Table_Body">
                {students?.applications?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(
                          `/counselor/applications/singleApp/${item.id}`
                        );
                      }}
                      className="studentTableTr"
                    >
                      <td>{item.first_name + " " + item.last_name}</td>
                      <td className="TableIDTd">{IdSplit(item.id)}</td>
                      <td>{item.student.user.email}</td>
                      <td>{item.student.user.nationality}</td>
                      <td>{item?.major_degree?.degree.name}</td>
                      <td>{item?.major_degree?.major?.name}</td>
                      <td>
                        {item?.major_degree?.major?.faculty.university?.name}
                      </td>
                      <td>
                        {item?.education_summary !== null &&
                          item?.education_summary[0]?.gpa +
                            "/" +
                            item?.education_summary[0]?.grade_scale}
                      </td>
                      <td
                        className={
                          item.status === "Draft" ||
                          item.status === "Ready To Be Sent"
                            ? "draft"
                            : item.status === "Submitted" ||
                              item.status === "Accepted" ||
                              item.status === "Applying For Visa"
                            ? "submitted"
                            : item.status === "Rejected" ||
                              item.status === "Canceled" ||
                              item.status === "Missing Documents"
                            ? "rejected"
                            : ""
                        }
                      >
                        {item.status == "Accepted" ? "Confirmed" : item.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody className="Table_Body">
                {students?.students?.map((item) => {
                  const locations = item.location_of_study.map((loc) => {
                    return loc;
                  });

                  return (
                    <tr
                      onClick={() => {
                        navigate(
                          `/counselor/students/singleStudent/${item.id}`
                        );
                      }}
                      className="studentTableTr"
                    >
                      <td>
                        {item.user.first_name + " " + item.user.last_name}
                      </td>
                      <td className="TableIDTd">{IdSplit(item.id)}</td>
                      <td>{item.user.email}</td>
                      <td>{item.user.nationality}</td>
                      <td>{item.education_level}</td>
                      <td>{locations.join(", ")}</td>
                      <td>
                        {item.budget.length == 1
                          ? item.budget?.[0].toLocaleString() + "$ +"
                          : item.budget?.[0] +
                            "$" +
                            "-" +
                            item.budget?.[1] +
                            "$"}
                      </td>

                      <td>{item.preferred_semester}</td>
                      <td
                        className={
                          item.progress.Apply_to_universities
                            ? "lightgreen"
                            : "pinkcolor"
                        }
                      >
                        {item.progress.Apply_to_universities
                          ? "Applied"
                          : "New"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>

        <Pagination
          limit={limit}
          offset={offset}
          hasPrev={hasPrev}
          hasNext={hasNext}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setOffset={setOffset}
          setLimit={setLimit}
        />
      </div>
    </>
  );
}

export default CounselorTable;
