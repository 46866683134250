import React, { useEffect, useState } from "react";
import "./ApplicationStep5.scss";
import ApplyForm from "../../../Components/ApplyForm/ApplyForm";
import Input from "../../../Components/Input/Input";

import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import UpperPopUp from "../../../Components/UpperPopUp/UpperPopUp";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import img from "../../../Assets/Images/checked.png";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader/Loader";
import { updateApplication } from "../../../Redux/CreateApplication/CreateApplicationAction";
import { getSingleApp } from "../../../Redux/Application/ApplicationAction";
function ApplicationStep5({ step, setStep, counselor }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [bg1, setBg1] = useState(false);
  const [bg2, setBg2] = useState(false);
  const [bg3, setBg3] = useState(false);
  const [country, setCountry] = useState("");
  const [disabilities_description, setDisabilities_description] = useState("");
  const [studiedDetails, setStudiedDetails] = useState("");
  const [showUpper, setShowUpper] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [clicked, setClicked] = useState(false);
  const {
    updateApplicationLoading,
    updateApplicationError,
    updateApplicationSuccess,
  } = useSelector((state) => state.CreateApplicationReducer);
  const {
    singleApplication,
    getSingleAppLoading,
    getSingleAppSuccess,
    getSingleAppError,
  } = useSelector((state) => state.ApplicationsReducer);
  useEffect(() => {
    if (updateApplicationLoading) {
      setBtnLoad(true);
    } else setBtnLoad(false);
  }, [updateApplicationLoading]);

  useEffect(() => {
    if (updateApplicationError) {
      setError(updateApplicationError);
    } else setError("");
  }, [updateApplicationError]);

  useEffect(() => {
    if (
      (updateApplicationSuccess && clicked && step == 5) ||
      (updateApplicationSuccess && clicked && counselor && step == 4)
    ) {
      setShowUpper(true);
    }
  }, [updateApplicationSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setClicked(true);
    const application = {
      application_step: -1,
      studied_in_countries: bg1,
      status:
        singleApplication.documents == null ? "Missing Documents" : "In Review",
      disabilities: bg2,
      refused_visa: bg3,
      countries_rejected: country ? country : "",
      disabilities_description: disabilities_description
        ? disabilities_description
        : "",

      studied_in_countries_description: studiedDetails ? studiedDetails : "",
    };
    setClicked(true);
    dispatch(
      updateApplication(localStorage.getItem("applicationId"), application)
    );
  };
  useEffect(() => {
    if (localStorage.getItem("applicationId")) {
      dispatch(getSingleApp(localStorage.getItem("applicationId")));
    }
  }, []);

  useEffect(() => {
    if (
      singleApplication &&
      localStorage.getItem("applicationId") &&
      singleApplication.studied_in_countries != null &&
      singleApplication.disabilities != null &&
      singleApplication.refused_visa != null &&
      singleApplication.countries_rejected != null
    ) {
      setBg1(singleApplication.studied_in_countries);
      setBg2(singleApplication.disabilities);
      setBg3(singleApplication.refused_visa);
      setCountry(singleApplication.countries_rejected);
    } else {
      setBg1(false);
      setBg2(false);
      setBg3(false);
      setCountry("");
    }
  }, [singleApplication]);

  const goBackF = () => {
    if (!counselor) {
      setStep(4);
      navigate("/student/my_applications/apply/4");
    } else {
      setStep(3);
      navigate("/counselor/applications/create/3");
    }
  };

  return (
    <div className="application_step2_mainDiv">
      {showUpper && (
        <UpperPopUp
          setShow={setShowUpper}
          title="Application submitted"
          img={img}
          navigate={() => {
            counselor
              ? navigate(
                  "/counselor/applications/singleApp" +
                    "/" +
                    localStorage.getItem("applicationId")
                )
              : navigate("/student/my_applications");
          }}
        />
      )}
      {step == 5 || (counselor && step == 4) ? (
        <ApplyForm
          title="Background Information"
          subTitle="VISA information"
          hasBack={true}
          back={goBackF}
          hasPlus={false}
          body={
            <div className="application_step1_body">
              <div className="background_info_div">
                <div className="background_info_title">
                  Have you ever pursued any previous studies in the UK, USA,
                  Canada, Australia, Switzerland, or any countries within the
                  (EEA) or the (EU)?
                </div>
                <div className="background_info_checkboxes">
                  <Checkbox
                    label="Yes"
                    selected={bg1 ? true : false}
                    click={true}
                    onClick={() => {
                      setBg1(true);
                    }}
                  />
                  <Checkbox
                    label="No"
                    setSelected={setBg2}
                    selected={bg1 ? false : true}
                    click={true}
                    onClick={() => {
                      setBg1(false);
                    }}
                  />
                </div>
              </div>
              {bg1 && (
                <Input
                  placeholder="Please provide more details"
                  type="text"
                  width={"100%"}
                  onChange={(e) => setStudiedDetails(e.target.value)}
                  required={true}
                  value={studiedDetails}
                />
              )}
              <div className="background_info_div">
                <div className="background_info_title">
                  Do you have any disability or considerations that need to be
                  addressed by the university?
                </div>
                <div className="background_info_checkboxes">
                  <Checkbox
                    label="Yes"
                    selected={bg2 ? true : false}
                    click={true}
                    onClick={() => {
                      setBg2(true);
                    }}
                  />
                  <Checkbox
                    label="No"
                    selected={bg2 ? false : true}
                    click={true}
                    onClick={() => {
                      setBg2(false);
                    }}
                  />
                </div>
              </div>{" "}
              {bg2 && (
                <Input
                  placeholder="Please provide more details"
                  type="text"
                  width={"100%"}
                  onChange={(e) => setDisabilities_description(e.target.value)}
                  required={true}
                  value={disabilities_description}
                />
              )}
              <div className="background_info_div">
                <div className="background_info_title">
                  Have you ever received a visa rejection?
                </div>
                <div className="background_info_checkboxes">
                  <Checkbox
                    label="Yes"
                    selected={bg3 ? true : false}
                    click={true}
                    onClick={() => {
                      setBg3(true);
                    }}
                  />
                  <Checkbox
                    label="No"
                    selected={bg3 ? false : true}
                    click={true}
                    onClick={() => {
                      setBg3(false);
                      setCountry("");
                    }}
                  />
                </div>
              </div>{" "}
              {bg3 && (
                <Input
                  placeholder="Which country rejected your visa?"
                  type="text"
                  width={"100%"}
                  onChange={(e) => setCountry(e.target.value)}
                  required={true}
                  value={country}
                />
              )}
              {error && <div className="error">{error}</div>}
              <div className="application_step1_body_footer">
                <Button
                  text={btnLoad ? <Loader onlyDots={true} /> : "Finish"}
                  color="blue"
                  width="100%"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={
                    (bg3 && country == "") ||
                    (bg2 && disabilities_description == "") ||
                    (bg1 && studiedDetails == "") ||
                    btnLoad
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ApplicationStep5;
