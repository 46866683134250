/** @format */
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  overview: [],
  fetchOverviewLoading: false,
  fetchOverviewFail: "",
  fetchOverviewSuccess: false,
  //help req
  helpReqLoading: false,
  helpReqFail: "",
  helpReqSuccess: false,
  helpReq: [],

  checkProgressLoading: false,
  checkProgressFail: "",
  checkProgressSuccess: false,
};

const OverviewSlice = createSlice({
  name: "Overview",
  initialState,
  reducers: {
    fetchOverviewRequest(state) {
      state.fetchOverviewLoading = true;
      state.fetchOverviewFail = "";
      state.overview = [];
      state.fetchOverviewSuccess = false;
    },
    fetchOverviewSuccess(state, action) {
      state.fetchOverviewLoading = false;
      state.overview = action.payload;
      state.fetchOverviewFail = "";
      state.fetchOverviewSuccess = true;
    },
    fetchOverviewFail(state, action) {
      state.fetchOverviewLoading = false;
      state.overview = [];
      state.fetchOverviewFail = action.payload;
      state.fetchOverviewSuccess = false;
    },

    helpReqRequest(state) {
      state.helpReqLoading = true;
      state.helpReqFail = "";
      state.helpReq = [];
      state.helpReqSuccess = false;
    },
    helpReqSuccess(state, action) {
      state.helpReqLoading = false;
      state.helpReq = action.payload;
      state.helpReqFail = "";
      state.helpReqSuccess = true;
    },
    helpReqFail(state, action) {
      state.helpReqLoading = false;
      state.helpReq = [];
      state.helpReqFail = action.payload;
      state.helpReqSuccess = false;
    },

    checkProgressRequest(state) {
      state.checkProgressLoading = true;
      state.checkProgressFail = "";
      state.checkProgressSuccess = false;
    },
    checkProgressSuccess(state, action) {
      state.checkProgressLoading = false;
      state.checkProgressFail = "";
      state.checkProgressSuccess = true;
    },
    checkProgressFail(state, action) {
      state.checkProgressLoading = false;
      state.checkProgressFail = action.payload;
      state.checkProgressSuccess = false;
    },
  },
});

export const OverviewActions = OverviewSlice.actions;
export default OverviewSlice;
