import React, { useEffect, useRef, useState } from "react";
import "./Leads.scss";
import UserTable from "../../Components/UserTable/UserTable";
import Popup from "../../../Shared/Components/Popup/Popup";
import Button from "../../../Shared/Components/Button/Button";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import plus from "../../../Shared/Assets/Images/add.png";
import SortBy from "../../../Shared/Components/SortBy/SortBy";
import Pagination from "../../../Shared/Components/Pagination/Pagination";
import Input from "../../../Shared/Components/Input/Input";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import uploadHere from "../../../Shared/Assets/Images/uploadHere.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeads,
  updateLeadStatus,
  updateLeadsNotes,
  CreateNewLeads,
  getsources,
  CreateNewLeadsfile,
  deleteLead,
  getArchivedleads,
  assignLead,
} from "../../Redux/Leads/LeadsActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import SearchInput from "../../../Shared/Components/SearchInput/SearchInput";
import axios from "axios";
import filter from "../../../Shared/Assets/Images/filterMob.png";
import Filters from "../../../Shared/Components/Filters/Filters";
import { GetSales } from "../../Redux/Students/StudentsActions";
import { getAllCont } from "../../../Shared/Redux/Location/LocationsActions";
import { DropDownList } from "../../../Utils";

function Leads() {
  const dispatch = useDispatch();

  const [showComment, setShowComment] = useState(false);
  const [commentIndex, setCommentIndex] = useState(-1);
  const [commentNew, setCommentNew] = useState("");
  const [checkedLeads, setCheckedLeads] = useState([]);
  const [leadBody1, setLeadBody1] = useState(false);
  const [leadBody2, setLeadBody2] = useState(false);
  const [addLeadsPop, setAddLeadsPop] = useState(false);
  const [search, setSearch] = useState(null);
  const screenWidth = UseInnerWidth();
  const [uploadedDoc, setUploadedDoc] = useState({});
  const [docName, setDocName] = useState("");
  const [deletePop, setDeletePop] = useState(false);
  const [selectedSales, setSelectedSales] = useState([]);

  const [leadsData, setLeadsData] = useState({});
  const [deleteError, setDeleteError] = useState("");
  const [changeStatus, setchangeStatus] = useState("");

  const { Sales, getSalesError, getSalesLoading } = useSelector(
    (state) => state.StudentsSaleReducer
  );

  const {
    Countries,
    getCountriesLoading,
    getCountriesSuccess,
    getCountriesError,
  } = useSelector((state) => state.LocationReducer);

  const {
    Leads,
    loading,
    Sources,
    UploadLeadsLoading,
    LoadingUpdateLeads,
    LoadingCreateLeads,
    createleaderror,
    createleadSuccuss,
    LoadingAssignLeads,
    AssignLeadsError,
    AssignLeadsSuccess,
    DeleteLeadsSuccess,
    DeleteLeadsError,
  } = useSelector((state) => state.LeadsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const [tempid, settempid] = useState("");
  const scWidth = UseInnerWidth();
  const [openFilter, setOpenFilter] = useState(false);
  const [data, setData] = useState({});

  const [ShowPop, setShowPop] = useState(false);

  useEffect(() => {
    if (commentIndex != -1) {
      setShowComment(true);

      settempid(Leads.leads[commentIndex].id);
    }
  }, [commentIndex]);

  useEffect(() => {
    if (!showComment) {
      setCommentIndex(-1);
    }
  }, [showComment]);

  const keys = [
    "Lead's Name",
    "Status",
    "Email",
    "Phone",
    "Source",
    "Budget",
    "Contacted By",
    "Assigned To",
    "Country of Residence",
    "Level of Education",
    "Notes",
  ];

  const update = () => {
    if (checkedLeads.length > 0 && changeStatus.value) {
      const id = checkedLeads.map((el) => {
        return el.value;
      });
      const leads = {
        id: id,
        status: changeStatus.value,
      };

      dispatch(
        updateLeadStatus(leads, sortBy.value, limit1, offset1, search, data)
      );
      setCheckedLeads([]);
    }
  };

  const deleteLeads1 = () => {
    if (checkedLeads.length > 0) {
      const leads = checkedLeads[0].value;
      dispatch(deleteLead(leads, sortBy.value, limit1, offset1, search, data));
    }
  };

  const updatenotes = () => {
    if (commentNew) {
      dispatch(
        updateLeadsNotes(
          commentNew,
          tempid,
          sortBy.value,
          limit1,
          offset1,
          search,
          data
        )
      );
    }
  };
  const handleDownload = () => {
    const filePath =
      process.env.REACT_APP_API + `/files/temp/LeadsExample.xlsx`;

    axios({
      method: "get",
      url: filePath,
      responseType: "blob",
      withCredentials: true, // Enable credentials
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "LeadsExamples.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (changeStatus) {
      update();
    }
  }, [changeStatus]);
  useEffect(() => {
    dispatch(GetSales());
    dispatch(getAllCont());
  }, []);

  const changeSt = [
    { value: "New", label: "New" },
    { value: "Contacted", label: "Contacted" },
    { value: "Qualified", label: "Qualified" },
    {
      value: "Unqualified",
      label: "Unqualified",
    },
    { value: "Archived", label: "Archived" },
    { value: "Booked", label: "Booked" },
  ];

  const [sortBy, setsortBy] = useState("");

  const sort1 = [
    { value: "a_z", label: "A to Z" },
    { value: "z_a", label: "Z to A" },
    { value: "d_a", label: "Oldest" },
    { value: "d_d", label: "Newest" },
    { value: "b_h", label: "High to  low budget" },
    { value: "b_l", label: "Low to high budget" },
  ];
  const [limit1, setlimit1] = useState(10);
  const [offset1, setoffset1] = useState(0);
  const [pagenumber1, setpagenumber1] = useState(1);

  useEffect(() => {
    dispatch(
      getLeads(
        data?.status?.map((el) => el.label).join(","),
        sortBy.value,
        limit1,
        offset1,
        "",
        data
      )
    );
    dispatch(getsources());
  }, [sortBy, limit1, offset1]);

  useEffect(() => {
    if (AssignLeadsSuccess) {
      setShowPop(false);
      setCheckedLeads([]);
      setSelectedSales([]);
      setCheckedLeads([]);
    }
  }, [AssignLeadsSuccess]);
  useEffect(() => {
    if (search) {
      const timer = setTimeout(() => {
        dispatch(
          getLeads(
            data?.status?.map((el) => el.label).join(","),
            sortBy.value,
            limit1,
            offset1,
            search,
            data
          )
        );
      }, 800);

      return () => clearTimeout(timer);
    } else if (search == "") {
      const timer = setTimeout(() => {
        dispatch(
          getLeads(
            data?.status?.map((el) => el.label).join(","),
            sortBy.value,
            limit1,
            offset1,
            "",
            data
          )
        );
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [search]);

  const addChecked = (id) => {
    const findLead = Leads.leads.find((student) => student.id === id);

    if (findLead) {
      if (checkedLeads.some((el) => el.value === id)) {
        setCheckedLeads(checkedLeads.filter((el) => el.value !== id));
      } else {
        setCheckedLeads([
          ...checkedLeads,
          {
            label: findLead.name,
            value: id,
            userId: id,
          },
        ]);
      }
    }
  };

  const selectAll = () => {
    if (checkedLeads.length == Leads?.leads?.length) {
      setCheckedLeads([]);
    } else {
      setCheckedLeads(
        Leads?.leads?.map((el) => ({
          label: el.name,
          value: el.id,
          userId: el.id,
        }))
      );
    }
  };
  const [clicked, setclicked] = useState(false);
  useEffect(() => {
    if (!addLeadsPop) {
      setLeadBody1(false);
      setLeadBody2(false);
    }
  }, [addLeadsPop]);
  const [docuploaded, setdocuploaded] = useState();

  const onDocChange = (event, name) => {
    if (event.target.files && event.target.files[0]) {
      const doc = { ...uploadedDoc };

      doc[name] = event.target.files[0];
      const doc2 = event.target.files[0];
      setdocuploaded(doc2);
      setUploadedDoc(doc);
    }
  };

  const ref = useRef(null);

  const changeDoc = () => {
    ref.current.click();
  };
  const uploadleadsdoc = () => {
    dispatch(CreateNewLeadsfile(docuploaded));
  };
  useEffect(() => {
    if (!createleaderror && addLeadsPop) {
      setAddLeadsPop(false);
    }
  }, [createleaderror]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const leads = [
      {
        name: leadsData.first_name + " " + leadsData.last_name,
        email: leadsData.email,
        phone: leadsData.phone,
        budget: leadsData.budget.value,
        referalSource: leadsData.referalSource.value,
        education_level: leadsData.level.value,
        country_of_residence: leadsData.country.value,
        user: user.id,
      },
    ];
    dispatch(CreateNewLeads(leads));
    setclicked(true);
  };

  useEffect(() => {
    if (createleaderror == "" && createleadSuccuss) {
      setAddLeadsPop(false);
      setLeadsData({});
      setclicked(false);
    }
  }, [createleaderror, createleadSuccuss]);

  const budgets = [
    { label: "0 to 5.000$", value: [0, 5000] },
    { label: "5.000 to 10.000$", value: [5000, 10000] },
    { label: "10.000 to 15.000$", value: [10000, 15000] },
    { label: "15.000$ +", value: [15000] },
  ];

  const levels = [
    { label: "Grade 9", value: "Grade 9" },
    { label: "Grade 10", value: "Grade 10" },
    { label: "Grade 11", value: "Grade 11" },
    { label: "Grade 12", value: "Grade 12" },
    { label: "Grade 13", value: "Grade 13" },
    { label: "Foundation/pre-university", value: "Foundation/pre-university" },
    { label: "Bachelor's Year 1", value: "Bachelor's Year 1" },
    { label: "Freshman", value: "Freshman" },
    { label: "Bachelor's Year 2", value: "Bachelor's Year 2" },
    { label: "Sophomore", value: "Sophomore" },
    { label: "Bachelor's Year 3", value: "Bachelor's Year 3" },
    { label: "Junior", value: "Junior" },
    { label: "Bachelor's Year 4", value: "Bachelor's Year 4" },
    { label: "Senior", value: "Senior" },
    { label: "Bachelor's Year 5+", value: "Bachelor's Year 5+" },
    { label: "Pre-master's", value: "Pre-master's" },
    { label: "Master's Year 1", value: "Master's Year 1" },
    { label: "Master's Year 2", value: "Master's Year 2" },
    { label: "PhD", value: "PhD" },
  ];

  const handleAssignLeads = () => {
    dispatch(
      assignLead(
        data?.status?.map((el) => el.label).join(","),
        sortBy.value,
        limit1,
        offset1,
        "",
        data,
        {
          sales: selectedSales.value,

          lead: checkedLeads.map((el) => {
            return el.value;
          }),
        }
      )
    );
  };

  useEffect(() => {
    if (DeleteLeadsSuccess) {
      setCheckedLeads([]);
      setDeletePop(false);
    }
  }, [DeleteLeadsSuccess]);

  useEffect(() => {
    if (DeleteLeadsError) {
      setDeleteError(DeleteLeadsError);
    }
  }, [DeleteLeadsError]);
  return (
    <>
      {(loading ||
        UploadLeadsLoading ||
        LoadingCreateLeads ||
        LoadingUpdateLeads) && <Loader fullScreen={true} />}

      <div className="leads_main_div">
        <div className="leads_top_div_main">
          <div className="leads_top_div">
            <h1 className="heads_main_header"> Leads</h1>
            <img
              src={plus}
              alt="add"
              className="add_leads_img"
              onClick={() => setAddLeadsPop(true)}
            />
          </div>
          <div className="leads_top_div_header">
            <div className="catgUni_search">
              <SearchInput
                placeholder="Search for Leads"
                width={screenWidth > 768 ? "400px" : "100%"}
                inputV={search}
                setInputV={setSearch}
              />
            </div>
            <Button
              text={
                scWidth <= 680 ? (
                  <img src={filter} alt="filter" className="filter_img_uni" />
                ) : (
                  "Add filter"
                )
              }
              color="blue"
              width="150px"
              onClick={() => {
                setOpenFilter(true);
              }}
            />
          </div>
        </div>

        <div className="leads_top_div_sub1">
          <div className="leads_top_div_sub">
            <h2 className="leads_top_div_sub_div_h2">Leads</h2>
            <div className="leads_top_sub_sort">
              <div className="leads_top_sub_sort_div">
                {checkedLeads.length > 0 && (
                  <div className="leads_top_sub_sort_div2">
                    {checkedLeads?.length == 1 && (
                      <Button
                        text="Delete"
                        color="pink"
                        onClick={() => setDeletePop(true)}
                        width="10rem"
                      />
                    )}
                    <SortBy
                      width="14rem"
                      placeholder="Change Status"
                      data={changeSt}
                      selectedData={changeStatus}
                      value={changeStatus}
                      setSelectedData={setchangeStatus}
                    />
                    <Button
                      text="Assign to Sales"
                      width="100%"
                      color="pink"
                      onClick={() => setShowPop(true)}
                    />
                  </div>
                )}
              </div>
              <SortBy
                width="90%"
                placeholder="Sort By"
                data={sort1}
                selectedData={sortBy}
                value={sortBy}
                setSelectedData={setsortBy}
              />
            </div>
          </div>
          {Leads?.leads?.length > 0 ? (
            <div className="leads_top_sub_table_cont">
              <UserTable
                keys={keys}
                data={Leads?.leads?.map((el) => {
                  return {
                    id: el.id,
                    name: el.name,
                    status: el.status,
                    email: el.email,
                    phone: el.phone,
                    source: el?.referalSource?.name,
                    budget:
                      (el?.budget && (el?.budget?.[0] || el?.budget?.[0] == 0)
                        ? el.budget?.[0].toLocaleString() + " $"
                        : "") +
                      (el?.budget && (el?.budget[1] || el?.budget[1] == 0)
                        ? "-" + el.budget[1].toLocaleString() + " $"
                        : ""),
                    contactedBy:
                      el?.contactedBy &&
                      el?.contactedBy?.first_name +
                        " " +
                        el?.contactedBy?.last_name,
                    assigned:
                      el?.user &&
                      el?.user?.first_name + " " + el?.user?.last_name,

                    country: el?.country_of_residence,
                    education: el?.education_level,

                    comment: el.notes,
                  };
                })}
                type={2}
                setCommentIndex={setCommentIndex}
                checkedLeads={checkedLeads}
                addChecked={addChecked}
                selectAll={selectAll}
              />
            </div>
          ) : (
            <div className="title_lead">No Leads Found</div>
          )}
          <div className="leads_pagination">
            {Leads?.leads?.length > 0 && (
              <Pagination
                pageNumber={pagenumber1}
                setPageNumber={setpagenumber1}
                offset={offset1}
                setOffset={setoffset1}
                limit={limit1}
                hasPrev={Leads.previous}
                hasNext={Leads.next}
              />
            )}
          </div>{" "}
        </div>
        {showComment && (
          <Popup
            showClose={screenWidth <= 768 && true}
            width="40%"
            setShowPopup={setShowComment}
            body={
              <div className="leads_comment_popUp">
                <h1>Lead's Comment</h1>
                <textarea
                  className="leads_comment_details"
                  placeholder="Enter your comment here"
                  onChange={(e) => setCommentNew(e.target.value)}
                  defaultValue={
                    commentIndex !== -1
                      ? Leads.leads[commentIndex]?.notes === null
                        ? ""
                        : Leads.leads[commentIndex]?.notes
                      : ""
                  }
                ></textarea>
                <Button
                  text="Save"
                  color="yellow"
                  width="100%"
                  disabled={commentNew === "" ? true : false}
                  onClick={() => {
                    setShowComment(false);
                    setCommentIndex(-1);
                    updatenotes();
                  }}
                />
              </div>
            }
          />
        )}
        {addLeadsPop && (
          <Popup
            setShowPopup={setAddLeadsPop}
            body={
              !leadBody1 && !leadBody2 ? (
                <div className="add_leads_popUp">
                  <h1 className="add_leads_header">Add Lead</h1>

                  <div className="add_leads_sub_title">
                    Do you want to add a new lead manually or using a document?
                  </div>
                  <div className="add_leads_sub_btns">
                    <Button
                      text="Manually"
                      color="blue"
                      width="100%"
                      onClick={() => setLeadBody1(true)}
                    />
                    <Button
                      text="Document"
                      color="yellow"
                      width="100%"
                      onClick={() => setLeadBody2(true)}
                    />
                  </div>

                  <div
                    className="add_leads_footer"
                    onClick={() => handleDownload()}
                  >
                    Download mockup document
                  </div>
                </div>
              ) : leadBody1 ? (
                <div className="add_leads_popUp">
                  <h1 className="add_leads_header">Add Lead</h1>

                  <form
                    className="add_leads_input_div"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="dropdown_flex">
                      <div className="input_div">
                        <div className="input_tile">First Name</div>
                        <Input
                          placeholder="First Name"
                          type="text"
                          width={"100%"}
                          onChange={(e) =>
                            setLeadsData({
                              ...leadsData,
                              first_name: e.target.value,
                            })
                          }
                          required={true}
                          value={leadsData?.first_name}
                        />{" "}
                      </div>
                      <div className="input_div">
                        <div className="input_tile">First Name</div>
                        <Input
                          placeholder="Last Name"
                          type="text"
                          width={"100%"}
                          onChange={(e) =>
                            setLeadsData({
                              ...leadsData,
                              last_name: e.target.value,
                            })
                          }
                          required={true}
                          value={leadsData?.last_name}
                        />
                      </div>
                    </div>

                    <div className="dropdown_flex">
                      <div className="input_div">
                        <div className="input_tile">Email</div>
                        <Input
                          placeholder="xxxx@gmail.com"
                          type="email"
                          width={"100%"}
                          onChange={(e) =>
                            setLeadsData({
                              ...leadsData,
                              email: e.target.value,
                            })
                          }
                          required={true}
                          value={leadsData?.email}
                        />
                      </div>
                      <div className="input_div">
                        <div className="input_tile">Budget</div>
                        <DropDown
                          placeholder="Budget"
                          setSelected={(e) =>
                            setLeadsData({ ...leadsData, budget: e })
                          }
                          data={budgets}
                          blueArrow={true}
                          multiSelection={false}
                          searchable={false}
                          selectedData={leadsData?.budget}
                          keepPlaceHolder={false}
                          value={leadsData?.budget}
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="dropdown_flex">
                      <div className="input_div">
                        <div className="input_tile">Phone Number</div>
                        <MobileInput
                          width="100%"
                          setPhone={(e) =>
                            setLeadsData({ ...leadsData, phone: e })
                          }
                          phone={leadsData?.phone}
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="input_div">
                        <div className="input_tile">Source</div>
                        <DropDown
                          placeholder="Source"
                          setSelected={(e) =>
                            setLeadsData({ ...leadsData, referalSource: e })
                          }
                          data={Sources?.map((el) => {
                            return {
                              label: el.name,
                              value: el.id,
                            };
                          })}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={leadsData?.referalSource}
                          keepPlaceHolder={false}
                          value={leadsData?.referalSource}
                        />
                      </div>
                    </div>
                    <div className="dropdown_flex">
                      <div className="input_div">
                        <div className="input_tile">Country of Residence</div>
                        <DropDown
                          placeholder="Country of Residence"
                          setSelected={(e) =>
                            setLeadsData({ ...leadsData, country: e })
                          }
                          data={Countries?.map((el) => {
                            return {
                              label: el.name,
                              value: el.name,
                            };
                          })}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={leadsData?.country}
                          keepPlaceHolder={false}
                          value={leadsData?.country}
                        />
                      </div>
                      <div className="input_div">
                        <div className="input_tile">Level of Education</div>
                        <DropDown
                          placeholder="Level of Education"
                          setSelected={(e) =>
                            setLeadsData({ ...leadsData, level: e })
                          }
                          data={levels}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={leadsData?.level}
                          keepPlaceHolder={false}
                          value={leadsData?.level}
                        />
                      </div>
                    </div>
                    {createleaderror && clicked && (
                      <div className="error_message">{createleaderror}</div>
                    )}
                    <Button
                      text="Add"
                      color="yellow"
                      width="100%"
                      disabled={
                        leadsData?.first_name &&
                        leadsData?.last_name &&
                        leadsData?.email &&
                        leadsData?.phone &&
                        leadsData?.budget &&
                        leadsData?.referalSource &&
                        leadsData?.country &&
                        leadsData?.level
                          ? false
                          : true
                      }
                    />
                  </form>
                </div>
              ) : (
                leadBody2 && (
                  <div className="add_leads_popUp">
                    <h1 className="add_leads_header">Add Lead</h1>
                    <div className="add_leads_subHeader">
                      Upload the mockup excel sheet
                    </div>
                    <div
                      className="upload_doc_div_body"
                      onClick={() => changeDoc()}
                    >
                      <input
                        type="file"
                        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        style={{ display: "none" }}
                        ref={ref}
                        onChange={(e) => onDocChange(e, docName)}
                      />

                      {!uploadedDoc[docName] ? (
                        <img
                          src={uploadHere}
                          alt="add"
                          className="upload_doc_div_body_img2"
                        />
                      ) : (
                        uploadedDoc[docName].name
                      )}
                    </div>
                    {createleaderror && (
                      <div className="error">{createleaderror}</div>
                    )}
                    <Button
                      text="Add"
                      color="yellow"
                      width="100%"
                      disabled={uploadedDoc[docName] ? false : true}
                      onClick={() => uploadleadsdoc()}
                    />
                  </div>
                )
              )
            }
            width="40rem"
            showClose={screenWidth <= 768 && true}
          />
        )}

        {deletePop && (
          <Popup
            setShowPopup={setDeletePop}
            body={
              <div className="add_leads_popUp">
                <h1 className="add_leads_header">Delete Lead</h1>

                <div className="add_leads_sub_title">
                  Are you sure you want to delete this lead?
                </div>
                <div className="add_leads_sub_btns">
                  <Button
                    text="Delete"
                    color="pink"
                    width="100%"
                    onClick={() => deleteLeads1()}
                  />
                  <Button
                    text="Cancel"
                    color="yellow"
                    width="100%"
                    onClick={() => setDeletePop(false)}
                  />
                </div>
                {deleteError && (
                  <div className="error" style={{ marginTop: "1rem" }}>
                    {deleteError}
                  </div>
                )}
              </div>
            }
            width="40rem"
            showClose={screenWidth <= 768 && true}
          />
        )}

        {openFilter && (
          <Filters
            setOpened={setOpenFilter}
            sales={true}
            data={data}
            setData={setData}
          />
        )}
        {ShowPop && (
          <Popup
            setShowPopup={setShowPop}
            // showClose={true}
            body={
              <div className="popup_body">
                {LoadingAssignLeads ? (
                  <Loader />
                ) : (
                  <>
                    <div className="popup_body_title">
                      Assign Leads to Sales
                    </div>
                    <div className="popup_body_content">
                      <div className="profile_input_top">
                        <div className="input_title">Choose Sales</div>
                        <DropDown
                          searchable={true}
                          setSelected={setSelectedSales}
                          data={Sales?.map((el) => {
                            return {
                              label: el?.first_name + " " + el?.last_name,
                              value: el?.id,
                            };
                          })}
                          placeholder={"Choose Sales"}
                          blueArrow={true}
                          multiSelection={false}
                          selectedData={selectedSales}
                          keepPlaceHolder={false}
                          value={selectedSales}
                          width={"100%"}
                        />
                      </div>
                      <div className="profile_input_top">
                        <div className="input_title">Choose Leads</div>
                        <DropDown
                          placeholder="Choose Leads "
                          setSelected={setCheckedLeads}
                          data={Leads?.leads?.map((el) => {
                            return {
                              label: el.name,
                              value: el.id,
                              userId: el.id,
                            };
                          })}
                          blueArrow={true}
                          width="100%"
                          multiSelection={true}
                          isClearable={false}
                          searchable={false}
                          keepPlaceHolder={true}
                          selectedData={checkedLeads}
                        />
                      </div>
                      {AssignLeadsError && (
                        <div className="error">{AssignLeadsError}</div>
                      )}
                      <div className="popup_buttons">
                        <Button
                          text="Save"
                          color="yellow"
                          width="100%"
                          disabled={
                            checkedLeads.length !== 0 &&
                            selectedSales.length !== 0
                              ? false
                              : true
                          }
                          onClick={() => {
                            handleAssignLeads();
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            }
            width="30rem"
          />
        )}
      </div>
    </>
  );
}

export default Leads;
