import React, { startTransition, useEffect, useState } from "react";
import "./ChangePass.scss";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import Check from "../../../Shared/Assets/Images/checkbox.svg";
import FormLayout from "../../../Shared/Components/FormLayout/FormLayout";
import Star from "../../../Shared/Components/Animations/Star/Star";
import Button from "../../../Shared/Components/Button/Button";
import Input from "../../../Shared/Components/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Redux/User/UserActions";
function ChangePass() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confPass, setConfPass] = useState("");
  const [error, setError] = useState("");
  const [ready, setReady] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [error2, setError2] = useState("");
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [error3, setError3] = useState("");

  const { resetPasswordLoading, resetPasswordError, resetPasswordSuccess } =
    useSelector((store) => store.userReducer);
  const checkPass = (e) => {
    var pass = e;
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (
      pass.length >= 8 &&
      /\d/.test(pass) &&
      /[A-Z]/.test(pass) &&
      /[a-z]/.test(pass) &&
      format.test(pass)
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
    if (pass.length >= 8) {
      document.getElementById("8char").classList.add("list_checked");
    } else {
      document.getElementById("8char").classList.remove("list_checked");
    }

    if (/\d/.test(pass)) {
      document.getElementById("cNub").classList.add("list_checked");
    } else {
      document.getElementById("cNub").classList.remove("list_checked");
    }

    if (/[A-Z]/.test(pass)) {
      document.getElementById("cUpc").classList.add("list_checked");
    } else {
      document.getElementById("cUpc").classList.remove("list_checked");
    }

    if (/[a-z]/.test(pass)) {
      document.getElementById("cLpc").classList.add("list_checked");
    } else {
      document.getElementById("cLpc").classList.remove("list_checked");
    }

    if (format.test(pass)) {
      document.getElementById("cSc").classList.add("list_checked");
    } else {
      document.getElementById("cSc").classList.remove("list_checked");
    }

    setPassword(pass);
  };
  useEffect(() => {
    if (confPass && password) {
      if (confPass != password) {
        setError("Passwords do not match!");
      } else {
        setError("");
      }
    }
  }, [password, confPass]);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (password == "" || confPass == "" || !ready || error) return false;
    else {
      dispatch(
        resetPassword({
          otp: state.otp,
          newPass: password,
          confirmPass: confPass,
          phone: state.phone,
        })
      );
    }
  };

  useEffect(() => {
    if (resetPasswordSuccess && password) {
      setShow(true);
    }

  }, [resetPasswordSuccess]);

  useEffect(() => {
    if (resetPasswordError && password) {
      setError3(resetPasswordError);
    }
  }, [resetPasswordError]);

  useEffect(() => {
    if (resetPasswordLoading && password) {
      setLoader(true);
    }
    else
    {
      setLoader(false);
    }
  }, [resetPasswordLoading]);

  return (
    <div className="changePass_main_div">
      {show && (
        <UpperPopUp
          setShow={setShow}
          title="Password Changed"
          img={Check}
          navigate={()=>navigate(state.staff ? `/staff/login` : "/student/login")}
        />
      )}
      <FormLayout
        header="Reset Your Password"
        subHeader="Create your new password"
        body={
          <form
            className="changePass_main_body"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="changePass_inputs">
              <div className="input_div">
                <div className="input_tile">Password</div>
                <Input
                  placeholder="Enter your new password"
                  type="password"
                  width={"100%"}
                  onChange={(e) => checkPass(e.target.value)}
                  required={true}
                  value={password}
                  onClick={() => {
                    setShowPopUp(true);
                  }}
                  onBlur={() => {
                    setShowPopUp(false);
                    if (!ready) {
                      setError2("Password must be strong!");
                    } else setError2("");
                  }}
                />
                <div
                  className="password_popUp"
                  style={{ display: showPopUp ? "block" : "none" }}
                >
                  <ul className="password_check">
                    <li id="8char">8 Characters </li>
                    <li id="cNub">Contains Number </li>
                    <li id="cLpc">Contains LowerCase </li>
                    <li id="cUpc">Contains Uppercase </li>
                    <li id="cSc">Contains Special Character</li>
                  </ul>
                </div>
              </div>
              <div className="input_div">
                <div className="input_tile">Repeat Password</div>
                <Input
                  placeholder="Confirm your password"
                  type="password"
                  width={"100%"}
                  onChange={(e) => setConfPass(e.target.value)}
                  required={true}
                  value={confPass}
                />
              </div>
            </div>
            {error && <div className="error">{error}</div>}
            {error2 && <div className="error">{error2}</div>}
            {error3 && <div className="error">{error3}</div>}


            <div className="changePass_main_footer">
              <Button
                text="Change Password"
                color="lightGreen"
                width="100%"
                disabled={
                  (password == "" || confPass == "" || error || error2) && true
                }
              />
            </div>
          </form>
        }
        minHeight="fit-content"
        width="90%"
        icon={<Star />}
        bg="var(--lightGreen)"
        gap="20px"
        loading={loader}
      />
    </div>
  );
}

export default ChangePass;
