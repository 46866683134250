import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MyDocuments.scss";
import download from "../../../Shared/Assets/Images/download.png";
import trash from "../../../Shared/Assets/Images/trash.png";
import attachment from "../../../Shared/Assets/Images/attachment.png";
import Popup from "../../../Shared/Components/Popup/Popup";
import Button from "../../../Shared/Components/Button/Button";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocs,
  deleteDoc,
  getmissingDocs,
  newuploadmissingDocs,
  uploadmissingDocs,
} from "../../Redux/Documents/DocumentsActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import Radio from "../../../Shared/Components/Radio/Radio";
import uploadHere from "../../../Shared/Assets/Images/uploadHere.png";
import UploadDoc from "../../../Shared/Components/UploadDoc/UploadDoc";
import { getStudentMe } from "../../Redux/Student/StudentActions";
import axios from "axios";
function MyDocuments() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [load1, setLoad1] = useState(true);
  const [load2, setLoad2] = useState(true);
  const [load3, setLoad3] = useState(true);

  const {
    Docs,
    fetchDocsLoading,
    MissingDocs,
    missingDocLoading,
    missingDocFail,
  } = useSelector((state) => state.DocumentsReducer);
  const { student, fetchLoading } = useSelector(
    (state) => state.StudentReducer
  );
  useEffect(() => {
    dispatch(getStudentMe());
  }, []);

  useEffect(() => {
    if (student && student.id) {
      dispatch(getDocs());
      dispatch(getmissingDocs(student.id));
    }
  }, [student]);

  useEffect(() => {
    if (missingDocLoading) {
      setLoad1(true);
    } else {
      setLoad1(false);
    }
    if (fetchDocsLoading) {
      setLoad2(true);
    } else {
      setLoad2(false);
    }
    if (fetchLoading) {
      setLoad3(true);
    } else {
      setLoad3(false);
    }
  }, [missingDocLoading, fetchDocsLoading, fetchLoading]);
  const [documents, setdocuments] = useState([]);

  useEffect(() => {
    setdocuments(
      Docs.map((doc) => {
        const date = doc.createdAt.split("T")[0];
        return {
          name: doc?.data?.originalName,
          created_at: date,
          format: doc?.data.type,
          size: (doc?.data?.size / 1024 / 1024).toFixed(2) + "MB",
          id: doc?.id,
          Path: doc?.data?.path,
        };
      })
    );
  }, [Docs]);
  // useEffect(() => {
  //   if (MissingDocs && MissingDocs.length > 0) {
  //     MissingDocs?.map((doc) => {
  //       const firstThreeDigits = doc?.application.slice(0, 3);
  //       const lastThreeDigits = doc?.application.slice(-3);
  //       const app_number = "#" + firstThreeDigits + lastThreeDigits;
  //       const app_id = doc?.application;
  //       setmissingDocuments(
  //         doc?.documents.map((doc) => {
  //           return {
  //             app_number: "App_" + app_number,
  //             university: doc?.university,
  //             application_id: app_id,
  //             Document: doc.name,
  //             format_needed: "PDF",
  //           };
  //         })
  //       );
  //     });
  //   }
  // }, [MissingDocs]);

  const [tempdeleteid, settempdeleteid] = useState(null);
  const [getmiss, setgetmiss] = useState(true);
  const handledelete = () => {
    setShowPopup(false);
    dispatch(deleteDoc(tempdeleteid, student.id, getmiss));
    settempdeleteid(null);
  };
  const handlePreview = (path, name) => {
    const filePath =
      process.env.REACT_APP_API +
      `/files/students/${student.id}/documents/${path}`; // Replace with the actual path to the file fetched from the backend
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", name); // Specify the desired filename
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownload = (path, name) => {
    const filePath =
      process.env.REACT_APP_API +
      `/files/students/${student.id}/documents/${path}`;

    axios({
      method: "get",
      url: filePath,
      responseType: "blob",
      withCredentials: true, // Enable credentials
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [showattach, setshowattach] = useState(false);
  const [fromPC, setFromPC] = useState(false);
  const [uploadedDoc, setUploadedDoc] = useState({});
  const [chosenDoc, setChosenDoc] = useState({});

  const ref = useRef(null);

  const changeDoc = () => {
    ref.current.click();
  };
  const chooseDocument = (element, name) => {
    const doc = { ...chosenDoc };
    doc[name] = element;
    setChosenDoc(doc);
  };
  const [docName, setDocName] = useState("");
  const onDocChange = (event, name) => {
    if (event.target.files && event.target.files[0]) {
      const doc = { ...uploadedDoc };
      doc[name] = event.target.files[0];
      setUploadedDoc(doc);
    }
  };
  const [docsDrop, setdocsDrop] = useState([]);
  useEffect(() => {
    setdocsDrop(
      documents.map((doc) => {
        return {
          label: doc.name,
          value: doc.id,
        };
      })
    );
  }, [documents]);
  const [temp_appid, settemp_appid] = useState("");
  const handleupload = () => {
    const app_id = temp_appid;
    const id = student.id;
    if (fromPC) {
      const data = new FormData();
      data.append("document", uploadedDoc[docName]);
      data.append("documentName", docName);
      data.append("student", student.id);
      dispatch(newuploadmissingDocs(app_id, data, id));
    } else {
      const doc_id = chosenDoc[docName].value;
      const data = {
        documentName: docName,
        student: student.id,
      };
      dispatch(uploadmissingDocs(temp_appid, doc_id, data, student.id));
    }
    setshowattach(false);
    settemp_appid("");
    setFromPC(false);
    setUploadedDoc({});
    setChosenDoc({});
    setDocName("");
  };
  function trimStringIfTooBig(str) {
    if (str.length > 10) {
      // Trim the string to the specified maxLength and add "..." at the end
      return str.substring(0, 10) + "...";
    }
    return str; // Return the original string if it's within the maxLength
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Add leading zeros if necessary
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");

    return `${formattedMonth}/${formattedDay}/${year}`;
  }
  return (
    <div className="mydocument_container">
      {(load1 || load2 || load3) && <Loader fullScreen={true} />}

      <div className="mydocument_container_top">
        <div className="mydocument_title">My Documents</div>
        <div className="mydocuments_subtitle">
          All your uploaded documents are listed here.
        </div>
        {documents?.length === 0 ? (
          <>
            <div className="no_document_found">No Documents Found</div>
          </>
        ) : (
          <>
            <div className="table_container">
              <table className="document_table">
                <tbody>
                  <tr>
                    <td className="document_table_cell document_table_title">
                      Document Name
                    </td>
                    <td className="document_table_cell document_table_title">
                      Date Uploaded
                    </td>
                    <td className="document_table_cell document_table_title">
                      Format
                    </td>
                    <td className="document_table_cell document_table_title">
                      Size
                    </td>
                    <td className="document_table_cell document_table_title action_keys">
                      More
                    </td>
                  </tr>
                  {documents.map((document, index) => {
                    return (
                      <>
                        <tr className="MyDocumentsTr" key={index}>
                          <td
                            className={
                              index == documents.length - 1
                                ? "document_table_cell last"
                                : "document_table_cell"
                            }
                            onClick={() =>
                              handlePreview(document.Path, document.name)
                            }
                          >
                            {document?.name.length > 10
                              ? trimStringIfTooBig(document.name)
                              : document.name}
                          </td>
                          <td
                            className={
                              index == documents.length - 1
                                ? "document_table_cell last"
                                : "document_table_cell"
                            }
                            onClick={() =>
                              handlePreview(document.Path, document.name)
                            }
                          >
                            {document.created_at}
                          </td>
                          <td
                            className={
                              index == documents.length - 1
                                ? "document_table_cell last"
                                : "document_table_cell"
                            }
                            onClick={() =>
                              handlePreview(document.Path, document.name)
                            }
                          >
                            {document.format}
                          </td>
                          <td
                            className={
                              index == documents.length - 1
                                ? "document_table_cell last"
                                : "document_table_cell"
                            }
                            onClick={() =>
                              handlePreview(document.Path, document.name)
                            }
                          >
                            {document.size}
                          </td>
                          <td
                            className={
                              index == documents.length - 1
                                ? "document_table_cell action_keys last"
                                : "document_table_cell action_keys"
                            }
                          >
                            <img
                              src={download}
                              alt="download"
                              className="download_doc"
                              onClick={() =>
                                handleDownload(document.Path, document.name)
                              }
                            />
                            <img
                              src={trash}
                              alt="trash"
                              className="delete_doc"
                              onClick={() => {
                                setShowPopup(true);
                                settempdeleteid(document.id);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="documents_cont_mobile">
              {documents?.map((document, index) => {
                return (
                  <>
                    <div key={index} className="single_div_mobile">
                      <div className="single_div_inner">
                        <div
                          className="left_div_mobile"
                          onClick={() =>
                            handlePreview(document.Path, document.name)
                          }
                        >
                          <div className="left_title">
                            {document?.name.length > 10
                              ? trimStringIfTooBig(document.name)
                              : document.name}
                          </div>
                          <div className="left_subtitle">
                            {document.created_at}
                          </div>
                        </div>
                        <div className="middle_div_mobile">
                          <div className="middle_title">{document.format}</div>
                          <div className="middle_subtitle">{document.size}</div>
                        </div>
                        <div className="right_div_mobile">
                          <img
                            src={download}
                            alt="download"
                            className="download_doc"
                            onClick={() =>
                              handleDownload(document.Path, document.name)
                            }
                          />
                          <img
                            src={trash}
                            alt="trash"
                            className="delete_doc"
                            onClick={() => {
                              setShowPopup(true);
                              settempdeleteid(document.id);
                              setDocName(document.name);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className="mydocuments_bottom">
        <div className="mydocuments_bottom_inner">
          <div className="mydocuments_bottom_title">My Missing Documents</div>
          {missingDocFail && <div className="error">{missingDocFail}</div>}
          {MissingDocs && MissingDocs?.length !== 0 ? (
            <div className="mydocuments_bottom_table">
              <table className="document_table">
                <tbody>
                  <tr>
                    <td className="document_table_cell document_table_title">
                      Application Number
                    </td>
                    <td className="document_table_cell document_table_title">
                      University
                    </td>
                    <td className="document_table_cell document_table_title">
                      Document
                    </td>
                    <td className="document_table_cell document_table_title">
                      Due Date
                    </td>
                    <td className="document_table_cell document_table_title">
                      Max Size
                    </td>
                    <td className="document_table_cell document_table_title action_keys">
                      More
                    </td>
                  </tr>

                  {MissingDocs &&
                    MissingDocs?.length > 0 &&
                    MissingDocs?.map((doc, index) => {
                      const firstThreeDigits = doc?.application.slice(0, 3);
                      const lastThreeDigits = doc?.application.slice(-3);
                      const app_number =
                        "#" + firstThreeDigits + lastThreeDigits;

                      return doc?.documents.map((document, index) => {
                        const temp_id = doc?.application;

                        return (
                          <>
                            <tr key={index}>
                              <td className={"document_table_cell"}>
                                {"App_"}
                                {app_number}
                              </td>
                              <td className={"document_table_cell"}>
                                {document.university}
                              </td>
                              <td className={"document_table_cell"}>
                                {document?.name.length > 10
                                  ? trimStringIfTooBig(document.name)
                                  : document.name}
                              </td>
                              <td className={"document_table_cell"}>
                                {document.dueDate !== null
                                  ? formatDate(document.dueDate)
                                  : "None"}
                              </td>
                              <td className={"document_table_cell"}>5MB</td>
                              <td className={"document_table_cell action_keys"}>
                                <img
                                  src={attachment}
                                  alt="attachment"
                                  className="attachment_doc"
                                  onClick={() => {
                                    setshowattach(true);
                                    settemp_appid(temp_id);
                                    setDocName(document.name);
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      });
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no_document_found">No Missing Documents Found</div>
          )}

          <div className="signle_bottom documents_cont_mobile">
            {MissingDocs?.map((doc, index) => {
              const firstThreeDigits = doc?.application.slice(0, 3);
              const lastThreeDigits = doc?.application.slice(-3);
              const app_number = "#" + firstThreeDigits + lastThreeDigits;

              return doc?.documents.map((document, index) => {
                const temp_id = doc.application;

                return (
                  <div key={index} className=" single_div_mobile">
                    <div className="single_div_inner">
                      <div className="left_div_mobile">
                        <div className="left_title">
                          {"App_"}
                          {app_number}
                        </div>
                        <div className="left_subtitle">
                          {document.university}
                        </div>
                      </div>
                      <div className="middle_div_mobile">
                        <div className="middle_title">
                          {document?.name.length > 10
                            ? trimStringIfTooBig(document.name)
                            : document.name}
                        </div>
                        <div className="middle_subtitle">
                          {document.dueDate != null
                            ? formatDate(document.dueDate)
                            : "No Due Date"}
                        </div>
                      </div>

                      <div className="right_div_mobile">
                        <img
                          src={attachment}
                          alt="attachment"
                          className="attachment_doc"
                          onClick={() => {
                            setshowattach(true);
                            settemp_appid(temp_id);
                            setDocName(document.name);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              });
            })}
            {missingDocFail && <div className="error">{missingDocFail}</div>}
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          width={"25rem"}
          body={
            <div className="delet_popup">
              <div className="delet_doc_popup">
                Are you sure you want to delete this document?
              </div>
              <div className="delet_doc_body">
                If you deleted this document you need to upload new document for
                this application
              </div>
              <div className="delet_doc_bottom">
                <Button
                  text="Delete"
                  color={"pink"}
                  onClick={() => handledelete()}
                />
                <Button
                  text="Cancel"
                  color={"yellow"}
                  onClick={() => setShowPopup(false)}
                />
              </div>
            </div>
          }
        />
      )}
      {showattach && (
        <Popup
          setShowPopup={setshowattach}
          width="30%"
          body={
            <div className="attach_popup">
              <div className="step4_chooseDoc_main_header">Upload Document</div>
              <Radio
                left_text="My uploaded documents"
                right_text="My Computer"
                isVisible={true}
                setRadioValue={setFromPC}
                radioValue={fromPC}
              />
              {fromPC ? (
                <div
                  className="upload_doc_div_body"
                  onClick={() => changeDoc()}
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg, .png, .pdf, .doc, .xls"
                    vb
                    style={{ display: "none" }}
                    ref={ref}
                    onChange={(e) => onDocChange(e, docName)}
                  />

                  {!uploadedDoc[docName] ? (
                    <img
                      src={uploadHere}
                      alt="add"
                      className="upload_doc_div_body_img2"
                    />
                  ) : (
                    uploadedDoc[docName].name
                  )}
                </div>
              ) : (
                <div className="step4_chooseDoc_dropdown">
                  <DropDown
                    title="Choose a document"
                    placeholder="Choose a document"
                    setSelected={(e) => chooseDocument(e, docName)}
                    data={docsDrop}
                    blueArrow={true}
                    width="100%"
                    multiSelection={false}
                    searchable={true}
                    selectedData={chosenDoc[docName]}
                    value={chosenDoc[docName]}
                  />
                </div>
              )}
              <Button
                text="Save"
                color="yellow"
                width="100%"
                onClick={() => {
                  handleupload();
                }}
              />
            </div>
          }
        />
        // <UploadDoc
        //   setShow={setshowattach}
        //   title={"Choose Document from"}
        //   showRadio={true}
        //   uploadedDoc={uploadedDoc}
        //   setUploadedDoc={setUploadedDoc}
        //   setChosenDoc={setChosenDoc}
        //   chosenDoc={chosenDoc}
        //   onSubmit={() => {
        //     handleupload();
        //   }}
        //   docName={docName}
        //   setDocName={setDocName}
        // />
      )}
    </div>
  );
}

export default MyDocuments;
