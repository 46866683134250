import React, { useState } from "react";
import "./SearchInput.scss";
import searchIcon from "../../Assets/Images/search_icon.png";

function SearchInput({ placeholder, width, inputV, setInputV }) {
  return (
    <div style={{ width: width }}>
      <div className="Search_Main">
        <input className="Search_Input" type="text" placeholder={placeholder} value={inputV}  onChange={(e)=>setInputV(e.target.value)} />
        <img className="SearchImage" src={searchIcon} />
      </div>
    </div>
  );
}

export default SearchInput;
