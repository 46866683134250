import React from "react";
import { useEffect } from "react";
import "./UpperPopUp.scss";
function UpperPopUp(props) {
  //setShow to show the popUp
  //title to show the title
  //img to show the icon
  useEffect(() => {
    setTimeout(() => {
      props.setShow(false);
      if(props.navigate)
      props.navigate();
    }, 1800);
  }, []);
  return (
    <div className="upper_popUp_bg">
      <div className="upper_popUp_main_div">
        <h2 className="upper_popUp_title">{props.title}</h2>
        {props.img && <img src={props.img} className="upper_popUp_icon" />}
      </div>
    </div>
  );
}

export default UpperPopUp;
