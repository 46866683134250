import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SingleUni.scss";
import Button from "../../../Shared/Components/Button/Button";
import redHeart from "../../../Shared/Assets/Images/redheart.webp";
import heart from "../../../Shared/Assets/Images/heart.webp";
import Location from "../../../Shared/Assets/Images/location.webp";
import uni from "../../../Shared/Assets/Images/uni.jpeg";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import clock from "../../../Shared/Assets/Images/clock.png";
import tuition from "../../../Shared/Assets/Images/tuition.png";
import Intakes from "../../../Shared/Assets/Images/Intakes.png";
import CostOfLiving from "../../../Shared/Assets/Images/CostOfLiving.png";
import pinkCircle from "../../../Shared/Assets/Images/pinkCircle.png";
import circle1 from "../../../Shared/Assets/Images/pinkCircle.png";
import Loader from "../../../Shared/Components/Loader/Loader";
import yellowCircle from "../../../Shared/Assets/Images/yellowCircle.png";
import blueCircle from "../../../Shared/Assets/Images/blueCircle.png";
import greenCircle from "../../../Shared/Assets/Images/greenCircle.png";
import img from "../../../Shared/Assets/Images/checked.png";

import {
  getUniById,
  getMajorByFacultyId,
  getDegreeByMajorId,
  getMajorDegree,
  addFavorite,
} from "../../../Shared/Redux/Universities/UniversityActions";
import useInnerWidth from "../../../Hooks/UseInnerWidth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Popup from "../../../Shared/Components/Popup/Popup";
import {
  applyUsingUnified,
  getUniversitySemesters,
} from "../../../Shared/Redux/CreateApplication/CreateApplicationAction";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import { checkProgress } from "../../Redux/Overview/OverviewActions";
function SingleUni() {
  const { id } = useParams();
  const {
    University,
    Majors,
    Degrees,
    MajorDegree,
    getOneUniversityLoading,
    getMajorsOfFacyltiesByIdLoading,
    getDegreeByMajorIdLoading,
    addFavoriteLoading,
    getMajorDegreeLoading,
    getOneUniversitySuccess,
  } = useSelector((store) => store.UniversitiesReducer);

  const {
    getUniversitiesSemestersLoading,
    universitiesSemesters,
    applyUsingUnifiedApplicationLoading,
    applyUsingUnifiedApplicationError,
    applyUsingUnifiedApplicationSuccess,
    newUnifiedApplication,
  } = useSelector((store) => store.CreateApplicationReducer);

  const { checkProgressLoading, checkProgressFail, checkProgressSuccess } =
    useSelector((store) => store.OverviewReducer);

  const { user } = useSelector((store) => store.userReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [facultySelected, setFacultySelected] = useState("");
  const [majorSelected, setMajorSelected] = useState("");
  const [degreeSelected, setDegreeSelected] = useState("");
  const [applied, setApplied] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [facultyArray, setFacultyArray] = useState([]);
  const [MajorArray, setMajorArray] = useState([]);
  const [degreeArray, setDegreeArray] = useState([]);
  const [done, setDone] = useState(false);
  const [lowLoading, setLowLoading] = useState(false);
  const [semester, setSemester] = useState({});
  const [error, setError] = useState("");
  const [clicked, setClicked] = useState(false);
  const [showMissingDocuments, setShowMissingDocuments] = useState(false);
  const [applyLoad, setApplyLoad] = useState(false);
  const [showUpperPopUp, setShowUpperPopUp] = useState(false);
  const [showPop2, setShowPop2] = useState(false);
  const [showPop3, setShowPop3] = useState(false);
  const [chosenSem, setChosenSem] = useState();
  const { pathname } = useLocation();
  useEffect(() => {
    setFacultyArray([]);
    dispatch(getUniById(id));
    dispatch(getUniversitySemesters(id));
    if (
      !user.Student?.progress?.Complete_your_profile ||
      !user.Student?.progress?.Apply_to_universities ||
      !user.Student?.progress?.Upload_needed_documents ||
      !user.Student?.progress?.Schedule_a_meeting
    ) {
      dispatch(checkProgress());
    }
  }, [id]);
  useEffect(() => {
    if (getOneUniversitySuccess) {
      setDone(true);
    }
  }, [getOneUniversitySuccess]);
  useEffect(() => {
    if (
      University?.faculties?.length > 0 &&
      done &&
      !getOneUniversityLoading &&
      getOneUniversitySuccess
    ) {
      let facultyA = [];
      University.faculties.map((faculty) => {
        const f = { value: faculty.id, label: faculty.name };
        facultyA.push(f);
      });
      setFacultyArray(facultyA);
    }
  }, [University, done, getOneUniversitySuccess, getOneUniversityLoading]);

  useEffect(() => {
    if (facultySelected) {
      setMajorArray([]);
      setDegreeSelected("");
      setDegreeArray([]);
      dispatch(getMajorByFacultyId(facultySelected.value));
    }
  }, [facultySelected]);
  useEffect(() => {
    if (majorSelected) {
      setDegreeArray([]);
      setDegreeSelected("");
      dispatch(getDegreeByMajorId(majorSelected.value));
    }
  }, [majorSelected]);
  useEffect(() => {
    if (degreeSelected && !pathname.includes("student")) {
      dispatch(getMajorDegree(majorSelected.value, degreeSelected.value));
    } else if (degreeSelected) {
      dispatch(
        getMajorDegree(
          majorSelected.value,
          degreeSelected.value,
          user.Student.id
        )
      );
    }
  }, [degreeSelected]);

  useEffect(() => {
    if (Majors?.length > 0) {
      let MajorsA = [];
      Majors.map((Major) => {
        const Maj = { value: Major.id, label: Major.name };
        MajorsA.push(Maj);
      });
      setMajorArray(MajorsA);
    }
  }, [Majors]);
  useEffect(() => {
    if (Degrees?.length > 0) {
      let DegreeA = [];
      Degrees.map((Degree) => {
        const Deg = { value: Degree.id, label: Degree.name };
        DegreeA.push(Deg);
      });
      setDegreeArray(DegreeA);
    }
  }, [Degrees]);
  useEffect(() => {
    setFavorite(University.isFavourite);
  }, [University.isFavourite]);

  useEffect(() => {
    if (getMajorDegreeLoading) {
      setLowLoading(true);
    } else {
      setLowLoading(false);
    }
  }, [getMajorDegreeLoading]);

  const dateConv = (string) => {
    const date = new Date(string);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return formattedDate;
  };

  const handleApply = () => {
    if (pathname.includes("student")) {
      if (user.Student.progress.Complete_your_profile) {
        setShowPop2(false);
        if (MajorDegree.canEdit) {
          localStorage.removeItem("applicationId");
          localStorage.setItem("applicationId", MajorDegree.app_id);
          navigate("/student/my_applications/apply/" + MajorDegree.app_step);
        } else if (MajorDegree.canApply) {
          setShowPopUp(true);
        } else if (!MajorDegree.canEdit && !MajorDegree.canApply)
          navigate("/student/my_applications/" + MajorDegree.app_id);
      } else {
        setShowPop2(true);
      }
    } else {
      setShowPop3(true);
    }
  };

  // const applyWithUnified = () => {
  //   const application = { major_degree: string, semester: string };
  // };

  useEffect(() => {
    if (universitiesSemesters && universitiesSemesters.length > 0) {
      let temp = {};
      let arr = [];
      universitiesSemesters.map((item) => {
        temp = {
          value: item.id,
          label: item.name + " " + new Date(item.start_date).getFullYear(),
        };
        arr.push(temp);
      });

      setSemester(arr);
    }
  }, [universitiesSemesters]);

  const applyWithUnified = () => {
    setClicked(true);
    const application = {
      major_degree: MajorDegree.id,
      semester: chosenSem.value,
    };
    dispatch(applyUsingUnified(application));
  };

  useEffect(() => {
    if (applyUsingUnifiedApplicationError && clicked) {
      setError(applyUsingUnifiedApplicationError);
    } else {
      setError("");
    }
  }, [applyUsingUnifiedApplicationError]);

  useEffect(() => {
    if (
      applyUsingUnifiedApplicationSuccess &&
      newUnifiedApplication &&
      clicked
    ) {
      setUnified(false);
      if (newUnifiedApplication.missing_documents) {
        setShowMissingDocuments(true);
        setShowUpperPopUp(true);
      } else {
        setShowPopUp(false);
        setShowUpperPopUp(true);
      }
    }
  }, [applyUsingUnifiedApplicationSuccess]);

  useEffect(() => {
    if (applyUsingUnifiedApplicationLoading) {
      setApplyLoad(true);
    } else {
      setApplyLoad(false);
    }
  }, [applyUsingUnifiedApplicationLoading]);

  const [unified, setUnified] = useState(false);
  return (
    <>
      {(getOneUniversityLoading ||
        getUniversitiesSemestersLoading ||
        checkProgressLoading) && <Loader fullScreen={true} />}

      {showUpperPopUp && (
        <UpperPopUp
          setShow={setShowUpperPopUp}
          title="Application submitted"
          img={img}
          navigate={() => {
            if (!showPopUp) {
              localStorage.removeItem("applicationId");

              navigate("/student/my_applications");
            }
          }}
        />
      )}
      <div className="SingleUni_Contatiner">
        {showPop3 && (
          <Popup
            body={
              <div className="application_popUp">
                <h1 className="application_popUp_header">
                  Hello {user?.first_name}
                </h1>
                <div className="application_popUp_body">
                  <p className="application_popUp_subtext">
                    Let's get started by filling out the student's application
                    with Education Basket to the {University.name}!
                  </p>
                  <Button
                    text="Get Started"
                    onClick={() => {
                      localStorage.removeItem("applicationId");

                      setShowPopUp(false);
                      navigate("/counselor/applications/create/1", {
                        state: {
                          majorId: MajorDegree.id,
                          uniId: University.id,
                          counselor: true,
                        },
                      });
                    }}
                    color="yellow"
                    width="70%"
                  />
                  {error && <div className="error">{error}</div>}
                </div>
              </div>
            }
            width="50%"
            setShowPopup={setShowPop3}
          />
        )}

        {showPop2 && (
          <Popup
            body={
              <div className="application_popUp">
                <h1 className="application_popUp_header">
                  Hello {user?.first_name}
                </h1>
                <div className="application_popUp_body">
                  <p className="application_popUp_subtext">
                    You haven't completed your profile yet! <br />
                    Please complete your profile to be able to apply to
                    universities.
                  </p>
                  <div
                    className="application_popUp_footer2"
                    style={{ display: "flex" }}
                  >
                    <Button
                      text="Complete Profile"
                      onClick={() => {
                        navigate(`/student/profile`);
                      }}
                      color="blue"
                      width="12rem"
                    />
                    <Button
                      text="Cancel"
                      onClick={() => {
                        setShowPop2(false);
                      }}
                      color="pink"
                      width="12rem"
                    />
                  </div>

                  {error && <div className="error">{error}</div>}
                </div>
              </div>
            }
            width="50%"
            setShowPopup={setShowPopUp}
          />
        )}
        {showPopUp && MajorDegree && (
          <Popup
            body={
              !MajorDegree.hasUnified ? (
                <div className="application_popUp">
                  <h1 className="application_popUp_header">
                    Hello {user?.first_name}
                  </h1>
                  <div className="application_popUp_body">
                    <p className="application_popUp_subtext">
                      Let's start by filling out your EB application to the{" "}
                      {University.name}!
                    </p>
                    <Button
                      text="Get Started"
                      onClick={() => {
                        localStorage.removeItem("applicationId");

                        setShowPopUp(false);
                        navigate("/student/my_applications/apply/1", {
                          state: {
                            majorId: MajorDegree.id,
                            uniId: University.id,
                          },
                        });
                      }}
                      color="yellow"
                      width="70%"
                    />
                  </div>
                </div>
              ) : !showMissingDocuments ? (
                <div className="application_popUp">
                  <h1 className="application_popUp_header">
                    Hello {user?.first_name}
                  </h1>
                  <div className="application_popUp_body">
                    <p className="application_popUp_subtext">
                      How do you like to apply to the {University.name}?
                    </p>
                    <div className="application_popUp_footer">
                      <Button
                        text={
                          applyLoad ? (
                            <Loader onlyDots={true} />
                          ) : (
                            "Using my Unified Application"
                          )
                        }
                        onClick={() => {
                          setUnified(true);
                        }}
                        color="yellow"
                        width="100%"
                        disabled={applyLoad ? true : false}
                      />
                      <p
                        className="application_popUp_footer_text"
                        onClick={() => {
                          localStorage.removeItem("applicationId");

                          setShowPopUp(false);
                          navigate("/student/my_applications/apply/1", {
                            state: {
                              majorId: MajorDegree.id,
                              uniId: University.id,
                            },
                          });
                        }}
                      >
                        Start a new application
                      </p>
                    </div>
                    {error && <div className="error">{error}</div>}
                  </div>
                </div>
              ) : (
                <div className="application_popUp">
                  <h1 className="application_popUp_header">
                    Hello {user?.first_name}
                  </h1>
                  <div className="application_popUp_body">
                    <p className="application_popUp_subtext">
                      It looks like you are missing some documents in your
                      application.
                      <br /> Please upload them to continue.
                    </p>
                    <div
                      className="application_popUp_footer2"
                      style={{ display: "flex" }}
                    >
                      <Button
                        text={
                          applyLoad ? <Loader onlyDots={true} /> : "Upload Now"
                        }
                        onClick={() => {
                          navigate(
                            `/student/my_applications/${newUnifiedApplication.applicationId}`
                          );
                        }}
                        color="blue"
                        width="100%"
                      />
                      <Button
                        text="Cancel"
                        onClick={() => {
                          setShowPopUp(false);
                        }}
                        color="pink"
                        width="100%"
                      />
                    </div>
                    {error && <div className="error">{error}</div>}
                  </div>
                </div>
              )
            }
            width="50%"
            setShowPopup={setShowPopUp}
          />
        )}

        {unified && (
          <Popup
            body={
              <div className="application_popUp">
                <h1 className="application_popUp_header">
                  Hello {user?.first_name}
                </h1>
                <div className="application_popUp_body">
                  <p className="application_popUp_subtext">
                    Please choose the semester you want to apply for
                  </p>
                  {semester && (
                    <DropDown
                      width="50%"
                      placeholder="Choose Semester"
                      setSelected={setChosenSem}
                      data={semester}
                      blueArrow={true}
                      multiSelection={false}
                      searchable={true}
                      selectedData={chosenSem}
                      value={chosenSem}
                    />
                  )}
                  <div
                    style={{ display: "flex", marginTop: "2rem", width: "50%" }}
                  >
                    <Button
                      text="Apply"
                      disabled={!chosenSem ? true : false}
                      onClick={applyWithUnified}
                      color="pink"
                      width="100%"
                    />
                  </div>
                  {error && <div className="error">{error}</div>}
                </div>
              </div>
            }
            width="50%"
          />
        )}

        <div className="SingleUniInner">
          <div className="SingleUni_TitleDiv">
            <p className="SingleUniName">{University.name}</p>
            {pathname.includes("student") &&
              (favorite ? (
                <img
                  className={
                    addFavoriteLoading
                      ? "SingleUniHeartImg SingleUniHeartImgLoading"
                      : "SingleUniHeartImg"
                  }
                  src={redHeart}
                  alt="Star-icon"
                  onClick={() => {
                    setFavorite(false);
                    dispatch(addFavorite(id));
                  }}
                />
              ) : (
                <img
                  className={
                    addFavoriteLoading
                      ? "SingleUniHeartImg SingleUniHeartImgLoading"
                      : "SingleUniHeartImg"
                  }
                  src={heart}
                  alt="Star-icon"
                  onClick={() => {
                    setFavorite(true);
                    dispatch(addFavorite(id));
                  }}
                />
              ))}
          </div>
          <div className="SingleUni_location">
            <img className="LocationImage" src={Location} alt="location-logo" />
            {University.country}, {University.city}
          </div>
          <div className="SingleUni_DetailsContainer">
            <div className="SingleUni_Img">
              <img
                className="UniImg"
                src={
                  process.env.REACT_APP_API +
                  `/files/universities/${University.image}`
                }
                alt={University.name}
              />
            </div>
            <div className="SingleUni_DetailsDiv">
              <div className="SingleUni_Details">
                <p className="Details">Details</p>
                <p className="DetailsText">{University.description}</p>
              </div>
              {University.hasOwnProperty("pathway_provider") && (
                <div className="Provider">
                  <div className="title">Provider:</div>
                  <div className="name">
                    {University?.pathway_provider?.provider
                      ? University?.pathway_provider?.provider
                      : "None"}
                  </div>
                </div>
              )}
              <div>
                <p className="Details">Summary</p>
                <div className="singleSummaryOuter">
                  <div className="singleSummaryAll">
                    <div className="singleSummaryLeft">
                      <p className="singleSummaryLeftData">Founded</p>
                      <p className="singleSummaryLeftData">Language/s</p>
                      <p className="singleSummaryLeftData">Departments</p>
                    </div>
                    <div className="singleSummaryMiddle">
                      <p className="SingleSummaryMiddleData">
                        {University?.summary?.Founded}
                      </p>
                      <div className="SingleSummaryMiddleData">
                        {University?.languages?.join(", ")}
                      </div>
                      <p className="SingleSummaryMiddleData">
                        {University?.summary?.Departments}
                      </p>
                    </div>
                  </div>
                  <div className="singleSummaryRight">
                    <div>
                      <p className="singleUniSummaryRightData">
                        Starting Dates
                      </p>
                    </div>
                    <div className="bob">
                      <div className="SingleUniSummaryRightImages">
                        {University?.semesters?.map((semester, index) => {
                          return (
                            <div className="SingleUniSumm" key={index}>
                              <img
                                className="SingleUniCostOfLiving"
                                src={
                                  index % 4 === 1
                                    ? yellowCircle
                                    : index % 4 === 2
                                    ? blueCircle
                                    : index % 4 === 3
                                    ? greenCircle
                                    : pinkCircle
                                }
                              />

                              <p className="SingleUniSummaryRightOneSeason">
                                <span className="SingleUniSemester">
                                  {semester.name +
                                    " (" +
                                    new Date(
                                      semester.start_date
                                    ).toLocaleString("default", {
                                      month: "long",
                                    }) +
                                    ")"}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SingleUniSelection">
            <div className="SingleUniDropDiv">
              {facultyArray?.length > 0 && !getOneUniversityLoading && (
                <DropDown
                  title="Faculty"
                  placeholder="Choose a Faculty"
                  width="100%"
                  searchable={true}
                  isDisabled={false}
                  setSelected={setFacultySelected}
                  selectedData={facultySelected}
                  multiSelection={false}
                  data={facultyArray}
                />
              )}
            </div>
            <div className="SingleUniDropDiv">
              {facultySelected &&
                MajorArray.length > 0 &&
                !getMajorsOfFacyltiesByIdLoading && (
                  <DropDown
                    title="Major"
                    placeholder="Choose a Major"
                    width="100%"
                    searchable={true}
                    isDisabled={false}
                    setSelected={setMajorSelected}
                    selectedData={majorSelected}
                    multiSelection={false}
                    data={MajorArray.length > 0 ? MajorArray : []}
                  />
                )}

              {(!facultySelected ||
                MajorArray.length == 0 ||
                getMajorsOfFacyltiesByIdLoading) && (
                <DropDown
                  title="Major"
                  placeholder="Choose a Major"
                  width="100%"
                  searchable={true}
                  isDisabled={true}
                  setSelected={setMajorSelected}
                  selectedData={majorSelected}
                  multiSelection={false}
                  data={[]}
                />
              )}
            </div>
            <div className="SingleUniDropDiv">
              {majorSelected &&
                degreeArray.length > 0 &&
                !getDegreeByMajorIdLoading && (
                  <DropDown
                    title="Degree"
                    placeholder="Choose a Degree"
                    width="100%"
                    searchable={true}
                    isDisabled={false}
                    setSelected={setDegreeSelected}
                    selectedData={degreeSelected}
                    multiSelection={false}
                    data={degreeArray}
                  />
                )}
              {(majorSelected == "" ||
                degreeArray.length == 0 ||
                getDegreeByMajorIdLoading) && (
                <DropDown
                  title="Degree"
                  placeholder="Choose a Degree"
                  width="100%"
                  searchable={true}
                  isDisabled={true}
                  setSelected={setDegreeSelected}
                  selectedData={degreeSelected}
                  multiSelection={false}
                  data={degreeArray}
                />
              )}
            </div>
          </div>
        </div>
        {lowLoading ? (
          <Loader fullScreen={true} />
        ) : (
          degreeSelected !== "" && (
            <>
              <div
                className={
                  degreeSelected
                    ? "SingleUniLowerDiv"
                    : "SingleUniLowerDivBlured SingleUniLowerDiv"
                }
              >
                <div className="SingleUniLowerLeft">
                  <div className="SingleUniOuterDiv">
                    <div className="SingleUniInnerLowerLeft">
                      <div className="SingleUniMajorDesc">
                        <h3 className="SingleUniAbtMajor">About The Major</h3>
                        <p className="SingleUniAbtMajDesc">
                          {MajorDegree.about}
                        </p>
                      </div>
                      <div className="SingleUniReq">
                        <div
                          className="SingleReqLeft"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <div className="LowerLeftTitleDiv">
                            <h3 className="MajorDetailsTitle">Major Details</h3>
                          </div>
                          <div className="singleDuration singleDurationLeft">
                            <div className="singleDurationAndImg">
                              <img className="SingleUniClock" src={clock} />
                              <span className="SingleUniDurationSpan">
                                Duration
                              </span>
                            </div>
                            <span className="SingleUniDurationSpanRight">
                              {MajorDegree.duration} years
                            </span>
                          </div>
                          <div className="singleDuration singleDurationLeft">
                            <div className="singleDurationAndImg">
                              <img className="SingleUniTuition" src={tuition} />
                              <span className="SingleUniDurationSpan">
                                Tuition
                              </span>
                            </div>
                            <span className="SingleUniDurationSpanRight">
                              ${MajorDegree.tuition} /year
                            </span>
                          </div>
                          <div className="singleDuration singleDurationLeft">
                            <div className="singleDurationAndImg">
                              <img
                                className="SingleUniCostOfLiving"
                                src={CostOfLiving}
                              />
                              <span className="SingleUniDurationSpan">
                                Cost of living
                              </span>
                            </div>
                            <span className="SingleUniDurationSpanRight">
                              ${MajorDegree.living_cost} /year
                            </span>
                          </div>
                          <div className="singleDuration singleDurationLeft">
                            <div className="singleDurationAndImg">
                              <img
                                className="SingleUniCostOfLiving"
                                src={Intakes}
                              />
                              <span className="SingleUniDurationSpan">
                                Intakes
                              </span>
                            </div>
                            <span className="SingleUniDurationSpanRight">
                              {MajorDegree.intakes} /year
                            </span>
                          </div>
                        </div>
                        <hr className="singleUniHr" />
                        <div className="SingleReqMiddle">
                          <div className="LowerLeftTitleDiv">
                            <h3 className="MajorDetailsTitle">
                              Entry Requirements
                            </h3>
                          </div>
                          <div className="SingleDurationAll">
                            {MajorDegree?.entry_requirements?.map(
                              (req, index) => {
                                return (
                                  <div className="singleDuration" key={index}>
                                    <div className="singleDurationAndImg">
                                      <img
                                        className="SingleUniCostOfLiving"
                                        src={
                                          index % 4 === 1
                                            ? yellowCircle
                                            : index % 4 === 2
                                            ? blueCircle
                                            : index % 4 === 3
                                            ? greenCircle
                                            : pinkCircle
                                        }
                                      />
                                      <span className="SingleUniDurationSpan">
                                        {req}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <hr className="singleUniHr" />
                        <div className="singleUniReqRight">
                          <div className="LowerLeftTitleDiv">
                            <h3 className="MajorDetailsTitle">
                              Visa Requirements
                            </h3>
                          </div>
                          <div className="SingleDurationAll">
                            {MajorDegree?.visa_requirements?.map(
                              (visareq, index) => {
                                return (
                                  <div className="singleDuration" key={index}>
                                    <div className="singleDurationAndImg">
                                      <img
                                        className="SingleUniCostOfLiving"
                                        src={
                                          index % 4 === 1
                                            ? yellowCircle
                                            : index % 4 === 2
                                            ? blueCircle
                                            : index % 4 === 3
                                            ? greenCircle
                                            : pinkCircle
                                        }
                                      />
                                      <span className="SingleUniDurationSpan">
                                        {visareq}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    applied
                      ? "SingleUniLowerRightApplied"
                      : "SingleuniLowerRight"
                  }
                  style={{
                    backgroundColor: pathname.includes("student")
                      ? MajorDegree.canApply === false &&
                        MajorDegree.canEdit === false
                        ? "var(--pink)"
                        : "var(--yellow)"
                      : "var(--yellow)",
                  }}
                >
                  {!pathname.includes("sales") ? (
                    <div className="SingleUniLowerRightInner">
                      <div className="LowerRightDivTitleDiv">
                        <span className="LowerRightDivTitle">
                          {/*talk with the backend about the status of the application to check it and 
                  according to it set the button functionality*/}
                          {pathname.includes("student")
                            ? MajorDegree.canApply === false &&
                              MajorDegree.canEdit === false
                              ? `You Applied to this major`
                              : University.accepting_applications === false
                              ? `Not Accepting Applications`
                              : MajorDegree.canEdit === true
                              ? "Application Almost Done"
                              : universitiesSemesters?.length === 0
                              ? `Not Accepting Applications`
                              : `Apply to ${majorSelected.label}`
                            : University.accepting_applications === false ||
                              universitiesSemesters?.length === 0
                            ? `Not Accepting Applications`
                            : `Apply to ${majorSelected.label}`}
                        </span>
                      </div>
                      <div className="singleUnibtnDiv">
                        {pathname.includes("student") ? (
                          <Button
                            text={
                              MajorDegree.canApply === false &&
                              MajorDegree.canEdit === false
                                ? "View Application"
                                : University.accepting_applications === false
                                ? "Apply Now"
                                : MajorDegree.canEdit === true
                                ? "Continue Application"
                                : "Apply Now"
                            }
                            onClick={() => handleApply()}
                            color={
                              MajorDegree.canApply === false &&
                              MajorDegree.canEdit === false
                                ? "blue"
                                : University.accepting_applications === false
                                ? ""
                                : MajorDegree.canEdit === true
                                ? "blue"
                                : universitiesSemesters?.length === 0
                                ? ""
                                : "blue"
                            }
                            width="100%"
                            disabled={
                              MajorDegree.canApply === false &&
                              MajorDegree.canEdit === false
                                ? false
                                : University.accepting_applications === false
                                ? true
                                : MajorDegree.canEdit === true
                                ? false
                                : universitiesSemesters?.length === 0
                                ? true
                                : false
                            }
                          />
                        ) : (
                          <Button
                            text={"Apply Now"}
                            onClick={() => handleApply()}
                            color={
                              University.accepting_applications === false ||
                              universitiesSemesters?.length === 0
                                ? ""
                                : "blue"
                            }
                            width="100%"
                            disabled={
                              University.accepting_applications === false ||
                              universitiesSemesters?.length === 0
                                ? true
                                : false
                            }
                          />
                        )}
                        <p className="DeadlineText">
                          {universitiesSemesters[0]?.deadline
                            ? "Application Deadline: "
                            : "Applications Closed"}
                          {universitiesSemesters[0]?.deadline &&
                            dateConv(universitiesSemesters[0]?.deadline)}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p className="DeadlineText_under">
                      {universitiesSemesters[0]?.deadline
                        ? "Application Deadline: "
                        : "Applications Closed"}
                      {universitiesSemesters[0]?.deadline &&
                        dateConv(universitiesSemesters[0]?.deadline)}
                    </p>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
}

export default SingleUni;
