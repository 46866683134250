import React, { useEffect, useState } from "react";
import "./Documentation.scss";

function Documentation() {
  return (
    <div style={{ width: "90%", margin: "auto", fontSize: "1.2rem" }}>
      <h1 style={{ fontSize: "2rem" }}>Installation</h1>
      <ul>
        <li>
          Login to <a href="edubkt.tech/login">Education Basket</a>
        </li>
        <li>Go to Meetings section</li>
        <li>If there is a meeting that is requested from you</li>
        <li>Click confirm and authorize the Zoom OAuth App</li>
      </ul>
      <h1 style={{ fontSize: "2rem" }}>Usage</h1>
      <ul>
        <li>
          <b>Zoom meetings with students</b> - Education Basket's counselors
          constantly meet with their assigned students to help them take better
          academical decision and tech their future goals
        </li>
      </ul>
      <h1 style={{ fontSize: "2rem" }}>Uninstallation</h1>
      <ul>
        <li>Log in to Zoom Marketplace</li>
        <li>Click on Manage on the top right corner</li>
        <li>Select Education Basket from Installed Apps</li>
        <li>Click Uninstall</li>
        <li>Or contact your administrator</li>
      </ul>
      <h1 style={{ fontSize: "2rem" }}>Troubleshooting</h1>
      <ul>
        <li>
          For any question, troubleshoot, or technical support, our Support team
          is available with a SLA of two business days -{" "}
          <a href="mailto:educationbasket1@gmail.com">
            educationbasket1@gmail.com
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Documentation;
