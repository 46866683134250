import React, { useEffect, useState } from "react";
import "./OBStep4.scss";
import Circle from "../../../../Shared/Components/Animations/Circle/Circle";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";

import SingleSelect from "../../../../Shared/Components/SingleSelect/SingleSelect";
import Star from "../../../../Shared/Components/Animations/Star/Star";
import DropDown from "../../../../Shared/Components/DropDown/DropDown";
import OBStep5 from "../OBStep5/OBStep5";
import Penta from "../../../../Shared/Components/Animations/Penta/Penta";
import { useDispatch, useSelector } from "react-redux";
import { getAllCont } from "../../../../Shared/Redux/Location/LocationsActions";
function OBStep4({ step, setStep, student, setStudent, counselor }) {
  const dispatch = useDispatch();

  const { Countries, getCountriesLoading } = useSelector(
    (state) => state.LocationReducer
  );

  const [allCountries, setAllCountries] = useState("");
  const [allNat, setAllNat] = useState("");

  const [contLoading, setContLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllCont());
  }, []);

  useEffect(() => {
    if (Countries.length > 0) {
      let temp = [];
      let temp2 = [];
      Countries.map((item) => {
        temp.push({ value: item.name, label: item.name });
        temp2.push({ value: item.nationality, label: item.nationality });
      });
      setAllCountries(temp);
      setAllNat(temp2);
    }
  }, [Countries]);

  useEffect(() => {
    setContLoading(getCountriesLoading);
  }, [getCountriesLoading]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedNat, setSelectedNat] = useState("");

  const onSubmit = () => {
    setStudent({
      ...student,
      country: selectedCountry.value,
      nationality: selectedNat.value,
    });
    if (selectedCountry.length != 0 && selectedNat.length != 0) setStep(5);
  };

  return (
    <div className="obStep_main_div">
      {step == 4 ? (
        <FormLayout
          back={() => setStep(3)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={counselor ? "Country of Residency" : "Where do you live?"}
          minHeight="fit-content"
          width="90%"
          loading={contLoading}
          body={
            <div className="step_body">
              {allCountries && (
                <DropDown
                  value={selectedCountry}
                  placeholder="Enter country of residence"
                  setSelected={setSelectedCountry}
                  data={allCountries}
                  blueArrow={true}
                  width="100%"
                  multiSelection={false}
                  searchable={false}
                  selectedData={selectedCountry}
                />
              )}
              <div>
                <div className="mainCard_heading">
                  <h1 className="mainCard_header">
                    {counselor ? "Nationality" : "What is your nationality?"}
                  </h1>
                </div>
                {allNat && (
                  <DropDown
                    value={selectedNat}
                    placeholder="Enter first nationality"
                    setSelected={setSelectedNat}
                    data={allNat}
                    blueArrow={true}
                    width="100%"
                    multiSelection={false}
                    searchable={false}
                    selectedData={selectedNat}
                  />
                )}
              </div>
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={
                  (selectedCountry.length == 0 || selectedNat.length == 0) &&
                  true
                }
              />
            </div>
          }
          icon={<Penta />}
          bg="var(--lightGreen)"
          gap="20px"
        />
      ) : (
        <OBStep5
          step={step}
          setStep={setStep}
          student={student}
          setStudent={setStudent}
          Countries={allCountries}
          counselor={counselor}
        />
      )}
    </div>
  );
}

export default OBStep4;
