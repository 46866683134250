import React from "react";
import "./OverviewCardProgress.scss";
import completed from "../../Assets/Images/completed.png";

function OverviewCardProgress(props) {
  return (
    <div
      onClick={() => props.onClick()}
      className={
        props.disabled
          ? "OverviewCardProgress_container disabled_card"
          : "OverviewCardProgress_container"
      }
    >
      <div className="OverviewCardProgress_container_inner">
        <div className="OverviewCardProgress_left">
          <div className="OverviewCardProgress_left_number">{props.number}</div>
          <div className="OverviewCardProgress_left_title">{props.title}</div>
        </div>
        <div className="OverviewCardProgress_right">
          {!props.completed ? (
            <div className="completed_container">
              <img src={completed} alt="check" className="check_img" style={{
              filter: "grayscale(100%)", opacity: 0.7
              }} />
            </div>
          ) : (
            <div className="completed_container">
              <img src={completed} alt="check" className="check_img" />
              <div className="check_text" style={{ opacity: 0.6 }}>
                Completed
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OverviewCardProgress;
