import React, { useLayoutEffect, useRef, useState } from "react";
import "./CounselorSingleStudent.scss";
import defaultImg from "../../../Shared/Assets/Images/default.jpeg";

import edit from "../../../Shared/Assets/Images/edit.png";
import Input from "../../../Shared/Components/Input/Input";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import Button from "../../../Shared/Components/Button/Button";
// import Radio from "../../../Components/Radio/Radio";
import check from "../../../Shared/Assets/Images/save.png";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "../../../Shared/Components/Popup/Popup";

import { useParams } from "react-router-dom";
import { getMajotCAtg } from "../../../Shared/Redux/Universities/UniversityActions";

import { useSelector, useDispatch } from "react-redux";
import {
  addPassport,
  getAllPassports,
} from "../../../Student/Redux/Student/StudentActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import MoreDetails from "../../../Student/Components/MoreDetails/MoreDetails";

import {
  getstudentsbyid,
  updateUserbbyid,
  getcountry,
  patchstudent,
} from "../../Redux/CounselorStudent/CounselorStudentActions";

import { languagesList } from "../../../Utils";
import { axiosReq, IdSplit } from "../../../Utils";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import {
  getProfileAddress,
  getProfileEmergency,
  getProfileExp,
} from "../../../Student/Redux/Student/StudentActions";
function Profile({ counselor, sales }) {
  const ref = useRef(null);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getstudentsbyid(id));
    dispatch(getcountry());
    dispatch(getMajotCAtg());
  }, []);
  const scwidth = UseInnerWidth();

  const { MajorCatg } = useSelector((state) => state.UniversitiesReducer);
  const [majorsAll, setMajorsAll] = useState([]);

  useEffect(() => {
    if (MajorCatg) {
      const allM = [];
      MajorCatg.map((item) => {
        const maj = { value: item.name, label: item.name };
        allM.push(maj);
      });
      setMajorsAll(allM);
    }
  }, [MajorCatg]);

  const levels = [
    { label: "Grade 9", value: "Grade 9" },
    { label: "Grade 10", value: "Grade 10" },
    { label: "Grade 11", value: "Grade 11" },
    { label: "Grade 12", value: "Grade 12" },
    { label: "Grade 13", value: "Grade 13" },
    { label: "Foundation/pre-university", value: "Foundation/pre-university" },
    { label: "Bachelor's Year 1", value: "Bachelor's Year 1" },
    { label: "Freshman", value: "Freshman" },
    { label: "Bachelor's Year 2", value: "Bachelor's Year 2" },
    { label: "Sophomore", value: "Sophomore" },
    { label: "Bachelor's Year 3", value: "Bachelor's Year 3" },
    { label: "Junior", value: "Junior" },
    { label: "Bachelor's Year 4", value: "Bachelor's Year 4" },
    { label: "Senior", value: "Senior" },
    { label: "Bachelor's Year 5+", value: "Bachelor's Year 5+" },
    { label: "Pre-master's", value: "Pre-master's" },
    { label: "Master's Year 1", value: "Master's Year 1" },
    { label: "Master's Year 2", value: "Master's Year 2" },
    { label: "PhD", value: "PhD" },
  ];
  const budgets = [
    { label: "0 to 5.000$", value: [0, 5000] },
    { label: "5.000 to 10.000$", value: [5000, 10000] },
    { label: "10.000 to 15.000$", value: [10000, 15000] },
    { label: "15.000$ +", value: [15000] },
  ];

  const Semesters = [
    { value: "Fall", label: "Fall" },
    { value: "Spring", label: "Spring" },
    { value: "Summer", label: "Summer" },
  ];
  const [newDate, setNewDate] = useState();
  const [Natioanlity, setNatioanlity] = useState("");
  const [country, setCountry] = useState("");
  const [profile_phone, setProfile_phone] = useState();
  const [gender, setGender] = useState("");
  const [language, setlanguage] = useState("");
  const addressDb = useSelector((state) => state.StudentReducer?.address);
  const expDb = useSelector((state) => state.StudentReducer?.exp);
  const EmergencyDb = useSelector((state) => state.StudentReducer?.emergency);

  const [newUser, setNewUser] = useState({});

  const [profileCompleted, setProfileCompleted] = useState(false);
  const [newsLetterSub, setNewsLetterSub] = useState();
  const [emailSub, setEmailSub] = useState();
  const [changePass, setChangePass] = useState(false);
  const [img, setImg] = useState("");
  const [myProfileEdit, setmyProfileEdit] = useState(false);
  const [editOnBoarding, setEditOnBoarding] = useState("");

  const [newFName, setNewFName] = useState("");
  const [newLName, setNewLName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");

  const [passportDate, setPassportDate] = useState();
  const [passportNumber, setPassportNumber] = useState();
  const [passportCountry, setPassportCountry] = useState("");
  const [passportNationality, setPassportNationality] = useState("");
  const [passport, setPassport] = useState(null);
  const [mobilePopup, setMobilePopup] = useState(false);
  const [allSources, setAllSources] = useState("");
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(
      axiosReq("get", process.env.REACT_APP_API + "/referal/sources", {}, {})
    ).then((res) => {
      if (res.success) {
        const data = res.data;
        setLoading(false);
        setAllSources(
          data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      } else setLoading(false);
    });
  }, []);
  const [addPassSuccess, setAddPassSuccess] = useState(false);

  // const user = useSelector((state) => state.StudentReducer?.student);
  const { user, countryList, loading, error, editUsersucess } = useSelector(
    (state) => state.CounselorStudentReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileAddress(id));
    dispatch(getProfileEmergency(navigate, id));
    dispatch(getProfileExp(id));
  }, [id]);
  const [access, setAccess] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (id) {
      dispatch(getAllPassports(navigate, id));
    }
  }, [id]);
  const currentYear = new Date().getFullYear();
  const futureYear = currentYear + 15;

  const yearArray = [];
  for (let year = currentYear; year <= futureYear; year++) {
    yearArray.push({ value: year, label: String(year) });
  }
  useEffect(() => {
    setload(true);
    if (user) {
      setNewFName(user?.user?.first_name);
      setNewLName(user?.user?.last_name);
      setNewEmail(user?.user?.email);
      setNatioanlity({
        value: user?.user?.nationality,
        label: user?.user?.nationality,
      });
      setCountry({
        value: user?.user?.country_of_residence,
        label: user?.user?.country_of_residence,
      });
      setProfile_phone(user?.user?.phone);
      setGender({
        label: user?.user?.gender,
        value: user?.user?.gender,
      });

      setNewDate(user?.user?.date_of_birth);
      setEmailSub(user?.user?.subscribed_to_emails);
      setNewsLetterSub(user?.user?.subscribed_to_newsletter);
      setlanguage({
        value: user?.user?.preferred_language,
        label: user?.user?.preferred_language,
      });
    }
    setload(false);
  }, [user]);
  const myinfo = useSelector((state) => state.userReducer.user);

  const navigate = useNavigate();

  const [newLevelofEducation, setnewLevelofEducation] = useState("");
  const [newInterestedMajor, setnewInterestedMajor] = useState("");
  const [newCountryToStudyin, setnewCountryToStudyin] = useState("");
  const [newBudget, setnewBudget] = useState("");
  const [newStartDate, setnewStartDate] = useState("");
  const [newSemester, setnewSemester] = useState("");
  const [newRefferalSource, setnewRefferalSource] = useState("");
  const [customRefferalSource, setCustomRefferalSource] = useState("");
  const [load, setload] = useState(true);
  useEffect(() => {
    if (user) {
      setnewLevelofEducation({
        label: user?.education_level,
        value: user?.education_level,
      });
      setnewInterestedMajor(
        user?.what_to_study?.map((item) => {
          return { value: item, label: item };
        })
      );
      setnewCountryToStudyin(
        user?.location_of_study?.map((item) => {
          return { value: item, label: item };
        })
      );
      setnewBudget({
        value: [parseInt(user?.budget?.[0]), parseInt(user?.budget?.[1])],
        label: user?.budget?.[0] + "-" + user?.budget?.[1],
      });
      setnewStartDate({
        label: user?.preferred_year,
        value: user?.preferred_year,
      });
      setnewSemester({
        label: user?.preferred_semester,
        value: user?.preferred_semester,
      });

      setnewRefferalSource({
        label: user?.referal_source?.name,
        value: user?.referal_source?.id,
      });
      setCustomRefferalSource(user?.customRefferalSource);
      setload(false);
    }
  }, [user]);
  const genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  const widdd = UseInnerWidth();

  const [InputWidth, setInputWidth] = useState("25rem");

  useEffect(() => {
    if (width > 950) {
      setInputWidth("25rem");
    } else if (width <= 950 && width > 800) {
      setInputWidth("20rem");
    } else if (width <= 800 && width > 690) {
      setInputWidth("17rem");
    } else {
      setInputWidth("100%");
    }
  }, [useWindowSize]);

  useEffect(() => {
    setNewUser({
      ...(newFName &&
        newFName != user?.user?.first_name && { first_name: newFName }),
      ...(newLName &&
        newLName != user?.user?.last_name && { last_name: newLName }),
      ...(newEmail && newEmail != user?.user?.email && { email: newEmail }),
      ...(newDate &&
        newDate != user?.user?.date_of_birth && { date_of_birth: newDate }),
      ...(Natioanlity &&
        Natioanlity.value != user?.user?.nationality && {
          nationality: Natioanlity.value,
        }),
      ...(country &&
        country.value != user?.user?.country_of_residence && {
          country_of_residence: country.value,
        }),
      ...(gender &&
        gender.value != user?.user?.gender && { gender: gender.value }),
      ...(language &&
        language.value != user?.user?.preferred_language && {
          preferred_language: language.value,
        }),
      ...(profile_phone &&
        profile_phone != user.user.phone && { phone: profile_phone }),
    });
  }, [
    newFName,
    newLName,
    newEmail,
    newDate,
    Natioanlity,
    profile_phone,
    country,
    gender,
    language,
  ]);

  const [newPass, setNewPass] = useState({});
  useEffect(() => {
    setNewPass({
      ...(passportNumber && { number: passportNumber }),
      ...(passportCountry && { country: passportCountry.value }),
      ...(passportDate && { expiry: passportDate }),
      ...(passportNationality && { nationality: passportNationality.value }),
      ...(passport && { passport: passport }),
      ...(id && { student: id }),
    });
  }, [
    passportNumber,
    passportDate,
    passportCountry,
    passportNationality,
    passport,
    id,
  ]);
  const editUser = () => {
    if (user.user.id) {
      dispatch(updateUserbbyid(newUser, user.user.id, id));
    } else {
      setmyProfileEdit(false);
    }
  };
  const editstudent = () => {
    const data = {
      education_level: newLevelofEducation.value,
      what_to_study: newInterestedMajor.map((item) => item.value),
      preferred_semester: newSemester.value,
      preferred_year: newStartDate.value,
      budget: newBudget.value,
      referal_source: newRefferalSource.value,
      location_of_study: newCountryToStudyin.map((item) => item.value),
      customRefferalSource: customRefferalSource,
    };
    setEditOnBoarding(false);
    dispatch(patchstudent(user.id, data));
  };

  useEffect(() => {
    setAccess(!loading);
  }, [loading]);
  const [popup, setpopup] = useState(false);
  const [pop2, setpop2] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (editOnBoarding || myProfileEdit) {
        // Show the popup or take any other necessary action

        setpop2(true);

        // Set the custom message for the confirmation dialog
        event.returnValue = "Are you sure you want to leave this page?";
      }
    };

    // Add the event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editOnBoarding, myProfileEdit]);

  useEffect(() => {
    if ((editOnBoarding || myProfileEdit) && !pop2) {
      setpop2(true);
    }
  }, [editOnBoarding, myProfileEdit]);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.add("pen_shake");
        }
      }, 1850);
    } else {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.remove("pen_shake");
        }
      }, 1850);
    }
  }, [popup]);
  useEffect(() => {
    if (addressDb.length > 0 && EmergencyDb.length > 0 && !load) {
      setProfileCompleted(true);
    }
  }, [addressDb, EmergencyDb, id]);

  useEffect(() => {
    if (editUsersucess) {
      setmyProfileEdit(false);
    }
  }, [editUsersucess]);
  return (
    <>
      {popup && (
        <UpperPopUp
          setShow={setpopup}
          title="Please Click on The Pen to Edit Info"
        />
      )}
      {/* {pop2 && (
        <UpperPopUp
          setShow={setpop2}
          title="Please Save Your Changes Before Leaving The Page"
        />
      )} */}

      {(!access || load) && <Loader fullScreen={true} />}
      <div className="counselor_Profile_container">
        <div className="counsler_studentprofile_top">
          <div className="user_img">
            <img
              src={
                user?.user?.profile_picture === null
                  ? defaultImg
                  : process.env.REACT_APP_API +
                    `/files/students/${id}/` +
                    user?.user?.profile_picture
              }
              alt="profile"
              className="user_photo"
            />
          </div>
        </div>
        <div className="title">
          <div className="User_ID">{IdSplit(user?.id)}</div>
          {user?.user?.first_name + " " + user?.user?.last_name}
          {myinfo.role === "sales" ? (
            <></>
          ) : (
            <div className="CSSChatBtn">
              <Button
                text="Chat"
                color="yellow"
                onClick={() =>
                  navigate("/counselor/chat", {
                    state: {
                      chatId: user?.user?.id,
                    },
                  })
                }
              />
            </div>
          )}
        </div>
        <div></div>
        {myProfileEdit && (
          <Popup
            setShowPopup={() => {}}
            showClose={false}
            body={
              <div>
                <div
                  style={{
                    fontFamily: "P_Bold",
                    fontSize: "2.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Edit Personal Details
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">First Name</div>
                    <Input
                      value={newFName}
                      onChange={(e) => setNewFName(e.target.value)}
                      placeholder="First Name"
                      type="text"
                      width={"100%"}
                      disabled={!myProfileEdit}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">Last Name</div>
                    <Input
                      value={newLName}
                      onChange={(e) => setNewLName(e.target.value)}
                      placeholder="Last Name"
                      type="text"
                      width={"100%"}
                      disabled={!myProfileEdit}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">Email</div>
                    <Input
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      placeholder="Email"
                      type="email"
                      width={"100%"}
                      disabled={!myProfileEdit}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">
                      Date of Birth{" "}
                      {!newDate && <span className="missing">Missing!</span>}
                    </div>
                    <DateInput
                      width={"100%"}
                      setSelectedDate={setNewDate}
                      selectedDate={newDate}
                      disabled={!myProfileEdit}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">Nationality</div>
                    <DropDown
                      placeholder="Nationality"
                      setSelected={setNatioanlity}
                      data={countryList.map((item) => {
                        return {
                          label: item.nationality,
                          value: item.nationality,
                        };
                      })}
                      blueArrow={false}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={Natioanlity}
                      keepPlaceHolder={false}
                      isDisabled={!myProfileEdit}
                      value={Natioanlity}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">Phone Number</div>
                    {myProfileEdit ? (
                      <span
                        onChange={(e) => setProfile_phone(e.target.value)}
                        style={{ cursor: "pointer" }}
                      >
                        <MobileInput
                          width={"100%"}
                          search={true}
                          required={true}
                          disabled={!myProfileEdit}
                          setPhone={setProfile_phone}
                          phone={profile_phone}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <MobileInput
                          width={"100%"}
                          search={true}
                          required={true}
                          disabled={true}
                          setPhone={setProfile_phone}
                          phone={profile_phone}
                        />
                      </span>
                    )}
                  </div>

                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">
                      Preferred Language{" "}
                      {!newDate && <span className="missing">Missing!</span>}
                    </div>
                    <DropDown
                      placeholder="Language"
                      setSelected={setlanguage}
                      data={languagesList}
                      blueArrow={false}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={language}
                      keepPlaceHolder={false}
                      isDisabled={!myProfileEdit}
                      value={language}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">Country</div>
                    <DropDown
                      placeholder="Country"
                      setSelected={setCountry}
                      data={countryList.map((item) => {
                        return {
                          label: item.name,
                          value: item.name,
                        };
                      })}
                      blueArrow={false}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={country}
                      keepPlaceHolder={false}
                      isDisabled={!myProfileEdit}
                      value={country}
                    />
                  </div>
                  <div className="profile_input_top" style={{ flex: "0" }}>
                    <div className="input_title">
                      Gender{" "}
                      {!newDate && <span className="missing">Missing!</span>}
                    </div>
                    <DropDown
                      placeholder="Gender"
                      setSelected={setGender}
                      data={genders}
                      blueArrow={false}
                      width={width > 774 && width < 1170 ? "47.5%" : "100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={gender}
                      keepPlaceHolder={false}
                      isDisabled={!myProfileEdit}
                      value={gender}
                    />
                  </div>
                </div>
                {error && <div className="error">{error}</div>}
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    gap: "1rem 2rem",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    color={"yellow"}
                    width={width < 768 ? "100%" : "45%"}
                    text="Submit"
                    onClick={() => editUser()}
                    disabled={loading}
                  />
                  <Button
                    color={"pink"}
                    width={width < 768 ? "100%" : "45%"}
                    text="Cancel"
                    onClick={() => setmyProfileEdit(false)}
                    disabled={loading}
                  />
                </div>
              </div>
            }
          />
        )}
        <div className="profile_body">
          <div className="my_profile">
            <div className="profile_title">
              Personal Details
              {!myProfileEdit ? (
                <img
                  src={edit}
                  alt="edit pen"
                  className="edit_pen"
                  onClick={() => setmyProfileEdit(true)}
                />
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    //send data to server
                    setmyProfileEdit(false);
                  }}
                >
                  <img
                    src={check}
                    alt="edit pen"
                    className="edit_save"
                    onClick={() => setmyProfileEdit(true)}
                  />
                </span>
              )}
            </div>
            <div
              className="my_profile_inputs"
              onClick={!myProfileEdit ? () => setpopup(true) : null}
            >
              <div className="profile_input_top">
                <div className="input_title">First Name</div>
                <Input
                  value={newFName}
                  onChange={(e) => setNewFName(e.target.value)}
                  placeholder="First Name"
                  type="text"
                  width={"100%"}
                  disabled={!myProfileEdit}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">Last Name</div>
                <Input
                  value={newLName}
                  onChange={(e) => setNewLName(e.target.value)}
                  placeholder="Last Name"
                  type="text"
                  width={"100%"}
                  disabled={!myProfileEdit}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">Email</div>
                <Input
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Email"
                  type="email"
                  width={"100%"}
                  disabled={!myProfileEdit}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">
                  Date of Birth{" "}
                  {!newDate && <span className="missing">Missing!</span>}
                </div>
                <DateInput
                  width={"100%"}
                  setSelectedDate={setNewDate}
                  selectedDate={newDate}
                  disabled={!myProfileEdit}
                  maxDate={new Date()}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">Nationality</div>
                <DropDown
                  placeholder="Nationality"
                  setSelected={setNatioanlity}
                  data={countryList.map((item) => {
                    return {
                      label: item.nationality,
                      value: item.nationality,
                    };
                  })}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={Natioanlity}
                  keepPlaceHolder={false}
                  isDisabled={!myProfileEdit}
                  value={Natioanlity}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">Phone Number</div>
                {myProfileEdit ? (
                  <span
                    onChange={(e) => setProfile_phone(e.target.value)}
                    style={{ cursor: "pointer" }}
                  >
                    <MobileInput
                      width={"100%"}
                      search={true}
                      required={true}
                      disabled={!myProfileEdit}
                      setPhone={setProfile_phone}
                      phone={profile_phone}
                    />
                  </span>
                ) : (
                  <span style={{ cursor: "pointer" }}>
                    <MobileInput
                      width={"100%"}
                      search={true}
                      required={true}
                      disabled={true}
                      setPhone={setProfile_phone}
                      phone={profile_phone}
                    />
                  </span>
                )}
              </div>

              <div className="profile_input_top">
                <div className="input_title">
                  Preferred Language{" "}
                  {!newDate && <span className="missing">Missing!</span>}
                </div>
                <DropDown
                  placeholder="Language"
                  setSelected={setlanguage}
                  data={languagesList}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={language}
                  keepPlaceHolder={false}
                  isDisabled={!myProfileEdit}
                  value={language}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">Country</div>
                <DropDown
                  placeholder="Country"
                  setSelected={setCountry}
                  data={countryList.map((item) => {
                    return {
                      label: item.name,
                      value: item.name,
                    };
                  })}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={country}
                  keepPlaceHolder={false}
                  isDisabled={!myProfileEdit}
                  value={country}
                />
              </div>
              <div className="profile_input_top">
                <div className="input_title">
                  Gender {!newDate && <span className="missing">Missing!</span>}
                </div>
                <DropDown
                  placeholder="Gender"
                  setSelected={setGender}
                  data={genders}
                  blueArrow={false}
                  width={width > 774 && width < 1170 ? "47.5%" : "100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={gender}
                  keepPlaceHolder={false}
                  isDisabled={!myProfileEdit}
                  value={gender}
                />
              </div>
            </div>
          </div>
        </div>
        {editOnBoarding && (
          <Popup
            setShowPopup={() => {}}
            showClose={false}
            body={
              <div>
                <div
                  style={{
                    fontSize: "2.5rem",
                    fontFamily: "P_Bold",
                    marginBottom: "2rem",
                  }}
                >
                  Edit Onboarding Details
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {newLevelofEducation.length != 0 && (
                    <DropDown
                      title="Level of Education"
                      placeholder="Language"
                      setSelected={setnewLevelofEducation}
                      data={levels}
                      blueArrow={true}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={newLevelofEducation}
                      keepPlaceHolder={false}
                      isDisabled={!editOnBoarding}
                      value={newLevelofEducation}
                    />
                  )}
                  {majorsAll.length != 0 && (
                    <DropDown
                      title="Interested in Major"
                      placeholder="Major"
                      setSelected={setnewInterestedMajor}
                      data={majorsAll}
                      blueArrow={true}
                      width={"100%"}
                      multiSelection={false}
                      isMulti={true}
                      searchable={false}
                      selectedData={newInterestedMajor}
                      keepPlaceHolder={false}
                      isDisabled={!editOnBoarding}
                      value={newInterestedMajor}
                    />
                  )}
                  <DropDown
                    title="Country to Study in"
                    placeholder="Country"
                    setSelected={setnewCountryToStudyin}
                    data={countryList.map((item) => {
                      return {
                        label: item.name,
                        value: item.name,
                      };
                    })}
                    blueArrow={true}
                    width={"100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newCountryToStudyin}
                    isMulti={true}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newCountryToStudyin}
                  />
                  <DropDown
                    title="Budget per Year"
                    placeholder="Budget"
                    setSelected={setnewBudget}
                    data={budgets}
                    blueArrow={true}
                    width={"100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newBudget}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newBudget}
                  />
                  <DropDown
                    title="Starting Date"
                    placeholder="Country"
                    setSelected={setnewStartDate}
                    data={yearArray}
                    blueArrow={true}
                    width={"100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newStartDate}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newStartDate}
                  />
                  <DropDown
                    title="Preferred Semester"
                    placeholder="Country"
                    setSelected={setnewSemester}
                    data={Semesters}
                    blueArrow={true}
                    width={"100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newSemester}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newSemester}
                  />
                  {allSources.length != 0 && (
                    <DropDown
                      title="Referral Source"
                      placeholder="Country"
                      setSelected={setnewRefferalSource}
                      data={allSources}
                      blueArrow={true}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={newRefferalSource}
                      keepPlaceHolder={false}
                      isDisabled={!editOnBoarding}
                      value={newRefferalSource}
                    />
                  )}
                  {newRefferalSource.label == "Others" && (
                    <div>
                      <div className="input_title">Custom Refferal Source</div>
                      <Input
                        value={customRefferalSource}
                        type="text"
                        width={"100%"}
                        disabled={!editOnBoarding}
                        onChange={(e) => {
                          setCustomRefferalSource(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    gap: "1rem 2rem",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    color={"yellow"}
                    text={"Submit"}
                    width={width < 768 ? "100%" : "45%"}
                    onClick={() => editstudent()}
                  />
                  <Button
                    color={"pink"}
                    text={"Cancel"}
                    width={width < 768 ? "100%" : "45%"}
                    onClick={() => setEditOnBoarding(false)}
                  />
                </div>
              </div>
            }
          />
        )}
        <div className="Privacy_and_settings">
          <div className="Privacy_and_settings_inner">
            <div className="Privacy_and_settings_title ">
              Onboarding Details
              {!editOnBoarding ? (
                <img
                  src={edit}
                  alt="edit pen"
                  className="edit_pen"
                  onClick={() => setEditOnBoarding(true)}
                />
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    //send data to server
                    setmyProfileEdit(false);
                  }}
                >
                  <img
                    src={check}
                    alt="edit pen"
                    className="edit_save"
                    onClick={() => setEditOnBoarding(false)}
                  />
                </span>
              )}
            </div>
            <div className="Privacy_and_settings_body">
              <>
                <div
                  className="my_profile_OnboardingInputs"
                  onClick={!editOnBoarding ? () => setpopup(true) : null}
                >
                  {newLevelofEducation.length != 0 && (
                    <DropDown
                      title="Level of Education"
                      placeholder="Language"
                      setSelected={setnewLevelofEducation}
                      data={levels}
                      blueArrow={true}
                      width={scwidth > 1030 ? "30%" : "100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={newLevelofEducation}
                      keepPlaceHolder={false}
                      isDisabled={!editOnBoarding}
                      value={newLevelofEducation}
                    />
                  )}
                  {majorsAll.length != 0 && (
                    <DropDown
                      title="Interested in Major"
                      placeholder="Major"
                      setSelected={setnewInterestedMajor}
                      data={majorsAll}
                      blueArrow={true}
                      width={scwidth > 1030 ? "30%" : "100%"}
                      multiSelection={false}
                      isMulti={true}
                      searchable={false}
                      selectedData={newInterestedMajor}
                      keepPlaceHolder={false}
                      isDisabled={!editOnBoarding}
                      value={newInterestedMajor}
                    />
                  )}
                  <DropDown
                    title="Country to Study in"
                    placeholder="Country"
                    setSelected={setnewCountryToStudyin}
                    data={countryList.map((item) => {
                      return {
                        label: item.name,
                        value: item.name,
                      };
                    })}
                    blueArrow={true}
                    width={scwidth > 1030 ? "30%" : "100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newCountryToStudyin}
                    isMulti={true}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newCountryToStudyin}
                  />
                  <DropDown
                    title="Budget per Year"
                    placeholder="Budget"
                    setSelected={setnewBudget}
                    data={budgets}
                    blueArrow={true}
                    width={scwidth > 1030 ? "30%" : "100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newBudget}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newBudget}
                  />
                  <DropDown
                    title="Starting Date"
                    placeholder="Country"
                    setSelected={setnewStartDate}
                    data={yearArray}
                    blueArrow={true}
                    width={scwidth > 1030 ? "30%" : "100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newStartDate}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newStartDate}
                  />
                  <DropDown
                    title="Preferred Semester"
                    placeholder="Country"
                    setSelected={setnewSemester}
                    data={Semesters}
                    blueArrow={true}
                    width={scwidth > 1030 ? "30%" : "100%"}
                    multiSelection={false}
                    searchable={false}
                    selectedData={newSemester}
                    keepPlaceHolder={false}
                    isDisabled={!editOnBoarding}
                    value={newSemester}
                  />
                  {allSources.length != 0 && (
                    <DropDown
                      title="Referral Source"
                      placeholder="Country"
                      setSelected={setnewRefferalSource}
                      data={allSources}
                      blueArrow={true}
                      width={scwidth > 1030 ? "30%" : "100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={newRefferalSource}
                      keepPlaceHolder={false}
                      isDisabled={!editOnBoarding}
                      value={newRefferalSource}
                    />
                  )}
                  {newRefferalSource.label == "Others" && (
                    <div className=" aa">
                      <div className="input_title">Custom Refferal Source</div>
                      <Input
                        value={customRefferalSource}
                        type="text"
                        width={scwidth > 1030 ? "100%" : "100%"}
                        disabled={!editOnBoarding}
                        onChange={(e) => {
                          setCustomRefferalSource(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>

        {!profileCompleted ? (
          <div className="compelet_profile">
            <div className="compelet_profile_inner">
              <span className="complete_profile_text">More Details</span>
              <Button
                text="Complete Student Profile"
                color={"lightGreen"}
                onClick={() =>
                  sales
                    ? navigate("/sales/students/completeProfile" + "/" + id)
                    : navigate("/counselor/students/completeProfile" + "/" + id)
                }
              />
            </div>
          </div>
        ) : (
          <MoreDetails width={widdd} />
          // <></>
        )}

        {mobilePopup && (
          <Popup
            setShowPopup={setMobilePopup}
            body={
              <div className="mobile_popup">
                <div className="mobile_popup_title">
                  Please enter your new phone number
                </div>
                <MobileInput
                  width={"100%"}
                  search={false}
                  required={true}
                  disabled={false}
                  setPhone={setNewPhone}
                  phone={newPhone}
                />
                <div className="mobile_popup_buttons">
                  <Button
                    text="Verify"
                    color={"pink"}
                    width={"100%"}
                    onClick={() => {
                      setMobilePopup(false);
                      editUser();
                    }}
                  />
                  <Button
                    text="Cancel"
                    color={"yellow"}
                    width={"100%"}
                    onClick={() => {
                      setMobilePopup(false);
                    }}
                  />
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  );
}

export default Profile;
