import { axiosReq } from "../../../Utils";
import { CrmActions } from "./CrmReducers";

export const getcrmstatistics = (year) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/sales/applications/statistics";
  dispatch(CrmActions.GetstatisticsRequest());
  dispatch(axiosReq("get", url, {}, {}, { year: year })).then((res) => {
    if (res.success) {
      dispatch(CrmActions.GetstatisticsSuccess(res.data));
    } else {
      dispatch(CrmActions.GetstatisticsFail(res.data.response.data.message));
    }
  });
};
export const getSalesSources = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal";

  dispatch(CrmActions.GetsourcesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(CrmActions.GetsourcesSuccess(res.data));
    } else {
      dispatch(CrmActions.GetsourcesFail(res.data.response.data.message));
    }
  });
};
