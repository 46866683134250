import { axiosReq } from "../../../Utils";
import { getStudentMe } from "../Student/StudentActions";
import { getUser } from "../User/UserActions";
import { OverviewActions } from "./OverviewReducers";

export const getOverview = () => async (dispatch) => {
  dispatch(OverviewActions.fetchOverviewRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/student/me/overview", {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(OverviewActions.fetchOverviewSuccess(res.data));
    } else {
      dispatch(
        OverviewActions.fetchOverviewFail(res.data.response.data.message)
      );
    }
  });
};

export const getHelpReq = () => async (dispatch) => {
  dispatch(OverviewActions.helpReqRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/student/help", {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(OverviewActions.helpReqSuccess(res.data));
    } else {
      dispatch(OverviewActions.helpReqFail(res.data.response.data.message));
    }
  });
};

export const checkProgress = () => async (dispatch) => {
  dispatch(OverviewActions.checkProgressRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/student/checkProgress",
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(OverviewActions.checkProgressSuccess());
      dispatch(getStudentMe());
      dispatch(getUser())
    } else {
      dispatch(
        OverviewActions.checkProgressFail(res.data.response.data.message)
      );
    }
  });
};
