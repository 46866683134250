import { axiosReq } from "../../../Utils";
import { CounselorStudentActions } from "./CounselorStudentReducers";

export const getCounselorStudents =
  (limit, offset, residence, status, major, language, sortBy, search) =>
  async (dispatch) => {
    const url = process.env.REACT_APP_API + "/counselor/student/filter";

    dispatch(CounselorStudentActions.CounselorStudentRequest());
    dispatch(
      axiosReq(
        "get",
        url,
        {},
        {},
        {
          search: search,
          sortBy: sortBy,
          limit: limit,
          offset: offset,
          residence: residence,
          status: status,
          major: major,
          language: language,
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(CounselorStudentActions.CounselorStudentSuccess(res.data));
      } else {
        dispatch(
          CounselorStudentActions.CounselorStudentFail(
            res.data.response.data.message
          )
        );
      }
    });
  };
export const getstudentsbyid = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/student/${id}`;
  dispatch(CounselorStudentActions.CounselorStudentDataRequest());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.CounselorStudentDataSuccess(res.data));
    } else {
      dispatch(
        CounselorStudentActions.CounselorStudentDataFail(
          res.data.response.data.message
        )
      );
    }
  });
};
export const getuserbyid = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/user/${id}`;
  dispatch(CounselorStudentActions.getuserbyidReq());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.getuserbyidSuccess(res.data));
    } else {
      dispatch(
        CounselorStudentActions.getuserbyidFail(res.data.response.data.message)
      );
    }
  });
};

export const updateUserbbyid = (data, id, stid) => async (dispatch) => {
  dispatch(CounselorStudentActions.updateuserReq());
  dispatch(
    axiosReq("patch", process.env.REACT_APP_API + `/user/${id}`, {}, data)
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.updateuserSuccess(res.data));
      dispatch(getstudentsbyid(stid));
      // dispatch(getuserbyid(id));
    } else {
      dispatch(
        CounselorStudentActions.updateuserFail(
          res.data.response.data.error.message
        )
      );
    }
  });
};

export const getcountry = () => async (dispatch) => {
  dispatch(CounselorStudentActions.getcountryReq);
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/location/countries", {})
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.getcountrySuccess(res.data));
    } else {
      dispatch(
        CounselorStudentActions.getcountryFail(res.data.response.data.message)
      );
    }
  });
};

export const patchstudent = (id, data) => async (dispatch) => {
  dispatch(CounselorStudentActions.pacthstudentReq);
  dispatch(
    axiosReq("patch", process.env.REACT_APP_API + `/student/${id}`, {}, data)
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.pacthstudentSuccess(res.data));
      dispatch(getstudentsbyid(id));
    } else {
      dispatch(
        CounselorStudentActions.pacthstudentFail(res.data.response.data.message)
      );
    }
  });
};

export const createStudent = (student) => async (dispatch) => {
  dispatch(CounselorStudentActions.createStudentRequest());

  const url = process.env.REACT_APP_API + "/auth/student/create";

  dispatch(axiosReq("post", url, {}, student)).then((res) => {
    if (res.success)
      dispatch(CounselorStudentActions.createStudentSuccess(res.data));
    else
      dispatch(
        CounselorStudentActions.createStudentFail(
          res.data.response.data.message
        )
      );
  });
};

export const sendCredential = (student) => async (dispatch) => {
  dispatch(CounselorStudentActions.sendCredentialsRequest());

  const url = process.env.REACT_APP_API + "/auth/sendCredentials";

  dispatch(axiosReq("post", url, {}, student)).then((res) => {
    if (res.success)
      dispatch(CounselorStudentActions.sendCredentialsSuccess(res.data));
    else
      dispatch(
        CounselorStudentActions.sendCredentialsFail(
          res.data.response.data.message
        )
      );
  });
};

export const getaddressbyid = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/student/${id}/address`;
  dispatch(CounselorStudentActions.getaddressbyidReq());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.getaddressbyidSuccess(res.data));
    } else {
      dispatch(
        CounselorStudentActions.getaddressbyidFail(
          res.data.response.data.message
        )
      );
    }
  });
};

export const getexp = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/student/${id}/experience`;
  dispatch(CounselorStudentActions.getexpReq());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.getexpSuccess(res.data));
    } else {
      dispatch(
        CounselorStudentActions.getexpFail(res.data.response.data.message)
      );
    }
  });
};

export const getemergencycontact = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/student/${id}/emergencyContact`;
  dispatch(CounselorStudentActions.getemercycontactReq());
  dispatch(axiosReq("get", url, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorStudentActions.getemercycontactSuccess(res.data));
    } else {
      dispatch(
        CounselorStudentActions.getemercycontactFail(
          res.data.response.data.message
        )
      );
    }
  });
};
