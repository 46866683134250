import React from "react";
import "./Diamond.scss";
import diamondShape from "../../../Assets/Images/diamond.png";

import glitter from "../../../Assets/Images/Glitter.gif";

function Diamond() {
  return (
    <div className="diamond_main_div">
      <div className="gillter_diamond">
        <img className="glitter_img" src={glitter} />
        <div className="wrap">
          <div className="lightSweep">
            <img className="diamond_img" src={diamondShape} />
          </div>
          <img className="diamond_img shimmer" src={diamondShape} />
        </div>
      </div>
    </div>
  );
}

export default Diamond;
