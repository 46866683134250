import React, { useEffect, useRef, useState } from "react";
import "./MyCounslerPanel.scss";
import { useSelector, useDispatch } from "react-redux";
import dot from "../../../Shared/Assets/Images/blueDot.png";
import Button from "../../../Shared/Components/Button/Button";
import yellowDot from "../../../Shared/Assets/Images/yellowDot.png";
import close from "../../../Shared/Assets/Images/close.webp";
import attachment from "../../../Shared/Assets/Images/attachment.png";
import send from "../../../Shared/Assets/Images/send.png";
import hasItemsInIndexedDB, {
  formatCreatedAtDate,
  handlePreview,
} from "../../../Utils";
import {
  changeTaskStatus,
  getChats,
  getStudentTasks,
  sendMessage,
} from "../../Redux/MyCounselor/MyCounselorActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import { MyCounselorActions } from "../../Redux/MyCounselor/MyCounselorReducer";
import documentt from "../../../Shared/Assets/Images/document.png";

function MyCounslerPanel({ subject, setSelected, messages, setMessages }) {
  const divRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the div when it is loaded
    if (subject == "chat")
      divRef.current.scrollTop = divRef.current.scrollHeight;
  }, []);

  // const messages = [
  //   {
  //     content: "Hello",
  //     reciver: { id: "1123" },
  //     sender: { id: "3333" },
  //   },
  //   {
  //     content: "How are you?",
  //     reciver: { id: "1123" },
  //     sender: { id: "3333" },
  //   },
  //   {
  //     content: "Hey, I am good. How are you?",
  //     reciver: { id: "1123" },
  //     sender: { id: "3333" },
  //   },
  //   {
  //     content: "Good, thanks for asking",
  //     reciver: { id: "3333" },
  //     sender: { id: "1123" },
  //   },
  //   {
  //     content: "How can i help you?",
  //     reciver: { id: "1123" },
  //     sender: { id: "3333" },
  //   },
  //   {
  //     content:
  //       "I want to know about the admission process of Harvard University",
  //     reciver: { id: "3333" },
  //     sender: { id: "1123" },
  //   },
  // ];

  useEffect(() => {
    // Scroll to the bottom of the div when it is loaded
    if (subject == "chat")
      divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [divRef.current, subject, messages]);
  const { events, tasks } = useSelector((state) => state.myCounselorReducer);
  const { counselor } = useSelector(
    (state) => state.userReducer?.user?.Student
  );

  const { user } = useSelector((state) => state.userReducer);

  let currentSender = "";

  const dispatch = useDispatch();

  const changeStatus = (id) => {
    if (id) dispatch(changeTaskStatus(id));
  };

  const {
    editTaskLoading,
    editTaskSuccess,
    chat,
    sendMessageLoading,
    sendMessageFail,
    sendMessageSuccess,
  } = useSelector((state) => state.myCounselorReducer);

  useEffect(() => {
    if (sendMessageFail != "") {
      setMessages((previousArr) => previousArr.slice(0, -1));
    }
  }, [sendMessageFail]);

  const [chatMessage, setChatMessage] = useState("");

  const doc_ref = useRef(null);

  const handleSendMessage = (e) => {
    e.preventDefault();
    dispatch(MyCounselorActions.cleanSendMessage());
    if (chatMessage != "") {
      dispatch(
        sendMessage(
          user.conversation[0].id,
          chatMessage,
          user?.id,
          counselor.id,
          counselor?.fcm_token,
          "message"
        )
      );
      dispatch(
        MyCounselorActions.addNewMessage({
          id: Math.random(),
          content: chatMessage,
          sender: user?.id,
          receiver: {
            counselor_id: counselor.id,
            counselor_fcm: counselor?.fcm_token,
          },
        })
      );
      setChatMessage("");
    }
  };

  const handleChatDocument = (e) => {
    doc_ref.current.click();
  };

  const handleDocChane = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        sendMessage(
          user.conversation[0].id,
          "file",
          user?.id,
          counselor.id,
          counselor?.fcm_token,
          "file",
          file
        )
      );
    }
  };

  const { fetchChatLoading } = useSelector((state) => state.myCounselorReducer);

  async function getBackNotification() {
    try {
      const hasItems = await hasItemsInIndexedDB(dispatch);
      return hasItems;
      // Perform any desired operations based on the result
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors that occurred during the IndexedDB operations
    }
  }

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        getBackNotification().then((res) => {
          if (res) dispatch(getChats(user?.id, user?.Student?.counselor?.id));
        });
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  if (subject == "upcoming_events") {
    return (
      <div className="panel_container_events">
        <div className="title">
          <div className="text">Upcoming Events with Counselor</div>
          <img
            src={close}
            alt="close"
            className="close_icon"
            onClick={() => setSelected(false)}
          />
        </div>
        <div className="all_events">
          {events.length > 0 ? (
            events.map((event, index) => {
              return (
                <>
                  <div className="event_container" key={index}>
                    <div className="event_container_inner">
                      <div className="event_container_inner_left">
                        <img src={dot} alt="dot" className="blue_dot" />
                        <div className="event_container_inner_left_text">
                          <div className="event_title">{event.topic}</div>
                          <div className="event_time">
                            {/* {new Date(event.date).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                            <br /> */}
                            {new Date(
                              event.date + " " + event.time
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "2-digit",
                            })}
                            -
                            {new Date(
                              new Date(
                                event.date + " " + event.time
                              ).getTime() + 5400000
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "2-digit",
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="event_container_inner_right">
                        {event.status !== "accepted" && (
                          <div className="event_date">
                            {new Date(event.date).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </div>
                        )}
                        <div className="event_status">
                          {event.status === "accepted" ? (
                            <Button
                              text="Join Meeting"
                              color="lightGreen"
                              disabled={
                                new Date(
                                  event.date + " " + event.time
                                ).getTime() > new Date().getTime() ||
                                new Date().getTime() >
                                  new Date(
                                    new Date(
                                      event.date + " " + event.time
                                    ).getTime() + 5400000
                                  ).getTime()
                              }
                            />
                          ) : event.status === "rejected" ? (
                            "Rejected"
                          ) : (
                            <div
                              style={{
                                color: "var(--yellow)",
                                fontFamily: "P_SemiboldItalic",
                              }}
                            >
                              Pending Confirmation
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {index == events.length - 1 && (
                    <div className="no_more">No more events</div>
                  )}
                </>
              );
            })
          ) : (
            <div className="no_more">No Upcoming Events</div>
          )}
        </div>
      </div>
    );
  } else if (subject == "chat") {
    return (
      <div className="chat_container">
        <div className="Chat_top">
          <div className="title">Chat With Your Counselor</div>
          <img
            src={close}
            alt="close"
            className="close_img"
            onClick={() => setSelected(false)}
          />
        </div>
        {fetchChatLoading && <Loader fullScreen={true} />}
        {
          <div className="chat_body" ref={divRef}>
            {messages.map((message, index) => {
              let title = "";
              if (
                message.receiver.id === user?.id &&
                currentSender !== "Counselor"
              ) {
                title = "Counselor";
                currentSender = "Counselor";
              } else if (
                message.receiver.id !== user?.id &&
                currentSender !== "You"
              ) {
                title = "You";
                currentSender = "You";
              }
              return message.receiver.id == user?.id ? (
                message.file == null ? (
                  <>
                    <div className="title">{title}</div>
                    <div className="sent_by_me" key={index}>
                      <div className="text">{message.content}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title">{title}</div>
                    <div
                      className="sent_by_me"
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => {
                        handlePreview(
                          "messages/" + message.id + "/file/" + message.file
                        );
                      }}
                    >
                      <img src={documentt} alt="file" />
                      <div className="text">{message.file}</div>
                    </div>
                  </>
                )
              ) : message.file == null ? (
                <>
                  <div className="counselor_title">{title}</div>
                  <div className="sent_to_me" key={index}>
                    <div className="text">{message.content}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="counselor_title">{title}</div>
                  <div
                    className="sent_to_me"
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                      handlePreview(
                        "messages/" + message.id + "/file/" + message.file
                      );
                    }}
                  >
                    <img src={documentt} alt="file" />
                    <div className="text">{message.file}</div>
                  </div>
                </>
              );
            })}
          </div>
        }
        {sendMessageFail && (
          <div
            style={{
              color: "var(--pink)",
              fontFamily: "P_Semibold",
              fontSize: "1.2rem",
              textAlign: "right",
            }}
          >
            {sendMessageFail}
          </div>
        )}
        <div className="chat_input">
          <form onSubmit={(e) => handleSendMessage(e)}>
            <div className="chat_input_inner">
              <div className="left">
                <input
                  type="text"
                  placeholder={
                    sendMessageFail != "" && sendMessageLoading
                      ? "Sending..."
                      : "Type your message"
                  }
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  disabled={sendMessageLoading}
                  id={"chat_input"}
                  onBlur={(e) => {
                    e.target.focus();
                  }}
                />
              </div>

              <div className="right">
                <img
                  src={attachment}
                  alt="attachment"
                  className="attachment_icon"
                  onClick={() => {
                    handleChatDocument();
                  }}
                />
                <input
                  type="file"
                  ref={doc_ref}
                  accept=".jpg, .jpeg, .png, .pdf, .doc, .xls"
                  style={{ display: "none" }}
                  onChange={(e) => handleDocChane(e)}
                />
                <button
                  type="submit"
                  style={{
                    border: "none",
                    background: "transparent",
                    padding: "0",
                    margin: "0 0 -0.25rem 0",
                  }}
                >
                  <img
                    src={send}
                    alt="send"
                    className="send_icon"
                    onClick={(e) => handleSendMessage(e)}
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } else if (subject == "assigned_tasks") {
    return (
      <div className="tasks_container">
        <div className="tasks_container_title">
          <div className="text">Your Assigned Tasks</div>
          <img
            src={close}
            alt="close"
            className="close_icon"
            onClick={() => setSelected(false)}
          />
        </div>
        <div className="tasks_main">
          {tasks.length > 0 ? (
            tasks.map((task, index) => {
              return (
                <>
                  <div className="task_container" key={index}>
                    <div className="task_container_left">
                      <div className="task_container_left_main">
                        <img src={yellowDot} alt="dot" className="yellow_dot" />
                        <div className="title">{task.title}</div>
                      </div>
                      {editTaskLoading && <Loader fullScreen={true} />}
                      <div className="task_container_left_text">
                        {task.status.toLowerCase() === "pending" ? (
                          <div
                            className="done_button"
                            onClick={() => changeStatus(task.id)}
                          >
                            Done
                          </div>
                        ) : task.status.toLowerCase() === "in review" ? (
                          <div
                            className="text"
                            style={{
                              color: "var(--yellow)",
                            }}
                          >
                            Pending
                          </div>
                        ) : task.status.toLowerCase() === "accepted" ? (
                          <div
                            className="text"
                            style={{
                              color: "var(--lightGreen)",
                            }}
                          >
                            Accepted
                          </div>
                        ) : (
                          <div
                            className="text"
                            style={{
                              color: "var(--pink)",
                            }}
                          >
                            Rejected
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="task_container_right">
                      <div className="time">
                        {formatCreatedAtDate(task.createdAt)}
                      </div>
                    </div>
                  </div>
                  {index == tasks.length - 1 && (
                    <div className="no_more">No more tasks</div>
                  )}
                </>
              );
            })
          ) : (
            <div className="no_more">No Assigned Tasks</div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="meet_container">
        <img
          src={close}
          alt="close"
          className="close_icon"
          onClick={() => setSelected(false)}
        />
        <div className="meet_container_top">
          <div className="meet_container_top_inner">
            Meet&nbsp;
            <span>
              {counselor?.first_name} {counselor?.last_name}!
            </span>
          </div>
        </div>
        <div className="meet_container_middle">
          <div className="meet_container_middle_inner">
            <div className="single_div div_top">
              <div className="title">Full Name</div>

              <div className="data">
                {counselor?.first_name} {counselor?.last_name}
              </div>
            </div>
            <div className="single_div">
              <div className="title">Email</div>
              <div className="data">{counselor?.email}</div>
            </div>
            <div className="single_div">
              <div className="title">Phone Number</div>
              <div className="data">{counselor?.phone}</div>
            </div>
          </div>
        </div>
        <div className="meet_container_bottom">
          <div className="meet_container_bottom_inner">
            {counselor?.description}
          </div>
        </div>
      </div>
    );
  }
}

export default MyCounslerPanel;
