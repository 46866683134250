const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Tasks: [],
  getTasksLoading: false,
  getTasksSuccess: false,
  getTasksError: "",
  ////////Create Task////////
  createTaskLoading: false,
  createTaskSuccess: false,
  createTaskError: "",
  ////////Delete Task////////
  deleteTaskLoding: false,
  deleteTaskSuccess: false,
  deleteTaskError: "",
  ////////Patch Task////////
  changeTaskStatusLoading: false,
  changeTaskStatusSuccess: false,
  changeTaskStatusError:""
};

const CounselorAppTasksSlice = createSlice({
  name: "Tasks",
  initialState,
  reducers: {
    getTasksRequest(state) {
      state.getTasksLoading = true;
    },
    getTasksSuccess(state, action) {
      state.Tasks = action.payload;
      state.getTasksLoading = false;
      state.getTasksSuccess = true;
      state.getTasksError = "";
    },
    getTasksError(state, action) {
      state.Tasks = [];
      state.getTasksLoading = false;
      state.getTasksSuccess = false;
      state.getTasksError = action.payload;
    },
    ////////Create Task////////
    createTaskRequest(state) {
      state.createTaskLoading = true;
    },
    createTaskSuccess(state) {
      state.createTaskLoading = false;
      state.createTaskSuccess = true;
      state.createTaskError = "";
    },
    createTaskError(state, action) {
      state.createTaskLoading = false;
      state.createTaskSuccess = false;
      state.createTaskError = action.payload;
    },
    ////////Delete Task////////
    deleteTaskRequest(state) {
      state.deleteTaskLoding = true;
    },
    deleteTaskSuccess(state) {
      state.deleteTaskLoding = false;
      state.deleteTaskSuccess = true;
      state.deleteTaskError = "";
    },
    deleteTaskError(state, action) {
      state.deleteTaskLoding = false;
      state.deleteTaskSuccess = false;
      state.deleteTaskError = action.payload;
    },
    ////////Patch task////////
    changeTaskStatusRequest(state){
      state.changeTaskStatusLoading=true;
    },
    changeTaskStatusSuccess(state){
      state.changeTaskStatusLoading=false;
      state.changeTaskStatusSuccess=true;
      state.changeTaskStatusError=""
    },
    changeTaskStatusError(state,action){
      state.changeTaskStatusLoading=false;
      state.changeTaskStatusSuccess=false;
      state.changeTaskStatusError=action.payload
    }
  },
});

export const CounselorAppTasksActions = CounselorAppTasksSlice.actions;
export default CounselorAppTasksSlice;
