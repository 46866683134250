/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  allChats: [],
  getAllChatsLoading: false,
  getAllChatsError: "",
  messages: [],
  getMessagesLoading: false,
  getMessagesError: "",

  senderId: [],
};

const CounselorChatSlice = createSlice({
  name: "CounselorChat",
  initialState,
  reducers: {
    getAllChatsReq(state) {
      state.allChats = [];
      state.getAllChatsLoading = true;
      state.getAllChatsError = "";
    },
    getAllChatsSuccess(state, action) {
      state.getAllChatsLoading = false;
      state.allChats = action.payload;
      state.getAllChatsError = "";
    },
    getAllChatsFail(state, action) {
      state.allChats = [];
      state.getAllChatsLoading = false;
      state.getAllChatsError = action.payload;
    },

    getMessagesReq(state) {
      state.messages = [];
      state.getMessagesLoading = true;
      state.getMessagesError = "";
    },
    getMessagesSuccess(state, action) {
      state.getMessagesLoading = false;
      state.messages = action.payload;
      state.getMessagesError = "";
    },
    getMessagesFail(state, action) {
      state.messages = [];
      state.getMessagesLoading = false;
      state.getMessagesError = action.payload;
    },

    addMessage(state, action) {
      state.messages.push(action.payload);
    },

    addNewSender(state, action) {
      state.senderId.push(action.payload);
    },

    resetNewSender(state, action) {
      state.senderId = state.senderId.filter(
        (element) => element !== action.payload
      );
    },
  },
});

export const CounselorChatActions = CounselorChatSlice.actions;
export default CounselorChatSlice;
