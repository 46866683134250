import React, { useEffect, useState } from "react";
import "./ApplicationStep2.scss";
import ApplyForm from "../../../Components/ApplyForm/ApplyForm";
import Input from "../../../Components/Input/Input";
import DateInput from "../../../Components/DatePicker/DateInput";
import DropDown from "../../../Components/DropDown/DropDown";
import Button from "../../../Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import trash from "../../../Assets/Images/trash.png";
import ApplicationStep3 from "../Application3/ApplicationStep3";
import UpperPopUp from "../../../Components/UpperPopUp/UpperPopUp";
import img from "../../../Assets/Images/checked.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCont } from "../../../Redux/Location/LocationsActions";
import { DropDownList } from "../../../../Utils";
import { updateApplication } from "../../../Redux/CreateApplication/CreateApplicationAction";
import Loader from "../../../Components/Loader/Loader";
import { getSingleApp } from "../../../Redux/Application/ApplicationAction";
function ApplicationStep2({ counselor, step, setStep }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [school, setSchool] = useState("");
  const [countryEd, setCountryEd] = useState("");
  const [overGpa, setOverGpa] = useState("");
  const [gpa, setGpa] = useState("");
  const [degree, setDegree] = useState("");
  const [field, setField] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [educationA, setEducationA] = useState([]);
  const [hideDetails, setHideDetails] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState("");
  const [showUpper, setShowUpper] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    updateApplicationLoading,
    updateApplicationError,
    updateApplicationSuccess,
  } = useSelector((state) => state.CreateApplicationReducer);
  const {
    singleApplication,
    getSingleAppLoading,
    getSingleAppSuccess,
    getSingleAppError,
  } = useSelector((state) => state.ApplicationsReducer);
  const { Countries, getCountriesLoading } = useSelector(
    (state) => state.LocationReducer
  );

  useEffect(() => {
    dispatch(getAllCont());
  }, []);
  useEffect(() => {
    if (Countries && Countries.length > 0) {
      setAllCountries(DropDownList(Countries, "name", "name"));
    }
  }, [Countries]);
  useEffect(() => {
    if (getCountriesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getCountriesLoading]);

  useEffect(() => {
    if (updateApplicationLoading) {
      setBtnLoad(true);
    } else {
      setBtnLoad(false);
    }
  }, [updateApplicationLoading]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hideDetails) {
      addEducation();
      if (educationA.length != 0) {
        setError("");
      }
      setHideDetails(true);
    } else {
      setClicked(true);
      const application = {
        education_summary: educationA,
        application_step: 3,
      };
      dispatch(
        updateApplication(localStorage.getItem("applicationId"), application)
      );
    }
  };

  const clearAll = () => {
    setSchool("");
    setCountryEd("");
    setOverGpa("");
    setGpa("");
    setDegree("");
    setField("");
    setStart("");
    setEnd("");
    setError("");
  };

  const addEducation = () => {
    if (
      educationA.length == 0 &&
      (school == "" ||
        countryEd == "" ||
        overGpa == "" ||
        gpa == "" ||
        degree == "" ||
        field == "" ||
        start == "" ||
        end == "")
    ) {
      setError("Please fill all the fields");
    } else {
      if (
        school != "" &&
        countryEd != "" &&
        overGpa != "" &&
        gpa != "" &&
        degree != "" &&
        field != "" &&
        start != "" &&
        end != ""
      ) {
        const educationArray = [...educationA];
        educationArray.push({
          school_name: school,
          country_of_education: countryEd.value,
          grade_scale: overGpa,
          gpa: gpa,
          degree: degree,
          field_of_study: field,
          start_date: new Date(start),
          end_date: new Date(end),
        });
        setEducationA(educationArray);
        setShowTable(true);
        clearAll();
      } else {
        setError("Please fill all the fields");
      }
    }
  };
  const deleteEducation = (index) => {
    const educationArray = [...educationA];
    educationArray.splice(index, 1);
    setEducationA(educationArray);
    if (educationA.length == 1) {
      setShowTable(false);
      setHideDetails(false);
    }
  };

  const goBackF = () => {
    if (hideDetails) {
      setHideDetails(false);
    } else {
      if (!counselor) {
        setStep(1);
        navigate("/student/my_applications/apply/1");
      } else {
        setStep(1);
        navigate("/counselor/applications/create/1");
      }
    }
  };

  useEffect(() => {
    if (updateApplicationSuccess && clicked && step == 2) {
      if (!counselor) {
        setStep(3);
        navigate("/student/my_applications/apply/3");
      } else {
        setStep(3);
        navigate("/counselor/applications/create/3");
      }
    }
  }, [updateApplicationSuccess, clicked]);

  useEffect(() => {
    if (updateApplicationError) {
      setError(updateApplicationError);
    } else {
      setError("");
    }
  }, [updateApplicationError]);

  useEffect(() => {
    if (localStorage.getItem("applicationId")) {
      dispatch(getSingleApp(localStorage.getItem("applicationId")));
    }
  }, []);

  useEffect(() => {
    if (
      singleApplication &&
      singleApplication?.education_summary &&
      singleApplication.education_summary?.length > 0 &&
      localStorage.getItem("applicationId") &&
      educationA.length == 0
    ) {
      const educationArray = [...educationA];
      singleApplication.education_summary.map((item) => {
        educationArray.push({
          school_name: item.school_name,
          country_of_education: item.country_of_education,
          grade_scale: item.grade_scale,
          gpa: item.gpa,
          degree: item.degree,
          field_of_study: item.field_of_study,
          start_date: item.start_date,
          end_date: item.end_date,
        });
      });
      setEducationA(educationArray);
      setHideDetails(true);
      setShowTable(true);
    } else if (
      !singleApplication ||
      !singleApplication?.education_summary ||
      singleApplication.education_summary?.length == 0 ||
      !localStorage.getItem("applicationId")
    ) {
      setShowTable(false);
      setHideDetails(false);
      setEducationA([]);
    }
  }, [singleApplication]);

  return (
    <div className="application_step2_mainDiv">
      {showUpper && (
        <UpperPopUp
          setShow={setShowUpper}
          title="Application progress saved"
          img={img}
          navigate={() => {
            counselor
              ? navigate(
                  "/counselor/applications/singleApp" +
                    "/" +
                    localStorage.getItem("applicationId")
                )
              : navigate("/student/my_applications");
          }}
        />
      )}
      {step == 2 ? (
        <ApplyForm
          title="Education Summary"
          subTitle="Please make sure your details are accurate and updated"
          hasBack={true}
          loading={loading}
          back={goBackF}
          hasPlus={hideDetails ? false : true}
          plus={() => addEducation()}
          body={
            <div className="application_step1_body">
              {!hideDetails && (
                <>
                  <div className="application_step1_body_div1">
                    <div className="input_div">
                      <div className="input_tile">School Attended</div>
                      <Input
                        placeholder="e.g: ABC Academy"
                        type="text"
                        width={"100%"}
                        onChange={(e) => setSchool(e.target.value)}
                        required={true}
                        value={school}
                      />
                    </div>

                    <div className="input_div">
                      <div className="input_tile">Country of Education</div>
                      {allCountries && allCountries.length > 0 && (
                        <DropDown
                          placeholder="Select Country"
                          setSelected={setCountryEd}
                          data={allCountries}
                          blueArrow={true}
                          width="100%"
                          multiSelection={false}
                          searchable={true}
                          selectedData={countryEd}
                          value={countryEd}
                        />
                      )}
                    </div>
                  </div>
                  <div className="application_step1_body_div1">
                    <div className="input_div">
                      <div className="input_tile">Grade Scale (out of)</div>
                      <Input
                        placeholder="e.g: 10"
                        type="number"
                        width={"100%"}
                        onChange={(e) => setOverGpa(e.target.value)}
                        required={true}
                        value={overGpa}
                        min={0}
                      />
                    </div>

                    <div className="input_div">
                      <div className="input_tile">Grade (GPA)</div>
                      <Input
                        placeholder="e.g: 7.8"
                        type="number"
                        width={"100%"}
                        onChange={(e) => setGpa(e.target.value)}
                        required={true}
                        value={gpa}
                        min={0}
                        max={overGpa}
                      />
                    </div>
                  </div>
                  <div className="application_step1_body_div1">
                    <div className="input_div">
                      <div className="input_tile">Degree</div>
                      <Input
                        min={0}
                        placeholder="e.g: Master's Degree"
                        type="text"
                        width={"100%"}
                        onChange={(e) => setDegree(e.target.value)}
                        required={true}
                        value={degree}
                      />
                    </div>

                    <div className="input_div">
                      <div className="input_tile">Field of Study</div>
                      <Input
                        placeholder="e.g: Computer Science"
                        type="text"
                        width={"100%"}
                        onChange={(e) => setField(e.target.value)}
                        required={true}
                        value={field}
                      />
                    </div>
                  </div>
                  <div className="application_step1_body_div1">
                    <div className="input_div">
                      <div className="input_tile">Start Date</div>
                      <DateInput
                        width="100%"
                        setSelectedDate={setStart}
                        selectedDate={start}
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_tile">End Date (or expected)</div>
                      <DateInput
                        width="100%"
                        setSelectedDate={setEnd}
                        selectedDate={end}
                        disabled={start == "" ? true : false}
                        minDate={start && new Date(start) }
                      />
                    </div>
                  </div>
                </>
              )}

              {showTable && (
                <div className="application_step2_educationTable_main">
                  {educationA?.map((item, i) => {
                    return (
                      <div
                        className="application_step2_educationTable"
                        style={{
                          borderBottom: hideDetails && "3px solid black",
                          borderTop: !hideDetails && "3px solid black",
                        }}
                        key={i}
                      >
                        <div className="educationTable_text">
                          {item.school_name}
                        </div>
                        <div className="educationTable_text">
                          {item.field_of_study}
                        </div>
                        <div className="educationTable_text">
                          {item.gpa} / {item.grade_scale}
                        </div>
                        <div className="educationTable_text">
                          {/* only show year */}
                          {new Date(item.start_date).getUTCFullYear()} -{" "}
                          {new Date(item.end_date).getUTCFullYear()}
                        </div>
                        <img
                          src={trash}
                          alt="delete"
                          className="educationTable_delete"
                          onClick={() => {
                            deleteEducation(i);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {error && <div className="error">{error}</div>}
              <div className="application_step1_body_footer">
                <Button
                  text={btnLoad ? <Loader onlyDots={true} /> : "Continue"}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  color="yellow"
                  width="100%"
                  disabled={
                    (educationA.length == 0 &&
                      (school == "" ||
                        countryEd == "" ||
                        overGpa == "" ||
                        gpa == "" ||
                        degree == "" ||
                        field == "" ||
                        start == "" ||
                        end == "")) ||
                    btnLoad
                      ? true
                      : false
                  }
                />
                <div
                  className="application_step1_body_footer_text"
                  onClick={() => setShowUpper(true)}
                >
                  Continue Later
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <ApplicationStep3 counselor={counselor} step={step} setStep={setStep} />
      )}
    </div>
  );
}

export default ApplicationStep2;
