import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { NotificationActions } from "./Shared/Redux/Notification/NotificationReducer";
import { openDB } from "idb";
import { MyCounselorActions } from "./Student/Redux/MyCounselor/MyCounselorReducer";
import CounselorChatSlice, {
  CounselorChatActions,
} from "./Counsler/Redux/CounselorChat/CounselorChatReducer";

export const axiosReq =
  (method, url, header, body, params) => async (dispatch) => {
    try {
      let response = await axios({
        method: method,
        url: url,
        headers: header,
        data: body,
        withCredentials: true,
        params: params,
        paramsSerializer: (params) => {
          // Sample implementation of query string building
          let result = "";
          Object.keys(params).forEach((key) => {
            if (params[key] || params[key] === 0)
              result += `${key}=${encodeURIComponent(params[key])}&`;
          });
          return result.substring(0, result.length - 1);
        },
      });
      return { success: 1, data: response.data };
    } catch (error) {
      if (
        error.response.data.message === "AccessTokenExpired" ||
        error.response.data.message === "jwt expired"
      ) {
        try {
          let res = await axios({
            method: "post",
            url: process.env.REACT_APP_API + "/auth/refresh",
            headers: {},
            data: {},
            withCredentials: true,
          });
          try {
            let response = await axios({
              method: method,
              url: url,
              headers: header,
              data: body,
              withCredentials: true,
            });
            return { success: 1, data: response.data };
          } catch (error) {
            return { success: 0, data: error };
          }
        } catch (err) {
          if (!window.location.href.includes("login"))
            window.location.href = "/login";
          else {
            return { success: 0, data: error };
          }
        }
      } else {
        return { success: 0, data: error };
      }
    }
  };

const firebaseConfig = {
  apiKey: "AIzaSyAHVENvYl6Wm-VFYcV44WmZtnJ3X1qjlfA",
  authDomain: "eb-firebase-387615.firebaseapp.com",
  projectId: "eb-firebase-387615",
  storageBucket: "eb-firebase-387615.appspot.com",
  messagingSenderId: "528595789",
  appId: "1:528595789:web:619aa26b015c0269ad5504",
  measurementId: "G-L57F7VJHW9",
};

export function requestPermission(dispatch) {
  try {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        onMessage(messaging, (payload) => {
          // ...
          dispatch(NotificationActions.notificationReceived());
          const notification = payload.notification;
          const data = payload.data;
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            silent: true,
          };
          var notifications = new Notification(
            notificationTitle,
            notificationOptions
          );

          openDB("notificationsDBb", 1, {
            upgrade(db) {
              if (!db.objectStoreNames.contains("notificationss")) {
                db.createObjectStore("notificationss", { keyPath: "id" });
              }
            },
          }).then((db) => {
            const transaction = db.transaction("notificationss", "readwrite");
            const store = transaction.objectStore("notificationss");

            const record = {
              id: new Date().getTime(),
              title: notification.title,
              body: notification.body,
              data: data,
            };

            if (notification.title === "Chat") {
              if (data.hasOwnProperty("file")) {
                dispatch(
                  MyCounselorActions.addNewMessage({
                    id: payload.data.messageId,
                    content: data.message,
                    file: data.file,
                    timestamp: new Date().getTime(),
                    sender: { id: data.sender },
                    receiver: { id: data.receiver },
                  })
                );
                if (localStorage.getItem("senderId") === data.sender) {
                  dispatch(
                    CounselorChatActions.addMessage({
                      id: payload.data.messageId,
                      content: data.message,
                      file: data.file,
                      timestamp: new Date().getTime(),
                      sender: { id: data.sender },
                      receiver: { id: data.receiver },
                    })
                  );
                } else {
                  dispatch(CounselorChatActions.addNewSender(data.sender));
                }
              } else {
                dispatch(
                  MyCounselorActions.addNewMessage({
                    id: payload.data.messageId,
                    content: data.message,
                    file: null,
                    timestamp: new Date().getTime(),
                    sender: { id: data.sender },
                    receiver: { id: data.receiver },
                  })
                );
                if (localStorage.getItem("senderId") === data.sender) {
                  dispatch(
                    CounselorChatActions.addMessage({
                      id: payload.data.messageId,
                      content: data.message,
                      file: null,
                      timestamp: new Date().getTime(),
                      sender: { id: data.sender },
                      receiver: { id: data.receiver },
                    })
                  );
                } else {
                  dispatch(CounselorChatActions.addNewSender(data.sender));
                }
              }
            } else {
              if (data.accepted == "true") {
                dispatch(
                  NotificationActions.setIsAccepted({
                    isAccepted: notification.body,
                    data: data,
                  })
                );
              }
            }

            store.add(record).then(() => {});
          });
        });

        getToken(messaging, {
          vapidKey:
            "BEJanE4UEEwwfW4CxA4UhCb7rqZsaAo7oH1W_Ec2kBuGdvexgKq3ObMrRuotlCdtOXz3Z46x93OgtiNDuqJw3zQ",
        }).then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("fcm_token", currentToken);
          } else {
            alert(
              "No registration token available! Request refresh to get one."
            );
          }
        });
      } else {
        alert(
          "You have blocked notification some features may not work properly! Please allow notification from settings."
        );
      }
    });
  } catch (error) {
    // Safari doesn't return a promise for requestPermissions and it
    // throws a TypeError. It takes a callback as the first argument
    // instead.
    const browserInfo = navigator.userAgent.toLowerCase();

    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return;
    }
    if (error instanceof TypeError) {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          const app = initializeApp(firebaseConfig);
          const messaging = getMessaging(app);
          onMessage(messaging, (payload) => {
            // ...
            dispatch(NotificationActions.notificationReceived());
            const notification = payload.notification;
            const data = payload.data;
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              silent: true,
            };
            var notifications = new Notification(
              notificationTitle,
              notificationOptions
            );

            openDB("notificationsDBb", 1, {
              upgrade(db) {
                if (!db.objectStoreNames.contains("notificationss")) {
                  db.createObjectStore("notificationss", { keyPath: "id" });
                }
              },
            }).then((db) => {
              const transaction = db.transaction("notificationss", "readwrite");
              const store = transaction.objectStore("notificationss");

              const record = {
                id: new Date().getTime(),
                title: notification.title,
                body: notification.body,
                data: data,
              };

              if (notification.title === "Chat") {
                if (data.hasOwnProperty("file")) {
                  dispatch(
                    MyCounselorActions.addNewMessage({
                      id: payload.data.messageId,
                      content: data.message,
                      file: data.file,
                      timestamp: new Date().getTime(),
                      sender: { id: data.sender },
                      receiver: { id: data.receiver },
                    })
                  );
                  if (localStorage.getItem("senderId") === data.sender) {
                    dispatch(
                      CounselorChatActions.addMessage({
                        id: payload.data.messageId,
                        content: data.message,
                        file: data.file,
                        timestamp: new Date().getTime(),
                        sender: { id: data.sender },
                        receiver: { id: data.receiver },
                      })
                    );
                  } else {
                    dispatch(CounselorChatActions.addNewSender(data.sender));
                  }
                } else {
                  dispatch(
                    MyCounselorActions.addNewMessage({
                      id: payload.data.messageId,
                      content: data.message,
                      file: null,
                      timestamp: new Date().getTime(),
                      sender: { id: data.sender },
                      receiver: { id: data.receiver },
                    })
                  );
                  if (localStorage.getItem("senderId") === data.sender) {
                    dispatch(
                      CounselorChatActions.addMessage({
                        id: payload.data.messageId,
                        content: data.message,
                        file: null,
                        timestamp: new Date().getTime(),
                        sender: { id: data.sender },
                        receiver: { id: data.receiver },
                      })
                    );
                  } else {
                    dispatch(CounselorChatActions.addNewSender(data.sender));
                  }
                }
              } else {
                if (data.accepted == "true") {
                  dispatch(
                    NotificationActions.setIsAccepted({
                      isAccepted: notification.body,
                      data: data,
                    })
                  );
                }
              }

              store.add(record).then(() => {});
            });
          });

          getToken(messaging, {
            vapidKey:
              "BEJanE4UEEwwfW4CxA4UhCb7rqZsaAo7oH1W_Ec2kBuGdvexgKq3ObMrRuotlCdtOXz3Z46x93OgtiNDuqJw3zQ",
          }).then((currentToken) => {
            if (currentToken) {
              localStorage.setItem("fcm_token", currentToken);
            } else {
              alert(
                "No registration token available! Request refresh to get one."
              );
            }
          });
        } else {
          alert(
            "You have blocked notification some features may not work properly! Please allow notification from settings."
          );
        }
      });
    } else {
      throw error;
    }
  }
}

const hasItemsInIndexedDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open("notificationsDBb", 1);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      if (!db.objectStoreNames.contains("notificationss")) {
        db.createObjectStore("notificationss", { keyPath: "id" });
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(["notificationss"], "readonly");
      const store = transaction.objectStore("notificationss");

      const cursorRequest = store.openCursor();

      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;

        if (cursor) {
          const notificationData = cursor.value;
          if (
            notificationData.data.hasOwnProperty("accepted") &&
            notificationData.data.accepted == "true"
          ) {
            dispatch(
              NotificationActions.setIsAccepted({
                isAccepted: notificationData.body,
                data: notificationData.data,
              })
            );
          }

          cursor.continue();
        }
      };

      const countRequest = store.count();
      countRequest.onsuccess = () => {
        const count = countRequest.result;

        if (count === 0) {
          // Object store exists, but it doesn't have any items
          resolve(false);
        } else {
          // Object store exists and has items
          resolve(true);
        }
      };

      transaction.oncomplete = () => {
        db.close();
      };
    };

    request.onerror = (event) => {
      console.error("Database error:", event.target.error);
      reject(event.target.error);
    };
  });
};

export default hasItemsInIndexedDB;

export function dateConv(string) {
  const date = new Date(string);
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  return formattedDate;
}

export function IdSplit(ID) {
  const firstThreeDigits = ID?.slice(0, 3);
  const lastThreeDigits = ID?.slice(-3);
  const app_number = "#" + firstThreeDigits + lastThreeDigits;
  return app_number;
}

export const languagesList = [
  { value: "Afrikaans", label: "Afrikaans" },
  { value: "Albanian", label: "Albanian" },
  { value: "Amharic", label: "Amharic" },
  { value: "Arabic", label: "Arabic" },
  { value: "Armenian", label: "Armenian" },
  { value: "Azerbaijani", label: "Azerbaijani" },
  { value: "Basque", label: "Basque" },
  { value: "Belarusian", label: "Belarusian" },
  { value: "Bengali", label: "Bengali" },
  { value: "Bosnian", label: "Bosnian" },
  { value: "Bulgarian", label: "Bulgarian" },
  { value: "Burmese", label: "Burmese" },
  { value: "Catalan", label: "Catalan" },
  { value: "Chinese", label: "Chinese" },
  { value: "Croatian", label: "Croatian" },
  { value: "Czech", label: "Czech" },
  { value: "Danish", label: "Danish" },
  { value: "Dutch", label: "Dutch" },
  { value: "English", label: "English" },
  { value: "Esperanto", label: "Esperanto" },
  { value: "Estonian", label: "Estonian" },
  { value: "Filipino", label: "Filipino" },
  { value: "Finnish", label: "Finnish" },
  { value: "French", label: "French" },
  { value: "Galician", label: "Galician" },
  { value: "Georgian", label: "Georgian" },
  { value: "German", label: "German" },
  { value: "Greek", label: "Greek" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Haitian Creole", label: "Haitian Creole" },
  { value: "Hausa", label: "Hausa" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Hindi", label: "Hindi" },
  { value: "Hmong", label: "Hmong" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Icelandic", label: "Icelandic" },
  { value: "Igbo", label: "Igbo" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Irish", label: "Irish" },
  { value: "Italian", label: "Italian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Javanese", label: "Javanese" },
  { value: "Kannada", label: "Kannada" },
  { value: "Kazakh", label: "Kazakh" },
  { value: "Khmer", label: "Khmer" },
  { value: "Korean", label: "Korean" },
  { value: "Kurdish", label: "Kurdish" },
  { value: "Kyrgyz", label: "Kyrgyz" },
  { value: "Lao", label: "Lao" },
  { value: "Latin", label: "Latin" },
  { value: "Latvian", label: "Latvian" },
  { value: "Lithuanian", label: "Lithuanian" },
  { value: "Luxembourgish", label: "Luxembourgish" },
  { value: "Macedonian", label: "Macedonian" },
  { value: "Malagasy", label: "Malagasy" },
  { value: "Malay", label: "Malay" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Maltese", label: "Maltese" },
  { value: "Maori", label: "Maori" },
  { value: "Marathi", label: "Marathi" },
  { value: "Mongolian", label: "Mongolian" },
  { value: "Nepali", label: "Nepali" },
  { value: "Norwegian", label: "Norwegian" },
  { value: "Nyanja", label: "Nyanja" },
  { value: "Odia", label: "Odia" },
  { value: "Pashto", label: "Pashto" },
  { value: "Persian", label: "Persian" },
  { value: "Polish", label: "Polish" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Romanian", label: "Romanian" },
  { value: "Russian", label: "Russian" },
  { value: "Samoan", label: "Samoan" },
  { value: "Scottish Gaelic", label: "Scottish Gaelic" },
  { value: "Serbian", label: "Serbian" },
  { value: "Sesotho", label: "Sesotho" },
  { value: "Shona", label: "Shona" },
  { value: "Sindhi", label: "Sindhi" },
  { value: "Sinhala", label: "Sinhala" },
  { value: "Slovak", label: "Slovak" },
  { value: "Slovenian", label: "Slovenian" },
  { value: "Somali", label: "Somali" },
  { value: "Spanish", label: "Spanish" },
  { value: "Sundanese", label: "Sundanese" },
  { value: "Swahili", label: "Swahili" },
  { value: "Swedish", label: "Swedish" },
  { value: "Tajik", label: "Tajik" },
  { value: "Tamil", label: "Tamil" },
  { value: "Tatar", label: "Tatar" },
  { value: "Telugu", label: "Telugu" },
  { value: "Thai", label: "Thai" },
  { value: "Turkish", label: "Turkish" },
  { value: "Turkmen", label: "Turkmen" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Urdu", label: "Urdu" },
  { value: "Uyghur", label: "Uyghur" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Welsh", label: "Welsh" },
  { value: "Wolof", label: "Wolof" },
  { value: "Xhosa", label: "Xhosa" },
  { value: "Yiddish", label: "Yiddish" },
  { value: "Yoruba", label: "Yoruba" },
  { value: "Zulu", label: "Zulu" },
];

export const DropDownList = (data, attr1, attr2) => {
  //example:setAllMajors(DropDownList(MajorCatg, "id", "name"));
  if (data.length > 0) {
    let temp = [];
    if (attr1 && attr2) {
      data.map((item) => {
        temp.push({ value: item[attr1], label: item[attr2] });
      });
    } else {
      data.map((item) => {
        temp.push({ value: item, label: item });
      });
    }
    return temp;
  } else return [];
};

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatCreatedAtDate = (createdAt) => {
  if (!createdAt) return "No messages";
  const createdDate = new Date(createdAt);
  const currentDate = new Date();

  const timeDifference = currentDate - createdDate;
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  if (hoursDifference < 1) {
    if (Math.floor(hoursDifference * 60) == 0) {
      return "Just now";
    } else return Math.floor(hoursDifference * 60) + " minutes ago";
  } else if (hoursDifference < 24) {
    return Math.floor(hoursDifference) + " hours ago";
  } else if (hoursDifference < 168) {
    // Less than a week (24 * 7)
    const daysDifference = Math.floor(Math.floor(hoursDifference) / 24);
    return daysDifference + " days ago";
  } else {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return createdDate.toLocaleDateString("en-US", options);
  }
};

export const handleDownload = (path, name) => {
  const filePath = process.env.REACT_APP_API + "/files/" + path;
  axios({
    method: "get",
    url: filePath,
    responseType: "blob",
    withCredentials: true, // Enable credentials
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const handlePreview = (path, name) => {
  const filePath = process.env.REACT_APP_API + "/files/" + path;
  const link = document.createElement("a");
  link.href = filePath;
  link.setAttribute("download", name); // Specify the desired filename
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
