import { components } from "react-select";
import arrow_down_default from "../../Assets/Images/arrow_down_default.webp";
import arrow_up_default from "../../Assets/Images/arrow_up_default.webp";

import "./DropDown.scss";

const { DropdownIndicator } = components;

export const CustomDropdownIndicator2 = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <DropdownIndicator {...props}>
      {!menuIsOpen ? (
        <img className="drop_down_arrow" src={arrow_down_default} />
      ) : (
        <img className="drop_down_arrow" src={arrow_up_default} />
      )}
    </DropdownIndicator>
  );
};
