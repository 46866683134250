const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  AllUnis: [],
  getAllUnisError: "",
  getAllUnisSuccess: false,
  getAllUnisLoading: false,
  /////////////////////////
  University: {},
  getOneUniversityLoading: false,
  getOneUniversitySuccess: false,
  getOneUniversityError: "",
  /////////////////////////
  Majors: [],
  getMajorsOfFacyltiesByIdLoading: false,
  getMajorsOfFacyltiesByIdSuccess: false,
  getMajorsOfFacyltiesByIdError: "",
  /////////////////////////
  Degrees: [],
  getDegreeByMajorIdLoading: false,
  getDegreeByMajorIdSuccess: false,
  getDegreeByMajorIdError: "",
  /////////////////////////
  MajorDegree: {},
  getMajorDegreeLoading: false,
  getMajorDegreeSuccess: false,
  getMajorDegreeError: "",
  MajorDegree: {},
  /////////////////////////
  addFavoriteLoading: false,
  addFavoriteSuccess: false,
  addFavoriteFail: false,

  ////////////////////////
  MajorCatg: [],
  getMajorCatgLoading: false,
  getMajorCatgSuccess: false,
  getMajorCatgError: "",

  ////////////////////////
  uniCatg: [],
  getUniCatgLoading: false,
  getUniCatgSuccess: false,
  getUniCatgError: "",
};

const UniSlice = createSlice({
  name: "Universities",
  initialState,
  reducers: {
    getAllUnisRequest(state) {
      state.getAllUnisLoading = true;
    },
    getAllUnisSuccess(state, action) {
      state.AllUnis = action.payload;
      state.getAllUnisSuccess = true;
      state.getAllUnisError = "";
      state.getAllUnisLoading = false;
    },
    getAllUnisError(state, action) {
      state.AllUnis = [];
      state.getAllUnisSuccess = false;
      state.getAllUnisError = action.payload;
      state.getAllUnisLoading = false;
    },
    ////////Get university by ID////////
    getOneUniversityRequest(state) {
      state.getOneUniversityLoading = true;
    },
    getOneUniversitySuccess(state, action) {
      state.University = action.payload;
      state.getOneUniversitySuccess = true;
      state.getOneUniversityLoading = false;
      state.getOneUniversityError = "";
    },
    getOneUniversityError(state, action) {
      state.University = {};
      state.getOneUniversitySuccess = false;
      state.getOneUniversityLoading = false;
      state.getOneUniversityError = action.payload;
    },
    ////////Get Majors By Faculty Id////////
    getMajorsByFacultyIdRequest(state) {
      state.getMajorsOfFacyltiesByIdLoading = true;
    },
    getMajorsByFacultyIdSuccess(state, action) {
      state.getMajorsOfFacyltiesByIdLoading = false;
      state.Majors = action.payload;
      state.getMajorsOfFacyltiesByIdSuccess = true;
      state.getMajorsOfFacyltiesByIdError = "";
    },
    getMajorsByFacultyIdError(state, action) {
      state.Majors = [];
      state.getMajorsOfFacyltiesByIdLoading = false;
      state.getMajorsOfFacyltiesByIdSuccess = false;
      state.getMajorsOfFacyltiesByIdError = action.payload;
    },
    ////////Get Degree By Major Id////////
    getDegreeByMajorIdRequest(state) {
      state.getDegreeByMajorIdLoading = true;
    },
    getDegreeByMajorIdSuccess(state, action) {
      state.Degrees = action.payload;
      state.getDegreeByMajorIdLoading = false;
      state.getDegreeByMajorIdSuccess = true;
      state.getDegreeByMajorIdError = "";
    },
    getDegreeByMajorIdError(state, action) {
      state.Degrees = [];
      state.getDegreeByMajorIdLoading = false;
      state.getDegreeByMajorIdSuccess = false;
      state.getDegreeByMajorIdError = action.payload;
    },
    ////////Get Major Degree////////
    getMajorDegreeRequest(state) {
      state.getMajorDegreeLoading = true;
    },
    getMajorDegreeSuccess(state, action) {
      state.MajorDegree = action.payload;
      state.getMajorDegreeLoading = false;
      state.getMajorDegreeSuccess = true;
      state.getMajorDegreeError = "";
    },
    getMajorDegreeError(state, action) {
      state.MajorDegree = {};
      state.getMajorDegreeLoading = false;
      state.getMajorDegreeSuccess = false;
      state.getMajorDegreeError = action.payload;
    },
    ////////Add to Favorite////////
    addToFavoriteRequest(state) {
      state.addFavoriteLoading = true;
      state.addFavoriteSuccess = false;
      state.addFavoriteFail = false;
    },
    addToFavoriteSuccess(state) {
      state.addFavoriteLoading = false;
      state.addFavoriteSuccess = true;
      state.addFavoriteFail = false;
    },
    addfavoriteFail(state) {
      state.addFavoriteLoading = state.addFavoriteSuccess = false;
      state.addFavoriteFail = true;
    },
    ////////Get Major Catg////////
    getMajorCatgRequest(state) {
      state.MajorCatg = [];
      state.getMajorCatgLoading = true;
      state.getMajorCatgSuccess = false;
      state.getMajorCatgError = "";
    },
    getMajorCatgSuccess(state, action) {
      state.MajorCatg = action.payload;
      state.getMajorCatgLoading = false;
      state.getMajorCatgSuccess = true;
      state.getMajorCatgError = "";
    },
    getMajorCatgError(state, action) {
      state.MajorCatg = [];
      state.getMajorCatgLoading = false;
      state.getMajorCatgSuccess = false;
      state.getMajorCatgError = action.payload;
    },

    ////////Get Uni Catg////////
    getUniCatgRequest(state) {
      state.uniCatg = [];
      state.getUniCatgLoading = true;
      state.getUniCatgSuccess = false;
      state.getUniCatgError = "";
    },
    getUniCatgSuccess(state, action) {
      state.uniCatg = action.payload;
      state.getUniCatgLoading = false;
      state.getUniCatgSuccess = true;
      state.getUniCatgError = "";
    },
    getUniCatgError(state, action) {
      state.uniCatg = [];
      state.getUniCatgLoading = false;
      state.getUniCatgSuccess = false;
      state.getUniCatgError = action.payload;
    },
  },
});
export const UniversitiesActions = UniSlice.actions;
export default UniSlice;
