import React, { useState } from "react";
import "./OBStep3.scss";
import Circle from "../../../../Shared/Components/Animations/Circle/Circle";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";

import SingleSelect from "../../../../Shared/Components/SingleSelect/SingleSelect";
import Star from "../../../../Shared/Components/Animations/Star/Star";
import OBStep4 from "../OBStep4/OBStep4";
function OBStep3({ step, setStep, student, setStudent, counselor }) {
  const [inLevel, setInLevel] = useState("");

  const inLevels = [
    "High School",
    "Summer/Short Courses",
    "Language",
    "Foundation/Pathway",
    "Diploma/Associate Degree",
    "Certificate",
    "Bachelors",
    "Pre-masters",
    "Masters",
    "Doctorate",
  ];

  const onSubmit = () => {
    setStudent({ ...student, needed_degree: inLevel });
    if (inLevel.length != 0) setStep(4);
  };
  return (
    <div className="obStep_main_div">
      {step == 3 ? (
        <FormLayout
          back={() => setStep(2)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={
            counselor
              ? "Choose the level of interest"
              : "What level are you interested in?  "
          }
          minHeight="fit-content"
          width="90%"
          body={
            <div className="step_body">
              <SingleSelect
                data={inLevels}
                setSelected={setInLevel}
                width="200px"
                selectedIndex={inLevel}
              />
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={inLevel.length == 0 && true}
              />
            </div>
          }
          icon={<Star />}
          bg="var(--pink)"
          gap="20px"
        />
      ) : (
        <OBStep4
          step={step}
          setStep={setStep}
          student={student}
          setStudent={setStudent}
          counselor={counselor}
        />
      )}
    </div>
  );
}

export default OBStep3;
