import { getSingleAppMissingDocs } from "../../../Shared/Redux/Application/ApplicationAction";
import { axiosReq } from "../../../Utils";
import { CounselorAppTasksActions } from "./CounselorAppTasksReducer";

export const getTasks = (AppId) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/tasks/application/${AppId}`;
  dispatch(CounselorAppTasksActions.getTasksRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorAppTasksActions.getTasksSuccess(res.data));
    } else {
      dispatch(
        CounselorAppTasksActions.getTasksError(res.data.response.data.message)
      );
    }
  });
};

export const createTask = (data, AppId) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/tasks/create`;
  dispatch(CounselorAppTasksActions.createTaskRequest());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(CounselorAppTasksActions.createTaskSuccess(res.data));
      dispatch(getTasks(AppId));
      dispatch(getSingleAppMissingDocs(AppId));
    } else {
      dispatch(
        CounselorAppTasksActions.createTaskError(res.data.response.data.message)
      );
    }
  });
};

export const deleteTask = (taskId, AppId) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/tasks/${taskId}`;
  dispatch(CounselorAppTasksActions.deleteTaskRequest());
  dispatch(axiosReq("delete", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(CounselorAppTasksActions.deleteTaskSuccess(res.data));
      dispatch(getTasks(AppId));
      dispatch(getSingleAppMissingDocs(AppId));
    } else {
      dispatch(
        CounselorAppTasksActions.deleteTaskError(res.data.response.message)
      );
    }
  });
};

export const changeTaskStatus = (taskId, status, AppId) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/tasks/${taskId}/status`;
  dispatch(CounselorAppTasksActions.changeTaskStatusRequest());
  dispatch(axiosReq("patch", url, {}, { status: status })).then((res) => {
    if (res.success) {
      dispatch(CounselorAppTasksActions.changeTaskStatusSuccess(res.data));
      dispatch(getTasks(AppId));
    } else {
      dispatch(
        CounselorAppTasksActions.changeTaskStatusError(
          res.data.response.message
        )
      );
    }
  });
};
