/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Stats: [],
  Counselors: [],
  Students: [],
  CreateDirectLoading: false,
  statssuccess: true,
  allcounssuccess: true,
  assignsuccess: false,
  getstudentsuccess: true,
};

const SalesCounselorSlice = createSlice({
  name: "SalesCounselor",
  initialState,
  reducers: {
    GetStatsRequest(state) {
      state.statssuccess = true;
      state.error = "";
    },
    GetStatsSuccess(state, action) {
      state.statssuccess = false;
      state.Stats = action.payload;
      state.error = "";
    },
    GetStatsFail(state, action) {
      state.statssuccess = false;
      state.error = action.payload;
    },
    getallcouselorsRequest(state) {
      state.allcounssuccess = true;
      state.error = "";
    },
    getallcouselorsSuccess(state, action) {
      state.allcounssuccess = false;
      state.Counselors = action.payload.data;
      state.error = "";
    },
    getallcouselorsFail(state, action) {
      state.allcounssuccess = false;
      state.error = action.payload;
    },
    AssignStudentRequest(state) {
      state.assignsuccess = true;
      state.error = "";
    },
    AssignStudentSuccess(state, action) {
      state.assignsuccess = false;
      state.error = "";
    },
    AssignStudentFail(state, action) {
      state.assignsuccess = false;
      state.error = action.payload;
    },
    getstudentsRequest(state) {
      state.getstudentsuccess = true;
      state.error = "";
    },
    getstudentsSuccess(state, action) {
      state.getstudentsuccess = false;
      state.Students = action.payload;
      state.error = "";
    },
    getstudentsFail(state, action) {
      state.getstudentsuccess = false;
      state.error = action.payload;
    },
  },
});

export const SalesCounselorActions = SalesCounselorSlice.actions;
export default SalesCounselorSlice;
