/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  loading: true,
  CrmStats: [],
  error: "",
  Sources: [],
};

const CrmSlice = createSlice({
  name: "Crm",
  initialState,
  reducers: {
    GetstatisticsRequest(state) {
      state.loading = true;
      state.error = "";
    },
    GetstatisticsSuccess(state, action) {
      state.loading = false;
      state.CrmStats = action.payload;
      state.error = "";
    },
    GetstatisticsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    GetsourcesRequest(state) {
      state.loading = true;
      state.error = "";
    },
    GetsourcesSuccess(state, action) {
      state.loading = false;
      state.Sources = action.payload;
      state.error = "";
    },
    GetsourcesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const CrmActions = CrmSlice.actions;
export default CrmSlice;
