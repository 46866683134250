import React from "react";
import "./SortBy.scss";
import DropDown from "../DropDown/DropDown";
function SortBy({
  placeholder,
  data,
  isClearable,
  blueArrow,
  width,
  keepPlaceHolder,
  searchable,
  isDisabled,
  selectedData,
  value,
  setSelectedData,
}) {
  return (
    <div className="button_sort_by" style={{ width: width }}>
      <DropDown
        data={data}
        isClearable={isClearable}
        width={width}
        blueArrow={blueArrow}
        selectedData={selectedData}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isSearchable={searchable}
        setSelected={setSelectedData}
        className="DropDown_Main"
        classNamePrefix={"DropDown_Main"}
        value={keepPlaceHolder ? placeholder : value}
        autoFocus={false}
      />
    </div>
  );
}

export default SortBy;
