/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  events: [],
  getEventsLoading: false,
  getEventsError: "",
  TodayEvents: [],
  TodayEventsLoading: false,
  TodayEventsError: "",

  changeMeetingStatusLoading: false,
  changeMeetingStatusError: "",
  changeMeetingStatusSuccess: false,
};

const CounselorMeetingSlice = createSlice({
  name: "CounselorMeeting",
  initialState,
  reducers: {
    getEventsReq(state) {
      state.events = [];
      state.getEventsLoading = true;
      state.getEventsError = "";
    },
    getEventsSuccess(state, action) {
      state.getEventsLoading = false;
      state.events = action.payload;
      state.getEventsError = "";
    },
    getEventsFail(state, action) {
      state.events = [];
      state.getEventsLoading = false;
      state.getEventsError = action.payload;
    },

    getTodayEventsReq(state) {
      state.TodayEvents = [];
      state.TodayEventsLoading = true;
      state.TodayEventsError = "";
    },
    getTodayEventsSuccess(state, action) {
      state.TodayEventsLoading = false;
      state.TodayEvents = action.payload;
      state.TodayEventsError = "";
    },
    getTodayEventsFail(state, action) {
      state.TodayEvents = [];
      state.TodayEventsLoading = false;
      state.TodayEventsError = action.payload;
    },

    changeMeetingStatusReq(state) {
      state.changeMeetingStatusLoading = true;
      state.changeMeetingStatusError = "";
      state.changeMeetingStatusSuccess = false;
    },

    changeMeetingStatusSuccess(state, action) {
      state.changeMeetingStatusLoading = false;
      state.changeMeetingStatusError = "";
      state.changeMeetingStatusSuccess = true;
    },
    changeMeetingStatusFail(state, action) {
      state.changeMeetingStatusLoading = false;
      state.changeMeetingStatusError = action.payload;
      state.changeMeetingStatusSuccess = false;
    },
  },
});

export const CounselorMeetingActions = CounselorMeetingSlice.actions;
export default CounselorMeetingSlice;
