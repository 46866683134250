import React from "react";
import "./Popup.scss";
import close from "../../Assets/Images/close.webp";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import Confetti from "../../Assets/Images/confetti.gif";

function Popup({ setShowPopup, body, width, showClose, confetti }) {
  const screenWidth = UseInnerWidth();
  // showClose={screenWidth <=768 && true}
  return (
    <div className="Main_popup">
      <div
        className={!confetti ? "Background_popup" : "Background_popup_confetti"}
        onClick={() => {
          setShowPopup(false);
        }}
      ></div>
      <div
        className="Popup_division"
        style={{
          width: screenWidth < 550 ? "100%" : width ? width : "fit-content",
        }}
      >
        {showClose && (
          <img
            src={close}
            alt="close"
            className="image_close_popup"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        )}
        <div className="Popup_division_top">{body}</div>
      </div>
    </div>
  );
}

export default Popup;
