import React from "react";
import "./Triangle.scss";
import triangle from "../../../Assets/Images/triangle.png";

function Triangle() {
  return (
    <div className="Triangle_Container">
      <img src={triangle} alt="Triangle" className="Triangle" />
      <img src={triangle} alt="Triangle2" className="Triangle2" />
      <img src={triangle} alt="Triangle3" className="Triangle3" />
      <img src={triangle} alt="Triangle4" className="Triangle4" />
    </div>
  );
}

export default Triangle;
