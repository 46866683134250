/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  user: {},

  //user
  createError: "",
  createLoading: false,
  success: false,

  //get user
  getUserLoading: false,
  getUserError: "",
  getUserSuccess: false,

  //get user role 
  getUserRoleLoading: false,
  getUserRoleError: "",
  getUserRoleSuccess: false,
  role:"",
  //login
  loginError: "",
  loginLoading: false,
  loginSuccess: false,

  //phone verify
  phoneVerifyLoading: false,
  phoneVerifyError: "",
  phoneVerifySuccess: false,

  //otp verify
  otpVerifyLoading: false,
  otpVerifyError: "",
  otpVerifySuccess: false,

  //verify phone forget
  verifyPhoneForgetLoading: false,
  verifyPhoneForgetError: "",
  verifyPhoneForgetSuccess: false,

  //otp verify forget
  otpVerifyForgetLoading: false,
  otpVerifyForgetError: "",
  otpVerifyForgetSuccess: false,

  //reset password
  resetPasswordLoading: false,
  resetPasswordError: "",
  resetPasswordSuccess: false,

  //logout
  logoutLoading: false,
  logoutError: "",
  logoutSuccess: false,

  //Contact us
  sendingSupportEmailLoading: false,
  sendingSupportEmailFailed: false,
  sendingSupportEmailSuccess: false,

};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    //create user
    createUserRequest(state) {
      state.createLoading = true;
      state.createError = "";
      state.user = {};
      state.success = false;
    },
    createUserSuccess(state, action) {
      state.createLoading = false;
      state.user = action.payload.user;
      state.createError = "";
      state.success = true;
    },
    createUserFail(state, action) {
      state.createLoading = false;
      state.user = {};
      state.createError = action.payload;
      state.success = false;
    },

    //get user
    getUserRequest(state) {
      state.getUserLoading = true;
      state.getUserError = "";
      state.user = {};
    },
    getUserSuccess(state, action) {
      state.getUserLoading = false;
      state.getUserError = "";
      state.user = action.payload;
    },
    getUserFail(state, action) {
      state.getUserLoading = false;
      state.getUserError = action.payload;
      state.user = {};
    },
    //login user
    loginUserRequest(state) {
      state.loginLoading = true;
      state.loginError = "";
      state.loginSuccess = false;
    },
    loginUserSuccess(state, action) {
      state.loginLoading = false;
      state.loginError = "";
      state.loginSuccess = true;
    },
    loginUserFail(state, action) {
      state.loginLoading = false;
      state.loginError = action.payload;
      state.loginSuccess = false;
    },

    //phone verify
    phoneVerifyRequest(state) {
      state.phoneVerifyLoading = true;
      state.phoneVerifyError = "";
      state.phoneVerifySuccess = false;
    },
    phoneVerifySuccess(state, action) {
      state.phoneVerifyLoading = false;
      state.phoneVerifyError = "";
      state.phoneVerifySuccess = true;
    },
    phoneVerifyFail(state, action) {
      state.phoneVerifyLoading = false;
      state.phoneVerifyError = action.payload;
      state.phoneVerifySuccess = false;
    },

    //otp verify
    otpVerifyRequest(state) {
      state.otpVerifyLoading = true;
      state.otpVerifyError = "";
      state.otpVerifySuccess = false;
    },
    otpVerifySuccess(state, action) {
      state.otpVerifyLoading = false;
      state.otpVerifyError = "";
      state.otpVerifySuccess = true;
    },
    otpVerifyFail(state, action) {
      state.otpVerifyLoading = false;
      state.otpVerifyError = action.payload;
      state.otpVerifySuccess = false;
    },

    //verify phone forget
    verifyPhoneForgetRequest(state) {
      state.verifyPhoneForgetLoading = true;
      state.verifyPhoneForgetError = "";
      state.verifyPhoneForgetSuccess = false;
    },
    verifyPhoneForgetSuccess(state, action) {
      state.verifyPhoneForgetLoading = false;
      state.verifyPhoneForgetError = "";
      state.verifyPhoneForgetSuccess = true;
    },
    verifyPhoneForgetFail(state, action) {
      state.verifyPhoneForgetLoading = false;
      state.verifyPhoneForgetError = action.payload;
      state.verifyPhoneForgetSuccess = false;
    },

    //otp verify forget
    otpVerifyForgetRequest(state) {
      state.otpVerifyForgetLoading = true;
      state.otpVerifyForgetError = "";
      state.otpVerifyForgetSuccess = false;
    },
    otpVerifyForgetSuccess(state, action) {
      state.otpVerifyForgetLoading = false;
      state.otpVerifyForgetError = "";
      state.otpVerifyForgetSuccess = true;
    },
    otpVerifyForgetFail(state, action) {
      state.otpVerifyForgetLoading = false;
      state.otpVerifyForgetError = action.payload;
      state.otpVerifyForgetSuccess = false;
    },

    //reset password
    resetPasswordRequest(state) {
      state.resetPasswordLoading = true;
      state.resetPasswordError = "";
      state.resetPasswordSuccess = false;
    },
    resetPasswordSuccess(state, action) {
      state.resetPasswordLoading = false;
      state.resetPasswordError = "";
      state.resetPasswordSuccess = true;
    },
    resetPasswordFail(state, action) {
      state.resetPasswordLoading = false;
      state.resetPasswordError = action.payload;
      state.resetPasswordSuccess = false;
    },

    //logout
    logoutRequest(state) {
      state.logoutLoading = true;
      state.logoutError = "";
      state.logoutSuccess = false;
    },
    logoutSuccess(state) {
      state.logoutLoading = false;
      state.logoutError = "";
      state.logoutSuccess = true;
      state.user = {};
      state.role=""
    },
    logoutFail(state, action) {
      state.logoutLoading = false;
      state.logoutError = action.payload;
      state.logoutSuccess = false;
    },

    //get user role
    getUserRoleRequest(state) {
      state.getUserRoleLoading = true;
      state.getUserRoleError = "";
      state.getUserRoleSuccess = false;
    },
    getUserRoleSuccess(state, action) {
      state.getUserRoleLoading = false;
      state.getUserRoleError = "";
      state.getUserRoleSuccess = true;
      state.role = action.payload.role;
    },
    getUserRoleFail(state, action) {
      state.getUserRoleLoading = false;
      state.getUserRoleError = action.payload;
      state.getUserRoleSuccess = false;
    },
    //send email to support
    sendSupportEmailLoading(state) {
      state.sendingSupportEmailLoading = true;
      state.sendingSupportEmailFailed = false;
      state.sendingSupportEmailSuccess = false;
      
    },
    sendSupportEmailSuccess(state) {
      state.sendingSupportEmailLoading = false;
      state.sendingSupportEmailFailed = false;
      state.sendingSupportEmailSuccess = true;
      
    },
    sendSupportEmailFailed(state) {
      state.sendingSupportEmailLoading = false;
      state.sendingSupportEmailFailed = true;
      state.sendingSupportEmailSuccess = false;
      
    },
  },
});

export const UserActions = UserSlice.actions;
export default UserSlice;
