import axios from "axios";
import { axiosReq } from "../../../Utils";
import { UniversitiesActions } from "../Universities/UniversitiesReducer";

export const getAllUnis =
  (limit, offset, search, filter) => async (dispatch) => {
    let url = process.env.REACT_APP_API + `/university/all`;

    dispatch(UniversitiesActions.getAllUnisRequest());
    dispatch(
      axiosReq(
        "get",
        url,
        {},
        {},
        {
          tuitionHigh: filter?.tuitionHigh <= 15000 && filter?.tuitionHigh,
          tuitionLow: filter?.tuitionLow,
          degree: filter?.degree,
          language: filter?.language,
          major: filter?.major,
          city: filter?.city,
          countries: filter?.countries,
          search: search,
          limit: limit,
          offset: offset,
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(UniversitiesActions.getAllUnisSuccess(res.data));
      } else
        dispatch(
          UniversitiesActions.getAllUnisError(res.data.response.data.message)
        );
    });
  };

export const getAllUnisCatg = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/university/UniversitiesByCategory";
  dispatch(UniversitiesActions.getUniCatgRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) dispatch(UniversitiesActions.getUniCatgSuccess(res.data));
    else
      dispatch(
        UniversitiesActions.getUniCatgError(res.data.response.data.message)
      );
  });
};

export const getUniById = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/university/${id}`;
  dispatch(UniversitiesActions.getOneUniversityRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(UniversitiesActions.getOneUniversitySuccess(res.data));
    else
      dispatch(
        UniversitiesActions.getOneUniversityError(
          res.data.response.data.message
        )
      );
  });
};
export const getMajorByFacultyId = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/major/faculty/${id}`;
  dispatch(UniversitiesActions.getMajorsByFacultyIdRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(UniversitiesActions.getMajorsByFacultyIdSuccess(res.data));
    else
      dispatch(
        UniversitiesActions.getMajorsByFacultyIdError(
          res.data.response.data.message
        )
      );
  });
};
export const getDegreeByMajorId = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/degree/${id}`;
  dispatch(UniversitiesActions.getDegreeByMajorIdRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(UniversitiesActions.getDegreeByMajorIdSuccess(res.data));
    else
      dispatch(
        UniversitiesActions.getDegreeByMajorIdError(
          res.data.response.data.message
        )
      );
  });
};

export const getMajorDegree =
  (majorId, DegreeId, studentId) => async (dispatch) => {
    let url = "";
    if (studentId) {
      url =
        process.env.REACT_APP_API +
        `/majordegree/${majorId}/${DegreeId}/${studentId}`;
    } else {
      url = process.env.REACT_APP_API + `/majordegree/${majorId}/${DegreeId}`;
    }

    dispatch(UniversitiesActions.getMajorDegreeRequest());
    dispatch(axiosReq("get", url, {}, {})).then((res) => {
      if (res.success)
        dispatch(UniversitiesActions.getMajorDegreeSuccess(res.data));
      else
        dispatch(
          UniversitiesActions.getMajorDegreeError(
            res.data.response.data.message
          )
        );
    });
  };

export const addFavorite = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/student/favourite`;
  dispatch(UniversitiesActions.addToFavoriteRequest());
  dispatch(axiosReq("post", url, {}, { university: id })).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.addToFavoriteSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.addfavoriteFail(res.data.response.data.message)
      );
  });
};

export const getMajotCAtg = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/major/group`;
  dispatch(UniversitiesActions.getMajorCatgRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(UniversitiesActions.getMajorCatgSuccess(res.data));
    else
      dispatch(
        UniversitiesActions.getMajorCatgError(res.data.response.data.message)
      );
  });
};
