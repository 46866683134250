import React, { useEffect, useState } from "react";
import "./CreateStep9.scss";
import Popup from "../../../../Shared/Components/Popup/Popup";
import UpperPopUp from "../../../../Shared/Components/UpperPopUp/UpperPopUp";
import img from "../../../../Shared/Assets/Images/checked.png";
import Button from "../../../../Shared/Components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import ScheduleMeeting from "../../../../Shared/Components/ScheduleMeeting/ScheduleMeeting";
import { sendCredential } from "../../../Redux/CounselorStudent/CounselorStudentActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Shared/Components/Loader/Loader";
import { getactiveCounselors } from "../../../../Sales/Redux/Students/StudentsActions";
function CreateStep9({ step, setStep, student }) {
  const dispatch = useDispatch();
  const [showPopUp, setShowPopUp] = useState(false);
  const [upperPopup, setUpperPopup] = useState(false);
  const [upperPop2, setUpperPop2] = useState(false);
  const [popUp2, setPopUp2] = useState(false);
  const [bookPop, setBookPop] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setselectedTime] = useState();
  const [meetingSubject, setmeetingSubject] = useState("");
  const [upperPop3, setupperPop3] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState("");
  const [counselorName, setCounselorName] = useState("");
  const { Counselors } = useSelector((state) => state.StudentsSaleReducer);
  useEffect(() => {
    dispatch(getactiveCounselors());
  }, []);

  const {
    createdStudent,
    sendCredentialsLoading,
    sendCredentialsError,
    sendCredentialsSuccess,
  } = useSelector((state) => state.CounselorStudentReducer);

  const { requestMeetingSuccess } = useSelector(
    (state) => state.myCounselorReducer
  );
  useEffect(() => {
    if (step == 9) {
      setShowPopUp(true);
      setUpperPopup(true);
    }
  }, []);
  const navigate = useNavigate();

  const sendEmail = () => {
    setClicked(true);
    dispatch(sendCredential(student));
  };

  useEffect(() => {
    if (sendCredentialsSuccess && clicked) {
      setPopUp2(true);
      setUpperPop2(true);
      setShowPopUp(false);
      setUpperPopup(false);
    }
  }, [sendCredentialsSuccess]);

  useEffect(() => {
    if (sendCredentialsError && clicked) {
      setError(sendCredentialsError);
    } else {
      setError("");
    }
  }, [sendCredentialsError]);

  useEffect(() => {
    if (requestMeetingSuccess) {
      setupperPop3(true);
    }
  }, [requestMeetingSuccess]);

  const { pathname } = useLocation();

  return (
    <div className="createStep9_main_div">
      {showPopUp && (
        <Popup
          setShowPopUp={setShowPopUp}
          body={
            <div className="createStudent_popUp">
              <h1 className="createStudent_popUp_header">
                Send Credentials via Email
              </h1>
              <div className="createStudent_popUp_body">
                <p className="createStudent_popUp_subtext">
                  Do you want to send credentials via email to the
                  student?
                </p>
                <div className="createStudent_btns">
                  {!sendCredentialsLoading && (
                    <Button
                      text={
                        sendCredentialsLoading ? (
                          <Loader onlyDots={true} />
                        ) : (
                          "Yes"
                        )
                      }
                      onClick={() => sendEmail()}
                      color="lightGreen"
                      width="100%"
                      disabled={sendCredentialsLoading ? true : false}
                    />
                  )}
                  <Button
                    text={
                      sendCredentialsLoading ? (
                        <Loader onlyDots={true} />
                      ) : (
                        "No"
                      )
                    }
                    onClick={() => {
                      setPopUp2(true);
                      setShowPopUp(false);
                    }}
                    color="pink"
                    width="100%"
                    disabled={sendCredentialsLoading ? true : false}
                  />
                </div>
              </div>
            </div>
          }
        />
      )}

      {popUp2 && (
        <Popup
          setShowPopUp={setPopUp2}
          body={
            <div className="createStudent_popUp">
              <h1 className="createStudent_popUp_header">Book a Meeting</h1>
              <div className="createStudent_popUp_body">
                <p className="createStudent_popUp_subtext">
                  Do you want to book a meeting for the student?
                </p>
                <div className="createStudent_btns">
                  <Button
                    text="Yes"
                    onClick={() => {
                      setPopUp2(false);
                      setUpperPop2(false);
                      setBookPop(true);
                    }}
                    color="lightGreen"
                    width="12rem"
                  />
                  <Button
                    text="No"
                    onClick={() => {
                      pathname.includes("sales")
                        ? navigate("/sales/students")
                        : navigate("/counselor/students");
                    }}
                    color="pink"
                    width="12rem"
                  />
                  {error && <div className="error">{error}</div>}
                </div>
              </div>
            </div>
          }
        />
      )}
      <div className="popup_zoom">
        {bookPop && (
          <ScheduleMeeting
            showPopup={bookPop}
            selectedDay={selectedDay}
            selectedTime={selectedTime}
            setselectedTime={setselectedTime}
            setSelectedDay={setSelectedDay}
            meetingSubject={meetingSubject}
            setmeetingSubject={setmeetingSubject}
            setShow={setupperPop3}
            sales={pathname.includes("sales")}
            stid={createdStudent.student}
            type={pathname.includes("sales") ? 2 : 3}
            setCounselorName={setCounselorName}
            test={pathname.includes("sales") && true}
            counselorName={counselorName}
            back={true}
            goBack={() => {
              setBookPop(false);
              setPopUp2(true);
              setShowPopUp(false);
            }}
            counselors={
              pathname.includes("sales") &&
              Counselors.map((el) => {
                return {
                  label: el.counselor.first_name + " " + el.counselor.last_name,
                  value: el.counselor.id,
                };
              })
            }
          />
        )}
      </div>

      {upperPopup && (
        <UpperPopUp setShow={setUpperPopup} title="Account Created" img={img} />
      )}

      {upperPop2 && (
        <UpperPopUp setShow={setUpperPop2} title="Email Sent" img={img} />
      )}

      {upperPop3 && (
        <UpperPopUp
          setShow={setupperPop3}
          navigate={() => {
            pathname.includes("sales")
              ? navigate("/sales/students")
              : navigate("/counselor/students");
          }}
          title="Meeting Booked"
          img={img}
        />
      )}
    </div>
  );
}

export default CreateStep9;
