/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Students: [],
  user: [],
  countryList: [],
  editUsersucess: false,

  loading: false,
  error: "",

  // create student
  createdStudent: [],
  createStudentLoading: false,
  createStudentError: "",
  createStudentSuccess: false,

  // send credentials
  sendCredentialsLoading: false,
  sendCredentialsError: "",
  sendCredentialsSuccess: false,
  //getstudentbyid
  address: [],
  getaddressLoading: false,
  getaddressError: "",
  exp: [],
  getexpLoading: false,
  getexpError: "",
  contact: [],
  getcontactLoading: false,
  getcontactError: "",
};

const CounselorStudentSlice = createSlice({
  name: "CounselorStudent",
  initialState,
  reducers: {
    CounselorStudentRequest(state) {
      state.loading = true;
      state.error = "";
    },
    CounselorStudentSuccess(state, action) {
      state.loading = false;
      state.Students = action.payload;
      state.error = "";
    },
    CounselorStudentFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    CounselorStudentDataRequest(state) {
      state.loading = true;
      state.error = "";
    },
    CounselorStudentDataSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    },
    CounselorStudentDataFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateuserReq(state, action) {
      state.loading = false;
      state.error = "";
      state.editUsersucess = false;
    },
    updateuserSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.editUsersucess = true;
      state.error = "";
    },
    updateuserFail(state, action) {
      state.loading = false;

      state.error = action.payload;
      state.editUsersucess = false;
    },
    getuserbyidReq(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    },
    getuserbyidSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    },
    getuserbyidFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getcountryReq(state, action) {
      state.loading = false;
      state.countryList = action.payload;
      state.error = "";
    },
    getcountrySuccess(state, action) {
      state.loading = false;
      state.countryList = action.payload;
      state.error = "";
    },
    getcountryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    pacthstudentReq(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    },
    pacthstudentSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    },
    pacthstudentFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // create student
    createStudentRequest(state) {
      state.createStudentLoading = true;
      state.createStudentError = "";
      state.createStudentSuccess = false;
      state.createdStudent = [];
    },
    createStudentSuccess(state, action) {
      state.createStudentLoading = false;
      state.createdStudent = action.payload;
      state.createStudentError = "";
      state.createStudentSuccess = true;
    },
    createStudentFail(state, action) {
      state.createStudentLoading = false;
      state.createStudentError = action.payload;
      state.createStudentSuccess = false;
      state.createdStudent = [];
    },

    // send credentials
    sendCredentialsRequest(state) {
      state.sendCredentialsLoading = true;
      state.sendCredentialsError = "";
      state.sendCredentialsSuccess = false;
    },
    sendCredentialsSuccess(state, action) {
      state.sendCredentialsLoading = false;
      state.sendCredentialsError = "";
      state.sendCredentialsSuccess = true;
    },
    sendCredentialsFail(state, action) {
      state.sendCredentialsLoading = false;
      state.sendCredentialsError = action.payload;
      state.sendCredentialsSuccess = false;
    },
    getstudentadrressReq(state, action) {
      state.getaddressLoading = false;
      state.address = action.payload;
      state.getaddressError = "";
    },
    getstudentadrressSuccess(state, action) {
      state.getaddressLoading = false;
      state.address = action.payload;
      state.getaddressError = "";
    },
    getstudentadrressFail(state, action) {
      state.getaddressLoading = false;
      state.getaddressError = action.payload;
    },
    getstudentexperienceReq(state, action) {
      state.getexpLoading = false;
      state.exp = action.payload;
      state.getexpError = "";
    },
    getstudentexperienceSuccess(state, action) {
      state.getexpLoading = false;
      state.exp = action.payload;
      state.getexpError = "";
    },
    getstudentexperienceFail(state, action) {
      state.getexpLoading = false;
      state.getexpError = action.payload;
    },
    getemercycontactReq(state, action) {
      state.getcontactLoading = false;
      state.contact = action.payload;
      state.getcontactError = "";
    },
    getemercycontactSuccess(state, action) {
      state.getcontactLoading = false;
      state.contact = action.payload;
      state.getcontactError = "";
    },
    getemercycontactFail(state, action) {
      state.getcontactLoading = false;
      state.getcontactError = action.payload;
    },
  },
});

export const CounselorStudentActions = CounselorStudentSlice.actions;
export default CounselorStudentSlice;
