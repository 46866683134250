import { axiosReq } from "../../../Utils";
import { getUser } from "../User/UserActions";
import { StudentActions } from "./StudentReducer";

export const getStudentMe = () => async (dispatch) => {
  dispatch(StudentActions.fetchStudentRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/student/me", {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(StudentActions.fetchStudentSuccess(res.data));
    } else {
      dispatch(StudentActions.fetchStudentFail(res.data.response.data.message));
    }
  });
};

export const uploadPic = (pic) => async (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append("image", pic);
  dispatch(StudentActions.uploadImgRequest());
  dispatch(
    axiosReq("patch", process.env.REACT_APP_API + "/user/me", {}, bodyFormData)
  ).then((res) => {
    if (res.success) {
      dispatch(StudentActions.uploadImgSuccess(res.data));
      dispatch(getStudentMe());
    } else {
      dispatch(StudentActions.uploadImgFail(res.data.response.data.message));
    }
  });
};

export const getProfileAddress = (stid, student) => async (dispatch) => {
  dispatch(StudentActions.fetchAddressRequest());
  if (!student) {
    dispatch(
      axiosReq(
        "get",
        process.env.REACT_APP_API + "/student/" + stid + "/address",
        {}
      )
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.fetchAddressSuccess(res.data));
      } else {
        dispatch(
          StudentActions.fetchAddressFail(res.data.response.data.message)
        );
      }
    });
  } else {
    dispatch(
      axiosReq("get", process.env.REACT_APP_API + "/student/address", {}, {})
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.fetchAddressSuccess(res.data));
      } else {
        dispatch(
          StudentActions.fetchAddressFail(res.data.response.data.message)
        );
      }
    });
  }
};

export const getProfileExp = (stid, student) => async (dispatch) => {
  dispatch(StudentActions.fetchExpRequest());
  if (!student) {
    dispatch(
      axiosReq(
        "get",
        process.env.REACT_APP_API + "/student/" + stid + "/experience",
        {}
      )
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.fetchExpSuccess(res.data));
      } else {
        dispatch(StudentActions.fetchExpFail(res.data.response.data.message));
      }
    });
  } else {
    dispatch(
      axiosReq("get", process.env.REACT_APP_API + "/student/experience", {}, {})
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.fetchExpSuccess(res.data));
      } else {
        dispatch(StudentActions.fetchExpFail(res.data.response.data.message));
      }
    });
  }
};

export const getProfileEmergency =
  (navigate, stid, student) => async (dispatch) => {
    dispatch(StudentActions.fetchEmgRequest());
    if (!student) {
      dispatch(
        axiosReq(
          "get",
          process.env.REACT_APP_API + "/student/" + stid + "/emergencyContact",
          {}
        )
      ).then((res) => {
        if (res.success) {
          dispatch(StudentActions.fetchEmgSuccess(res.data));
        } else {
          dispatch(StudentActions.fetchEmgFail(res.data.response.data.message));
          if (res.data.response.data.statusCode === 401) {
            navigate("/login");
          }
        }
      });
    } else {
      dispatch(
        axiosReq(
          "get",
          process.env.REACT_APP_API + "/student/emergencyContact",
          {},
          {}
        )
      ).then((res) => {
        if (res.success) {
          dispatch(StudentActions.fetchEmgSuccess(res.data));
        } else {
          dispatch(StudentActions.fetchEmgFail(res.data.response.data.message));
          if (res.data.response.data.statusCode === 401) {
            navigate("/login");
          }
        }
      });
    }
  };

export const updateUser =
  (navigate, data, setSub, sub, state) => async (dispatch) => {
    dispatch(StudentActions.editReq());
    dispatch(
      axiosReq("patch", process.env.REACT_APP_API + "/user/me", {}, data)
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.editSuccess(res.data));
        if (setSub) setSub(!sub);
        !state && dispatch(getUser());
      } else {
        dispatch(StudentActions.editFail(res.data.response.data.error.message));
      }
    });
  };

export const changePassword =
  (navigate, data, setOldPass, setNewPass, setSuccessMsg) =>
  async (dispatch) => {
    dispatch(StudentActions.ChangeReq());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/user/changePassword",
        {},
        data
      )
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.ChangeSuccess(res.data));
        setOldPass("");
        setNewPass("");
        setSuccessMsg("Password Changed Successfully!");
      } else {
        dispatch(StudentActions.ChangeFail(res.data.response.data.message));
        if (res.data.response.data.statusCode === 401) {
          navigate("/login");
        }
        setSuccessMsg("");
      }
    });
  };

export const getAllPassports = (navigate, stId) => async (dispatch) => {
  dispatch(StudentActions.fetchPassportsRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/student/passports/" + stId,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(StudentActions.fetchPassportsSuccess(res.data));
    } else {
      dispatch(
        StudentActions.fetchPassportsFail(res.data.response.data.message)
      );
      if (res.data.response.data.statusCode === 401) {
        navigate("/login");
      }
    }
  });
};

export const addPassport =
  (navigate, data, setAddPassSuccess, setAddPassportSuccessMsg) =>
  async (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.append("passport", data.passport);
    bodyFormData.append("number", data.number);
    bodyFormData.append("country", data.country);
    bodyFormData.append("nationality", data.nationality);
    bodyFormData.append("expiry", data.expiry);
    bodyFormData.append("student", data.student);

    dispatch(StudentActions.addPassportReq());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/student/passport",
        {},
        bodyFormData
      )
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.addPassportSuccess());
      } else {
        dispatch(
          StudentActions.addPassportFail(res.data.response.data.message)
        );
      }
    });
  };

export const editPassport =
  (navigate, data, setAddPassSuccess, setAddPassportSuccessMsg, id) =>
  async (dispatch) => {
    var bodyFormData = new FormData();
    data.passport && bodyFormData.append("passport", data.passport);
    data.number && bodyFormData.append("number", data.number);
    data.country && bodyFormData.append("country", data.country);
    data.nationality && bodyFormData.append("nationality", data.nationality);
    data.expiry && bodyFormData.append("expiry", data.expiry);
    data.student && bodyFormData.append("student", data.student);

    dispatch(StudentActions.editPassportReq());
    dispatch(
      axiosReq(
        "patch",
        process.env.REACT_APP_API + "/student/passport/" + id,
        {},
        bodyFormData
      )
    ).then((res) => {
      if (res.success) {
        dispatch(StudentActions.editPassportSuccess());
      } else {
        dispatch(
          StudentActions.editPassportFail(res.data.response.data.message)
        );
      }
    });
  };

export const deleteUserPassport = (navigate, id, stid) => async (dispatch) => {
  dispatch(StudentActions.deletePassportReq());
  dispatch(
    axiosReq(
      "delete",
      process.env.REACT_APP_API + "/student/passport/" + id,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(StudentActions.deletePassportSuccess(res.data));
      dispatch(getAllPassports(navigate, stid));
    } else {
      dispatch(
        StudentActions.deletePassportFail(res.data.response.data.message)
      );
    }
  });
};
