import React, { useEffect, useState } from "react";
import "./MoreDetails.scss";
import one from "../../../Shared/Assets/Images/one.png";
import two from "../../../Shared/Assets/Images/two.png";
import three from "../../../Shared/Assets/Images/three.png";
import edit from "../../../Shared/Assets/Images/edit.png";
import trash from "../../../Shared/Assets/Images/trash.png";
import Input from "../../../Shared/Components/Input/Input";
import check from "../../../Shared/Assets/Images/save.png";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import Exit from "../../../Shared/Assets/Images/Exit.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addContact,
  addExp,
  deleteContact,
  deleteExp,
  updateAddress,
  updateContact,
  updateWorkExp,
} from "../../Redux/completeProfile/CompleteProfileActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import Popup from "../../../Shared/Components/Popup/Popup";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
import Button from "../../../Shared/Components/Button/Button";
import {
  getProfileAddress,
  getProfileEmergency,
  getProfileExp,
} from "../../Redux/Student/StudentActions";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllCont,
  getCity2ByContId,
  getCityByContId,
} from "../../../Shared/Redux/Location/LocationsActions";
import { DropDownList } from "../../../Utils";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import {
  getaddressbyid,
  getexp,
  getemergencycontact,
} from "../../../Counsler/Redux/CounselorStudent/CounselorStudentActions";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
function MoreDetails({ width }) {
  const { id } = useParams();
  const [newStreetName, setNewStreetName] = useState("");
  const [addressEdit, setAddressEdit] = useState(false);
  const [newBuildingName, setNewBuildingName] = useState("");
  const [newBuildingNumber, setNewBuildingNumber] = useState();
  const [newPostal, setNewPostal] = useState();
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [workExp, setWorkExp] = useState([]);
  const [emergancyContact, setEmergancyContact] = useState([]);
  const [newAddressData, setNewAddressData] = useState({});
  const [openExpPopup, setOpenExpPopup] = useState(false);

  const [newCompanyName, setNewCompanyName] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [newCountry, setnewCountry] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newStart_date, setnewStart_date] = useState();
  const [newEnd_date, setNewEnd_date] = useState();
  const [Ongoing, setOngoing] = useState(false);
  const [newExp, setNewExp] = useState();
  const [expSuccess, setExpSuccess] = useState("");
  const [expError, setExpError] = useState("");
  const [openDeletePopup, setopenDeletePopup] = useState(false);
  const [toEditExp, setToEditExp] = useState({});
  const [deleteExppError, setDeleteExppError] = useState("");
  const [expType, setExpType] = useState("add");
  const [updateError, setUpdateError] = useState("");
  const [deletingExp, setDeletingExp] = useState(false);

  const [newContactFName, setNewContactFName] = useState("");
  const [newContactLName, setNewContactLName] = useState("");
  const [newContactEmail, setNewContactEmail] = useState("");
  const [newContactRelation, setNewContactRelation] = useState("");
  const [othersRelation, setothersRelation] = useState("");
  const [newContactNumber, setNewContactNumber] = useState("");
  const [newContactAddress, setNewContactAddress] = useState("");
  const [contactType, setContactType] = useState("add");
  const [toEditContact, setToEditContact] = useState({});
  const [finalContact, setFinalContact] = useState({});
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [sameAs, setSameAs] = useState(false);
  // const countries = [
  //   { value: "Beirut", label: "Beirut" },
  //   { value: "Paris", label: "Paris" },
  //   { value: "Berlin", label: "Berlin" },
  // ];

  const [countries, setCountries] = useState([]);

  const { Countries, Cities } = useSelector((state) => state.LocationReducer);
  useEffect(() => {
    if (Countries.length == 0) dispatch(getAllCont());
  }, []);

  useEffect(() => {
    setCountries(DropDownList(Countries, "id", "name"));
  }, [Countries]);

  // const cities = [
  //   { value: "Beirut, Lebanon", label: "Beirut, Lebanon" },
  //   { value: "Paris, France", label: "Paris, France" },
  //   { value: "Berlin, Germany", label: "Berlin, Germany" },
  // ];

  useEffect(() => {
    if (country) dispatch(getCityByContId(country.value));
  }, [country]);
  useEffect(() => {
    if (newCountry) {
      dispatch(getCity2ByContId(newCountry.value));
    }
  }, [newCountry]);

  useEffect(() => {
    // if (
    //   (Cities.length > 0 && data?.country != country?.label) ||
    //   data?.city !== city?.label
    // ) {
    //   setCity({ label: Cities[0]?.name, value: Cities[0]?.name });
    // } else {
    //   setCity({ label: data?.city, value: data?.city });
    // }
    // setCities(DropDownList(Cities, "name", "name"));

    setCity(data?.city);
  }, [Cities, country]);

  const relations = [
    { value: "Mother", label: "Mother" },
    { value: "Father", label: "Father" },
    { value: "Other", label: "Other" },
  ];

  const data = useSelector((state) => state.StudentReducer.address[0]);
  const dataExp = useSelector((state) => state.StudentReducer.exp);
  const dataEmergency = useSelector((state) => state.StudentReducer.emergency);
  const role = useSelector((state) => state.userReducer.role);

  useEffect(() => {
    if (newContactEmail == "") {
      setNewContactEmail(null);
    }
  }, [newContactEmail]);

  useEffect(() => {
    setFinalContact({
      ...(newContactFName !== "" &&
        newContactFName !== toEditContact.first_name && {
          first_name: newContactFName,
        }),
      ...(newContactLName !== "" &&
        newContactLName !== toEditContact.last_name && {
          last_name: newContactLName,
        }),
      ...(newContactEmail !== toEditContact.email && {
        email: newContactEmail,
      }),
      ...(newContactRelation !== "" &&
        newContactRelation.value !== toEditContact.relationship && {
          relationship: newContactRelation.value,
        }),
      ...(newContactNumber !== "" &&
        newContactNumber !== toEditContact.phone && {
          phone_number: newContactNumber,
        }),
      ...(newContactAddress !== "" &&
        !sameAs &&
        newContactAddress !== toEditContact.address && {
          address: newContactAddress,
        }),
      ...{ sameAddress: sameAs },
    });
  }, [
    newContactFName,
    newContactLName,
    newContactEmail,
    newContactRelation,
    newContactNumber,
    newContactAddress,
    othersRelation,
    sameAs,
  ]);

  const {
    updateAddressLoading,
    updateExpLoading,
    updateContactLoading,
    updateAddressError,
    updateExpError,
    updateContactError,
    updateAddressSuccess,
    updateExpSuccess,
    updateContactSuccess,
    addAddressLoading,
    addAddressError,
    addAddressSuccess,
    addExperienceLoading,
    addExperienceError,
    addExperienceSuccess,
    addContactLoading,
    addContactError,
    addContactSuccess,
    deleteExpLoading,
    deleteExpError,
    deleteExpSuccess,
    deleteContactLoading,
    deleteContactError,
    deleteContactSuccess,
  } = useSelector((state) => state.completeProfileReducer);

  useEffect(() => {
    if (updateExpError) setUpdateError(updateExpError);
  }, [updateExpError]);

  useEffect(() => {
    if (updateExpSuccess) {
      setUpdateError("");
      setOpenExpPopup(false);
    }
  }, [updateExpSuccess]);

  useEffect(() => {
    if (role !== "student") {
      dispatch(getProfileAddress(id, false));
      dispatch(getProfileEmergency(navigate, id, false));
      dispatch(getProfileExp(id, false));
    }
  }, [role]);
  useEffect(() => {
    if (addExperienceSuccess) {
      {
        role === "student"
          ? dispatch(getProfileExp("", true))
          : dispatch(getProfileExp(id, false));
      }
      setExpSuccess("Experience added successfully!");
      setTimeout(function () {
        setExpSuccess("");
        setOpenExpPopup(false);
      }, 2000);
      setNewCompanyName("");
      setNewPosition("");
      setnewCountry("");
      setNewLocation("");
      setnewStart_date(null);
      setNewEnd_date(null);
      setExpError("");
    }
  }, [addExperienceSuccess]);

  useEffect(() => {
    if (addExperienceError) {
      setExpError(addExperienceError);
    }
  }, [addExperienceError]);

  useEffect(() => {
    if (dataExp) {
      setWorkExp(dataExp);
    }
  }, [dataExp]);

  useEffect(() => {
    if (dataEmergency) {
      setEmergancyContact(dataEmergency);
    }
  }, [dataEmergency]);

  useEffect(() => {
    if (data) {
      setNewStreetName(data.street);
      setNewBuildingName(data.building_name);
      setNewBuildingNumber(data.building_number);
      setNewPostal(data.postal_code);
      setCity(data.city);
      setCountry({
        label: data?.country,
        value: Countries.find((item) => item.name === data.country)?.id,
      });

      setSameAs(data.sameAddress);
    }
  }, [data]);

  useEffect(() => {
    if (Ongoing) {
      setNewEnd_date("");
    }
  }, [Ongoing]);

  useEffect(() => {
    setNewAddressData({
      ...(newStreetName !== data?.street &&
        newStreetName !== "" && { street: newStreetName }),
      ...(newBuildingName !== data?.building_name &&
        newBuildingName !== "" && { building_name: newBuildingName }),
      ...(newBuildingNumber !== data?.building_number &&
        newBuildingNumber !== "" && { building_number: newBuildingNumber }),
      ...(newPostal !== data?.postal_code &&
        newPostal !== "" && { postal_code: newPostal }),
      ...(city !== data?.city && city !== "" && { city: city }),
      ...(country?.label !== data?.country &&
        country?.label !== "" && { country: country?.label }),
      ...(sameAs && { sameAddress: sameAs }),
    });
  }, [
    newStreetName,
    newBuildingName,
    newBuildingNumber,
    newPostal,
    city,
    country,
  ]);

  useEffect(() => {
    setNewExp({
      ...(newCompanyName !== "" && { company: newCompanyName }),
      ...(newPosition !== "" && { position: newPosition }),
      ...(newCountry !== "" && { country: newCountry.label }),
      ...(newLocation !== "" && { city: newLocation }),
      ...(newStart_date !== null && { start_date: newStart_date }),
      ...(!Ongoing
        ? newEnd_date !== null && { end_date: newEnd_date }
        : { end_date: "Ongoing" }),
      ...(role !== "student" && { student: id }),
    });
  }, [
    newCompanyName,
    newCountry,
    newLocation,
    newPosition,
    newStart_date,
    newEnd_date,
    Ongoing,
  ]);
  const [stet, setstet] = useState();
  const handleAddExp = () => {
    if (
      newCompanyName === "" ||
      newPosition === "" ||
      newCountry === "" ||
      newLocation === "" ||
      newStart_date === null ||
      newEnd_date === null
    ) {
      alert("please fill all fields");
    } else {
      if (role !== "student") {
        dispatch(addExp([newExp], setstet, setOpenExpPopup, id));
      } else {
        dispatch(addExp([newExp]));
      }
    }
  };

  const dispatch = useDispatch();

  const Screenwidth = UseInnerWidth();

  const handleExpDelete = () => {
    if (role === "student") {
      dispatch(deleteExp(toEditExp.id, "", true));
    } else {
      if (id) dispatch(deleteExp(toEditExp.id, id, false));
    }
  };

  const handleEditExp = () => {
    if (
      (newCompanyName !== "" &&
        newPosition !== "" &&
        newCountry !== "" &&
        newLocation !== "" &&
        newStart_date !== null &&
        newEnd_date !== null &&
        newCompanyName !== toEditExp.company) ||
      newPosition !== toEditExp.position ||
      newCountry.label !== toEditExp.country ||
      newLocation !== toEditExp.city ||
      newStart_date !== toEditExp.start_date ||
      newEnd_date !== toEditExp.end_date
    ) {
      if (role !== "student") {
        dispatch(
          updateWorkExp(
            {
              ...(newCompanyName !== toEditExp.company && {
                company: newCompanyName,
              }),
              ...(newPosition !== toEditExp.position && {
                position: newPosition,
              }),
              ...(newCountry.label !== toEditExp.country && {
                country: newCountry.label,
              }),
              ...(newLocation !== toEditExp.city && {
                city: newLocation,
              }),
              ...(newStart_date !== toEditExp.start_date && {
                start_date: newStart_date,
              }),
              ...(Ongoing
                ? { end_date: "Ongoing" }
                : newEnd_date !== toEditExp.end_date && {
                    end_date: newEnd_date,
                  }),
            },
            toEditExp.id,
            id
          )
        );
      } else {
        dispatch(
          updateWorkExp(
            {
              ...(newCompanyName !== toEditExp.company && {
                company: newCompanyName,
              }),
              ...(newPosition !== toEditExp.position && {
                position: newPosition,
              }),
              ...(newCountry.label !== toEditExp.country && {
                country: newCountry.label,
              }),
              ...(newLocation !== toEditExp.city && {
                city: newLocation,
              }),
              ...(newStart_date !== toEditExp.start_date && {
                start_date: newStart_date,
              }),
              ...(Ongoing
                ? { end_date: "Ongoing" }
                : newEnd_date !== toEditExp.end_date && {
                    end_date: newEnd_date,
                  }),
            },
            toEditExp.id
          )
        );
      }
    }
  };

  useEffect(() => {
    if (deleteExpSuccess) {
      setopenDeletePopup(false);
    }
  }, [deleteExpSuccess]);

  useEffect(() => {
    if (deleteExpError !== "") {
      setDeleteExppError(deleteExpError);
    }
  }, [deleteExpError]);

  useEffect(() => {
    if (!openDeletePopup) {
      setDeleteExppError("");
    }
  }, [openDeletePopup]);

  useEffect(() => {
    if (expType === "add") {
      setNewCompanyName("");
      setNewPosition("");
      setnewCountry("");
      setNewLocation("");
      setnewStart_date("");
      setNewEnd_date("");
      setOngoing(false);
    } else {
      setNewCompanyName(toEditExp.company);
      setNewPosition(toEditExp.position);
      setnewCountry({ label: toEditExp.country, value: toEditExp.country });
      setNewLocation(toEditExp.city);
      setnewStart_date(toEditExp.start_date);
      if (toEditExp.end_date === "Ongoing") {
        setNewEnd_date("");
        setOngoing(true);
      } else {
        setNewEnd_date(toEditExp.end_date);
        setOngoing(false);
      }
    }
  }, [expType, toEditExp]);

  const navigate = useNavigate();

  const handleAddContact = () => {
    if (role === "student") {
      dispatch(
        addContact(navigate, [
          {
            ...(newContactFName !== "" && { first_name: newContactFName }),
            ...(newContactLName !== "" && { last_name: newContactLName }),
            ...(newContactEmail !== "" && { email: newContactEmail }),
            ...(newContactNumber !== "" && { phone: newContactNumber }),
            ...(newContactRelation !== "" &&
              newContactRelation.label !== "Other" && {
                relationship: newContactRelation.value,
              }),
            ...(newContactRelation.label === "Other" &&
              othersRelation !== "" && {
                relationship: othersRelation,
              }),
            ...(newContactAddress !== "" &&
              !sameAs && { address: newContactAddress }),
            ...(sameAs && { sameAddress: sameAs }),
          },
        ])
      );
    } else {
      dispatch(
        addContact(
          navigate,
          [
            {
              ...(newContactFName !== "" && { first_name: newContactFName }),
              ...(newContactLName !== "" && { last_name: newContactLName }),
              ...(newContactEmail !== "" && { email: newContactEmail }),
              ...(newContactNumber !== "" && { phone: newContactNumber }),
              ...(newContactRelation !== "" &&
                newContactRelation.label !== "Other" && {
                  relationship: newContactRelation.value,
                }),
              ...(newContactRelation.label === "Other" && {
                relationship: othersRelation,
              }),
              ...(newContactAddress !== "" &&
                !sameAs && { address: newContactAddress }),
              ...(sameAs && { sameAddress: sameAs }),

              student: id,
            },
          ],
          id,
          role === "counselor" ? true : false
        )
      );
    }
  };

  const handleEditContact = () => {
    if (Object.keys(finalContact).length !== 0)
      if (role === "student") {
        dispatch(updateContact(finalContact, toEditContact.id));
      } else {
        dispatch(updateContact(finalContact, toEditContact.id, id, navigate));
        setOpenContactPopup(false);
      }
  };

  useEffect(() => {
    if (addContactSuccess) {
      setNewContactFName("");
      setNewContactLName("");
      setNewContactEmail("");
      setNewContactNumber("");
      setNewContactRelation("");
      setothersRelation("");
      setNewContactAddress("");
      setOpenContactPopup(false);
    }
  }, [addContactSuccess]);

  useEffect(() => {
    if (contactType === "add") {
      setNewContactFName("");
      setNewContactLName("");
      setNewContactEmail("");
      setNewContactNumber("");
      setNewContactRelation("");
      setNewContactAddress("");
      setothersRelation("");
    } else {
      setNewContactFName(toEditContact.first_name);
      setNewContactLName(toEditContact.last_name);
      setNewContactEmail(toEditContact.email);
      setNewContactNumber(toEditContact.phone);

      setSameAs(toEditContact.sameAddress);

      if (
        toEditContact.relationship !== "Mother" &&
        toEditContact.relationship !== "Father"
      ) {
        setNewContactRelation({
          label: "Other",
          value: "Other",
        });
        setothersRelation(toEditContact.relationship);
      } else {
        setNewContactRelation({
          label: toEditContact.relationship,
          value: toEditContact.relationship,
        });
        setothersRelation("");
      }
      setNewContactAddress(toEditContact.address);
    }
  }, [contactType, toEditContact]);

  const handleContactDelete = () => {
    if (role === "student") {
      dispatch(deleteContact(toEditContact.id));
    } else {
      dispatch(deleteContact(toEditContact.id, id, navigate));
    }
    setopenDeletePopup(false);
  };

  const [popup, setpopup] = useState(false);

  return (
    <>
      {updateAddressLoading ||
      updateExpLoading ||
      updateContactLoading ||
      addExperienceLoading ||
      addContactLoading ||
      deleteContactLoading ||
      deleteExpLoading ? (
        <Loader fullScreen={true} />
      ) : (
        <div className="compelet_profile">
          {/* {popup && (
            <UpperPopUp
              setShow={setpopup}
              title="Please click on the pen to edit info"
            />
          )} */}
          <div className="compelet_profile_inner">
            <span className="complete_profile_text">More Details</span>
          </div>
          {addressEdit && (
            <Popup
              setShowPopup={() => {}}
              body={
                <div>
                  <div style={{ fontSize: "2.5rem", fontFamily: "P_Bold" }}>
                    Edit Home Address
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    <div className="profile_input_top popup_input_address">
                      <div className="input_title">Street Name</div>
                      <Input
                        value={newStreetName}
                        onChange={(e) => setNewStreetName(e.target.value)}
                        placeholder="e.g:Naccache, Al Matn"
                        type="text"
                        width={"100%"}
                        disabled={!addressEdit}
                      />
                    </div>
                    <div className="profile_input_top popup_input_address">
                      <div className="input_title">Building Name</div>
                      <Input
                        value={newBuildingName}
                        onChange={(e) => setNewBuildingName(e.target.value)}
                        placeholder="e.g:building"
                        type="email"
                        width={"100%"}
                        disabled={!addressEdit}
                      />
                    </div>
                    <div className="profile_input_top popup_input_address">
                      <div className="input_title">Building number</div>
                      <Input
                        value={newBuildingNumber}
                        onChange={(e) => setNewBuildingNumber(e.target.value)}
                        placeholder="e.g:122"
                        type="number"
                        width={"100%"}
                        disabled={!addressEdit}
                      />
                    </div>
                    <div className="profile_input_top popup_input_address">
                      <div className="input_title">Country</div>
                      {countries && countries.length > 0 && (
                        <DropDown
                          placeholder="Country"
                          setSelected={setCountry}
                          data={countries}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={country}
                          keepPlaceHolder={false}
                          isDisabled={!addressEdit}
                          value={country}
                        />
                      )}
                      {!countries && countries.length == 0 && (
                        <DropDown
                          placeholder="Country"
                          setSelected={setCountry}
                          data={[]}
                          blueArrow={false}
                          width={"100%"}
                          multiSelection={false}
                          searchable={false}
                          selectedData={country}
                          keepPlaceHolder={false}
                          isDisabled={!addressEdit}
                          value={country}
                        />
                      )}
                    </div>
                    <div className="profile_input_top popup_input_address">
                      <div className="input_title">City</div>
                      {/* // <DropDown
                //   placeholder="City"
                //   setSelected={setCity}
                //   data={cities}
                //   blueArrow={false}
                //   width={"100%"}
                //   multiSelection={false}
                //   searchable={false}
                //   selectedData={city}
                //   keepPlaceHolder={false}
                //   isDisabled={!addressEdit}
                //   value={city}
                // /> */}
                      <Input
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="City"
                        type="text"
                        width={"100%"}
                        disabled={!addressEdit}
                      />
                    </div>
                    <div className="profile_input_top popup_input_address">
                      <div className="input_title">Postal Code</div>
                      <Input
                        value={newPostal}
                        onChange={(e) => setNewPostal(e.target.value)}
                        placeholder="e.g:1001"
                        type="number"
                        width={"100%"}
                        disabled={!addressEdit}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "2rem" }}>
                    <Button
                      color="yellow"
                      width={"100%"}
                      text={"Submit"}
                      onClick={() =>
                        dispatch(updateAddress(newAddressData, data.id, id))
                      }
                    />
                  </div>
                </div>
              }
            />
          )}
          <div className="home_address">
            <img src={one} alt="one" className="one_img" />
            <span className="home_address_title">Home Address</span>
            {!addressEdit ? (
              <img
                src={edit}
                alt="edit"
                className="edit_img"
                onClick={() => setAddressEdit(true)}
              />
            ) : (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAddressEdit(false);
                }}
              >
                <img src={check} alt="edit pen" className="edit_save" />
              </span>
            )}
          </div>
          {updateAddressError !== "" && (
            <div
              style={{
                width: "90%",
                margin: "-2rem auto 1rem auto",
                color: "var(--white)",
                fontFamily: "P_Semibold",
                fontSize: "1.2rem",
              }}
            >
              {updateAddressError}
            </div>
          )}
          <div
            className="home_address_inputs"
            onClick={!addressEdit ? () => setpopup(true) : null}
          >
            <div className="profile_input_top">
              <div className="input_title">Street Name</div>
              <Input
                value={newStreetName}
                onChange={(e) => setNewStreetName(e.target.value)}
                placeholder="e.g:Naccache, Al Matn"
                type="text"
                width={"100%"}
                disabled={!addressEdit}
              />
            </div>
            <div className="profile_input_top">
              <div className="input_title">Building Name</div>
              <Input
                value={newBuildingName}
                onChange={(e) => setNewBuildingName(e.target.value)}
                placeholder="e.g:building"
                type="email"
                width={"100%"}
                disabled={!addressEdit}
              />
            </div>
            <div className="profile_input_top">
              <div className="input_title">Building number</div>
              <Input
                value={newBuildingNumber}
                onChange={(e) => setNewBuildingNumber(e.target.value)}
                placeholder="e.g:122"
                type="number"
                width={"100%"}
                disabled={!addressEdit}
              />
            </div>
            <div className="profile_input_top">
              <div className="input_title">Country</div>
              {countries && countries.length > 0 && (
                <DropDown
                  placeholder="Country"
                  setSelected={setCountry}
                  data={countries}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={country}
                  keepPlaceHolder={false}
                  isDisabled={!addressEdit}
                  value={country}
                />
              )}
              {!countries && countries.length == 0 && (
                <DropDown
                  placeholder="Country"
                  setSelected={setCountry}
                  data={[]}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={country}
                  keepPlaceHolder={false}
                  isDisabled={!addressEdit}
                  value={country}
                />
              )}
            </div>
            <div className="profile_input_top">
              <div className="input_title">City</div>
              {/* // <DropDown
                //   placeholder="City"
                //   setSelected={setCity}
                //   data={cities}
                //   blueArrow={false}
                //   width={"100%"}
                //   multiSelection={false}
                //   searchable={false}
                //   selectedData={city}
                //   keepPlaceHolder={false}
                //   isDisabled={!addressEdit}
                //   value={city}
                // /> */}
              <Input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
                type="text"
                width={"100%"}
                disabled={!addressEdit}
              />
            </div>
            <div className="profile_input_top">
              <div className="input_title">Postal Code</div>
              <Input
                value={newPostal}
                onChange={(e) => setNewPostal(e.target.value)}
                placeholder="e.g:1001"
                type="number"
                width={"100%"}
                disabled={!addressEdit}
              />
            </div>
          </div>
          <div className="more_details_bottom">
            {workExp.length > 0 ? (
              <div className="more_details_2">
                <div className="more_details_2_title">
                  <img src={two} alt="step 2" className="two_img" />
                  <span className="more_details_2_title_text">
                    Work Experience
                  </span>
                  <img
                    src={Exit}
                    alt="add"
                    className="add_pen"
                    onClick={() => {
                      setOpenExpPopup(true);
                      setExpType("add");
                    }}
                  />
                </div>
                {width > 1000 ? (
                  <div className="more_details_2_body">
                    <div className="more_details_2_body_inner">
                      {workExp.map((item, index) => {
                        return (
                          <div
                            className={
                              index === workExp.length - 1 ? "row last" : "row"
                            }
                            key={index}
                          >
                            <div className="start">{item.company}</div>
                            <div className="middle">{item.position}</div>
                            <div className="middle">{item.country}</div>
                            <div className="last">
                              {item.start_date.split("-")[2] +
                                " - " +
                                (item.end_date == "Ongoing"
                                  ? "Present"
                                  : item.end_date.split("-")[2])}
                            </div>
                            <div className="actions">
                              <img
                                src={edit}
                                alt="edit"
                                className="edit_pen"
                                onClick={() => {
                                  setOpenExpPopup(true);
                                  setExpType("edit");
                                  setToEditExp(item);
                                }}
                              />
                              <img
                                src={trash}
                                alt="edit"
                                className="edit_pen"
                                onClick={() => {
                                  setDeletingExp(true);
                                  setToEditExp(item);
                                  setopenDeletePopup(true);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="more_details_2_body_mobile">
                    <div className="more_details_2_body_inner">
                      {workExp.map((item, index) => {
                        return (
                          <div
                            className={
                              index === workExp.length - 1 ? "row last" : "row"
                            }
                            key={index}
                          >
                            <div className="row_top">
                              <div className="top_data">
                                <div className="start">{item.company}</div>
                                <div className="last">{item.position}</div>
                              </div>
                              <div className="bottom_data">
                                <div className="start">{item.country}</div>
                                <div className="last">
                                  {" "}
                                  {item.start_date.split("-")[2] +
                                    " - " +
                                    item.end_date.split("-")[2]}
                                </div>
                              </div>
                            </div>
                            <hr className="horizontal_line" />
                            <div className="actions">
                              <img
                                src={edit}
                                className="edit_pen"
                                alt="edit"
                                onClick={() => {
                                  setExpType("edit");
                                  setToEditExp(item);
                                  setOpenExpPopup(true);
                                }}
                              />
                              <img
                                src={trash}
                                className="edit_pen"
                                alt="edit"
                                onClick={() => {
                                  setDeletingExp(true);
                                  setToEditExp(item);
                                  setopenDeletePopup(true);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="more_details_2">
                <div className="more_details_2_title">
                  <img src={two} alt="step 2" className="two_img" />
                  <span className="more_details_2_title_text">
                    Work Experience
                  </span>
                  <img
                    src={Exit}
                    alt="add"
                    className="add_pen"
                    onClick={() => setOpenExpPopup(true)}
                  />
                </div>
                <div className="more_details_2_body centered_Text">
                  No experience added
                </div>
              </div>
            )}
            <div className="more_details_3">
              <div className="more_details_3_title">
                <img src={three} alt="step 3" className="three_img" />
                <span className="more_details_3_title_text">
                  Emergency Contact
                </span>
                <img
                  src={Exit}
                  alt="add"
                  className="add_pen"
                  onClick={() => {
                    setOpenContactPopup(true);
                    setContactType("add");
                    setDeletingExp(true);
                  }}
                />
              </div>
              {width > 1000 ? (
                <div className="more_details_3_body">
                  <div className="more_details_3_body_inner">
                    {emergancyContact.map((item, index) => {
                      return (
                        <div
                          className={
                            index === emergancyContact.length - 1
                              ? "row last"
                              : "row"
                          }
                          key={index}
                        >
                          <div className="start">
                            {item.first_name} {item.last_name}
                          </div>
                          <div className="middle">{item.relationship}</div>
                          <div className="last">{item.address}</div>
                          <div className="actions">
                            <img
                              src={edit}
                              alt="edit"
                              className="edit_pen"
                              onClick={() => {
                                setToEditContact(item);
                                setOpenContactPopup(true);
                                setContactType("edit");
                                setDeletingExp(true);
                              }}
                            />
                            {emergancyContact.length > 1 && (
                              <img
                                src={trash}
                                alt="edit"
                                className="edit_pen"
                                onClick={() => {
                                  setToEditContact(item);
                                  setDeletingExp(false);
                                  setopenDeletePopup(true);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="more_details_3_body_mobile">
                  <div className="more_details_3_body_inner">
                    {emergancyContact.map((item, index) => {
                      return (
                        <div
                          className={
                            index === emergancyContact.length - 1
                              ? "row last"
                              : "row"
                          }
                          key={index}
                        >
                          <div className="row_top">
                            <div className="top_data_3">
                              <div className="start">
                                {item.first_name} {item.last_name}
                              </div>
                            </div>
                            <div className="bottom_data_3">
                              <div className="start">{item.relationship}</div>
                              <div className="last">{item.address}</div>
                            </div>
                          </div>
                          <hr className="horizontal_line" />
                          <div className="actions">
                            <img
                              src={edit}
                              alt="edit"
                              className="edit_pen"
                              onClick={() => {
                                setToEditContact(item);
                                setOpenContactPopup(true);
                                setContactType("edit");
                              }}
                            />
                            {emergancyContact.length > 1 && (
                              <img
                                src={trash}
                                alt="edit"
                                className="edit_pen"
                                onClick={() => {
                                  setToEditContact(item);
                                  setDeletingExp(false);
                                  setopenDeletePopup(true);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {openExpPopup && (
        <Popup
          setShowPopup={setOpenExpPopup}
          showClose={Screenwidth < 768 && true}
          body={
            <div className="add_exp_popup">
              <div className="add_exp_popup_title">
                {expType == "add" ? "Add" : "Edit"} Work Experience
              </div>
              <div className="add_exp_popup_body">
                <div className="add_exp_popup_body_row">
                  <div className="add_exp_popup_body_row_single">
                    <div className="add_exp_popup_body_row_single_title">
                      Company
                    </div>
                    <Input
                      placeholder={"Company Name"}
                      type="text"
                      width={"100%"}
                      disabled={false}
                      value={newCompanyName}
                      onChange={(e) => setNewCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="add_exp_popup_body_row_single">
                    <div className="add_exp_popup_body_row_single_title">
                      Position
                    </div>
                    <Input
                      placeholder={"Position"}
                      type="text"
                      width={"100%"}
                      disabled={false}
                      value={newPosition}
                      onChange={(e) => setNewPosition(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add_exp_popup_body_row">
                  <div className="add_exp_popup_body_row_single">
                    <div className="add_exp_popup_body_row_single_title">
                      Country
                    </div>

                    <DropDown
                      placeholder="Country"
                      setSelected={setnewCountry}
                      data={countries}
                      blueArrow={false}
                      width={"100%"}
                      multiSelection={false}
                      searchable={false}
                      selectedData={newCountry}
                      keepPlaceHolder={false}
                      isDisabled={false}
                      value={newCountry}
                    />
                  </div>
                  <div className="add_exp_popup_body_row_single">
                    <div className="add_exp_popup_body_row_single_title">
                      City
                    </div>

                    <Input
                      placeholder={"City"}
                      type="text"
                      width={"100%"}
                      disabled={false}
                      value={newLocation}
                      onChange={(e) => setNewLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add_exp_popup_body_row">
                  <div className="add_exp_popup_body_row_single">
                    <div
                      className="add_exp_popup_body_row_single_title"
                      style={{ paddingBottom: "1rem" }}
                    >
                      Start Date
                    </div>
                    <DateInput
                      width={"100%"}
                      setSelectedDate={setnewStart_date}
                      selectedDate={newStart_date}
                    />
                  </div>
                  <div className="add_exp_popup_body_row_single">
                    <div className="add_exp_popup_body_row_single">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1rem",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        <div className="add_exp_popup_body_row_single_title">
                          End Date
                        </div>
                        <Checkbox
                          label="Ongoing"
                          setSelected={setOngoing}
                          selected={Ongoing}
                        />
                      </div>
                      <DateInput
                        width={"100%"}
                        setSelectedDate={setNewEnd_date}
                        selectedDate={newEnd_date}
                        minDate={new Date(newStart_date)}
                        disabled={
                          newStart_date === "" || Ongoing === true
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Success_error">
                {expType === "add" && expSuccess && (
                  <div className="success">{expSuccess}</div>
                )}
                {expType === "add" && expError && (
                  <div className="Error">{expError}</div>
                )}
                {expType === "edit" && updateError && (
                  <div className="Error">{updateError}</div>
                )}
              </div>
              <div className="add_exp_popup_bottom">
                <Button
                  text={
                    updateExpLoading || addExperienceLoading ? (
                      <Loader onlyDots={true} />
                    ) : (
                      "Save"
                    )
                  }
                  color={"yellow"}
                  width={"100%"}
                  disabled={
                    updateExpLoading || addExperienceLoading
                      ? true
                      : newCompanyName !== "" &&
                        newPosition !== "" &&
                        newCountry !== "" &&
                        newLocation !== "" &&
                        newStart_date !== "" &&
                        (newEnd_date !== "" || Ongoing === true)
                      ? false
                      : true
                  }
                  onClick={() => {
                    expType === "add" ? handleAddExp() : handleEditExp();
                  }}
                />
              </div>
            </div>
          }
        />
      )}
      {openDeletePopup && (
        <Popup
          setShowPopup={setopenDeletePopup}
          showClose={Screenwidth < 768 && true}
          body={
            <div className="delete_popup">
              <div className="delete_popup_title">
                Are you sure you want to delete this?
              </div>
              <div className="error">
                {deleteExppError && deletingExp && (
                  <div className="Error">{deleteExppError}</div>
                )}
                {deleteContactError && !deletingExp && (
                  <div className="Error">{deleteContactError}</div>
                )}
              </div>
              {deleteExpLoading || deleteContactLoading ? (
                <Loader onlyDots={true} />
              ) : (
                <div className="delete_popup_bottom">
                  <Button
                    text="Yes"
                    color={"yellow"}
                    width={"100%"}
                    onClick={() => {
                      deletingExp ? handleExpDelete() : handleContactDelete();
                    }}
                  />
                  <Button
                    text="Cancel"
                    color={"pink"}
                    width={"100%"}
                    onClick={() => {
                      setopenDeletePopup(false);
                    }}
                  />
                </div>
              )}
            </div>
          }
        />
      )}
      {openContactPopup && (
        <Popup
          setShowPopup={setOpenContactPopup}
          showClose={Screenwidth < 768 && true}
          body={
            <div className="contact_popup_main">
              <div className="contact_popup_main_title">
                {contactType == "add" ? "Add" : "Edit"} Contact
              </div>
              <div className="contact_popup_main_body">
                <div className="contact_popup_main_body_row">
                  <div className="contact_popup_main_body_row_single">
                    <div className="contact_popup_main_body_row_single_title">
                      First Name
                    </div>
                    <Input
                      placeholder={"First Name"}
                      type="text"
                      width={"100%"}
                      disabled={false}
                      value={newContactFName}
                      onChange={(e) => setNewContactFName(e.target.value)}
                    />
                  </div>
                  <div className="contact_popup_main_body_row_single">
                    <div className="contact_popup_main_body_row_single_title">
                      Last Name
                    </div>
                    <Input
                      placeholder={"Last Name"}
                      type="text"
                      width={"100%"}
                      disabled={false}
                      value={newContactLName}
                      onChange={(e) => setNewContactLName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="contact_popup_main_body_row">
                  <div className="contact_popup_main_body_row_single">
                    <div className="contact_popup_main_body_row_single_title">
                      Email
                      <span className="optional">Optional</span>
                    </div>
                    <Input
                      placeholder={"Email"}
                      type="email"
                      width={"100%"}
                      disabled={false}
                      value={newContactEmail}
                      onChange={(e) => setNewContactEmail(e.target.value)}
                    />
                  </div>
                  <div className="contact_popup_main_body_row_single ">
                    <div className="contact_popup_main_body_row_single_title">
                      Relationship
                    </div>
                    <div className="others_div">
                      <DropDown
                        placeholder="Relationship"
                        setSelected={setNewContactRelation}
                        data={relations}
                        blueArrow={false}
                        width={
                          newContactRelation.label == "Other" ? "50%" : "100%"
                        }
                        multiSelection={false}
                        searchable={false}
                        selectedData={newContactRelation}
                        keepPlaceHolder={false}
                        isDisabled={false}
                        value={newContactRelation}
                      />
                      {newContactRelation.label == "Other" && (
                        <Input
                          placeholder={"Ex : Uncle"}
                          type="text"
                          width={"50%"}
                          disabled={false}
                          value={othersRelation}
                          onChange={(e) => setothersRelation(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="contact_popup_main_body_row">
                  <div className="contact_popup_main_body_row_single">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1rem",
                        paddingBottom: "1rem",
                      }}
                    >
                      <div className="contact_popup_main_body_row_single_title">
                        Phone
                      </div>
                    </div>
                    <MobileInput
                      width={"100%"}
                      search={true}
                      required={true}
                      disabled={false}
                      setPhone={setNewContactNumber}
                      phone={newContactNumber}
                    />
                  </div>
                  <div className="contact_popup_main_body_row_single">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1rem",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <div
                        className="contact_popup_main_body_row_single_title"
                        style={{ width: "fit-content" }}
                      >
                        Address
                      </div>
                      <Checkbox
                        label={"Same as mine"}
                        selected={sameAs}
                        setSelected={setSameAs}
                      />
                    </div>
                    <Input
                      placeholder={"Address"}
                      type="text"
                      width={"100%"}
                      disabled={sameAs}
                      value={newContactAddress}
                      onChange={(e) => setNewContactAddress(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {addContactError !== "" && contactType == "add" && (
                <div
                  style={{
                    color: "var(--pink)",
                    margin: "1rem 0 -1rem 0",
                    fontFamily: "P_Semibold",
                  }}
                >
                  {addContactError}
                </div>
              )}
              {updateContactError !== "" && contactType == "edit" && (
                <div
                  style={{
                    color: "var(--pink)",
                    margin: "1rem 0 -1rem 0",
                    fontFamily: "P_Semibold",
                  }}
                >
                  {updateContactError}
                </div>
              )}

              <div className="contact_popup_main_bottom">
                <Button
                  text={
                    updateContactLoading || addContactLoading ? (
                      <Loader onlyDots={true} />
                    ) : (
                      "Save"
                    )
                  }
                  color={"yellow"}
                  width={"100%"}
                  disabled={
                    updateContactLoading || addContactLoading
                      ? true
                      : contactType == "add"
                      ? newContactFName === "" ||
                        newContactLName === "" ||
                        newContactNumber === "" ||
                        newContactRelation === "" ||
                        (!sameAs && newContactAddress === "") ||
                        (newContactRelation?.value == "Other" &&
                          othersRelation === "")
                        ? true
                        : false
                      : newContactFName === "" ||
                        newContactLName === "" ||
                        newContactNumber === "" ||
                        newContactRelation === "" ||
                        (!sameAs && newContactAddress === "") ||
                        (newContactRelation?.value == "Other" &&
                          othersRelation === "") ||
                        (newContactFName === toEditContact.first_name &&
                          newContactLName === toEditContact.last_name &&
                          newContactNumber === toEditContact.phone &&
                          (toEditContact.relationship == "Mother" ||
                          toEditContact.relationship == "Father"
                            ? newContactRelation?.value ===
                              toEditContact.relationship
                            : othersRelation?.value ===
                              toEditContact.relationship) &&
                          newContactAddress === toEditContact.address &&
                          newContactEmail === toEditContact.email &&
                          sameAs == toEditContact.sameAddress)
                      ? true
                      : false
                  }
                  onClick={() => {
                    contactType == "add"
                      ? handleAddContact()
                      : handleEditContact();
                  }}
                />
              </div>
            </div>
          }
        />
      )}
    </>
  );
}

export default MoreDetails;
