import React, { useEffect, useRef, useState } from "react";
import "./DropDown.scss";

import Select, { components, DropdownIndicatorProps } from "react-select";
import { CustomDropdownIndicator } from "./CustomIndicator";
import { CustomDropdownIndicator2 } from "./CustomIndicator2";
import close from "../../Assets/Images/close.webp";

function DropDown({
  title,
  placeholder,
  setSelected,
  data,
  isClearable,
  blueArrow,
  width,
  multiSelection,
  keepPlaceHolder,
  searchable,
  selectedData,
  isDisabled,
  value,
  isMulti,
}) {
  const [options, setoptions] = useState(data);

  const removeElm = (index, item) => {
    const newfields = [...selectedData];
    newfields.splice(index, 1);
    setSelected(newfields);
    const d = options;
    d.push(item);
    setoptions(d);
  };

  const setSelectedElement = (event) => {
    if (multiSelection) {
      setoptions(options.filter((i) => i.value != event.value));

      var found = false;
      if (selectedData?.length > 0)
        for (var i = 0; i < selectedData.length; i++) {
          if (event.value == selectedData[i].value) {
            found = true;
          }
        }
      if (!found) setSelected((e) => [...e, event]);
    } else setSelected(event);
  };
  return (
    <span
      className="dropdown_main"
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: width ? `calc(${width} - (1px))` : "fit-content",
        width: width ? `calc(${width} - (1px))` : "fit-content",
      }}
    >
      {title && <div className="DropDown_Title">{title}</div>}
      <Select
        options={options}
        isClearable={isClearable}
        style={{
          minWidth: width ? width : "fit-content",
          width: width ? width : "fit-content",
        }}
        components={{
          DropdownIndicator: blueArrow
            ? CustomDropdownIndicator
            : CustomDropdownIndicator2,
        }}
        onChange={(e) => setSelectedElement(e)}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isSearchable={true}
        className="DropDown_Main"
        classNamePrefix={"DropDown_Main"}
        value={keepPlaceHolder ? placeholder : value}
        autoFocus={false}
        isMulti={isMulti}
      />
      {multiSelection && keepPlaceHolder && selectedData.length > 0 && (
        <div className="selected_main_div">
          {selectedData?.map((item, index) => {
            return (
              <div className="selected_value" key={index}>
                {item.label}
                <img
                  src={close}
                  alt="close"
                  className="close_selected"
                  onClick={() => removeElm(index, item)}
                />
              </div>
            );
          })}
        </div>
      )}
    </span>
  );
}

export default DropDown;
