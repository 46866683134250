import React, { useEffect, useState } from "react";
import "./CompleteProfilee.scss";
import CompleteProfileCard from "../../Components/CompleteProfileCard/CompleteProfileCard";
import Button from "../../../Shared/Components/Button/Button";
import question from "../../../Shared/Assets/Images/question.png";
import Popup from "../../../Shared/Components/Popup/Popup";
import Input from "../../../Shared/Components/Input/Input";
import back from "../../../Shared/Assets/Images/arrow1.webp";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
import add from "../../../Shared/Assets/Images/add.png";
import trash from "../../../Shared/Assets/Images/trash.png";
import { useNavigate } from "react-router-dom";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import { getstudentsbyid } from "../../../Counsler/Redux/CounselorStudent/CounselorStudentActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addAdress,
  addContact,
  addExp,
  updateAddress,
} from "../../Redux/completeProfile/CompleteProfileActions";
import {
  getProfileAddress,
  getProfileEmergency,
  getProfileExp,
} from "../../Redux/Student/StudentActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import {
  getAllCont,
  getCityByContId,
} from "../../../Shared/Redux/Location/LocationsActions";
import { DropDownList } from "../../../Utils";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";

function CompleteProfilee({ sales, counselor }) {
  const navigate = useNavigate();
  const stid = useParams()?.id;
  const [showPopup, setShowPopup] = useState(false);
  const [steps, setSteps] = useState("1");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState();
  const [B_number, setB_number] = useState();
  const [B_name, setB_name] = useState("");
  const [country, setCountry] = useState("");
  const [addressError, setAddressError] = useState("");

  const [error, setError] = useState("");
  const [error2_1, setError2_1] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [Ongoing, setOngoing] = useState(false);
  const [step2country, setStep2country] = useState("");
  const [experi, setExperi] = useState([]);

  const [emergencyRelation, setEmergencyRelation] = useState({});
  const [error3_1, setError3_1] = useState("");
  const [emergencyFName, setEmergencyFName] = useState("");
  const [emergencyLName, setEmergencyLName] = useState("");
  const [emergencyEmail, setEmergencyEmail] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [emergencyAddress, setEmergencyAddress] = useState("");
  const [emergencyUser, setEmergencyUser] = useState([]);
  const [othersRelation, setOthersRelation] = useState("");
  const [contactError, setContactError] = useState("");

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();

  const addressDatabase = useSelector((state) => state.StudentReducer?.address);
  const { student } = useSelector((state) => state.StudentReducer);
  const expDatabase = useSelector((state) => state.StudentReducer?.exp);
  const [sameAs, setSameAs] = useState(false);
  const emergencyDatabase = useSelector(
    (state) => state.StudentReducer?.emergency
  );
  useEffect(() => {
    if (counselor || sales || stid != undefined) {
      dispatch(getProfileAddress(stid, false));
      dispatch(getProfileExp(stid, false));
      dispatch(getProfileEmergency(navigate, stid, false));
      dispatch(getAllCont());
    } else {
      dispatch(getProfileAddress("", true));
      dispatch(getProfileExp("", true));
      dispatch(getProfileEmergency(navigate, "", true));
      dispatch(getAllCont());
    }
  }, [steps]);

  const { Countries, Cities, getCitiesLoading } = useSelector(
    (state) => state.LocationReducer
  );

  useEffect(() => {
    setCountries(DropDownList(Countries, "id", "name"));
  }, [Countries]);

  // useEffect(() => {
  //   if (addressDatabase.length > 0) {
  //     if (expDatabase.length > 0) {
  //       setSteps("3");
  //     } else {
  //       if (emergencyDatabase.length > 0) {
  //         if (counselor || sales) {
  //           sales
  //             ? navigate("/sales/students/singleStudent/" + stid)
  //             : navigate("/counselor/students/singleStudent/" + stid);
  //         } else {
  //           navigate("/student/profile");
  //         }
  //       } else {
  //         setSteps("2");
  //       }
  //     }
  //   } else {
  //     setSteps("1");
  //   }
  // }, [addressDatabase, expDatabase, emergencyDatabase]);

  useEffect(() => {
    if (addressDatabase.length > 0) {
      setAddress(addressDatabase[0].street);
      setB_name(addressDatabase[0].building_name);
      setB_number(addressDatabase[0].building_number);
      setCity(addressDatabase[0].city);
      setPostal(addressDatabase[0].postal_code);
      setCountry({
        label: addressDatabase[0].country,
        value: addressDatabase[0].country,
      });
    }

    if (expDatabase.length > 0) {
      setExperi(expDatabase);
    }
    if (emergencyDatabase.length > 0) {
      setEmergencyUser(emergencyDatabase);
    }
  }, [addressDatabase, expDatabase, emergencyDatabase]);
  const handleContinue = () => {
    if (
      address == "" ||
      city == "" ||
      postal == null ||
      B_number == null ||
      B_name == "" ||
      country == ""
    ) {
      setError("Please fill all the fields");
    } else {
      if (addressDatabase.length > 0) {
        const step = expDatabase.length > 0 ? "2-1" : "2";
        dispatch(
          updateAddress(
            newAddress,
            addressDatabase[0]?.id,
            stid,
            setSteps,
            step
          )
        );
      } else {
        dispatch(addAdress(newAddress, setSteps));
      }
    }
  };
  const handleContinue2_1 = () => {
    if (
      companyName &&
      position &&
      startDate &&
      (Ongoing ? true : endDate) &&
      step2country &&
      location
    ) {
      handleAddExperience();
    } else {
      if (experi.length > 0) {
        setError2_1("");

        let arr = [...experi];
        arr = arr.filter((item) => !item.id);
        if (arr.length > 0) {
          dispatch(addExp(arr, setSteps));
        } else {
          setSteps("3");
        }
      } else {
        if (experi.length == 0) {
          handleAddExperience();
        } else setError2_1("Please add at least one work experience");
      }
    }
  };

  const handleAddExperience = () => {
    if (
      companyName != "" &&
      location != "" &&
      position != "" &&
      startDate != "" &&
      (Ongoing ? true : endDate != "") &&
      step2country != ""
    ) {
      setError2_1("");
      if (experi.length == 0) {
        if (counselor || sales) {
          setExperi([
            ...experi,
            {
              company: companyName,
              city: location,
              position: position,
              start_date: startDate,
              end_date: Ongoing ? "Ongoing" : endDate,
              country: step2country.label,
              student: stid,
            },
          ]);
        } else {
          setExperi([
            ...experi,
            {
              company: companyName,
              city: location,
              position: position,
              start_date: startDate,
              end_date: Ongoing ? "Ongoing" : endDate,
              country: step2country.label,
            },
          ]);
        }

        setCompanyName("");
        setLocation("");
        setPosition("");
        setStartDate("");
        setEndDate("");
        setStep2country("");
        setOngoing(false);
      } else {
        var i = 0;
        for (i; i < experi.length; i++) {
          if (
            experi[i].company == companyName &&
            experi[i].city == location &&
            experi[i].position == position &&
            experi[i].start_date == startDate &&
            Ongoing
              ? experi[i].end_date == "Ongoing"
              : experi[i].end_date == endDate &&
                experi[i].country == step2country.label
          ) {
            setError2_1("Company already exists");
            break;
          }
        }
        if (i == experi.length) {
          setError2_1("");
          setExperi([
            ...experi,
            {
              company: companyName,
              city: location,
              position: position,
              start_date: startDate,
              end_date: Ongoing ? "Ongoing" : endDate,
              country: step2country.label,
            },
          ]);
          setCompanyName("");
          setLocation("");
          setPosition("");
          setStartDate("");
          setEndDate("");
          setStep2country("");
        }
      }
    } else {
      setError2_1("Please fill all the fields");
    }
  };
  const handleDeleteExperience = (index) => {
    var temp = [...experi];
    temp.splice(index, 1);
    setExperi(temp);
  };

  const handleDeleteEmergency = (index) => {
    var temp = [...emergencyUser];
    temp.splice(index, 1);
    setEmergencyUser(temp);
  };

  const handleContinue3_1 = () => {
    if (
      emergencyFName &&
      emergencyLName &&
      emergencyPhone &&
      emergencyAddress &&
      emergencyRelation &&
      emergencyRelation.value != "Other"
        ? true
        : othersRelation
    ) {
      handleAdd3_1();
    } else {
      if (emergencyUser.length > 0) {
        emergencyUser.map((item) => {
          if (item.sameAddress) {
            delete item.address;
          }
        });
        setError3_1("");
        if (counselor || sales)
          dispatch(addContact(navigate, emergencyUser, stid, counselor));
        else {
          dispatch(addContact(navigate, emergencyUser));
        }
      } else {
        if (emergencyUser.length == 0) {
          handleAdd3_1();
        } else setError3_1("Please add at least one emergency contact");
      }
    }
  };

  const handleAdd3_1 = () => {
    if (
      emergencyRelation == "" ||
      (emergencyRelation.label == "Other" && othersRelation == "") ||
      emergencyFName == "" ||
      emergencyLName == "" ||
      emergencyPhone == "" ||
      (!sameAs && emergencyAddress == "")
    ) {
      setError3_1("Please fill all the fields");
    } else {
      setError3_1("");
      var j = 0;
      for (j; j < emergencyUser.length; j++) {
        if (
          (emergencyRelation.value != "Other"
            ? emergencyUser[j].relationship == emergencyRelation.value
            : emergencyUser[j].relationship == othersRelation) ||
          (emergencyUser[j].first_name == emergencyFName &&
            emergencyUser[j].last_name == emergencyLName &&
            emergencyUser[j].phone == emergencyPhone &&
            !sameAs &&
            emergencyUser[j].address == emergencyAddress &&
            emergencyUser[j].email == emergencyEmail)
        ) {
          setError3_1("Emergency contact already exists");
          break;
        }
      }
      if (j == emergencyUser.length) {
        if (counselor || sales) {
          setEmergencyUser([
            ...emergencyUser,
            {
              relationship:
                emergencyRelation.value != "Other"
                  ? emergencyRelation.value
                  : othersRelation,
              first_name: emergencyFName,
              last_name: emergencyLName,
              ...(emergencyEmail && { email: emergencyEmail }),
              phone: emergencyPhone,
              address: !sameAs ? emergencyAddress : null,
              student: stid,
              sameAddress: sameAs,
            },
          ]);
        } else {
          setEmergencyUser([
            ...emergencyUser,
            {
              relationship:
                emergencyRelation.value != "Other"
                  ? emergencyRelation.value
                  : othersRelation,
              first_name: emergencyFName,
              last_name: emergencyLName,
              ...(emergencyEmail && { email: emergencyEmail }),
              phone: emergencyPhone,
              address: !sameAs ? emergencyAddress : null,
              sameAddress: sameAs,
            },
          ]);
        }
        setEmergencyAddress("");
        setEmergencyEmail("");
        setEmergencyFName("");
        setEmergencyLName("");
        setEmergencyPhone("");
        setOthersRelation("");
      }
    }
  };

  const [newAddress, setNewAddress] = useState({});

  const { id } = useSelector((state) => state.StudentReducer?.student);
  const {
    addAddressLoading,
    addAddressSuccess,
    addAddressError,
    addExperienceError,
    addExperienceSuccess,
    addExperienceLoading,
    addContactError,
    addContactSuccess,
    addContactLoading,
  } = useSelector((state) => state.completeProfileReducer);

  useEffect(() => {
    if (addContactError) {
      setError3_1(addContactError);
    }
    if (addContactSuccess) {
      setError3_1("");
    }
  }, [addContactError, addContactSuccess]);

  useEffect(() => {
    if (addExperienceError) {
      setError2_1(addExperienceError);
    }
    if (addExperienceSuccess) {
      setError2_1("");
    }
  }, [addExperienceError, addExperienceSuccess]);

  useEffect(() => {
    if (addAddressError) {
      setAddressError(addAddressError);
    }
    if (addAddressSuccess) {
      setAddressError("");
    }
  }, [addAddressError, addAddressSuccess]);

  useEffect(() => {
    setCities(DropDownList(Cities, "name", "name"));
  }, [Cities]);

  useEffect(() => {
    setNewAddress({
      ...(address && { street: address }),
      ...(city && { city: city }),
      ...(postal && { postal_code: postal }),
      ...(B_number && { building_number: B_number }),
      ...(B_name && { building_name: B_name }),
      ...(country && { country: country.label }),
      ...(counselor || sales
        ? stid && { student: stid }
        : id && { student: id }),
    });
  }, [address, city, postal, B_number, B_name, country]);

  useEffect(() => {
    if (Ongoing) {
      setEndDate("");
    }
  }, [Ongoing]);

  return (
    <>
      {addAddressLoading || addContactLoading || addExperienceLoading ? (
        <Loader fullScreen={true} />
      ) : (
        <div className="CompleteProfile_container">
          {steps == "1" && (
            <CompleteProfileCard
              title="Detailed Home Address"
              number={1}
              body={
                <>
                  <div className="complete_profile_body_top">
                    <span className="complete_profile_body_top_text">
                      Why do we ask for a detailed home address?
                    </span>
                    <img
                      src={question}
                      alt="question mark"
                      className="question_icon"
                      onClick={() => {
                        setShowPopup(true);
                      }}
                    />
                  </div>
                  <Button
                    text="Let's Go!"
                    color={"lightGreen"}
                    onClick={() => setSteps("1-1")}
                  />
                  {counselor || sales ? (
                    <div
                      className="Skip_Button"
                      onClick={() =>
                        sales
                          ? navigate("/sales/students/singleStudent/" + stid)
                          : navigate(
                              "/counselor/students/singleStudent/" + stid
                            )
                      }
                    >
                      Skip for now
                    </div>
                  ) : (
                    <div
                      className="Skip_Button"
                      onClick={() => navigate("/student/profile")}
                    >
                      Skip for now
                    </div>
                  )}
                </>
              }
            />
          )}
          {steps == "1-1" && (
            <CompleteProfileCard
              title={
                <div
                  className="Home_Address_Title"
                  style={{ marginTop: "-1.8rem", marginBottom: "-0.5rem" }}
                >
                  Home Address
                </div>
              }
              body={
                <>
                  <div className="complete_profile_body_top_1-1">
                    <img
                      src={back}
                      alt="back"
                      className="back_img"
                      onClick={() => setSteps("1")}
                    />
                    <div className="input_div">
                      <div className="input_title">Street Name</div>
                      <Input
                        value={address}
                        placeholder="e.g: 123 Main Street"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Building Name</div>
                      <Input
                        value={B_name}
                        placeholder="e.g: ABC Building"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setB_name(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Building Number</div>
                      <Input
                        value={B_number}
                        placeholder="e.g: 111"
                        type="number"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setB_number(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Postal Code</div>
                      <Input
                        value={postal}
                        placeholder="e.g: 000"
                        type="number"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setPostal(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Country</div>

                      <DropDown
                        placeholder="Select a Country"
                        setSelected={setCountry}
                        data={countries}
                        blueArrow={false}
                        width={"100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={country}
                        keepPlaceHolder={false}
                        isDisabled={false}
                        value={country}
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">City</div>
                      <Input
                        value={city}
                        placeholder="Select a City"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  {error != "" && (
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        color: "var(--pink)",
                        fontFamily: "P_Semibold",
                      }}
                    >
                      {error}
                    </div>
                  )}
                  {addressError && (
                    <div
                      style={{
                        color: "var(--pink)",
                        margin: "-1rem auto 0 0",
                        fontFamily: "P_Semibold",
                      }}
                    >
                      {addressError}
                    </div>
                  )}
                  <Button
                    text="Continue"
                    color={"lightGreen"}
                    width={"100%"}
                    onClick={() => handleContinue()}
                    disabled={
                      address == "" ||
                      city == "" ||
                      postal == null ||
                      B_number == null ||
                      B_name == "" ||
                      country == ""
                    }
                  />
                </>
              }
            />
          )}
          {steps == "2" && (
            <CompleteProfileCard
              back={true}
              onClick={() => setSteps("1-1")}
              title="Do you have work experience?"
              number={2}
              body={
                <>
                  <div
                    className="complete_profile_body_top"
                    style={{ marginTop: "1rem" }}
                  >
                    <Button
                      text="Yes"
                      color="lightGreen"
                      onClick={() => setSteps("2-1")}
                    />
                    &nbsp;
                    <Button
                      text="No"
                      color="pink"
                      onClick={() => setSteps("3")}
                    />
                  </div>
                  {counselor || sales ? (
                    <div
                      className="Skip_Button"
                      onClick={() =>
                        sales
                          ? navigate("/sales/students/singleStudent/" + stid)
                          : navigate(
                              "/counselor/students/singleStudent/" + stid
                            )
                      }
                    >
                      Skip for now
                    </div>
                  ) : (
                    <div
                      className="Skip_Button"
                      onClick={() => navigate("/student/profile")}
                    >
                      Skip for now
                    </div>
                  )}
                </>
              }
            />
          )}
          {steps == "2-1" && (
            <CompleteProfileCard
              title={
                <div
                  className="work_Exp_title"
                  style={{ marginTop: "-2rem", marginBottom: "-0.5rem" }}
                >
                  Work Experience
                </div>
              }
              body={
                <>
                  <div className="complete_profile_body_top_1-1">
                    <img
                      src={back}
                      alt="back"
                      className="back_img"
                      onClick={() => setSteps("2")}
                    />
                    <img
                      src={add}
                      alt="add"
                      className="work_exp_add_img"
                      onClick={() => {
                        handleAddExperience();
                      }}
                    />
                    <div className="input_div">
                      <div className="input_title">Company Name</div>
                      <Input
                        value={companyName}
                        placeholder="Company Name"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Position</div>
                      <Input
                        value={position}
                        placeholder="Position "
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setPosition(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Country</div>

                      <DropDown
                        placeholder="Country"
                        setSelected={setStep2country}
                        data={countries}
                        blueArrow={false}
                        width={"100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={step2country}
                        keepPlaceHolder={false}
                        isDisabled={false}
                        value={step2country}
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">City</div>
                      <Input
                        value={location}
                        placeholder="City"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setLocation(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div
                        className="input_title"
                        style={{ paddingBottom: "0.5rem" }}
                      >
                        Date Started
                      </div>
                      <DateInput
                        width={"100%"}
                        setSelectedDate={setStartDate}
                        selectedDate={startDate}
                        disabled={false}
                      />
                    </div>
                    <div className="input_div">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1rem",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        <div className="input_title">Date Ended</div>
                        <Checkbox
                          label={"Ongoing"}
                          selected={Ongoing}
                          setSelected={setOngoing}
                        />
                      </div>
                      <DateInput
                        width={"100%"}
                        setSelectedDate={setEndDate}
                        selectedDate={endDate}
                        disabled={
                          startDate == null || startDate == "" || Ongoing
                            ? true
                            : false
                        }
                        minDate={new Date(startDate)}
                      />
                    </div>
                  </div>

                  <div style={{ width: "100%", overflowX: "auto" }}>
                    {experi.length > 0 && (
                      <hr
                        style={{
                          height: "2px",
                          width: "100%",
                          backgroundColor: "black",
                          border: "none",
                          borderRadius: "10px",
                          minWidth: "30rem",
                        }}
                      />
                    )}
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "separate",
                        borderSpacing: "15px",
                        minWidth: "30rem",
                      }}
                    >
                      <tbody>
                        {experi?.map((item, index) => {
                          return (
                            <tr key={index} style={{ width: "100%" }}>
                              <td
                                style={{
                                  textAlign: "left",
                                  width: "fit-content",
                                }}
                              >
                                {item.company}
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  width: "fit-content",
                                }}
                              >
                                {item.position}
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  width: "fit-content",
                                }}
                              >
                                {item.country}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  width: "7rem",
                                }}
                              >
                                {item.start_date.split("-")[2] +
                                  "-" +
                                  (item.end_date == "Ongoing"
                                    ? "Present"
                                    : item.end_date.split("-")[2])}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={trash}
                                  alt="delete"
                                  style={{ width: "20px", cursor: "pointer" }}
                                  onClick={() => {
                                    handleDeleteExperience(index);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {error2_1 != "" && (
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        color: "var(--pink)",
                        fontFamily: "P_Semibold",
                      }}
                    >
                      {error2_1}
                    </div>
                  )}
                  <Button
                    text="Continue"
                    color={"lightGreen"}
                    width={"100%"}
                    onClick={() => handleContinue2_1()}
                    disabled={
                      experi.length == 0 &&
                      !(
                        companyName != "" &&
                        location != "" &&
                        position != "" &&
                        startDate != null &&
                        (Ongoing ? true : endDate != null && endDate != "") &&
                        step2country != ""
                      )
                    }
                  />
                </>
              }
            />
          )}
          {steps == "3" && (
            <CompleteProfileCard
              title="Emergency Contact"
              back={true}
              onClick={() => setSteps("2-1")}
              number={3}
              body={
                <>
                  <div
                    className="complete_profile_body_top"
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Button
                      text="Mother"
                      color="pink"
                      onClick={() => {
                        setSteps("3-1");
                        setEmergencyRelation({
                          label: "Mother",
                          value: "Mother",
                        });
                      }}
                    />
                    &nbsp;
                    <Button
                      text="Father"
                      color="lightGreen"
                      onClick={() => {
                        setSteps("3-1");
                        setEmergencyRelation({
                          label: "Father",
                          value: "Father",
                        });
                      }}
                    />
                    &nbsp;
                    <Button
                      text="Other"
                      color="yellow"
                      onClick={() => {
                        setSteps("3-1");
                        setEmergencyRelation({
                          label: "Other",
                          value: "Other",
                        });
                      }}
                    />
                  </div>
                  {counselor || sales ? (
                    <div
                      className="Skip_Button"
                      onClick={() =>
                        sales
                          ? navigate("/sales/students/singleStudent/" + stid)
                          : navigate(
                              "/counselor/students/singleStudent/" + stid
                            )
                      }
                    >
                      Skip for now
                    </div>
                  ) : (
                    <div
                      className="Skip_Button"
                      onClick={() => navigate("/student/profile")}
                    >
                      Skip for now
                    </div>
                  )}
                </>
              }
            />
          )}
          {steps == "3-1" && (
            <CompleteProfileCard
              title={
                <div
                  className="work_Exp_title"
                  style={{ marginTop: "-2rem", marginBottom: "-0.5rem" }}
                >
                  Emergency Contact
                </div>
              }
              body={
                <>
                  <div className="complete_profile_body_top_1-1">
                    <img
                      src={back}
                      alt="back"
                      className="back_img"
                      onClick={() => {
                        setError3_1("");
                        setSteps("3");
                      }}
                    />
                    <img
                      src={add}
                      alt="add"
                      className="work_exp_add_img"
                      onClick={() => {
                        handleAdd3_1();
                      }}
                    />
                    <div className="input_div">
                      <div className="input_title">First Name</div>
                      <Input
                        value={emergencyFName}
                        placeholder="e.g: John"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setEmergencyFName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Last Name</div>
                      <Input
                        value={emergencyLName}
                        placeholder="e.g: Doe"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setEmergencyLName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">
                        Email
                        <span
                          style={{
                            float: "right",
                            fontFamily: "P_Italic",
                            opacity: "0.7",
                            fontSize: "0.9rem",
                          }}
                        >
                          Optional
                        </span>
                      </div>
                      <Input
                        value={emergencyEmail}
                        placeholder="e.g: johndoe@hotmail.com"
                        type="text"
                        width="100%"
                        disabled={false}
                        onChange={(e) => setEmergencyEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input_div">
                      <div className="input_title">Relationship</div>
                      <div className="others_div">
                        <DropDown
                          placeholder=""
                          setSelected={setEmergencyRelation}
                          data={[
                            { label: "Mother", value: "Mother" },
                            { label: "Father", value: "Father" },
                            { label: "Other", value: "Other" },
                          ]}
                          blueArrow={false}
                          width={
                            emergencyRelation.label == "Other" ? "50%" : "100%"
                          }
                          multiSelection={false}
                          searchable={false}
                          keepPlaceHolder={false}
                          isDisabled={false}
                          value={emergencyRelation}
                        />
                        {emergencyRelation.label == "Other" && (
                          <Input
                            value={othersRelation}
                            placeholder="e.g: Uncle"
                            type="text"
                            width="50%"
                            disabled={false}
                            onChange={(e) => setOthersRelation(e.target.value)}
                            required
                          />
                        )}
                      </div>
                    </div>

                    <div className="input_div">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1rem",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        <div className="input_title">Phone Number</div>
                      </div>
                      <MobileInput
                        width={"100%"}
                        search={false}
                        required={true}
                        disabled={false}
                        phone={emergencyPhone}
                        setPhone={setEmergencyPhone}
                      />
                    </div>
                    <div className="input_div">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1rem",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        <div className="input_title">Address</div>

                        <Checkbox
                          label={"Same as mine"}
                          selected={sameAs}
                          setSelected={setSameAs}
                        />
                      </div>
                      <Input
                        value={emergencyAddress}
                        placeholder="e.g: 1234 Main Street"
                        type="text"
                        width="100%"
                        disabled={sameAs}
                        onChange={(e) => setEmergencyAddress(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", overflowX: "auto" }}>
                    {emergencyUser.length > 0 && (
                      <hr
                        style={{
                          height: "2px",
                          width: "100%",
                          backgroundColor: "black",
                          border: "none",
                          borderRadius: "10px",
                          minWidth: "30rem",
                        }}
                      />
                    )}
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "separate",
                        borderSpacing: "15px",
                        minWidth: "30rem",
                      }}
                    >
                      <tbody>
                        {emergencyUser?.map((item, index) => {
                          return (
                            <tr key={index} style={{ width: "100%" }}>
                              <td
                                style={{
                                  textAlign: "left",
                                  width: "fit-content",
                                }}
                              >
                                {item.first_name + " " + item.last_name}
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  width: "fit-content",
                                }}
                              >
                                {item.relationship}
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  width: "fit-content",
                                }}
                              >
                                {item.address}
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={trash}
                                  alt="delete"
                                  style={{ width: "20px", cursor: "pointer" }}
                                  onClick={() => {
                                    handleDeleteEmergency(index);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {error3_1 != "" && (
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        color: "var(--pink)",
                        fontFamily: "P_Semibold",
                      }}
                    >
                      {error3_1}
                    </div>
                  )}
                  <Button
                    text="Continue"
                    color={"lightGreen"}
                    width={"100%"}
                    onClick={() => handleContinue3_1()}
                    disabled={
                      emergencyUser.length == 0 &&
                      (emergencyRelation == "" ||
                        emergencyFName == "" ||
                        emergencyLName == "" ||
                        emergencyPhone == "" ||
                        (!sameAs && emergencyAddress == ""))
                    }
                  />
                </>
              }
            />
          )}
        </div>
      )}
      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          width={"50%"}
          body={
            <div>
              <h1
                style={{
                  fontSize: "1.5rem",
                  textAlign: "center",
                  fontFamily: "P_Bold",
                  marginBottom: "1.5rem",
                }}
              >
                Why do we ask for a detailed home address?
              </h1>
              <div
                style={{
                  width: "80%",
                  margin: "auto",
                  fontSize: "1rem",
                  textAlign: "center",
                  fontFamily: "P_Medium",
                  opacity: "0.7",
                }}
              >
                For most universities, providing a detailed home address is
                essential. This is because they need to ship official documents
                directly to your location once you're accepted. Additionally, a
                detailed home address is often required on visa documents that
                universities issue for embassies.
              </div>
              <div
                style={{
                  width: "fit-content",
                  margin: "1.7rem auto 0.5rem auto",
                }}
                onClick={() => setShowPopup(false)}
              >
                <Button text="Got It!" color="pink" />
              </div>
            </div>
          }
        />
      )}
    </>
  );
}

export default CompleteProfilee;
