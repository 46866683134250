const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  notifications: [],
  getNotificationsError: "",
  getNotificationsLoading: false,
  notificationReceived: false,

  deleteAllNotificationsLoading: false,
  deleteAllNotificationsError: "",
  deleteAllNotificationsSuccess: false,

  deleteSingleNotificationLoading: false,
  deleteSingleNotificationError: "",
  deleteSingleNotificationSuccess: false,

  isAccepted: "",
  acceptedLink: {},
};

const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getNotificationsRequest(state) {
      state.getNotificationsLoading = true;
      state.getNotificationsError = "";
      state.notifications = [];
    },
    getNotificationsSuccess(state, action) {
      state.notifications = action.payload;
      state.getNotificationsLoading = false;
      state.getNotificationsError = "";
    },
    getNotificationsError(state, action) {
      state.getNotificationsLoading = false;
      state.getNotificationsError = action.payload;
      state.notifications = [];
    },
    notificationReceived(state) {
      state.notificationReceived = true;
    },
    readNotification(state) {
      state.notificationReceived = false;
    },

    deleteAllNotificationsRequest(state) {
      state.deleteAllNotificationsLoading = true;
      state.deleteAllNotificationsError = "";
      state.deleteAllNotificationsSuccess = false;
    },
    deleteAllNotificationsSuccess(state) {
      state.deleteAllNotificationsLoading = false;
      state.deleteAllNotificationsError = "";
      state.deleteAllNotificationsSuccess = true;
    },
    deleteAllNotificationsError(state, action) {
      state.deleteAllNotificationsLoading = false;
      state.deleteAllNotificationsError = action.payload;
      state.deleteAllNotificationsSuccess = false;
    },

    deleteSingleNotificationRequest(state) {
      state.deleteSingleNotificationLoading = true;
      state.deleteSingleNotificationError = "";
      state.deleteSingleNotificationSuccess = false;
    },
    deleteSingleNotificationSuccess(state) {
      state.deleteSingleNotificationLoading = false;
      state.deleteSingleNotificationError = "";
      state.deleteSingleNotificationSuccess = true;
    },
    deleteSingleNotificationError(state, action) {
      state.deleteSingleNotificationLoading = false;
      state.deleteSingleNotificationError = action.payload;
      state.deleteSingleNotificationSuccess = false;
    },

    setIsAccepted(state, action) {
      state.isAccepted = action.payload.isAccepted;
      state.acceptedLink = action.payload.data;
    },
    resetIsAccepted(state) {
      state.isAccepted = "";
      state.acceptedLink = {};
    },
  },
});
export const NotificationActions = NotificationSlice.actions;
export default NotificationSlice;
