import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CounselorStudents.scss";
import Button from "../../../Shared/Components/Button/Button";
import SearchIcon from "../../../Shared/Assets/Images/search_icon.png";
import Add from "../../../Shared/Assets/Images/add.png";
import ViewMode from "../../../Shared/Assets/Images/blue_View.png";
import ArrowUp from "../../../Shared/Assets/Images/arrow_up.svg";
import ArrowBack from "../../../Shared/Assets/Images/arrow1.webp";
import StudentCard from "../../../Shared/Components/StudentCard/StudentCard";
import SearchInput from "../../../Shared/Components/SearchInput/SearchInput";
import Loader from "../../../Shared/Components/Loader/Loader";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import filter from "../../../Shared/Assets/Images/filterMob.png";
import UploadButton from "../../../Shared/Assets/Images/uploadHere.png";
import document from "../../../Shared/Assets/Images/document.png";
import tableIcon from "../../../Shared/Assets/Images/tableView.png";
import cardIcon from "../../../Shared/Assets/Images/cardView.png";
import Filters from "../../../Shared/Components/Filters/Filters";
import CounselorTable from "../../Components/CounselorTable/CounselorTable";
import Popup from "../../../Shared/Components/Popup/Popup";
import { getCounselorStudents } from "../../Redux/CounselorStudent/CounselorStudentActions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../Shared/Components/Pagination/Pagination";

function CounselorStudents() {
  const dispatch = useDispatch();
  const [chosenStatus, setChosenStatus] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [counselor, setCounselor] = useState(true);
  const [tableView, setTableView] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openValidationPopUp, setOpenValidationPopUp] = useState(false);
  const [UploadedDocument, setUploadedDocument] = useState("");
  const [counter, setCounter] = useState(0);
  const [search, setSearch] = useState("");
  const [all, setAll] = useState(false);
  const scWidth = UseInnerWidth();
  const navigate = useNavigate();
  const ref = useRef(null);
  const selectDoc = () => {
    ref.current.click();
  };
  const onSelectDoc = (event) => {
    setUploadedDocument(event.target.files[0].name);
  };
  //filter states
  const [limit, setlimit] = useState(8);
  const [offset, setOffset] = useState(0);

  const [data, setData] = useState({});
  const [pageNumber, setpageNumber] = useState(1);
  const createid = (id) => {
    const firstThreeDigits = id.slice(0, 3);
    const lastThreeDigits = id.slice(-3);
    const app_number = "#" + firstThreeDigits + lastThreeDigits;
    return app_number;
  };
  useEffect(() => {
    dispatch(
      getCounselorStudents(
        limit,
        offset,
        data?.countries?.label,
        data?.status?.label,
        data?.major?.label,
        data?.language?.label,
        data?.sort?.label,
        search
      )
    );
  }, [limit, offset]);
  useEffect(() => {
    if (search) {
      setCounter(counter + 1);
      const timer = setTimeout(() => {
        dispatch(
          getCounselorStudents(
            limit,
            offset,
            data?.countries?.label,
            data?.status?.label,
            data?.major?.label,
            data?.language?.label,
            data?.sort?.label,
            search
          )
        );
      }, 800);

      return () => clearTimeout(timer);
    } else if (counter > 0 && !search) {
      const timer = setTimeout(() => {
        dispatch(
          getCounselorStudents(
            limit,
            offset,
            data?.countries?.label,
            data?.status?.label,
            data?.major?.label,
            data?.language?.label,
            data?.sort?.label
          )
        );
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [search]);
  const { Students, error, loading } = useSelector(
    (state) => state.CounselorStudentReducer
  );
  useEffect(() => {
    if (scWidth < 990) {
      setTableView(false);
    }
  }, [scWidth]);

  return (
    <div className="CSContainer">
      {loading && (
        <div>
          <Loader fullScreen={true} />
        </div>
      )}
      <>
        <div className="CSInnerContainer">
          <div className="CSHeader">
            <div className="CSHeaderRight">
              <div className="CsSearchButton">
                <div className="CSMainFilterDiv">
                  <div className="catgUni_search">
                    <SearchInput
                      placeholder="Search for Students"
                      width={scWidth > 768 ? "400px" : "100%"}
                      inputV={search}
                      setInputV={setSearch}
                    />
                  </div>
                  <div className="CS_search_buttons">
                    {counselor ? (
                      scWidth > 990 && (
                        <div className="catgUni_search_button1">
                          <Button
                            text={
                              <div className="viewMode_btn">
                                View Mode
                                <img
                                  src={tableView ? tableIcon : cardIcon}
                                  alt="viewMode"
                                  className="viewMode"
                                />
                              </div>
                            }
                            color="yellow"
                            width="Fit-Content"
                            onClick={() => {
                              setTableView(!tableView);
                            }}
                          />
                        </div>
                      )
                    ) : (
                      <div className="catgUni_search_button1">
                        <Button
                          text={all ? "View By Sections" : "View All"}
                          color="yellow"
                          width="Fit-Content"
                          // onClick={() => {
                          //   !all
                          //     ? navigate("/student/universities/all")
                          //     : navigate("/student/universities");
                          // }}D
                        />
                      </div>
                    )}
                    <div className="catgUni_search_button2">
                      <span>
                        <Button
                          text={
                            scWidth <= 680 ? (
                              <img
                                src={filter}
                                alt="filter"
                                className="filter_img_uni"
                              />
                            ) : (
                              "Add filter"
                            )
                          }
                          color="blue"
                          width="150px"
                          onClick={() => {
                            setOpenFilter(true);
                          }}
                        />
                      </span>
                      {openFilter && (
                        <Filters
                          setOpened={setOpenFilter}
                          type={"student"}
                          data={data}
                          setData={setData}
                          application={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="AddPlusTitle">
              <div className="CSHeaderTitle">My Students</div>
              <img
                className="CSAddImg"
                src={Add}
                alt="Add Student"
                onClick={() => {
                  setOpenValidationPopUp(true);
                }}
              />
            </div>
          </div>
          {openValidationPopUp && (
            <Popup
              setShowPopup={setOpenValidationPopUp}
              body={
                <div className="CSPopUpContainer">
                  <div className="CSPopUpInnerContainer">
                    <div className="CSPopUpTitleDiv">
                      <p className="CSPopUpTitle">Create Student</p>
                    </div>
                    <div>
                      <p className="CSPopUpValidationText">
                        Create a new student using onboarding process
                      </p>
                    </div>
                    <div className="CSPopUpButtons">
                      <Button
                        text="Create"
                        color="blue"
                        width="100%"
                        onClick={() => navigate("/counselor/students/create")}
                      />
                      <Button
                        text="Cancel"
                        color="pink"
                        width="100%"
                        onClick={() => setOpenValidationPopUp(false)}
                      />
                    </div>
                  </div>
                </div>
              }
              width="40%"
            />
          )}
        </div>
        {tableView && scWidth > 900 ? (
          <CounselorTable
            students={Students}
            limit={limit}
            offset={offset}
            hasPrev={Students.previous}
            hasNext={Students.next}
            pageNumber={pageNumber}
            setPageNumber={setpageNumber}
            setOffset={setOffset}
            setLimit={setlimit}
          />
        ) : (
          <div className="CounselorStudentsLowerInner">
            <div className="CounselorStudentsBody CounselorStudentHeader">
              <div className="CSCardDiv">
                {Students?.students?.map((student) => (
                  <div
                    className="CSSingleStudentCard"
                    onClick={() => {
                      navigate(
                        `/counselor/students/singleStudent/` + student.id
                      );
                    }}
                  >
                    <StudentCard
                      name={
                        student.user.first_name + " " + student.user.last_name
                      }
                      degree={student.education_level}
                      status={
                        student?.applications?.length > 0 ? "Applied" : "New"
                      }
                      id={createid(student.id)}
                      stid={student.id}
                      picture={student.user.profile_picture}
                      email={student.user.email}
                      nationality={student.user.nationality}
                      date={student.user.createdAt
                        .slice(0, 10)
                        .replace(/-/g, "/")}
                      budget={
                        (student.budget?.[0] != "null"
                          ? student.budget?.[0] + "$"
                          : "") +
                        (student.budget?.[1] != "null"
                          ? "- " + student.budget?.[1] + "$"
                          : "")
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="PaginationDiv">
              <Pagination
                limit={limit}
                offset={offset}
                hasPrev={Students.previous}
                hasNext={Students.next}
                pageNumber={pageNumber}
                setPageNumber={setpageNumber}
                setOffset={setOffset}
                setLimit={setlimit}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default CounselorStudents;
