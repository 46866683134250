import React from 'react'
import './Square.scss' 
import sqaureShape from '../../../Assets/Images/square.png'
function Square() {
  return (
    <div className='sqaure_main_div'> 
        <div className='sqaure_shape1'> </div>
  

    </div>
  )
}

export default Square