import { getTasks } from "../../../Counsler/Redux/CounselorAppTasks/CounselorAppTasksActions";
import { axiosReq } from "../../../Utils";
import { getSingleApp, getSingleAppMissingDocs } from "../Application/ApplicationAction";
import { CreateApplicationAction } from "./CreateApplicationReducer";

export const createApplication = (body) => async (dispatch) => {
  dispatch(CreateApplicationAction.createApplicationRequest());
  const url = process.env.REACT_APP_API + "/application/apply";
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success)
      dispatch(CreateApplicationAction.createApplicationSuccess(res.data));
    else
      dispatch(
        CreateApplicationAction.createApplicationFail(
          res.data.response.data.message
        )
      );
  });
};

export const updateApplication = (id, body, send) => async (dispatch) => {
  dispatch(CreateApplicationAction.updateApplicationRequest());
  const url = process.env.REACT_APP_API + `/application/${id}`;
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(CreateApplicationAction.updateApplicationSuccess(res.data.id));
      if (send) {
        dispatch(getSingleApp(id));
      }
    
    } else
      dispatch(
        CreateApplicationAction.updateApplicationFail(
          res.data.response.data.message
        )
      );
  });
};

export const getUniversitySemesters = (id) => async (dispatch) => {
  dispatch(CreateApplicationAction.getUniversitiesSemestersRequest());
  const url =
    process.env.REACT_APP_API + `/university/semesters/${id}/available/`;
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(
        CreateApplicationAction.getUniversitiesSemestersSuccess(res.data)
      );
    else
      dispatch(
        CreateApplicationAction.getUniversitiesSemestersFail(
          res.data.response.data.message
        )
      );
  });
};

export const getAppDocument = (id) => async (dispatch) => {
  dispatch(CreateApplicationAction.getAppDocumentRequest());
  const url = process.env.REACT_APP_API + `/application/${id}/degree`;
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(CreateApplicationAction.getAppDocumentSuccess(res.data));
    else
      dispatch(
        CreateApplicationAction.getAppDocumentFail(
          res.data.response.data.message
        )
      );
  });
};

export const applyUsingUnified = (body) => async (dispatch) => {
  dispatch(CreateApplicationAction.applyUsingUnifiedApplicationRequest());
  const url = process.env.REACT_APP_API + "/application/apply/unified";
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success)
      dispatch(
        CreateApplicationAction.applyUsingUnifiedApplicationSuccess(res.data)
      );
    else
      dispatch(
        CreateApplicationAction.applyUsingUnifiedApplicationFail(
          res.data.response.data.message
        )
      );
  });
};

export const uploadOffer = (appId, doc) => async (dispatch) => {
  dispatch(CreateApplicationAction.uploadOfferRequest());
  const data = new FormData();
  data.append("document", doc);
  const url = process.env.REACT_APP_API + `/application/${appId}/offer`;
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(CreateApplicationAction.uploadOfferSuccess(res.data));
      dispatch(getSingleApp(appId));
    } else
      dispatch(
        CreateApplicationAction.uploadOfferFail(res.data.response.data.message)
      );
  });
};
