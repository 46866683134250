import React, { useEffect, useRef, useState } from "react";
import Input from "../Input/Input";
import "./OTP.scss";
import OtpInput from "react-otp-input";

function OTP({ setOtpValue, otpValue }) {
  const [errorState, setErrorState] = useState(false);
  //if there is an error, the error state will be true and the error class will be added to the input field

  const handleChange = (otp) => {
    setOtpValue(otp);
    if (otp.length === 4) {
      setOtpValue(otp);
    }
  };

  return (
    <span className="OTP_input_main">
      <OtpInput
        value={otpValue}
        onChange={(otp) => handleChange(otp)}
        numInputs={4}
        renderInput={(props) => <input {...props} />}
        containerStyle={"OTP_Main"}
        inputStyle="OTP_input"
        hasErrored={errorState}
        errorStyle="Error"
        isInputNum={true}
        inputType="number"
        isInputSecure={false}
        separator={<span></span>}
        placeholder=""
        focusStyle="OTP_input_focus"
        isDisabled={false}
        disabledStyle="OTP_input_disabled"
      />
    </span>
  );
}

export default OTP;
