import React from "react";
import "./UserTable.scss";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import comment from "../../../Shared/Assets/Images/comment.png";
import plus from "../../../Shared/Assets/Images/add.png";
import checked from "../../../Shared/Assets/Images/completed.png";
import Button from "../../../Shared/Components/Button/Button";
import { useNavigate } from "react-router-dom";

function UserTable({
  keys,
  data,
  type,
  setCommentIndex,
  checkedLeads,
  addChecked,
  selectAll,
  openPop,
  salesstudent,
}) {
  const navigate = useNavigate();
  return (
    <table className="userTable_main_div">
      {keys && (
        <tr className="userTable_header">
          {keys.map((key, index) => {
            if ((type == 1 && index == 0) || (type == 3 && index == 0))
              return (
                <th key={index}>
                  <div className="userTable_details_name">
                    <Checkbox
                      selected={checkedLeads?.length == data?.length && true}
                      click={true}
                      onClick={selectAll}
                    />
                    {key}
                  </div>
                </th>
              );
            else if (type == 2 && index == 0)
              return (
                <th key={index}>
                  <div className="userTable_details_name">
                    <Checkbox
                      selected={checkedLeads?.length == data?.length && true}
                      click={true}
                      onClick={selectAll}
                    />
                    {key}
                  </div>
                </th>
              );
            return <th key={index}>{key}</th>;
          })}
        </tr>
      )}
      {data &&
        data.map((user, index) => {
          return (
            <tr key={index} className="userTable_details">
              {Object.keys(user).map((el) => {
                if (el == "id") return null;
                else if (type == 2 && el == "status")
                  return (
                    <td
                      className={
                        user[el] == "New"
                          ? "userTable_pink"
                          : user[el] == "Archived" || user[el] == "Unqualified"
                          ? "userTable_yellow"
                          : user[el] == "Qualified"
                          ? "userTable_blue"
                          : "userTable_green"
                      }
                    >
                      {user[el]}
                    </td>
                  );
                else if (type == 1 && el == "email")
                  return (
                    <td className={!user[el] && "userTable_grey"}>
                      {user[el] ? user[el] : "N/A"}
                    </td>
                  );
                else if (type == 2 && el == "email")
                  return (
                    <td className={!user[el] && "userTable_grey"}>
                      {user[el] ? user[el] : "N/A"}
                    </td>
                  );
                else if (
                  (type == 1 && el == "name") ||
                  (type == 3 && el == "name")
                )
                  return (
                    <td>
                      {salesstudent ? (
                        <>
                          {type == 3 && el == "name" ? (
                            <div className="userTable_details_name">
                              <Checkbox
                                selected={checkedLeads.some(
                                  (lead) => lead.value === user.id
                                )}
                                click={true}
                                onClick={(e) => {
                                  addChecked(user.id);
                                }}
                              />
                              <div
                                className="userTable_detailsSpan"
                                onClick={(e) => {
                                  navigate(
                                    `/sales/students/singleStudent/${user.id}`
                                  );
                                }}
                              >
                                {user[el]}
                              </div>
                            </div>
                          ) : (
                            <div className="userTable_details_name">
                              <Checkbox
                                selected={checkedLeads.some(
                                  (lead) => lead.value === user.id
                                )}
                                click={true}
                                onClick={() => addChecked(user.id)}
                              />
                              {user[el]}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="userTable_details_name">
                          <Checkbox
                            selected={checkedLeads.includes(user.id)}
                            click={true}
                            onClick={() => addChecked(user.id)}
                          />
                          {user[el]}
                        </div>
                      )}
                    </td>
                  );
                else if (type <= 2 && el == "comment")
                  return (
                    <td onClick={() => setCommentIndex(index)}>
                      <img
                        src={comment}
                        alt="comment"
                        className="userTable_img"
                      />
                    </td>
                  );
                else if (type == 2 && el == "name")
                  return (
                    <td>
                      <div className="userTable_details_name_type2">
                        <div className="userTable_details_name_type2_2">
                          <Checkbox
                            selected={checkedLeads.some(
                              (lead) => lead.value === user.id
                            )}
                            click={true}
                            onClick={() => addChecked(user.id)}
                          />
                          <div
                            style={{
                              textAlign: "left",
                              fontFamily: "P_SemiBold",
                            }}
                          >
                            {user[el]}
                          </div>
                        </div>
                        <img
                          style={{ marginLeft: "20px" }}
                          src={plus}
                          alt="add"
                          className="userTable_img"
                          onClick={() =>
                            navigate("/sales/students/create", {
                              state: { lead: user?.id },
                            })
                          }
                        />
                      </div>
                    </td>
                  );
                else if (type == 3 && el == "status")
                  return (
                    <td
                      className={
                        user[el] == "Hot" ? "userTable_pink" : "userTable_green"
                      }
                    >
                      {user[el]}
                    </td>
                  );
                else if (type == 3 && el == "counselor")
                  return (
                    <td className={!user[el] && "userTable_grey"}>
                      {user[el] ? user[el] : "N/A"}
                    </td>
                  );
                else if (type == 3 && el == "phone")
                  return (
                    <td className={!user[el] && "userTable_grey"}>
                      {user[el] ? user[el] : "N/A"}
                    </td>
                  );
                else if (type == 3 && el == "meeting")
                  return (
                    <td>
                      {user[el] ? (
                        <div className="userTable_meeting">
                          Meeting Booked
                          <img
                            src={checked}
                            alt="checked"
                            className="userTable_img"
                          />
                        </div>
                      ) : (
                        <div className="userTable_meeting">
                          {" "}
                          <Button
                            text="Book Now"
                            width="80%"
                            color="blue"
                            onClick={() => openPop(user.id)}
                          />
                        </div>
                      )}
                    </td>
                  );
                else
                  return (
                    <td className={!user[el] && "userTable_grey"}>
                      {user[el] ? user[el] : "N/A"}{" "}
                    </td>
                  );
              })}
            </tr>
          );
        })}
    </table>
  );
}

export default UserTable;
