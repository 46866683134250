import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./MyCounsler.scss";
import info from "../../../Shared/Assets/Images/info.png";
import zoom from "../../../Shared/Assets/Images/zoom.png";
import Button from "../../../Shared/Components/Button/Button";
import arrow2 from "../../../Shared/Assets/Images/arrow2.webp";
import MyCounslerPanel from "../../Components/MyCounslerPanel/MyCounslerPanel";
import Popup from "../../../Shared/Components/Popup/Popup";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import DateInput from "../../../Shared/Components/DatePicker/DateInput";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import Input from "../../../Shared/Components/Input/Input";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import check from "../../../Shared/Assets/Images/checkbox.svg";
import ScheduleMeeting from "../../../Shared/Components/ScheduleMeeting/ScheduleMeeting";
import { getHelpReq } from "../../../Student/Redux/Overview/OverviewActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import {
  getChats,
  getDays,
  getStudentEvents,
  getStudentTasks,
} from "../../Redux/MyCounselor/MyCounselorActions";

function MyCounsler() {
  const [isAssigned, setIsAssigned] = useState(true);
  const [selectedButton, setSelectedButton] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setselectedTime] = useState();
  const [meetingSubject, setmeetingSubject] = useState("");
  const [show, setShow] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showUpperHelp, setShowUpperHelp] = useState(false);

  const { Student } = useSelector((state) => state?.userReducer?.user);
  const { user } = useSelector((state) => state?.userReducer);
  const { getUserLoading } = useSelector((state) => state?.userReducer);
  const { fetchEventsLoading, fetchTasksLoading, chat } = useSelector(
    (state) => state?.myCounselorReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudentEvents());
    dispatch(getStudentTasks());
    dispatch(getDays());
  }, []);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      dispatch(getChats(user?.id, user?.Student?.counselor?.id));
    }
  }, [user]);

  useEffect(() => {
    setMessages(chat);
  }, [chat]);

  const handleClick = (button) => {
    if (selectedButton == button) {
      setSelectedButton("");
    } else {
      setSelectedButton(button);
    }
  };

  const times = [
    { label: "12:00 PM", value: "12:00 PM" },
    { label: "1:00 PM", value: "1:00 PM" },
    { label: "2:00 PM", value: "2:00 PM" },
  ];

  return (
    <div className="myCounsler_container">
      {!getUserLoading && !fetchEventsLoading && !fetchTasksLoading ? (
        <>
          {Student?.counselor == null ? (
            <div className="myCounsler_top_no_counsler">
              <div className="popup_help">
                {showHelp && (
                  <Popup
                    setShowPopup={setShowHelp}
                    body={
                      <div className="popup_help_main">
                        <div className="popup_help_title">Need Help?</div>
                        <div className="popup_help_subtitle">
                          If you are having trouble with your application EB
                          will contact you!
                        </div>
                        <div className="popup_help_button">
                          <Button
                            text="Yes"
                            color={"pink"}
                            onClick={() => {
                              setShowUpperHelp(true);
                              setShowHelp(false);
                              dispatch(getHelpReq());
                            }}
                          />
                          <Button
                            text="Cancel"
                            color={"yellow"}
                            onClick={() => {
                              setShowHelp(false);
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                )}
                {showUpperHelp && (
                  <UpperPopUp
                    setShow={setShowUpperHelp}
                    title="We will contact you within 24 hours."
                    img={check}
                  />
                )}
              </div>
              <div className="myCounsler_top_no_counsler_top">
                <div className="left">
                  <div className="left_inner">
                    <img src={info} alt="info" className="info_img" />
                    <div>
                      You haven't been assigned to a counselor yet. We will
                      assign one once you finish your progress.
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="right_inner">
                    <Button
                      text="Need Help!"
                      color={"yellow"}
                      onClick={() => setShowHelp(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="myCounsler_top">
              <div
                className={
                  selectedButton != ""
                    ? "myCounsler_top_button"
                    : "myCounsler_top_button myCounsler_top_button_closed"
                }
              >
                <div className="myCounsler_top_button_inner">
                  <div
                    className={
                      selectedButton == "upcoming_events"
                        ? "button_div blue_background selected_button"
                        : "button_div blue_background "
                    }
                    onClick={() => handleClick("upcoming_events")}
                  >
                    <div className="text">
                      <div className="font">Upcoming Events with Counselor</div>
                      <img src={arrow2} alt="arrow" className="aroow_img" />
                    </div>
                  </div>

                  <div
                    className={
                      selectedButton == "chat"
                        ? "button_div green_background selected_button"
                        : "button_div green_background "
                    }
                    onClick={() => handleClick("chat")}
                  >
                    <div className="text">
                      <div className="font">Chat with Counselor</div>
                      <img src={arrow2} alt="arrow" className="aroow_img" />
                    </div>
                  </div>
                  <div
                    className={
                      selectedButton == "assigned_tasks"
                        ? "button_div pink_background selected_button"
                        : "button_div pink_background "
                    }
                    onClick={() => handleClick("assigned_tasks")}
                  >
                    <div className="text">
                      <div className="font">Assigned Tasks</div>
                      <img src={arrow2} alt="arrow" className="aroow_img" />
                    </div>
                  </div>
                  <div
                    className={
                      selectedButton == "meet"
                        ? "button_div yellow_background selected_button"
                        : "button_div yellow_background "
                    }
                    onClick={() => handleClick("meet")}
                  >
                    <div className="text">
                      <div className="font">Meet Your Counselor</div>
                      <img src={arrow2} alt="arrow" className="aroow_img" />
                    </div>
                  </div>
                </div>
              </div>
              {selectedButton != "" && (
                <div
                  className={
                    selectedButton == "upcoming_events"
                      ? "myCounsler_top_details_open blue_background"
                      : selectedButton == "assigned_tasks"
                      ? "myCounsler_top_details_open pink_background"
                      : selectedButton == "chat"
                      ? "myCounsler_top_details_open green_background"
                      : "myCounsler_top_details_open"
                  }
                >
                  <MyCounslerPanel
                    subject={selectedButton}
                    setSelected={setSelectedButton}
                    messages={messages}
                    setMessages={setMessages}
                  />
                </div>
              )}
            </div>
          )}
          <div className="myCounsler_bottom">
            <div
              className={
                selectedButton == ""
                  ? "myCounsler_bottom_inner_left"
                  : "myCounsler_bottom_inner_left myCounsler_bottom_inner_left_closed"
              }
            >
              <div className="myCounsler_bottom_inner_left_inner">
                <div className="myCounsler_bottom_inner_left_inner_text">
                  Explore, Apply And Grow Your Education
                </div>
                <Button
                  text={"Take The Career Test"}
                  color={"yellow"}
                  width={"17rem"}
                />
              </div>
            </div>
            {selectedButton == "" && (
              <div className="myCounsler_bottom_inner_right">
                <div className="myCounsler_bottom_inner_right_inner">
                  <img src={zoom} alt="zoom" className="zoom_icon" />
                  <Button
                    text="Schedule Meeting"
                    color={"yellow"}
                    width={"20rem"}
                    disabled={Student?.counselor == null}
                    onClick={() => setShowPopup(true)}
                  />
                  <span className="mobile_button">
                    <Button
                      text="Take The Career Test"
                      color={"yellow"}
                      width={"20rem"}
                      disabled={false}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="popup_zoom">
            <ScheduleMeeting
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              selectedDay={selectedDay}
              selectedTime={selectedTime}
              setselectedTime={setselectedTime}
              setSelectedDay={setSelectedDay}
              times={times}
              meetingSubject={meetingSubject}
              setmeetingSubject={setmeetingSubject}
              setShow={setShow}
            />
          </div>
          {show && (
            <UpperPopUp
              setShow={setShow}
              title="Your zoom request has been sent."
              img={check}
            />
          )}
        </>
      ) : (
        <Loader fullScreen={true} />
      )}
    </div>
  );
}

export default MyCounsler;
