import axios from "axios";
import { axiosReq } from "../../../Utils";
import { NotificationActions } from "./NotificationReducer";

export const getAllNotifications = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/user/notifications";
  dispatch(NotificationActions.getNotificationsRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(NotificationActions.getNotificationsSuccess(res.data));
    else
      dispatch(
        NotificationActions.getNotificationsError(
          res.data.response.data.message
        )
      );
  });
};

export const deleteAllNotifications = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/user/notifications";
  dispatch(NotificationActions.deleteAllNotificationsRequest());
  dispatch(axiosReq("delete", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(NotificationActions.deleteAllNotificationsSuccess());
      dispatch(getAllNotifications());
    } else
      dispatch(
        NotificationActions.deleteAllNotificationsError(
          res.data.response.data.message
        )
      );
  });
};

export const deleteSingleNotifications = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/user/notification/" + id;
  dispatch(NotificationActions.deleteSingleNotificationRequest());
  dispatch(axiosReq("delete", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(NotificationActions.deleteSingleNotificationSuccess());
      dispatch(getAllNotifications());
    } else
      dispatch(
        NotificationActions.deleteSingleNotificationError(
          res.data.response.data.message
        )
      );
  });
};
