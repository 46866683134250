import React, { useEffect, useState } from "react";
import "./Input.scss";

//images import
import shown_pass from "../../Assets/Images/shown_pass.webp";

function Input({
  placeholder,
  type,
  width,
  disabled,
  onChange,
  onClick,
  onBlur,
  required,
  value,
  max,
  min
}) {
  const [input_type, setInput_type] = useState("");

  useEffect(() => {
    setInput_type(type);
  }, [type]);

  if (type != "password") {
    return (
      <input
        type={type}
        placeholder={placeholder}
        style={{
          width: width ? `calc(${width} - (2rem + 6px))` : "fit-content",
        }}
        className="Input"
        onChange={onChange}
        disabled={disabled ? disabled : false}
        required={required}
        value={value}
        max={type === "number" && max}
        min={type === "number" && min}
      />
    );
  } else {
    return (
      <>
        <span
          className="Input"
          style={{
            width: width ? `calc(${width} - (2rem + 6px))` : "fit-content",
            minWidth: width ? `calc(${width} - (2rem + 6px))` : "fit-content",
          }}
        >
          <input
            type={input_type}
            placeholder={placeholder}
            className="Input_Pass"
            onChange={onChange}
            onClick={onClick}
            onBlur={onBlur}
            disabled={disabled ? disabled : false}
            required={required}
            value={value}
          />
          <div className="Input_right_div">
            <img
              src={shown_pass}
              className="eye_icon"
              onClick={() => {
                input_type == "password"
                  ? setInput_type("text")
                  : setInput_type("password");
              }}
            />
            {input_type != "password" && (
              <hr
                className="eye_closed"
                onClick={() => {
                  input_type == "password"
                    ? setInput_type("text")
                    : setInput_type("password");
                }}
              />
            )}
          </div>
        </span>
      </>
    );
  }
}

export default Input;
