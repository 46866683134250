import React, { useEffect, useLayoutEffect, useState } from "react";
import "./FormLayout.scss";
import ProgressBar from "../../../Student/Components/ProgressBar/ProgressBar";
import MainCard from "../MainCard/MainCard";
import Loader from "../Loader/Loader";
function FormLayout(props) {
  //go back route if there is one : back
  //show steps (true or false): showSteps
  // which step : step
  //total nb of steps : totalSteps
  //for the main card: header, subHeader, body, width, minHeight
  //which icon to use for animation: icon

  const [nbCol, setNbCol] = useState(6);
  const [nbRow, setNbRow] = useState(3);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  useEffect(() => {
    if (width > 1030) {
      setNbRow(3);
      setNbCol(6);
    } else if (width <= 1030 && width > 890) {
      setNbRow(2);
      setNbCol(6);
    } else if (width <= 890 && width > 550) {
      setNbRow(1);
      setNbCol(6);
    } else if (width <= 550) {
      setNbRow(5);
      setNbCol(1);
    }
  }, [useWindowSize]);
  return (
    <div>
      <div className="formLayout_main_div">
        <div className="formLayout_card">
          <ProgressBar
            step={props.step}
            steps={props.totalSteps}
            goBack={props.back}
            showSteps={props.showSteps}
          />
          {!props.loading ? (
            <MainCard
              header={props.header}
              subHeader={props.subHeader}
              minHeight={props.minHeight}
              width={props.width}
              body={props.body}
            />
          ) : (
            <Loader />
          )}
        </div>

        <div
          className="formLayout_side"
          style={{ backgroundColor: props.bg, gap: props.gap }}
        >
          {Array.from(Array(nbRow), (el, i) => (
            <div
            key={i}
              className={
                !props.triangle
                  ? "formLayout_col"
                  : "formLayout_col rotate_icon"
              }
            >
              {Array.from(Array(nbCol), (el, i) => props.icon)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FormLayout;
