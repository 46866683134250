import { axiosReq } from "../../../Utils";
import {
  getProfileAddress,
  getProfileEmergency,
  getProfileExp,
} from "../Student/StudentActions";
import { completeProfileActions } from "./CompleteProfileReducer";
import { useNavigate } from "react-router-dom";
export const addAdress = (address, setSteps) => async (dispatch) => {
  dispatch(completeProfileActions.addAddressRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/student/address",
      {},
      address
    )
  ).then((res) => {
    if (res.success) {
      dispatch(completeProfileActions.addAddressSuccess(res.data));
      setSteps && setSteps("2");
    } else {
      dispatch(
        completeProfileActions.addAddressFail(res.data.response.data.message)
      );
    }
  });
};

export const addExp =
  (exp, setSteps, setOpenPopup, stid) => async (dispatch) => {
    dispatch(completeProfileActions.addExperienceRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/student/experience",
        {},
        { workExperiences: exp }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(completeProfileActions.addExperienceSuccess(res.data));
        setSteps && setSteps("3");
        setOpenPopup && setOpenPopup(false);
        if (stid) {
          dispatch(getProfileExp(stid, false));
        }
      } else {
        dispatch(
          completeProfileActions.addExperienceFail(
            res.data.response.data.message
          )
        );
      }
    });
  };

export const deleteExp = (id, stid, student) => async (dispatch) => {
  dispatch(completeProfileActions.deleteExpRequest());
  dispatch(
    axiosReq(
      "delete",
      process.env.REACT_APP_API + "/student/experience/" + id,
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(completeProfileActions.deleteExpSuccess());
      if (student) {
        dispatch(getProfileExp("", true));
      }
      dispatch(getProfileExp(stid, false));
    } else {
      dispatch(
        completeProfileActions.deleteExpFail(res.data.response.data.message)
      );
    }
  });
};

export const addContact =
  (navigate, contact, stid, couns) => async (dispatch) => {
    dispatch(completeProfileActions.addContactRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/student/emergencyContact",
        {},
        { emergencyContacts: contact }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(completeProfileActions.addContactSuccess(res.data));
        if (stid) {
          dispatch(getProfileEmergency(navigate, stid, false));
          if (couns) {
            navigate("/counselor/students/singleStudent/" + stid);
          } else {
            navigate("/sales/students/singleStudent/" + stid);
          }
        } else {
          dispatch(getProfileEmergency(navigate, "", true));
          navigate("/student/profile");
        }
      } else {
        dispatch(
          completeProfileActions.addContactFail(res.data.response.data.message)
        );
      }
    });
  };

export const updateAddress =
  (address, id, stid, setSteps, step) => async (dispatch) => {
    dispatch(completeProfileActions.updateAddressRequest());
    dispatch(
      axiosReq(
        "patch",
        process.env.REACT_APP_API + "/student/address/" + id,
        {},
        address
      )
    ).then((res) => {
      if (res.success) {
        dispatch(completeProfileActions.updateAddressSuccess());
        if (setSteps) setSteps(step);
        else {
          if (stid) {
            dispatch(getProfileAddress(stid, false));
          } else {
            dispatch(getProfileAddress("", true));
          }
        }
      } else {
        dispatch(
          completeProfileActions.updateAddressFail(
            res.data.response.data.message
          )
        );
      }
    });
  };

export const updateWorkExp = (exp, id, stid) => async (dispatch) => {
  dispatch(completeProfileActions.updateExpRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/student/experience/" + id,
      {},
      exp
    )
  ).then((res) => {
    if (res.success) {
      dispatch(completeProfileActions.updateExpSuccess());
      if (stid) {
        dispatch(getProfileExp(stid, false));
      } else {
        dispatch(getProfileExp("", true));
      }
    } else {
      dispatch(
        completeProfileActions.updateExpFail(res.data.response.data.message)
      );
    }
  });
};

export const updateContact =
  (contact, id, stid, navigate) => async (dispatch) => {
    dispatch(completeProfileActions.updateContactRequest());
    dispatch(
      axiosReq(
        "patch",
        process.env.REACT_APP_API + "/student/emergencyContact/" + id,
        {},
        contact
      )
    ).then((res) => {
      if (res.success) {
        dispatch(completeProfileActions.updateContactSuccess());
        if (stid) {
          dispatch(getProfileEmergency(navigate, stid, false));
        } else {
          dispatch(getProfileEmergency(navigate, "", true));
        }
      } else {
        dispatch(
          completeProfileActions.updateContactFail(
            res.data.response.data.message
          )
        );
      }
    });
  };

export const deleteContact = (id, stid, navigate) => async (dispatch) => {
  dispatch(completeProfileActions.deleteContactRequest());
  dispatch(
    axiosReq(
      "delete",
      process.env.REACT_APP_API + "/student/emergencyContact/" + id,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(completeProfileActions.deleteContactSuccess());
      if (stid) {
        dispatch(getProfileEmergency(navigate, stid, false));
      } else {
        dispatch(getProfileEmergency(navigate, "", true));
      }
    } else {
      dispatch(
        completeProfileActions.deleteContactFail(res.data.response.data.message)
      );
    }
  });
};
