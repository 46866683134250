import React, { useEffect, useRef, useState } from "react";
import "./CounselorChatC.scss";
import defaultImg from "../../../Shared/Assets/Images/default.jpeg";
import attachment from "../../../Shared/Assets/Images/attachment.png";
import send from "../../../Shared/Assets/Images/send.png";
import cancel from "../../../Shared/Assets/Images/close.webp";
import { getCounselorMessages } from "../../Redux/CounselorChat/CounselorChatActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Shared/Components/Loader/Loader";
import { sendMessage } from "../../../Student/Redux/MyCounselor/MyCounselorActions";
import Button from "../../../Shared/Components/Button/Button";
import { CounselorChatActions } from "../../Redux/CounselorChat/CounselorChatReducer";
import { handlePreview } from "../../../Utils";
import document from "../../../Shared/Assets/Images/document.png";

function CounselorChat({
  user,
  setOpenMobile,
  selectedIndex,
  setselectedIndex,
  Chats,
  setChats,
}) {
  let currentSender = "";

  const divRef = useRef(null);

  const [messageChat, setMessageChat] = useState("");

  const counselor = useSelector((state) => state.userReducer.user);

  const dispatch = useDispatch();

  const messages = useSelector((state) => state.CounselorChatReducer.messages);

  const { getAllChatsLoading, getMessagesLoading } = useSelector(
    (state) => state.CounselorChatReducer
  );

  useEffect(() => {
    if (user && counselor.hasOwnProperty("id"))
      dispatch(getCounselorMessages(user?.user?.id, counselor.id));
  }, [user, counselor]);

  useEffect(() => {
    // Scroll to the bottom of the div when it is loaded
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the div when it is loaded
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [divRef.current, messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (messageChat != "")
      dispatch(
        CounselorChatActions.addMessage({
          id: Math.random(),
          content: messageChat,
          file: null,
          timestamp: new Date().getTime(),
          counselor: { id: user.user.id },
          user: { id: user.counselor.id },
          conversation: user.id,
        })
      );
    if (messageChat != "") {
      dispatch(
        sendMessage(
          user.id,
          messageChat,
          counselor.id,
          user.user.id,
          user.user.fcm_token,
          "message"
        )
      );
      setMessageChat("");
    }
  };

  const handleDocChane = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        sendMessage(
          user.id,
          "file",
          counselor.id,
          user.user.id,
          user.user.fcm_token,
          "file",
          file
        )
      );
    }
  };

  const fileUploadRef = useRef(null);

  const checkSelectedIndex = () => {
    if (selectedIndex != 0) {
      const c = [...Chats];
      [c[selectedIndex], c[0]] = [c[0], c[selectedIndex]];
      setChats(c);
      setselectedIndex(0);
    }
  };

  return (
    <div className="CounselorChat_container">
      <img
        src={cancel}
        alt={"close"}
        className="close_img"
        onClick={() => {
          setOpenMobile(false);
          setselectedIndex(undefined);
        }}
      />
      {(getMessagesLoading || getAllChatsLoading) && (
        <Loader fullScreen={true} />
      )}
      {Chats && Chats.length > 0 && (
        <div className="counselor_chat_top">
          <img
            src={
              user?.user?.profile_picture == null
                ? defaultImg
                : process.env.REACT_APP_API +
                  "/files/students/" +
                  user?.user?.student?.id +
                  "/" +
                  user?.user?.profile_picture
            }
            alt="image"
            className="user_image"
          />
          <div className="user_name">
            {user?.user?.first_name + " " + user?.user?.last_name}
          </div>
        </div>
      )}
      <div className="counselor_chat_middle" ref={divRef}>
        {messages.map((message, index) => {
          let title = "";
          if (
            message?.receiver?.id !== counselor?.id &&
            currentSender !== "You"
          ) {
            title = "You";
            currentSender = "You";
          } else if (
            message?.receiver?.id === counselor?.id &&
            currentSender !== user?.user?.first_name
          ) {
            title = user?.user?.first_name;
            currentSender = user?.user?.first_name;
          }
          return message?.receiver?.id !== counselor?.id ? (
            message.file == null ? (
              <>
                <div className="title">{title}</div>
                <div className="sent_by_me" key={index}>
                  <div className="text">{message.content}</div>
                </div>
              </>
            ) : (
              <>
                <div className="title">{title}</div>
                <div
                  className="sent_by_me"
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() =>
                    handlePreview(
                      "messages/" + message.id + "/file/" + message.file
                    )
                  }
                >
                  <img src={document} alt="file" />
                  <div className="text">{message.file}</div>
                </div>
              </>
            )
          ) : message.file === null ? (
            <>
              <div className="counselor_title">{title}</div>
              <div className="sent_to_me" key={index}>
                <div className="text">{message.content}</div>
              </div>
            </>
          ) : (
            <>
              <div className="counselor_title">{title}</div>
              <div
                className="sent_to_me"
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() =>
                  handlePreview(
                    "messages/" + message.id + "/file/" + message.file
                  )
                }
              >
                <img src={document} alt="file" />
                <div className="text">{message.file}</div>
              </div>
            </>
          );
        })}
      </div>
      {Chats && Chats.length > 0 && (
        <div className="counselor_chat_bottom">
          <div className="inner">
            <form
              onSubmit={(e) => {
                handleSendMessage(e);
                checkSelectedIndex();
              }}
            >
              <div className="counselor_chat_bottom_left">
                <input
                  value={messageChat}
                  onChange={(e) => setMessageChat(e.target.value)}
                />
              </div>
              <div className="counselor_chat_bottom_right">
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "0",
                    margin: "0",
                  }}
                  type="button"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                >
                  <img
                    src={attachment}
                    alt="attachment"
                    className="attachment_icon"
                  />
                </button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png, .pdf, .doc, .xls"
                  ref={fileUploadRef}
                  onChange={(e) => {
                    handleDocChane(e);
                  }}
                />
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "0",
                    margin: "0",
                  }}
                  type="submit"
                >
                  <img
                    src={send}
                    alt="send"
                    className="send_icon"
                    onClick={(e) => {
                      handleSendMessage(e);
                      checkSelectedIndex();
                    }}
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CounselorChat;
