import React, { useState } from "react";
import "./OBStep6.scss";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";

import SingleSelect from "../../../../Shared/Components/SingleSelect/SingleSelect";
import Square from "../../../../Shared/Components/Animations/Square/Square";
import OBStep7 from "../OBStep7/OBStep7";
function OBStep6({ step, setStep, student, setStudent, counselor }) {
  const [budget, setBudget] = useState("");

  const budgets = [
    "0 to 5.000$",
    "5.000 to 10.000$",
    "10.000 to 15.000$",
    "15.000$ +",
  ];

  const onSubmit = () => {
    let choseBud = [];
    if (budget == "0 to 5.000$") choseBud = [0, 5000];
    if (budget == "5.000 to 10.000$") choseBud = [5000, 10000];
    if (budget == "10.000 to 15.000$") choseBud = [10000, 15000];
    if (budget == "15.000$ +") choseBud = [15000];

    setStudent({ ...student, budget: choseBud });
    if (budget != "") setStep(7);
  };

  return (
    <div className="obStep_main_div">
      {step == 6 ? (
        <FormLayout
          back={() => setStep(5)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={
            counselor
              ? "Choose budget for university tuition fee"
              : "What is your budget for university tuition fee? "
          }
          minHeight="fit-content"
          width="90%"
          body={
            <div className="step_body">
              <SingleSelect
                data={budgets}
                setSelected={setBudget}
                width="236px"
                selectedIndex={budget}
              />
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={budget == 0 && true}
              />
            </div>
          }
          icon={<Square />}
          bg="var(--yellow)"
          gap="50px 20px"
        />
      ) : (
        <OBStep7
          step={step}
          setStep={setStep}
          student={student}
          counselor={counselor}
          setStudent={setStudent}
        />
      )}
    </div>
  );
}

export default OBStep6;
