import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./NavBar.scss";
import logo from "../../Assets/Images/logo.webp";
import notification from "../../Assets/Images/bell.svg";
import arrow_up from "../../Assets/Images/arrow_up.svg";
import { AiOutlineMenu } from "react-icons/ai";
import avatar from "../../Assets/Images/default.jpeg";
import Button from "../Button/Button";
import close from "../../Assets/Images/close.webp";
import profile from "../../Assets/Images/profile.svg";
import logout from "../../Assets/Images/logout.svg";
import Popup from "../../Components/Popup/Popup";
import { logoutUser } from "../../../Student/Redux/User/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import hasItemsInIndexedDB, { capitalizeFirstLetter } from "../../../Utils";
import {
  deleteAllNotifications,
  deleteSingleNotifications,
  getAllNotifications,
} from "../../Redux/Notification/NotificationActions";
import not_dot from "../../Assets/Images/not_dot.png";
import { NotificationActions } from "../../Redux/Notification/NotificationReducer";
import { openDB } from "idb";
import useSound from "use-sound";
import bell_sound from "../../Assets/sounds/mixkit-phone-ring-bell-593.wav";
import yellowCircle from "../../Assets/Images/yellowCircle.png";
import blueCircle from "../../Assets/Images/blueCircle.png";
import pinkCircle from "../../Assets/Images/pinkCircle.png";
import greenCircle from "../../Assets/Images/greenCircle.png";

function NavBar() {
  const dispatch = useDispatch();

  const [play] = useSound(bell_sound);

  const { user, logoutLoading, logoutError, logoutSuccess } = useSelector(
    (state) => state.userReducer
  );

  const userRole = useSelector((state) => state.userReducer.role);
  const { student } = useSelector((state) => state.StudentReducer);

  useEffect(() => {
    if (logoutSuccess) {
      setShowPopup(false);
      navigate("/login");
    }
  }, [logoutSuccess]);

  const pages = [
    "Overview",
    "Universities",
    "My Applications",
    "My Counselor",
    "My Documents",
  ];
  const Counslerpages = [
    "Overview",
    "Students",
    "Applications",
    "Universities",
    "Chat",
    "Meeting",
  ];
  const Salespages = [
    "Leads",
    "Students",
    "Counselor",
    "Universities",
    "Sources",
    "CRM",
  ];

  // const notifications = [
  //   "You have a new message from your counselor",
  //   "check out our new university",
  // ];

  const {
    notifications,
    notificationReceived,
    getNotificationsLoading,
    deleteAllNotificationsLoading,
    deleteSingleNotificationLoading,
  } = useSelector((state) => state.notificationReducer);

  const [chosenPage, setchosenPage] = useState("Overview");
  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [role, seTrole] = useState("");

  useEffect(() => {
    if (userRole) seTrole(capitalizeFirstLetter(userRole));
    else {
      seTrole("");
    }
  }, [userRole]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !pathname.includes("login") &&
      !pathname.includes("signUp") &&
      pathname != "/" &&
      !pathname.includes("privacyPolicy") &&
      !pathname.includes("documentation") &&
      !pathname.includes("termsAndConditions") &&
      !pathname.includes("contactUs")
    )
      dispatch(getAllNotifications());
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  useEffect(() => {
    if (chosenPage != "notification") setShowNotification(false);
  }, [chosenPage]);

  useEffect(() => {
    if (pathname.includes("/profile")) {
      setchosenPage("profile");
    }

    if (
      pathname.includes(pages[0].toLowerCase().replace(" ", "_")) &&
      role == "Student"
    ) {
      setchosenPage(pages[0]);
    }
    if (
      pathname.includes(pages[1].toLowerCase().replace(" ", "_")) &&
      role == "Student"
    ) {
      setchosenPage(pages[1]);
    }
    if (
      pathname.includes(pages[2].toLowerCase().replace(" ", "_")) &&
      role == "Student"
    ) {
      setchosenPage(pages[2]);
    }
    if (
      pathname.includes(pages[3].toLowerCase().replace(" ", "_")) &&
      role == "Student"
    ) {
      setchosenPage(pages[3]);
    }
    if (
      pathname.includes(pages[4].toLowerCase().replace(" ", "_")) &&
      role == "Student"
    ) {
      setchosenPage(pages[4]);
    }
    if (
      pathname.includes(Counslerpages[0].toLowerCase().replace(" ", "_")) &&
      role == "Counselor"
    ) {
      setchosenPage(Counslerpages[0]);
    }
    if (
      pathname.includes(Counslerpages[1].toLowerCase().replace(" ", "_")) &&
      role == "Counselor"
    ) {
      setchosenPage(Counslerpages[1]);
    }
    if (
      pathname.includes(Counslerpages[2].toLowerCase().replace(" ", "_")) &&
      role == "Counselor"
    ) {
      setchosenPage(Counslerpages[2]);
    }
    if (
      pathname.includes(Counslerpages[3].toLowerCase().replace(" ", "_")) &&
      role == "Counselor"
    ) {
      setchosenPage(Counslerpages[3]);
    }
    if (
      pathname.includes(Counslerpages[4].toLowerCase().replace(" ", "_")) &&
      role == "Counselor"
    ) {
      setchosenPage(Counslerpages[4]);
    }
    if (
      pathname.includes(Counslerpages[5].toLowerCase().replace(" ", "_")) &&
      role == "Counselor"
    ) {
      setchosenPage(Counslerpages[5]);
    }
    if (
      pathname.includes(Salespages[0].toLowerCase().replace(" ", "_")) &&
      role == "Sales"
    ) {
      setchosenPage(Salespages[0]);
    }
    if (
      pathname.includes(Salespages[1].toLowerCase().replace(" ", "_")) &&
      role == "Sales"
    ) {
      setchosenPage(Salespages[1]);
    }
    if (
      pathname.includes(Salespages[2].toLowerCase().replace(" ", "_")) &&
      role == "Sales"
    ) {
      setchosenPage(Salespages[2]);
    }
    if (
      pathname.includes(Salespages[3].toLowerCase().replace(" ", "_")) &&
      role == "Sales"
    ) {
      setchosenPage(Salespages[3]);
    }
    if (
      pathname.includes(Salespages[4].toLowerCase().replace(" ", "_")) &&
      role == "Sales"
    ) {
      setchosenPage(Salespages[4]);
    }
    if (
      pathname.includes(Salespages[5].toLowerCase().replace(" ", "_")) &&
      role == "Sales"
    ) {
      setchosenPage(Salespages[5]);
    }
  }, [pathname, role]);

  useEffect(() => {
    if (!showNotification && !showProfile) {
      if (
        pathname.includes(pages[0].toLowerCase().replace(" ", "_")) &&
        role == "Student"
      ) {
        setchosenPage(pages[0]);
      }
      if (
        pathname.includes(pages[1].toLowerCase().replace(" ", "_")) &&
        role == "Student"
      ) {
        setchosenPage(pages[1]);
      }
      if (
        pathname.includes(pages[2].toLowerCase().replace(" ", "_")) &&
        role == "Student"
      ) {
        setchosenPage(pages[2]);
      }
      if (
        pathname.includes(pages[3].toLowerCase().replace(" ", "_")) &&
        role == "Student"
      ) {
        setchosenPage(pages[3]);
      }
      if (
        pathname.includes(pages[4].toLowerCase().replace(" ", "_")) &&
        role == "Student"
      ) {
        setchosenPage(pages[4]);
      }
      if (
        pathname.includes(Counslerpages[0].toLowerCase().replace(" ", "_")) &&
        role == "Counselor"
      ) {
        setchosenPage(Counslerpages[0]);
      }
      if (
        pathname.includes(Counslerpages[1].toLowerCase().replace(" ", "_")) &&
        role == "Counselor"
      ) {
        setchosenPage(Counslerpages[1]);
      }
      if (
        pathname.includes(Counslerpages[2].toLowerCase().replace(" ", "_")) &&
        role == "Counselor"
      ) {
        setchosenPage(Counslerpages[2]);
      }
      if (
        pathname.includes(Counslerpages[3].toLowerCase().replace(" ", "_")) &&
        role == "Counselor"
      ) {
        setchosenPage(Counslerpages[3]);
      }
      if (
        pathname.includes(Counslerpages[4].toLowerCase().replace(" ", "_")) &&
        role == "Counselor"
      ) {
        setchosenPage(Counslerpages[4]);
      }
      if (
        pathname.includes(Counslerpages[5].toLowerCase().replace(" ", "_")) &&
        role == "Counselor"
      ) {
        setchosenPage(Counslerpages[5]);
      }
      if (pathname.includes("profile")) {
        setchosenPage("profile");
      }
      if (
        pathname.includes(Salespages[0].toLowerCase().replace(" ", "_")) &&
        role == "Sales"
      ) {
        setchosenPage(Salespages[0]);
      }
      if (
        pathname.includes(Salespages[1].toLowerCase().replace(" ", "_")) &&
        role == "Sales"
      ) {
        setchosenPage(Salespages[1]);
      }
      if (
        pathname.includes(Salespages[2].toLowerCase().replace(" ", "_")) &&
        role == "Sales"
      ) {
        setchosenPage(Salespages[2]);
      }
      if (
        pathname.includes(Salespages[3].toLowerCase().replace(" ", "_")) &&
        role == "Sales"
      ) {
        setchosenPage(Salespages[3]);
      }
      if (
        pathname.includes(Salespages[4].toLowerCase().replace(" ", "_")) &&
        role == "Sales"
      ) {
        setchosenPage(Salespages[4]);
      }
      if (
        pathname.includes(Salespages[5].toLowerCase().replace(" ", "_")) &&
        role == "Sales"
      ) {
        setchosenPage(Salespages[5]);
      }
      if (pathname.includes("profile")) {
        setchosenPage("profile");
      }
    }
  }, [showNotification, showProfile]);

  const handleNotificationClick = () => {
    deleteItemFromIndexedDB();
    setchosenPage("notification");
    setShowNotification(!showNotification);
    if (notificationReceived) {
      dispatch(getAllNotifications());
      dispatch(NotificationActions.readNotification());
    }
  };

  useEffect(() => {
    if (notificationReceived) {
      play();
    }
  }, [notificationReceived]);

  async function getBackNotification() {
    try {
      const hasItems = await hasItemsInIndexedDB(dispatch);
      return hasItems;
      // Perform any desired operations based on the result
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors that occurred during the IndexedDB operations
    }
  }

  useEffect(() => {
    getBackNotification().then((res) => {
      if (res) dispatch(NotificationActions.notificationReceived());
    });
  }, []);

  function handleVisibilityChange() {
    if (document.visibilityState === "visible") {
      getBackNotification().then((res) => {
        if (res) dispatch(NotificationActions.notificationReceived());
      });
    }
  }

  document.addEventListener("visibilitychange", handleVisibilityChange);

  const deleteItemFromIndexedDB = () => {
    openDB("notificationsDBb", 1).then((db) => {
      const transaction = db.transaction("notificationss", "readwrite");
      const store = transaction.objectStore("notificationss");

      store.getAllKeys().then((keys) => {
        const deletePromises = keys.map((key) => store.delete(key));

        Promise.all(deletePromises).then(() => {});
      });
    });
  };

  const markAllAsRead = () => {
    dispatch(deleteAllNotifications());
  };

  const deleteSingleNotification = (id) => {
    dispatch(deleteSingleNotifications(id));
  };

  return (
    <div className="NavBar_Container">
      {role != "" && role != "Admin" && (
        <img
          src={arrow_up}
          alt="arrow_up"
          className={
            chosenPage == pages[0] && (role == "Student" || role == "Counselor")
              ? "arrow_up first_selection"
              : chosenPage == pages[1] && role == "Student"
              ? "arrow_up second_selection"
              : chosenPage == pages[2] && role == "Student"
              ? "arrow_up third_selection"
              : chosenPage == pages[3] && role == "Student"
              ? "arrow_up forth_selection"
              : chosenPage == pages[4] && role == "Student"
              ? "arrow_up fifth_selection"
              : chosenPage == "notification"
              ? "arrow_up sixth_selection"
              : chosenPage == "profile"
              ? "arrow_up seventh_selection"
              : chosenPage == Counslerpages[0] && role == "Counselor"
              ? "arrow_up first_selection"
              : chosenPage == Counslerpages[1] && role == "Counselor"
              ? "arrow_up counsler_second_selection"
              : chosenPage == Counslerpages[2] && role == "Counselor"
              ? "arrow_up counsler_third_selection"
              : chosenPage == Counslerpages[3] && role == "Counselor"
              ? "arrow_up counsler_forth_selection"
              : chosenPage == Counslerpages[4] && role == "Counselor"
              ? "arrow_up counsler_fifth_selection"
              : chosenPage == Counslerpages[5] && role == "Counselor"
              ? "arrow_up counsler_sixth_selection"
              : chosenPage == Salespages[0]
              ? "arrow_up sales_first_selection"
              : chosenPage == Salespages[1]
              ? "arrow_up sales_second_selection"
              : chosenPage == Salespages[2]
              ? "arrow_up sales_third_selection"
              : chosenPage == Salespages[3]
              ? "arrow_up sales_forth_selection"
              : chosenPage == Salespages[4]
              ? "arrow_up sales_fifth_selection"
              : chosenPage == Salespages[5]
              ? "arrow_up sales_sixth_selection"
              : "arrow_up"
          }
        />
      )}
      <div className="NavBar">
        <div className="NavBar_Left">
          <div className="Logo" style={{ cursor: role == "" && "pointer" }}>
            <img
              src={logo}
              alt="logo"
              className="Logo_image"
              onClick={() => {
                role == "" && navigate("/");
              }}
            />
          </div>
          {role != "" && role != "Admin" && (
            <div className="Pages">
              {role == "Student"
                ? pages.map((page, index) => (
                    <div
                      className={
                        pathname.includes(page.toLowerCase().replace(" ", "_"))
                          ? "Page selected"
                          : "Page"
                      }
                      key={index}
                      onClick={() => {
                        setchosenPage(page);
                        navigate(
                          "/" +
                            role.toLowerCase() +
                            "/" +
                            page.toLowerCase().replace(" ", "_")
                        );
                      }}
                    >
                      {page}
                    </div>
                  ))
                : role == "Counselor"
                ? Counslerpages.map((page, index) => (
                    <div
                      className={
                        pathname.includes(page.toLowerCase().replace(" ", "_"))
                          ? "Page selected"
                          : "Page"
                      }
                      key={index}
                      onClick={() => {
                        setchosenPage(page);
                        navigate(
                          "/" +
                            role.toLowerCase() +
                            "/" +
                            page.toLowerCase().replace(" ", "_")
                        );
                      }}
                    >
                      {page}
                    </div>
                  ))
                : Salespages.map((page, index) => (
                    <div
                      className={
                        pathname.includes(page.toLowerCase().replace(" ", "_"))
                          ? "Page selected"
                          : "Page"
                      }
                      key={index}
                      onClick={() => {
                        setchosenPage(page);
                        navigate(
                          "/" +
                            role.toLowerCase() +
                            "/" +
                            page.toLowerCase().replace(" ", "_")
                        );
                      }}
                    >
                      {page}
                    </div>
                  ))}
            </div>
          )}
        </div>
        {role != "" && role != "Admin" ? (
          <div className="NavBar_Right">
            <div className="Notification" onClick={handleNotificationClick}>
              <img
                src={notification}
                alt="notification"
                className={
                  !notificationReceived
                    ? "bell_image"
                    : "bell_image animated_bell"
                }
              />
              {notificationReceived && (
                <img src={not_dot} alt="dot" className="not_dot_img" />
              )}
            </div>
            <div
              className="Profile"
              onClick={() => {
                setShowProfile(!showProfile);
                setchosenPage("profile");
              }}
            >
              <img
                src={
                  student?.user?.profile_picture
                    ? process.env.REACT_APP_API +
                      "/files/students/" +
                      student?.id +
                      "/" +
                      student?.user?.profile_picture
                    : avatar
                }
                className="Profile_img"
                alt="profile"
              />
            </div>
            <div className="Menu" onClick={() => setShowMenu(!showMenu)}>
              <AiOutlineMenu size="1.5rem" color="black" />
            </div>
          </div>
        ) : (
          <div className="NavBar_Right">
            <div className="noRole_navbar">
              <div
                className="btn_navbar"
                onClick={() => navigate("/student/signUp")}
              >
                <img className="SingleUniCostOfLiving" src={pinkCircle} />
                Become a Student
              </div>
              <div
                className="btn2_navbar"
                onClick={() => navigate("/student/login")}
              >
                <img className="SingleUniCostOfLiving" src={greenCircle} />
                Login
              </div>

              <div
                className="btn3_navbar"
                onClick={() => navigate("/staff/login")}
              >
                <img className="SingleUniCostOfLiving" src={yellowCircle} />I am
                EB
              </div>
            </div>
            <div className="noRole_navbar_mobile">
              <div className="Menu" onClick={() => setShowMenu(!showMenu)}>
                <AiOutlineMenu size="1.5rem" color="black" />
              </div>
            </div>
          </div>
        )}
      </div>
      {role != "" && role != "Admin" ? (
        <div className="Menu_dropdown">
          {showMenu && (
            <div
              className="background"
              onClick={() => setShowMenu(false)}
            ></div>
          )}

          <div className={showMenu ? "Main_Navbar" : "Main_NavBar_Hidden"}>
            {role == "Student"
              ? pages.map((page, index) => {
                  return (
                    <div
                      className={
                        pathname.includes(page.toLowerCase().replace(" ", "_"))
                          ? "mobile_selected_page dropdown_page"
                          : "dropdown_page"
                      }
                      key={index}
                      onClick={() => {
                        setchosenPage(page);
                        navigate(
                          "/" +
                            role.toLowerCase() +
                            "/" +
                            page.toLowerCase().replace(" ", "_")
                        );
                        setShowMenu(false);
                      }}
                    >
                      {page}
                    </div>
                  );
                })
              : role == "Counselor"
              ? Counslerpages.map((page, index) => {
                  return (
                    <div
                      className={
                        pathname.includes(page.toLowerCase().replace(" ", "_"))
                          ? "mobile_selected_page dropdown_page"
                          : "dropdown_page"
                      }
                      key={index}
                      onClick={() => {
                        setchosenPage(page);
                        navigate(
                          "/" +
                            role.toLowerCase() +
                            "/" +
                            page.toLowerCase().replace(" ", "_")
                        );
                        setShowMenu(false);
                      }}
                    >
                      {page}
                    </div>
                  );
                })
              : Salespages.map((page, index) => {
                  return (
                    <div
                      className={
                        pathname.includes(page.toLowerCase().replace(" ", "_"))
                          ? "mobile_selected_page dropdown_page"
                          : "dropdown_page"
                      }
                      key={index}
                      onClick={() => {
                        setchosenPage(page);
                        navigate(
                          "/" +
                            role.toLowerCase() +
                            "/" +
                            page.toLowerCase().replace(" ", "_")
                        );
                        setShowMenu(false);
                      }}
                    >
                      {page}
                    </div>
                  );
                })}
            <div
              className="dropdown_page"
              onClick={() => {
                setShowMenu(false);
                setShowNotification(true);
              }}
            >
              Notification
            </div>
          </div>
        </div>
      ) : (
        <div className="Menu_dropdown">
          <div className={showMenu ? "Main_Navbar" : "Main_NavBar_Hidden"}>
            <div
              className="dropdown_page"
              onClick={() => navigate("/student/signUp")}
            >
              Become a Student
            </div>
            <div
              className="dropdown_page"
              onClick={() => navigate("/student/login")}
            >
              Login
            </div>
            <div
              className="dropdown_page"
              onClick={() => navigate("/staff/login")}
            >
              I am EB
            </div>
          </div>
        </div>
      )}
      <div className={"notification_panel"}>
        {showNotification && (
          <div
            className="background"
            onClick={() => setShowNotification(false)}
          ></div>
        )}

        {showNotification && (
          <div className={"Notification_Main"}>
            <img
              src={close}
              className="close_img"
              onClick={() => setShowNotification(false)}
            />
            <div className={"title"}>
              <div className="inner">
                <div className="text">Notifications</div>
                {notifications.length > 0 && (
                  <button
                    className="btn"
                    onClick={() => {
                      markAllAsRead();
                    }}
                  >
                    Mark all as read
                  </button>
                )}
              </div>
            </div>
            {!getNotificationsLoading &&
            !deleteAllNotificationsLoading &&
            !deleteSingleNotificationLoading ? (
              <div className="Body">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => {
                    return (
                      <div className="notification" key={index}>
                        <div className="text">{notification.message}</div>
                        <Button
                          text="Mark as read"
                          color="blue"
                          onClick={() => {
                            deleteSingleNotification(notification.id);
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginLeft: "0.7rem",
                      fontFamily: "P_MediumItalic",
                      color: "var(--darkgrey)",
                    }}
                  >
                    No New Notification
                  </div>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        )}
      </div>
      {showProfile && (
        <div className="profile_Menu">
          <div
            className="background"
            onClick={() => setShowProfile(false)}
          ></div>
          <div className="inner">
            <div
              className="data profile"
              onClick={() => {
                navigate("/" + role.toLowerCase() + "/profile");
                setShowProfile(false);
              }}
            >
              <img src={profile} className="profile_img img" />
              Profile & Settings
            </div>
            <br />
            <div
              className="data logout"
              onClick={() => {
                setShowPopup(true);
                setShowProfile(false);
              }}
            >
              <img src={logout} className="logout_img img" />
              Log Out
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          body={
            <div className="logout_popup_main" style={{ padding: "1rem" }}>
              {/* <div
                className="Logout_popup_title"
                style={{
                  textAlign: "center",
                  fontFamily: "P_Bold",
                  fontSize: "2rem",
                }}
              >
                Log Out
              </div> */}
              <div
                className="Logout_popup_subtitle"
                style={{
                  marginBottom: "0.5rem",
                  textAlign: "center",
                  fontFamily: "P_Bold",
                  fontSize: "1.5rem",
                }}
              >
                Are you sure you want to logout?
              </div>
              <div
                className="Logout_popup_subtitle2"
                style={{
                  marginBottom: "2rem",
                  textAlign: "center",
                  fontFamily: "P_SemiBold",
                  fontSize: "1rem",
                  opacity: "0.6",
                }}
              >
                Any unsaved changes will be lost.
              </div>
              {logoutError && (
                <div
                  style={{
                    color: "var(--pink)",
                    fontFamily: "P_Semibold",
                    fontSize: "1.2rem",
                  }}
                >
                  {logoutError}
                </div>
              )}
              {logoutLoading ? (
                <Loader onlyDots={true} />
              ) : (
                <div
                  className="Logout_popup_buttons"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    text="Log Out"
                    color="yellow"
                    onClick={() => {
                      dispatch(logoutUser(navigate));
                    }}
                  />
                  <Button
                    text="Cancel"
                    color="pink"
                    onClick={() => setShowPopup(false)}
                  />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
}

export default NavBar;
