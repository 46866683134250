import { axiosReq } from "../../../Utils";
import { SalesSourcesActions } from "./SalesSourcesReducers";

export const getSalesSources = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal";

  dispatch(SalesSourcesActions.GetStudents());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(SalesSourcesActions.GetStudentsSuccess(res.data));
    } else {
      dispatch(
        SalesSourcesActions.GetStudentsFail(res.data.response.data.message)
      );
    }
  });
};

export const createSalesSources = (data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal/category";

  dispatch(SalesSourcesActions.createcategory());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(SalesSourcesActions.createcategorySuccess());
      dispatch(getSalesSources());
    } else {
      dispatch(
        SalesSourcesActions.createcategoryFail(res.data.response.data.message)
      );
    }
  });
};

export const createSalesSourcecat = (data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal/source";

  dispatch(SalesSourcesActions.createsource());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(SalesSourcesActions.createsourceSuccess());
      dispatch(getSalesSources());
    } else {
      dispatch(
        SalesSourcesActions.createsourceFail(res.data.response.data.message)
      );
    }
  });
};
