import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { useEffect } from "react";
import UserSlice from "./Student/Redux/User/UserReducer";
import { configureStore } from "@reduxjs/toolkit";
import UniSlice from "./Shared/Redux/Universities/UniversitiesReducer";
import StudentSlice from "./Student/Redux/Student/StudentReducer";
import AppSlice from "./Shared/Redux/Application/ApplicationReducer";
import LocationsSlice from "./Shared/Redux/Location/LocationsReducer";
import CompleteProfileSlice from "./Student/Redux/completeProfile/CompleteProfileReducer";
import OverviewSlice from "./Student/Redux/Overview/OverviewReducers";
import DocumentsSlice from "./Student/Redux/Documents/DocumentsReducers";
import CounselorAppsSlice from "./Counsler/Redux/CounselorApplication/CounselorApplicaitonReducer";
import CounselorStudentSlice from "./Counsler/Redux/CounselorStudent/CounselorStudentReducers";
import CreateApplicationSlice from "./Shared/Redux/CreateApplication/CreateApplicationReducer";
import MyCounselorSlice from "./Student/Redux/MyCounselor/MyCounselorReducer";
import NotificationSlice from "./Shared/Redux/Notification/NotificationReducer";
import LeadsSlice from "./Sales/Redux/Leads/LeadsReducers";
import CounselorAppTasksSlice from "./Counsler/Redux/CounselorAppTasks/CounselorAppTasksReducer";
import CounselorOverViewSlice from "./Counsler/Redux/CounselorOverView/CounselorOverViewReducer";
import UniversitiesSlice from "./Sales/Redux/Universities/UniversitiesReducers";
import StudentsSlice from "./Sales/Redux/Students/StudentsReducers";
import SalesCounselorSlice from "./Sales/Redux/SalesCounselor/SalesCounselorReducers";
import SalesSourcesSlice from "./Sales/Redux/SalesSources/SalesSourcesReducers";
import CrmSlice from "./Sales/Redux/Crm/CrmReducers";
import CounselorMeetingSlice from "./Counsler/Redux/CounselorMeeting/CounselorMeetingReducer";
import CounselorChatSlice from "./Counsler/Redux/CounselorChat/CounselorChatReducer";
import FiltersDegreesSlice from "./Shared/Redux/Filters/FiltersReducer";

const RootReducer = combineReducers({
  // Add reducers here
  completeProfileReducer: CompleteProfileSlice.reducer,
  userReducer: UserSlice.reducer,
  StudentReducer: StudentSlice.reducer,
  notificationReducer: NotificationSlice.reducer,
  myCounselorReducer: MyCounselorSlice.reducer,
  CounselorChatReducer: CounselorChatSlice.reducer,
  UniversitiesReducer: UniSlice.reducer,
  ApplicationsReducer: AppSlice.reducer,
  LocationReducer: LocationsSlice.reducer,
  OverviewReducer: OverviewSlice.reducer,
  DocumentsReducer: DocumentsSlice.reducer,
  CounselorMeetingReducer: CounselorMeetingSlice.reducer,
  CounselorApplicationReducer: CounselorAppsSlice.reducer,
  CounselorStudentReducer: CounselorStudentSlice.reducer,
  CreateApplicationReducer: CreateApplicationSlice.reducer,
  LeadsReducer: LeadsSlice.reducer,
  CounselorAppTasksReducer: CounselorAppTasksSlice.reducer,
  UniversitiesSaleReducer: UniversitiesSlice.reducer,
  StudentsSaleReducer: StudentsSlice.reducer,
  SalesCounselorReducer: SalesCounselorSlice.reducer,
  SalesSourcesReducer: SalesSourcesSlice.reducer,
  CounselorOverViewReducer: CounselorOverViewSlice.reducer,
  CrmReducer: CrmSlice.reducer,
  FiltersReducer: FiltersDegreesSlice.reducer,
});

// const initialState = {};
// const middlewares = [thunk];
// let devtools = (x) => x;

// // if (
// //   process &&
// //   process.env.NODE_ENV !== "production" &&
// //   process.browser &&
// //   window._REDUX_DEVTOOLS_EXTENSION_
// // ) {
// //   devtools = window._REDUX_DEVTOOLS_EXTENSION_();
// // }

export const Store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
