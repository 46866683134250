import { axiosReq } from "../../../Utils";
import { CounselorAplicationActions } from "./CounselorApplicaitonReducer";

export const getAllCounselorApps =
  (offset, limit, residence, status, major, language, sortBy, search) =>
  async (dispatch) => {
    const url = process.env.REACT_APP_API + `/counselor/application/filter`;
    dispatch(CounselorAplicationActions.getAllAppsRequest());
    dispatch(
      axiosReq(
        "get",
        url,
        {},
        {},
        {
          search: search,
          sortBy: sortBy,
          limit: limit,
          offset: offset,
          residence: residence,
          status: status,
          major: major,
          language: language,
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(CounselorAplicationActions.getAllAppsSuccess(res.data));
      } else {
        dispatch(
          CounselorAplicationActions.getAllAppsFail(
            res.data.response.data.message
          )
        );
      }
    });
  };
