import React from 'react'
import './CreateStudent.scss'
import OBStep1 from "../../../Shared/Components/OnBoarding/OBStep1/OBStep1"
function CreateStudent() {
  return (
    <div>
      <OBStep1 counselor={true}/>
    </div>
  )
}

export default CreateStudent