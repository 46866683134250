import React from "react";
import "./MainCard.scss";
function MainCard(props) {
  //width, minHeight, header, subHeader, body
  return (
    <div
      className="mainCard_main_div"
      style={{ width: props.width, minHeight: props.minHeight }}
    >
      <div className="mainCard_heading">
        <h1 className="mainCard_header">{props.header}</h1>
        {props.subHeader && (
          <h3 className="mainCard_subHeader">{props.subHeader}</h3>
        )}
      </div>

      <>{props.body}</>
    </div>
  );
}

export default MainCard;
