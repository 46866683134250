import React, { useEffect, useState } from "react";
import "./ApplicationStep1.scss";
import ApplyForm from "../../../Components/ApplyForm/ApplyForm";
import Input from "../../../Components/Input/Input";
import DateInput from "../../../Components/DatePicker/DateInput";
import DropDown from "../../../Components/DropDown/DropDown";
import Button from "../../../Components/Button/Button";
import ApplicationStep2 from "../Application2/ApplicationStep2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropDownList, languagesList } from "../../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  createApplication,
  getUniversitySemesters,
  updateApplication,
} from "../../../Redux/CreateApplication/CreateApplicationAction";
import Loader from "../../../Components/Loader/Loader";
import { all } from "axios";
import { getSingleApp } from "../../../Redux/Application/ApplicationAction";
import { getCounselorStudents } from "../../../../Counsler/Redux/CounselorStudent/CounselorStudentActions";
function ApplicationStep1({ counselor }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { stepApp } = useParams();
  const { user } = useSelector((store) => store.userReducer);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [gender, setGender] = useState("");
  const [language, setLanguage] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [semester, setSemester] = useState("");
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [allSemesters, setAllSemesters] = useState([]);
  const [error, setError] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [emails, setEmails] = useState([]);
  const {
    createApplicationLoading,
    createApplicationError,
    createApplicationSuccess,
    getUniversitiesSemestersLoading,
    universitiesSemesters,
    newApplication,
    updateApplicationLoading,
    updateApplicationError,
    updateApplicationSuccess,
  } = useSelector((state) => state.CreateApplicationReducer);

  const {
    singleApplication,
    getSingleAppLoading,
    getSingleAppSuccess,
    getSingleAppError,
  } = useSelector((state) => state.ApplicationsReducer);

  const Students = useSelector((state) => state.CounselorStudentReducer);

  const languages = languagesList;

  const genders = [
    { value: "female", label: "Female" },
    { value: "male", label: "Male" },
  ];

  useEffect(() => {
    if (counselor) dispatch(getCounselorStudents());
  }, []);

  useEffect(() => {
    if (stepApp) {
      setStep(stepApp);
    }
  }, [stepApp]);

  useEffect(() => {
    if (Students?.Students?.students && counselor) {
      let temp = [];
      Students?.Students.students.map((item) => {
        temp.push({
          value: item["id"],
          label: item["user"].email,
          student: item,
        });
      });
      setEmails(temp);
    }
  }, [Students]);

  useEffect(() => {
    if (createApplicationError) setError(createApplicationError);
    else setError("");
  }, [createApplicationError, updateApplicationError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    if (localStorage.getItem("applicationId")) {
      const application = {
        first_name: firstName,
        last_name: lastName,
        preferred_language: language.label,
        date_of_birth: new Date(dateBirth),
        gender: gender.label,
        semester: semester.value,
      };
      dispatch(
        updateApplication(localStorage.getItem("applicationId"), application)
      );
    } else {
      if (!counselor) {
        const application = {
          application_step: 2,
          first_name: firstName,
          last_name: lastName,
          student: user.Student.id,
          major_degree: state.majorId,
          preferred_language: language.label,
          date_of_birth: new Date(dateBirth),
          gender: gender.label,
          semester: semester.value,
          counselor: user.Student.counselor ? true : false,
        };
        dispatch(createApplication(application));
      } else {
        const application = {
          application_step: 2,
          first_name: fullName.slice(0, fullName.indexOf(" ")),
          last_name: fullName.slice(fullName.indexOf(" ") + 1),
          student: studentEmail.value,
          major_degree: state.majorId,
          preferred_language: language.label,
          date_of_birth: new Date(dateBirth),
          gender: gender.label,
          semester: semester.value,
          counselor: user.id,
        };
        dispatch(createApplication(application));
      }
    }
  };

  useEffect(() => {
    if (
      getUniversitiesSemestersLoading ||
      getSingleAppLoading ||
      Students.loading
    )
      setLoading(true);
    else setLoading(false);
  }, [getUniversitiesSemestersLoading, getSingleAppLoading, Students.loading]);
  useEffect(() => {
    if (
      (createApplicationSuccess && clicked && step == 1) ||
      (updateApplicationSuccess && clicked && step == 1)
    ) {
      if (!counselor) {
        setStep(2);
        navigate("/student/my_applications/apply/2");
      } else {
        setStep(2);
        navigate("/counselor/applications/create/2");
      }
    }
  }, [createApplicationSuccess, updateApplicationSuccess]);
  useEffect(() => {
    if (
      newApplication &&
      (createApplicationSuccess || updateApplicationSuccess) &&
      clicked
    ) {
      localStorage.setItem("applicationId", newApplication.id);
    }
  }, [newApplication]);

  useEffect(() => {
    if (state?.uniId) {
      dispatch(getUniversitySemesters(state.uniId));
    }
  }, [state?.uniId]);

  useEffect(() => {
    if (universitiesSemesters && universitiesSemesters.length > 0) {
      let temp = {};
      let arr = [];
      universitiesSemesters.map((item) => {
        temp = {
          value: item.id,
          label: item.name + " " + new Date(item.start_date).getFullYear(),
        };
        arr.push(temp);
      });

      setAllSemesters(arr);
    }
  }, [universitiesSemesters]);

  useEffect(() => {
    if (createApplicationLoading || updateApplicationLoading) {
      setBtnLoad(true);
    } else {
      setBtnLoad(false);
    }
  }, [createApplicationLoading, updateApplicationLoading, getSingleAppLoading]);

  useEffect(() => {
    if (localStorage.getItem("applicationId")) {
      dispatch(getSingleApp(localStorage.getItem("applicationId")));
    }
  }, []);

  useEffect(() => {
    if (
      singleApplication &&
      Object.keys(singleApplication).length > 0 &&
      localStorage.getItem("applicationId") &&
      (firstName == "" ||
        lastName == "" ||
        dateBirth == "" ||
        gender == "" ||
        language == "")
    ) {
      setFirstName(singleApplication.first_name);
      setLastName(singleApplication.last_name);
      setDateBirth(singleApplication.date_of_birth);
      setGender({ label: singleApplication.gender });
      setLanguage({ label: singleApplication.preferred_language });
      setSemester({
        value: singleApplication.semester.id,

        label:
          singleApplication.semester.name +
          " " +
          new Date(singleApplication.semester.start_date).getFullYear(),
      });
    } else if (
      !singleApplication ||
      Object.keys(singleApplication).length == 0 ||
      !localStorage.getItem("applicationId")
    ) {
      setFirstName("");
      setLastName("");
      setDateBirth("");
      setGender("");
      setLanguage("");
      setSemester("");
    }
  }, [singleApplication]);

  useEffect(() => {
    if (!counselor && user && Object.keys(user).length > 0) {
      setFirstName(user.first_name ? user.first_name : "");
      setLastName(user.last_name ? user.last_name : "");
      setDateBirth(user.date_of_birth ? user.date_of_birth : "");
      setGender(user.gender ? { label: user.gender } : "");
      setLanguage(
        user.preferred_language ? { label: user.preferred_language } : ""
      );
    }
  }, [user]);

  useEffect(() => {
    if (counselor && Object.keys(studentEmail)?.length > 0) {
      setFullName(
        studentEmail.student.user.first_name +
          " " +
          studentEmail.student.user.last_name
      );
      setDateBirth(
        studentEmail.student.user.date_of_birth
          ? studentEmail.student.user.date_of_birth
          : ""
      );
      setLanguage(
        studentEmail.student.user.preferred_language
          ? { label: studentEmail.student.user.preferred_language }
          : ""
      );
      setGender(
        studentEmail.student.user.gender
          ? { label: studentEmail.student.user.gender }
          : ""
      );
    }
  }, [studentEmail]);

  return (
    <div className="application_step1_mainDiv">
      {step == 1 ? (
        <ApplyForm
          title="Confirm Your Details"
          subTitle="Please make sure your details match your ID or Passport Information"
          hasBack={false}
          hasPlus={false}
          loading={loading}
          body={
            <form
              className="application_step1_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              {counselor ? (
                <div className="application_step1_body_div1">
                  <div className="input_div">
                    <div className="input_tile">Enter Student Email</div>
                    <DropDown
                      placeholder="Enter Student Email"
                      setSelected={setStudentEmail}
                      data={emails}
                      blueArrow={true}
                      width="100%"
                      multiSelection={false}
                      searchable={true}
                      selectedData={language}
                      value={studentEmail}
                    />
                  </div>

                  <div className="input_div">
                    <div className="input_tile">Full Name</div>
                    <Input
                      placeholder="Full name"
                      type="text"
                      width={"100%"}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const letterOnlyValue = inputValue.replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                        setFullName(letterOnlyValue);
                      }}
                      required={true}
                      value={fullName}
                    />
                  </div>
                </div>
              ) : (
                <div className="application_step1_body_div1">
                  <div className="input_div">
                    <div className="input_tile">First Name</div>
                    <Input
                      placeholder="First name"
                      type="text"
                      width={"100%"}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const letterOnlyValue = inputValue.replace(
                          /[^A-Za-z]/g,
                          ""
                        );
                        setFirstName(letterOnlyValue);
                      }}
                      required={true}
                      value={firstName}
                    />
                  </div>

                  <div className="input_div">
                    <div className="input_tile">Last Name</div>
                    <Input
                      placeholder="Last name"
                      type="text"
                      width={"100%"}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const letterOnlyValue = inputValue.replace(
                          /[^A-Za-z]/g,
                          ""
                        );
                        setLastName(letterOnlyValue);
                      }}
                      required={true}
                      value={lastName}
                    />
                  </div>
                </div>
              )}
              <div className="application_step1_body_div1">
                <div className="input_div">
                  <div className="input_tile">Date of birth</div>
                  <DateInput
                    width="100%"
                    setSelectedDate={setDateBirth}
                    selectedDate={dateBirth}
                    maxDate={new Date()}
                  />
                </div>
                <div className="input_div">
                  <div className="input_tile">Semester</div>
                  {allSemesters && (
                    <DropDown
                      placeholder="Choose Semester"
                      setSelected={setSemester}
                      data={allSemesters}
                      blueArrow={true}
                      width="100%"
                      multiSelection={false}
                      searchable={true}
                      selectedData={semester}
                      value={semester}
                    />
                  )}
                </div>
              </div>
              <div className="application_step1_body_div1">
                <div className="input_div">
                  <div className="input_tile">Preferred Language</div>
                  <DropDown
                    placeholder="Preferred language"
                    setSelected={setLanguage}
                    data={languages}
                    blueArrow={true}
                    width="100%"
                    multiSelection={false}
                    searchable={true}
                    selectedData={language}
                    value={language}
                  />
                </div>
                <div className="input_div">
                  <div className="input_tile">Gender</div>
                  <DropDown
                    placeholder="Gender"
                    setSelected={setGender}
                    data={genders}
                    blueArrow={true}
                    width="100%"
                    multiSelection={false}
                    searchable={true}
                    selectedData={gender}
                    value={gender}
                  />
                </div>
              </div>

              <div className="application_step1_body_footer">
                {error && <div className="error">{error}</div>}

                <Button
                  text={btnLoad ? <Loader onlyDots={true} /> : "Continue"}
                  color="yellow"
                  width="100%"
                  disabled={
                    !counselor
                      ? firstName == "" ||
                        lastName == "" ||
                        dateBirth == "" ||
                        gender == "" ||
                        language == "" ||
                        semester == "" ||
                        btnLoad
                      : studentEmail == "" ||
                        fullName == "" ||
                        dateBirth == "" ||
                        gender == "" ||
                        semester == "" ||
                        language == "" ||
                        btnLoad
                      ? true
                      : false
                  }
                />
              </div>
            </form>
          }
        />
      ) : (
        <ApplicationStep2 counselor={counselor} step={step} setStep={setStep} />
      )}
    </div>
  );
}

export default ApplicationStep1;
