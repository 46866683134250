import App from "../../../Student/Pages/Application/Application";
import { axiosReq } from "../../../Utils";
import { ApplicationActions } from "./ApplicationReducer";

export const getAllApps = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/application/student/${id}`;
  dispatch(ApplicationActions.getAllApplicationsRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(ApplicationActions.getAllApplicationsSuccess(res.data));
    else
      dispatch(
        ApplicationActions.getAllApplicationsError(
          res.data.response.data.message
        )
      );
  });
};
export const getSingleApp = (appId) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/application/${appId}/detailed`;
  dispatch(ApplicationActions.getSingleApplicationRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(ApplicationActions.getSignleApplicationSuccess(res.data));
    else
      dispatch(
        ApplicationActions.getSingleApplicationError(
          res.data.response.data.message
        )
      );
  });
};
export const getSingleAppMissingDocs = (AppId) => async (dispatch) => {
  const url =
    process.env.REACT_APP_API + `/application/${AppId}/missingDocuments`;
  dispatch(ApplicationActions.getSingleApplicationMissingDocsRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(
        ApplicationActions.getSingleApplicationMissingDocsSuccess(res.data)
      );
    else
      dispatch(
        ApplicationActions.getSingleApplicationMissingDocsError(
          res.data.response.data.message
        )
      );
  });
};

export const getUnifiedApplication = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/student/unifiedApplication`;
  dispatch(ApplicationActions.getUnifiedApplicationRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(ApplicationActions.getUnifiedApplicationSuccess(res.data));
    else
      dispatch(
        ApplicationActions.getUnifiedApplicationError(
          res.data.response.data.message
        )
      );
  });
};

export const cancelApplication = (appId,studentId) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/application/cancel/${appId}`;
  dispatch(ApplicationActions.cancelApplicationRequest());
  dispatch(axiosReq("patch", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(ApplicationActions.cancelApplicationSuccess(res.data));
      dispatch(getAllApps(studentId));
    } else
      dispatch(
        ApplicationActions.cancelApplicationError(
          res.data.response.data.message
        )
      );
  });
};
export const uploadAppMissingDocuments =
  (AppId, docName, selectedDoc, studentId, dis) => async (dispatch) => {
    const url = process.env.REACT_APP_API + `/application/${AppId}/documents/`;
    const data = new FormData();
    data.append("documentName", docName);
    data.append("document", selectedDoc);
    data.append("student", studentId);
    dispatch(ApplicationActions.uploadDocumentRequest());

    dispatch(axiosReq("post", url, {}, data)).then((res) => {
      if (res.success) {
        dispatch(ApplicationActions.uploadDocumentSuccess(res.data));
        if (!dis) {
          dispatch(getSingleApp(AppId));
          dispatch(getSingleAppMissingDocs(AppId));
        }
      } else {
        dispatch(
          ApplicationActions.uploadDocumentError(res.data.response.data.message)
        );
      }
    });
  };
export const uploadAppMissingDocumentsFromWebsite =
  (AppId, docId, docName, studentId, dis) => async (dispatch) => {
    const data = {
      documentName: docName,
      student: studentId,
    };
    dispatch(ApplicationActions.uploadDocumentRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + `/application/${AppId}/documents/${docId}`,
        {},
        data
      )
    ).then((res) => {
      if (res.success) {
        dispatch(ApplicationActions.uploadDocumentSuccess(res.data));
        if (!dis) {
          dispatch(getSingleApp(AppId));
          dispatch(getSingleAppMissingDocs(AppId));
        }
      } else {
        dispatch(
          ApplicationActions.uploadDocumentError(res.data.response.data.message)
        );
      }
    });
  };

export const updateUnifiedApp = (body) => async (dispatch) => {
  dispatch(ApplicationActions.updateUnifiedApplicationRequest());
  const url = process.env.REACT_APP_API + `/student/unifiedApplication`;
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(ApplicationActions.updateUnifiedApplicationSuccess(res.data.id));
      dispatch(getUnifiedApplication());
    } else
      dispatch(
        ApplicationActions.updateUnifiedApplicationError(
          res.data.response.data.message
        )
      );
  });
};

export const uploadUnifiedMissingDocuments =
  (AppId, docName, selectedDoc, studentId) => async (dispatch) => {
    const url =
      process.env.REACT_APP_API +
      `/student/unifiedApplication/${AppId}/upload
    `;
    const data = new FormData();
    data.append("documentName", docName);
    data.append("document", selectedDoc);
    data.append("student", studentId);
    dispatch(ApplicationActions.updateUnifiedDocumentRequest());

    dispatch(axiosReq("post", url, {}, data)).then((res) => {
      if (res.success) {
        dispatch(ApplicationActions.updateUnifiedApplicationSuccess(res.data));
        dispatch(getUnifiedApplication());
      } else {
        dispatch(
          ApplicationActions.updateUnifiedApplicationError(
            res.data.response.data.message
          )
        );
      }
    });
  };
export const chooseUnifiedMissingDocuments =
  (AppId, docId, docName, studentId, dis) => async (dispatch) => {
    const data = {
      documentName: docName,
      student: studentId,
    };
    dispatch(ApplicationActions.chooseUnifiedDocumentRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + `/student/${AppId}/unifiedAppDocs/${docId}`,
        {},
        data
      )
    ).then((res) => {
      if (res.success) {
        dispatch(ApplicationActions.chooseUnifiedDocumentSuccess(res.data));
        dispatch(getUnifiedApplication());
      } else {
        dispatch(
          ApplicationActions.cancelApplicationError(
            res.data.response.data.message
          )
        );
      }
    });
  };

export const makeAppUnified = (id) => async (dispatch) => {
  dispatch(ApplicationActions.makeThisApplicationUnifiedRequest());
  const url = process.env.REACT_APP_API + `/student/makeUnifiedApplication`;
  dispatch(axiosReq("post", url, {}, { applicationId: id })).then((res) => {
    if (res.success) {
      dispatch(
        ApplicationActions.makeThisApplicationUnifiedSuccess(res.data.id)
      );
      dispatch(getSingleApp(id));
      dispatch(getUnifiedApplication());
    } else
      dispatch(
        ApplicationActions.makeThisApplicationUnifiedError(
          res.data.response.data.message
        )
      );
  });
};

export const getApplicationFeed = () => (dispatch) => {
  dispatch(ApplicationActions.getApplicationFeedRequest());
  const url = process.env.REACT_APP_API + `/application/feed`;
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(ApplicationActions.getApplicationFeedSuccess(res.data));
    } else {
      dispatch(
        ApplicationActions.getApplicationFeedError(
          res.data.response.data.message
        )
      );
    }
  });
};
