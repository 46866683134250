import React, { useEffect, useState } from "react";
import "./CounselorMeeting.scss";
import clock2 from "../../../Shared/Assets/Images/clock2.png";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import Button from "../../../Shared/Components/Button/Button";
import chat from "../../../Shared/Assets/Images/chat.png";
import Popup from "../../../Shared/Components/Popup/Popup";
import calendar from "../../../Shared/Assets/Images/calendar.webp";
import {
  changeMeetingStatus,
  getCounselorMeeting,
  getCounselorMeetingToday,
} from "../../Redux/CounselorMeeting/CounselorMeetingActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Shared/Components/Loader/Loader";

function CounselorMeeting() {
  const dispatch = useDispatch();

  const [selectedDay, setSelectedDay] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  // const disabledDays = [
  //   {
  //     year: 2023,
  //     month: 5,
  //     day: 15,
  //   },
  //   {
  //     year: 2023,
  //     month: 5,
  //     day: 21,
  //   },
  //   {
  //     year: 2023,
  //     month: 5,
  //     day: 6,
  //   },
  // ];

  useEffect(() => {
    dispatch(
      getCounselorMeeting(
        selectedDay.year + "-" + selectedDay.month + "-" + selectedDay.day
      )
    );
  }, [selectedDay]);

  useEffect(() => {
    dispatch(getCounselorMeetingToday());
  }, []);

  const hours = [
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
  ];


  const events = useSelector((state) => state.CounselorMeetingReducer.events);
  const {
    getEventsLoading,
    TodayEvents,
    TodayEventsLoading,
    changeMeetingStatusLoading,
  } = useSelector((state) => state.CounselorMeetingReducer);
  const [showPop, setShowPop] = useState(false);

  useEffect(() => {
    setShowPop(false);
  }, [selectedDay]);

  return (
    <>
      <div className="meeting_main">
        {showPop && (
          <Popup
            setShowPopup={setShowPop}
            body={
              <div className="calendar_popup">
                <Calendar
                  value={selectedDay}
                  onChange={setSelectedDay}
                  colorPrimary="var(--pink)"
                  calendarTodayClassName="selected_day"
 
                />
              </div>
            }
          />
        )}
        <div className="left_div">
          <div className="cal">
            <Calendar
              value={selectedDay}
              onChange={setSelectedDay}
              colorPrimary="var(--pink)"
              calendarTodayClassName="selected_day"
              // disabledDays={disabledDays}
              minimumDate={{
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
              }}
              // customDaysClassName={[
              //   // here we add some CSS classes
              //   {
              //     year: 2023,
              //     month: 5,
              //     day: 4,
              //     className: "yellowDay",
              //   },
              //   {
              //     year: 2023,
              //     month: 5,
              //     day: 12,
              //     className: "yellowDay",
              //   },
              //   {
              //     year: 2023,
              //     month: 5,
              //     day: 18,
              //     className: "yellowDay",
              //   },
              //   {
              //     year: 2019,
              //     month: 3,
              //     day: 26,
              //     className: "navyBlueDay",
              //   },
              // ]}
            />
            <div className="legends">
              <div className="left_legend">
                <div className="single_legend">
                  <div className="yellow_box"></div>
                  <div className="text">Selected Day</div>
                </div>
                <div className="single_legend">
                  <div className="green_box"></div>
                  <div className="text">Today</div>
                </div>
              </div>
              <div className="right_legend">
                <div className="single_legend">
                  <div className="white_box"></div>
                  <div className="text">Available day</div>
                </div>
                <div className="single_legend">
                  <div className="transparent_box"></div>
                  <div className="text">Unavailable day</div>
                </div>
              </div>
            </div>
          </div>
          {(getEventsLoading ||
            TodayEventsLoading ||
            changeMeetingStatusLoading) && <Loader fullScreen={true} />}
          <div className="next_event">
            <div className="next_event_title">Next Event</div>
            <div className="next_event_body">
              {hours.map((hour, i) => {
                return (
                  <>
                    {TodayEvents.map((event, index) => {
                      return (
                        new Date(
                          event.date + " " + event.time
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                        }) === hour && (
                          <div
                            className="next_event_body_item"
                            key={index}
                            onClick={() =>
                              setSelectedDay({
                                day: new Date().getDate(),
                                month: new Date().getMonth() + 1,
                                year: new Date().getFullYear(),
                              })
                            }
                          >
                            <div className="next_event_body_single_item">
                              <div className="inner">
                                <div className="title">
                                  Meeting with {event.student.user.first_name}{" "}
                                  {event.student.user.last_name}
                                </div>
                                <div className="body">
                                  <div className="date">{event.date}</div>
                                  <div className="time">
                                    <img
                                      src={clock2}
                                      alt="clock"
                                      className="clock_icon"
                                    />
                                    <div>
                                      {new Date(
                                        event.date + " " + event.time
                                      ).toLocaleTimeString([], {
                                        hour: "numeric",
                                        minute: "2-digit",
                                      })}{" "}
                                      -{" "}
                                      {new Date(
                                        new Date(
                                          event.date + " " + event.time
                                        ).getTime() + 5400000
                                      ).toLocaleTimeString([], {
                                        hour: "numeric",
                                        minute: "2-digit",
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className="right_div">
          <div className="right_div_up">
            <div className="right_div_up_left">
              <img src={chat} alt="person" className="zoom_icon" />
              <div className="zoom_text">Zoom Calendar</div>
            </div>
            <div className="right_div_up_right">
              {selectedDay.day +
                "-" +
                selectedDay.month +
                "-" +
                selectedDay.year}
            </div>
            <div className="right_div_up_button">
              <div className="button">
                <Button
                  text={"Choose Date"}
                  color={"pink"}
                  onClick={() => setShowPop(true)}
                />
              </div>
              <img
                src={calendar}
                alt="calendar"
                className="calendar_icon"
                onClick={() => setShowPop(true)}
              />
            </div>
          </div>
          <div className="right_div_table">
            <table>
              <tbody>
                {hours.map((hour, index) => {
                  const event = events.find(
                    (event) =>
                      new Date(
                        event.date + " " + event.time
                      ).toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      }) === hour
                  );
                  return (
                    <tr key={index}>
                      <td className={event ? "pink_div hours" : "hours"}>
                        {hour.split(" ")[0]}
                      </td>
                      {event ? (
                        <>
                          <td className="pink_div title">
                            Meeting with {event.student.user.first_name}{" "}
                            {event.student.user.last_name}
                          </td>
                          <td className="pink_div">{event.topic}</td>
                          {
                            <td>
                              {event.status == "pending" ? (
                                <div className="buttons_div">
                                  <Button
                                    text="Confirm"
                                    color={"lightGreen"}
                                    onClick={() =>
                                      window.open(
                                        `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
                                        "_blank"
                                      )
                                    }
                                  />
                                  <Button
                                    text="Reject"
                                    color={"pink"}
                                    onClick={() =>
                                      dispatch(
                                        changeMeetingStatus(
                                          event.id,
                                          "rejected",
                                          selectedDay
                                        )
                                      )
                                    }
                                  />
                                </div>
                              ) : event.status == "accepted" ? (
                                <div className="buttons_div join_button">
                                  <Button text="Start" color={"lightGreen"} />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    fontFamily: "P_Bold",
                                    fontSize: "1.2rem",
                                    textAlign: "center",
                                  }}
                                >
                                  Meeting was Rejected
                                </div>
                              )}
                            </td>
                          }
                        </>
                      ) : (
                        <td colSpan={3} className="no_meeting">
                          No Scheduled Meetings
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="CounselorOverview_middle_inner_mobile">
            {events.map((item, index) => {
              return (
                <div
                  className="CounselorOverview_middle_inner_mobile_row"
                  key={index}
                >
                  <div className="inner">
                    <div className="title">Meeting with:</div>
                    <div className="CounselorOverview_middle_inner_mobile_row_meeting_name">
                      {item.student.user.first_name}{" "}
                      {item.student.user.last_name}
                    </div>
                    <div className="CounselorOverview_middle_inner_mobile_row_meeting_major">
                      {item.topic}
                    </div>
                    <div className="CounselorOverview_middle_inner_mobile_row_meeting_date_time">
                      <div className="CounselorOverview_middle_inner_mobile_row_meeting_date">
                        {item.date}
                      </div>
                      <div className="CounselorOverview_middle_inner_mobile_row_time">
                        {new Date(
                          item.date + " " + item.time
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                        })}
                      </div>
                    </div>
                    <div className="CounselorOverview_middle_inner_mobile_row_meeting_buttons">
                      {item.status === "pending" ? (
                        <>
                          <Button
                            text="Confirm"
                            color={"lightGreen"}
                            onClick={() =>
                              window.open(
                                 `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
                                "_blank"
                              )
                            }
                          />
                          <Button
                            text="Reject"
                            color={"pink"}
                            onClick={() =>
                              dispatch(
                                changeMeetingStatus(
                                  item.id,
                                  "rejected",
                                  selectedDay
                                )
                              )
                            }
                          />
                        </>
                      ) : item.status === "accepted" ? (
                        <Button
                          text="Start"
                          color={"lightGreen"}
                          width={"100%"}
                        />
                      ) : (
                        <div
                          style={{
                            fontFamily: "P_Bold",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          Meeting was Rejected
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CounselorMeeting;
