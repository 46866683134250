import React, { useEffect, useState } from "react";
import FormLayout from "../../Components/FormLayout/FormLayout";
import Diamond from "../../Components/Animations/Diamond/Diamond";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import "./ContactUs.scss";
import { sendSupportEmail } from "../../../Student/Redux/User/UserActions";
import { useDispatch, useSelector } from "react-redux";
function ContacUs() {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const {
    sendingSupportEmailLoading,
    sendingSupportEmailSuccess,
    sendSupportEmailFailed,
  } = useSelector((store) => store.userReducer);

  useEffect(() => {
    if (
      sendingSupportEmailSuccess &&
      name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      text.length > 0
    ) {
      setShow(true);
      setName("");
      setEmail("");
      setSubject("");
      setText("");
    }
  }, [sendingSupportEmailSuccess]);

  useEffect(() => {
    if (
      sendingSupportEmailLoading &&
      name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      text.length > 0
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [sendingSupportEmailLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      sendSupportEmail({
        subject,
        name,
        from: email,
        body: text,
      })
    );
  };
  return (
    <div className="contactUs_main_div">
      <FormLayout
        loading={show}
        header="Let's Talk"
        subHeader="To reach out, fill out this form and we will get back to you promptly."
        minHeight="fit-content"
        width="90%"
        triangle={true}
        icon={<Diamond />}
        bg="var(--yellow)"
        gap="20px"
        body={
          <>
            <div className="available_hours">
              <div>
                Available Hours :{" "}
                <span>
                  Monday to Friday 10 am to 6 pm (Lebanon time) - Sundays 10 am
                  to 6 pm (Egypt time)
                </span>
              </div>
              <div>
                Response Time :{" "}
                <span>
                  24 hours on working day - Saturday and Sundays (not available)
                </span>
              </div>
            </div>
            <form className="contactUs_body" onSubmit={(e) => handleSubmit(e)}>
              <div className="dropdown_flex">
                <Input
                  placeholder="Your Name"
                  width="100%"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  placeholder="Your Email"
                  required
                  type="email"
                  width="100%"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <Input
                placeholder="Subject"
                required
                width="100%"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />

              <textarea
                required
                className="textarea_email"
                placeholder="Write your message here..."
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <Button text="Send" color="pink" width="100%" />
            </form>
          </>
        }
      />
    </div>
  );
}

export default ContacUs;
