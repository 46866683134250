import React, { useEffect, useState } from "react";
import "./OBStep2.scss";
import Circle from "../../../../Shared/Components/Animations/Circle/Circle";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";

import SingleSelect from "../../../../Shared/Components/SingleSelect/SingleSelect";
import HexaAnimation from "../../../../Shared/Components/Animations/Hexa/HexaAnimation";
import OBStep3 from "../OBStep3/OBStep3";
import DropDown from "../../DropDown/DropDown";
import Input from "../../Input/Input";
function OBStep2({ step, setStep, student, setStudent, counselor }) {
  const [edLevel, setEdLevel] = useState("");
  const [edLevel2, setEdLevel2] = useState();

  const levels = ["High School", "Bachelor's", "Master's", "PhD", "Other"];

  const hightSchoolLevels = [
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
    "Grade 13",
  ];

  const bachelorLevels = [
    "Foundation/pre-university",
    "Bachelor's Year 1",
    "Freshman",
    "Bachelor's Year 2",
    "Sophomore",
    "Bachelor's Year 3",
    "Junior",
    "Bachelor's Year 4",
    "Senior",
    "Bachelor's Year 5+",
  ];

  const masterLevels = ["Pre-master's", "Master's Year 1", " Master's Year 2"];

  const onSubmit = () => {
    //i need to setStudent({ ...student, education_level: edLevel2 }); but if the edLevel2 is an object i need to put edLevel2.value and if it a string i need to put edLevel2
    if (edLevel2.value)
      setStudent({ ...student, education_level: edLevel2.value });
    else setStudent({ ...student, education_level: edLevel2 });

    if (edLevel2) setStep(3);
  };

  useEffect(() => {
    edLevel != "PhD" ? setEdLevel2("") : setEdLevel2("PhD");
  }, [edLevel]);

  return (
    <div className="obStep_main_div">
      {step == 2 ? (
        <FormLayout
          back={() => setStep(1)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={
            counselor
              ? "Completed Level of Education"
              : "What is your Completed Level of Education?"
          }
          minHeight="fit-content"
          width="90%"
          body={
            <div className="step_body">
              <SingleSelect
                data={levels}
                setSelected={setEdLevel}
                width="200px"
                selectedIndex={edLevel}
              />
              {edLevel === "High School" && (
                <DropDown
                  placeholder="Choose level"
                  setSelected={setEdLevel2}
                  data={hightSchoolLevels.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={edLevel2}
                  keepPlaceHolder={false}
                  value={edLevel2}
                />
              )}
              {edLevel === "Bachelor's" && (
                <DropDown
                  placeholder="Choose level"
                  setSelected={setEdLevel2}
                  data={bachelorLevels.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={edLevel2}
                  keepPlaceHolder={false}
                  value={edLevel2}
                />
              )}
              {edLevel === "Master's" && (
                <DropDown
                  placeholder="Choose level"
                  setSelected={setEdLevel2}
                  data={masterLevels.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  blueArrow={false}
                  width={"100%"}
                  multiSelection={false}
                  searchable={false}
                  selectedData={edLevel2}
                  keepPlaceHolder={false}
                  value={edLevel2}
                />
              )}
              {edLevel === "Other" && (
                <Input
                  placeholder="Your Level of Education"
                  type="text"
                  width="100%"
                  disabled={false}
                  onChange={(e) => setEdLevel2(e.target.value)}
                  value={edLevel2}
                />
              )}
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={edLevel.length == 0 || !edLevel2}
              />
            </div>
          }
          icon={<HexaAnimation />}
          bg="var(--blue)"
          gap="20px"
        />
      ) : (
        <OBStep3
          counselor={counselor}
          step={step}
          setStep={setStep}
          student={student}
          setStudent={setStudent}
        />
      )}
    </div>
  );
}

export default OBStep2;
