import React, { useState } from "react";
import "./StudentCard.scss";
import RightArrow from "../../../Shared/Assets/Images/arrow2.webp";
import girl from "../../../Shared/Assets/Images/girl.jpg";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import { IdSplit } from "../../../Utils";

function StudentCard({
  picture,
  name,
  degree,
  status,
  email,
  nationality,
  date,
  id,
  budget,
  stid,
  GPA,
}) {
  const [navigate, setNavigate] = useState("");
  const scWidth = UseInnerWidth();
  return (
    <div
      className="StudentCardContainer"
      key={id}
      onClick={() => {
        setNavigate(navigate);
      }}
    >
      <div className="StudentCardUpper">
        {scWidth < 1000 ? (
          <>
            <div className="StudentCardUpperID">{id}</div>
            {/* <div className="SCStatus">{GPA ? "" : status}</div> */}

            <div className={status === "New" ? "SCStatuspink" : "SCStatus"}>
              {GPA ? "" : status}
            </div>
          </>
        ) : (
          <>
            <div className="StudentCardUpperID">Student ID {id}</div>
            <img className="SCArrow" src={RightArrow} />
          </>
        )}
      </div>
      <div className="StudentCardMiddle">
        <div className="SCImgNameDegDiv">
          {/* <img className="SCImg" src={girl} /> */}
          {scWidth > 1000 && (
            <img
              className="SCImg"
              src={
                process.env.REACT_APP_API + `/files/students/${stid}/${picture}`
              }
            />
          )}
          <div className="SCNameDeg">
            <div className="SCName">{name}</div>
            <div className="SCDeg">{degree}</div>
          </div>
        </div>
        {scWidth > 1000 && (
          <div
            className={
              GPA ? "GPAStatus" : status === "New" ? "SCStatuspink" : "SCStatus"
            }
          >
            {GPA && <p className="SCgpaStatus">GPA</p>}
            {status}
          </div>
        )}
      </div>
      <div className="StudentCardLower">
        <div className="SCEmailNationality">
          <div className="SCEmail">{email}</div>
          <div className="SCNationality">
            <span className="SCNationalityText">Nationality: </span>
            {nationality}
          </div>
        </div>
        <div className="SCAccountCreatedDate">
          {scWidth <= 1000 ? (
            <>
              <div className="SCAccountCreated">{GPA ? "GPA" : "Budget"}:</div>
              {/*Have to import the convDate func and add the date*/}
              <div className="SCBudgetArrow">
                <div className="SCBudget">{GPA ? status : budget}</div>
                <img className="SCArrow" src={RightArrow} />
              </div>
            </>
          ) : (
            <>
              <div className="SCAccountCreated">Account Created On:</div>
              {/*Have to import the convDate func and add the date*/}
              <div className="SCDate">{date}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentCard;
