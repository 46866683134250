import React, { useRef, useState } from "react";
import "./Profile.scss";
import defaultImg from "../../Assets/Images/default.jpeg";
import add from "../../Assets/Images/add.png";
import edit from "../../Assets/Images/edit.png";
import Input from "../../Components/Input/Input";
import DateInput from "../../Components/DatePicker/DateInput";
import DropDown from "../../Components/DropDown/DropDown";
import MobileInput from "../../Components/MobileInput/MobileInput";
import Button from "../../Components/Button/Button";
import check from "../../Assets/Images/save.png";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup/Popup";
import attachment from "../../Assets/Images/attachment.png";
import close from "../../Assets/Images/close.webp";

import documentt from "../../Assets/Images/document.png";
import { useSelector, useDispatch } from "react-redux";
import {
  addPassport,
  editPassport,
  getAllPassports,
  getProfileAddress,
  getProfileEmergency,
  getProfileExp,
  getStudentMe,
  updateUser,
  uploadPic,
} from "../../../Student/Redux/Student/StudentActions";
import Loader from "../../Components/Loader/Loader";
import MoreDetails from "../../../Student/Components/MoreDetails/MoreDetails";
import PassportTable from "../../../Student/Components/PassportTable/PassportTable";
import PrivacyAndSettings from "../../../Student/Components/PrivacyAndSettings/PrivacyAndSettings";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import { getAllCont } from "../../Redux/Location/LocationsActions";
import { DropDownList, handlePreview, languagesList } from "../../../Utils";
import {
  getUser,
  phoneVerification,
} from "../../../Student/Redux/User/UserActions";
import UpperPopUp from "../../Components/UpperPopUp/UpperPopUp";

function Profile() {
  const ref = useRef(null);

  const changeImage = () => {
    ref.current.click();
  };

  const { phoneVerifyLoading, phoneVerifySuccess, phoneVerifyError } =
    useSelector((state) => state.userReducer);

  useEffect(() => {
    if (phoneVerifySuccess) {
      dispatch(getUser());
    }
  }, [phoneVerifySuccess]);

  const [newDate, setNewDate] = useState();
  const [Natioanlity, setNatioanlity] = useState("");
  const [country, setCountry] = useState("");
  const [profile_phone, setProfile_phone] = useState();
  const [gender, setGender] = useState("");
  const [language, setlanguage] = useState("");

  const [newUser, setNewUser] = useState({});

  const [profileCompleted, setProfileCompleted] = useState(false);
  const [newsLetterSub, setNewsLetterSub] = useState();
  const [emailSub, setEmailSub] = useState();
  const [changePass, setChangePass] = useState(false);
  const [myProfileEdit, setmyProfileEdit] = useState(false);

  const [newFName, setNewFName] = useState("");
  const [newLName, setNewLName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");

  const [openPassportPopup, setOpenPassportPopup] = useState(false);
  const [passportDate, setPassportDate] = useState();
  const [passportNumber, setPassportNumber] = useState();
  const [passportCountry, setPassportCountry] = useState("");
  const [passportNationality, setPassportNationality] = useState("");
  const [passport, setPassport] = useState(null);
  const [passportEdit, setPassportEdit] = useState(false);
  const [mobilePopup, setMobilePopup] = useState(false);
  const [userPassport, setUserPassport] = useState([]);
  const [addPassSuccess, setAddPassSuccess] = useState(false);
  const [addPassportSuccessMsg, setAddPassportSuccessMsg] = useState("");
  const [toEditPassport, setToEditPassport] = useState({});
  const [editedPassport, setEditedPassport] = useState({});
  const [clicked, setClicked] = useState(false);
  const [upperError, setUpperError] = useState(false);

  const user = useSelector((state) => state.userReducer?.user);
  const addressDb = useSelector((state) => state.StudentReducer?.address);
  const expDb = useSelector((state) => state.StudentReducer?.exp);
  const EmergencyDb = useSelector((state) => state.StudentReducer?.emergency);
  const { editSuccess } = useSelector((state) => state.StudentReducer);
  const dispatch = useDispatch();

  const [access, setAccess] = useState(false);

  const { pathname } = useLocation();

  const [lang, setLang] = useState([]);

  useEffect(() => {
    if (Object.keys(user).length === 0 && pathname.includes("student")) {
      dispatch(getStudentMe());
    }
    dispatch(getProfileAddress("", true));
    dispatch(getProfileExp("", true));
    dispatch(getProfileEmergency(navigate, "", true));
    dispatch(getAllCont());
    setLang(languagesList);
  }, []);

  const { id } = useSelector((state) => state.StudentReducer?.student);

  useEffect(() => {
    if (id) {
      dispatch(getAllPassports(navigate, id));
    }
  }, [id]);

  useEffect(() => {
    if (addressDb.length > 0 && EmergencyDb.length > 0) {
      setProfileCompleted(true);
    } else {
      setProfileCompleted(false);
    }
  }, [addressDb, expDb, EmergencyDb]);

  const { fetchEmgLoading, fetchAddressLoading, imgError } = useSelector(
    (store) => store.StudentReducer
  );

  useEffect(() => {
    if (user) {
      setNewFName(user?.first_name);
      setNewLName(user?.last_name);
      setNewEmail(user?.email);
      setNatioanlity({
        value: user?.nationality,
        label: user?.nationality,
      });
      setCountry({
        value: user?.country_of_residence,
        label: user?.country_of_residence,
      });
      setProfile_phone(user?.phone);
      setGender({
        label: user?.gender,
        value: user?.gender,
      });

      setNewDate(user?.date_of_birth);
      setEmailSub(user?.subscribed_to_emails);
      setNewsLetterSub(user?.subscribed_to_newsletter);
      setlanguage({
        value: user?.preferred_language,
        label: user?.preferred_language,
      });
    }
  }, [user]);

  const navigate = useNavigate();

  const passRef = useRef(null);

  const [passportError, setPassportError] = useState("");

  const handlePassport = (type) => {
    if (type !== "Edit") {
      if (
        passportNumber !== "" &&
        passportCountry !== "" &&
        passportNationality !== "" &&
        passportDate !== "" &&
        passport !== ""
      ) {
        dispatch(
          addPassport(
            navigate,
            newPass,
            setAddPassSuccess,
            setAddPassportSuccessMsg
          )
        );
        setPassportCountry("");
        setPassportNationality("");
        setPassportDate("");
        setPassportNumber("");
        setPassport(null);
        setPassportError("");
      } else {
        setPassportError("Please fill all the fields!");
      }
    } else {
      if (Object.keys(editedPassport).length > 0) {
        dispatch(
          editPassport(
            navigate,
            editedPassport,
            setAddPassSuccess,
            setAddPassportSuccessMsg,
            toEditPassport.id
          )
        );
      }
    }
  };

  useEffect(() => {
    if (addPassSuccess) {
      const timer = setTimeout(() => {
        setAddPassportSuccessMsg("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [addPassSuccess]);

  const genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const [countries, setCountries] = useState([]);
  const [nations, setNations] = useState([]);

  const { Countries, getCountriesLoading } = useSelector(
    (state) => state.LocationReducer
  );

  useEffect(() => {
    setCountries(DropDownList(Countries, "id", "name"));
    setNations(DropDownList(Countries, "nationality", "nationality"));
  }, [Countries]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setClicked(true);
      dispatch(uploadPic(event.target.files[0]));
    }
  };

  useEffect(() => {
    if (imgError) {
      setUpperError(true);
    }
  }, [clicked, imgError]);
  const width = UseInnerWidth();

  const [passportInputWidth, setPassportInputWidth] = useState("20rem");

  useEffect(() => {
    if (width > 950) {
      setPassportInputWidth("20rem");
    } else if (width <= 950 && width > 800) {
      setPassportInputWidth("20rem");
    } else if (width <= 800 && width > 690) {
      setPassportInputWidth("15rem");
    } else {
      setPassportInputWidth("100%");
    }
  }, [width]);

  useEffect(() => {
    setNewUser({
      ...(newFName &&
        newFName !== user?.first_name && { first_name: newFName }),
      ...(newLName && newLName !== user?.last_name && { last_name: newLName }),
      ...(newEmail && newEmail !== user?.email && { email: newEmail }),
      ...(newDate &&
        newDate !== user?.date_of_birth && { date_of_birth: newDate }),
      ...(Natioanlity &&
        Natioanlity.value !== user?.nationality && {
          nationality: Natioanlity.value,
        }),
      ...(country &&
        country.label !== user?.country_of_residence && {
          country_of_residence: country.label,
        }),
      ...(gender && gender.value !== user?.gender && { gender: gender.value }),
      ...(language &&
        language.value !== user?.preferred_language && {
          preferred_language: language.value,
        }),
    });
  }, [
    newFName,
    newLName,
    newEmail,
    newDate,
    Natioanlity,
    profile_phone,
    country,
    gender,
    language,
  ]);

  const [newPass, setNewPass] = useState({});

  useEffect(() => {
    if (!passportEdit) {
      setNewPass({
        ...(passportNumber && { number: passportNumber }),
        ...(passportCountry && { country: passportCountry }),
        ...(passportDate && { expiry: passportDate }),
        ...(passportNationality && { nationality: passportNationality.value }),
        ...(passport && { passport: passport }),
        ...(id && { student: id }),
      });
    } else {
      setEditedPassport({
        ...(passportNumber &&
          passportNumber != toEditPassport.number && {
            number: passportNumber,
          }),
        ...(passportCountry &&
          passportCountry != toEditPassport.country && {
            country: passportCountry,
          }),
        ...(passportDate &&
          passportDate != toEditPassport.expiry && { expiry: passportDate }),
        ...(passportNationality &&
          passportNationality.value != toEditPassport.nationality && {
            nationality: passportNationality.value,
          }),
        ...(passport != null && passport != undefined && passport.originalName
          ? passport?.originalName != toEditPassport?.passport?.originalName
          : passport?.name != toEditPassport?.passport?.originalName && {
              passport: passport,
            }),
      });
    }
  }, [
    passportNumber,
    passportDate,
    passportCountry,
    passportNationality,
    passport,
    id,
  ]);
  const editUser = () => {
    if (Object.keys(newUser).length > 0) {
      dispatch(updateUser(navigate, newUser));
    } else {
      setmyProfileEdit(false);
    }
  };

  const {
    editLoading,
    addPassportFail,
    addPassportSuccess,
    addPassportLoading,
    editPassportLoading,
    editPassportFail,
    editPassportSuccess,
    editFail,
    imgLoading,
  } = useSelector((state) => state.StudentReducer);

  useEffect(() => {
    if (addPassportSuccess || editPassportSuccess) {
      setOpenPassportPopup(false);
      dispatch(getAllPassports(navigate, id));
    }
  }, [addPassportSuccess, editPassportSuccess]);

  const profile_picture = useSelector(
    (state) => state.StudentReducer?.student?.user?.profile_picture
  );
  const allPassports = useSelector((state) => state.StudentReducer?.passport);

  useEffect(() => {
    if (allPassports) {
      setUserPassport(allPassports);
    }
  }, [allPassports]);

  const [subClicked, setSubClicked] = useState(false);

  useEffect(() => {
    if ((!editLoading && !imgLoading && !getCountriesLoading) || subClicked) {
      setAccess(true);
    } else {
      setAccess(false);
      setSubClicked(false);
    }
  }, [editLoading, imgLoading, subClicked]);
  const [popup, setpopup] = useState(false);
  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.add("pen_shake");
        }
      }, 1850);
    } else {
      setTimeout(() => {
        const pens = document.getElementsByClassName("edit_pen");
        for (let i = 0; i < pens.length; i++) {
          pens[i].classList.remove("pen_shake");
        }
      }, 1850);
    }
  }, [popup]);
  const [pop2, setpop2] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (myProfileEdit) {
        // Show the popup or take any other necessary action

        setpop2(true);

        // Set the custom message for the confirmation dialog
        event.returnValue = "Are you sure you want to leave this page?";
      }
    };

    // Add the event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [myProfileEdit]);

  useEffect(() => {
    if (myProfileEdit && !pop2) {
      setpop2(true);
    }
  }, [myProfileEdit]);

  useEffect(() => {
    if (editSuccess) {
      setmyProfileEdit(false);
    }
  }, [editSuccess]);
  return (
    <>
      {popup && (
        <UpperPopUp
          setShow={setpopup}
          title="Please click on the pen to edit info"
        />
      )}
      {/* {pop2 && (
        <UpperPopUp
          setShow={setpop2}
          title="Please Save Your Changes Before Leaving The Page"
        />
      )} */}

      {access ? (
        <div className="Profile_container">
          <div className="profile_top">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              ref={ref}
              onChange={(e) => onImageChange(e)}
            />
            {pathname.includes("student") && (
              <>
                <div className="user_img" onClick={() => changeImage()}>
                  <img
                    src={
                      profile_picture === null
                        ? defaultImg
                        : process.env.REACT_APP_API +
                          "/files/students/" +
                          user?.Student?.id +
                          "/" +
                          profile_picture
                    }
                    alt="profile"
                    className="user_photo"
                  />
                  <img src={add} alt="add" className="add_img" />
                </div>
              </>
            )}
          </div>

          <div className="title">Hello {user?.first_name}</div>
          {upperError && <div className="error">{imgError}</div>}
          {myProfileEdit && (
            <Popup
              setShowPopup={() => {}}
              showClose={false}
              width={width > 768 ? "35rem" : "100%"}
              body={
                <div className="popup_profile_mian">
                  <div style={{ fontSize: "2.5rem", fontFamily: "P_Bold" }}>
                    Edit Profile Data
                  </div>
                  <div
                    className="popup_profile_body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <div className="profile_input_top popup_input">
                      <div className="input_title">First Name</div>
                      <Input
                        value={newFName}
                        onChange={(e) => setNewFName(e.target.value)}
                        placeholder="First Name"
                        type="text"
                        width={"100%"}
                        disabled={!myProfileEdit}
                      />
                    </div>
                    <div className="profile_input_top popup_input">
                      <div className="input_title">Last Name</div>
                      <Input
                        value={newLName}
                        onChange={(e) => setNewLName(e.target.value)}
                        placeholder="Last Name"
                        type="text"
                        width={"100%"}
                        disabled={!myProfileEdit}
                      />
                    </div>
                    <div className="profile_input_top popup_input">
                      <div className="input_title">Email</div>
                      <Input
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        placeholder="Email"
                        type="email"
                        width={"100%"}
                        disabled={true}
                      />
                    </div>

                    <div className="profile_input_top popup_input">
                      <div className="input_title">
                        Date of Birth{" "}
                        {!newDate && <span className="missing">Missing!</span>}
                      </div>
                      <DateInput
                        width={"100%"}
                        setSelectedDate={setNewDate}
                        selectedDate={newDate}
                        disabled={!myProfileEdit}
                        maxDate={new Date()}
                      />
                    </div>
                    <div className="profile_input_top popup_input">
                      <div className="input_title">Nationality</div>
                      {nations.length > 0 &&
                        !getCountriesLoading &&
                        Natioanlity && (
                          <DropDown
                            placeholder="Nationality"
                            setSelected={setNatioanlity}
                            data={nations}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={Natioanlity}
                            keepPlaceHolder={false}
                            isDisabled={!myProfileEdit}
                            value={Natioanlity}
                          />
                        )}
                      {!nations ||
                        (nations.length == 0 && (
                          <DropDown
                            placeholder="Nationality"
                            setSelected={setNatioanlity}
                            data={nations}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={Natioanlity}
                            keepPlaceHolder={false}
                            isDisabled={!myProfileEdit}
                            value={Natioanlity}
                          />
                        ))}
                    </div>
                    <div className="profile_input_top popup_input">
                      <div className="input_title">Phone Number</div>
                      <span
                        onClick={() => {
                          setMobilePopup(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <MobileInput
                          width={"100%"}
                          search={true}
                          required={true}
                          // disabled={true}
                          changeable={"No"}
                          disableDropdown={true}
                          setPhone={setProfile_phone}
                          phone={profile_phone}
                        />
                      </span>
                    </div>

                    {pathname.includes("student") ? (
                      <>
                        <div className="profile_input_top popup_input">
                          <div className="input_title">
                            Preferred Language{" "}
                            {!language.value && (
                              <span className="missing">Missing!</span>
                            )}
                          </div>
                          <DropDown
                            placeholder="Language"
                            setSelected={setlanguage}
                            data={lang}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={true}
                            selectedData={language}
                            keepPlaceHolder={false}
                            value={language}
                          />
                        </div>
                        <div className="profile_input_top popup_input">
                          <div className="input_title">Country</div>
                          {countries.length > 0 &&
                            !getCountriesLoading &&
                            country && (
                              <DropDown
                                placeholder="Country"
                                setSelected={setCountry}
                                data={countries}
                                blueArrow={false}
                                width={"100%"}
                                multiSelection={false}
                                searchable={false}
                                selectedData={country}
                                keepPlaceHolder={false}
                                value={country}
                              />
                            )}
                          {!countries ||
                            (countries.length == 0 && (
                              <DropDown
                                placeholder="Country"
                                setSelected={setCountry}
                                data={[]}
                                blueArrow={false}
                                width={"100%"}
                                multiSelection={false}
                                searchable={false}
                                selectedData={country}
                                keepPlaceHolder={false}
                                isDisabled={!myProfileEdit}
                                value={country}
                              />
                            ))}
                        </div>
                        <div className="profile_input_top popup_input">
                          <div className="input_title">
                            Gender{" "}
                            {!gender.value && (
                              <span className="missing">Missing!</span>
                            )}
                          </div>
                          <DropDown
                            placeholder="Gender"
                            setSelected={setGender}
                            data={genders}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={gender}
                            keepPlaceHolder={false}
                            value={gender}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="profile_input_top popup_input">
                          <div className="input_title">country</div>
                          <DropDown
                            placeholder="Country"
                            setSelected={setCountry}
                            data={countries}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={country}
                            keepPlaceHolder={false}
                            value={country}
                          />
                        </div>
                        <div className="profile_input_top popup_input">
                          <div className="input_title">Gender</div>
                          <DropDown
                            placeholder="Gender"
                            setSelected={setGender}
                            data={genders}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={gender}
                            keepPlaceHolder={false}
                            value={gender}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {editFail && <div className="error_msg">{editFail}</div>}
                  <div
                    style={{
                      marginTop: "2rem",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "1rem 2rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      color={"yellow"}
                      width={width < 768 ? "100%" : "45%"}
                      text={"Submit"}
                      onClick={() => editUser()}
                    />
                    <Button
                      color={"pink"}
                      width={width < 768 ? "100%" : "45%"}
                      text={"Cancel"}
                      onClick={() => setmyProfileEdit(false)}
                    />
                  </div>
                </div>
              }
            />
          )}
          <div className="profile_body">
            <div className="my_profile">
              <div className="profile_title">
                My Profile
                {!myProfileEdit ? (
                  <img
                    src={edit}
                    alt="edit pen"
                    className="edit_pen"
                    onClick={() => setmyProfileEdit(true)}
                  />
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      //send data to server
                      setmyProfileEdit(false);
                    }}
                  >
                    <img
                      src={check}
                      alt="edit pen"
                      className="edit_save"
                      onClick={() => setmyProfileEdit(true)}
                    />
                  </span>
                )}
              </div>
              {/* {editFail && (
                <div
                  style={{
                    fontFamily: "P_Semibold",
                    color: "var(--pink)",
                    margin: "-1rem 0 1rem 0",
                    fontSize: "1.2rem",
                  }}
                >
                  {editFail}
                </div>
              )} */}

              <div
                className="my_profile_inputs"
                onClick={!myProfileEdit ? () => setpopup(true) : null}
              >
                <div className="profile_input_top">
                  <div className="input_title">First Name</div>
                  <Input
                    value={newFName}
                    onChange={(e) => setNewFName(e.target.value)}
                    placeholder="First Name"
                    type="text"
                    width={"100%"}
                    disabled={!myProfileEdit}
                  />
                </div>
                <div className="profile_input_top">
                  <div className="input_title">Last Name</div>
                  <Input
                    value={newLName}
                    onChange={(e) => setNewLName(e.target.value)}
                    placeholder="Last Name"
                    type="text"
                    width={"100%"}
                    disabled={!myProfileEdit}
                  />
                </div>
                <div className="profile_input_top">
                  <div className="input_title">Email</div>
                  <Input
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    width={"100%"}
                    disabled={!myProfileEdit}
                  />
                </div>
                <div className="profile_input_top">
                  <div className="input_title">
                    Date of Birth{" "}
                    {!newDate && <span className="missing">Missing!</span>}
                  </div>
                  <DateInput
                    width={"100%"}
                    setSelectedDate={setNewDate}
                    selectedDate={newDate}
                    disabled={!myProfileEdit}
                    maxDate={new Date()}
                  />
                </div>
                <div className="profile_input_top">
                  <div className="input_title">Nationality</div>
                  {nations.length > 0 &&
                    !getCountriesLoading &&
                    Natioanlity && (
                      <DropDown
                        placeholder="Nationality"
                        setSelected={setNatioanlity}
                        data={nations}
                        blueArrow={false}
                        width={"100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={Natioanlity}
                        keepPlaceHolder={false}
                        isDisabled={!myProfileEdit}
                        value={Natioanlity}
                      />
                    )}
                  {!nations ||
                    (nations.length == 0 && (
                      <DropDown
                        placeholder="Nationality"
                        setSelected={setNatioanlity}
                        data={nations}
                        blueArrow={false}
                        width={"100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={Natioanlity}
                        keepPlaceHolder={false}
                        isDisabled={!myProfileEdit}
                        value={Natioanlity}
                      />
                    ))}
                </div>
                <div className="profile_input_top">
                  <div className="input_title">Phone Number</div>
                  <span
                    onClick={() => {
                      myProfileEdit && setMobilePopup(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <MobileInput
                      width={"100%"}
                      search={true}
                      required={true}
                      disabled={true}
                      disableDropdown={true}
                      setPhone={setProfile_phone}
                      phone={profile_phone}
                    />
                  </span>
                </div>

                {pathname.includes("student") ? (
                  <>
                    <div className="profile_input_top">
                      <div className="input_title">
                        Preferred Language{" "}
                        {!language.value && (
                          <span className="missing">Missing!</span>
                        )}
                      </div>
                      <DropDown
                        placeholder="Language"
                        setSelected={setlanguage}
                        data={lang}
                        blueArrow={false}
                        width={"100%"}
                        multiSelection={false}
                        searchable={true}
                        selectedData={language}
                        keepPlaceHolder={false}
                        isDisabled={!myProfileEdit}
                        value={language}
                      />
                    </div>
                    <div className="profile_input_top">
                      <div className="input_title">Country</div>
                      {countries.length > 0 &&
                        !getCountriesLoading &&
                        country && (
                          <DropDown
                            placeholder="Country"
                            setSelected={setCountry}
                            data={countries}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={country}
                            keepPlaceHolder={false}
                            isDisabled={!myProfileEdit}
                            value={country}
                          />
                        )}
                      {!countries ||
                        (countries.length == 0 && (
                          <DropDown
                            placeholder="Country"
                            setSelected={setCountry}
                            data={[]}
                            blueArrow={false}
                            width={"100%"}
                            multiSelection={false}
                            searchable={false}
                            selectedData={country}
                            keepPlaceHolder={false}
                            isDisabled={!myProfileEdit}
                            value={country}
                          />
                        ))}
                    </div>
                    <div className="profile_input_top">
                      <div className="input_title">
                        Gender{" "}
                        {!gender.value && (
                          <span className="missing">Missing!</span>
                        )}
                      </div>
                      <DropDown
                        placeholder="Gender"
                        setSelected={setGender}
                        data={genders}
                        blueArrow={false}
                        width={width > 774 && width < 1183 ? "47.5%" : "100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={gender}
                        keepPlaceHolder={false}
                        isDisabled={!myProfileEdit}
                        value={gender}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="profile_input_top">
                      <div className="input_title">country</div>
                      <DropDown
                        placeholder="Country"
                        setSelected={setCountry}
                        data={countries}
                        blueArrow={false}
                        width={width > 1170 && width < 1580 ? "47.5%" : "100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={country}
                        keepPlaceHolder={false}
                        isDisabled={!myProfileEdit}
                        value={country}
                      />
                    </div>
                    <div
                      className="profile_input_top"
                      style={{
                        ...(width > 1170 &&
                          width < 1580 && { marginLeft: "-35%" }),
                      }}
                    >
                      <div className="input_title">Gender</div>
                      <DropDown
                        placeholder="Gender"
                        setSelected={setGender}
                        data={genders}
                        blueArrow={false}
                        width={width > 1170 && width < 1580 ? "47.5%" : "100%"}
                        multiSelection={false}
                        searchable={false}
                        selectedData={gender}
                        keepPlaceHolder={false}
                        isDisabled={!myProfileEdit}
                        value={gender}
                      />
                    </div>
                  </>
                )}
              </div>
              {userPassport.length > 0 && (
                <PassportTable
                  setToEditPassport={setToEditPassport}
                  userPassport={userPassport}
                  setUserPassport={setUserPassport}
                  setPassportEdit={setPassportEdit}
                  setPassportNumber={setPassportNumber}
                  setPassport={setPassport}
                  setPassportDate={setPassportDate}
                  setPassportNationality={setPassportNationality}
                  setPassportCountry={setPassportCountry}
                  setOpenPassportPopup={setOpenPassportPopup}
                />
              )}
              <div className="Add_passport">
                {pathname.includes("student") && (
                  <Button
                    text="Add Passport"
                    color="yellow"
                    width={"100%"}
                    onClick={() => {
                      setOpenPassportPopup(true);
                      setPassportCountry("");
                      setPassportNationality("");
                      setPassportDate("");
                      setPassportNumber("");
                      setPassport(null);
                      setPassportEdit(false);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {pathname.includes("student") && (
            <>
              {fetchEmgLoading || fetchAddressLoading ? (
                <Loader fullScreen={true} />
              ) : !profileCompleted ? (
                <div className="compelet_profile">
                  <div className="compelet_profile_inner">
                    <span className="complete_profile_text">More Details</span>
                    <Button
                      text="Complete Your Profile"
                      color={"lightGreen"}
                      onClick={() =>
                        navigate("/student/profile/completeProfile")
                      }
                    />
                  </div>
                </div>
              ) : (
                <MoreDetails width={width} />
              )}
            </>
          )}
          <PrivacyAndSettings
            setNewsLetterSub={setNewsLetterSub}
            newsLetterSub={newsLetterSub}
            setEmailSub={setEmailSub}
            emailSub={emailSub}
            changePass={changePass}
            setChangePass={setChangePass}
            setSubClicked={setSubClicked}
          />
          {openPassportPopup && (
            <Popup
              setShowPopup={setOpenPassportPopup}
              showClose={width < 768 && true}
              body={
                <div style={{ padding: "0 1rem" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "2rem",
                      marginBottom: "2rem",
                    }}
                    className="passport_popup_main"
                  >
                    <div className="upload_passport_popup">
                      Upload Your Passport
                    </div>
                    {!passportEdit && (
                      <img
                        src={add}
                        alt="add_img"
                        style={{ width: "25px", cursor: "pointer" }}
                        onClick={() => handlePassport("add")}
                      />
                    )}
                  </div>
                  <div>
                    {!passportEdit && userPassport.length > 0 && (
                      <div className="passport_popup">
                        {userPassport.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="Passport_popup_title">
                                <span className="Passport_popup_title_number">
                                  Passport #{index + 1}:&nbsp;
                                </span>
                                {item.name}
                              </div>
                              <div className="Passport_popup_body">
                                <div className="Passport_popup_body_left">
                                  <div className="Passport_popup_body_left_div">
                                    <div>
                                      <div className="Passport_popup_body_left_title">
                                        Passport Number
                                      </div>
                                      <div className="Passport_popup_body_left_text">
                                        {item.number}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="Passport_popup_body_left_title">
                                        Passport Expiry Date
                                      </div>
                                      <div className="Passport_popup_body_left_text">
                                        {item.expiry}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Passport_popup_body_left_div">
                                    <div>
                                      <div className="Passport_popup_body_left_title">
                                        Country
                                      </div>
                                      <div className="Passport_popup_body_left_text">
                                        {item.country}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="Passport_popup_body_left_title">
                                        Nationality
                                      </div>
                                      <div className="Passport_popup_body_left_text">
                                        {item?.nationality}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="Passport_popup_body_right">
                                  <div
                                    className="Passport_popup_body_right_div"
                                    onClick={() =>
                                      handlePreview(
                                        "/students/" +
                                          user.Student.id +
                                          "/passports/" +
                                          item.document.path,
                                        item.document.path
                                      )
                                    }
                                  >
                                    <img
                                      src={documentt}
                                      alt="document"
                                      width="30px"
                                    />
                                    <div className="Passport_popup_body_right_div_description">
                                      {item.document.type
                                        .split("/")[1]
                                        .toUpperCase() + " "}
                                      {(
                                        item.document.size /
                                        1024 /
                                        1024
                                      ).toFixed(2)}
                                      MB
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="last_passport_popup">
                          Passport #{userPassport.length + 1}:
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "2rem",
                        ...(width <= 690 && { flexDirection: "column" }), //media query
                        marginBottom: "1.5rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div className="input_title">Passport Number</div>
                        <Input
                          placeholder="121354"
                          type="text"
                          width={passportInputWidth}
                          onChange={(e) => setPassportNumber(e.target.value)}
                          value={passportNumber}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="input_title">Passport Expiry Date</div>
                        <DateInput
                          width={passportInputWidth}
                          setSelectedDate={setPassportDate}
                          selectedDate={passportDate}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        ...(width <= 690 && { flexDirection: "column" }), //media query
                        gap: "2rem",
                        marginBottom: "1.5rem",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div className="input_title">Place of Birth</div>
                        {/* <DropDown
                          placeholder="Country of residence"
                          setSelected={setPassportCountry}
                          data={countries}
                          blueArrow={false}
                          width={passportInputWidth}
                          multiSelection={false}
                          searchable={true}
                          selectedData={passportCountry}
                          keepPlaceHolder={false}
                          isDisabled={false}
                          value={passportCountry}
                        /> */}
                        <Input
                          placeholder="Place of Birth"
                          type="text"
                          width={passportInputWidth}
                          onChange={(e) => setPassportCountry(e.target.value)}
                          value={passportCountry}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="input_title">Nationality</div>
                        <DropDown
                          placeholder="Nationality"
                          setSelected={setPassportNationality}
                          data={nations}
                          blueArrow={false}
                          width={passportInputWidth}
                          multiSelection={false}
                          searchable={true}
                          selectedData={passportNationality}
                          keepPlaceHolder={false}
                          isDisabled={false}
                          value={passportNationality}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "2rem",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="input_title">
                            Upload your Passport or ID to autofill
                          </div>
                          {passport !== null && passport !== undefined && (
                            <img
                              src={close}
                              alt="delete"
                              style={{
                                width: "25px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setPassport(null);
                                passRef.current.value = "";
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            border: "3px solid black",
                            borderRadius: "10px",
                            boxShadow: "var(--shadow)",
                            height: "8rem",
                            display: "flex",
                            gap: "0.3rem",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            position: "relative",
                          }}
                          onClick={() => passRef.current.click()}
                        >
                          {passport === null || passport === undefined ? (
                            <>
                              <img
                                src={attachment}
                                alt="attachment"
                                style={{ width: "50px" }}
                              />
                              <div
                                style={{
                                  fontFamily: "P_Regular",
                                  opacity: "0.6",
                                  fontSize: "0.8rem",
                                }}
                              >
                                Upload here
                              </div>
                            </>
                          ) : (
                            <div>
                              {passportEdit
                                ? passport?.originalName
                                  ? passport?.originalName
                                  : passport?.name
                                : passport?.name}
                            </div>
                          )}
                        </div>
                      </div>
                      {passportError && (
                        <div
                          style={{
                            margin: "1rem 0 -1rem 0",
                            color: "var(--pink)",
                            fontFamily: "P_Bold",
                          }}
                        >
                          {passportError}
                        </div>
                      )}
                      <div
                        style={{
                          padding: "2.5rem 0 0 0",
                          width: "100%",
                        }}
                      >
                        {addPassportFail && (
                          <div
                            style={{
                              color: "var(--pink)",
                              fontFamily: "P_Bold",
                              fontSize: "1.2rem",
                              marginTop: "-1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            {addPassportFail}
                          </div>
                        )}
                        {editPassportFail && (
                          <div
                            style={{
                              color: "var(--pink)",
                              fontFamily: "P_Bold",
                              fontSize: "1.2rem",
                              marginTop: "-1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            {editPassportFail}
                          </div>
                        )}
                        <Button
                          text={
                            addPassportLoading || editPassportLoading ? (
                              <Loader onlyDots={true} />
                            ) : (
                              "Done"
                            )
                          }
                          color={"yellow"}
                          width={"100%"}
                          disabled={
                            !addPassportLoading && !editPassportLoading
                              ? passportNumber !== "" &&
                                passportDate !== "" &&
                                passportCountry !== "" &&
                                passportNationality !== "" &&
                                passport !== null &&
                                passport !== undefined
                                ? false
                                : true
                              : true
                          }
                          onClick={() => {
                            !passportEdit
                              ? handlePassport("Done")
                              : handlePassport("Edit");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={passRef}
                    accept=".jpg, .jpeg, .png, .pdf, .doc, .xls"
                    onChange={(e) => {
                      setPassport(e.target.files[0]);
                    }}
                  />
                </div>
              }
            />
          )}
          {mobilePopup && (
            <Popup
              setShowPopup={setMobilePopup}
              body={
                <div className="mobile_popup">
                  <div className="mobile_popup_title">
                    Please enter your new phone number
                  </div>
                  <MobileInput
                    width={"100%"}
                    search={false}
                    required={true}
                    disabled={false}
                    disableDropdown={true}
                    setPhone={setNewPhone}
                    phone={newPhone}
                  />
                  {phoneVerifyError && (
                    <div
                      style={{
                        color: "var(--pink)",
                        fontFamily: "P_Semibold",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        width: "100%",
                        margin: "1rem 0 -1rem 0",
                      }}
                    >
                      {phoneVerifyError}
                    </div>
                  )}
                  {!phoneVerifyLoading ? (
                    <div className="mobile_popup_buttons">
                      <Button
                        text="Verify"
                        color={"pink"}
                        width={"100%"}
                        disabled={newPhone === "" ? true : false}
                        onClick={() => {
                          dispatch(
                            phoneVerification({
                              userId: user.id,
                              phone: newPhone,
                            })
                          );
                        }}
                      />
                      <Button
                        text="Cancel"
                        color={"yellow"}
                        width={"100%"}
                        onClick={() => {
                          setMobilePopup(false);
                        }}
                      />
                    </div>
                  ) : (
                    <Button
                      text={<Loader onlyDots={true} />}
                      disabled={true}
                      color={"yellow"}
                      width={"100%"}
                    />
                  )}
                </div>
              }
            />
          )}
        </div>
      ) : (
        <div>
          <Loader fullScreen={true} />
        </div>
      )}
    </>
  );
}

export default Profile;
