import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./CounselorApplications.scss";
import Button from "../../../Shared/Components/Button/Button";
import SearchIcon from "../../../Shared/Assets/Images/search_icon.png";
import Add from "../../../Shared/Assets/Images/add.png";
import ViewMode from "../../../Shared/Assets/Images/blue_View.png";
import ArrowUp from "../../../Shared/Assets/Images/arrow_up.svg";
import StudentCard from "../../../Shared/Components/StudentCard/StudentCard";
import SearchInput from "../../../Shared/Components/SearchInput/SearchInput";
import Loader from "../../../Shared/Components/Loader/Loader";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import filter from "../../../Shared/Assets/Images/filterMob.png";
import tableIcon from "../../../Shared/Assets/Images/tableView.png";
import cardIcon from "../../../Shared/Assets/Images/cardView.png";
import Filters from "../../../Shared/Components/Filters/Filters";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import CounselorTable from "../../Components/CounselorTable/CounselorTable";
import { getAllCounselorApps } from "../../Redux/CounselorApplication/CounselorApplicationActions";
import { IdSplit, dateConv } from "../../../Utils";
import Pagination from "../../../Shared/Components/Pagination/Pagination";

function CounselorApplications() {
  const [chosenStatus, setChosenStatus] = useState(0);
  const [counselor, setCounselor] = useState(true);
  const [tableView, setTableView] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [all, setAll] = useState(false);
  const [data, setData] = useState({});
  ////////Pagination States////////
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [status, setStatus] = useState("Draft");
  const [search, setSearch] = useState("");
  const [counter, setCounter] = useState(0);
  ////////////////////////////////
  const scWidth = UseInnerWidth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statuses = [
    { value: "Draft", label: "Draft" },
    { value: "In Review", label: "In Review" },
    { value: "Missing Documents", label: "Missing Documents" },
    { value: "Ready To Be Sent", label: "Ready To Be Sent" },
    { value: "Submitted", label: "Submitted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Accepted", label: "Confirmed" },
    { value: "Canceled", label: "Canceled" },
    { value: "Applying For Visa", label: "Applying For Visa" },
    {
      value: "Enrolled",
      label: "Enrolled",
    },
  ];

  useEffect(() => {
    dispatch(
      getAllCounselorApps(
        offset,
        limit,
        data?.country?.label,
        status?.label ? status?.label : status,
        data?.major?.label,
        data?.language?.label,
        data?.sort?.label
      )
    );
  }, []);
  useEffect(() => {
    dispatch(
      getAllCounselorApps(
        offset,
        limit,
        data?.country?.label,
        status?.label ? status?.label : status,
        data?.major?.label,
        data?.language?.label,
        data?.sort?.label
      )
    );
    setSearch("");
  }, [offset, limit, status]);
  useEffect(() => {
    if (search) {
      setCounter(counter + 1);
      const timer = setTimeout(() => {
        dispatch(
          getAllCounselorApps(
            offset,
            limit,
            data?.country?.label,
            status?.label ? status?.label : status,
            data?.major?.label,
            data?.language?.label,
            data?.sort?.label,
            search
          )
        );
      }, 800);

      return () => clearTimeout(timer);
    } else if (counter > 0 && !search) {
      const timer = setTimeout(() => {
        dispatch(getAllCounselorApps(limit, offset));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [search]);
  const { Applications, getAllAppsLoading } = useSelector(
    (store) => store.CounselorApplicationReducer
  );

  useEffect(() => {
    if (scWidth < 990) {
      setTableView(false);
    }
  }, [scWidth]);
  return (
    <div className="CAContainer">
      <>
        <div className="CAInnerContainer">
          <div className="CAHeader">
            <div className="CAHeaderRight">
              <div className="CASearchButton">
                <div className="CAMainFilterDiv">
                  <div className="catgUni_search">
                    <SearchInput
                      placeholder="Search for applications"
                      width={scWidth > 768 ? "400px" : "100%"}
                      inputV={search}
                      setInputV={setSearch}
                    />
                  </div>
                  <div className="CA_search_buttons">
                    {counselor ? (
                      scWidth > 990 && (
                        <div className="catgUni_search_button1">
                          <Button
                            text={
                              <div className="viewMode_btn">
                                View Mode
                                <img
                                  src={tableView ? tableIcon : cardIcon}
                                  alt="viewMode"
                                  className="viewMode"
                                />
                              </div>
                            }
                            color="yellow"
                            width="Fit-Content"
                            onClick={() => {
                              setTableView(!tableView);
                            }}
                          />
                        </div>
                      )
                    ) : (
                      <div className="catgUni_search_button1">
                        <Button
                          text={all ? "View By Sections" : "View All"}
                          color="yellow"
                          width="Fit-Content"
                          // onClick={() => {
                          //   !all
                          //     ? navigate("/student/universities/all")
                          //     : navigate("/student/universities");
                          // }}
                        />
                      </div>
                    )}
                    <div className="catgUni_search_button2">
                      <span>
                        <Button
                          text={
                            scWidth <= 680 ? (
                              <img
                                src={filter}
                                alt="filter"
                                className="filter_img_uni"
                              />
                            ) : (
                              "Add filter"
                            )
                          }
                          color="blue"
                          width="150px"
                          onClick={() => {
                            setOpenFilter(true);
                          }}
                        />
                      </span>
                      {openFilter && (
                        <Filters
                          setOpened={setOpenFilter}
                          application={true}
                          data={data}
                          setData={setData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="AddPlusTitle">
              <div className="CAHeaderTitle">Applications</div>
              {/* <img className="CAAddImg" src={Add} alt="Add Application" /> */}
              {scWidth <= 1300 && (
                <DropDown
                  width={scWidth < 450 ? "100%" : "25rem"}
                  data={statuses}
                  blueArrow={true}
                  searchable={false}
                  selectedData={status}
                  setSelected={setStatus}
                  value={status}
                />
              )}
            </div>
          </div>

          <div className="CAStatusDiv">
            {scWidth > 1300 &&
              statuses.map((status, index) => {
                return (
                  <div className="CABody" key={index}>
                    <div
                      className={
                        chosenStatus === index
                          ? "CAStatus CAStatusSelected"
                          : "CAStatus "
                      }
                      onClick={() => {
                        setChosenStatus(index);
                        setStatus(status.label);
                      }}
                    >
                      {status.label}
                    </div>
                  </div>
                );
              })}
          </div>
          <img
            className={
              chosenStatus === 0
                ? "CAArrow_Up firstSelection"
                : chosenStatus === 1
                ? "CAArrow_Up secondSelection"
                : chosenStatus === 2
                ? "CAArrow_Up thirdSelection"
                : chosenStatus === 3
                ? "CAArrow_Up fourthSelection"
                : chosenStatus === 4
                ? "CAArrow_Up fifthSelection"
                : chosenStatus === 5
                ? "CAArrow_Up sixthSelection"
                : chosenStatus === 6
                ? "CAArrow_Up seventhSelection"
                : chosenStatus === 7
                ? "CAArrow_Up eighthSelection"
                : "CAArrow_Up ninethSelection"
            }
            src={ArrowUp}
          />
        </div>
        {tableView && scWidth > 1000 ? (
          <>
            {getAllAppsLoading && (
              <div>
                <Loader fullScreen={true} />
              </div>
            )}
            <CounselorTable
              students={Applications}
              application={true}
              limit={limit}
              offset={offset}
              hasPrev={Applications.previous}
              hasNext={Applications.next}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setOffset={setOffset}
              setLimit={setLimit}
            />
          </>
        ) : (
          <div className="CounselorApplicationsLowerInner">
            <div className="CounselorApplicationBody CounselorStudentHeader">
              {getAllAppsLoading && (
                <div>
                  <Loader fullScreen={true} />
                </div>
              )}
              <div className="CACardDiv">
                {Applications?.applications?.map((App, index) => {
                  return (
                    <div
                      key={index}
                      className="CASingleStudentCard"
                      onClick={() => {
                        navigate(`/counselor/applications/singleApp/${App.id}`);
                      }}
                    >
                      <StudentCard
                        id={IdSplit(App.id)}
                        name={App.first_name + " " + App.last_name}
                        degree={App?.major_degree?.degree?.name}
                        status={
                          App.education_summary !== null ? (
                            App?.education_summary[0].gpa +
                            "/" +
                            App?.education_summary[0].grade_scale
                          ) : (
                            <span className="CANullGPA">None</span>
                          )
                        }
                        email={App?.student?.user?.email}
                        nationality={App?.student?.user?.nationality}
                        date={dateConv(App?.createdAt)}
                        GPA={true}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <Pagination
              limit={limit}
              offset={offset}
              hasPrev={Applications.previous}
              hasNext={Applications.next}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setOffset={setOffset}
              setLimit={setLimit}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default CounselorApplications;
