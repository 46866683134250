import React, { useEffect, useState } from "react";
import "./Universities.scss";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Button from "../../Components/Button/Button";
import star from "../../Assets/Images/star.webp";
import heart from "../../Assets/Images/redheart.webp";
import like from "../../Assets/Images/like.webp";
import UniCard from "../../Components/Unicard/UniCard";
import logo from "../../Assets/Images/logo2.webp";
import uni from "../../Assets/Images/uni.jpeg";
import {
  getAllUnis,
  getAllUnisCatg,
} from "../../../Shared/Redux/Universities/UniversityActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Filters from "../../Components/Filters/Filters";
import Pagination from "../../Components/Pagination/Pagination";
import "react-multi-carousel/lib/styles.css";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import filter from "../../../Shared/Assets/Images/filterMob.png";
import CardCarousel from "../../Components/CardCarousel/CardCarousel";
import tableIcon from "../../../Shared/Assets/Images/tableView.png";
import cardIcon from "../../../Shared/Assets/Images/cardView.png";
import Tooltip from "@mui/material/Tooltip";

function Universities({ all, counselor }) {
  const navigate = useNavigate();

  const { AllUnis, getAllUnisLoading, uniCatg, getUniCatgLoading } =
    useSelector((state) => state.UniversitiesReducer);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [offset, setOffset] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [nbOfUni, setNbOfUni] = useState(3);
  const [counter, setCounter] = useState(0);
  const [data, setData] = useState({
    tuitionHigh: 16000,
    tuitionLow: 0,
  });

  function getFirst200Words(text) {
    // Split the text into an array of words
    const words = text.split(" ");

    // If the text has 200 words or less, return the entire text
    if (words.length <= 23) {
      return text;
    }

    // Otherwise, slice the array to get the first 200 words, and join them back into a string
    const first200Words = words.slice(0, 18).join(" ");
    return first200Words + "...";
  }

  const screenWidth = UseInnerWidth();
  useEffect(() => {
    if (!counselor) dispatch(getAllUnisCatg());
  }, []);
  useEffect(() => {
    if (all && search === "")
      dispatch(
        getAllUnis(limit, offset, "", {
          country: data?.country?.label,
          major: data?.major?.label,
          language: data?.language?.label,
          city: data?.city?.label,
          degree: data?.degree?.label,
          tuitionLow: data?.tuitionLow,
          tuitionHigh: data?.tuitionHigh,
        })
      );
    else {
      if (!counselor) dispatch(getAllUnisCatg());
    }
  }, [all]);
  useEffect(() => {
    if (all)
      dispatch(
        getAllUnis(limit, offset, "", {
          country: data?.country?.label,
          major: data?.major?.label,
          language: data?.language?.label,
          city: data?.city?.label,
          degree: data?.degree?.label,
          tuitionLow: data?.tuitionLow,
          tuitionHigh: data?.tuitionHigh,
        })
      );
  }, [limit, offset]);

  useEffect(() => {
    if (screenWidth >= 1896) {
      setNbOfUni(4);
    } else if (screenWidth >= 1250) {
      setNbOfUni(3);
    } else if (screenWidth >= 800) {
      setNbOfUni(1);
    } else if (screenWidth < 800) {
      setNbOfUni(1);
    }
    if (screenWidth < 990) {
      setTableView(false);
    }
  }, [screenWidth]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1896 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1896, min: 1250 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1250, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (counselor) {
      setTableView(true);
    }
  }, []);

  useEffect(() => {
    if (search) {
      setCounter(counter + 1);
      const timer = setTimeout(() => {
        if (!all) navigate("all");
        dispatch(
          getAllUnis(limit, offset, search, {
            country: data?.country?.label,
            major: data?.major?.label,
            language: data?.language?.label,
            city: data?.city?.label,
            degree: data?.degree?.label,
            tuitionLow: data?.tuitionLow,
            tuitionHigh: data?.tuitionHigh,
          })
        );
      }, 800);

      return () => clearTimeout(timer);
    } else if (counter > 0 && !search && all) {
      const timer = setTimeout(() => {
        dispatch(
          getAllUnis(limit, offset, "", {
            country: data?.country?.label,
            major: data?.major?.label,
            language: data?.language?.label,
            city: data?.city?.label,
            degree: data?.degree?.label,
            tuitionLow: data?.tuitionLow,
            tuitionHigh: data?.tuitionHigh,
          })
        );
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [search]);
  const { pathname } = useLocation();
  return (
    <div className="catgUni_main_div">
      {(getAllUnisLoading || getUniCatgLoading) && <Loader fullScreen={true} />}
      {openFilter && (
        <Filters
          setOpened={setOpenFilter}
          type="university"
          data={data}
          setData={setData}
          application={false}
        />
      )}

      <div className="catgUni_main_div_filtering">
        <div className="catgUni_search">
          <SearchInput
            placeholder="Search for universities"
            width={screenWidth > 768 ? "400px" : "100%"}
            inputV={search}
            setInputV={setSearch}
          />
        </div>
        <div className="catgUni_search_buttons">
          {counselor ? (
            screenWidth > 990 && (
              <div className="catgUni_search_button1">
                <Button
                  text={
                    <div className="viewMode_btn">
                      View Mode
                      <img
                        src={tableView ? tableIcon : cardIcon}
                        alt="viewMode"
                        className="viewMode_img"
                      />
                    </div>
                  }
                  color="yellow"
                  width="Fit-Content"
                  onClick={() => {
                    setTableView(!tableView);
                    setSearch("");
                  }}
                />
              </div>
            )
          ) : (
            <div className="catgUni_search_button1">
              <Button
                text={all ? "View By Sections" : "View All"}
                color="yellow"
                width="Fit-Content"
                onClick={() => {
                  setSearch("");
                  !all
                    ? navigate("/student/universities/all")
                    : navigate("/student/universities");
                }}
              />
            </div>
          )}
          <div className="catgUni_search_button2">
            <Button
              text={
                screenWidth <= 768 ? (
                  <img src={filter} alt="filter" className="filter_img_uni" />
                ) : (
                  "Add filter"
                )
              }
              color="blue"
              width="150px"
              onClick={() => {
                setOpenFilter(true);
                !all && navigate("all");
              }}
            />
          </div>
        </div>
      </div>
      {!all ? (
        <div className="catgUni_main_div_unitcatg">
          <div className="catgUni_main_div_cards">
            <div className="catgUni_main_div_cardSec">
              {uniCatg?.top?.length > 0 && (
                <div className="catgUni_main_div_card_mainheader">
                  <div className="catgUni_main_div_card_header">
                    <img src={star} alt="star" className="title_img" />
                    <h1 className="catgUni_main_div_card_title">
                      Top Universities
                    </h1>
                  </div>
                </div>
              )}
              {uniCatg?.top?.length > 0 && (
                <CardCarousel
                  cards={uniCatg?.top.map((uni) => {
                    return (
                      <UniCard
                        id={uni.id}
                        image={uni.image}
                        uniImage={uni.logo}
                        uniName={uni.name}
                        full={!uni.accepting_applications}
                        uniLocation={uni.country + ", " + uni.city}
                        uniDescription={getFirst200Words(uni.description)}
                        favorite={uni.isFavourite}
                        isStudent={true}
                      />
                    );
                  })}
                  screenwidth={screenWidth}
                  responsive={responsive}
                  cardLength={uniCatg?.top?.length}
                  nbOfCards={nbOfUni}
                />
              )}
            </div>
            <div className="catgUni_main_div_cardSec">
              {uniCatg?.favourites?.length > 0 && (
                <div className="catgUni_main_div_card_mainheader">
                  <div className="catgUni_main_div_card_header">
                    <img src={heart} alt="star" className="title_img" />
                    <h1 className="catgUni_main_div_card_title">
                      Your Favorites
                    </h1>
                  </div>
                </div>
              )}
              {uniCatg?.favourites?.length > 0 && (
                <CardCarousel
                  cards={uniCatg?.favourites?.map((uni) => {
                    return (
                      <UniCard
                        id={uni.id}
                        image={uni.image}
                        uniImage={uni.logo}
                        uniName={uni.name}
                        uniLocation={uni.country + ", " + uni.city}
                        uniDescription={getFirst200Words(uni.description)}
                        favorite={true}
                        full={!uni.accepting_applications}
                        isStudent={true}
                      />
                    );
                  })}
                  screenwidth={screenWidth}
                  responsive={responsive}
                  cardLength={uniCatg?.favourites?.length}
                  nbOfCards={nbOfUni}
                />
              )}
            </div>
            <div className="catgUni_main_div_cardSec">
              {uniCatg?.recommended?.length > 0 && (
                <div className="catgUni_main_div_card_mainheader">
                  <div className="catgUni_main_div_card_header">
                    <img src={like} alt="star" className="title_img" />
                    <h1 className="catgUni_main_div_card_title">Recommended</h1>
                  </div>
                </div>
              )}
              {uniCatg?.recommended?.length > 0 && (
                <CardCarousel
                  cards={uniCatg?.recommended?.map((uni) => {
                    return (
                      <UniCard
                        id={uni.id}
                        image={uni.image}
                        uniImage={uni.logo}
                        uniName={uni.name}
                        uniLocation={uni.country + ", " + uni.city}
                        uniDescription={getFirst200Words(uni.description)}
                        favorite={uni.isFavourite}
                        full={!uni.accepting_applications}
                        isStudent={true}
                      />
                    );
                  })}
                  screenwidth={screenWidth}
                  responsive={responsive}
                  cardLength={uniCatg?.recommended?.length}
                  nbOfCards={nbOfUni}
                />
              )}
            </div>
          </div>{" "}
          {uniCatg?.recommended?.length === 0 &&
            uniCatg?.favourites?.length === 0 &&
            uniCatg?.top?.length === 0 && (
              <div className="no_uni_found">No Recommended Universities</div>
            )}
        </div>
      ) : (
        <div className="catgUni_main_div_allcards">
          <div className="catgUni_main_div_card_header">
            <img src={star} alt="star" className="title_img" />
            <h1 className="catgUni_main_div_card_title"> All Universities</h1>
          </div>
          {tableView && (
            <div className="uni_tableView_header">
              <div className="uni_tableView_header_text"> Universities</div>
              <div className="uni_tableView_header_text"> Location</div>
              <div className="uni_tableView_header_text"> Language</div>
              <div className="uni_tableView_header_text"> Deadline</div>
              <div className="uni_tableView_header_text"> Status</div>
            </div>
          )}
          <div
            className={
              tableView
                ? "catgUni_main_div_tableView"
                : "catgUni_main_div_card_body"
            }
          >
            {AllUnis?.universities?.length > 0 ? (
              AllUnis?.universities?.map((univ) => {
                if (tableView)
                  return (
                    <div
                      className={
                        !univ.accepting_applications
                          ? "uni_tableView_card full_uni"
                          : "uni_tableView_card"
                      }
                      onClick={() => {
                        pathname.includes("counselor")
                          ? navigate(
                              "/counselor/universities/singleUni/" + univ.id
                            )
                          : pathname.includes("sales")
                          ? navigate("/sales/universities/singleUni/" + univ.id)
                          : navigate(
                              "/student/universities/singleUni/" + univ.id
                            );
                      }}
                    >
                      <div className="tableView_card_header">
                        <img
                          src={
                            process.env.REACT_APP_API +
                            "/files/universities/" +
                            univ.logo
                          }
                          alt="uniImage"
                          className="tableView_card_header_img"
                        />
                        <div className="tableView_card_body_text_header">
                          {univ.name}
                        </div>
                      </div>
                      <Tooltip title={univ.country + ", " + univ.city}>
                        <div className="tableView_card_body_text">
                          {univ.country + ", " + univ.city}
                        </div>
                      </Tooltip>
                      <div className="tableView_card_body_text">
                        {univ.languages.map((lang, i) => {
                          if (i === univ.languages.length - 1) return lang;
                          else return lang + ", ";
                        })}
                      </div>{" "}
                      <div className="tableView_card_body_text">
                        {new Date(univ.application_deadline).getDate() +
                          "/" +
                          (new Date(univ.application_deadline).getMonth() + 1) +
                          "/" +
                          new Date(univ.application_deadline).getFullYear()}
                      </div>
                      <div
                        className="tableView_card_body_text"
                        style={{
                          color: univ.accepting_applications
                            ? "var(--lightGreen)"
                            : "var(--pink)",
                        }}
                      >
                        {!univ.accepting_applications
                          ? "Full"
                          : "Still Recruiting"}
                      </div>
                    </div>
                  );
                else
                  return (
                    <UniCard
                      all={all}
                      id={univ.id}
                      width="435px"
                      image={univ.image}
                      uniImage={univ.logo}
                      uniName={univ.name}
                      uniLocation={univ.country + ", " + univ.city}
                      uniDescription={getFirst200Words(univ.description)}
                      favorite={univ.isFavourite}
                      full={!univ.accepting_applications}
                      isStudent={counselor ? false : true}
                    />
                  );
              })
            ) : (
              <div className="no_uni_found">No Universities Found</div>
            )}
          </div>
          <Pagination
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            pageNumber={page}
            setPageNumber={setPage}
            hasPrev={AllUnis?.previous}
            hasNext={AllUnis?.next}
          />
        </div>
      )}
    </div>
  );
}

export default Universities;
