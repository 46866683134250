import React from "react";
import "./Progress.scss";

function Progress({ ongoing }) {
  return (
    <div
      className="Progress_Main"
      style={{   background: ongoing== 0 ? "linear-gradient(to right,rgba(128, 213, 236,0.5) 50%,rgba(128, 213, 236,0.5) 50%)":  ongoing==1 ? "linear-gradient(to right, var(--blue) 50%,rgba(128, 213, 236,0.5) 50%)" : "linear-gradient(to right, var(--blue) 50%, var(--blue) 50%)"}}
    ></div>
  );
}

export default Progress;
