/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  DirectPath: [],
  PathwayProviders: [],
  loading: false,
  CreateDirectLoading: false,
};

const UniversitiesSlice = createSlice({
  name: "Universities",
  initialState,
  reducers: {
    GetDirectRequest(state) {
      state.loading = true;
      state.error = "";
    },
    GetDirectSuccess(state, action) {
      state.loading = false;
      state.DirectPath = action.payload;
      state.error = "";
    },
    GetDirectFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    CreateDirectRequest(state) {
      state.CreateDirectLoading = true;
      state.error = "";
    },
    CreateDirectSuccess(state, action) {
      state.CreateDirectLoading = false;
      // state.DirectPath.push(action.payload);
      state.error = "";
    },
    CreateDirectFail(state, action) {
      state.CreateDirectLoading = false;
      state.error = action.payload;
    },
    GetPathwayRequest(state) {
      state.loading = true;
      state.error = "";
    },
    GetPathwaySuccess(state, action) {
      state.loading = false;
      state.PathwayProviders = action.payload;
      state.error = "";
    },
    GetPathwayFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createPathwayRequest(state) {
      state.CreateDirectLoading = true;
      state.error = "";
    },
    createPathwaySuccess(state) {
      state.CreateDirectLoading = false;
      state.error = "";
    },
    createPathwayFail(state, action) {
      state.CreateDirectLoading = false;
      state.error = action.payload;
    },
  },
});

export const UniversitiesActions = UniversitiesSlice.actions;
export default UniversitiesSlice;
