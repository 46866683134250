/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Sources: [],
  error: "",
  loading: false,
};

const SalesSourcesSlice = createSlice({
  name: "SalesSources",
  initialState,
  reducers: {
    GetStudents(state) {
      state.loading = true;
      state.error = "";
    },
    GetStudentsSuccess(state, action) {
      state.loading = false;
      state.Sources = action.payload;
      state.error = "";
    },
    GetStudentsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createcategory(state) {
      state.loading = true;
      state.error = "";
    },
    createcategorySuccess(state, action) {
      state.loading = false;
      state.error = "";
    },
    createcategoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createsource(state) {
      state.loading = true;
      state.error = "";
    },
    createsourceSuccess(state, action) {
      state.loading = false;
      state.error = "";
    },
    createsourceFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const SalesSourcesActions = SalesSourcesSlice.actions;
export default SalesSourcesSlice;
