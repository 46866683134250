import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Radio from "../../../Shared/Components/Radio/Radio";
import Input from "../../../Shared/Components/Input/Input";
import Button from "../../../Shared/Components/Button/Button";
import { changePassword, updateUser } from "../../Redux/Student/StudentActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import "./PrivacyAndSettings.scss";

function PrivacyAndSettings({
  newsLetterSub,
  setNewsLetterSub,
  setEmailSub,
  emailSub,
  changePass,
  setChangePass,
  setSubClicked,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Ready, setReady] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [Ready2, setReady2] = useState(false);

  const [error, setError] = useState("");
  const [newPass, setNewPass] = useState("");
  const [oldPassword, setOldPass] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    if (successMsg != "") {
      const timer = setTimeout(() => {
        setSuccessMsg("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [successMsg]);

  const checkPass = (e) => {
    var pass = e;
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (
      pass.length >= 8 &&
      /\d/.test(pass) &&
      /[A-Z]/.test(pass) &&
      /[a-z]/.test(pass) &&
      format.test(pass)
    ) {
      if (oldPassword != "") setReady(true);
    } else {
      setReady(false);
    }
    if (pass.length >= 8) {
      document.getElementById("8char").classList.add("list_checked");
    } else {
      document.getElementById("8char").classList.remove("list_checked");
    }

    if (/\d/.test(pass)) {
      document.getElementById("cNub").classList.add("list_checked");
    } else {
      document.getElementById("cNub").classList.remove("list_checked");
    }

    if (/[A-Z]/.test(pass)) {
      document.getElementById("cUpc").classList.add("list_checked");
    } else {
      document.getElementById("cUpc").classList.remove("list_checked");
    }

    if (/[a-z]/.test(pass)) {
      document.getElementById("cLpc").classList.add("list_checked");
    } else {
      document.getElementById("cLpc").classList.remove("list_checked");
    }

    if (format.test(pass)) {
      document.getElementById("cSc").classList.add("list_checked");
    } else {
      document.getElementById("cSc").classList.remove("list_checked");
    }

    setNewPass(pass);
  };

  useEffect(() => {
    if (!changePass) {
      if (oldPassword != "") {
        setReady2(true);
      } else {
        setReady2(false);
      }
    } else {
      if (oldPassword != "" && Ready) {
        setReady2(true);
      } else {
        setReady2(false);
      }
    }
  }, [oldPassword, changePass, Ready]);

  const checkPassword = () => {
    if (newPass == "" || newPass.length < 8) {
      setError("Please Fill all the fields!");
    } else {
      dispatch(
        changePassword(
          navigate,
          {
            oldPassword: oldPassword,
            newPassword: newPass,
            confirmPassword: newPass,
          },
          setOldPass,
          setNewPass,
          setSuccessMsg
        )
      );
    }
  };

  const changePassLoading = useSelector(
    (state) => state.StudentReducer.changeLoading
  );
  const changeError = useSelector((state) => state.StudentReducer.changeFail);

  useEffect(() => {
    if (changeError) {
      setError(changeError);
      const timer = setTimeout(() => {
        setError("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [changeError]);

  return (
    <div className="Privacy_and_settings">
      <div className="Privacy_and_settings_inner">
        <div className="Privacy_and_settings_title">Privacy and Settings</div>
        <div className="Privacy_and_settings_body">
          {pathname.includes("student") && (
            <div className="Privacy_and_settings_body_left">
              <div className="Privacy_and_settings_body_left_inner">
                <div className="Privacy_and_settings_body_left_inner_title">
                  Notifications
                </div>
              </div>
              <div className="horizontal_line"></div>
              <div className="Privacy_and_settings_body_left_inner">
                <div className="Privacy_and_settings_body_left_inner_body">
                  <div className="Privacy_and_settings_body_left_inner_body_title">
                    Subscribe to Emails
                    <span
                      onClick={() => {
                        setSubClicked(true);
                        dispatch(
                          updateUser(
                            navigate,
                            {
                              subscribed_to_emails: !emailSub,
                            },
                            setEmailSub,
                            emailSub,
                            true
                          )
                        );
                      }}
                    >
                      <Radio
                        left_text={"false"}
                        right_text={"true"}
                        isVisible={false}
                        radioValue={emailSub}
                        disabled={true}
                      />
                    </span>
                  </div>
                  <div className="Privacy_and_settings_body_left_inner_body_text">
                    Receive email related to your application updates and
                    important information about your account
                  </div>
                </div>
                <div
                  className="Privacy_and_settings_body_left_inner_body"
                  style={{ paddingTop: "2.5rem" }}
                >
                  <div className="Privacy_and_settings_body_left_inner_body_title">
                    Subscribe to Newsletter
                    <span
                      onClick={() => {
                        setSubClicked(true);
                        dispatch(
                          updateUser(
                            navigate,
                            {
                              subscribed_to_newsletter: !newsLetterSub,
                            },
                            setNewsLetterSub,
                            newsLetterSub,
                            true
                          )
                        );
                      }}
                    >
                      <Radio
                        left_text={"false"}
                        right_text={"true"}
                        isVisible={false}
                        radioValue={newsLetterSub}
                        disabled={true}
                      />
                    </span>
                  </div>
                  <div className="Privacy_and_settings_body_left_inner_body_text">
                    Receive tips, advice and personalized recommendations
                    delivered straight to your inbox.
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={
              pathname.includes("student")
                ? "Privacy_and_settings_body_right"
                : "Privacy_and_settings_body_right counselor_password"
            }
          >
            {!changePassLoading ? (
              <>
                <div className="Privacy_and_settings_body_right_inner">
                  <div className="Privacy_and_settings_body_right_inner_title">
                    Change Password
                  </div>
                </div>
                <div className="horizontal_line"></div>
                <div className="Privacy_and_settings_body_right_inner">
                  <div className="Privacy_and_settings_body_right_inner_body">
                    <div className="password_title">Current Password</div>
                    {changePass && (
                      <div className="input_title">Current Password</div>
                    )}
                    <Input
                      type="password"
                      width={"99.5%"}
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPass(e.target.value);
                      }}
                    />
                    {changePass && (
                      <>
                        <div className="input_title">New Password</div>
                        <Input
                          type="password"
                          width={"99.5%"}
                          value={newPass}
                          onChange={(e) => checkPass(e.target.value)}
                          onClick={() => {
                            setShowPopUp(true);
                          }}
                          onBlur={() => {
                            setShowPopUp(false);
                            if (!Ready) {
                              setError("Password must be strong!");
                            } else setError("");
                          }}
                        />
                        <div
                          className="password_popUp"
                          style={{ display: showPopUp ? "block" : "none" }}
                        >
                          <ul className="password_check">
                            <li id="8char">8 Characters </li>
                            <li id="cNub">Contains Number </li>
                            <li id="cLpc">Contains LowerCase </li>
                            <li id="cUpc">Contains Uppercase </li>
                            <li id="cSc">Contains Special Character</li>
                          </ul>
                        </div>
                        {error && <div className="error">{error}</div>}
                        {successMsg && (
                          <div className="success">{successMsg}</div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="change_pass">
                    <Button
                      color="pink"
                      text="Change password"
                      width={"100%"}
                      disabled={Ready2 ? false : true}
                      onClick={() => {
                        if (!changePass) setChangePass(true);
                        else {
                          checkPassword();
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <Loader fullScreen={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyAndSettings;
