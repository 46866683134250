/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  //create application
  createApplicationLoading: false,
  createApplicationError: "",
  createApplicationSuccess: false,
  newApplication: {},

  //get universities' semseters
  getUniversitiesSemestersLoading: false,
  getUniversitiesSemestersError: "",
  getUniversitiesSemestersSuccess: false,
  universitiesSemesters: [],

  //update application
  updateApplicationLoading: false,
  updateApplicationError: "",
  updateApplicationSuccess: false,

  //get app document
  getAppDocumentLoading: false,
  getAppDocumentError: "",
  getAppDocumentSuccess: false,
  appDocument: [],

  //apply using unified application
  applyUsingUnifiedApplicationLoading: false,
  applyUsingUnifiedApplicationError: "",
  applyUsingUnifiedApplicationSuccess: false,
  newUnifiedApplication: {},

  // upload offer
  uploadOfferLoading: false,
  uploadOfferError: "",
  uploadOfferSuccess: false,
};

const CreateApplicationSlice = createSlice({
  name: "Create Application",
  initialState,
  reducers: {
    //create application
    createApplicationRequest(state) {
      state.createApplicationLoading = true;
      state.createApplicationError = "";
      state.createApplicationSuccess = false;
      state.newApplication = "";
    },
    createApplicationSuccess(state, action) {
      state.createApplicationLoading = false;
      state.createApplicationError = "";
      state.createApplicationSuccess = true;
      state.newApplication = action.payload;
    },
    createApplicationFail(state, action) {
      state.createApplicationLoading = false;
      state.createApplicationError = action.payload;
      state.createApplicationSuccess = false;
      state.newApplication = "";
    },

    //update application
    updateApplicationRequest(state) {
      state.updateApplicationLoading = true;
      state.updateApplicationError = "";
      state.updateApplicationSuccess = false;
    },
    updateApplicationSuccess(state, action) {
      state.updateApplicationLoading = false;
      state.updateApplicationError = "";
      state.updateApplicationSuccess = true;
    },
    updateApplicationFail(state, action) {
      state.updateApplicationLoading = false;
      state.updateApplicationError = action.payload;
      state.updateApplicationSuccess = false;
    },

    //get universities' semseters
    getUniversitiesSemestersRequest(state) {
      state.getUniversitiesSemestersLoading = true;
      state.getUniversitiesSemestersError = "";
      state.getUniversitiesSemestersSuccess = false;
      state.universitiesSemesters = [];
    },
    getUniversitiesSemestersSuccess(state, action) {
      state.getUniversitiesSemestersLoading = false;
      state.getUniversitiesSemestersError = "";
      state.getUniversitiesSemestersSuccess = true;
      state.universitiesSemesters = action.payload;
    },
    getUniversitiesSemestersFail(state, action) {
      state.getUniversitiesSemestersLoading = false;
      state.getUniversitiesSemestersError = action.payload;
      state.getUniversitiesSemestersSuccess = false;
      state.universitiesSemesters = [];
    },

    //get app document
    getAppDocumentRequest(state) {
      state.getAppDocumentLoading = true;
      state.getAppDocumentError = "";
      state.getAppDocumentSuccess = false;
      state.appDocument = [];
    },
    getAppDocumentSuccess(state, action) {
      state.getAppDocumentLoading = false;
      state.getAppDocumentError = "";
      state.getAppDocumentSuccess = true;
      state.appDocument = action.payload;
    },

    getAppDocumentFail(state, action) {
      state.getAppDocumentLoading = false;
      state.getAppDocumentError = action.payload;
      state.getAppDocumentSuccess = false;
      state.appDocument = [];
    },

    //apply using unified application
    applyUsingUnifiedApplicationRequest(state) {
      state.applyUsingUnifiedApplicationLoading = true;
      state.applyUsingUnifiedApplicationError = "";
      state.applyUsingUnifiedApplicationSuccess = false;
      state.newUnifiedApplication = {};
    },
    applyUsingUnifiedApplicationSuccess(state, action) {
      state.applyUsingUnifiedApplicationLoading = false;
      state.applyUsingUnifiedApplicationError = "";
      state.applyUsingUnifiedApplicationSuccess = true;
      state.newUnifiedApplication = action.payload;
    },
    applyUsingUnifiedApplicationFail(state, action) {
      state.applyUsingUnifiedApplicationLoading = false;
      state.applyUsingUnifiedApplicationError = action.payload;
      state.applyUsingUnifiedApplicationSuccess = false;
      state.newUnifiedApplication = {};
    },

    // upload offer
    uploadOfferRequest(state) {

      state.uploadOfferLoading = true;
      state.uploadOfferError = "";
      state.uploadOfferSuccess = false;
    },
    uploadOfferSuccess(state, action) {
      state.uploadOfferLoading = false;
      state.uploadOfferError = "";
      state.uploadOfferSuccess = true;
    },
    uploadOfferFail(state, action) {
      state.uploadOfferLoading = false;
      state.uploadOfferError = action.payload;
      state.uploadOfferSuccess = false;
    }
  },
});
export const CreateApplicationAction = CreateApplicationSlice.actions;
export default CreateApplicationSlice;
