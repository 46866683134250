import React, { useState, useRef, useEffect } from "react";
import filter from "../../Assets/Images/filter.png";
import DropDown from "../DropDown/DropDown";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import MultiRangeSlider from "multi-range-slider-react";
import Exit from "../../Assets/Images/Exit.png";
import { ReactComponent as Range_Chart } from "../../Assets/Images/range.svg";
import { ReactComponent as Range_Chart2 } from "../../Assets/Images/range_tablet.svg";
import { getCounselorStudents } from "../../../Counsler/Redux/CounselorStudent/CounselorStudentActions";
import "./Filters.scss";
import {
  DropDownList,
  axiosReq,
  languages,
  languagesList,
} from "../../../Utils";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCont,
  getCityByContId,
} from "../../../Shared/Redux/Location/LocationsActions";
import Loader from "../Loader/Loader";
import {
  getAllUnis,
  getMajotCAtg,
} from "../../Redux/Universities/UniversityActions";
import { getAllCounselorApps } from "../../../Counsler/Redux/CounselorApplication/CounselorApplicationActions";
import { getFiltersDegrees } from "../../../Shared/Redux/Filters/FiltersActions";
import { getLeads } from "../../../Sales/Redux/Leads/LeadsActions";
import {
  GetSales,
  getstudents,
} from "../../../Sales/Redux/Students/StudentsActions";

function Filters({
  setOpened,
  application,
  type,
  data,
  setData,
  sales,
  student,
}) {
  const dispatch = useDispatch();

  const [allCountries, setAllCountries] = useState([]);
  const [contLoading, setContLoading] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [allMajors, setAllMajors] = useState([]);

  const minVal = 0;
  const maxVal = 16000;

  const scWidth = UseInnerWidth();

  const ColorSvg = (minVal, maxVal) => {
    if (minVal > 0 && minVal >= 1000) {
      document.getElementById("rec1").style.fill = "#00000085";
    } else {
      document.getElementById("rec1").style.fill = "#000000";
    }
    if (minVal >= 2000 || maxVal < 2000) {
      document.getElementById("rec2").style.fill = "#00000085";
    } else {
      document.getElementById("rec2").style.fill = "#000000";
    }
    if (minVal >= 3000 || maxVal < 3000) {
      document.getElementById("rec3").style.fill = "#00000085";
    } else {
      document.getElementById("rec3").style.fill = "#000000";
    }
    if (minVal >= 4000 || maxVal < 4000) {
      document.getElementById("rec4").style.fill = "#00000085";
    } else {
      document.getElementById("rec4").style.fill = "#000000";
    }
    if (minVal >= 5000 || maxVal < 5000) {
      document.getElementById("rec5").style.fill = "#00000085";
    } else {
      document.getElementById("rec5").style.fill = "#000000";
    }
    if (minVal >= 6000 || maxVal < 6000) {
      document.getElementById("rec6").style.fill = "#00000085";
    } else {
      document.getElementById("rec6").style.fill = "#000000";
    }
    if (minVal >= 7000 || maxVal < 7000) {
      document.getElementById("rec7").style.fill = "#00000085";
    } else {
      document.getElementById("rec7").style.fill = "#000000";
    }
    if (minVal >= 8000 || maxVal < 8000) {
      document.getElementById("rec8").style.fill = "#00000085";
    } else {
      document.getElementById("rec8").style.fill = "#000000";
    }
    if (minVal >= 9000 || maxVal < 9000) {
      document.getElementById("rec9").style.fill = "#00000085";
    } else {
      document.getElementById("rec9").style.fill = "#000000";
    }
    if (minVal >= 10000 || maxVal < 10000) {
      document.getElementById("rec10").style.fill = "#00000085";
    } else {
      document.getElementById("rec10").style.fill = "#000000";
    }
    if (minVal >= 11000 || maxVal < 11000) {
      document.getElementById("rec11").style.fill = "#00000085";
    } else {
      document.getElementById("rec11").style.fill = "#000000";
    }
    if (minVal >= 12000 || maxVal < 12000) {
      document.getElementById("rec12").style.fill = "#00000085";
    } else {
      document.getElementById("rec12").style.fill = "#000000";
    }
    if (minVal >= 13000 || maxVal < 13000) {
      document.getElementById("rec13").style.fill = "#00000085";
    } else {
      document.getElementById("rec13").style.fill = "#000000";
    }
    if (minVal >= 14000 || maxVal < 14000) {
      document.getElementById("rec14").style.fill = "#00000085";
    } else {
      document.getElementById("rec14").style.fill = "#000000";
    }
    if (maxVal < 15000) {
      document.getElementById("rec15").style.fill = "#00000085";
    } else {
      document.getElementById("rec15").style.fill = "#000000";
    }
    if (maxVal > 15000) {
      document.getElementsByClassName("max-caption")[0].innerHTML = "15000+";
    }
  };

  const handleInput = (e) => {
    setData({ ...data, tuitionLow: e.minValue, tuitionHigh: e.maxValue });
    ColorSvg(e.minValue, e.maxValue);
  };
  const { Countries, getCountriesLoading, Cities, getCitiesLoading } =
    useSelector((state) => state.LocationReducer);

  const { Sales, getSalesError, getSalesLoading } = useSelector(
    (state) => state.StudentsSaleReducer
  );

  const { MajorCatg, getMajorCatgLoading } = useSelector(
    (state) => state.UniversitiesReducer
  );
  const { FiltersDegrees } = useSelector((state) => state.FiltersReducer);

  useEffect(() => {
    dispatch(getAllCont());
    dispatch(getMajotCAtg());
    dispatch(getFiltersDegrees());
    dispatch(GetSales());
  }, []);

  useEffect(() => {
    setAllCountries(DropDownList(Countries, "id", "name"));
  }, [Countries]);

  useEffect(() => {
    setContLoading(getCountriesLoading);
  }, [getCountriesLoading]);

  useEffect(() => {
    if (data?.countries) {
      setAllCities([]);
      setData({ ...data, city: "" });
      dispatch(getCityByContId(data?.countries[0]?.value));
    }
  }, [data?.countries]);

  useEffect(() => {
    setAllCities(DropDownList(Cities, "id", "name"));
  }, [Cities]);

  useEffect(() => {
    setAllMajors(DropDownList(MajorCatg, "id", "name"));
  }, [MajorCatg]);

  useEffect(() => {
    if (data?.tuitionHigh && (data?.tuitionLow || data?.tuitionLow == 0)) {
      setTimeout(() => {
        ColorSvg(data.tuitionLow, data.tuitionHigh);
      }, 150);
    }
  }, [data.tuitionHigh, data.tuitionLow]);
  const sortData = [
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
  ];
  const statusData = [
    { label: "New", value: "New" },
    { label: "applied", value: "Applied" },
  ];

  const educationLevels = [
    { label: "Grade 9", value: "Grade 9" },
    { label: "Grade 10", value: "Grade 10" },
    { label: "Grade 11", value: "Grade 11" },
    { label: "Grade 12", value: "Grade 12" },
    { label: "Grade 13", value: "Grade 13" },
    { label: "Foundation/pre-university", value: "Foundation/pre-university" },
    { label: "Bachelor's Year 1", value: "Bachelor's Year 1" },
    { label: "Freshman", value: "Freshman" },
    { label: "Bachelor's Year 2", value: "Bachelor's Year 2" },
    { label: "Sophomore", value: "Sophomore" },
    { label: "Bachelor's Year 3", value: "Bachelor's Year 3" },
    { label: "Junior", value: "Junior" },
    { label: "Bachelor's Year 4", value: "Bachelor's Year 4" },
    { label: "Senior", value: "Senior" },
    { label: "Bachelor's Year 5+", value: "Bachelor's Year 5+" },
    { label: "Pre-master's", value: "Pre-master's" },
    { label: "Master's Year 1", value: "Master's Year 1" },
    { label: "Master's Year 2", value: "Master's Year 2" },
    { label: "PhD", value: "PhD" },
  ];

  const leadsStatus = [
    {
      value: "New",
      label: "New",
    },
    {
      value: "Qualified",
      label: "Qualified",
    },
    {
      value: "Unqualified",
      label: "Unqualified",
    },
    {
      value: "Contacted",
      label: "Contacted",
    },
    {
      value: "Archived",
      label: "Archived",
    },
    {
      value: "Booked",
      label: "Booked",
    },
  ];

  const languages = languagesList;
  const handleSubmit = () => {
    if (type == "university") {
      let filter = {};
      if (data?.countries) {
        filter.countries = data?.countries
          ?.map((country) => country.label)
          .join(",");
      }
      if (data?.major) {
        filter.major = data?.major?.label;
      }
      if (data?.language) {
        filter.language = data?.language?.label;
      }
      if (data?.city) {
        filter.city = data?.city?.label;
      }
      if (data?.degree) {
        filter.degree = data?.degree;
      }
      if (data.tuitionLow || data.tuitionLow == 0) {
        filter.tuitionLow = data.tuitionLow;
      }
      if (data.tuitionHigh) {
        filter.tuitionHigh = data.tuitionHigh;
      }

      dispatch(getAllUnis(9, 0, "", filter));
      // clearAll();
      setOpened(false);
    } else if (type == "student") {
      dispatch(
        getCounselorStudents(
          8,
          0,
          data?.countries?.map((country) => country.label).join(","),
          data?.status?.label,
          data?.major?.label,
          data?.language?.label,
          data?.sort?.value
        )
      );
      {
        application &&
          dispatch(
            getAllCounselorApps(
              0,
              8,
              data?.countries?.map((country) => country.label).join(","),
              data?.status?.label,
              data?.major?.label,
              data?.language?.label,
              data?.sort?.value
            )
          );
      }
      // clearAll();
      setOpened(false);
    }
    if (sales) {
      dispatch(
        getLeads(
          data?.status?.map((el) => el.label).join(","),
          "",
          10,
          0,
          "",
          data
        )
      );
      setOpened(false);
    } else if (student) {
      dispatch(getstudents("", 10, 0, "", data));
      setOpened(false);
    }
  };
  const clearAll = () => {
    setData({});
    setAllCities([]);
    setOpened(false);
    if (type == "university") {
      setData({
        tuitionHigh: 16000,
        tuitionLow: 0,
      });
      dispatch(getAllUnis(9, 0, ""));
    } else if ((type = "student")) {
      dispatch(getCounselorStudents(8, 0));

      if (application) dispatch(getAllCounselorApps(0, 8));
    }

    if (sales) {
      dispatch(getLeads("", "", 10, 0, ""));
    }
    if (student) {
      dispatch(getstudents("", 10, 0, ""));
    }
  };
  const budgets = [
    { label: "0 to 5.000$", value: [0, 5000] },
    { label: "5.000 to 10.000$", value: [5000, 10000] },
    { label: "10.000 to 15.000$", value: [10000, 15000] },
    { label: "15.000$ +", value: [15000] },
  ];

  return (
    <>
      <div className="Main_Div">
        <div className="Filter_Title">
          <div className="Filter_Title_Right">
            <img className="FilterImg" src={filter} alt="Filter" />
            <h1>Add Filter</h1>
          </div>
          <div className="Filter_Title_div_head">
            <Button
              className="filter_button"
              text="Clear Filters"
              color="pink"
              width="20%"
              onClick={() => clearAll()}
            />
            <img
              className="ExitImg"
              src={Exit}
              alt="Exit"
              onClick={() => {
                setOpened(false);
              }}
            />
          </div>
        </div>
        {!sales && !student && (
          <div className="Main_Filters_Div">
            {contLoading || getMajorCatgLoading ? (
              <Loader fullScreen={true} />
            ) : (
              <>
                <div
                  className="Filters_Left_Div"
                  style={{ width: type != "university" && "100%" }}
                >
                  <div className="All_DropDowns">
                    <div className="Two_DropDowns">
                      <div className="Single_DropDown">
                        {allCountries.length > 0 && !contLoading && (
                          <DropDown
                            title={
                              type === "university"
                                ? "Country"
                                : "Country of Residence"
                            }
                            placeholder="Select Country"
                            width={
                              type == "university"
                                ? scWidth <= 1170 && scWidth > 910
                                  ? "25rem"
                                  : scWidth <= 910
                                  ? "100%"
                                  : scWidth >= 1500
                                  ? "30rem"
                                  : "17rem"
                                : "100%"
                            }
                            setSelected={(e) => {
                              setData({
                                ...data,
                                countries: e,
                              });
                            }}
                            selectedData={data?.countries}
                            value={data?.countries}
                            blueArrow={true}
                            data={allCountries}
                            // multiSelection={true}
                            isMulti={true}
                          />
                        )}
                      </div>
                      <div className="Single_DropDown">
                        {type == "university" ? (
                          <>
                            {allCities.length > 0 &&
                              !getCitiesLoading &&
                              data?.countries?.length == 1 && (
                                <DropDown
                                  title="City"
                                  placeholder="Select City"
                                  width={
                                    type == "university"
                                      ? scWidth <= 1170 && scWidth > 910
                                        ? "25rem"
                                        : scWidth <= 910
                                        ? "100%"
                                        : scWidth >= 1500
                                        ? "30rem"
                                        : "17rem"
                                      : "100%"
                                  }
                                  blueArrow={true}
                                  data={allCities}
                                  selectedData={data?.city}
                                  setSelected={(e) => {
                                    setData({
                                      ...data,
                                      city: e,
                                    });
                                  }}
                                  value={data?.city}
                                />
                              )}
                            {(!allCities ||
                              allCities.length == 0 ||
                              getCitiesLoading ||
                              data?.countries?.length != 1) && (
                              <DropDown
                                title="City"
                                placeholder="Select City"
                                width={
                                  type == "university"
                                    ? scWidth <= 1170 && scWidth > 910
                                      ? "25rem"
                                      : scWidth <= 910
                                      ? "100%"
                                      : scWidth >= 1500
                                      ? "30rem"
                                      : "17rem"
                                    : "100%"
                                }
                                blueArrow={true}
                                data={[]}
                                isDisabled={true}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {application != true && (
                              <DropDown
                                title={type === "student" && "Student Status"}
                                placeholder="Select Status"
                                width={
                                  type == "university"
                                    ? scWidth <= 1170 && scWidth > 910
                                      ? "25rem"
                                      : scWidth <= 910
                                      ? "100%"
                                      : scWidth >= 1500
                                      ? "30rem"
                                      : "17rem"
                                    : "100%"
                                }
                                blueArrow={true}
                                value={data?.status}
                                selectedData={data?.status}
                                setSelected={(e) => {
                                  setData({
                                    ...data,
                                    status: e,
                                  });
                                }}
                                data={type === "student" && statusData}
                              />
                            )}
                            {application == true && (
                              <div className="Single_DropDown">
                                <DropDown
                                  title="Languages"
                                  placeholder="Select Language"
                                  width={
                                    type == "university"
                                      ? scWidth <= 1170 && scWidth > 910
                                        ? "25rem"
                                        : scWidth <= 910
                                        ? "100%"
                                        : scWidth >= 1500
                                        ? "30rem"
                                        : "17rem"
                                      : "100%"
                                  }
                                  blueArrow={true}
                                  data={languages}
                                  setSelected={(e) =>
                                    setData({
                                      ...data,
                                      language: e,
                                    })
                                  }
                                  selectedData={data?.language}
                                  value={data?.language}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="Two_DropDowns">
                      <div className="Single_DropDown">
                        {allMajors.length > 0 && !getMajorCatgLoading && (
                          <DropDown
                            title={"Major"}
                            placeholder="Select Major"
                            width={
                              type == "university"
                                ? scWidth <= 1170 && scWidth > 910
                                  ? "25rem"
                                  : scWidth <= 910
                                  ? "100%"
                                  : scWidth >= 1500
                                  ? "30rem"
                                  : "17rem"
                                : "100%"
                            }
                            blueArrow={true}
                            selectedData={data?.major}
                            setSelected={(e) => {
                              setData({
                                ...data,
                                major: e,
                              });
                            }}
                            value={data?.major}
                            data={allMajors}
                          />
                        )}
                      </div>
                      {application == false && (
                        <div className="Single_DropDown">
                          <DropDown
                            title="Languages"
                            placeholder="Select Language"
                            width={
                              type == "university"
                                ? scWidth <= 1170 && scWidth > 910
                                  ? "25rem"
                                  : scWidth <= 910
                                  ? "100%"
                                  : scWidth >= 1500
                                  ? "30rem"
                                  : "17rem"
                                : "100%"
                            }
                            blueArrow={true}
                            data={languages}
                            setSelected={(e) =>
                              setData({
                                ...data,
                                language: e,
                              })
                            }
                            selectedData={data?.language}
                            value={data?.language}
                          />
                        </div>
                      )}
                    </div>
                    {type != "university" && (
                      <div className="Two_DropDowns">
                        <div className="Single_DropDown">
                          <DropDown
                            placeholder="Select Order"
                            title="Sort By"
                            width={
                              type == "university"
                                ? scWidth <= 1170 && scWidth > 910
                                  ? "25rem"
                                  : scWidth <= 910
                                  ? "100%"
                                  : scWidth >= 1500
                                  ? "30rem"
                                  : "17rem"
                                : "100%"
                            }
                            blueArrow={true}
                            setSelected={(e) =>
                              setData({
                                ...data,
                                sort: e,
                              })
                            }
                            selectedData={data?.sort}
                            data={sortData}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {type === "university" && (
                  <>
                    <div className="RangeInput_Div">
                      <div className="Filter_Label">Yearly Tuition</div>
                      <div className="noLabel">
                        {scWidth > 700 ? <Range_Chart2 /> : <Range_Chart />}
                        <div className="range_input">
                          <MultiRangeSlider
                            min={minVal}
                            max={maxVal}
                            stepOnly={true}
                            step={1000}
                            minValue={data.tuitionLow}
                            maxValue={data.tuitionHigh}
                            ruler={false}
                            baseClassName={"multi-range-slider-black"}
                            label={false}
                            preventWheel={false}
                            onInput={(e) => {
                              handleInput(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Filter_Checkbox_Div">
                      <div className="Filter_Label">Degrees</div>
                      <div className="Filter_Checkbox_subDiv">
                        {FiltersDegrees?.map((degree, key) => {
                          return (
                            <div className="Filter_Checkbox" key={key}>
                              <Checkbox
                                click={true}
                                onClick={() => {
                                  if (
                                    data?.degree &&
                                    data?.degree.includes(degree)
                                  ) {
                                    const updatedDegree = data.degree
                                      .split(",")
                                      .filter((item) => item !== degree)
                                      .join(",");
                                    setData({
                                      ...data,
                                      degree: updatedDegree,
                                    });
                                  } else {
                                    const updatedDegree = data?.degree
                                      ? `${data.degree},${degree}`
                                      : degree;
                                    setData({
                                      ...data,
                                      degree: updatedDegree,
                                    });
                                  }
                                }}
                                selected={
                                  data?.degree && data?.degree.includes(degree)
                                }
                                label={degree}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {(sales || student) && (
          <div className="Main_Filters_Div">
            {getSalesLoading ? (
              <Loader fullScreen={true} />
            ) : (
              <>
                <div className="Single_DropDown" style={{ width: "30%" }}>
                  {allCountries.length > 0 && !contLoading && (
                    <DropDown
                      title={"Country of Residence"}
                      placeholder="Select Country"
                      width={"100%"}
                      setSelected={(e) => {
                        setData({
                          ...data,
                          countries: e,
                        });
                      }}
                      selectedData={data?.countries}
                      value={data?.countries}
                      blueArrow={true}
                      data={allCountries}
                      // multiSelection={true}
                      isMulti={true}
                    />
                  )}
                </div>

                <div className="Single_DropDown" style={{ width: "30%" }}>
                  <DropDown
                    title={"Level of Education"}
                    placeholder="Select Education Level"
                    width={"100%"}
                    setSelected={(e) => {
                      setData({
                        ...data,
                        education: e,
                      });
                    }}
                    selectedData={data?.education}
                    value={data?.education}
                    blueArrow={true}
                    data={educationLevels}
                    // multiSelection={true}
                    isMulti={true}
                  />
                </div>
                {sales && (
                  <div className="Single_DropDown" style={{ width: "30%" }}>
                    <DropDown
                      title={"Leads' Status"}
                      placeholder="Select Status"
                      width={"100%"}
                      setSelected={(e) => {
                        setData({
                          ...data,
                          status: e,
                        });
                      }}
                      selectedData={data?.status}
                      value={data?.status}
                      blueArrow={true}
                      data={leadsStatus}
                      // multiSelection={true}
                      isMulti={true}
                    />
                  </div>
                )}
                {sales && (
                  <div className="Single_DropDown" style={{ width: "30%" }}>
                    <DropDown
                      title="Assigned To"
                      placeholder="Select Sales"
                      width={"100%"}
                      setSelected={(e) => {
                        setData({
                          ...data,
                          assigned: e,
                        });
                      }}
                      selectedData={data?.assigned}
                      value={data?.assigned}
                      blueArrow={true}
                      data={Sales?.map((el) => {
                        return {
                          label: el.first_name + " " + el.last_name,
                          value: el.id,
                        };
                      })}
                      // multiSelection={true}
                      isMulti={true}
                    />
                  </div>
                )}
                {sales && (
                  <div className="Single_DropDown" style={{ width: "30%" }}>
                    <DropDown
                      title="Contacted By"
                      placeholder="Select Sales"
                      width={"100%"}
                      setSelected={(e) => {
                        setData({
                          ...data,
                          contacted: e,
                        });
                      }}
                      selectedData={data?.contacted}
                      value={data?.contacted}
                      blueArrow={true}
                      data={Sales?.map((el) => {
                        return {
                          label: el.first_name + " " + el.last_name,
                          value: el.id,
                        };
                      })}
                      // multiSelection={true}
                      isMulti={true}
                    />
                  </div>
                )}
                <div className="Single_DropDown" style={{ width: "30%" }}>
                  <DropDown
                    title="Budget"
                    placeholder="Select Budget"
                    width={"100%"}
                    setSelected={(e) => {
                      setData({
                        ...data,
                        budget: e,
                      });
                    }}
                    selectedData={data?.budget}
                    value={data?.budget}
                    blueArrow={true}
                    data={budgets}
                    // multiSelection={true}
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className="Filter_Button_Div">
          <Button
            className="filter_button"
            text="Apply Changes"
            color="yellow"
            width="100%"
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
    </>
  );
}

export default Filters;
