import React from "react";
import "./SignUp.scss";
import OBStep1 from "../../../Shared/Components/OnBoarding/OBStep1/OBStep1";
function SignUp() {
  return (
    <div className="signUp_main_div">
      <OBStep1 />
    </div>
  );
}

export default SignUp;
