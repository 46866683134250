const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Countries: [],
  getCountriesLoading: false,
  getCountriesSuccess: false,
  getCountriesError: "",

  Cities: [],
  Cities2: [],
  getCitiesLoading: false,
  getCitiesSuccess: false,
  getCitiesError: "",
};

const LocationsSlice = createSlice({
  name: "Locations",
  initialState,
  reducers: {
    getCountriesRequest(state) {
      state.Countries = [];
      state.getCountriesSuccess = false;
      state.getCountriesError = "";
      state.getCountriesLoading = true;
    },
    getCountriesSuccess(state, action) {
      state.Countries = action.payload;
      state.getCountriesSuccess = true;
      state.getCountriesError = "";
      state.getCountriesLoading = false;
    },
    getCountriesError(state, action) {
      state.Countries = [];
      state.getCountriesSuccess = false;
      state.getCountriesError = action.payload;
      state.getCountriesLoading = false;
    },
    getCityByCountryIdRequest(state) {
      state.Cities = [];
      state.getCitiesSuccess = false;
      state.getCitiesError = "";
      state.getCitiesLoading = true;
    },
    getCityByCountryIdSuccess(state, action) {
      state.Cities = action.payload;
      state.getCitiesSuccess = true;
      state.getCitiesError = "";
      state.getCitiesLoading = false;
    },
    getCityByCountryIdError(state, action) {
      state.Cities = [];
      state.getCitiesSuccess = false;
      state.getCitiesError = action.payload;
      state.getCitiesLoading = false;
    },

    getCity2ByCountryIdRequest(state) {
      state.Cities2 = [];
      state.getCitiesSuccess = false;
      state.getCitiesError = "";
      state.getCitiesLoading = true;
    },
    getCity2ByCountryIdSuccess(state, action) {
      state.Cities2 = action.payload;
      state.getCitiesSuccess = true;
      state.getCitiesError = "";
      state.getCitiesLoading = false;
    },
    getCit2yByCountryIdError(state, action) {
      state.Cities2 = [];
      state.getCitiesSuccess = false;
      state.getCitiesError = action.payload;
      state.getCitiesLoading = false;
    },
  },
});

export const LocationsActions = LocationsSlice.actions;
export default LocationsSlice;
