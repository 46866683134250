import React, { useEffect, useRef, useState } from "react";
import "./SingleApplication.scss";
import { useNavigate, useParams } from "react-router-dom";
import attachment from "../../../Shared/Assets/Images/attachment.png";
import Button from "../../../Shared/Components/Button/Button";
import Popup from "../../../Shared/Components/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleApp,
  getSingleAppMissingDocs,
  cancelApplication,
  uploadAppMissingDocuments,
  uploadAppMissingDocumentsFromWebsite,
  makeAppUnified,
  getUnifiedApplication,
} from "../../../Shared/Redux/Application/ApplicationAction";
import {
  IdSplit,
  dateConv,
  handleDownload,
  handlePreview,
} from "../../../Utils";
import Loader from "../../../Shared/Components/Loader/Loader";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import UploadDoc from "../../../Shared/Components/UploadDoc/UploadDoc";
import download from "../../../Shared/Assets/Images/download.png";
import pen from "../../../Shared/Assets/Images/edit.png";

function SingleApplication() {
  const { id } = useParams();
  const scWidth = UseInnerWidth();
  const [daysDiff, setDaysDiff] = useState(0);
  const [appDonePercentage, setAppDonePercentage] = useState();
  const [openUploadDocuments, setopenUploadDocuments] = useState(false);
  const [chosenDoc, setChosenDoc] = useState({});
  const [uploadedDocument, setUploadedDocument] = useState({});
  const [docName, setDocName] = useState("");
  const [showPop, setShowPop] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMake, setShowMake] = useState(true);
  const {
    unifiedApplication,
    singleApplication,
    singleApplicationMissingDoc,
    getSingleAppLoading,
    singleApplicationMissingDocsLoading,
    unifiedApplicationLoading,
    makeThisApplicationUnifiedLoading,
  } = useSelector((store) => store.ApplicationsReducer);
  const { student } = useSelector((store) => store.StudentReducer);

  useEffect(() => {
    dispatch(getSingleApp(id));
    dispatch(getSingleAppMissingDocs(id));
    dispatch(getUnifiedApplication());
  }, [id]);
  useEffect(() => {
    if (getSingleAppLoading || unifiedApplicationLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getSingleAppLoading, unifiedApplicationLoading]);
  useEffect(() => {
    if (
      singleApplicationMissingDocsLoading ||
      makeThisApplicationUnifiedLoading
    ) {
      setLoading2(true);
    } else {
      setLoading2(false);
    }
  }, [singleApplicationMissingDocsLoading, makeThisApplicationUnifiedLoading]);

  useEffect(() => {
    if (unifiedApplication && unifiedApplication.applicationId && id) {
      if (unifiedApplication.applicationId == id) {
        setShowMake(false);
      } else if (unifiedApplication.applicationId != id) {
        setShowMake(true);
      }
    }
  }, [unifiedApplication, id]);

  useEffect(() => {
    if (singleApplication) {
      const currentDate = new Date();
      const newDate = new Date(singleApplication?.createdAt);
      const timeDiff = currentDate.getTime() - newDate.getTime();
      const dayss = Math.round(timeDiff / (1000 * 3600 * 24));
      if (dayss === 0) {
        setDaysDiff("Today");
      } else if (dayss === 1) {
        setDaysDiff("Yesterday");
      } else if (dayss < 7) {
        setDaysDiff(dayss + " days ago");
      } else if (dayss === 7) {
        setDaysDiff("1 week ago");
      } else {
        const datee = dateConv(newDate);
        setDaysDiff(datee);
      }
    }
  }, [singleApplication]);

  useEffect(() => {
    if (
      singleApplication &&
      singleApplication.documents &&
      singleApplicationMissingDoc
    ) {
      const arraysTotalLength =
        singleApplication.documents.length + singleApplicationMissingDoc.length;
      setAppDonePercentage(
        Math.round(
          (singleApplication?.documents?.length * 100) / arraysTotalLength
        )
      );
    } else setAppDonePercentage(0);
  }, [singleApplication, singleApplicationMissingDoc]);
  const docRef = useRef(null);
  const handleButtonClick = () => {
    docRef.current.click();
  };

  return (
    <div className="OneApplication_Container">
      {(Loading || loading2) && <Loader fullScreen={true} />}
      {openUploadDocuments && (
        <UploadDoc
          setShow={setopenUploadDocuments}
          title={"Upload Document"}
          showRadio={true}
          uploadedDoc={uploadedDocument}
          setUploadedDoc={setUploadedDocument}
          setChosenDoc={setChosenDoc}
          chosenDoc={chosenDoc}
          onSubmit={() => {
            {
              uploadedDocument[docName]
                ? dispatch(
                    uploadAppMissingDocuments(
                      id,
                      docName,
                      uploadedDocument[docName],
                      student.id
                    )
                  )
                : dispatch(
                    uploadAppMissingDocumentsFromWebsite(
                      id,
                      chosenDoc[docName].value,
                      docName,
                      student.id
                    )
                  );
            }
          }}
          docName={docName}
          setDocName={setDocName}
        />
      )}

      {showPop && (
        <Popup
          body={
            <div className="delete_Confirm_Popup">
              <div className="delete_Confrim_Title">
                Are you sure you want to cancel this application?
              </div>
              <div className="delete_Confrim_Body">
                <Button
                  text={"Yes"}
                  color={"yellow"}
                  onClick={() => {
                    dispatch(cancelApplication(id, student.id));

                    navigate("/student/my_applications");
                  }}
                />
                <Button
                  text={"No"}
                  color={"pink"}
                  onClick={() => {
                    setShowPop(false);
                  }}
                />
              </div>
            </div>
          }
          setShowPopup={setShowPop}
          width={"30rem"}
        />
      )}
      <div className="OneApplication_InnerContainer">
        <div className="OneApplication_TitleDiv">
          <div className="OneApplication_Title">Your Application</div>
          {singleApplication.status === "Draft" ? (
            <Button
              text="Continue my application"
              className="OneApplication_Number_continue"
              color="blue"
              onClick={() => {
                localStorage.removeItem("applicationId");
                localStorage.setItem("applicationId", singleApplication.id);
                navigate(
                  "/student/my_applications/apply/" +
                    singleApplication.application_step
                );
              }}
            />
          ) : (
            singleApplication.status != "Draft" &&
            singleApplication.status != "Missing Documents" &&
            singleApplication.status != "In Review" &&
            singleApplication.status != "Canceled" &&
            singleApplication.status != "Rejected" &&
            showMake && (
              <Button
                className="OneApplication_Number_continue"
                color="lightGreen"
                onClick={() => {
                  dispatch(makeAppUnified(singleApplication.id));
                }}
                text="Make it my Unified Application!"
              />
            )
          )}
        </div>
        <div className="OneAppllication_NumberStatus">
          <div className="OneApplication_Number">
            <div className="OneApplication_Number_text">
              Application {IdSplit(singleApplication?.id)}
              <div
                style={{ userSelect: "none" }}
                className="OneApplication_Number_semester"
              >
                {singleApplication?.semester?.name +
                  " " +
                  singleApplication?.semester?.start_date.split("-")[0]}
              </div>
            </div>
            {!showMake && (
              <span
                style={{ userSelect: "none" }}
                className="OneApplication_Number_unified"
              >
                This is your Unified Application
              </span>
            )}{" "}
            <br />
          </div>
          <div className="status_time_dvi">
            {singleApplication?.offer?.originalName ? (
              <div
                id="offer_button"
                className="OneApplication_Status oofer_buttonn"
                style={{ marginBottom: "0.5rem" }}
              >
                <Button
                  className="OneApplication_Number_continue "
                  color="yellow"
                  onClick={() => {
                    handlePreview(
                      `students/${student?.id}/${id}/${singleApplication?.offer?.path}`,
                      singleApplication?.offer?.originalName
                    );
                    document
                      .getElementById("offer_button")
                      .classList.remove("oofer_buttonn");
                  }}
                  text="Check Received Offer!"
                />
              </div>
            ) : (
              <div
                className={
                  singleApplication.status === "Draft" ||
                  singleApplication.status === "Ready To Be Sent"
                    ? "OneApplication_Status draft"
                    : singleApplication.status === "Submitted" ||
                      singleApplication.status === "Accepted" ||
                      singleApplication.status === "Applying For Visa"
                    ? "OneApplication_Status submitted"
                    : singleApplication.status === "Rejected" ||
                      singleApplication.status === "Canceled" ||
                      singleApplication.status === "Missing Documents"
                    ? "OneApplication_Status rejected"
                    : "OneApplication_Status"
                }
              >
                {singleApplication.status == "Accepted"
                  ? "Confirmed"
                  : singleApplication.status}
              </div>
            )}
            <div className="OneApplication_Date">{daysDiff}</div>
          </div>
        </div>

        <div className="OneApplicationUpperTableCont">
          <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftDiv ">
            <div className="OneApplicationYableSecTitle">The University</div>
            <div className="OneApplicationUniLogoName">
              <div>
                <img
                  alt="uniLogo"
                  className="OneApplicationUniLogo"
                  src={
                    process.env.REACT_APP_API +
                    `/files/universities/${singleApplication?.major_degree?.major.faculty?.university.logo}`
                  }
                />
              </div>
              <div className="OneAppUpperTableName">
                <p className="OneAppUniName">
                  {
                    singleApplication?.major_degree?.major.faculty?.university
                      .name
                  }
                </p>
                <p className="OneAppUniLocation">
                  {
                    singleApplication?.major_degree?.major?.faculty?.university
                      ?.city
                  }
                  ,
                  {
                    singleApplication?.major_degree?.major?.faculty?.university
                      ?.country
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftMiddleRight">
            <div className="OneApplicationYableSecTitle">Faculty</div>
            <div className="OneAppUniName">
              {singleApplication?.major_degree?.major?.faculty?.name}
            </div>
          </div>
          <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftMiddleRight">
            <div className="OneApplicationYableSecTitle">Major</div>
            <div className="OneAppUniName">
              {singleApplication?.major_degree?.major?.name}
            </div>
          </div>
          <div className="OneApplicationUpperTableInner OneApplicationUppertableInnerLeftMiddleRight">
            <div className="OneApplicationYableSecTitle">Yearly Tuition</div>
            <div className="OneAppUniName">
              ${singleApplication?.major_degree?.tuition}
            </div>
          </div>
        </div>
        <div className="OneApplicationLowerTableCont">
          <div className="OneAppLowerTableHeader">
            <p className="OneAppLowerTableHeaderP">Documents</p>
            <p className="OneAppLowerTableHeaderP">
              {appDonePercentage} % {scWidth > 450 && "Uploaded"}
            </p>
          </div>
          <div className="OneAppLowerTableDescAll">
            <div className="OneAppLowerTableDescriptions">
              <p className="OneAppLowerTableDescP">Document</p>
              <p className="OneAppLowerTableDescP">Due Date</p>
              <p className="OneAppLowerTableDescP">Submitted</p>
              <p className="OneAppLowerTableDescP doc_images">Actions</p>
            </div>
            {singleApplication?.documents?.map((document) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  className="OneAppLowerTableDescriptions"
                  onClick={() =>
                    handlePreview(
                      `students/${singleApplication.student.id}/${singleApplication.id}/${document.path}`,
                      document.originalName
                    )
                  }
                >
                  <p className="OneAppLowerTableDescP OneAppDocs">
                    {document.name}
                  </p>
                  <p className="OneAppLowerTableDescP OneAppDocs">None</p>
                  {scWidth > 450 && (
                    <p className="OneAppLowerTableDescP OneAppDocs">
                      {dateConv(document.submitted)}
                    </p>
                  )}

                  <div className="OneAppLowerTableDescP OneAppDocs doc_images">
                    <img
                      src={download}
                      alt="download"
                      className="documents_uni_icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(
                          `students/${singleApplication.student.id}/${singleApplication.id}/${document.path}`,
                          document.originalName
                        );
                      }}
                    />

                    {(singleApplication.status === "In Review" ||
                      singleApplication.status === "Draft" ||
                      singleApplication.status === "Missing Documents") && (
                      <img
                        className="documents_uni_icon"
                        src={pen}
                        onClick={(e) => {
                          e.stopPropagation();
                          setopenUploadDocuments(true);
                          setDocName(document.name);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
            {singleApplicationMissingDoc?.map((missingDoc, index) => {
              return (
                <div className="OneAppLowerTableDescriptions" key={index}>
                  <p className="OneAppLowerTableDescP OneAppDocs">
                    {missingDoc.name ? missingDoc.name : missingDoc}
                  </p>
                  <p className="OneAppLowerTableDescP OneAppDocs">
                    {singleApplication.status === "In Review" ||
                    singleApplication.status === "Draft" ||
                    singleApplication.status === "Missing Documents"
                      ? missingDoc?.dueDate
                        ? dateConv(missingDoc.dueDate)
                        : "None"
                      : "None"}
                  </p>
                  {scWidth > 450 && (
                    <p className="OneAppLowerTableDescP OneAppDocs">
                      {singleApplication.status === "In Review" ||
                      singleApplication.status === "Draft" ||
                      singleApplication.status === "Missing Documents"
                        ? "Pending"
                        : "Not Submitted"}
                    </p>
                  )}
                  <div className="OneAppLowerTableDescP doc_images">
                    {singleApplication.status === "In Review" ||
                    singleApplication.status === "Draft" ||
                    singleApplication.status === "Missing Documents" ? (
                      <>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={docRef}
                          onChange={() => {
                            handleButtonClick();
                          }}
                        />
                        {scWidth <= 1000 ? (
                          <img
                            className="attachmentPic"
                            src={attachment}
                            alt="Upload Now"
                            onClick={(e) => {
                              setDocName(
                                missingDoc.name ? missingDoc.name : missingDoc
                              );
                              setopenUploadDocuments(true);
                            }}
                          />
                        ) : (
                          <Button
                            text="Upload Now"
                            color="yellow"
                            onClick={(e) => {
                              setDocName(
                                missingDoc.name ? missingDoc.name : missingDoc
                              );

                              setopenUploadDocuments(true);
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <div className="None_div">None</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {(singleApplication.status === "In Review" ||
          singleApplication.status === "Draft" ||
          singleApplication.status === "Missing Documents") && (
          <div className="CancelMyApplication" onClick={() => setShowPop(true)}>
            Cancel My Application
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleApplication;
