import { axiosReq } from "../../../Utils";
import { CounselorChatActions } from "./CounselorChatReducer";

export const getAllChats = (search) => async (dispatch) => {
  dispatch(CounselorChatActions.getAllChatsReq());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/user/conversations",
      {},
      {},
      { search: search }
    )
  ).then((res) => {
    if (res.success) {
      dispatch(CounselorChatActions.getAllChatsSuccess(res.data));
    } else {
      dispatch(
        CounselorChatActions.getAllChatsFail(res.data.response.data.message)
      );
    }
  });
};
export const getCounselorMessages =
  (user_id, counselor_id) => async (dispatch) => {
    dispatch(CounselorChatActions.getMessagesReq());
    dispatch(
      axiosReq(
        "get",
        process.env.REACT_APP_API +
          "/user/messages/" +
          user_id +
          "/" +
          counselor_id,
        {},
        {}
      )
    ).then((res) => {
      if (res.success) {
        dispatch(CounselorChatActions.getMessagesSuccess(res.data));
      } else {
        dispatch(
          CounselorChatActions.getMessagesFail(res.data.response.data.message)
        );
      }
    });
  };
