/** @format */
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  student: {},
  address: [],
  exp: [],
  emergency: [],
  passport: [],
  //get user
  fetchError: "",
  fetchLoading: false,
  fetchSuccess: false,
  //upload img
  imgError: "",
  imgLoading: false,
  imgSuccess: false,
  //address
  fetchAddressError: "",
  fetchAddressLoading: false,
  fetchAddressSuccess: false,
  //experience
  fetchExpError: "",
  fetchExpLoading: false,
  fetchExpSuccess: false,
  //emergency
  fetchEmgError: "",
  fetchEmgLoading: false,
  fetchEmgSuccess: false,
  //edit
  editLoading: false,
  editSuccess: false,
  editFail: "",
  //change pass
  changeLoading: false,
  changeSuccess: false,
  changeFail: "",
  //passport
  fetchPassportsLoading: false,
  fetchPassportsFail: "",
  //add passport
  addPassportLoading: false,
  addPassportFail: "",
  addPassportSuccess: false,
  //edit passport
  editPassportLoading: false,
  editPassportFail: "",
  editPassportSuccess: false,
  //delete passport
  deletePassportLoading: false,
  deletePassportFail: "",
  deletePassportSuccess: false,
};

const StudentSlice = createSlice({
  name: "Student",
  initialState,
  reducers: {
    fetchStudentRequest(state) {
      state.fetchLoading = true;
      state.fetchError = "";
      state.student = {};
      state.fetchSuccess = false;
    },
    fetchStudentSuccess(state, action) {
      state.fetchLoading = false;
      state.student = action.payload;
      state.fetchError = "";
      state.fetchSuccess = true;
    },
    fetchStudentFail(state, action) {
      state.fetchLoading = false;
      state.student = {};
      state.fetchError = action.payload;
      state.fetchSuccess = false;
    },
    uploadImgRequest(state) {
      state.imgLoading = true;
      state.imgError = "";
      state.imgSuccess = false;
    },
    uploadImgSuccess(state) {
      state.imgLoading = false;
      state.imgError = "";
      state.imgSuccess = true;
    },
    uploadImgFail(state, action) {
      state.imgLoading = false;
      state.imgError = action.payload;
      state.imgSuccess = false;
    },
    fetchAddressRequest(state) {
      state.fetchAddressLoading = true;
      state.fetchAddressError = "";
      state.address = [];
      state.fetchAddressSuccess = false;
    },
    fetchAddressSuccess(state, action) {
      state.fetchAddressLoading = false;
      state.fetchAddressError = "";
      state.address = action.payload;
      state.fetchAddressSuccess = true;
    },
    fetchAddressFail(state, action) {
      state.fetchAddressLoading = false;
      state.fetchAddressError = action.payload;
      state.address = [];
      state.fetchAddressSuccess = false;
    },
    fetchExpRequest(state) {
      state.fetchExpLoading = true;
      state.fetchExpError = "";
      state.exp = [];
      state.fetchExpSuccess = false;
    },
    fetchExpSuccess(state, action) {
      state.fetchExpLoading = false;
      state.fetchExpError = "";
      state.exp = action.payload;
      state.fetchExpSuccess = true;
    },
    fetchExpFail(state, action) {
      state.fetchExpLoading = false;
      state.fetchExpError = action.payload;
      state.exp = [];
      state.fetchExpSuccess = false;
    },
    fetchEmgRequest(state) {
      state.fetchEmgLoading = true;
      state.fetchEmgError = "";
      state.emergency = [];
      state.fetchEmgSuccess = false;
    },
    fetchEmgSuccess(state, action) {
      state.fetchEmgLoading = false;
      state.fetchEmgError = "";
      state.emergency = action.payload;
      state.fetchEmgSuccess = true;
    },
    fetchEmgFail(state, action) {
      state.fetchEmgLoading = false;
      state.fetchEmgError = action.payload;
      state.emergency = [];
      state.fetchEmgSuccess = false;
    },
    editReq(state) {
      state.editLoading = true;
      state.editSuccess = false;
      state.editFail = "";
    },
    editSuccess(state) {
      state.editLoading = false;
      state.editSuccess = true;
      state.editFail = "";
    },
    editFail(state, action) {
      state.editLoading = false;
      state.editSuccess = false;
      state.editFail = action.payload;
    },
    ChangeReq(state) {
      state.changeLoading = true;
      state.changeSuccess = false;
      state.changeFail = "";
    },
    ChangeSuccess(state) {
      state.changeLoading = false;
      state.changeSuccess = true;
      state.changeFail = "";
    },
    ChangeFail(state, action) {
      state.changeLoading = false;
      state.changeSuccess = false;
      state.changeFail = action.payload;
    },
    fetchPassportsRequest(state) {
      state.fetchPassportsLoading = true;
      state.fetchPassportsFail = "";
      state.passport = [];
    },
    fetchPassportsSuccess(state, action) {
      state.fetchPassportsLoading = false;
      state.fetchPassportsFail = "";
      state.passport = action.payload;
    },
    fetchPassportsFail(state, action) {
      state.fetchPassportsLoading = false;
      state.fetchPassportsFail = action.payload;
      state.passport = [];
    },
    addPassportReq(state) {
      state.addPassportLoading = true;
      state.addPassportFail = "";
      state.addPassportSuccess = false;
    },
    addPassportSuccess(state) {
      state.addPassportLoading = false;
      state.addPassportFail = "";
      state.addPassportSuccess = true;
    },
    addPassportFail(state, action) {
      state.addPassportLoading = false;
      state.addPassportFail = action.payload;
      state.addPassportSuccess = false;
    },
    editPassportReq(state) {
      state.editPassportLoading = true;
      state.editPassportFail = "";
      state.editPassportSuccess = false;
    },
    editPassportSuccess(state) {
      state.editPassportLoading = false;
      state.editPassportFail = "";
      state.editPassportSuccess = true;
    },
    editPassportFail(state, action) {
      state.editPassportLoading = false;
      state.editPassportFail = action.payload;
      state.editPassportSuccess = false;
    },

    deletePassportReq(state) {
      state.deletePassportLoading = true;
      state.deletePassportFail = "";
      state.deletePassportSuccess = false;
    },
    deletePassportSuccess(state) {
      state.deletePassportLoading = false;
      state.deletePassportFail = "";
      state.deletePassportSuccess = true;
    },
    deletePassportFail(state, action) {
      state.deletePassportLoading = false;
      state.deletePassportFail = action.payload;
      state.deletePassportSuccess = false;
    },
    cleanStudent(state) {
      state.student = {};
 
    },
  },
});

export const StudentActions = StudentSlice.actions;
export default StudentSlice;
