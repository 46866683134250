import SharedRoutes from "./SharedRoutes";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import { requestPermission } from "./Utils";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();

  requestPermission(dispatch);

  return (
    <GoogleOAuthProvider clientId="205714923260-bifds87h4vl75f2ks7f1tt1j0045l3fj.apps.googleusercontent.com">
      <BrowserRouter>
        <SharedRoutes />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
