import React, { useEffect, useState } from "react";
/*global google*/

import "./Login.scss";
import FormLayout from "../../Components/FormLayout/FormLayout";
import Button from "../../Components/Button/Button";
import Diamond from "../../Components/Animations/Diamond/Diamond";
import Input from "../../Components/Input/Input";
import { FaFacebookF, FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import MobileInput from "../../Components/MobileInput/MobileInput";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import { createUser, loginUser } from "../../../Student/Redux/User/UserActions";
import { useGoogleLogin } from "@react-oauth/google";
import UpperPopUp from "../../Components/UpperPopUp/UpperPopUp";
import img from "../../../Shared/Assets/Images/checked.png";

function Login({ staff }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isPhone, setIsPhone] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [CheckingErrorMessage, setCheckingErrorMessage] = useState("");
  const [showUpper, setShowUpper] = useState(false);
  const [error, setError] = useState("");
  const {
    loginSuccess,
    loginLoading,
    loginError,
    createLoading,
    createError,
    success,
  } = useSelector((state) => state.userReducer);

  //user login
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isPhone && (email != "" || password != "")) {
      let user = { email: email, password: password, type: "emailLogin" };
      dispatch(loginUser(user));
    } else if (isPhone && (phone != "" || password != "")) {
      let user = { phone: phone, password: password, type: "phoneLogin" };
      dispatch(loginUser(user));
    } else {
      setErrorMessage("Please fill all the fields");
    }
  };

  const { user, getUserLoading } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (
      (loginSuccess && user && Object.keys(user)?.length > 0) ||
      (success && user && Object.keys(user)?.length > 0)
    ) {
      if (user.role == "sales") {
        navigate("/" + user.role + "/leads");
      } else {
        navigate("/" + user?.role + "/overview");
      }
    }
  }, [loginSuccess, user]);

  const login = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    responseType: "id_token",
    onSuccess: (credentialResponse) => {
      const user = {
        credentialResponse: credentialResponse,
      };

      dispatch(createUser(user, true, false, true));
    },
    onError: (error) => {
      setErrorMessage(error);
    },
  });

  useEffect(() => {
    if (state && state.popUp) {
      setShowUpper(true);
    }
  }, []);

  return (
    <div className="login_main_div">
      <FormLayout
        header="Welcome Back!"
        subHeader="Sign in to continue."
        loading={loginLoading || getUserLoading || createLoading}
        body={
          <form className="login_main_body" onSubmit={(e) => handleSubmit(e)}>
            <div className="login_inputs">
              <div className="input_div">
                <div className="input_tile">
                  {!isPhone ? "Enter your email" : "Enter your phone number"}
                </div>
                {!isPhone ? (
                  <Input
                    placeholder="e.g: janegilmour@gmail.com"
                    type="email"
                    width={"100%"}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    value={email}
                  />
                ) : (
                  <MobileInput
                    width="100%"
                    phone={phone}
                    setPhone={setPhone}
                    required={true}
                  />
                )}
              </div>
              <div className="input_div">
                <div className="input_tile">Password</div>
                <Input
                  placeholder="Enter your password"
                  type="password"
                  width={"100%"}
                  onChange={(e) => setPassword(e.target.value)}
                  required={true}
                  value={password}
                />
              </div>
            </div>
            {loginError && <div className="error">{loginError}</div>}
            {error && <div className="error">{error}</div>}

            {errorMessage && <div className="error">{errorMessage}</div>}
            {createError && <div className="error">{createError}</div>}

            <div className="login_main_footer">
              <Button
                text="Log In"
                color="lightGreen"
                width="100%"
                disabled={
                  ((isPhone ? phone == "" : email == "") || password == "") &&
                  true
                }
              />
              <div
                className="footer_underbutton"
                style={{ justifyContent: staff && "flex-end" }}
              >
                {!staff && (
                  <div className="footer_signup">
                    Don't have an account?{" "}
                    <span onClick={() => navigate("/student/signUp")}>
                      Sign up here
                    </span>
                  </div>
                )}
                <div
                  className="footer_forgot"
                  onClick={() => {
                    navigate("/login/otpVerification", {
                      state: { staff: staff },
                    });
                  }}
                >
                  Forgot Password?
                </div>
              </div>
              {!staff && (
                <div className="footer_signUpWith">
                  <div className="dropdown_flex">
                    <hr className="login_line" />
                    <div className="login_footer_title"> Or Login With</div>
                    <hr className="login_line" />
                  </div>
                  <div className="login_footer">
                    <div className="google">
                      <FcGoogle size="1.5rem" onClick={() => login()} />
                    </div>
                    <div className="google">
                      <FacebookLogin
                        render={(renderProps) => (
                          <FaFacebookF
                            color="#3b5998"
                            size="1.5rem"
                            onClick={renderProps.onClick}
                          />
                        )}
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        onSuccess={(response) => {
                          const user = {
                            facebookUserId: response.userID,
                            facebookUserToken: response.accessToken,
                          };

                          dispatch(createUser(user, false, true));
                        }}
                        onFail={(error) => {
                          setError(error);
                        }}
                      />
                    </div>
                    <div className="google">
                      {!isPhone ? (
                        <FaPhone
                          style={{
                            transform: "rotate(90deg)",
                            color: "var(--pink)",
                          }}
                          size="1.5rem"
                          onClick={() => setIsPhone(true)}
                        />
                      ) : (
                        <IoMail
                          style={{
                            color: "var(--pink)",
                          }}
                          size="1.5rem"
                          onClick={() => setIsPhone(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        }
        minHeight="fit-content"
        width="90%"
        icon={<Diamond />}
        bg="var(--yellow)"
        gap="20px"
      />
    </div>
  );
}

export default Login;
