import React from "react";
import "./NotFound.scss";
import four_o_four from "../../Assets/Images/404.png";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function NotFound() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.userReducer);

  return (
    <div className="Notfound_container">
      <div className="Notfound_main">
        <img src={four_o_four} alt="404" className="four_image" />
        <div className="four_title">Oops...</div>
        <div className="four_text">Something went wrong</div>
        <Button
          text="Back to Home"
          color={"yellow"}
          onClick={() => {
            Object.keys(user).length > 0
              ? user.role == "student" || user.role == "counselor"
                ? navigate("/" + user.role + "/overview")
                : user.role == "sales"
                ? navigate("/" + user.role + "/leads")
                : navigate("/login")
              : navigate("/login");
          }}
        />
      </div>
    </div>
  );
}

export default NotFound;
