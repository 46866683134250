import React, { useState } from "react";
import "./SingleSelect.scss";

function SingleSelect({ data, setSelected, width, selectedIndex}) {

  return (
    <div className="SingleSelect_Container">
      {data && data.length > 0 ? (
        data.map((item, index) => {
          return (
            <div
              className={
                selectedIndex == item
                  ? "Signle_Div selectedChoice"
                  : "Signle_Div"
              }
              key={index}
              style={{ width: width ? width : "fit-content" }}
              onClick={() => {
                setSelected(item);
              }}
            >
              {item}
            </div>
          );
        })
      ) : (
        <div>No data</div>
      )}
    </div>
  );
}

export default SingleSelect;
