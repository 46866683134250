import React, { useEffect, useState } from "react";
import "./OTPVerify.scss";
import FormLayout from "../../../Shared/Components/FormLayout/FormLayout";

import Circle from "../../../Shared/Components/Animations/Circle/Circle";
import OTP from "../../../Shared/Components/OTP/OTP";
import Button from "../../../Shared/Components/Button/Button";
import Input from "../../../Shared/Components/Input/Input";
import MobileInput from "../../../Shared/Components/MobileInput/MobileInput";
import { useLocation, useNavigate } from "react-router-dom";
import Diamond from "../../../Shared/Components/Animations/Diamond/Diamond";
import Popup from "../../../Shared/Components/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  otpVerify,
  otpVerifyForget,
  phoneVerification,
  verifyPhonetForget,
} from "../../Redux/User/UserActions";
import UpperPopUp from "../../../Shared/Components/UpperPopUp/UpperPopUp";
import img from "../../../Shared/Assets/Images/checked.png";
import { getStudentMe } from "../../Redux/Student/StudentActions";
function OTPVerify({ forget, showOtp, logged }) {
  //ischangeInput is true if we are trying to change phone number or email
  //forget is true if we are  for forget password
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(40);
  const [clicked, setClicked] = useState(false);
  const [input, setInput] = useState("");
  const [otpPage, setOtpPage] = useState(showOtp);
  const [goBack, setGoBack] = useState(false);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  
  const [loading, setLoading] = useState(true);
  const [showUpper, setShowUpper] = useState(false);

  const {
    otpVerifyLoading,
    otpVerifyError,
    otpVerifySuccess,
    phoneVerifyLoading,
    phoneVerifySuccess,
    phoneVerifyError,
    verifyPhoneForgetLoading,
    verifyPhoneForgetError,
    verifyPhoneForgetSuccess,
    otpVerifyForgetLoading,
    otpVerifyForgetError,
    otpVerifyForgetSuccess,
  } = useSelector((store) => store.userReducer);

  //if counter to resend otp
  const counterTime = () => {
    let co = 40;
    let timer = setInterval(() => {
      co = co - 1;
      setCounter(co);
      if (co == 0) {
        clearInterval(timer);
        setClicked(false);
      }
    }, 1000);
  };

  //verify otp
  const verifyPhoneOTP = () => {
    dispatch(otpVerify({ userId: state.user, otp: otp, phone: input }));
  };

  // verify forget otp
  const verifyPhoneForgetOTP = () => {
    dispatch(otpVerifyForget({ otp: otp, phone: input }));
  };

  //if otp is verified show upper popup
  useEffect(() => {
    if ((otp && otpVerifySuccess) || (otp && otpVerifyForgetSuccess)) {
      setShowUpper(true);
    }
  }, [otpVerifySuccess, otpVerifyForgetSuccess]);

  //add and verify phone number (already exists or not) + send otp to user
  const addPhone = () => {
    dispatch(phoneVerification({ userId: state.user, phone: input }));
  };

  //verfiy if phone number exists to reset password + send otp to user
  const verfiyPhoneForget = () => {
    dispatch(verifyPhonetForget({ phone: input }));
  };

  //if phone number is verified then change the page to otp page
  useEffect(() => {
    if ((input && phoneVerifySuccess) || (input && verifyPhoneForgetSuccess)) {
      setOtpPage(true);
      setCounter(40);
      setClicked(false);
    }
  }, [phoneVerifySuccess, verifyPhoneForgetSuccess]);

  // clear inputs and errors
  const changePage = () => {
    setOtp("");
    setInput("");
    setError("");
    setError2("");
  };

  //handle submit on enter phone number page
  const handleSubmit = (e) => {
    e.preventDefault();
    if (forget) {
      verfiyPhoneForget();
    } else addPhone();
  };

  //setting loaders
  useEffect(() => {
    if (
      phoneVerifyLoading ||
      otpVerifyLoading ||
      verifyPhoneForgetLoading ||
      otpVerifyForgetLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    otpVerifyLoading,
    phoneVerifyLoading,
    verifyPhoneForgetLoading,
    otpVerifyForgetLoading,
  ]);

  //setting errors
  useEffect(() => {
    if (otpVerifyError) {
      setError2(otpVerifyError);
    }
    if (phoneVerifyError && !forget) {
      setError(phoneVerifyError);
    }
    if (verifyPhoneForgetError && forget) {
      setError(verifyPhoneForgetError);
    }
    if (otpVerifyForgetError) {
      setError2(otpVerifyForgetError);
    }
  }, [
    otpVerifyError,
    phoneVerifyError,
    verifyPhoneForgetError,
    otpVerifyForgetError,
  ]);

  const sendAgain = () => {
    setOtp("");
    counterTime();
    setClicked(true);
    if (forget) verfiyPhoneForget();
    else addPhone();
  };
  return (
    <div className="otp_verify_main_div">
      {showUpper && (
        <UpperPopUp
          title="Account Verified"
          img={img}
          setShow={setShowUpper}
          navigate={() =>
            forget
              ? navigate("/login/changePassword", {
                  state: { phone: input, otp: otp ,staff:state.staff },
                })
              : logged
              ? (window.location.href = "/student/overview")
              : navigate("/login")
          }
        />
      )}
      {otpPage ? (
        <FormLayout
          header={forget ? "Forgot Password?" : "Enter your verification code"}
          subHeader={
            forget
              ? "Enter the code to create a new password"
              : "We have just sent a verification code to your phone number"
          }
          loading={loading}
          body={
            <div>
              <OTP setOtpValue={setOtp} otpValue={otp} />
              <div className="send_code_div">
                <div
                  className={
                    clicked ? "send_code_text text_unactive" : "send_code_text"
                  }
                  onClick={() => {
                    sendAgain();
                  }}
                >
                  Send code again {clicked ? "in " + counter + " sec" : ""}
                </div>

                <div
                  className="send_code_text"
                  onClick={() => {
                    setOtpPage(false);
                    setGoBack(true);
                    changePage();
                  }}
                >
                  Change phone number
                </div>
              </div>
              {error2 && <div className="error">{error2}</div>}

              <Button
                text={"Finish"}
                color="lightGreen"
                width="100%"
                onClick={() => {
                  forget ? verifyPhoneForgetOTP() : verifyPhoneOTP();
                }}
                disabled={otp.length != 4 ? true : false}
              />
            </div>
          }
          minHeight="fit-content"
          width="90%"
          icon={<Circle />}
          bg="var(--yellow)"
          gap="20px"
        />
      ) : (
        <FormLayout
          back={goBack ? () => setOtpPage(!otpPage) : ""}
          header={"Enter your phone number"}
          subHeader={
            "Enter your phone number to receive a verification code on WhatsApp."
          }
          loading={loading}
          body={
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="input_div" style={{
                marginTop:"2rem"
              }}>
                <MobileInput
                  width="100%"
                  phone={input}
                  setPhone={setInput}
                  required={true}
                />
              </div>
              {error && <div className="error">{error}</div>}

              <Button
                text="Verify"
                color="lightGreen"
                width="100%"
                disabled={input == "" ? true : false}
              />
            </form>
          }
          minHeight="fit-content"
          width="90%"
          icon={<Diamond />}
          bg="var(--pink)"
          gap="20px"
        />
      )}
    </div>
  );
}

export default OTPVerify;
