import React, { useEffect, useState } from "react";
import "./MobileInput.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function MobileInput(props) {
  // props:
  // width
  // disabled
  // defaultValue
  // search
  // required
  //setPhone
  //phone

  useEffect(() => {
    if (props.defaultValue) props.setPhone(props.defaultValue);
  }, []);

  // props for inputPhone:
  // country	string	initial country
  // value	string	input state value
  // onlyCountries	array	country codes to be included	['cu','cw','kz']
  // preferredCountries	array	country codes to be at the top	['cu','cw','kz']
  // excludeCountries	array	array of country codes to be excluded	['cu','cw','kz']
  // placeholder	string	custom placeholder
  // inputProps	object	props to pass into the input
  // autoFormat	true	on/off phone formatting
  // disabled	false	disable input and dropdown
  // disableDropdown	false	disable dropdown only
  // disableCountryCode	false
  // enableAreaCodes	false	enable local codes for all countries
  // enableTerritories	false	enable dependent territories with population of ~100,000 or lower
  // enableLongNumbers	false	boolean/number
  // countryCodeEditable	true
  // enableSearch	false	enable search in the dropdown
  // disableSearchIcon	false	hide icon for the search field

  // containerClass	string	class for container
  // inputClass	string	class for input
  // buttonClass	string	class for dropdown button
  // dropdownClass	string	class for dropdown container
  // searchClass	string	class for search field
  // containerStyle	object	styles for container
  // inputStyle	object	styles for input
  // buttonStyle	object	styles for dropdown button
  // dropdownStyle	object	styles for dropdown container
  // searchStyle	object	styles for search field

  /////////isValid to check if number is valid or not

  //full documentation https://www.npmjs.com/package/react-phone-input-2
  //qatar lebnen emarat saudi
  return (
    <PhoneInput
      country={"lb"}
      masks={{ lb: ".. ... ..." }}
      value={props.phone}
      onChange={(phone) =>
        props.changeable != "No" ? props.setPhone(phone) : null
      }
      onlyCountries={[]}
      preferredCountries={["qa", "lb", "ae", "sa"]}
      excludeCountries={[]}
      placeholder={props.placeholder}
      inputProps={{
        name: "phone",
        required: props.required,
      }}
      autoFormat={true}
      disabled={props.disabled}
      disableDropdown={props.disableDropdown ? props.disableDropdown : false}
      disableCountryCode={false}
      enableAreaCodes={false}
      enableTerritories={false}
      enableLongNumbers={false}
      countryCodeEditable={true}
      enableSearch={props.search}
      disableSearchIcon={true}
      containerClass={"MobileInput_container"}
      inputClass={"MobileInput_input"}
      buttonClass={"MobileInput_button"}
      dropdownClass={"MobileInput_dropdown"}
      searchClass={"MobileInput_search"}
      containerStyle={{
        width: props.width ? `calc(${props.width} - (6px))` : "fit-content",
      }}
      inputStyle={{
        width: props.width ? `calc(${props.width} - (6px))` : "fit-content",
      }}
      dropdownStyle={{
        width: props.width ? `calc(${props.width} - (6px))` : "fit-content",
      }}
    />
  );
}

export default MobileInput;
