import React, { useEffect, useState } from "react";
import "./EditEducationSummary.scss";
import Input from "../Input/Input";
import DateInput from "../DatePicker/DateInput";
import DropDown from "../DropDown/DropDown";
import Button from "../Button/Button";
import trash from "../../Assets/Images/trash.png";
import UpperPopUp from "../UpperPopUp/UpperPopUp";
import img from "../../Assets/Images/checked.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCont } from "../../Redux/Location/LocationsActions";
import { DropDownList } from "../../../Utils";
import Loader from "../Loader/Loader";
import {
  getSingleApp,
  updateUnifiedApp,
} from "../../Redux/Application/ApplicationAction";
import Popup from "../Popup/Popup";
import add from "../../Assets/Images/add.png";
import arrow from "../../Assets/Images/arrow1.webp";
import { updateApplication } from "../../Redux/CreateApplication/CreateApplicationAction";
function EditEducationSummary({ setShowPop, data, isApp }) {
  const dispatch = useDispatch();
  const [school, setSchool] = useState("");
  const [countryEd, setCountryEd] = useState("");
  const [overGpa, setOverGpa] = useState("");
  const [gpa, setGpa] = useState("");
  const [degree, setDegree] = useState("");
  const [field, setField] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [educationA, setEducationA] = useState([]);
  const [hideDetails, setHideDetails] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState("");
  const [showUpper, setShowUpper] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    updateUnifiedApplicationLoading,
    updateUnifiedApplicationSuccess,
    updateUnifiedApplicationError,
  } = useSelector((state) => state.ApplicationsReducer);

  const {
    updateApplicationLoading,
    updateApplicationError,
    updateApplicationSuccess,
  } = useSelector((state) => state.CreateApplicationReducer);

  const { Countries, getCountriesLoading } = useSelector(
    (state) => state.LocationReducer
  );

  useEffect(() => {
    dispatch(getAllCont());
  }, []);
  useEffect(() => {
    if (Countries && Countries.length > 0) {
      setAllCountries(DropDownList(Countries, "name", "name"));
    }
  }, [Countries]);

  useEffect(() => {
    if (getCountriesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getCountriesLoading]);

  useEffect(() => {
    if (
      (updateUnifiedApplicationLoading && !isApp) ||
      (updateApplicationLoading && isApp)
    ) {
      setBtnLoad(true);
    } else {
      setBtnLoad(false);
    }
  }, [updateUnifiedApplicationLoading, updateApplicationLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hideDetails) {
      addEducation();
      if (educationA.length != 0) {
        setError("");
      }
      setHideDetails(true);
    } else {
      setClicked(true);
      const application = {
        education_summary: educationA,
      };
      if (!isApp) {
        dispatch(updateUnifiedApp(application));
      } else {
        dispatch(
          updateApplication(
            localStorage.getItem("applicationId"),
            application,
            true
          )
        );
        localStorage.removeItem("applicationId");
      }
    }
  };

  const clearAll = () => {
    setSchool("");
    setCountryEd("");
    setOverGpa("");
    setGpa("");
    setDegree("");
    setField("");
    setStart("");
    setEnd("");
    setError("");
  };

  const addEducation = () => {
    if (
      educationA.length == 0 &&
      (school == "" ||
        countryEd == "" ||
        overGpa == "" ||
        gpa == "" ||
        degree == "" ||
        field == "" ||
        start == "" ||
        end == "")
    ) {
      setError("Please fill all the fields");
    } else {
      if (
        school != "" &&
        countryEd != "" &&
        overGpa != "" &&
        gpa != "" &&
        degree != "" &&
        field != "" &&
        start != "" &&
        end != ""
      ) {
        const educationArray = [...educationA];
        educationArray.push({
          school_name: school,
          country_of_education: countryEd.value,
          grade_scale: overGpa,
          gpa: gpa,
          degree: degree,
          field_of_study: field,
          start_date: new Date(start),
          end_date: new Date(end),
        });
        setEducationA(educationArray);
        setShowTable(true);
        clearAll();
      } else {
        setError("Please fill all the fields");
      }
    }
  };
  const deleteEducation = (index) => {
    const educationArray = [...educationA];
    educationArray.splice(index, 1);
    setEducationA(educationArray);
    if (educationA.length == 1) {
      setShowTable(false);
      setHideDetails(false);
    }
  };

  useEffect(() => {
    if (
      (updateUnifiedApplicationSuccess && clicked && !isApp) ||
      (updateApplicationSuccess && clicked && isApp)
    ) {
      setShowPop(false);
      setShowUpper(true);
    }
  }, [updateUnifiedApplicationSuccess, updateApplicationSuccess, clicked]);

  useEffect(() => {
    if (
      (updateUnifiedApplicationError && !isApp) ||
      (updateApplicationError && isApp)
    ) {
      setError(updateUnifiedApplicationError);
    } else {
      setError("");
    }
  }, [updateUnifiedApplicationError, updateApplicationError]);

  useEffect(() => {
    if (data && data.length > 0) {
      const educationArray = [];
      data.map((item) => {
        educationArray.push({
          school_name: item.school_name,
          country_of_education: item.country_of_education,
          grade_scale: item.grade_scale,
          gpa: item.gpa,
          degree: item.degree,
          field_of_study: item.field_of_study,
          start_date: item.start_date,
          end_date: item.end_date,
        });
      });
      setEducationA(educationArray);
      setHideDetails(true);
      setShowTable(true);
    } else {
      setShowTable(false);
      setHideDetails(false);
      setEducationA([]);
    }
  }, [data]);

  return (
    <div className="edit_education_summary_main_div">
      {showUpper && (
        <UpperPopUp
          setShow={setShowUpper}
          title="Unified Application Updated Successfully"
          img={img}
        />
      )}
      <Popup
        setShowPopup={setShowPop}
        body={
          <div className="applyForm_card_div">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="applyForm_card_div_main_header">
                  <div className="applyForm_card_div_header">
                    {hideDetails && (
                      <img
                        src={arrow}
                        alt="back"
                        className="applyForm_headerIcon1"
                        onClick={() => setHideDetails(false)}
                      />
                    )}
                    <h1 className="applyForm_card_title">Education Summary</h1>
                    {!hideDetails && (
                      <img
                        src={add}
                        alt="add"
                        className="applyForm_headerIcon2"
                        onClick={() => addEducation()}
                      />
                    )}
                  </div>
                  <p className="applyForm_card_sub">
                    Please make sure your details are accurate and updated
                  </p>
                </div>
                <>
                  <div className="application_step1_body">
                    {!hideDetails && (
                      <>
                        <div className="application_step1_body_div1">
                          <div className="input_div">
                            <div className="input_tile">School Attended</div>
                            <Input
                              placeholder="School Attended"
                              type="text"
                              width={"100%"}
                              onChange={(e) => setSchool(e.target.value)}
                              required={true}
                              value={school}
                            />
                          </div>

                          <div className="input_div">
                            <div className="input_tile">
                              County of Education
                            </div>
                            {allCountries && allCountries.length > 0 && (
                              <DropDown
                                placeholder="County of Education"
                                setSelected={setCountryEd}
                                data={allCountries}
                                blueArrow={true}
                                width="100%"
                                multiSelection={false}
                                searchable={true}
                                selectedData={countryEd}
                                value={countryEd}
                              />
                            )}
                          </div>
                        </div>
                        <div className="application_step1_body_div1">
                          <div className="input_div">
                            <div className="input_tile">
                              Grade Scale (out of)
                            </div>
                            <Input
                              placeholder="Grade Scale (out of)"
                              type="number"
                              width={"100%"}
                              onChange={(e) => setOverGpa(e.target.value)}
                              required={true}
                              value={overGpa}
                              min={0}
                            />
                          </div>

                          <div className="input_div">
                            <div className="input_tile">Grade (GPA)</div>
                            <Input
                              placeholder="Grade (GPA)"
                              type="number"
                              width={"100%"}
                              onChange={(e) => setGpa(e.target.value)}
                              required={true}
                              value={gpa}
                              min={0}
                              max={overGpa}
                            />
                          </div>
                        </div>
                        <div className="application_step1_body_div1">
                          <div className="input_div">
                            <div className="input_tile">Degree</div>
                            <Input
                              min={0}
                              placeholder="Degree"
                              type="text"
                              width={"100%"}
                              onChange={(e) => setDegree(e.target.value)}
                              required={true}
                              value={degree}
                            />
                          </div>

                          <div className="input_div">
                            <div className="input_tile">Field Of Study</div>
                            <Input
                              placeholder="Field Of Study"
                              type="text"
                              width={"100%"}
                              onChange={(e) => setField(e.target.value)}
                              required={true}
                              value={field}
                            />
                          </div>
                        </div>
                        <div className="application_step1_body_div1">
                          <div className="input_div">
                            <div className="input_tile">Start Date</div>
                            <DateInput
                              width="100%"
                              setSelectedDate={setStart}
                              selectedDate={start}
                            />
                          </div>
                          <div className="input_div">
                            <div className="input_tile">
                              End Date (or expected)
                            </div>
                            <DateInput
                              width="100%"
                              setSelectedDate={setEnd}
                              selectedDate={end}
                              disabled={start == "" ? true : false}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {showTable && (
                      <div className="application_step2_educationTable_main">
                        {educationA?.map((item, i) => {
                          return (
                            <div
                              className="application_step2_educationTable"
                              style={{
                                borderBottom: hideDetails && "3px solid black",
                                borderTop: !hideDetails && "3px solid black",
                              }}
                              key={i}
                            >
                              <div className="educationTable_text">
                                {item.school_name}
                              </div>
                              <div className="educationTable_text">
                                {item.field_of_study}
                              </div>
                              <div className="educationTable_text">
                                {item.gpa} / {item.grade_scale}
                              </div>
                              <div className="educationTable_text">
                                {/* only show year */}
                                {new Date(
                                  item.start_date
                                ).getUTCFullYear()} -{" "}
                                {new Date(item.end_date).getUTCFullYear()}
                              </div>
                              <img
                                src={trash}
                                alt="delete"
                                className="educationTable_delete"
                                onClick={() => {
                                  deleteEducation(i);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {error && <div className="error">{error}</div>}
                    <div className="application_step1_body_footer">
                      <Button
                        text={
                          btnLoad ? (
                            <Loader onlyDots={true} />
                          ) : hideDetails ? (
                            "Finish"
                          ) : (
                            "Continue"
                          )
                        }
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        color="yellow"
                        width="100%"
                        disabled={
                          (educationA.length == 0 &&
                            (school == "" ||
                              countryEd == "" ||
                              overGpa == "" ||
                              gpa == "" ||
                              degree == "" ||
                              field == "" ||
                              start == "" ||
                              end == "")) ||
                          btnLoad
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </>
              </>
            )}
          </div>
        }
      />
    </div>
  );
}

export default EditEducationSummary;
