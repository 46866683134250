import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Snap2.scss";
import R1 from "../assets/MainBird/1-1.webm";
import R1M from "../assets/MainBird/1.png";
import R3 from "../assets/MainBird/3-1.webm";
import R3M from "../assets/MainBird/3.png";
import R4 from "../assets/MainBird/4.webm";
import R4M from "../assets/MainBird/4.png";
import R5 from "../assets/MainBird/5.webm";
import R5M from "../assets/MainBird/5.png";
import R6 from "../assets/MainBird/6.webm";
import R6M from "../assets/MainBird/6.png";
import R7 from "../assets/MainBird/7.webm";
import R7M from "../assets/MainBird/6.png";

import F11 from "../assets/Bird/1.webm";
import F11M from "../assets/Bird/1.png";
import F12 from "../assets/Bird/2.webm";
import F12M from "../assets/Bird/2.png";
import F13 from "../assets/Bird/3.webm";
import F13M from "../assets/Bird/3.png";
import F14 from "../assets/Bird/4.webm";
import F14M from "../assets/Bird/4.png";

import F21 from "../assets/Bird 2/1.webm";
import F21M from "../assets/Bird 2/1.png";
import F22 from "../assets/Bird 2/2.webm";
import F22M from "../assets/Bird 2/2.png";
import F23 from "../assets/Bird 2/3.webm";
import F23M from "../assets/Bird 2/3.png";
import F24 from "../assets/Bird 2/4.webm";
import F24M from "../assets/Bird 2/4.png";

import F31 from "../assets/Bird 3/1.webm";
import F31M from "../assets/Bird 3/1.png";
import F32 from "../assets/Bird 3/2.webm";
import F32M from "../assets/Bird 3/2.png";
import F33 from "../assets/Bird 3/3.webm";
import F33M from "../assets/Bird 3/3.png";
import F34 from "../assets/Bird 3/4.webm";
import F34M from "../assets/Bird 3/4.png";

import H1 from "../assets/CounselorBird/1.webm";
import H1M from "../assets/CounselorBird/1.png";
import H2 from "../assets/CounselorBird/2.webm";
import H2M from "../assets/CounselorBird/2.png";
import H3 from "../assets/CounselorBird/3.webm";
import H3M from "../assets/CounselorBird/3.png";

import Cloud from "../assets/Cloud/Cloud.gif";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";

gsap.registerPlugin(ScrollTrigger);
function Snap2() {
	const [isSafari] = useState(!!window.safari);

	const containerRef = useRef();
	// const imagesRef = useRef([]);
	const Robin1 = useRef();
	const Robin2 = useRef();
	const Robin3 = useRef();
	const Robin4 = useRef();
	const Robin5 = useRef();
	const Robin6 = useRef();
	const Robin7 = useRef();
	const sec1 = useRef();

	// const Target1 = useRef();

	const Henry1 = useRef();
	const Henry2 = useRef();
	const Henry3 = useRef();

	const frd11 = useRef();
	const frd21 = useRef();
	const frd31 = useRef();

	const frd12 = useRef();
	const frd22 = useRef();
	const frd32 = useRef();

	const frd13 = useRef();
	const frd23 = useRef();
	const frd33 = useRef();

	const frd14 = useRef();
	const frd24 = useRef();
	const frd34 = useRef();
	const navigate = useNavigate();

	const ani = (elm, x0, y0, x1, y1, dur, scl) => {
		ScrollTrigger.create({
			trigger: elm,
			scroller: containerRef.current,
			scrub: 1,
			onEnter: () => {
				gsap.fromTo(
					elm,
					{
						x: x0,
						y: y0,
						opacity: 0,
						scale: scl,
					},
					{
						x: x1,
						y: y1,
						opacity: 1,
						scale: scl,
						duration: dur,
					}
				);
				if (!isSafari) {
					elm.play();
				}
			},
			onLeave: () => {
				gsap.fromTo(
					elm,
					{
						x: x1,
						y: y1,
						opacity: 1,
						scale: scl,
					},
					{
						x: x0,
						y: y0,
						opacity: 0,
						scale: scl,
					}
				);
			},
			onEnterBack: () => {
				gsap.fromTo(
					elm,
					{
						x: x0,
						y: y0,
						opacity: 0,
						scale: scl,
					},
					{
						x: x1,
						y: y1,
						opacity: 1,
						scale: scl,
						duration: dur,
					}
				);
				if (!isSafari) {
					elm.play();
				}
			},
			onLeaveBack: () => {
				gsap.fromTo(
					elm,
					{
						x: x1,
						y: y1,
						scale: scl,
					},
					{
						x: x0,
						y: y0,
						scale: scl,
					}
				);
			},
		});
	};

	useEffect(() => {
		if (!isSafari) {
			if (window.innerWidth === 1920) {
				ani(Robin1.current, -700, -400, 400, 200, 1.5, 0.4);
				ani(Robin2.current, 400, -400, 500, 100, 2, 0.6);
				ani(Robin3.current, 400, -400, -600, 100, 1.5, 0.7);
				ani(Robin4.current, 400, -400, -600, 300, 1.5, 0.7);
				ani(Robin5.current, -400, -400, -200, 0, 4.5, 0.6);
				ani(Robin6.current, -400, -400, -300, 0, 1.5, 0.6);

				ani(frd11.current, -400, -400, 1000, -300, 1.5, 0.4);
				ani(frd12.current, 1000, -600, -200, -300, 1.5, 0.4);
				ani(frd13.current, -400, -600, 200, -200, 1.5, 0.4);
				ani(frd14.current, 1000, -600, -300, -250, 1.5, 0.3);

				ani(frd21.current, -400, -1400, -400, -300, 1, 0.4);
				ani(frd22.current, 1000, -600, -400, 250, 1.5, 0.4);
				ani(frd23.current, -400, -600, 500, -200, 1.5, 0.4);
				ani(frd24.current, 1000, -600, -100, -250, 1.5, 0.3);

				ani(frd31.current, 1000, -1400, -400, 100, 1.5, 0.35);
				ani(frd32.current, -400, -1000, 450, -200, 1.5, 0.4);
				ani(frd33.current, -400, -600, 500, 100, 1.5, 0.4);
				ani(frd34.current, 1000, -600, 300, -250, 1.5, 0.3);

				ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.6);
				ani(Henry2.current, -400, -600, 0, 0, 1.5, 0.6);
				ani(Henry3.current, 1000, -600, 0, -50, 1.5, 0.6);
			}
			if (window.innerWidth > 1920) {
				ani(Robin1.current, -700, -400, 600, 200, 1.5, 1);
				ani(Robin2.current, 400, -400, 500, 200, 2, 1);
				ani(Robin3.current, 400, -400, -800, 100, 1.5, 1);
				ani(Robin4.current, 400, -400, -600, 300, 1.5, 1);
				ani(Robin5.current, -400, -400, -250, 100, 4.5, 0.8);
				ani(Robin6.current, -400, -400, -300, 0, 1.5, 1);

				ani(frd11.current, -400, -400, 1600, -200, 1.5, 0.5);
				ani(frd12.current, 1000, -600, 200, -200, 1.5, 0.7);
				ani(frd13.current, -400, -600, 200, -200, 1.5, 0.6);
				ani(frd14.current, 1000, -600, -400, -300, 1.5, 0.45);

				ani(frd21.current, -400, -1400, -800, -200, 1, 0.6);
				ani(frd22.current, 1000, -600, -600, 400, 1.5, 0.7);
				ani(frd23.current, -400, -600, 700, -200, 1.5, 0.6);
				ani(frd24.current, 1000, -600, -50, -300, 1.5, 0.45);

				ani(frd31.current, 1000, -1400, -600, 400, 1.5, 0.5);
				ani(frd32.current, -400, -1000, 650, -200, 1.5, 0.7);
				ani(frd33.current, -400, -600, 600, 150, 1.5, 0.7);
				ani(frd34.current, 1000, -600, 500, -300, 1.5, 0.45);

				ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.8);
				ani(Henry2.current, -400, -600, 200, 0, 1.5, 0.8);
				ani(Henry3.current, 1000, -600, 0, 100, 1.5, 0.9);
			}
			if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
				ani(Robin1.current, -700, -400, 400, 200, 1.5, 0.5);
				ani(Robin2.current, 400, -400, 450, 150, 2, 0.6);
				ani(Robin3.current, 400, -400, -500, 50, 1.5, 0.8);
				ani(Robin4.current, 400, -400, -400, 300, 1.5, 0.5);
				ani(Robin5.current, -400, -400, -200, 50, 4.5, 0.6);
				ani(Robin6.current, -400, -400, -300, 0, 1.5, 0.5);

				ani(frd11.current, -400, -400, 700, -300, 1.5, 0.4);
				ani(frd12.current, 1000, -600, -200, -300, 1.5, 0.4);
				ani(frd13.current, -400, -600, 200, -200, 1.5, 0.4);
				ani(frd14.current, 1000, -600, -400, -200, 1.5, 0.3);

				ani(frd21.current, -400, -1400, -500, -300, 1, 0.4);
				ani(frd22.current, 1000, -600, -400, 250, 1.5, 0.4);
				ani(frd23.current, -400, -600, 450, -200, 1.5, 0.4);
				ani(frd24.current, 1000, -600, -50, -225, 1.5, 0.3);

				ani(frd31.current, 1000, -1400, -400, 100, 1.5, 0.4);
				ani(frd32.current, -400, -1000, 400, -150, 1.5, 0.4);
				ani(frd33.current, -400, -600, 400, 50, 1.5, 0.5);
				ani(frd34.current, 1000, -600, 400, -250, 1.5, 0.3);

				ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.5);
				ani(Henry2.current, -400, -600, 200, 50, 1.5, 0.6);
				ani(Henry3.current, 1000, -600, 0, -100, 1.5, 0.6);
			}
			if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
				ani(Robin1.current, -700, -400, 350, 200, 1.5, 0.4);
				ani(Robin2.current, 400, -400, 300, 225, 2, 0.5);
				ani(Robin3.current, 400, -400, -400, 50, 1.5, 0.7);
				ani(Robin4.current, 400, -400, -250, 350, 1.5, 0.6);
				ani(Robin5.current, -400, -400, -200, 50, 4.5, 0.6);
				ani(Robin6.current, -400, -400, -300, 0, 1.5, 0.5);

				ani(frd11.current, -400, -400, 300, -400, 1.5, 0.3);
				ani(frd12.current, 1000, -600, -300, -350, 1.5, 0.3);
				ani(frd13.current, -400, -600, 150, -250, 1.5, 0.3);
				ani(frd14.current, 1000, -600, -400, -200, 1.5, 0.3);

				ani(frd21.current, -400, -1400, -400, -400, 1, 0.3);
				ani(frd22.current, 1000, -600, -400, 250, 1.5, 0.4);
				ani(frd23.current, -400, -600, 350, -150, 1.5, 0.3);
				ani(frd24.current, 1000, -600, -150, -225, 1.5, 0.3);

				ani(frd31.current, 1000, -1400, -350, 100, 1.5, 0.3);
				ani(frd32.current, -400, -1000, 400, -150, 1.5, 0.4);
				ani(frd33.current, -400, -600, 250, 50, 1.5, 0.4);
				ani(frd34.current, 1000, -600, 300, -250, 1.5, 0.3);

				ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.5);
				ani(Henry2.current, -400, -600, 200, 50, 1.5, 0.5);
				ani(Henry3.current, 1000, -600, 0, -100, 1.5, 0.6);
			}
			if (window.innerWidth >= 768 && window.innerWidth < 1024) {
				ani(Robin1.current, -700, -400, 250, 250, 1.5, 0.3);
				ani(Robin2.current, 400, -400, 225, 250, 2, 0.5);
				ani(Robin3.current, 400, -400, -300, 50, 1.5, 0.7);
				ani(Robin4.current, 400, -400, 0, 350, 1.5, 0.5);
				ani(Robin5.current, -400, -400, -150, 50, 4.5, 0.5);
				ani(Robin6.current, -400, -400, -200, 0, 1.5, 0.4);

				ani(frd11.current, -400, -400, 100, -400, 1.5, 0.3);
				ani(frd12.current, 1000, -600, -350, -400, 1.5, 0.25);
				ani(frd13.current, -400, -600, 150, -250, 1.5, 0.3);
				ani(frd14.current, 1000, -600, -250, -250, 1.5, 0.25);

				ani(frd21.current, -400, -1400, -200, -400, 1, 0.3);
				ani(frd22.current, 1000, -600, -200, 250, 1.5, 0.3);
				ani(frd23.current, -400, -600, 200, 0, 1.5, 0.25);
				ani(frd24.current, 1000, -600, -100, -250, 1.5, 0.3);

				ani(frd31.current, 1000, -1400, -250, 150, 1.5, 0.3);
				ani(frd32.current, -400, -1000, 275, -150, 1.5, 0.3);
				ani(frd33.current, -400, -600, 200, 150, 1.5, 0.3);
				ani(frd34.current, 1000, -600, 250, -250, 1.5, 0.3);

				ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.4);
				ani(Henry2.current, -400, -600, 100, 150, 1.5, 0.4);
				ani(Henry3.current, 1000, -600, 0, -50, 1.5, 0.5);
			}
			if (window.innerWidth >= 425 && window.innerWidth < 768) {
				ani(Robin1.current, -700, -400, 100, 250, 1.5, 0.3);
				ani(Robin2.current, 400, -400, 50, 200, 2, 0.4);
				ani(Robin3.current, 400, -400, -150, 50, 1.5, 0.4);
				ani(Robin4.current, 400, -400, 50, 300, 1.5, 0.5);
				ani(Robin5.current, -400, -400, -100, 50, 4.5, 0.5);
				ani(Robin6.current, -400, -400, -100, 0, 1.5, 0.4);

				ani(frd11.current, -400, -400, -200, -400, 1.5, 0.25);
				ani(frd12.current, 1000, -600, -450, -400, 1.5, 0.2);
				ani(frd13.current, -400, -600, 100, -250, 1.5, 0.3);
				ani(frd14.current, 1000, -600, -150, -300, 1.5, 0.2);

				ani(frd21.current, -400, -1400, -100, -400, 1, 0.25);
				ani(frd22.current, 1000, -600, -100, 250, 1.5, 0.2);
				ani(frd23.current, -400, -600, 75, 0, 1.5, 0.25);
				ani(frd24.current, 1000, -600, 100, -300, 1.5, 0.2);

				ani(frd31.current, 1000, -1400, -100, 150, 1.5, 0.25);
				ani(frd32.current, -400, -1000, 100, -200, 1.5, 0.2);
				ani(frd33.current, -400, -600, 0, 200, 1.5, 0.3);
				ani(frd34.current, 1000, -600, 0, -210, 1.5, 0.3);

				ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.5);
				ani(Henry2.current, -400, -600, 100, 150, 1.5, 0.4);
				ani(Henry3.current, 1000, -600, 0, -100, 1.5, 0.6);
			}
			if (window.innerWidth < 425) {
				ani(Robin1.current, -700, -400, 100, 200, 1.5, 0.2);
				ani(Robin2.current, 400, -400, 50, 200, 2, 0.3);
				ani(Robin3.current, 400, -400, -150, 50, 1.5, 0.4);
				ani(Robin4.current, 400, -400, 50, 250, 1.5, 0.4);
				ani(Robin5.current, -400, -400, -100, 100, 4.5, 0.4);
				ani(Robin6.current, -400, -400, -75, 0, 1.5, 0.35);

				ani(frd11.current, -400, -400, -300, -400, 1.5, 0.25);
				ani(frd12.current, 1000, -600, -450, -400, 1.5, 0.2);
				ani(frd13.current, -400, -600, 75, -250, 1.5, 0.2);
				ani(frd14.current, 1000, -600, -100, -300, 1.5, 0.15);

				ani(frd21.current, -400, -1400, -100, -400, 1, 0.25);
				ani(frd22.current, 1000, -600, -100, 250, 1.5, 0.2);
				ani(frd23.current, -400, -600, 75, 0, 1.5, 0.2);
				ani(frd24.current, 1000, -600, 50, -300, 1.5, 0.15);

				ani(frd31.current, 1000, -1400, -50, 50, 1.5, 0.2);
				ani(frd32.current, -400, -1000, 100, -200, 1.5, 0.2);
				ani(frd33.current, -400, -600, 50, 150, 1.5, 0.3);
				ani(frd34.current, 1000, -600, 0, -210, 1.5, 0.2);

				ani(Henry1.current, -400, -1000, 50, 150, 1.5, 0.4);
				ani(Henry2.current, -400, -600, 25, 100, 1.5, 0.45);
				ani(Henry3.current, 1000, -600, 0, -150, 1.5, 0.45);
			}
			if (window.innerHeight < 707 && window.innerHeight >= 563) {
				if (window.innerWidth >= 1280 && window.innerWidth < 1920) {
					ani(Robin1.current, -700, -400, 400, 150, 1.5, 0.3);
					ani(Robin2.current, 400, -400, 450, 150, 2, 0.4);
					ani(Robin3.current, 400, -400, -500, 50, 1.5, 0.6);
					ani(Robin4.current, 400, -400, -400, 250, 1.5, 0.45);
					ani(Robin5.current, -400, -400, -150, 50, 4.5, 0.4);
					ani(Robin6.current, -400, -400, -300, 0, 1.5, 0.5);

					ani(frd11.current, -400, -400, 600, -400, 1.5, 0.3);
					ani(frd12.current, 1000, -600, -300, -400, 1.5, 0.3);
					ani(frd13.current, -400, -600, 150, -150, 1.5, 0.25);
					ani(frd14.current, 1000, -600, -300, -150, 1.5, 0.175);

					ani(frd21.current, -400, -1400, -500, -400, 1, 0.3);
					ani(frd22.current, 1000, -600, -500, 100, 1.5, 0.3);
					ani(frd23.current, -400, -600, 400, -125, 1.5, 0.25);
					ani(frd24.current, 1000, -600, -50, -150, 1.5, 0.175);

					ani(frd31.current, 1000, -1400, -400, -100, 1.5, 0.25);
					ani(frd32.current, -400, -1000, 400, -100, 1.5, 0.3);
					ani(frd33.current, -400, -600, 350, 0, 1.5, 0.4);
					ani(frd34.current, 1000, -600, 300, -150, 1.5, 0.175);

					ani(Henry1.current, -400, -1000, 100, 75, 1.5, 0.3);
					ani(Henry2.current, -400, -600, 200, 50, 1.5, 0.3);
					ani(Henry3.current, 1000, -600, 0, -230, 1.5, 0.35);
				}
			}
		}
		if (window.innerHeight < 923 && window.innerHeight >= 707) {
			if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
				if (isSafari) {
					ani(Robin1.current, -700, -400, 500, 300, 1.5, 1);
					ani(Robin2.current, 400, -400, 450, 300, 2, 1);
					ani(Robin3.current, 400, -400, -500, 50, 1.5, 1.5);
					ani(Robin4.current, 400, -400, -400, 250, 1.5, 1);
					ani(Robin5.current, -400, -400, -150, 200, 1.5, 0.8);
					ani(Robin6.current, -400, -400, -300, 0, 1.5, 0.8);

					ani(frd11.current, -400, -400, 1000, 0, 1.5, 1);
					ani(frd12.current, 1000, -600, 0, 0, 1.5, 1);
					ani(frd13.current, -400, -600, 300, -300, 1.5, 0.8);
					ani(frd14.current, 1000, -600, -400, -200, 1.5, 0.5);

					ani(frd21.current, -400, -1400, -500, -50, 1, 1);
					ani(frd22.current, 1000, -600, -400, 250, 1.5, 1);
					ani(frd23.current, -400, -600, 550, -150, 1.5, 0.8);
					ani(frd24.current, 1000, -600, -50, -200, 1.5, 0.5);

					ani(frd31.current, 1000, -1400, -400, 350, 1.5, 1);
					ani(frd32.current, -400, -1000, 400, -150, 1.5, 1);
					ani(frd33.current, -400, -600, 400, 200, 1.5, 0.8);
					ani(frd34.current, 1000, -600, 400, -250, 1.5, 0.5);

					ani(Henry1.current, -400, -1000, 100, 100, 1.5, 1);
					ani(Henry2.current, -400, -600, 200, 50, 1.5, 1);
					ani(Henry3.current, 1000, -600, 0, 250, 1.5, 1.2);
				} else {
					ani(Robin1.current, -700, -400, 400, 200, 1.5, 0.4);
					ani(Robin2.current, 400, -400, 450, 150, 2, 0.6);
					ani(Robin3.current, 400, -400, -500, 50, 1.5, 0.7);
					ani(Robin4.current, 400, -400, -400, 250, 1.5, 0.45);
					ani(Robin5.current, -400, -400, -150, 50, 4.5, 0.5);
					ani(Robin6.current, -400, -400, -300, 0, 1.5, 0.5);

					ani(frd11.current, -400, -400, 700, -300, 1.5, 0.3);
					ani(frd12.current, 1000, -600, -200, -300, 1.5, 0.4);
					ani(frd13.current, -400, -600, 200, -150, 1.5, 0.3);
					ani(frd14.current, 1000, -600, -400, -200, 1.5, 0.25);

					ani(frd21.current, -400, -1400, -500, -300, 1, 0.3);
					ani(frd22.current, 1000, -600, -400, 250, 1.5, 0.4);
					ani(frd23.current, -400, -600, 450, -150, 1.5, 0.3);
					ani(frd24.current, 1000, -600, -50, -200, 1.5, 0.25);

					ani(frd31.current, 1000, -1400, -400, 0, 1.5, 0.3);
					ani(frd32.current, -400, -1000, 400, -150, 1.5, 0.4);
					ani(frd33.current, -400, -600, 400, 50, 1.5, 0.4);
					ani(frd34.current, 1000, -600, 400, -250, 1.5, 0.25);

					ani(Henry1.current, -400, -1000, 100, 100, 1.5, 0.4);
					ani(Henry2.current, -400, -600, 200, 50, 1.5, 0.5);
					ani(Henry3.current, 1000, -600, 0, -200, 1.5, 0.5);
				}
			}
		}
	}, [window.innerWidth, window.innerHeight]);

	return (
		<div id="container" ref={containerRef}>
			{isSafari ? (
				<>
					{/* Scene1 */}
					<div className="scene1" id="sec1" ref={sec1}>
						<img className="Robin1" src={R1M} alt="" ref={Robin1} />
						<img ref={frd11} className="frd11" src={F11M} alt="" />
						<img ref={frd21} className="frd21" src={F21M} alt="" />
						<img ref={frd31} className="frd31" src={F31M} alt="" />

						<h1 id="tit">
							Welcome To <br /> <span>Education Basket</span>
						</h1>
						<div className="btn_div_home">
							<Button
								text="Become a Student"
								color="pink"
								width="13rem"
								onClick={() => navigate("/student/signUp")}
							/>
							<Button
								text="Login"
								color="lightGreen"
								width="13rem"
								onClick={() => navigate("/student/login")}
							/>
						</div>
						<div id="scrl">
							<div className="mouse_scroll">
								<div className="mouse">
									<div className="wheel"></div>
								</div>
							</div>
						</div>
					</div>

					{/* Scene2 */}
					<div className="scene2" id="sec2">
						<img ref={Robin2} className="Robin2" src={R1M} alt="" />
						<img ref={frd12} className="frd12" src={F12M} alt="" />
						<img ref={frd22} className="frd22" src={F22M} alt="" />
						<img ref={frd32} className="frd32" src={F32M} alt="" />
						<p>
							<span className="big">
								<span>Meet</span> Robin
							</span>
							,<br /> the high-flying bird,and his
							<br />
							<br />
							<span className="big">
								<span>feathered</span> companions.
							</span>
							<br />
							They were all enjoying their teenage years together,
							<br />
							navigating through the skies
						</p>
						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
					</div>

					{/* Scene3 */}
					<div className="scene3" id="sec3">
						<img ref={Robin3} className="Robin3" src={R3M} alt="" />
						<img ref={frd13} className="frd13" src={F13M} alt="" />
						<img ref={frd23} className="frd23" src={F23M} alt="" />
						<img ref={frd33} className="frd33" src={F33M} alt="" />
						<div id="left">
							<div id="pDiv">
								<p>
									<span>Suddenly,</span> life felt too serious for Robin,
									everyone was deciding their futures, taking drastic decisions
									and Robin was not near this{" "}
								</p>
							</div>
							<div></div>
						</div>
						<div id="right"></div>
					</div>

					{/* Scene4 */}
					<div className="scene4" id="sec4">
						<img ref={Robin4} className="Robin4" src={R4M} alt="" />
						<img ref={frd14} className="frd14" src={F14M} alt="" />
						<img ref={frd24} className="frd24" src={F24M} alt="" />
						<img ref={frd34} className="frd34" src={F34M} alt="" />
						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<p>
							<span>Robin</span> couldn't ignore the weight of life settling in.
							<br /> It seemed like everyone was making big decisions about
							their future, <br />
							leaving Robin feeling left behind.
						</p>
					</div>

					{/* Scene5 */}
					<div className="scene5" id="sec5">
						<img ref={Robin5} className="Robin5" src={R5M} alt="" />
						<img ref={Henry1} className="Henry1" src={H1M} alt="" />
						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<div id="left"></div>
						<div id="right">
							<div id="pDiv">
								<p>
									<span>Just then,</span> a colorful bird named Henry flew over
									and said, "Hey Robin, life is meant to be an adventure, let's
									stick together and figure out your own path!”
								</p>
							</div>
							<div></div>
						</div>
					</div>

					{/* Scene6 */}
					<div className="scene6" id="sec6">
						<img ref={Robin6} className="Robin6" src={R6M} alt="" />
						<img ref={Henry2} className="Henry2" src={H2M} alt="" />

						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<div id="left"></div>
						<div id="right">
							<p>
								<span>Rise above</span> the clouds of loneliness, Robin. Spread
								your wings, and get ready to connect with like-minded
								individuals. Expand your worldview, dream, explore, & learn
							</p>
						</div>
					</div>

					{/* Scene7 */}
					<div className="scene7" id="sec7">
						<img ref={Robin7} className="Robin7" src={R7M} alt="" />
						<img ref={Henry3} className="Henry3" src={H3M} alt="" />

						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<p>
							Let <span>Henry</span> lead you to the open door of education
							wonderland. Brace yourself, spread your wings and let's create a
							story together worth sharing.
						</p>
					</div>
				</>
			) : (
				<>
					{/* Scene1 */}
					<div className="scene1" id="sec1" ref={sec1}>
						<video
							ref={Robin1}
							className="Robin1"
							src={R1}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video ref={frd11} className="frd11" src={F11} muted />
						<video
							ref={frd21}
							className="frd21"
							src={F21}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video ref={frd31} className="frd31" muted src={F31} />

						{/* <img src={F31M} ref={frd31} className="frd31" alt="" /> */}
						<h1 id="tit">
							Welcome To <br /> <span>Education Basket</span>
						</h1>
						<div className="btn_div_home">
							<Button
								text="Become a Student"
								color="pink"
								width="13rem"
								onClick={() => navigate("/student/signUp")}
							/>
							<Button
								text="Login"
								color="lightGreen"
								width="13rem"
								onClick={() => navigate("/student/login")}
							/>
						</div>
						<div id="scrl">
							<div className="mouse_scroll">
								<div className="mouse">
									<div className="wheel"></div>
								</div>
							</div>
						</div>
					</div>

					{/* Scene2 */}
					<div className="scene2" id="sec2">
						<video
							ref={Robin2}
							className="Robin2"
							src={R1}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd12}
							className="frd12"
							src={F12}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd22}
							className="frd22"
							src={F22}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd32}
							className="frd32"
							src={F32}
							type="video/webm"
							muted
							// autoPlay
						/>
						<p>
							<span className="big">
								<span>Meet</span> Robin
							</span>
							,<br /> the high-flying bird,and his
							<br />
							<br />
							<span className="big">
								<span>feathered</span> companions.
							</span>
							<br />
							They were all enjoying their teenage years together,
							<br />
							navigating through the skies
						</p>
						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
					</div>

					{/* Scene3 */}
					<div className="scene3" id="sec3">
						<video
							ref={Robin3}
							className="Robin3"
							src={R3}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd13}
							className="frd13"
							src={F13}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd23}
							className="frd23"
							src={F23}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd33}
							className="frd33"
							src={F33}
							type="video/webm"
							muted
							// autoPlay
						/>
						<div id="left">
							<div id="pDiv">
								<p>
									<span>Suddenly,</span> life felt too serious for Robin,
									everyone was deciding their futures, taking drastic decisions
									and Robin was not near this{" "}
								</p>
							</div>
							<div></div>
						</div>
						<div id="right"></div>
					</div>

					{/* Scene4 */}
					<div className="scene4" id="sec4">
						<video
							ref={Robin4}
							className="Robin4"
							src={R4}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={frd14}
							className="frd14"
							src={F14}
							type="video/webm"
							muted
							// autoPlay
							loop
						/>
						<video
							ref={frd24}
							className="frd24"
							src={F24}
							type="video/webm"
							muted
							// autoPlay
							loop
						/>
						<video
							ref={frd34}
							className="frd34"
							src={F34}
							type="video/webm"
							muted
							// autoPlay
							loop
						/>
						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<p>
							<span>Robin</span> couldn't ignore the weight of life settling in.
							<br /> It seemed like everyone was making big decisions about
							their future, <br />
							leaving Robin feeling left behind.
						</p>
					</div>

					{/* Scene5 */}
					<div className="scene5" id="sec5">
						<video
							ref={Robin5}
							className="Robin5"
							src={R5}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={Henry1}
							className="Henry1"
							src={H1}
							type="video/webm"
							muted
							// autoPlay
						/>

						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<div id="left"></div>
						<div id="right">
							<div id="pDiv">
								<p>
									<span>Just then,</span> a colorful bird named Henry flew over
									and said, "Hey Robin, life is meant to be an adventure, let's
									stick together and figure out your own path!”
								</p>
							</div>
							<div></div>
						</div>
					</div>

					{/* Scene6 */}
					<div className="scene6" id="sec6">
						<video
							ref={Robin6}
							className="Robin6"
							src={R6}
							type="video/webm"
							muted
							// autoPlay
						/>
						<video
							ref={Henry2}
							className="Henry2"
							src={H2}
							type="video/webm"
							muted
							// autoPlay
						/>

						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<div id="left"></div>
						<div id="right">
							<p>
								<span>Rise above</span> the clouds of loneliness, Robin. Spread
								your wings, and get ready to connect with like-minded
								individuals. Expand your worldview, dream, explore, & learn
							</p>
						</div>
					</div>

					{/* Scene7 */}
					<div className="scene7" id="sec7">
						<video
							ref={Robin7}
							className="Robin7"
							src={R7}
							type="video/webm"
							muted
							autoPlay
							loop
						/>

						<video
							ref={Henry3}
							className="Henry3"
							src={H3}
							type="video/webm"
							muted
							// autoPlay
							loop
						/>

						<img id="cloud1" src={Cloud} alt="cloud" />
						<img id="cloud2" src={Cloud} alt="cloud" />
						<p>
							Let <span>Henry</span> lead you to the open door of education
							wonderland. Brace yourself, spread your wings and let's create a
							story together worth sharing.
						</p>
					</div>
				</>
			)}

			<Footer />
		</div>
	);
}

export default Snap2;
