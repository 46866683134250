import React, { useEffect, useState } from "react";
import "./SalesSources.scss";
import Add from "../../../Shared/Assets/Images/add.png";
import PopUp from "../../../Shared/Components/Popup/Popup";
import Input from "../../../Shared/Components/Input/Input";
import Button from "../../../Shared/Components/Button/Button";
import Dropdown from "../../../Shared/Components/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesSources,
  createSalesSources,
  createSalesSourcecat,
} from "../../Redux/SalesSources/SalesSourcesActions";
import Loader from "../../../Shared/Components/Loader/Loader";

function SalesSources() {
  const dispatch = useDispatch();
  const [addSource, setAddSource] = useState(false);
  const [addedSource, setAddedSource] = useState("");
  const [addCategory, setAddCategory] = useState(false);
  const { loading, Sources } = useSelector(
    (state) => state.SalesSourcesReducer
  );
  useEffect(() => {
    dispatch(getSalesSources());
  }, []);
  const tableHeader = [
    "Schools",
    "Social Media",
    "Referral",
    "Qatar",
    "Sub-agent",
    "Others",
  ];
  const array = [
    {
      CatName: "Schools",
      data: [
        {
          name: "Lebanese technical school",
        },
        {
          name: "Private technical school",
        },
      ],
    },
    {
      CatName: "Social Media",
      data: [
        {
          name: "Facebook",
        },
        {
          name: "Whatsapp",
        },
      ],
    },
    {
      CatName: "Referral",
      data: [
        {
          name: "Lebanon Referral",
        },
        {
          name: "Lebanon Referral",
        },
      ],
    },
    {
      CatName: "Qatar",
      data: [
        {
          name: "Qatar Offices",
        },
        {
          name: "Facebook",
        },
      ],
    },
    {
      CatName: "Sub-agent",
      data: [
        {
          name: "Jana",
        },
        {
          name: "Eliana",
        },
        {
          name: "Georges",
        },
      ],
    },
    {
      CatName: "Others",
      data: [
        {
          name: "Old Lead Event",
        },
        {
          name: "Counselor",
        },
        {
          name: "Counselor",
        },
      ],
    },
  ];

  const arr2 = [];

  // Find the maximum number of data items among all categories
  // const maxDataItems = Math.max(
  //   ...array.map((category) => category.data.length)
  // );

  // // Iterate over each data item index
  // for (let dataIndex = 0; dataIndex < maxDataItems; dataIndex++) {
  //   // Create a new row to store the current data items
  //   const newRow = [];

  //   // Iterate over each category
  //   for (let categoryIndex = 0; categoryIndex < array.length; categoryIndex++) {
  //     const category = array[categoryIndex];
  //     const dataItem = category.data[dataIndex];

  //     // If a data item exists at the current index, add its name to the row
  //     if (dataItem) {
  //       newRow.push(dataItem.name);
  //     } else {
  //       newRow.push(""); // Push an empty string if there is no data item
  //     }
  //   }

  //   // Push the row to the transformed array
  //   arr2.push(newRow);
  // }

  // Find the maximum number of sources among all categories
  const maxSources = Math.max(
    ...Sources.map((category) => category.sources.length)
  );

  // Iterate over each source index
  for (let sourceIndex = 0; sourceIndex < maxSources; sourceIndex++) {
    // Create a new row to store the current sources
    const newRow = [];

    // Iterate over each category
    for (
      let categoryIndex = 0;
      categoryIndex < Sources.length;
      categoryIndex++
    ) {
      const category = Sources[categoryIndex];
      const sources = category.sources;

      // If a source exists at the current index, add its name to the row
      if (sourceIndex < sources.length) {
        const source = sources[sourceIndex];
        newRow.push(source.name);
      } else {
        newRow.push(""); // Push an empty string if there is no source
      }
    }

    // Push the row to the transformed array
    arr2.push(newRow);
  }
  const handlecreatecategory = () => {
    const data = {
      name: addedSource,
    };
    dispatch(createSalesSources(data));
    setAddedSource("");
    setAddSource(false);
  };
  const [selectedsource, setselectedsource] = useState();
  const handlecreatesource = () => {
    const data = {
      name: addedSource,
      referal_category: selectedsource.value,
    };
    dispatch(createSalesSourcecat(data));
    setAddedSource("");
    setselectedsource("");
    setAddCategory(false);
  };
  return (
    <div className="SSources_Container">
      <div className="SSources_InnerContainer">
        <div className="SSourcesTitleAddDiv">
          <h1 className="SSourcesTitle">Sources</h1>
          <img
            className="SSourcesAdd"
            src={Add}
            onClick={() => {
              setAddSource(true);
            }}
          />
        </div>
        {addSource && (
          <PopUp
            setShowPopup={setAddSource}
            width="30%"
            body={
              <div>
                <h1 className="AddSourceCatPopup">Add Source Category</h1>
                <div className="input_div">
                  <div className="input_tile">Source Category</div>
                  <Input
                    placeholder="Source Category"
                    type="text"
                    width={"100%"}
                    onChange={(e) => setAddedSource(e.target.value)}
                    required={true}
                    value={addedSource}
                  />
                </div>
                <div className="AddSourceCatButtonDiv">
                  <Button
                    text="Save"
                    color="yellow"
                    width="100%"
                    onClick={() => handlecreatecategory()}
                  />
                </div>
              </div>
            }
          />
        )}
        <div className="SSourcesTableContainer">
          <table>
            <thead>
              {Sources.map((header) => {
              
                return (
                  <th>
                    <div className="thNameImgDiv">
                      {header.name}
                      <img
                        className="thSSourcesAdd"
                        src={Add}
                        onClick={() => {
                          setAddCategory(true);
                          setselectedsource({
                            label: header.name,
                            value: header.id,
                          });
                        }}
                      />
                    </div>
                  </th>
                );
              })}
              {addCategory && (
                <PopUp
                  setShowPopup={setAddCategory}
                  width="30%"
                  body={
                    <div>
                      <h1 className="AddSourceCatPopup">
                        Add Referral Category
                      </h1>
                      <div className="input_div">
                        <Dropdown
                          title="Source Category"
                          placeholder="Source Category"
                          width={"100%"}
                          blueArrow={true}
                          data={Sources.map((item) => {
                            return { label: item.name, value: item.id };
                          })}
                          selectedData={selectedsource}
                          setSelected={setselectedsource}
                          value={selectedsource}
                        />
                      </div>
                      <div className="input_div RefferalCatSOurcesInputDiv">
                        <div className="input_tile">Referral Category</div>
                        <Input
                          placeholder="Source Category"
                          type="text"
                          width={"100%"}
                          onChange={(e) => setAddedSource(e.target.value)}
                          required={true}
                          value={addedSource}
                        />
                      </div>
                      <div className="AddSourceCatButtonDiv">
                        <Button
                          text="Save"
                          color="yellow"
                          width="100%"
                          onClick={() => handlecreatesource()}
                        />
                      </div>
                    </div>
                  }
                />
              )}
            </thead>

            <tbody>
              {arr2?.map((item, index) => {
                return (
                  <tr>
                    {item.map((singleItem, i) => {
                      return (
                        <td className={!singleItem && "SSourcesEmptyTd"}>
                          {singleItem}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {loading && <Loader fullScreen={true} />}
    </div>
  );
}

export default SalesSources;
