import React, { useEffect, useRef, useState } from "react";
import "./CounselorChat.scss";
import four_o_four from "../../../Shared/Assets/Images/404.png";
import Button from "../../../Shared/Components/Button/Button";
import SearchInput from "../../../Shared/Components/SearchInput/SearchInput";
import defaultImg from "../../../Shared/Assets/Images/default.jpeg";
import CounselorChatC from "../../Components/CounselorChatC/CounselorChatC";
import UseInnerWidth from "../../../Hooks/UseInnerWidth";
import { useDispatch, useSelector } from "react-redux";
import { getAllChats } from "../../Redux/CounselorChat/CounselorChatActions";
import hasItemsInIndexedDB, {
  DropDownList,
  IdSplit,
  formatCreatedAtDate,
} from "../../../Utils";
import Loader from "../../../Shared/Components/Loader/Loader";
import { getCounselorStudents } from "../../Redux/CounselorStudent/CounselorStudentActions";
import { CounselorChatActions } from "../../Redux/CounselorChat/CounselorChatReducer";
import { useLocation } from "react-router-dom";

function CounselorChat() {
  const [selectedIndex, setselectedIndex] = useState();
  const [user, setUser] = useState({});

  const location = useLocation();
  const state = location.state;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCounselorStudents());
    dispatch(getAllChats(""));
    setselectedIndex(0);
  }, []);

  // const allChats = [
  //   {
  //     name: "Mohamad",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Tarek",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Alex",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Dana",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Talia",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Melanie",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Majd",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  //   {
  //     name: "Mohamad",
  //     degree: "Bachelors in Law",
  //     id: "223344",
  //     date: "2 days ago",
  //   },
  // ];

  const { allChats, getAllChatsLoading, senderId, getMessagesLoading } =
    useSelector((state) => state.CounselorChatReducer);

  const counselor = useSelector((state) => state.userReducer.user);

  const [Chats, setChats] = useState([]);

  useEffect(() => {
    setChats(allChats);
  }, [allChats]);

  const [openMobile, setOpenMobile] = useState(false);

  useEffect(() => {
    if (selectedIndex !== undefined && Chats[selectedIndex]) {
      if (senderId.find((element) => element == Chats[selectedIndex].user.id)) {
        dispatch(
          CounselorChatActions.resetNewSender(Chats[selectedIndex].user.id)
        );
      }
    }
    setUser(Chats[selectedIndex]);
    localStorage.setItem("senderId", Chats[selectedIndex]?.user?.id);
    if (selectedIndex !== undefined && width <= 768) {
      setOpenMobile(true);
    }
  }, [Chats, selectedIndex]);

  const width = UseInnerWidth();

  useEffect(() => {
    if (width <= 768) {
      setselectedIndex(undefined);
    } else {
      setOpenMobile(false);
      if (selectedIndex === undefined) {
        setselectedIndex(0);
      }
    }
  }, [width]);

  const [searchIn, setSearchIn] = useState("");

  useEffect(() => {
    if (searchIn != "") {
    }
  }, [searchIn]);

  const { students } = useSelector(
    (state) => state.CounselorStudentReducer.Students
  );

  const { loading } = useSelector((state) => state.CounselorStudentReducer);

  useEffect(() => {
    var c = [...Chats];
    senderId.length > 0 &&
      senderId.map((id) => {
        const found = c.find((chat) => chat.user.id == id);
        if (found) {
          c = c.filter((chat) => chat.user.id != id);
        }
        const foundStudent = students.find((student) => student.user.id == id);
        c.unshift({
          id: "",
          messages: [{ timestamp: new Date().toISOString() }],
          user: {
            id: id,
            student: { id: foundStudent.id },
            first_name: foundStudent.user.first_name,
            last_name: foundStudent.user.last_name,
            profile_picture: foundStudent.user.profile_picture,
            fcm_token: foundStudent.user.fcm_token,
          },
          counselor: {
            id: counselor.id,
          },
        });

        selectedIndex + 1 <= Chats.length - 1
          ? setselectedIndex(selectedIndex + 1)
          : setselectedIndex(selectedIndex);

        setChats(c);
      });
  }, [senderId]);

  const [st, setst] = useState([]);

  useEffect(() => {
    if (Chats.length > 0 && students && students.length > 0) {
      const a = students.filter(
        (student) => !Chats.find((chat) => chat.user.id == student.user.id)
      );
      const c = a.map((student) => student);
      setst(c);
    } else {
      setst(students?.map((student) => student));
    }
  }, [students, Chats]);

  const [selectedStudent, setSelectedStudent] = useState();

  useEffect(() => {
    if (selectedStudent && Object.keys(selectedStudent).length > 0) {
      const c = [...Chats];
      c.unshift({
        id: "",
        messages: [{ timestamp: new Date().toISOString() }],
        user: {
          id: students.find((student) => student.id == selectedStudent.value)
            .user.id,
          student: {
            id: students.find((student) => student.id == selectedStudent.value)
              .id,
          },
          first_name: students.find(
            (student) => student.id == selectedStudent.value
          ).user.first_name,
          last_name: students.find(
            (student) => student.id == selectedStudent.value
          ).user.last_name,
          profile_picture: students.find(
            (student) => student.id == selectedStudent.value
          ).user.profile_picture,
          fcm_token: students.find(
            (student) => student.id == selectedStudent.value
          ).user.fcm_token,
        },
        counselor: { id: counselor.id },
      });
      setst(st.filter((student) => student.user.id != selectedStudent.value));
      setChats(c);
      setselectedIndex(0);
    }
  }, [selectedStudent]);

  async function getBackNotification() {
    try {
      const hasItems = await hasItemsInIndexedDB(dispatch);
      return hasItems;
      // Perform any desired operations based on the result
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors that occurred during the IndexedDB operations
    }
  }

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        getBackNotification().then((res) => {
          if (res) dispatch(getAllChats(""));
        });
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (searchIn) {
      setCounter(counter + 1);
      const timer = setTimeout(() => {
        dispatch(getAllChats(searchIn));
      }, 800);

      return () => clearTimeout(timer);
    } else if (counter > 0 && !searchIn) {
      const timer = setTimeout(() => {
        dispatch(getAllChats(searchIn));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [searchIn]);

  useEffect(() => {
    if (state) {
      const { chatId } = state;
      const found = allChats.find((student) => student.user.id == chatId);
      if (found) {
        setselectedIndex(allChats.indexOf(found));
      }
    }
  }, []);

  return (
    <div className="counselorchat_container">
      {(loading || getAllChatsLoading) && <Loader fullScreen={true} />}
      <div className="counselorchat">
        <div className="counselorChat_left">
          <div className="counselorChat_left_inner">
            <SearchInput
              width={"100%"}
              placeholder={"Search Name Here"}
              inputV={searchIn}
              setInputV={setSearchIn}
            />

            {/* {!getMessagesLoading && st && st.length > 0 && (
              <div className="new_chat">
                Choose a student to start a new chat with:
                <span className="dropdown_students">
                  {st && st.length > 0 && (
                    <Dropdown
                      placeholder={"Choose a student"}
                      width={"100%"}
                      setSelected={setSelectedStudent}
                      data={st.map((student) => {
                        return {
                          label:
                            student.user.first_name +
                            " " +
                            student.user.last_name,
                          value: student.id,
                        };
                      })}
                      searchable={true}
                    />
                  )}
                  {(!st || st.length == 0) && (
                    <Dropdown
                      placeholder={"Choose a student"}
                      width={"100%"}
                      setSelected={setSelectedStudent}
                      data={st.map((student) => {
                        return {
                          label: student.first_name + " " + student.last_name,
                          value: student.id,
                        };
                      })}
                      searchable={true}
                    />
                  )}
                </span>
              </div>
            )} */}
            <div className="left_members">
              {Chats.length > 0
                ? Chats.map((chat, index) => {
                    return (
                      <div
                        className={
                          selectedIndex == index
                            ? "Single_chat green_Border"
                            : "Single_chat"
                        }
                        key={index}
                        onClick={() => setselectedIndex(index)}
                      >
                        <div className="Single_chat_inner">
                          <img
                            src={
                              chat?.user?.profile_picture == null
                                ? defaultImg
                                : process.env.REACT_APP_API +
                                  "/files/students/" +
                                  chat?.user?.student?.id +
                                  "/" +
                                  chat?.user?.profile_picture
                            }
                            alt="default"
                            className="default_img"
                          />
                          <div className="text">
                            <div className="top">
                              {chat.user.first_name + " " + chat.user.last_name}
                            </div>
                            <div className="middle">
                              <div className="bott">
                                ID: {IdSplit(chat.user.id).replace("#", "")}
                              </div>
                              <div className="date">
                                {formatCreatedAtDate(
                                  chat?.messages[0]?.timestamp
                                )}
                              </div>
                            </div>
                          </div>
                          {senderId.find(
                            (element) => element == chat.user.id
                          ) && <div className="red_dot_unread"></div>}
                        </div>
                      </div>
                    );
                  })
                : "No Chats Found"}
            </div>
          </div>
        </div>
        {width > 768 ? (
          <div className="counselorChat_right">
            <CounselorChatC
              user={user}
              setOpenMobile={setOpenMobile}
              selectedIndex={selectedIndex}
              setselectedIndex={setselectedIndex}
              Chats={Chats}
              setChats={setChats}
            />
          </div>
        ) : (
          selectedIndex != undefined &&
          openMobile && (
            <div className="counselorChat_right">
              <CounselorChatC
                user={user}
                setOpenMobile={setOpenMobile}
                selectedIndex={selectedIndex}
                setselectedIndex={setselectedIndex}
                Chats={Chats}
                setChats={setChats}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default CounselorChat;
