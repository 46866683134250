import CounselorChatSlice from "../../../Counsler/Redux/CounselorChat/CounselorChatReducer";

/** @format */
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  events: [],
  fetchEventsLoading: false,
  fetchEventsFail: "",
  tasks: [],
  fetchTasksLoading: false,
  fetchTasksFail: "",
  editTaskLoading: false,
  editTaskFail: "",
  editTaskSuccess: false,
  getTimeSlotsLoading: false,
  getTimeSlotsFail: "",
  timeSlots: [],
  requestMeetingLoading: false,
  requestMeetingFail: "",
  requestMeetingSuccess: false,
  getDaysLoading: false,
  getDaysFail: "",
  days: {},

  chat: [],
  fetchChatLoading: false,
  fetchChatFail: "",
  sendMessageLoading: false,
  sendMessageFail: "",
  sendMessageSuccess: false,
};

const MyCounselorSlice = createSlice({
  name: "Overview",
  initialState,
  reducers: {
    fetchEventsRequest(state) {
      state.fetchEventsLoading = true;
      state.fetchEventsFail = "";
      state.events = [];
    },
    fetchEventsSuccess(state, action) {
      state.fetchEventsLoading = false;
      state.events = action.payload;
      state.fetchEventsFail = "";
    },
    fetchEventsFail(state, action) {
      state.fetchEventsLoading = false;
      state.events = [];
      state.fetchEventsFail = action.payload;
    },

    fetchTasksRequest(state) {
      state.fetchTasksLoading = true;
      state.fetchTasksFail = "";
      state.tasks = [];
    },
    fetchTasksSuccess(state, action) {
      state.fetchTasksLoading = false;
      state.tasks = action.payload;
      state.fetchTasksFail = "";
      state.editTaskSuccess = false;
    },
    fetchTasksFail(state, action) {
      state.fetchTasksLoading = false;
      state.tasks = [];
      state.fetchTasksFail = action.payload;
    },
    changeTaskStatusRequest(state) {
      state.editTaskLoading = true;
      state.editTaskFail = "";
      state.editTaskSuccess = false;
    },
    changeTaskStatusSuccess(state) {
      state.editTaskLoading = false;
      state.editTaskFail = "";
      state.editTaskSuccess = true;
    },
    changeTaskStatusFail(state, action) {
      state.editTaskLoading = false;
      state.editTaskFail = action.payload;
      state.editTaskSuccess = false;
    },
    getTimeSlotsRequest(state) {
      state.getTimeSlotsLoading = true;
      state.getTimeSlotsFail = "";
      state.timeSlots = [];
    },
    getTimeSlotsSuccess(state, action) {
      state.getTimeSlotsLoading = false;
      state.getTimeSlotsFail = "";
      state.timeSlots = action.payload;
    },
    getTimeSlotsFail(state, action) {
      state.getTimeSlotsLoading = false;
      state.getTimeSlotsFail = action.payload;
      state.timeSlots = [];
    },
    requestMeetingRequest(state) {
      state.requestMeetingLoading = true;
      state.requestMeetingFail = "";
      state.requestMeetingSuccess = false;
    },
    requestMeetingSuccess(state) {
      state.requestMeetingLoading = false;
      state.requestMeetingFail = "";
      state.requestMeetingSuccess = true;
    },
    requestMeetingFail(state, action) {
      state.requestMeetingLoading = false;
      state.requestMeetingFail = action.payload;
      state.requestMeetingSuccess = false;
    },
    getDaysRequest(state) {
      state.getDaysLoading = true;
      state.getDaysFail = "";
      state.days = {};
    },
    getDaysSuccess(state, action) {
      state.getDaysLoading = false;
      state.getDaysFail = "";
      state.days = action.payload;
    },
    getDaysFail(state, action) {
      state.getDaysLoading = false;
      state.getDaysFail = action.payload;
      state.days = {};
    },

    fetchChatRequest(state) {
      state.fetchChatLoading = true;
      state.fetchChatFail = "";
      state.chat = [];
    },
    fetchChatSuccess(state, action) {
      state.fetchChatLoading = false;
      state.chat = action.payload;
      state.fetchChatFail = "";
    },
    fetchChatFail(state, action) {
      state.fetchChatLoading = false;
      state.chat = [];
      state.fetchChatFail = action.payload;
    },
    sendMessageRequest(state) {
      state.sendMessageLoading = true;
      state.sendMessageFail = "";
      state.sendMessageSuccess = false;
    },
    sendMessageSuccess(state) {
      state.sendMessageLoading = false;
      state.sendMessageFail = "";
      state.sendMessageSuccess = true;
    },
    sendMessageFail(state, action) {
      state.sendMessageLoading = false;
      state.sendMessageFail = action.payload;
      state.sendMessageSuccess = false;
    },
    cleanSendMessage(state) {
      state.sendMessageLoading = false;
      state.sendMessageFail = "";
      state.sendMessageSuccess = false;
    },

    addNewMessage(state, action) {
      state.chat.push(action.payload);
    },
  },
});

export const MyCounselorActions = MyCounselorSlice.actions;
export default MyCounselorSlice;
