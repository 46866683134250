import React, { useEffect, useRef, useState } from "react";
import "./UploadDoc.scss";
import Popup from "../Popup/Popup";
import Radio from "../Radio/Radio";
import DropDown from "../DropDown/DropDown";
import Button from "../Button/Button";
import uploadHere from "../../Assets/Images/uploadHere.png";
import { getDocs } from "../../../Student/Redux/Documents/DocumentsActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";

function UploadDoc(props) {
  //setShow
  //title
  //showRadio
  //uploadedDoc
  //setUploadedDoc
  //setChosenDoc
  //chosenDoc
  //onSubmit
  //docName
  //setDocName
  const dispatch = useDispatch();
  const [fromPC, setFromPC] = useState(false);
  const [loading, setLoading] = useState(true);
  const { Docs, fetchDocsLoading, MissingDocs, missingDocLoading } =
    useSelector((state) => state.DocumentsReducer);
  const ref = useRef(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!fetchDocsLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [fetchDocsLoading]);
  const onDocChange = (event, name) => {
    if (event.target.files && event.target.files[0]) {
      if (props.chosenDoc[name]) {
        props.setChosenDoc({ ...props.chosenDoc, [name]: null });
      }
      const doc = { ...props.uploadedDoc };
      doc[name] = event.target.files[0];
      props.setUploadedDoc(doc);
    }
  };

  const changeDoc = () => {
    ref.current.click();
  };

  const chooseDocument = (element, name) => {
    if (props.uploadedDoc[name]) {
      props.setUploadedDoc({ ...props.uploadedDoc, [name]: null });
    }
    const doc = { ...props.chosenDoc };
    doc[name] = element;
    props.setChosenDoc(doc);
  };

  useEffect(() => {
    if (Docs.length > 0 && Docs)
      setData(
        Docs.map((doc) => {
          return {
            label: doc.data.originalName,
            value: doc.id,
          };
        })
      );
  }, [Docs]);

  useEffect(() => {
    if (Docs.length === 0 && Docs) {
      dispatch(getDocs());
    }
    if (props.uploadedDoc[props.docName]) {
      setFromPC(true);
    }
  }, []);

  return (
    <div>
      <Popup
        setShowPopup={props.setShow}
        body={
          <>
            {loading ? (
              <Loader />
            ) : (
              <div className="step4_chooseDoc_main">
                <div className="step4_chooseDoc_main_header">{props.title}</div>
                {props.showRadio && data.length !== 0 && (
                  <Radio
                    left_text="My uploaded documents"
                    right_text="My Computer"
                    isVisible={true}
                    setRadioValue={setFromPC}
                    radioValue={fromPC}
                  />
                )}

                {data.length === 0 || fromPC || !props.showRadio ? (
                  <div
                    className="upload_doc_div_body"
                    onClick={() => changeDoc()}
                  >
                    <input
                      type="file"
                      accept="image/jpeg,image/jpg,image/png,.pdf,application/pdf"
                      vb
                      style={{ display: "none" }}
                      ref={ref}
                      onChange={(e) => onDocChange(e, props.docName)}
                    />

                    {!props.uploadedDoc[props.docName] ? (
                      <img
                        src={uploadHere}
                        alt="add"
                        className="upload_doc_div_body_img2"
                      />
                    ) : (
                      props.uploadedDoc[props.docName].name
                    )}
                  </div>
                ) : (
                  <div className="step4_chooseDoc_dropdown">
                    {data.length > 0 && (
                      <DropDown
                        title="Choose a document"
                        placeholder="Choose a document"
                        setSelected={(e) => chooseDocument(e, props.docName)}
                        data={data}
                        blueArrow={true}
                        width="100%"
                        multiSelection={false}
                        searchable={true}
                        selectedData={props.chosenDoc[props.docName]}
                        value={props.chosenDoc[props.docName]}
                      />
                    )}
                  </div>
                )}
                <Button
                  text="Save"
                  color="yellow"
                  width="100%"
                  onClick={() => {
                    props.onSubmit();
                    props.setShow(false);
                    setFromPC(false);
                  }}
                />
              </div>
            )}
          </>
        }
        width="550px"
      />
    </div>
  );
}

export default UploadDoc;
