import { axiosReq } from "../../../Utils";
import { DocumentsActions } from "./DocumentsReducers";

export const getDocs = () => async (dispatch) => {
  dispatch(DocumentsActions.fetchDocsRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/student/uploadedDocuments",
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(DocumentsActions.fetchDocsSuccess(res.data));
    } else {
      dispatch(DocumentsActions.fetchDocsFail(res.data.response.data.message));
    }
  });
};

export const deleteDoc = (id, docid, getmiss) => async (dispatch) => {
  dispatch(DocumentsActions.deleteDocRequest());
  dispatch(
    axiosReq(
      "delete",
      process.env.REACT_APP_API + "/student/uploadedDocument/" + id,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(DocumentsActions.deleteDocSuccess(res.data));
      dispatch(getDocs());
      if (getmiss) {
        dispatch(getmissingDocs(docid));
        dispatch(getDocs());
      }
    } else {
      dispatch(DocumentsActions.deleteDocFail(res.data.response.data.message));
    }
  });
};

export const getmissingDocs = (id) => async (dispatch) => {
  dispatch(DocumentsActions.missingDocRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/application/missingDocuments/" + id,
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(DocumentsActions.missingDocSuccess(res.data));
    } else {
      dispatch(DocumentsActions.missingDocFail(res.data.response.data.message));
    }
  });
};
export const newuploadmissingDocs = (app_id, data, id) => async (dispatch) => {
  dispatch(DocumentsActions.uploadmissingDocRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + `/application/${app_id}/documents/`,
      {},
      data
    )
  ).then((res) => {
    if (res.success) {
      dispatch(DocumentsActions.uploadmissingDocSuccess(res.data));
      dispatch(getmissingDocs(id));
      dispatch(getDocs());
    } else {
      dispatch(
        DocumentsActions.uploadmissingDocFail(res.data.response.data.message)
      );
    }
  });
};
export const uploadmissingDocs =
  (app_id, doc_id, data, id) => async (dispatch) => {
    dispatch(DocumentsActions.uploadmissingDocRequest());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API +
          `/application/${app_id}/documents/${doc_id}`,
        {},
        data
      )
    ).then((res) => {
      if (res.success) {
        dispatch(DocumentsActions.uploadmissingDocSuccess(res.data));
        dispatch(getmissingDocs(id));
        dispatch(getDocs());
      } else {
        dispatch(
          DocumentsActions.uploadmissingDocFail(res.data.response.data.message)
        );
      }
    });
  };
