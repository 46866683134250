import React, { useEffect, useState } from "react";
import "./SalesUniversities.scss";
import AddIcon from "../../../Shared/Assets/Images/add.png";
import DropDown from "../../../Shared/Components/DropDown/DropDown";
import Popup from "../../../Shared/Components/Popup/Popup";
import location from "../../../Shared/Assets/Images/location.webp";
import checked from "../../../Shared/Assets/Images/checked.png";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import unchecked from "../../../Shared/Assets/Images/unchecked.png";
import Input from "../../../Shared/Components/Input/Input";
import Button from "../../../Shared/Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getdirectpartnerships,
  createdirectpartnership,
  getpathwayproviders,
  createpathwayproviders,
} from "../../Redux/Universities/UniversitiesActions";
import Loader from "../../../Shared/Components/Loader/Loader";
function SalesUniversities() {
  const dispatch = useDispatch();
  const [sortBy, setsortBy] = useState("");
  const [sortBy2, setsortBy2] = useState("");
  useEffect(() => {
    dispatch(getdirectpartnerships(sortBy.value));
    dispatch(getpathwayproviders(sortBy2.value));
  }, [sortBy, sortBy2]);
  const { DirectPath, loading, CreateDirectLoading, PathwayProviders } =
    useSelector((state) => state.UniversitiesSaleReducer);

  const sortList = [
    { value: "I_a", label: "Institutions Asc" },
    { value: "I_d", label: "Institutions Desc" },
    { value: "d_a", label: "Date Ascending" },
    { value: "d_d", label: "Date Descending" },
  ];
  const keys = [
    "Country",
    "Institutions",
    "Online Programs",
    "Tuition",
    "Scholarships",
    "Deadline",
    "Application Fee",
  ];
  const keys2 = [
    "Country",
    "City",
    "Institutions",
    "Online Programs",
    "Medicine",
    "Search Tool",
    "Provider",
    "Contact",
  ];
  const [Showpop, setShowpop] = useState(false);
  const [Showpop2, setShowpop2] = useState(false);
  const [Showpop3, setShowpop3] = useState(false);
  //states for the add popup
  const [uniName, setUniName] = useState("");
  const [Institutions, setInstitutions] = useState("");
  const [Tuition, setTuition] = useState("");
  const [Deadline, setDeadline] = useState("");
  const [Applicationfees, setApplicationfees] = useState("");
  const [OnlinePrograms, setOnlinePrograms] = useState(false);
  const [Scholarships, setScholarships] = useState(false);
  const [Representativescon, setRepresentativescon] = useState("");
  const [ApplicationProcess, setApplicationProcess] = useState("");
  const [livingexpense, setlivingexpense] = useState("");
  const [maxlivingexpense, setmaxlivingexpense] = useState("");
  const [majors, setmajors] = useState("");
  const [description, setdescription] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [Possibilitytransfer, setPossibilitytransfer] = useState(false);
  const [tempdata, settempdata] = useState("");
  const [link, setlink] = useState("");
  const [entryreq, setentryreq] = useState("");
  const [transferdep, settransferdep] = useState("");
  //states for add pathway provider
  const [uniName2, setUniName2] = useState("");
  const [country2, setcountry2] = useState("");
  const [city2, setcity2] = useState("");
  const [medicine, setmedicine] = useState(false);
  const [searchtool, setsearchtool] = useState("");
  const [provider, setprovider] = useState("");
  const [contact2, setcontact2] = useState("");
  const [commision, setcommision] = useState("");
  const [applicationprocedure, setapplicationprocedure] = useState("");
  const [onlineprog2, setonlineprog2] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const handlecreatedirectpartnership = () => {
    const data = {
      sales: user.id,
      country: country,
      city: city,
      institution: Institutions,
      description: description,
      majors: majors,
      tuition: parseInt(Tuition),
      min_monthly_expenses: parseInt(livingexpense),
      max_monthly_expenses: parseInt(maxlivingexpense),
      scholarship: Scholarships,
      entry_requirements: entryreq,
      possibility_of_transfer: Possibilitytransfer,
      transfer_dependency: transferdep,
      deadline: Deadline,
      application_fee: parseInt(Applicationfees),
      application_process: ApplicationProcess,
      representative: Representativescon,
      link: link,
      online_program: OnlinePrograms,
    };
    dispatch(createdirectpartnership(data));
    setcountry("");
    setcity("");
    setInstitutions("");
    setdescription("");
    setmajors("");
    setTuition("");
    setlivingexpense("");
    setmaxlivingexpense("");
    setScholarships(false);
    setentryreq("");
    setPossibilitytransfer(false);
    settransferdep("");
    setDeadline("");
    setApplicationfees("");
    setApplicationProcess("");
    setRepresentativescon("");
    setlink("");
    setOnlinePrograms(false);

    setShowpop2(false);
  };

  const handlecreatepathwayprovider = () => {
    const data = {
      sales: user.id,
      provider: provider,
      institution: uniName2,
      commission: commision,
      country: country2,
      city: city2,
      medecine: medicine,
      search_tool: searchtool,
      application_procedure: applicationprocedure,
      contact: contact2,
      online_program: onlineprog2,
    };
    dispatch(createpathwayproviders(data));
    setcountry2("");
    setcity2("");
    setUniName2("");
    setmedicine(false);
    setsearchtool("");
    setprovider("");
    setcontact2("");
    setcommision("");
    setapplicationprocedure("");
    setonlineprog2(false);
    setShowpop3(false);
  };
  return (
    <>
      {loading || CreateDirectLoading ? (
        <>
          {" "}
          <Loader fullScreen={true} />
        </>
      ) : (
        <div className="Main_sales_universities">
          <div className="Main_sales_uni_container">
            <div className="Universities_sales_head_cont">
              <div
                className="titles_cont_sales"
                onClick={() => setShowpop2(!Showpop2)}
              >
                Direct Partnerships{" "}
                <img src={AddIcon} className="icon_add_sales" />
              </div>
              {DirectPath.length !== 0 && (
                <DropDown
                  data={sortList}
                  placeholder={"Sort By"}
                  blueArrow={true}
                  width={"13rem"}
                  searchable={false}
                  setSelected={setsortBy}
                  selectedData={sortBy}
                  value={sortBy}
                />
              )}
            </div>
            {DirectPath.length !== 0 ? (
              <div className="Universities_sales_body_cont">
                <table className="SalesTable_main_div">
                  {keys && (
                    <tr className="userTable_header">
                      {keys.map((key, index) => (
                        <th key={index}>{key}</th>
                      ))}
                    </tr>
                  )}
                  {DirectPath &&
                    DirectPath.map((cats) => {
                      const data = [
                        {
                          Country: cats.country,
                          Institutions: cats.institution,
                          online: cats.online_program ? "Yes" : "No",
                          Tuition: cats.tuition + " $/Course",
                          Scholarships: cats.scholarship ? "Yes" : "No",
                          Deadline: cats.deadline,
                          Application: cats.application_fee + " $",
                        },
                      ];

                      return data.map((cat, index) => {
                        return (
                          <tr
                            key={index}
                            className={`userTable_details`}
                            onClick={() => {
                              setShowpop(true);
                              settempdata(cats);
                            }}
                          >
                            {Object.keys(cat).map((el) => (
                              <td
                                className={`${
                                  el === "Country" ? "Boldfont" : ""
                                }`}
                              >
                                {cat[el]}
                              </td>
                            ))}
                          </tr>
                        );
                      });
                    })}
                </table>
              </div>
            ) : (
              <>
                <div className="text_direct">No Data Available</div>
              </>
            )}

            {Showpop && (
              <Popup
                setShowPopup={setShowpop}
                // showClose={true}
                body={
                  <div className="popup_body_uni">
                    <div className="popup_head">
                      <div className="popup_body_title">
                        {tempdata?.institution}
                      </div>
                      <div className="popup_location">
                        <img src={location} className="location_icon" />
                        <div className="location_text">
                          {tempdata?.city + "," + tempdata?.country}
                        </div>
                      </div>
                    </div>
                    <div className="popup_subhead">{tempdata?.description}</div>
                    <div className="popup_body_conts_all">
                      <div className="popup_body_conts">
                        <div className="popup_data_cont">
                          <div className="popup_data_title">Notable Majors</div>
                          <div className="popup_data_value">
                            {tempdata?.majors}
                          </div>
                          {/* <div className="popup_data_value">Multimedia</div> */}
                        </div>
                        <div className="popup_data_cont">
                          <div className="popup_data_title"> Tuition</div>
                          <div className="popup_data_value">
                            {" "}
                            {tempdata?.tuition} $ /Course
                          </div>
                        </div>
                        <div className="popup_data_cont">
                          <div className="popup_data_title">
                            {" "}
                            Living Expenses
                          </div>
                          <div className="popup_data_value">
                            {" "}
                            {tempdata?.min_monthly_expenses +
                              "-" +
                              tempdata?.min_monthly_expenses}{" "}
                            / month
                          </div>
                        </div>
                      </div>
                      <div className="popup_body_conts">
                        <div className="popup_data_cont">
                          <div className="popup_data_title">
                            Entry Requirements
                          </div>
                          <div className="popup_data_value">
                            {tempdata?.entry_requirements}
                          </div>
                          {/* <div className="popup_data_value">
                            Local Curriculum: High School Degree
                          </div> */}
                        </div>
                        <div className="popup_data_cont">
                          <div className="popup_data_title">
                            {" "}
                            Representatives/Contact
                          </div>
                          <div className="popup_data_value">
                            {" "}
                            {tempdata?.representative}
                          </div>
                        </div>
                        <div className="popup_data_cont">
                          <div className="popup_data_title">
                            {" "}
                            Application Process
                          </div>
                          <div className="popup_data_value">
                            {tempdata?.application_process}
                          </div>
                        </div>
                      </div>
                      <div className="popup_body_conts">
                        <div className="popup_data_cont">
                          <div className="popup_data_title">
                            Application Fee
                          </div>
                          <div className="popup_data_value">
                            {tempdata?.application_fee}$
                          </div>
                        </div>
                        <div className="popup_data_cont">
                          <div className="popup_data_title">Deadline</div>
                          <div className="popup_data_value">
                            {" "}
                            {tempdata?.deadline}
                          </div>
                        </div>
                        <div className="popup_data_cont">
                          <div className="popup_icon_text">
                            <img
                              src={
                                tempdata?.online_program ? checked : unchecked
                              }
                              className="popup_icon"
                            />
                            Online Programs
                          </div>
                          <div className="popup_icon_text">
                            <img
                              src={tempdata?.scholarship ? checked : unchecked}
                              className="popup_icon"
                            />
                            Scholarship
                          </div>
                          <div className="popup_icon_text">
                            <img
                              src={
                                tempdata?.possibility_of_transfer
                                  ? checked
                                  : unchecked
                              }
                              className="popup_icon"
                            />
                            Possibility of Transfer
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="popup_footer">
                      <div className="popup_data_title">Link</div>{" "}
                      {tempdata?.link}
                    </div>
                  </div>
                }
              />
            )}
            {Showpop2 && (
              <Popup
                // showClose={true}
                setShowPopup={setShowpop2}
                body={
                  <div className="Main_add_partnerships">
                    <div className="popup_head">
                      <div className="popup_body_title">
                        {" "}
                        Add Direct Partnerships
                      </div>
                    </div>
                    <div className="popup_body">
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile">Country </div>

                          <Input
                            value={country}
                            onChange={(e) => setcountry(e.target.value)}
                            width={"100%"}
                            placeholder="Country Name"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">City </div>

                          <Input
                            value={city}
                            onChange={(e) => setcity(e.target.value)}
                            width={"100%"}
                            placeholder="City Name"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Instiution Name</div>

                          <Input
                            value={Institutions}
                            onChange={(e) => setInstitutions(e.target.value)}
                            width={"100%"}
                            placeholder="Instiution Name"
                          />
                        </div>
                      </div>

                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile"> Deadline</div>

                          <Input
                            value={Deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                            width={"100%"}
                            placeholder="Deadline"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile"> Application Process</div>

                          <Input
                            value={ApplicationProcess}
                            onChange={(e) =>
                              setApplicationProcess(e.target.value)
                            }
                            width={"100%"}
                            placeholder="Application Process"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Application Fees</div>

                          <Input
                            value={Applicationfees}
                            onChange={(e) => setApplicationfees(e.target.value)}
                            width={"100%"}
                            placeholder="Application Fees"
                          />
                        </div>
                      </div>
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile"> Entry Requirements</div>

                          <Input
                            value={entryreq}
                            onChange={(e) => setentryreq(e.target.value)}
                            width={"100%"}
                            placeholder="Entry Requirements"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile"> Transfer Dependency</div>

                          <Input
                            value={transferdep}
                            onChange={(e) => settransferdep(e.target.value)}
                            width={"100%"}
                            placeholder="Transfer Dependency"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Link</div>

                          <Input
                            value={link}
                            onChange={(e) => setlink(e.target.value)}
                            width={"100%"}
                            placeholder="Link"
                          />
                        </div>
                      </div>
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile">Tuition Fees</div>

                          <Input
                            value={Tuition}
                            onChange={(e) => setTuition(e.target.value)}
                            width={"100%"}
                            placeholder="Tuition Fees"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile"> Min Living Expenses</div>

                          <Input
                            value={livingexpense}
                            onChange={(e) => setlivingexpense(e.target.value)}
                            width={"100%"}
                            type={"number"}
                            placeholder=" Min Living Expenses /month  "
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile"> Max Living Expenses</div>

                          <Input
                            value={maxlivingexpense}
                            onChange={(e) =>
                              setmaxlivingexpense(e.target.value)
                            }
                            type={"number"}
                            width={"100%"}
                            placeholder="Max Living Expenses /month  "
                          />
                        </div>

                        {/* <div className="input_div">
                <Button
                  text={"Add"}
                  width={"100%"}
                  color={"pink"}
                  onClick={() => setShowpop2(false)}
                />
              </div> */}
                      </div>
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile">Majors </div>

                          <Input
                            value={majors}
                            onChange={(e) => setmajors(e.target.value)}
                            width={"100%"}
                            placeholder="Majors Name"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Description </div>

                          <Input
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            width={"100%"}
                            placeholder="description"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">
                            {" "}
                            Representatives Contacts
                          </div>

                          <Input
                            value={Representativescon}
                            onChange={(e) =>
                              setRepresentativescon(e.target.value)
                            }
                            width={"100%"}
                            placeholder="Representatives Contacts"
                          />
                        </div>
                      </div>
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile"> Scholarships</div>
                          <div className="input_radio_cont">
                            <Checkbox
                              label="Yes"
                              selected={Scholarships ? true : false}
                              click={true}
                              onClick={() => {
                                setScholarships(true);
                              }}
                            />
                            <Checkbox
                              label="No"
                              selected={Scholarships ? false : true}
                              click={true}
                              onClick={() => {
                                setScholarships(false);
                              }}
                            />
                          </div>
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Online Programs</div>
                          <div className="input_radio_cont">
                            <Checkbox
                              label="Yes"
                              selected={OnlinePrograms ? true : false}
                              click={true}
                              onClick={() => {
                                setOnlinePrograms(true);
                              }}
                            />
                            <Checkbox
                              label="No"
                              selected={OnlinePrograms ? false : true}
                              click={true}
                              onClick={() => {
                                setOnlinePrograms(false);
                              }}
                            />
                          </div>
                        </div>
                        <div className="input_div">
                          <div className="input_tile">
                            Possibility of Transfer
                          </div>
                          <div className="input_radio_cont">
                            <Checkbox
                              label="Yes"
                              selected={Possibilitytransfer ? true : false}
                              click={true}
                              onClick={() => {
                                setPossibilitytransfer(true);
                              }}
                            />
                            <Checkbox
                              label="No"
                              selected={Possibilitytransfer ? false : true}
                              click={true}
                              onClick={() => {
                                setPossibilitytransfer(false);
                              }}
                            />
                          </div>
                        </div>

                        {/* <div className="input_div">
                <Button
                  text={"Add"}
                  width={"100%"}
                  color={"pink"}
                  onClick={() => setShowpop2(false)}
                />
              </div> */}
                      </div>

                      <div className="inpt_cont_direct_last">
                        <div className="input_div_butt">
                          <Button
                            text={"Add"}
                            width={"100%"}
                            color={"pink"}
                            disabled={
                              country === "" ||
                              city === "" ||
                              Institutions === "" ||
                              Deadline === "" ||
                              ApplicationProcess === "" ||
                              Applicationfees === "" ||
                              Tuition === "" ||
                              livingexpense === "" ||
                              maxlivingexpense === "" ||
                              majors === "" ||
                              description === "" ||
                              Representativescon === "" ||
                              transferdep === "" ||
                              link === ""
                                ? true
                                : false
                            }
                            onClick={() => handlecreatedirectpartnership()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            {Showpop3 && (
              <Popup
                // showClose={true}
                setShowPopup={setShowpop3}
                body={
                  <div className="Main_add_partnerships">
                    <div className="popup_head">
                      <div className="popup_body_title">
                        {" "}
                        Add Pathway Providers
                      </div>
                    </div>
                    <div className="popup_body">
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile">University Name</div>

                          <Input
                            value={uniName2}
                            onChange={(e) => setUniName2(e.target.value)}
                            width={"100%"}
                            placeholder="University Name"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Country </div>

                          <Input
                            value={country2}
                            onChange={(e) => setcountry2(e.target.value)}
                            width={"100%"}
                            placeholder="Country Name"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">City</div>

                          <Input
                            value={city2}
                            onChange={(e) => setcity2(e.target.value)}
                            width={"100%"}
                            placeholder="City Name"
                          />
                        </div>
                      </div>
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile"> Search Tool</div>

                          <Input
                            value={searchtool}
                            onChange={(e) => setsearchtool(e.target.value)}
                            width={"100%"}
                            placeholder="Search Tool"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Provider </div>

                          <Input
                            value={provider}
                            onChange={(e) => setprovider(e.target.value)}
                            width={"100%"}
                            placeholder="Provider "
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">Contact </div>

                          <Input
                            value={contact2}
                            onChange={(e) => setcontact2(e.target.value)}
                            width={"100%"}
                            placeholder="Contact"
                          />
                        </div>
                      </div>
                      <div className="inpt_cont_direct">
                        <div className="input_div">
                          <div className="input_tile"> Commision </div>

                          <Input
                            value={commision}
                            onChange={(e) => setcommision(e.target.value)}
                            width={"100%"}
                            placeholder="Commision"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile">
                            {" "}
                            Application Procedure{" "}
                          </div>

                          <Input
                            value={applicationprocedure}
                            onChange={(e) =>
                              setapplicationprocedure(e.target.value)
                            }
                            width={"100%"}
                            placeholder="Application Procedure"
                          />
                        </div>
                        <div className="input_div">
                          <div className="input_tile"> Medicine</div>
                          <div className="input_radio_cont">
                            <Checkbox
                              label="Yes"
                              selected={medicine ? true : false}
                              click={true}
                              onClick={() => {
                                setmedicine(true);
                              }}
                            />
                            <Checkbox
                              label="No"
                              selected={medicine ? false : true}
                              click={true}
                              onClick={() => {
                                setmedicine(false);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inpt_cont_last">
                        <div className="input_div">
                          <div className="input_tile">Online Programs</div>
                          <div className="input_radio_cont">
                            <Checkbox
                              label="Yes"
                              selected={onlineprog2 ? true : false}
                              click={true}
                              onClick={() => {
                                setonlineprog2(true);
                              }}
                            />
                            <Checkbox
                              label="No"
                              selected={onlineprog2 ? false : true}
                              click={true}
                              onClick={() => {
                                setonlineprog2(false);
                              }}
                            />
                          </div>
                        </div>
                        <div className="input_div_butt">
                          <Button
                            disabled={
                              uniName2 === "" ||
                              country2 === "" ||
                              city2 === "" ||
                              searchtool === "" ||
                              provider === "" ||
                              contact2 === "" ||
                              commision === "" ||
                              applicationprocedure === ""
                                ? true
                                : false
                            }
                            text={"Add"}
                            width={"100%"}
                            color={"pink"}
                            onClick={() => handlecreatepathwayprovider()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
          </div>
          <div className="Main_sales_uni_container">
            <div className="Universities_sales_head_cont">
              <div
                className="titles_cont_sales"
                onClick={() => setShowpop3(!Showpop3)}
              >
                Pathway Providers{" "}
                <img src={AddIcon} className="icon_add_sales" />
              </div>
              {PathwayProviders.length !== 0 && (
                <DropDown
                  data={sortList}
                  placeholder={"Sort By"}
                  blueArrow={true}
                  width={"13rem"}
                  searchable={false}
                  setSelected={setsortBy2}
                  selectedData={sortBy2}
                  value={sortBy2}
                />
              )}
            </div>
            {PathwayProviders.length !== 0 ? (
              <div className="Universities_sales_body_cont_2">
                <table className="SalesTable_main_div">
                  {keys2 && (
                    <tr className="userTable_header">
                      {keys2.map((key, index) => (
                        <th key={index}>{key}</th>
                      ))}
                    </tr>
                  )}
                  {PathwayProviders &&
                    PathwayProviders.map((cats) => {
                      const data = [
                        {
                          Country: cats.country,
                          City: cats.city,
                          Institutions: cats.institution,
                          online: cats.online_program ? "Yes" : "No",
                          Medicine: cats.medecine ? "Yes" : "No",
                          Search_tool: cats.search_tool,
                          Provider: cats.provider,
                          Contact: cats.contact,
                        },
                      ];

                      return data.map((cat, index) => {
                        return (
                          <tr key={index} className={`userTable_details`}>
                            {Object.keys(cat).map((el) => (
                              <td
                                className={`${
                                  el === "Country" ? "Boldfont" : ""
                                }`}
                              >
                                {cat[el]}
                              </td>
                            ))}
                          </tr>
                        );
                      });
                    })}
                </table>
              </div>
            ) : (
              <>
                <div className="text_direct">No Data Available</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SalesUniversities;
