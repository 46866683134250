import React from "react";
import "./CardCarousel.scss";
import arrowLeft from "../../Assets/Images/arrow1.webp";
import arrowRight from "../../Assets/Images/arrow2.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function CardCarousel({ cards, responsive, cardLength, nbOfCards,screenwidth }) {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="catgUni_main_div_card_pagination">
        <img
          src={arrowLeft}
          alt="arrow"
          className={currentSlide === 0 ? "arrow_img_disable" : "arrow_img"}
          onClick={() => goToSlide(currentSlide - nbOfCards)}
        />
        {nbOfCards + currentSlide >= cardLength ? (
          <> {cardLength + " of " + cardLength}</>
        ) : (
          <> {nbOfCards + currentSlide + " of " + cardLength}</>
        )}
        <>
          <img
            src={arrowRight}
            alt="arrow"
            className={
              nbOfCards + currentSlide >= cardLength
                ? "arrow_img_disable"
                : "arrow_img"
            }
            onClick={() => goToSlide(currentSlide + nbOfCards)}
          />
        </>
      </div>
    );
  };
  return (
    <Carousel
      renderButtonGroupOutside={true}
      swipeable={screenwidth < 550 ? true : false}
      autoPlay={false}
      draggable={false}
      autoPlaySpeed={0}
      containerClass="container-with-dots"
      transitionDuration={0}
      className="catgUni_main_div_card_body"
      infinite={false}
      customButtonGroup={<ButtonGroup />}
      showDots={screenwidth < 550 ? true : false}
      removeArrowOnDeviceType={[
        "superLargeDesktop",
        "desktop",
        "tablet",
        "mobile",
      ]}
      responsive={responsive}
      partialVisible={false}
    >
      {cards}
    </Carousel>
  );
}

export default CardCarousel;
