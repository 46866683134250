/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Leads: [],
  CreatedLeads: [],
  Sources: [],
  Quialified: [],
  UploadLeads: [],
  loading: false,
  error: "",
  createleaderror: "",
  createleadSuccuss: false,
  LoadingUpdateLeads: false,
  LoadingCreateLeads: false,
  LoadingSources: false,
  QuialifiedLeadsLoading: false,
  UploadLeadsLoading: false,

  //delete lead
  LoadingDeleteLeads: false,
  DeleteLeadsError: "",
  DeleteLeadsSuccess: false,

  //asign lead
  LoadingAssignLeads: false,
  AssignLeadsError: "",
  AssignLeadsSuccess: false,

};

const LeadsSlice = createSlice({
  name: "Leads",
  initialState,
  reducers: {
    LeadsRequest(state) {
      state.loading = true;
      state.Leads = [];

      state.error = "";
    },
    LeadsSuccess(state, action) {
      state.loading = false;
      state.Leads = action.payload;
      state.error = "";
    },
    LeadsFail(state, action) {
      state.loading = false;
      state.Leads = [];

      state.error = action.payload;
    },
    updateLeadReq(state, action) {
      state.LoadingUpdateLeads = true;
      state.error = "";
    },
    updateLeadSuccess(state, action) {
      state.LoadingUpdateLeads = false;
      state.error = "";
    },
    updateLeadFail(state, action) {
      state.LoadingUpdateLeads = false;
      state.error = action.payload;
    },
    createleadsReq(state) {
      state.LoadingCreateLeads = true;
      state.createleaderror = "";
      state.createleadSuccuss = false;
    },
    createleadsSuccess(state, action) {
      state.LoadingCreateLeads = false;
      state.CreatedLeads = action.payload;
      state.createleaderror = "";
      state.createleadSuccuss = true;
    },
    createleadsFail(state, action) {
      state.LoadingCreateLeads = false;
      state.createleaderror = action.payload;
      state.createleadSuccuss = false;
    },
    getsourcesReq(state) {
      state.LoadingSources = true;
      state.error = "";
    },
    getsourcesSuccess(state, action) {
      state.LoadingSources = false;
      state.Sources = action.payload;
      state.error = "";
    },
    getsourcesFail(state, action) {
      state.LoadingSources = false;
      state.error = action.payload;
    },
    QualifiedLeadsRequest(state) {
      state.QuialifiedLeadsLoading = true;
      state.error = "";
    },
    QualifiedLeadsSuccess(state, action) {
      state.QuialifiedLeadsLoading = false;
      state.Quialified = action.payload;
      state.error = "";
    },
    QualifiedLeadsFail(state, action) {
      state.QuialifiedLeadsLoading = false;
      state.error = action.payload;
    },
    UploadLeadsReq(state) {
      state.UploadLeadsLoading = true;
      state.error = "";
    },
    UploadLeadsSuccess(state, action) {
      state.UploadLeadsLoading = false;
      state.UploadLeads = action.payload;
      state.error = "";
    },
    UploadLeadsFail(state, action) {
      state.UploadLeadsLoading = false;
      state.error = action.payload;
    },
    //delete lead
    DeleteLeadsReq(state) {
      state.LoadingDeleteLeads = true;
      state.DeleteLeadsError = "";
    },
    DeleteLeadsSuccess(state, action) {
      state.LoadingDeleteLeads = false;
      state.DeleteLeadsSuccess = true;
      state.DeleteLeadsError = "";
    },
    DeleteLeadsFail(state, action) {
      state.LoadingDeleteLeads = false;
      state.DeleteLeadsError = action.payload;
    },

    //asign lead
    AssignLeadsReq(state) {
      state.LoadingAssignLeads = true;
      state.AssignLeadsError = "";
      state.AssignLeadsSuccess = false;

    },
    AssignLeadsSuccess(state, action) {
      state.LoadingAssignLeads = false;
      state.AssignLeadsSuccess = true;
      state.AssignLeadsError = "";
    },
    AssignLeadsFail(state, action) {
      state.LoadingAssignLeads = false;
      state.AssignLeadsError = action.payload;
      state.AssignLeadsSuccess = false;

    },

  },
});

export const LeadsActions = LeadsSlice.actions;
export default LeadsSlice;
