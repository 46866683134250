import React, { useEffect, useState } from "react";
import "./OBStep5.scss";
import Button from "../../../../Shared/Components/Button/Button";
import FormLayout from "../../../../Shared/Components/FormLayout/FormLayout";

import Diamond from "../../../../Shared/Components/Animations/Diamond/Diamond";
import DropDown from "../../../../Shared/Components/DropDown/DropDown";
import OBStep6 from "../OBStep6/OBStep6";
import { useDispatch, useSelector } from "react-redux";
import { getAllCont } from "../../../../Shared/Redux/Location/LocationsActions";
function OBStep5({ step, setStep, student, setStudent, Countries, counselor }) {
  const [inCountry, setInCountry] = useState("");

  const onSubmit = () => {
    const stInC = inCountry.map((el) => el.value);
    setStudent({ ...student, location_of_study: stInC });
    if (inCountry.length != 0) setStep(6);
  };

  return (
    <div className="obStep_main_div">
      {step == 5 ? (
        <FormLayout
          back={() => setStep(4)}
          showSteps={true}
          step={step}
          totalSteps={4}
          header={
            counselor
              ? "Choose country to study in"
              : "Where do you want to study?"
          }
          subHeader="You can choose up to 5 countries"
          minHeight="fit-content"
          width="90%"
          body={
            <div className="step_body">
              {Countries && (
                <DropDown
                  placeholder="Choose country "
                  setSelected={setInCountry}
                  data={Countries}
                  blueArrow={true}
                  width="100%"
                  multiSelection={true}
                  isClearable={false}
                  searchable={false}
                  keepPlaceHolder={true}
                  isDisabled={inCountry.length == 5 && true}
                  selectedData={inCountry}
                />
              )}
              <Button
                text="Continue"
                color="lightGreen"
                width="100%"
                onClick={() => onSubmit()}
                disabled={inCountry.length == 0 && true}
              />
            </div>
          }
          icon={<Diamond />}
          bg="var(--pink)"
          gap="20px"
        />
      ) : (
        <OBStep6
        counselor={counselor}
          step={step}
          setStep={setStep}
          student={student}
          setStudent={setStudent}
        />
      )}
    </div>
  );
}

export default OBStep5;
