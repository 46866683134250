import React from 'react'
import './CreateApplication.scss'
import ApplicationStep1 from '../../../Shared/Pages/Application/Application1/ApplicationStep1'
function CreateApplication() {
  return (
    <div>
        <ApplicationStep1 counselor={true}/>
    </div>
  )
}

export default CreateApplication