import React from "react";
import "./Footer.scss";
import logo from "../../Assets/Images/logo.webp";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
  return (
    <div className="footer_main_div">
      <div className="footer_div">
        <div className="footer_div11">
          <div className="footer_div1">
            <img src={logo} className="footer_logo" alt="logo" />
            <div className="footer_div1_socials">
              <div className="footer_div1_socials1">
                <BsInstagram
                
                onClick={()=>window.open("https://www.instagram.com/educationbasket/")}
                  className="footer_div1_socials1_icon"
                  size={"1.5rem"}
                />
                <ImFacebook2
                onClick={()=>window.open("https://www.facebook.com/educationbasket/")}

                  className="footer_div1_socials1_icon"
                  size={"1.5rem"}
                />
                <BsLinkedin
                onClick={()=>window.open("https://www.linkedin.com/company/education-basket/")}

                  className="footer_div1_socials1_icon"
                  size={"1.5rem"}
                />
              </div>
              <div className="footer_div1_socials2">
                Copyright 2023 Education Basket Kuwait Company for Conferences and Exhibitions , Developed by Stitches Studios
              </div>
            </div>
          </div>
        </div>

        <div className="footer_div22">
          <div className="footer_div2">
            <div className="footer_div2_1">
              <div className="footer_div2_1_heading">Contact Us</div>
              <div>
                <a href="tel:+96171202358" className="footer_div2_1_content">
                  Lebanon: +961 71 202 358
                </a>
              </div>
              <div>
                <a href="tel:+201014589131" className="footer_div2_1_content">
                  Egypt: +20 101 458 9131
                </a>
              </div>
              <div>
                <a href="tel:+97433111667" className="footer_div2_1_content">
                  Qatar: +974 3311 1667
                </a>
              </div>
              <div>
                <a href="tel:+96565756205" className="footer_div2_1_content">
                  Kuwait: +965 65756205
                </a>
              </div>
            </div>
            <div className="footer_div2_1">
              <div className="footer_div2_1_heading">Helpful Links</div>
              <div className="footer_div2_1_content" onClick={()=>navigate("/privacyPolicy")}>Privacy Policy</div>
              <div className="footer_div2_1_content" onClick={()=>navigate("/termsAndConditions")}>Terms & Conditions</div>
              <div className="footer_div2_1_content"  onClick={()=>navigate("/contactUs")}>Contact Us</div>
            </div>{" "}
            <div className="footer_div2_1">
              <div className="footer_div2_1_heading">Email Us At</div>
              <div>
                <a className="footer_div2_1_content" href="mailto:info@educationbasket.com"> 
                  info@educationbasket.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
