import { axiosReq } from "../../../Utils";
import { LeadsActions } from "./LeadsReducers";

export const getLeads =
  (status, sortBy, limit, offset, search, filter) => async (dispatch) => {
    const url = process.env.REACT_APP_API + "/sales/leads/view?";
    dispatch(LeadsActions.LeadsRequest());
    dispatch(
      axiosReq(
        "get",
        url,
        {},
        {},
        {
          status: status ? status : "New,Qualified,Contacted,Unqualified",
          sortBy: sortBy,
          limit: limit,
          offset: offset,
          search: search,
          assignedTo: filter?.assigned?.map((el) => el.value).join(","),
          contactedBy: filter?.contacted?.map((el) => el.value).join(","),
          country: filter?.countries?.map((el) => el.label).join(","),
          education: filter?.education?.map((el) => el.label).join(","),
          budgetLow: filter?.budget?.value[0],
          budgetHigh: filter?.budget?.value[1] && filter?.budget?.value[1],
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(LeadsActions.LeadsSuccess(res.data));
      } else {
        dispatch(LeadsActions.LeadsFail(res.data.response.data.message));
      }
    });
  };

export const updateLeadStatus =
  (lead, sortBy, limit, offset, search, data) => async (dispatch) => {
    dispatch(LeadsActions.updateLeadReq());
    dispatch(
      axiosReq(
        "patch",
        process.env.REACT_APP_API + "/sales/leads/status",
        {},
        lead
      )
    ).then((res) => {
      if (res.success) {
        dispatch(LeadsActions.updateLeadSuccess(res.data));
        dispatch(getLeads("", sortBy, limit, offset, search, data));
      } else
        dispatch(LeadsActions.updateLeadFail(res.data.response.data.message));
    });
  };

export const updateLeadsNotes =
  (comment, id, sortBy, limit, offset, search, data) => (dispatch) => {
    dispatch(LeadsActions.updateLeadReq());
    dispatch(
      axiosReq(
        "patch",
        process.env.REACT_APP_API + `/sales/lead/${id}`,
        {},
        { notes: comment }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(LeadsActions.updateLeadSuccess(res.data));
        dispatch(getLeads("", sortBy, limit, offset, search, data));
      } else
        dispatch(LeadsActions.updateLeadFail(res.data.response.data.message));
    });
  };
export const CreateNewLeads = (leads) => (dispatch) => {
  dispatch(LeadsActions.createleadsReq());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/sales/lead", {}, leads)
  ).then((res) => {
    if (res.success) {
      dispatch(LeadsActions.createleadsSuccess(res.data));
      dispatch(getLeads("", "", 10, 0));
    } else {
      dispatch(LeadsActions.createleadsFail(res.data.response.data.message));
    }
  });
};
export const getsources = () => async (dispatch) => {
  dispatch(LeadsActions.getsourcesReq());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/referal/sources", {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(LeadsActions.getsourcesSuccess(res.data));
    } else
      dispatch(LeadsActions.getsourcesFail(res.data.response.data.message));
  });
};
// export const getqualifiedleads =
//   (sortBy, limit, offset, search) => async (dispatch) => {
//     const url = process.env.REACT_APP_API + "/sales/leads/view";

//     dispatch(LeadsActions.QualifiedLeadsRequest());
//     dispatch(
//       axiosReq(
//         "get",
//         url,
//         {},
//         {},
//         {
//           status: "Qualified",
//           sortBy: sortBy,
//           limit: limit,
//           offset: offset,
//           search: search,
//         }
//       )
//     ).then((res) => {
//       if (res.success) {
//         dispatch(LeadsActions.QualifiedLeadsSuccess(res.data));
//       } else {
//         dispatch(
//           LeadsActions.QualifiedLeadsFail(res.data.response.data.message)
//         );
//       }
//     });
//   };
export const CreateNewLeadsfile = (leads) => (dispatch) => {
  const formdata = new FormData();
  formdata.append("file", leads);
  dispatch(LeadsActions.createleadsReq());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/sales/lead/sheet",
      {},
      formdata
    )
  ).then((res) => {
    if (res.success) {
      dispatch(LeadsActions.createleadsSuccess(res.data));
      dispatch(getLeads("", "", 10, 0));
    } else {
      dispatch(LeadsActions.createleadsFail(res.data.response.data.message));
    }
  });
};

//delete leads
export const deleteLead =
  (id, sortBy, limit, offset, search, data) => async (dispatch) => {
    dispatch(LeadsActions.DeleteLeadsReq());
    dispatch(
      axiosReq(
        "delete",
        process.env.REACT_APP_API + `/sales/lead/${id}`,
        {},
        {}
      )
    ).then((res) => {
      if (res.success) {
        dispatch(LeadsActions.DeleteLeadsSuccess(res.data));
        dispatch(getLeads("", sortBy, limit, offset, search, data));
      } else {
        dispatch(LeadsActions.DeleteLeadsFail(res.data.response.data.message));
      }
    });
  };

export const assignLead =
  (id, sortBy, limit, offset, search, data, body) => async (dispatch) => {
    dispatch(LeadsActions.AssignLeadsReq());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + `/sales/lead/assign`,
        {},
        body
      )
    ).then((res) => {
      if (res.success) {
        dispatch(LeadsActions.AssignLeadsSuccess(res.data));
        dispatch(getLeads("", sortBy, limit, offset, search, data));
      } else {
        dispatch(
          LeadsActions.AssignLeadsFail(res.data.response.data.error.message)
        );
      }
    });
  };
